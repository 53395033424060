/* eslint-disable */
import { UtaBaggage } from '../../../v1/obt/air/routehappy';
import { PassengerType, PassengerAge } from '../../../v1/obt/air/air_common';
import { CardType, CardCompany } from '../../../v1/common/card';
import { Money } from '../../../v1/common/money';
import { UnusedCreditInfo } from '../../../v1/obt/air/unused_credits';
import { UserOrgId } from '../common/user_org_id';
import { AirSeatMapRequestPaxInfo } from './air_seat_map_request';

export interface AirAncillariesRequest {
  /**
   * The 'search_id' should be the one sent by the backend in the
   * air search response proto.
   */
  searchId: string;
  /**
   * The 'id' of the selected itinerary in the air search response referred to by
   * the above search_id.
   */
  rateOptionId: string;
  /** Information about passengers */
  passengerInfos: AirSeatMapRequestPaxInfo[];
}

export interface AirAncillariesResponse {
  /** Unique id representing this ancillary response. */
  ancillaryResponseId: string;
  /**
   * Baggage options for the travellers to choose. This would be either for entire itinerary or per
   * leg.
   */
  baggage: AirAncillariesResponseBaggage[];
  /** List of all the supported card options. If this list is empty, all cards are supported. */
  supportedCards: AirAncillariesResponseSupportedCard[];
  /** List of parameters requested by supplier.For eg- passport number, DOB, etc. */
  reqdParamsForCheckout: AirAncillariesResponseReqdParamsForCheckout[];
  /** @deprecated */
  unusedCredits?: ApplicableUnusedCredits;
  /**
   * Deprecated, moved to repeated field
   *
   * @deprecated
   */
  earlyBird?: AirAncillariesResponseEarlyBird;
  /** EarlyBird offer for NDC Southwest Airlines */
  earlyBirds: AirAncillariesResponseEarlyBird[];
  /** Applicable unused credits of a traveler */
  applicableUnusedCredits: ApplicableUnusedCredit[];
  ancillaries: AirAncillariesResponseAncillary[];
  allowedFeatures: AirAncillariesResponseAllowedFeatures[];
}

/** Baggage is per leg[, aka LegBaggage] */
export interface AirAncillariesResponseBaggage {
  /** Would be set to -1 if the luggage is for the entire itinerary. */
  legIndex: number;
  /** Whether the supplier requires sending luggage choices per passenger or for the whole booking. */
  perPassenger: boolean;
  /** @deprecated */
  successive: boolean;
  /** @deprecated */
  baggageOptions: AirAncillariesResponseBaggageBaggageOption[];
  /**
   * Baggage information for each passenger type. If per_passenger is false, baggage_infos will
   * contain only one element and will apply per booking.
   */
  baggageInfos: AirAncillariesResponseBaggageBaggageInfo[];
}

export interface AirAncillariesResponseBaggageBaggageOption {
  baggageId: string;
  baggage?: UtaBaggage;
  structuredBaggage?: UtaBaggage;
}

/** BaggageInfo is per pax for each leg[, aka PaxLegBaggage] */
export interface AirAncillariesResponseBaggageBaggageInfo {
  /**
   * Passenger type on which this baggage info applies. If per_passenger is false, pax_type
   * will be set to UNKNOWN_PASSENGER_TYPE
   */
  paxType: PassengerType;
  /** Age of the passenger */
  paxAge?: PassengerAge;
  /**
   * Different baggage options for this leg.
   * There are generally two types:
   * Eg 1: UA - first 2 bags: $25 each, 3rd bag: $30
   *   In this case, successive = true
   *   baggage_options would be of length 3 with num_bags = 1 in each.
   *
   * Eg 2: EasyJet = 1 bag - 23Kg total - 0.00 GBP, 2 bags - 43Kg total - 119.98 GBP,
   * 2 bags - 48Kg total - 129.98 GBP
   *   In this case, successive = false
   */
  successive: boolean;
  baggageOptions: AirAncillariesResponseBaggageBaggageOption[];
}

export interface AirAncillariesResponseSupportedCard {
  type: CardType;
  company: CardCompany;
  /** This is the total fees for the entire itinerary. */
  fees?: Money;
}

/**
 * These are the mandatory fields without which PNR won't be created
 * We send these in ancillaries response to FE, so they can ensure the
 * traveler adds them before checkout.
 * e.g for Sabre on checkout DOB is mandatory.
 */
export interface AirAncillariesResponseReqdParamsForCheckout {
  parameter: AirAncillariesResponseReqdParamsForCheckoutParameter;
  /**
   * Determines whether the requiredParams should be provided per passenger or not.
   * eg certain airlines might just want billing address of the primary traveler.
   * In that case this boolean will be false for billing address.
   */
  perPassenger: boolean;
}

export enum AirAncillariesResponseReqdParamsForCheckoutParameterEnum {
  UNKNOWN = 0,
  DATE_OF_BIRTH = 1,
  BILLING_ADDRESS = 2,
  POSTAL_CODE = 3,
  CVV = 4,
  PASSPORT_ID = 5,
  PASSPORT_EXPIRY_DATE = 6,
  PASSPORT_ISSUE_COUNTRY = 7,
  NATIONALITY = 8,
  GENDER = 9,
  UNRECOGNIZED = -1,
}

export type AirAncillariesResponseReqdParamsForCheckoutParameter = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

/** This is specific to NDC Southwest airlines. */
export interface AirAncillariesResponseEarlyBird {
  /** Determines if EarlyBird option is available to be purchased */
  isAvailable: boolean;
  /** Fees for EarlyBird */
  fees?: Money;
  /** Leg index for which the early bird response came */
  legIndex: number;
}

export interface ApplicableUnusedCredits {
  unusedCreditInfos: UnusedCreditInfo[];
}

export interface ApplicableUnusedCredit {
  unusedCreditInfos?: UnusedCreditInfo;
  canSelfRedeem: boolean;
  /** reason why credit can't be redeemed automatically via OBT. It will be populated when can_self_redeem is false */
  contactSupportReason: ApplicableUnusedCreditReason[];
}

export enum ApplicableUnusedCreditReason {
  REASON_UNKNOWN = 0,
  NAME_MISMATCH = 1,
  CREDIT_TYPE_NOT_SUPPORTED = 2,
  UNRECOGNIZED = -1,
  MULTI_SOURCE_BOOKING = 4,
  PUBLISHED_FARE_CANNOT_BE_USED_FOR_PRIVATE_FARE_BOOKING = 5,
}

/** Ancillary which can be bought per segment like early bird, wifi, drinks etc. */
export interface AirAncillariesResponseAncillary {
  type: AirAncillariesResponseAncillaryAncillaryType;
  /** Display name to show on UI. eg. EARLY_BIRD will be displayed as "Early Bird". */
  displayName: string;
  /**
   * Description about Ancillary to show on UI.
   * eg. EARLY_BIRD - Purchasing early bird provides the customer with a higher boarding priority compared to WannaGetAway/Anytime customers
   * that don’t have a special rapid rewards status irrespective of the time they check in.
   */
  description: string;
  /** Ancillary details per segment/leg. */
  details: AirAncillariesResponseAncillaryAncillaryDetails[];
  /** User for which this ancillary is applicable. */
  userOrgId?: UserOrgId;
}

export enum AirAncillariesResponseAncillaryAncillaryType {
  ANCILLARY_TYPE_UNKNOWN = 0,
  EARLY_BIRD = 1,
  WIFI = 2,
  UNRECOGNIZED = -1,
}

export interface AirAncillariesResponseAncillaryAncillaryDetails {
  status: AirAncillariesResponseAncillaryAncillaryDetailsStatus;
  /** Amount to purchase ancillary. */
  fare?: Money;
  /** All the flights defined by <flight_index, leg_index> for which fare is applicable */
  flightIds: AirAncillariesResponseAncillaryAncillaryDetailsFlightId[];
  /** Ancillary id to be used for purchase. */
  ancillaryId: string;
}

/**
 * Status for ancillary. Priority for Business class is higher than customers who purchased Early Bird so, early bird won't be applicable for
 * business class.
 */
export enum AirAncillariesResponseAncillaryAncillaryDetailsStatus {
  STATUS_UNKNOWN = 0,
  ELIGIBLE = 1,
  NOT_APPLICABLE = 2,
  PURCHASED = 3,
  UNRECOGNIZED = -1,
}

export interface AirAncillariesResponseAncillaryAncillaryDetailsFlightId {
  /** Index of flight within the leg for which the ancillary response. */
  flightIndex: number;
  /** Leg index for which the ancillary response. */
  legIndex: number;
}

export interface AirAncillariesResponseAllowedFeatures {
  /** List of features allowed. */
  features: AirAncillariesResponseAllowedFeaturesFeature[];
  /**
   * Leg index for which the features are allowed. -1 if applicable for
   * entire itinerary.
   */
  legIndex: number;
}

export enum AirAncillariesResponseAllowedFeaturesFeature {
  NONE = 0,
  OSI = 1,
  SSR = 2,
  UNRECOGNIZED = -1,
}
