import first from 'lodash/first';
import type { GetTripDetailsResponse } from '../types/api/v1/obt/trip/trip_details';
import type {
  Pnr,
  AirPnrFlightInfo,
  AirPnrTravelerInfoSeatInfo,
  Ticket,
  AirPnrVoidPolicy,
} from '../types/api/v1/obt/pnr/pnr';
import type { LoyaltyInfo } from '../types/api/v1/obt/common/traveler_personal_info';
import type { IAirPnrFlightInfo } from '../types/trip';
import { IsRefundableEnum, IThirdPartySourceEnum, ITripPnrStatusEnum } from '../types/trip';
import AirSearchResponseManager from './AirSearchResponseManager';
import { MoneyUtil } from '../utils/Money';
import { getMerchantFeeFromPnr } from '../utils/trips';
import CarSearchResponseManager from './CarSearchResponseManager';
import HotelDetailsManager from './HotelDetailsManager';
import type { RateOption } from '../types/api/v1/obt/hotel/hotel_details_response';
import type { IItinerarySelectedLeg, IFlightLegDetails } from '../types/flight';
import type { AirSearchResponse } from '../types/api/v1/obt/air/air_search_response';
import type { ThirdPartySource } from '../types/api/v1/obt/supplier/third_party_info';
import type { CarSearchResponse } from '../types/api/v1/obt/car/car_search_response';
import type { ICarSummary } from '../types/car';
import type { Document } from '../types/api/v1/obt/document/document_service';

export default class TripDetailsResponseManager {
  constructor(readonly response: GetTripDetailsResponse) {
    if (!response) {
      throw new Error('Invalid Trip Details Response passed to TripDetailsResponseManager');
    }
  }

  public GetTripId(): string {
    return this.response.tripInfo?.tripId ?? '';
  }

  public GetTripName(): string {
    return this.response.tripInfo?.name ?? '';
  }

  public GetTripDescription(): string {
    return this.response.tripInfo?.description ?? '';
  }

  public GetSortedPnrDetails(): Pnr[] {
    const hotels: Pnr[] = [];
    const flights: Pnr[] = [];
    const cars: Pnr[] = [];
    this.response.pnr.forEach((pnr) => {
      if (Object.keys(pnr).includes('air')) {
        flights.push(pnr);
      }
      if (Object.keys(pnr).includes('hotel')) {
        hotels.push(pnr);
      }
      if (Object.keys(pnr).includes('car')) {
        cars.push(pnr);
      }
    });

    return [...flights, ...hotels, ...cars];
  }

  public GetPnrDetailsObject(): { [key: string]: Pnr } {
    const pnrs: { [key: string]: Pnr } = {};
    this.response.pnr.forEach((pnr) => {
      pnrs[pnr.pnrId] = pnr;
    });
    return pnrs;
  }

  public GetTotalAirFare = (): MoneyUtil | MoneyUtil[] | undefined => {
    const fares: MoneyUtil[] = this.GetAllAirFares();
    return this.GetTotalFare(fares);
  };

  public GetTotalCarFare = (): MoneyUtil | MoneyUtil[] | undefined => {
    const fares: MoneyUtil[] = this.GetAllCarFares();
    return this.GetTotalFare(fares);
  };

  public GetTotalHotelFare(): MoneyUtil | MoneyUtil[] | undefined {
    const allFares: MoneyUtil[] = [];

    this.response.pnr.forEach(({ status, hotel }: Pnr): void => {
      const hotelDetailsResponse = hotel?.hotel;
      const confirmed = status === ITripPnrStatusEnum.CONFIRMED;
      if (hotelDetailsResponse && confirmed) {
        const rateOption: RateOption = hotelDetailsResponse?.rooms[0]?.rateOptions[0];
        const hotelDetailsManager = new HotelDetailsManager(hotelDetailsResponse);
        allFares.push(hotelDetailsManager.GetTotalPrice(rateOption));
      }
    });
    return this.GetTotalFare(allFares);
  }

  public GetAirPnrInfo(pnrNumber: string): IAirPnrFlightInfo[] | undefined {
    const pnrDetails = this.GetPnrDetails(pnrNumber);
    if (pnrDetails?.air?.air) {
      const vendorInfo: AirPnrFlightInfo[] = pnrDetails.air.flightInfo;
      const seatInfo: AirPnrTravelerInfoSeatInfo[] = first(pnrDetails.air.travelerInfo)?.seatInfo ?? [];
      const legSeatVendorInfo = TripDetailsResponseManager.GetVendorAndSeatInfo(
        pnrDetails.air.air,
        vendorInfo,
        seatInfo,
      );
      return legSeatVendorInfo;
    }
    return undefined;
  }

  public GetAllPnrs(): string[] {
    return this.response.pnr.map((pnr) => pnr.sourcePnrId);
  }

  public GetAirItinSource(sourcePnrId: string): ThirdPartySource {
    const pnrDetails = this.GetPnrDetails(sourcePnrId);
    const itinSorce = first(pnrDetails?.air?.air?.itineraries)?.rateOptions[0].source;
    return itinSorce ?? IThirdPartySourceEnum.UNKNOWN_SOURCE;
  }

  public GetLoyaltyInfo(pnrNumber: string, type: 'air' | 'hotel' | 'car'): LoyaltyInfo[] {
    const pnrDetails = this.GetPnrDetails(pnrNumber);
    return pnrDetails ? first(pnrDetails[type]?.travelerInfo)?.traveler?.travelerPersonalInfo?.loyaltyInfos ?? [] : [];
  }

  public GetAirports(pnrNumber: string, legIndex: number): { code: string; name: string }[] {
    const airSearchResponse = this.GetAirSearchResponse(pnrNumber);
    if (!airSearchResponse) return [];
    const airSearchResponseManager = new AirSearchResponseManager(airSearchResponse);
    return airSearchResponseManager.GetAirports(legIndex);
  }

  public GetTotalFlightsTillLeg(
    pnrNumber: string,
    itineraryAllLegs: IItinerarySelectedLeg[],
    legIndex: number,
  ): number {
    const airSearchResponse = this.GetAirSearchResponse(pnrNumber);
    if (!airSearchResponse) return 0;
    const airSearchResponseManager = new AirSearchResponseManager(airSearchResponse);
    return airSearchResponseManager.GetTotalFlightsTillLeg(itineraryAllLegs, legIndex);
  }

  public GetLegDetails(pnrNumber: string, legNumber: number): IFlightLegDetails[] {
    const airSearchResponse = this.GetAirSearchResponse(pnrNumber);
    if (!airSearchResponse) return [];
    const itinerary = first(airSearchResponse.itineraries);
    const legIndex = itinerary?.legIndices[legNumber] ?? -1;
    const airSearchResponseManager = new AirSearchResponseManager(airSearchResponse);
    const legDetails = airSearchResponseManager.GetLegDetailsFromLegIndexAndItinerary(legIndex, legNumber, itinerary);
    return [legDetails];
  }

  public GetItineraryAllLegs(pnrNumber: string): IItinerarySelectedLeg[] {
    const airSearchResponse = this.GetAirSearchResponse(pnrNumber);
    if (!airSearchResponse) return [];
    const legs = TripDetailsResponseManager.GetLegs(airSearchResponse);
    return legs.map((legIndex, legNumber) => ({ legIndex, legNumber, isLastLeg: false }));
  }

  /** Need this check because while say, sitting in US
   * you can book an European airline too (which charges in EUR)
   * bcoz its just that it operates in US too
   * @param fares
   */
  static areAllFaresInSameCurrency(fares: MoneyUtil[]): boolean {
    const firstCurrencyCode = first(fares)?.getCurrency();
    if (!firstCurrencyCode) return false;
    return fares.every((fare) => fare.getCurrency() === firstCurrencyCode);
  }

  static GetLegs(airSearchResponse: AirSearchResponse): number[] {
    const itineraries = first(airSearchResponse.itineraries);
    return itineraries?.legIndices ?? [];
  }

  static GetLegFlights(airSearchResponse: AirSearchResponse, legIndex: number): number[] {
    const legFlights = airSearchResponse.data.legs[legIndex].flightIndices;
    return legFlights;
  }

  static GetVendorAndSeatInfo(
    airSearchResponse: AirSearchResponse,
    vendorInfo: AirPnrFlightInfo[],
    seatInfo: AirPnrTravelerInfoSeatInfo[],
  ): IAirPnrFlightInfo[] {
    const legDetails = TripDetailsResponseManager.GetLegs(airSearchResponse);
    const legSeatVendorInfo: IAirPnrFlightInfo[] = [];

    legDetails.forEach((_legDetail, legIndex) => {
      const flightIndices = TripDetailsResponseManager.GetLegFlights(airSearchResponse, legIndex);
      flightIndices.forEach((flightIndex, flightNumber) => {
        const flightVendorInfo = first(vendorInfo.filter((vendor) => vendor.flightIndex === flightIndex));
        const flightSeatInfo = first(seatInfo.filter((seat) => seat.flightIndex === flightIndex));
        legSeatVendorInfo.push({
          legIndex,
          flightIndex: flightNumber,
          vendorConfirmationId: flightVendorInfo?.vendorConfirmationId ?? '',
          vendorConfirmationStatus: flightVendorInfo?.status,
          seatNumber: flightSeatInfo?.number ?? '',
          seatStatus: flightSeatInfo?.status,
        });
      });
    });

    return legSeatVendorInfo;
  }

  public GetPnrDetails(sourcePnrId: string): Pnr | null {
    return this.response.pnr.find((pnr) => pnr.sourcePnrId === sourcePnrId) ?? null;
  }

  private GetAllAirFares(): MoneyUtil[] {
    let allFares: MoneyUtil[] = [];
    this.response.pnr.forEach(({ status, air }) => {
      const airSearchResponse = air?.air;
      if (!airSearchResponse) return;
      const confirmedOrTicketed = status === ITripPnrStatusEnum.CONFIRMED || status === ITripPnrStatusEnum.TICKETED;
      if (confirmedOrTicketed) {
        const airSearchResponseManager = new AirSearchResponseManager(airSearchResponse);
        allFares.push(airSearchResponseManager.GetTotalFare(0));
        /** Add seat price to total fare calculation */
        const seatInfo: AirPnrTravelerInfoSeatInfo[] = first(air?.travelerInfo)?.seatInfo ?? [];
        const allSeatPrice = TripDetailsResponseManager.GetAllSeatPrice(seatInfo);
        allFares = allFares.concat(allSeatPrice);
      }
    });
    return allFares;
  }

  protected static GetAllSeatPrice(seatInfo: AirPnrTravelerInfoSeatInfo[]): MoneyUtil[] {
    const seatPrices: MoneyUtil[] = seatInfo.map((seat) => MoneyUtil.parse(seat?.amount));
    return seatPrices;
  }

  /* We create zeroMoney from baseFare because for other fare components like tax,seat,refund penalty and exchnage penalty,
     we might not get amount node and creating zeroMoney would lead to bugs.
     For eg , if the preferred currency is 'SGD' and originalCurrency is 'USD'.
     if one of the seat options doesn't have amount node , We will end up creating a zero money
     with both original currency and preferred currency to 'USD' and on adding it with baseFare , We will get currency mismatch error.
     To avoid this , We create  zeroMoney from baseFare since we know this would always have amount.
  */

  /** @deprecated */
  protected GetZeroMoney(sourcePnrId: string): MoneyUtil {
    const baseFare = this.GetTicketsBasefare(sourcePnrId);
    return MoneyUtil.zeroMoneyWithOriginal(baseFare.getCurrency(), baseFare.getOriginalCurrency());
  }

  private GetAllCarFares(): MoneyUtil[] {
    const allFares: MoneyUtil[] = [];
    this.response.pnr.forEach(({ car, status }) => {
      const carSearchResponse = car?.car;
      if (!carSearchResponse) return;
      const confirmed = status === ITripPnrStatusEnum.CONFIRMED;
      if (confirmed) {
        const carSearchResponseManager = new CarSearchResponseManager(carSearchResponse);
        /** The response will only have one car hence, passing 0 here */
        const carIndex = 0;
        const selectedCarId = carSearchResponseManager.GetPnrRequestCarDetails(carIndex).carId;
        allFares.push(carSearchResponseManager.GetApproximateTotalCost(selectedCarId));
      }
    });
    return allFares;
  }

  private GetTotalFare = (fares: MoneyUtil[]): MoneyUtil | MoneyUtil[] | undefined => {
    if (!fares.length) return undefined;
    if (TripDetailsResponseManager.areAllFaresInSameCurrency(fares)) {
      const currencyCode = fares[0].getCurrency();
      return fares.reduce((totalFare, currentFare) => currentFare.add(totalFare), MoneyUtil.create(0, currencyCode));
    }
    return fares;
  };

  private GetAirSearchResponse(sourcePnrId: string): AirSearchResponse | undefined {
    const pnrDetails = this.GetPnrDetails(sourcePnrId);
    const airSearchResponse = pnrDetails?.air?.air;
    return airSearchResponse;
  }

  public GetAirPnrVoidPolicy(sourcePnrId: string): AirPnrVoidPolicy | undefined {
    const pnrDetails = this.GetPnrDetails(sourcePnrId);
    const voidPolicy = pnrDetails?.air?.voidPolicy;
    return voidPolicy;
  }

  /** @deprecated */
  public GetAirPnrTickets(sourcePnrId: string, _paxIndex = 0): Ticket[] {
    const pnrDetails = this.GetPnrDetails(sourcePnrId);
    return pnrDetails?.air?.travelerInfo.flatMap((traveler) => traveler.tickets) ?? [];
  }

  /** @deprecated */
  public IsPnrRefundable(sourcePnrId: string, _paxIndex = 0): IsRefundableEnum {
    const pnrTickets = this.GetAirPnrTickets(sourcePnrId);
    if (pnrTickets) {
      if (pnrTickets.length) {
        // if any of the ticket's isInfoAvailable is false : contact us (i.e return IsRefundableEnum.UNRECOGNIZED)
        // if both refundable/non-refundable then show cummulative penalty else, contact support(i.e return IsRefundableEnum.UNRECOGNIZED)
        const allInfoAvailable = pnrTickets.every(
          (ticket) => ticket.cancellationPolicy?.refundPolicy?.isInfoAvailable === true,
        );
        if (!allInfoAvailable) {
          return IsRefundableEnum.UNRECOGNIZED;
        }
        const allRefundable = pnrTickets.every(
          (ticket) => ticket.cancellationPolicy?.refundPolicy?.isRefundable === true,
        );
        if (allRefundable) {
          return IsRefundableEnum.TRUE;
        }
        const allNonRefundable = pnrTickets.every(
          (ticket) => ticket.cancellationPolicy?.refundPolicy?.isRefundable === false,
        );
        if (allNonRefundable) {
          return IsRefundableEnum.FALSE;
        }
        return IsRefundableEnum.UNRECOGNIZED;
      }
    }
    return IsRefundableEnum.UNRECOGNIZED;
  }

  /** @deprecated */
  public IsPnrExchangeable(sourcePnrId: string, _paxIndex = 0): boolean {
    const pnrTickets = this.GetAirPnrTickets(sourcePnrId);
    const isRefundable = this.IsPnrRefundable(sourcePnrId);
    if (isRefundable === IsRefundableEnum.FALSE) {
      if (pnrTickets) {
        if (pnrTickets.length) {
          const allExchangeable = pnrTickets.every(
            (ticket) => ticket.cancellationPolicy?.exchangePolicy?.isExchangeable === true,
          );
          if (allExchangeable) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /** @deprecated (We will fetch ticket level info in pnr manager) */
  public GetTicketsBasefare(sourcePnrId: string): MoneyUtil {
    const pnrTickets = this.GetAirPnrTickets(sourcePnrId);
    const baseFare = MoneyUtil.parse(pnrTickets[0]?.amount?.base);
    const currency = baseFare.getCurrency();
    const originalCurrency = baseFare.getOriginalCurrency();
    return pnrTickets.reduce(
      (totalFare, currentFare) => MoneyUtil.parse(currentFare?.amount?.base).add(totalFare),
      MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency),
    );
  }

  /** @deprecated (We will fetch ticket level info in pnr manager) */
  public GetTicketsTax(sourcePnrId: string): MoneyUtil {
    const pnrTickets = this.GetAirPnrTickets(sourcePnrId);
    const zeroMoney = this.GetZeroMoney(sourcePnrId);
    return pnrTickets.reduce(
      (totalFare, currentFare) => MoneyUtil.parse(currentFare?.amount?.tax).add(totalFare),
      zeroMoney,
    );
  }

  /** @deprecated (move to AirPnr manager later) */

  public GetSeatPrice(sourcePnrId: string): MoneyUtil {
    const pnrDetails = this.GetPnrDetails(sourcePnrId);
    const zeroMoney = this.GetZeroMoney(sourcePnrId);
    const seatInfo: AirPnrTravelerInfoSeatInfo[] = first(pnrDetails?.air?.travelerInfo)?.seatInfo ?? [];
    const allSeatPrice = TripDetailsResponseManager.GetAllSeatPrice(seatInfo);
    if (allSeatPrice.length) {
      return allSeatPrice.reduce(
        (totalFare, currentFare) => (currentFare.isZero() ? zeroMoney.add(totalFare) : currentFare.add(totalFare)),
        zeroMoney,
      );
    }
    return zeroMoney;
  }

  /** @deprecated */
  public GetTotalRefundPenalty(sourcePnrId: string): MoneyUtil {
    const pnrTickets = this.GetAirPnrTickets(sourcePnrId);
    const zeroMoney = this.GetZeroMoney(sourcePnrId);
    const isExchangeable = this.IsPnrExchangeable(sourcePnrId);
    if (isExchangeable) {
      return zeroMoney;
    }

    return pnrTickets.reduce((totalPenalty, currentPenalty) => {
      const refundPenalty = MoneyUtil.parse(currentPenalty.cancellationPolicy?.refundPolicy?.refundPenalty);
      return refundPenalty.isZero() ? zeroMoney.add(totalPenalty) : refundPenalty.add(totalPenalty);
    }, zeroMoney);
  }

  /** @deprecated */
  public GetTotalExchangePenalty(sourcePnrId: string): MoneyUtil {
    const pnrTickets = this.GetAirPnrTickets(sourcePnrId);
    const zeroMoney = this.GetZeroMoney(sourcePnrId);
    return pnrTickets.reduce((totalPenalty, currentPenalty) => {
      const exchangePenalty = MoneyUtil.parse(currentPenalty.cancellationPolicy?.exchangePolicy?.exchangePenalty);
      return exchangePenalty.isZero() ? zeroMoney.add(totalPenalty) : exchangePenalty.add(totalPenalty);
    }, zeroMoney);
  }

  /** @deprecated */
  public GetAirlineCredit(sourcePnrId: string): MoneyUtil | undefined {
    const totalFare = this.GetTotalFlightsPrice(sourcePnrId);
    const penalty = this.GetTotalExchangePenalty(sourcePnrId);
    let total;
    if (totalFare) {
      total = totalFare;
      if (penalty) {
        total = total.subtract(penalty);
      }
      return total;
    }
    return undefined;
  }

  /** @deprecated */

  private GetTotalFlightsPrice(sourcePnrId: string): MoneyUtil | undefined {
    const baseFare = this.GetTicketsBasefare(sourcePnrId);
    const tax = this.GetTicketsTax(sourcePnrId);
    const seatPrice = this.GetSeatPrice(sourcePnrId);
    let total;
    if (baseFare) {
      total = baseFare;
      if (tax) {
        total = total.add(tax);
      }
      if (seatPrice) {
        total = total.add(seatPrice);
      }
      return total;
    }
    return undefined;
  }

  /** @deprecated not needed */

  public GetTotalRefundSW(sourcePnrId: string): MoneyUtil | undefined {
    const totalFare = this.GetTotalFlightsPrice(sourcePnrId);

    if (totalFare) {
      const merchantFee = this.getMerchantFee(sourcePnrId);
      return totalFare.subtract(merchantFee);
    }
    return undefined;
  }

  /** @deprecated not needed */
  public GetTotalRefund(sourcePnrId: string): MoneyUtil | undefined {
    const totalFare = this.GetTotalFlightsPrice(sourcePnrId);
    const refundPenalty = this.GetTotalRefundPenalty(sourcePnrId);
    const isExchangeable = this.IsPnrExchangeable(sourcePnrId);
    const merchantFee = this.getMerchantFee(sourcePnrId);

    if (totalFare) {
      if (isExchangeable) {
        return MoneyUtil.zeroMoneyWithOriginal(totalFare.getCurrency(), totalFare.getOriginalCurrency());
      }

      const total = totalFare.subtract(merchantFee);
      return refundPenalty ? total.subtract(refundPenalty) : total;
    }

    return undefined;
  }

  static GetCarDetails(carSearchResponse: CarSearchResponse | undefined): ICarSummary | undefined {
    if (!carSearchResponse) {
      return undefined;
    }
    const manager = new CarSearchResponseManager(carSearchResponse);
    const carSummary = manager.GetCarSummary(carSearchResponse.cars[0].carId);
    const co2EmissionsValue = manager.GetCarCO2EmissionValue(carSearchResponse.cars[0].carId);
    return { ...carSummary, co2EmissionsValue };
  }

  public getMerchantFee(sourcePnrId: string): MoneyUtil {
    const pnr = this.GetPnrDetails(sourcePnrId);

    return pnr ? getMerchantFeeFromPnr(pnr) : this.GetZeroMoney(sourcePnrId);
  }

  public getDocumentsPresentInPnr(pnrId: string): Document[] {
    const foundPnr = this.response.pnr.find((pnr) => pnr.pnrId === pnrId);
    if (!(foundPnr && foundPnr.document)) {
      return [];
    }
    return foundPnr.document;
  }

  /**
   * Returns list of documents in pnr where generated invoices have been removed
   */
  public getUploadedDocumentsPresentInPnr(pnrId: string): Document[] {
    const allDocuments = this.getDocumentsPresentInPnr(pnrId);
    const uploadedDocuments = allDocuments.filter(
      (document) => !document.documentMetadata.entityMetadata?.pnrMetadata?.invoiceMetadata,
    );
    return uploadedDocuments;
  }

  public getGeneratedInvoiceDocuments(pnrId: string): Document[] {
    const allDocuments = this.getDocumentsPresentInPnr(pnrId);
    const uploadedDocuments = allDocuments.filter(
      (document) => document.documentMetadata.entityMetadata?.pnrMetadata?.invoiceMetadata,
    );
    return uploadedDocuments;
  }
}
