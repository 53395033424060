import type { IFlightsFilterState } from '../../types';
import { getDefaultTimeRanges } from '../../transformers';

interface getDefaultFilterStateInput {
  length: number;
  isDefaultOopFilterEnabled: boolean;
  isDefaultSeparateTicketFilterEnabled: boolean;
}
export const getDefaultFilterState = ({
  length,
  isDefaultOopFilterEnabled,
  isDefaultSeparateTicketFilterEnabled,
}: getDefaultFilterStateInput): IFlightsFilterState[][] => {
  const filter = new Array(length).fill(
    [
      {
        cabin: {
          cabin: 1,
          type: 2,
        },
      },
      {
        timeRange: getDefaultTimeRanges(length),
      },
      {
        policyFilter: {
          onlyInPolicy: !isDefaultOopFilterEnabled,
        },
      },
      {
        changeable: 0,
      },
      {
        multiTicketFilter: {
          hideMultiTicket: !isDefaultSeparateTicketFilterEnabled,
        },
      },
      {
        unusedCreditFilter: {
          hideWithoutCredits: false,
        },
      },
    ],
    0,
  );
  return filter;
};
