export enum AirCheckoutFareNodeTypeEnum {
  HEADING = 'HEADING',
  SUB_HEADING = 'SUB_HEADING',
  NORMAL = 'NORMAL',
  DIVIDER = 'DIVIDER',
  ONLY_VALUE = 'ONLY_VALUE',
  INFO = 'INFO',
}

export enum CheckoutColorVariantEnum {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  NORMAL = 'NORMAL',
}

export type IAirCheckoutFareBreakupNode =
  | { type: AirCheckoutFareNodeTypeEnum.DIVIDER }
  | {
      type: AirCheckoutFareNodeTypeEnum.HEADING;
      label: string;
      value: string;
    }
  | {
      type: AirCheckoutFareNodeTypeEnum.SUB_HEADING;
      label: string;
      value: string;
      subTextLeft?: string;
      subTextRight?: string;
    }
  | {
      type: AirCheckoutFareNodeTypeEnum.NORMAL;
      label: string;
      value: string;
      subTextLeft?: string;
      subTextRight?: string;
      colorVariant?: CheckoutColorVariantEnum;
      toolTipText?: string;
    }
  | {
      type: AirCheckoutFareNodeTypeEnum.ONLY_VALUE;
      value: string;
    }
  | {
      type: AirCheckoutFareNodeTypeEnum.INFO;
      text: string;
    };
