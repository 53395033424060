import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import findKey from 'lodash/findKey';
import { injectPathParams } from '../../utils/common';

import type {
  MethodTypes,
  ParamsType,
  PathType,
  PostQueryParamsType,
  QueryParamsType,
  RequestParamsType,
  ResponseType,
} from './types';
import api from '../index';

interface QueryKeyParams<Path extends PathType, Method extends MethodTypes> {
  path: Path;
  params?: ParamsType<Path, Method> | undefined;
}

export const generateUrlInfo = <Path extends PathType, Method extends MethodTypes>(
  queryKeyParams: QueryKeyParams<Path, Method>,
) => {
  const { path, params } = queryKeyParams;
  const pathParams = params && 'path' in params ? params.path : undefined;
  const searchParams = params && 'search' in params ? params.search : undefined;
  let url: string = path;
  if (pathParams && !findKey(pathParams, (value) => !value)) {
    url = injectPathParams(path, pathParams);
  }
  return { url, searchParams, queryKey: [url, searchParams] };
};

export function useApiQuery<
  Path extends PathType,
  QueryParams extends QueryParamsType<Path>,
  Response extends ResponseType<Path, 'get'>,
>(path: Path, queryParams: QueryParams) {
  const params = 'params' in queryParams ? queryParams.params : undefined;
  const options = 'options' in queryParams ? queryParams.options : undefined;

  const { url, searchParams, queryKey } = generateUrlInfo<Path, 'get'>({ path, params });

  const queryFn = async () => {
    const response = await api('GET', 'base', {
      urlParam: url,
      params: searchParams,
    });
    return response as Response;
  };

  return useQuery({ queryKey, queryFn, ...options });
}

export function usePostQuery<
  Path extends PathType,
  PostQueryParams extends PostQueryParamsType<Path>,
  Response extends ResponseType<Path, 'post'>,
>(path: Path, postQueryParams: PostQueryParams) {
  const params = 'params' in postQueryParams ? postQueryParams.params : undefined;
  const options = 'options' in postQueryParams ? postQueryParams.options : undefined;
  const requestBody = 'requestBody' in postQueryParams ? postQueryParams.requestBody : undefined;

  const { url, searchParams, queryKey } = generateUrlInfo<Path, 'post'>({ path, params });

  if (requestBody) {
    queryKey.push(JSON.stringify(requestBody));
  }

  const queryFn = async () => {
    const response = await api('POST', 'base', {
      urlParam: url,
      data: requestBody as Record<string, any>,
      params: searchParams,
    });
    return response as Response;
  };

  return useQuery({ queryKey, queryFn, ...options });
}

export function usePostMutation<
  Path extends PathType,
  RequestParams extends RequestParamsType<Path, 'post'>,
  Response extends ResponseType<Path, 'post'>,
>(path: Path, options?: UseMutationOptions<Response, unknown, RequestParams>) {
  return useMutation<Response, unknown, RequestParams>({
    mutationFn: async (requestParams) => {
      const params = 'params' in requestParams ? requestParams.params : undefined;
      const requestBody = 'requestBody' in requestParams ? requestParams.requestBody : undefined;

      const { url, searchParams } = generateUrlInfo<Path, 'post'>({ path, params });
      const response = await api('POST', 'base', {
        urlParam: url,
        data: requestBody as Record<string, any>,
        params: searchParams,
      });
      return response as Response;
    },
    ...options,
  });
}

export function usePutMutation<
  Path extends PathType,
  RequestParams extends RequestParamsType<Path, 'put'>,
  Response extends ResponseType<Path, 'put'>,
>(path: Path, options?: UseMutationOptions<Response, unknown, RequestParams>) {
  return useMutation<Response, unknown, RequestParams>({
    mutationFn: async (requestParams) => {
      const params = 'params' in requestParams ? requestParams.params : undefined;
      const requestBody = 'requestBody' in requestParams ? requestParams.requestBody : undefined;

      const { url, searchParams } = generateUrlInfo<Path, 'put'>({ path, params });
      const response = await api('PUT', 'base', {
        urlParam: url,
        data: requestBody as Record<string, any>,
        params: searchParams,
      });
      return response as Response;
    },
    ...options,
  });
}

export function usePatchMutation<
  Path extends PathType,
  RequestParams extends RequestParamsType<Path, 'patch'>,
  Response extends ResponseType<Path, 'patch'>,
>(path: Path, options?: UseMutationOptions<Response, unknown, RequestParams>) {
  return useMutation<Response, unknown, RequestParams>({
    mutationFn: async (requestParams) => {
      const params = 'params' in requestParams ? requestParams.params : undefined;
      const requestBody = 'requestBody' in requestParams ? requestParams.requestBody : undefined;

      const { url, searchParams } = generateUrlInfo<Path, 'patch'>({ path, params });
      const response = await api('PATCH', 'base', {
        urlParam: url,
        data: requestBody as Record<string, any>,
        params: searchParams,
      });
      return response as Response;
    },
    ...options,
  });
}

export function useDeleteMutation<
  Path extends PathType,
  RequestParams extends RequestParamsType<Path, 'delete'>,
  Response extends ResponseType<Path, 'delete'>,
>(path: Path, options?: UseMutationOptions<Response, unknown, RequestParams>) {
  return useMutation<Response, unknown, RequestParams>({
    mutationFn: async (requestParams) => {
      const params = 'params' in requestParams ? requestParams.params : undefined;
      const requestBody = 'requestBody' in requestParams ? requestParams.requestBody : undefined;
      const { url, searchParams } = generateUrlInfo<Path, 'delete'>({ path, params });
      const response = await api('DELETE', 'base', {
        urlParam: url,
        data: requestBody as Record<string, any>,
        params: searchParams,
      });
      return response as Response;
    },
    ...options,
  });
}
