import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import type { ServiceCharge } from '../types/api/v2/obt/model/service-charge';
import { MoneyUtil } from './Money';
import type { ITripFeeInfo } from '../types';
import type { ServiceFee } from '../types/api/v2/obt/model/service-fee';
import type { FeeInfo } from '../types/api/v2/obt/model/fee-info';
import type { Money } from '../types/api/v1/common/money';

const compareOriginalCurrencies = (moneyUtil1: MoneyUtil, moneyUtil2: MoneyUtil) =>
  moneyUtil1.getOriginalCurrency() !== moneyUtil2.getOriginalCurrency();

export const checkForMixedCurrencies = (moneUtils: MoneyUtil[]) => {
  if (!moneUtils.length) {
    return false;
  }
  const firstMoneyUtil = moneUtils[0];
  return moneUtils.some((moneUtil) => compareOriginalCurrencies(firstMoneyUtil, moneUtil));
};

interface ICombineTripFeesProps {
  tripFees: ITripFeeInfo[];
}

export const combineTripFees = ({ tripFees }: ICombineTripFeesProps) => {
  const groupedFees = groupBy(tripFees, ({ feeType, subType, subCategory }) => `${feeType}_${subType}_${subCategory}`);
  const mergedGroups = mapValues(groupedFees, (groupValues) => {
    return groupValues.reduce((acc, value) => {
      if (!acc) {
        return value;
      }
      const base = value.base || MoneyUtil.zeroMoney();
      const tax = value.tax || MoneyUtil.zeroMoney();
      const accTax = acc.tax || MoneyUtil.zeroMoney();

      return { ...acc, base: acc.base.add(base), tax: accTax.add(tax) };
    });
  });
  return values(mergedGroups);
};

function getTotalServiceFeesV1(serviceFees: MoneyUtil[]) {
  if (!serviceFees.length) {
    return MoneyUtil.zeroMoney();
  }

  const { otherCoinage } = serviceFees[0];
  const currency = serviceFees[0].getCurrency();
  const originalCurrency = serviceFees[0].getOriginalCurrency();

  let totalServiceFee = MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency, otherCoinage);

  serviceFees.forEach((fee) => {
    totalServiceFee = totalServiceFee.add(fee);
  });
  return totalServiceFee;
}

function createTripFeeInfo(serviceCharge: ServiceFee): ITripFeeInfo {
  // added this way type check to avoid build Excessive complexity comparing types as feeInfo in ServiceFee is used with service charge
  const feeInfo: FeeInfo = serviceCharge?.feeInfo ?? {
    feeType: 'BOOKING_FEE',
    bookingFeeType: 'TRIP_FEE',
  };

  const { feeType } = feeInfo;
  const subType = feeInfo.bookingFeeType;
  let subCategory;

  if (feeInfo.feeType === 'BOOKING_FEE') {
    if (feeInfo.bookingFeeType === 'TRANSACTION_FEE') {
      subCategory = feeInfo.transactionFeeType;
    } else if (feeInfo.bookingFeeType === 'VALUE_ADDED_SERVICE_FEE') {
      subCategory = feeInfo.valueAddedServiceFeeType;
    }
  }

  return {
    feeType,
    subType,
    subCategory,
    base: MoneyUtil.parse(serviceCharge?.fare?.base as unknown as Money),
    tax: serviceCharge?.fare?.tax && MoneyUtil.parse(serviceCharge.fare.tax as unknown as Money),
    visibleToTraveler: serviceCharge.visibleToTraveler,
    fop: serviceCharge?.fop,
    ticketNumber: serviceCharge.ticketNumber,
  };
}

interface ICreateTripFeesProps {
  serviceCharges: ServiceFee[];
}

export function createTripFees({ serviceCharges }: ICreateTripFeesProps): ITripFeeInfo[] {
  return serviceCharges.map(createTripFeeInfo);
}

interface ICreateTripFeeInfoOfCalculateApiProps {
  serviceCharge: ServiceCharge;
}

function createTripFeeInfoOfCalculateApi({ serviceCharge }: ICreateTripFeeInfoOfCalculateApiProps): ITripFeeInfo {
  const defaultFeeInfo: FeeInfo = {
    feeType: 'BOOKING_FEE',
    bookingFeeType: 'TRIP_FEE',
  };
  const feeInfo = serviceCharge?.feeInfo ?? defaultFeeInfo;

  const { feeType } = feeInfo;
  const subType = feeInfo.bookingFeeType;
  let subCategory;

  if (feeInfo.feeType === 'BOOKING_FEE') {
    if (feeInfo.bookingFeeType === 'TRANSACTION_FEE') {
      subCategory = feeInfo.transactionFeeType;
    } else if (feeInfo.bookingFeeType === 'VALUE_ADDED_SERVICE_FEE') {
      subCategory = feeInfo.valueAddedServiceFeeType;
    }
  }
  const allTaxes = serviceCharge.taxes.map((tax) => MoneyUtil.convertV2MoneyToMoneyUtil(tax.amount));
  const totalTaxes = getTotalServiceFeesV1(allTaxes);

  return {
    feeType,
    subType,
    subCategory,
    base: MoneyUtil.convertV2MoneyToMoneyUtil(serviceCharge.amount),
    tax: totalTaxes,
  };
}

interface ICreateTripFeesFromCalculateApiServiceCharge {
  serviceCharges: ServiceCharge[];
}

export function createTripFeesFromCalculateApiServiceCharge({
  serviceCharges,
}: ICreateTripFeesFromCalculateApiServiceCharge) {
  return serviceCharges.map((serviceCharge) => createTripFeeInfoOfCalculateApi({ serviceCharge }));
}

export function createServiceFeesWithTripFeeV3(serviceFees?: ServiceFee[]) {
  const parsedServiceChargeTaxes: MoneyUtil[] = [];
  const parsedServiceCharges: MoneyUtil[] = [];
  serviceFees?.forEach((serviceFee) => {
    if (serviceFee.fare?.tax) {
      parsedServiceChargeTaxes.push(MoneyUtil.convertV2MoneyToMoneyUtil(serviceFee?.fare?.tax));
    }
    /* old and new types have minor irrelvant difference that don't make a difference to this function's scope */
    parsedServiceCharges.push(MoneyUtil.convertV2MoneyToMoneyUtil(serviceFee?.fare?.base));
  });

  const base = getTotalServiceFeesV1(parsedServiceCharges);
  const tax = getTotalServiceFeesV1(parsedServiceChargeTaxes);
  const total = base.add(tax);
  const isTotalHavingDifferentCurrency = checkForMixedCurrencies([
    ...parsedServiceCharges,
    ...parsedServiceChargeTaxes,
  ]);
  const tripFees = createTripFees({ serviceCharges: serviceFees || [] });
  return {
    base,
    tax,
    total,
    tripFees,
    showWithFeeBreakup: true, // As this gets called only when new feature is enabled
    isTotalHavingDifferentCurrency,
  };
}
