import type { BookingPaymentSource } from '../../types/api/v2/obt/model/booking-payment-source';
import type { PaymentSourceType } from '../../types/api/v2/obt/model/payment-source-type';
import { CreditCardAccessType } from '../../types/api/v2/obt/model/credit-card-access-type';
import { isBefore, getTodayDateTime } from '../../date-utils';
import type { PaymentSourceMetadata } from './extractPaymentSourceMetadata';
import {
  extractAirlineProgramMetadata,
  extractBrexBudgetPaymentMetadata,
  extractCardMetadata,
  extractDirectBillingMetadata,
  extractRewardsProgramMetadata,
  extractVirtualCardMetadata,
  extractCustomVirtualCardMetadata,
} from './extractPaymentSourceMetadata';
import {
  isCardPaymentSourceWithMetadata,
  isCustomPaymentWithBrexBudgetMetadata,
  isRewardPaymentWithPoints,
  isVendorProgramPaymentWithVendorDescription,
  isCustomVirtualPaymentSourceWithMetadata,
  isVirtualCardPaymentSourceWithMetadata,
} from './paymentSourceConditionals';

interface CardInfo {
  id: string;
  paymentSourceType: PaymentSourceType;
  accessType: CreditCardAccessType;
  metadata: PaymentSourceMetadata;
  isCardExpired: boolean;
  isCentralCard: boolean;
  isDisabled: boolean;
}

export const getPaymentSourceCardInfo = (paymentSource: BookingPaymentSource): CardInfo => {
  let metadata: PaymentSourceMetadata = {
    cardTitle: '',
    cardCompany: '',
    cardExpireDate: '',
    cardLogo: '',
    maskedCardNumber: '',
    cardType: '',
  };
  const accessType = paymentSource.paymentAccess?.creditCardAccess?.accessType ?? CreditCardAccessType.UnknownType;
  const isCentralCard = accessType !== CreditCardAccessType.Personal;

  if (isCardPaymentSourceWithMetadata(paymentSource)) {
    const { cardMetadata } = paymentSource.metadata;
    metadata = extractCardMetadata(cardMetadata, accessType);
  }

  if (isVendorProgramPaymentWithVendorDescription(paymentSource)) {
    const { vendorProgramPaymentMetadata } = paymentSource.metadata.vendorProgramPaymentDescriptor;

    if ('airlineProgram' in vendorProgramPaymentMetadata) {
      metadata = extractAirlineProgramMetadata(vendorProgramPaymentMetadata.airlineProgram?.airlineProgramMetadata);
    } else if ('directBilling' in vendorProgramPaymentMetadata) {
      metadata = extractDirectBillingMetadata(paymentSource.metadata.vendorProgramPaymentDescriptor);
    }
  }

  if (isVirtualCardPaymentSourceWithMetadata(paymentSource)) {
    const { virtualCardMetadata } = paymentSource.metadata;
    metadata = extractVirtualCardMetadata(virtualCardMetadata);
  }
  if (isCustomVirtualPaymentSourceWithMetadata(paymentSource)) {
    const { virtualPaymentMetadata } = paymentSource.metadata.customPaymentMethodMetadata;
    metadata = extractCustomVirtualCardMetadata(virtualPaymentMetadata);
  }

  if (isCustomPaymentWithBrexBudgetMetadata(paymentSource)) {
    const { brexBudgetMetadata } = paymentSource.metadata.customPaymentMethodMetadata;
    metadata = extractBrexBudgetPaymentMetadata(brexBudgetMetadata);
  }

  if (isRewardPaymentWithPoints(paymentSource)) {
    const { rewardsProgramMetadata } = paymentSource.metadata;
    if ('type' in rewardsProgramMetadata && 'pointsBalance' in rewardsProgramMetadata) {
      metadata = extractRewardsProgramMetadata(rewardsProgramMetadata);
    }
  }

  const isCardExpired = metadata && isBefore(metadata.cardExpireDate, getTodayDateTime('MM/YY'), 'MM/YY');

  return {
    id: paymentSource.id,
    paymentSourceType: paymentSource.type,
    accessType,
    metadata,
    isCardExpired,
    isCentralCard,
    isDisabled: isCardExpired,
  };
};
