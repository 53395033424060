import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $trimTextContentFromAnchor } from '@lexical/selection';
import { $restoreEditorState } from '@lexical/utils';
import { $getRoot, $getSelection, $isRangeSelection, EditorState, RootNode } from 'lexical';
import { FC, useEffect } from 'react';
import { remaining_characters } from './styles';

interface IProps {
  id: string;
  maxLength: number;
}

/**
 * This plugin is heavily referenced from the playground plugin MaxLengthPlugin
 * https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/MaxLengthPlugin/index.tsx
 *
 * Copying over playground plugins is suggested by lexical's collaborator
 * https://github.com/facebook/lexical/discussions/2406#discussioncomment-2984725
 *
 * This plugin prevents the user from entering more characters than the specified length
 */
const MaxLengthPlugin: FC<IProps> = ({ id, maxLength }) => {
  const [editor] = useLexicalComposerContext();
  /**
   * Used to display character limit. Known issue that lexical considers new paragraph as 2 characters. Shift
   * enter will be a linebreak instead and be counted as only 1 character.
   */
  const textCount = editor.read(() => $getRoot().getTextContentSize());

  useEffect(() => {
    let lastRestoredEditorState: EditorState | null = null;

    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }
      const prevEditorState = editor.getEditorState();
      const prevTextContentSize = prevEditorState.read(() => rootNode.getTextContentSize());
      const textContentSize = rootNode.getTextContentSize();

      if (prevTextContentSize !== textContentSize) {
        const delCount = textContentSize - maxLength;
        const { anchor } = selection;

        if (delCount > 0) {
          // Restore the old editor state instead if the last text content was already at the limit.
          if (prevTextContentSize === maxLength && lastRestoredEditorState !== prevEditorState) {
            lastRestoredEditorState = prevEditorState;
            $restoreEditorState(editor, prevEditorState);
          } else {
            $trimTextContentFromAnchor(editor, anchor, delCount);
          }
        }
      }
    });
  }, [editor, maxLength]);

  return (
    <div css={remaining_characters} aria-live="polite" id={id}>
      {textCount}/{maxLength}
    </div>
  );
};

export default MaxLengthPlugin;
