/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ElectricVehicle } from './electric-vehicle';
import { LimoVendorInfo } from './limo-vendor-info';
import { LimoAmenities } from './limo-amenities';

/**
 * Limo details.
 * @export
 * @interface LimoDetails
 */
export interface LimoDetails {
  /**
   * Limo type.
   * @type {string}
   * @memberof LimoDetails
   */
  carType: LimoDetailsCarTypeEnum | number;
  /**
   *
   * @type {ElectricVehicle}
   * @memberof LimoDetails
   */
  electricVehicle: ElectricVehicle;
  /**
   * Vendor name of the limo.
   * @type {string}
   * @memberof LimoDetails
   * @deprecated
   */
  vendorName?: string;
  /**
   *
   * @type {LimoVendorInfo}
   * @memberof LimoDetails
   */
  limoVendorInfo?: LimoVendorInfo;
  /**
   *
   * @type {LimoAmenities}
   * @memberof LimoDetails
   */
  amenities?: LimoAmenities;
}

/**
 * @export
 * @enum {string}
 */
export enum LimoDetailsCarTypeEnum {
  UnknownCarType = 'UNKNOWN_CAR_TYPE',
  StandardCar = 'STANDARD_CAR',
  ExecutiveCar = 'EXECUTIVE_CAR',
  BusinessVan = 'BUSINESS_VAN',
  FirstClass = 'FIRST_CLASS',
  SUV = 'SUV',
  Sedan = 'SEDAN',
  ExecutiveSedan = 'EXECUTIVE_SEDAN',
  LuxurySedan = 'LUXURY_SEDAN',
  ExecutiveVan = 'EXECUTIVE_VAN',
  BusinessSedan = 'BUSINESS_SEDAN',
}

export enum LimoCarTypeEnum {
  UnknownCarType = 0,
  StandardCar = 1,
  ExecutiveCar = 2,
  BusinessVan = 3,
  FirstClass = 4,
  SUV = 5,
  Sedan = 6,
  ExecutiveSedan = 7,
  LuxurySedan = 8,
  ExecutiveVan = 9,
  BusinessSedan = 10,
}
