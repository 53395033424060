import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const CompanyApprovalsPage = lazy(() => import('./approvals/CompanyApprovalsPage'));
const ApproverApprovalsPage = lazy(() => import('./approvals/ApproverApprovalsPage'));
const TripDetailPage = lazy(() => import('./TripDetailPage/TripDetailPage'));

const FlightBookingImporter = lazy(() => import('./bookingImporter/flightsBookingImporterPage'));
const HotelBookingImporter = lazy(() => import('./bookingImporter/hotelBookingImporterPage'));
const CarBookingImporter = lazy(() => import('./bookingImporter/carsBookingImporterPage'));
const RailBookingImporter = lazy(() => import('./bookingImporter/railsBookingImporterPage'));
const LimoBookingImporter = lazy(() => import('./bookingImporter/limoBookingImporterPage'));
const MiscellaneousBookingImporter = lazy(() => import('./bookingImporter/miscellaneousBookingImporterPage'));
const TripConfigPage = lazy(() => import('../events/SpecialityDesk/components/TripConfig/TripConfig'));

const manualFormRoutes: IRouteConfig[] = [
  {
    key: 'import-flight-booking',
    path: '/trips/:tripId/booking-importer/flights',
    component: FlightBookingImporter,
    exact: true,
  },
  {
    key: 'imported-flight-booking-edit',
    path: '/trips/:tripId/booking-importer/flights/:pnrId/edit',
    component: FlightBookingImporter,
    exact: true,
  },
  {
    key: 'import-hotel-booking',
    path: '/trips/:tripId/booking-importer/hotels',
    component: HotelBookingImporter,
    exact: true,
  },
  {
    key: 'imported-hotel-booking-edit',
    path: '/trips/:tripId/booking-importer/hotels/:pnrId/edit',
    component: HotelBookingImporter,
  },
  {
    key: 'import-car-booking',
    path: '/trips/:tripId/booking-importer/cars',
    component: CarBookingImporter,
    exact: true,
  },
  {
    key: 'imported-car-booking-edit',
    path: '/trips/:tripId/booking-importer/car/:pnrId/edit',
    component: CarBookingImporter,
    exact: true,
  },
  {
    key: 'import-rail-booking',
    path: '/trips/:tripId/booking-importer/rails',
    component: RailBookingImporter,
    exact: true,
  },
  {
    key: 'imported-rail-booking-edit',
    path: '/trips/:tripId/booking-importer/rails/:pnrId/edit',
    component: RailBookingImporter,
    exact: true,
  },
  {
    key: 'import-limo-booking',
    path: '/trips/:tripId/booking-importer/limo',
    component: LimoBookingImporter,
    exact: true,
  },
  {
    key: 'imported-limo-booking-edit',
    path: '/trips/:tripId/booking-importer/limo/:pnrId/edit',
    component: LimoBookingImporter,
    exact: true,
  },
  {
    key: 'import-miscellaneous-booking',
    path: '/trips/:tripId/booking-importer/miscellaneous',
    component: MiscellaneousBookingImporter,
    exact: true,
  },
  {
    key: 'imported-miscellaneous-booking-edit',
    path: '/trips/:tripId/booking-importer/miscellaneous/:pnrId/edit',
    component: MiscellaneousBookingImporter,
    exact: true,
  },
];

export const tripsRoutes: IRouteConfig[] = [
  {
    key: 'trips-v3',
    path: '/trips/v3/:tripId',
    component: TripDetailPage,
  },
  {
    key: 'approval-dashboard',
    path: '/approvals',
    component: ApproverApprovalsPage,
    search: '/:type(pending|history)?',
  },
  {
    key: 'company-approval-dashboard',
    path: '/company/approvals',
    component: CompanyApprovalsPage,
    search: '/:type(pending|history)?',
  },
  {
    key: 'create-trip',
    path: '/trips/create',
    component: TripConfigPage,
  },

  ...manualFormRoutes,
];
