import type { TFunction } from 'react-i18next';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import type { PolicyConstValue } from '@spotnana/types/openapi/models/policy-const-value';
import type { Preference, PreferredType } from '../types/api/v1/obt/common/common';

import type { IPolicyInfo } from '../types/flight';
import type { IPreventBooking } from '../types/policy';
import type { PreferredTypeV2, PreferenceV2, Money } from '../types/api/v2/obt/model';
import { TravelClassHierarchy } from '../types/api/v2/obt/model/travel-class-hierarchy';
import { defineMessage } from '../translations/defineMessage';
import { MoneyUtil } from './Money';
import { localeCurrencyFormat } from './common';
import type { PnrPnrPolicyInfo } from '../types/api/v1/obt/pnr/pnr';

export const getPreventBookingInfoFromPolicyInfo = (policyInfo?: IPolicyInfo | PnrPnrPolicyInfo): IPreventBooking => {
  const policyPrevention = first(
    (policyInfo?.ruleResultInfos ?? [])
      .map((rule) => first(rule.actions.filter((a) => a.preventBooking?.prevent))?.preventBooking)
      .filter(Boolean),
  );

  if (policyPrevention?.prevent) {
    return policyPrevention;
  }

  return {
    prevent: false,
    reason: '',
  };
};

export const getVendorPreferences = (preferredType?: PreferredType[], preference?: Preference[]): PreferredType[] => {
  if (preference?.length) {
    return preference.map((p) => p.preferredType);
  }
  return preferredType ?? [];
};

export const getVendorPreferencesV2 = (
  preferredType?: PreferredTypeV2[],
  preference?: PreferenceV2[],
): PreferredTypeV2[] => {
  if (preference?.length) {
    return preference.map((p) => p.preferredType);
  }
  return preferredType ?? [];
};

const railTravelClassV2Labels = {
  [TravelClassHierarchy.Business]: defineMessage('Business'),
  [TravelClassHierarchy.BusinessPremium]: defineMessage('Business premium'),
  [TravelClassHierarchy.First]: defineMessage('First'),
  [TravelClassHierarchy.Sleeper]: defineMessage('Sleeper'),
  [TravelClassHierarchy.Standard]: defineMessage('Standard'),
  [TravelClassHierarchy.StandardPremium]: defineMessage('Standard premium'),
  [TravelClassHierarchy.Coach]: defineMessage('Coach'),
  [TravelClassHierarchy.Unknown]: '',
} as const;

const isInTravelClassLabels = (
  value: TravelClassHierarchy | undefined,
): value is keyof typeof railTravelClassV2Labels => {
  return value !== undefined && value in railTravelClassV2Labels;
};

export const getViolationStringV2 = ({
  value,
  rounding = 'ceil',
  t,
}: {
  value?: PolicyConstValue;
  rounding?: 'ceil' | 'floor' | 'round';
  t: TFunction<'COMMON'>;
}): string | number | JSX.Element => {
  if (!value) {
    return '';
  }
  if ('s' in value && value.s) {
    return value.s;
  }
  if ('i' in value && value.i) {
    return value.i.toString();
  }
  if ('d' in value && value.d) {
    return value.d.toString();
  }
  if ('sList' in value && value.sList) {
    return value.sList.join(', ');
  }
  if ('iList' in value && value.iList) {
    return value.iList.join(', ');
  }
  if ('dList' in value && value.dList) {
    return value.dList.join(', ');
  }
  if ('money' in value && value.money) {
    // Money interface is modified in v2, needs to be type casted to support spotnana types
    const moneyInstance = MoneyUtil.convertV2MoneyToMoneyUtil(value.money as Money);
    return localeCurrencyFormat(Math[rounding](moneyInstance.getAmount()), moneyInstance.getCurrency());
  }
  if ('rating' in value && value.rating) {
    // we use start rating in app, star text is needed for email template
    return t('{{count}} star', { count: value.rating });
  }
  if ('railTravelClass' in value && !!value.railTravelClass && isInTravelClassLabels(value.railTravelClass)) {
    return t(railTravelClassV2Labels[value.railTravelClass as TravelClassHierarchy]);
  }
  if ('percentage' in value && !isNil(value.percentage)) {
    return `${value.percentage}%`;
  }
  return '';
};
