export enum TelemetrySource {
  WEB = 'Web',
  APP = 'App',
}

export enum TelemetryType {
  User = 'User',
  Error = 'Error',
  Event = 'Event',
  Surface = 'Surface',
}

export enum TelemetryEvents {
  ERROR = 'Error',
  BACK_TO_SEARCH_CLICKED = 'Back To Search Clicked',
  RAILS_SEARCH = 'Rails - Searched',
  RAILS_CHECKOUT = 'Rails - Checked Out',
  RAILS_APPLY_FILTERS = 'Rails - Filters Applied',
  RAILS_FILTERS_CHANGES = 'Rails - Filters Changes',
  RAILS_VIEW_JOURNEY = 'Rails - Rail Viewed',
  RAILS_SELECT = 'Rails - Rail Selected',
  RAILS_BOOKED = 'Rails - Rail Booked',
  RAILS_SEAT_RESERVATION = 'Rails - Seat Reservation',
  RAILS_SEAT_PREFERENCES = 'Rails - Seat Preferences',
  RAILS_VIEW_FARE_DETAILS = 'Rails - Fare Details Viewed',
  BOOKING_FEEDBACK = 'Booking feedback left',
  FLIGHT_CREDITS_CLICKED_COLLAPSED = 'Flight Credits - Collapse Clicked',
  FLIGHT_CREDITS_CLICKED_EXPANDED = 'Flight Credits - Expanded Clicked',
  FLIGHT_SEARCH = 'Flight Searched',
  FLIGHT_VIEW = 'Flight Viewed',
  FLIGHT_SELECT = 'Flight Selected',
  FLIGHT_SEAT_SELECTED = 'Flight Seat Selected',
  FLIGHT_SEARCH_SCROLL = 'Flight Results Scrolled',
  FLIGHT_CHECKOUT_APPLY_CREDIT_CLICKED = 'Flight Checkout - Apply Credit Clicked',
  FLIGHT_CHECKOUT_REMOVE_CREDIT_CLICKED = 'Flight Checkout - Remove Credit Clicked',
  FLIGHT_CHECKOUT = 'Flight Checked Out',
  FLIGHT_BOOKED = 'Flight Booked',
  FLIGHT_FARE_CHANGE = 'Flight Fare Changed',
  FLIGHT_FILTERS = 'Flight Filters Changed',
  FLIGHT_FILTERS_CHANGES = 'Flight Filters Changes',
  FLIGHT_EXCHANGE_BOOKING = 'Flight Exchange Booking',
  FLIGHT_EXCHANGE_NO_RESULTS = 'Flight Exchange - No Results',
  FLIGHT_EXCHANGE_ATTEMPTED = 'Flight Exchange Attempted',
  FLIGHT_EXCHANGE_SUCCESSFUL = 'Flight Exchange Successful',
  FLIGHT_SORT = 'Flight Sorting Changed',
  FLIGHT_TICKET_TYPE_SELECTED = 'Flight Ticket Type Selected',
  FLIGHT_SEPARATE_VS_MULTI_TICKET_RESULT = 'Flight Separate vs Multi Ticket Result',
  FLIGHT_SHOW_FULL_FARE = 'Flight - Full fare',
  FLIGHT_FARE_RULES_AND_AMENITIES_CLICKED = 'Flight - Fare Rules And Amenities Clicked',
  FLIGHT_OUT_OF_POLICY_LABEL_CLICKED = 'Flight - Out Of Policy Label Clicked',
  FLIGHT_BACK_TO_ANCILLARY_CLICKED = 'Flight - Back To Ancillary Clicked',
  FLIGHT_LOYALTY_CONFIRMATION_MODAL_MOUNTED = 'Flight - Loyalty Confirmation Modal Mounted',
  FLIGHT_SELECT_SEAT_CLICKED = 'Flight - Select Seat Clicked',
  FLIGHT_SELECT_SEAT_DEAD_CLICK = 'Flight - Seat Selection Dead Click',
  FLIGHT_TRIP_SELECTION_DEAD_CLICK = 'Flight - Trip Selection Dead Click',
  FLIGHT_PAYMENT_ADD_NEW_CARD_CLICKED = 'Flight - Add New Card Clicked',
  FLIGHT_EDIT_TRAVELER_CLICKED = 'Flight - Edit Traveler Clicked',
  FLIGHT_PREBOOK_QUESTIONS_REPORT = 'Flight - Prebook Questions Data Report',
  FLIGHT_UNUSED_CREDITS_REPORT = 'Flight - Unused Credits Data Report',
  FLIGHT_LOYALTY_VALIDATION_ERROR = 'Flight - Loyalty Validation Error',
  FLIGHT_SSR_SELECTED = 'Flight - SSR Selected',
  FLIGHT_BAGGAGE_SELECTED = 'Flight - Baggage Selected',
  FLIGHT_SEATMAP_AVAILABLE_REPORT = 'Flight - Seatmap Available Report',
  FLIGHT_BAGGAGE_AVAILABLE_REPORT = 'Flight - Baggage Available Report',
  FLIGHT_SSR_OSI_AVAILABLE_REPORT = 'Flight - SSR/OSI Available Report',
  FLIGHT_AIR_BOOKING_INITIATED = 'Flight - Air Booking Initiated',
  HOTEL_SEARCH = 'Hotel Searched',
  HOTEL_SEARCH_CRITERIA_UPDATE = 'Hotel Search Criteria Updated',
  HOTEL_SEARCH_SCROLL = 'Hotel Results Scrolled',
  HOTEL_SEARCH_EXPAND_SEARCH_AREA = 'Hotel Search Expand Search Area Clicked',
  HOTEL_SEARCH_EDIT_SEARCH_CRITERIA = 'Hotel Search Edit Search Criteria Clicked',
  HOTEL_SELECT = 'Hotel Selected',
  HOTEL_ROOM_FILTERS = 'Hotel Room Filters Changed',
  HOTEL_ROOM_SELECT = 'Hotel Room Selected',
  HOTEL_CHECKOUT = 'Hotel Checked Out',
  HOTEL_FARE_CHANGE = 'Hotel Fare Changed',
  HOTEL_MAP = 'Hotel Map Changed',
  HOTEL_BOOKED = 'Hotel Booked',
  // Event for modify flow initiated
  HOTEL_MODIFY_FLOW_INITIATED = 'Hotel Modify Flow Initiated',
  // Event for dates changed
  HOTEL_MODIFY_FLOW_DATES_CHANGED = 'Hotel Modify Dates Changed',
  // Event for same rate selected
  HOTEL_MODIFY_FLOW_SAME_RATE_SELECTED = 'Hotel Modify with Same Room Rate',
  // Event for different rate selected
  HOTEL_MODIFY_FLOW_DIFFERENT_RATE_SELECTED = 'Hotel Modify with Different Room Rate',
  // Event for continue to checkout
  HOTEL_MODIFY_FLOW_CONTINUE_TO_CHECKOUT = 'Modifying hotel and continue to checkout',
  // Checked out (new modification confirmed)
  HOTEL_MODIFY_FLOW_CHECKOUT = 'Hotel Modified',
  LEGAL_ENTITY_CREATE = 'Legal entity created',
  LEGAL_ENTITY_UPDATE = 'Legal entity updated',
  CAR_SEARCH = 'Car - Searched',
  CAR_SEARCH_SCROLL = 'Car - Results Scrolled',
  CAR_CHECKOUT = 'Car - Checked Out',
  CAR_APPLY_FILTERS = 'Car - Filters Applied',
  CAR_FILTERS_CHANGES = 'Car - Filters Changes',
  CAR_SHOW_OUT_OF_POLICY = 'Car - Show Out Of Policy Clicked',
  CAR_APPLY_SORT = 'Car - Sorting Applied',
  CAR_SELECT = 'Car - Car Selected',
  CAR_FARE_CHANGE = 'Car - Fare Changed',
  CAR_BOOKED = 'Car - Car Booked',
  APP_BETA_ACCESS_REQUESTED = 'App Beta Access Requested',
  CREDIT_CARD_VERIFY = 'Credit Card Verification Required',
  CREDIT_CARD_CREATE = 'Credit Card Creation',
  TRIPS_ADD_NEW_TRIP = 'Trips - Add New Trip',
  COMPANY_TRIPS_ADD_NEW_TRIP = 'Company Trips - Add New Trip',
  TRIPS_ADD_TO_TRIP = 'Trips - Add To Trip',
  TRIPS_CANCEL = 'Trips - Cancel Trip',
  TRIPS_CLICK_CANCEL = 'Trips - Cancel Clicked',
  TRIPS_CLICK_CHANGE = 'Trips - Change Clicked',
  TRIPS_EXPAND_DETAILS = 'Trips - Details Expanded',
  AMADEUS_SDK_ON_READY_WITHOUT_QBR_POINTS = 'AMADEUS_SDK_ON_READY_WITHOUT_QBR_POINTS',
  AMADEUS_ONLY_POINT_SELECTOR_RENDERED = 'AMADEUS_ONLY_POINT_SELECTOR_RENDERED',
  AMADEUS_ONLY_CREDIT_CARDS_RENDERED = 'AMADEUS_ONLY_CREDIT_CARDS_RENDERED',
  AMADEUS_ON_CHANGE_INVALID = 'AMADEUS_ON_CHANGE_INVALID',
  TRIPS_EXPAND_ITEM = 'Trips - Item Expanded',
  TRIPS_CLICK_PAYMENT_DETAILS = 'Trips - Payment Details Clicked',
  TRIPS_DETAILS_VIEW = 'Trips - Details Viewed',
  TRIPS_CLICK_SHOW_CANCELLED = 'Trips - Show Cancelled Clicked',
  TRIPS_CLICK_SHOW_INVOICES = 'Trips - Show Invoices Clicked',
  TRIPS_COMMENT_ADDED = 'Trip comment added',
  TRIPS_COMMENT_UPDATED = 'Trip comment updated',
  TRIPS_COMMENT_REMOVED = 'Trip comment removed',
  TRIPS_CANCEL_PAGE_LOADED = 'Trips - Cancel Page Loaded',
  TRIPS_FLIGHT_COMPLETE_BOOKING_CLICKED = 'Trips - Flight Complete Booking Clicked',
  TRIPS_FLIGHT_MODIFY_CLICKED = 'Trips - Flight Modify Clicked',
  TRIPS_FLIGHT_CANCEL_CLICKED = 'Trips - Flight Cancel Clicked',
  TRIPS_FLIGHT_PAYMENT_DETAILS_CLICKED = 'Trips - Flight Payment Details Clicked',
  TRIPS_FLIGHT_ADD_AGENT_TASK_CLICKED = 'Trips - Flight Add Agent Task Clicked',
  TRIPS_FLIGHT_UPLOAD_DOCUMENTS_CLICKED = 'Trips - Flight Upload Documents Clicked',
  TRIPS_FLIGHT_FINALIZE_CLICKED = 'Trips - Flight Finalize Clicked',
  TRIPS_FLIGHT_UNFINALIZE_CLICKED = 'Trips - Flight Unfinalize Clicked',
  TRIPS_FLIGHT_UPDATE_CLICKED = 'Trips - Flight Update Clicked',
  TRIPS_FLIGHT_EDIT_SEAT_CLICKED = 'Trips - Flight Edit Seat Clicked',
  TRIPS_FLIGHT_EDIT_BAGGAGE_CLICKED = 'Trips - Flight Edit Baggage Clicked',
  TRIPS_FLIGHT_REQUEST_UPGRADE_CLICKED = 'Trips - Flight Request Upgrade Clicked',
  TRIPS_FLIGHT_RETRY_PAYMENT_CLICKED = 'Trips - Flight Retry Payment Clicked',
  TRIPS_FLIGHT_BUY_EARLY_BIRD_CHECK_IN_CLICKED = 'Trips - Flight Buy EarlyBird Check-In Clicked',
  TRIPS_FLIGHT_WEB_CHECK_IN_CLICKED = 'Trips - Flight Web Check-In Clicked',
  TRIPS_FLIGHT_UNDO_WEB_CHECK_IN_CLICKED = 'Trips - Flight Undo Web Check-In Clicked',
  TRIPS_FLIGHT_REBOOK_FLIGHT_CLICKED = 'Trips - Flight Book Again Clicked',
  TRIPS_HOTEL_MODIFY_CLICKED = 'Trips - Hotel Modify Clicked',
  TRIPS_HOTEL_CANCEL_CLICKED = 'Trips - Hotel Cancel Clicked',
  TRIPS_HOTEL_PAYMENT_DETAILS_CLICKED = 'Trips - Hotel Payment Details Clicked',
  TRIPS_HOTEL_UPDATE_CLICKED = 'Trips - Hotel Update Clicked',
  TRIPS_HOTEL_UPLOAD_DOCUMENTS_CLICKED = 'Trips - Hotel Upload Documents Clicked',
  TRIPS_HOTEL_ADD_AGENT_TASK_CLICKED = 'Trips - Hotel Add Agent Task Clicked',
  TRIPS_HOTEL_REBOOK_CLICKED = 'Trips - Hotel Book Again Clicked',
  TRIPS_HOTEL_FINALIZE_CLICKED = 'Trips - Hotel Finalize Clicked',
  TRIPS_HOTEL_UNFINALIZE_CLICKED = 'Trips - Hotel Unfinalize Clicked',
  TRIPS_CAR_MODIFY_CLICKED = 'Trips - Car Modify Clicked',
  TRIPS_CAR_CANCEL_CLICKED = 'Trips - Car Cancel Clicked',
  TRIPS_CAR_FINALIZE_CLICKED = 'Trips - Car Finalize Clicked',
  TRIPS_CAR_UNFINALIZE_CLICKED = 'Trips - Car Unfinalize Clicked',
  TRIPS_CAR_PAYMENT_DETAILS_CLICKED = 'Trips - Car Payment Details Clicked',
  TRIPS_CAR_UPDATE_CLICKED = 'Trips - Car Update Clicked',
  TRIPS_CAR_UPLOAD_DOCUMENTS_CLICKED = 'Trips - Car Upload Documents Clicked',
  TRIPS_CAR_ADD_AGENT_TASK_CLICKED = 'Trips - Car Add Agent Task Clicked',
  TRIPS_CANCEL_BUTTON_CLICKED = 'Trips - Cancel Button Clicked',
  TRIPS_DOWNLOAD_ITINERARY_CLICKED = 'Trips - Download Itinerary Clicked',
  TRIPS_SHARE_VIA_EMAIL_CLICKED = 'Trips - Share Via Email Clicked',
  TRIPS_TITLE_CHANGE = 'Trips - Title Changed',
  TRIPS_VIEW = 'Trips - Viewed',
  TRIPS_ACTIVITY_OPENED = 'Trip activity - Opened',
  TRIPS_ACTIVITY_CLOSED = 'Trip activity - Closed',
  TRIPS_ACTIVITY_APPLY_FILTER = 'Trip activity - Filters applied',
  TRIPS_ACTIVITY_ADD_COMMENT = 'Trip activity - Commented on item',
  TRIPS_ACTIVITY_DELETE_COMMENT = 'Trip activity - Deleted item',
  SCREEN_INITIAL_DIMENSIONS = 'Screen Initial Dimensions',
  PCC_CREATE = 'PCC Created',
  PCC_DELETE = 'PCC Deleted',
  PCC_UPDATE = 'PCC Updated',
  PCC_SET_AS_DEFAULT = 'PCC Set As Default',
  PCC_TOGGLE = 'PCC Toggled',
  PCC_SAVE_VALIDATION = 'PCC On Save Validation',
  DOC_CHANGE_ORGANIZATION = 'DoC - Organization Changed',
  DOC_MAP_VIEW = 'DoC Map -  Viewed',
  DOC_MAP_SELECT_TRAVELER = 'DoC Map - Traveler Selected',
  DOC_MAP_CLICK_CONTACT = 'DoC Map - Contact Button Clicked',
  DOC_MAP_CLICK_TRIP = 'DoC Map - Trip Clicked',
  DOC_MAP_SEARCH_LOCATION = 'DoC Map - Search Location',
  DOC_TRAVELER_REPORTS_VIEW = 'DoC Traveler Reports - Viewed',
  DOC_TRAVELER_REPORTS_DOWNLOAD_CSV = 'DoC Traveler Reports - CSV Downloaded',
  DOC_TRAVELER_REPORTS_APPLY_FILTERS = 'DoC Traveler Reports - Filters Applied',
  REPORTS_VIEW = 'Reports - Viewed',
  REPORTS_DOWNLOAD_CSV = 'Reports - CSV Downloaded',
  REPORTS_APPLY_FILTERS = 'Reports - Filters Applied',
  DASHBOARD_VIEW = 'Dashboard - Viewed',
  DASHBOARD_CHANGE_HEADLINES = 'Dashboard - Headlines Changed',
  DASHBOARD_APPLY_FILTERS = 'Dashboard - Filters Applied',
  ADMIN_PREFERRED_VENDORS_HOTELS_ADD = 'Admin - Preferred Vendors - Hotels Added',
  ADMIN_PREFERRED_VENDORS_HOTELS_CHANGE_ORDER = 'Admin - Preferred Vendors - Hotels Changed Order',
  ADMIN_PREFERRED_VENDORS_HOTELS_SELECT = 'Admin - Preferred Vendors - Hotels Selected',
  ADMIN_PREFERRED_VENDORS_CARS_ADD = 'Admin - Preferred Vendors - Cars Added',
  ADMIN_PREFERRED_VENDORS_CARS_CHANGE_ORDER = 'Admin - Preferred Vendors - Cars Changed Order',
  ADMIN_PREFERRED_VENDORS_CARS_SELECT = 'Admin - Preferred Vendors - Cars Selected',
  ADMIN_PREFERRED_VENDORS_SAVE = 'Admin - Preferred Vendors Saved',
  ADMIN_PREFERRED_VENDORS_CANCEL = 'Admin - Preferred Vendors Cancelled',
  AGENT_DESKTOP_UPDATE_STATUS = 'Agent Desktop - Task Status Updated',
  AGENT_DESKTOP_ASSIGN_AGENT = 'Agent Desktop - Assigned Agent',
  AGENT_DESKTOP_TASK_EXPAND = 'Agent Desktop - Task Expanded',
  AGENT_DESKTOP_COMMENT = 'Agent Desktop - Commented on task',
  AGENT_DESKTOP_STATS_CLICK = 'Agent Desktop - Clicked on stats bucket',
  AGENT_DESKTOP_APPLY_FILTER = 'Agent Desktop - Filters applied',
  AGENT_DESKTOP_REDIRECTED = 'Agent Desktop - Redirected to home page',
  COMPANION_VIEW_ACTIVE_PAGE = 'Companion view - Active page',
  COMPANION_VIEW_ACTIVE_TAB = 'Companion view - Active tab',
  COMPANION_VIEW_EXPAND = 'Companion view - Expanded status',
  COMPANION_VIEW_SEARCH_PROFILE = 'Companion view - Search profile',
  COMPANION_VIEW_ADD_NOTE = 'Companion view - Note added',
  COMPANION_VIEW_DELETE_NOTE = 'Companion view - Note deleted',
  BOOKING_IMPORTERS_HOTELS_ADD_BOOKING = 'Booking Importer - Hotels Add Booking',
  BOOKING_IMPORTERS_HOTELS_FORM_ERRORS = 'Booking Importer - Hotels Form Errors',
  BOOKING_IMPORTERS_FLIGHTS_ADD_BOOKING = 'Booking Importer - Flights Add Booking',
  BOOKING_IMPORTERS_FLIGHTS_FORM_ERRORS = 'Booking Importer - Flights Form Errors',
  BOOKING_IMPORTERS_CARS_ADD_BOOKING = 'Booking Importer - Cars Add Booking',
  BOOKING_IMPORTERS_CARS_FORM_ERRORS = 'Booking Importer - Cars Form Errors',
  BOOKING_IMPORTERS_RAILS_ADD_BOOKING = 'Booking Importer - Rails Add Booking',
  BOOKING_IMPORTERS_RAILS_FORM_ERRORS = 'Booking Importer - Rails Form Errors',
  BOOKING_IMPORTERS_LIMO_ADD_BOOKING = 'Booking Importer - Limo Add Booking',
  BOOKING_IMPORTERS_LIMO_FORM_ERRORS = 'Booking Importer - Limo Form Errors',
  BOOKING_IMPORTERS_MISCELLANEOUS_ADD_BOOKING = 'Booking Importer - Miscellaneous Add Booking',
  BOOKING_IMPORTERS_MISCELLANEOUS_FORM_ERRORS = 'Booking Importer - Miscellaneous Form Errors',
  BOOKING_IMPORTERS_CHARGE_TRAVELER = 'Booking Importer - Charge Traveler',
  SHELL_PNR_CREATE = 'Shell Pnr - Create',
  SHELL_PNR_FORM_ERRORS = 'Shell Pnr - Form Errors',
  PROFILE_PERSONAL_SAVE_CHANGES = 'Profile personal - Save Changes',
  PROFILE_PERSONAL_CANCEL_CHANGES = 'Profile personal - Cancel Changes',
  PROFILE_PERSONAL_CHANGED_FIELDS = 'Profile personal - Changed Fields',
  PROFILE_AIR_PREFERENCES_SAVE_CHANGES = 'Profile Air Preferences - Save Changes',
  PROFILE_AIR_PREFERENCES_CANCEL_CHANGES = 'Profile Air Preferences - Cancel Changes',
  PROFILE_AIR_PREFERENCES_CHANGED_FIELDS = 'Profile Air Preferences - Changed Fields',
  PROFILE_LOYALTY_ADD_PROGRAM = 'Profile Loyalty - Add Program',
  PROFILE_LOYALTY_DELETE_PROGRAM = 'Profile Loyalty - Delete Program',
  PROFILE_LOYALTY_CHANGE_PROGRAM = 'Profile Loyalty - Change Program',
  PROFILE_LOYALTY_PROGRAM_FORM_ERRORS = 'Profile Loyalty - Program From Errors',
  PROFILE_PAYMENT_ADD_CARD = 'Profile Payment - Add Card',
  PROFILE_PAYMENT_DELETE_CARD = 'Profile Payment - Delete Card',
  PROFILE_PAYMENT_UNMASK_CARD = 'Profile Payment - Unmask Card',
  PROFILE_PERSONAL_UPLOAD_PHOTO = 'Profile personal - Upload Photo',
  PROFILE_PERSONAL_CHANGE_TITLE = 'Profile personal - Change Title',
  PROFILE_PERSONAL_MARK_AS_SEAT_ONE = 'Profile personal - Mark As Seat One',
  PROFILE_PERSONAL_CHANGE_GENDER = 'Profile personal - Change Gender',
  PROFILE_PERSONAL_CHANGE_PRONOUN = 'Profile personal - Change Pronoun',
  PROFILE_PERSONAL_CHANGE_DOB = 'Profile personal - Change Date of Birth',
  PROFILE_PERSONAL_CHANGE_MOBILE = 'Profile personal - Change Mobile',
  PROFILE_PERSONAL_MOBILE_FORM_ERRORS = 'Profile personal - Mobile Form Errors',
  PROFILE_PERSONAL_ADD_MOBILE = 'Profile personal - Add Mobile',
  PROFILE_PERSONAL_DELETE_MOBILE = 'Profile personal - Delete Mobile',
  PROFILE_PERSONAL_CHANGE_ADDRESS = 'Profile personal - Change Address',
  PROFILE_PERSONAL_ADDRESS_FORM_ERRORS = 'Profile personal - Address Form Errors',
  PROFILE_PERSONAL_ADD_ADDRESS = 'Profile personal - Add Address',
  PROFILE_PERSONAL_DELETE_ADDRESS = 'Profile personal - Delete Address',
  PROFILE_PERSONAL_CHANGE_PASSPORT = 'Profile personal - Change Passport',
  PROFILE_PERSONAL_PASSPORT_FORM_ERRORS = 'Profile personal - Passport Form Errors',
  PROFILE_PERSONAL_ADD_PASSPORT = 'Profile personal - Add Passport',
  PROFILE_PERSONAL_DELETE_PASSPORT = 'Profile personal - Delete Passport',
  PROFILE_PERSONAL_CHANGE_KNT = 'Profile personal - Change Known Traveler Number',
  PROFILE_PERSONAL_KNT_FORM_ERRORS = 'Profile personal - Known Traveler Number Form Errors',
  PROFILE_PERSONAL_ADD_KNT = 'Profile personal - Add Known Traveler Number',
  PROFILE_PERSONAL_DELETE_KNT = 'Profile personal - Delete Known Traveler Number',
  PROFILE_PERSONAL_CHANGE_REDRESS_NUMBER = 'Profile personal - Change Redress Number',
  PROFILE_PERSONAL_REDRESS_NUMBER_FORM_ERRORS = 'Profile personal - Redress Number Form Errors',
  PROFILE_PERSONAL_ADD_REDRESS_NUMBER = 'Profile personal - Add Redress Number',
  PROFILE_PERSONAL_DELETE_REDRESS_NUMBER = 'Profile personal - Delete Redress Number',
  PROFILE_PERSONAL_CHANGE_EMERGENCY_CONTACT = 'Profile personal - Change Emergency Contact',
  PROFILE_PERSONAL_EMERGENCY_CONTACT_FORM_ERRORS = 'Profile personal - Emergency Contact Form Errors',
  PROFILE_PERSONAL_ADD_EMERGENCY_CONTACT = 'Profile personal - Add Emergency Contact',
  PROFILE_PERSONAL_DELETE_EMERGENCY_CONTACT = 'Profile personal - Delete Emergency Contact',
  PROFILE_PERSONAL_ADD_NOTE = 'Profile personal - Note added',
  PROFILE_PERSONAL_DELETE_NOTE = 'Profile personal - Note deleted',
  CUSTOM_FIELD_CREATED = 'Custom Field Created',
  CUSTOM_FIELD_UPDATED = 'Custom Field Updated',
  CUSTOM_FIELD_TOGGLED = 'Custom Field Toggled',
  CUSTOM_FIELD_DELETED = 'Custom Field Deleted',
  THOUGHTSPOT_REPORTS_VIEW = 'Thoughtspot Reports - View',
  THOUGHTSPOT_REPORTS_APPLY_FILTERS = 'Thoughtspot Reports - Apply Filters',
  VIRTUAL_PAYMENT_METHOD_CREATED = 'Virtual Payment Method Created',
  VIRTUAL_PAYMENT_METHOD_UPDATED = 'Virtual Payment Method Updated',
  VIRTUAL_PAYMENT_METHOD_DELETED = 'Virtual Payment Method Deleted',
  TRIPS_VIRTUAL_CARD_REVEAL = 'Trips Virtual Card Revealed',
  TRIPS_VIRTUAL_CARD_INFO_RESENT = 'Trips Virtual Card Info Resent',
  TRIPS_VIRTUAL_CARD_INFO_UPDATED = 'Trips Virtual Card Info Updated',
  TRANSLATION_ERROR_CODE_MISSING = 'Translation Error Code Missing',
  VIEW_TRAVEL_POLICY = 'View Travel Policy',
  OPEN_SUPPORT_CHAT = 'Open support chat',
  MISSING_ERROR_CODE = 'Missing Error Code',
  SHARE_LINK_PRESSED = 'Share link pressed',
  SERVICE_FEE_PER_CURRENCY_UPDATE = 'Service Fee Per Currency Update',
  SERVICE_FEE_PREFERENCES_UPDATE = 'Service Fee Preferences Update',
  ROUTE_CHANGE = 'Route Change',
  TRIPS_FLIGHT_SPLIT_PNR_CLICKED = 'Trips - Flight Split Pnr Clicked',
  TRIPS_CHARGE_AGENT_FEE_CLICKED = ' Trips - Charge Agent Fee Clicked',
  TNC_CONSENT = 'Terms of Service - User Action',
  ACCEPT_FLIGHT_RECOMMENDATION = 'ACCEPT_FLIGHT_RECOMMENDATION',

  /**
   * ************************************************************
   * EVENTS/RECRUITMENT feature start
   * ************************************************************
   */
  EVENTS_NAVBAR_LINK_CLICKED = 'Events - Navbar Link Clicked',
  EVENTS_NEW_EVENT_CLICKED = 'Events - New Event Clicked',
  EVENTS_EVENT_PUBLISHED = 'Events - Event Published',
  EVENTS_PREVIEW_EMAIL = 'Events - Preview Email Clicked',
  EVENTS_TRAVELERS_ADDED = 'Events - Traveler(s) Added',
  EVENTS_TRAVELERS_REMOVED = 'Events - Traveler(s) Removed',
  EVENTS_IMAGE_SELECTED = 'Events - Image Selected',
  EVENTS_PAYMENT_IS_SPLIT = 'Events - Payment Split',
  EVENTS_PAYMENT_IS_NON_DEFAULT = 'Events - Payment Not Default',
  EVENTS_AIR_BOOKING_CREATED = 'Events - Booking Created (Flight)',
  EVENTS_HOTEL_BOOKING_CREATED = 'Events - Booking Created (Hotel)',
  EVENTS_CAR_BOOKING_CREATED = 'Events - Booking Created (Car)',
  EVENTS_RAIL_BOOKING_CREATED = 'Events - Booking Created (Rail)',
  EVENTS_HAS_ALLOWED_AIRPORTS = 'Events - Has Allowed Airport Options',
  EVENTS_HAS_ALLOWED_HOTELS = 'Events - Has Allowed Hotel Options',

  /**
   * ************************************************************
   * EVENTS/RECRUITMENT feature end
   * ************************************************************
   */
}

export enum TelemetryErrors {
  BACK_TO_SEARCH_CLICKED_ERROR = 'BACK_TO_SEARCH_CLICKED_ERROR',
  PROVIDER_ERROR = 'TELEMETRY_PROVIDER_ERROR',
  CREDIT_CARD_ERROR = 'CREDIT_CARD_TELEMETRY_FAILED',
  RAILS_SEARCH_ERROR = 'RAILS_SEARCH_ERROR',
  RAILS_CHECKOUT_ERROR = 'RAILS_CHECKOUT_ERROR',
  RAILS_APPLY_FILTERS_ERROR = 'RAILS_APPLY_FILTERS_ERROR',
  RAILS_FILTERS_CHANGES_ERROR = 'RAILS_FILTERS_CHANGES_ERROR',
  RAILS_SELECT_ERROR = 'RAILS_SELECT_ERROR',
  RAILS_VIEW_JOURNEY_ERROR = 'RAILS_VIEW_JOURNEY_ERROR',
  RAILS_BOOKED_ERROR = 'RAILS_BOOKED_ERROR',
  RAILS_SEAT_RESERVATION_ERROR = 'RAILS_SEAT_RESERVATION_ERROR',
  RAILS_SEAT_PREFERENCES_ERROR = 'RAILS_SEAT_PREFERENCES_ERROR',
  RAILS_VIEW_FARE_DETAILS_ERROR = 'RAILS_VIEW_FARE_DETAILS_ERROR',
  CARS_CHECKOUT_ERROR = 'CARS_VIEW_TELEMETRY_FAILED',
  CAR_APPLY_FILTERS_ERROR = 'CAR_APPLY_FILTERS_TELEMETRY_FAILED',
  CAR_SHOW_OUT_OF_POLICY_ERROR = 'CAR_SHOW_OUT_OF_POLICY_TELEMETRY_FAILED',
  CAR_APPLY_SORT_ERROR = 'CAR_APPLY_SORT_TELEMETRY_FAILED',
  CAR_FILTERS_CHANGES_ERROR = 'CAR_FILTERS_CHANGES_TELEMETRY_FAILED',
  CAR_SELECT_ERROR = 'CAR_SELECT_TELEMETRY_FAILED',
  CAR_FARE_CHANGE_ERROR = 'CAR_FARE_CHANGE_TELEMETRY_FAILED',
  CAR_BOOKED_ERROR = 'CAR_BOOKED_TELEMETRY_FAILED',
  FLIGHT_CREDITS_CLICKED_COLLAPSED_ERROR = 'FLIGHT_CREDITS_CLICKED_COLLAPSED_ERROR',
  FLIGHT_CREDITS_CLICKED_EXPANDED_ERROR = 'FLIGHT_CREDITS_CLICKED_EXPANDED_ERROR',
  FLIGHT_SEARCH_ERROR = 'FLIGHT_VIEW_TELEMETRY_FAILED',
  FLIGHT_SELECT_ERROR = 'FLIGHT_SELECT_TELEMETRY_FAILED',
  FLIGHT_SEAT_ERROR = 'FLIGHT_SEAT_TELEMETRY_FAILED',
  FLIGHT_CHECKOUT_APPLY_CREDIT_CLICKED_ERROR = 'FLIGHT_CHECKOUT_APPLY_CREDIT_CLICKED_ERROR',
  FLIGHT_CHECKOUT_REMOVE_CREDIT_CLICKED_ERROR = 'FLIGHT_CHECKOUT_REMOVE_CREDIT_CLICKED_ERROR',
  FLIGHT_FARE_RULES_AND_AMENITIES_CLICKED_ERROR = 'FLIGHT_FARE_RULES_AND_AMENITIES_CLICKED_ERROR',
  FLIGHT_OUT_OF_POLICY_LABEL_CLICKED_ERROR = 'FLIGHT_OUT_OF_POLICY_LABEL_CLICKED_ERROR',
  FLIGHT_BACK_TO_ANCILLARY_CLICKED_ERROR = 'FLIGHT_BACK_TO_ANCILLARY_CLICKED_ERROR',
  FLIGHT_LOYALTY_CONFIRMATION_MODAL_MOUNTED_ERROR = 'FLIGHT_LOYALTY_CONFIRMATION_MODAL_MOUNTED_ERROR',
  FLIGHT_SELECT_SEAT_CLICKED_ERROR = 'FLIGHT_SELECT_SEAT_CLICKED_ERROR',
  FLIGHT_SELECT_SEAT_DEAD_CLICK_ERROR = 'FLIGHT_SELECT_SEAT_DEAD_CLICK_ERROR',
  FLIGHT_TRIP_SELECTION_DEAD_CLICK_ERROR = 'FLIGHT_TRIP_SELECTION_DEAD_CLICK_ERROR',
  FLIGHT_PAYMENT_ADD_NEW_CARD_CLICKED_ERROR = 'FLIGHT_PAYMENT_ADD_NEW_CARD_CLICKED_ERROR',
  FLIGHT_EDIT_TRAVELER_CLICKED_ERROR = 'FLIGHT_EDIT_TRAVELER_CLICKED_ERROR',
  FLIGHT_PREBOOK_QUESTION_DATA_REPORT_ERROR = 'FLIGHT_PREBOOK_QUESTION_DATA_REPORT_ERROR',
  FLIGHT_UNUSED_CREDITS_REPORT_ERROR = 'FLIGHT_UNUSED_CREDITS_REPORT_ERROR',
  FLIGHT_LOYALTY_VALIDATION_ERROR_TELEMETRY_ERROR = 'FLIGHT_LOYALTY_VALIDATION_ERROR_TELEMETRY_ERROR',
  FLIGHT_SSR_SELECTED_ERROR = 'FLIGHT_SSR_SELECTED_ERROR',
  FLIGHT_BAGGAGE_SELECTED_ERROR = 'FLIGHT_BAGGAGE_SELECTED_ERROR',
  FLIGHT_SEATMAP_AVAILABLE_REPORT_ERROR = 'FLIGHT_SEATMAP_AVAILABLE_REPORT_ERROR',
  FLIGHT_BAGGAGE_AVAILABLE_REPORT_ERROR = 'FLIGHT_BAGGAGE_AVAILABLE_REPORT_ERROR',
  FLIGHT_SSR_OSI_AVAILABLE_REPORT_ERROR = 'FLIGHT_SSR_OSI_AVAILABLE_REPORT_ERROR',
  FLIGHT_CHECKOUT_ERROR = 'FLIGHT_CHECKOUT_TELEMETRY_FAILED',
  FLIGHT_BOOK_ERROR = 'FLIGHT_BOOKED_TELEMETRY_FAILED',
  FLIGHT_FILTERS_ERROR = 'FLIGHT_FILTERS_FAILED',
  FLIGHT_FILTERS_CHANGES_ERROR = 'FLIGHT_FILTERS_CHANGES_ERROR',
  FLIGHT_SORT_ERROR = 'FLIGHT_SORT_FAILED',
  FLIGHT_TICKET_TYPE_SELECTED_ERROR = 'FLIGHT_TICKET_TYPE_SELECTED_ERROR',
  FLIGHT_FULL_FARE_ERROR = 'FLIGHT_FULL_FARE_ERROR',
  HOTEL_SEARCH_ERROR = 'HOTEL_SEARCH_TELEMETRY_FAILED',
  HOTEL_SEARCH_SCROLL_ERROR = 'HOTEL_SEARCH_SCROLL_TELEMETRY_FAILED',
  HOTEL_SELECT_ERROR = 'HOTEL_SELECT_TELEMETRY_FAILED',
  HOTEL_ROOM_FILTERS_ERROR = 'HOTEL_ROOM_FILTERS_TELEMETRY_FAILED',
  HOTEL_ROOM_SELECT_ERROR = 'HOTEL_ROOM_SELECT_TELEMETRY_FAILED',
  HOTEL_CHECKOUT_ERROR = 'HOTEL_CHECKOUT_TELEMETRY_FAILED',
  HOTEL_FARE_CHANGE_ERROR = 'HOTEL_FARE_CHANGE_TELEMETRY_FAILED',
  HOTEL_MAP_ERROR = 'HOTEL_MAP_TELEMETRY_FAILED',
  HOTEL_BOOKED_ERROR = 'HOTEL_BOOKED_TELEMETRY_FAILED',
  SCREEN_INITIAL_DIMENSIONS_ERROR = 'SCREEN_INITIAL_DIMENSIONS_ERROR',
  TRIPS_ADD_NEW_TRIP_ERROR = 'TRIPS_ADD_NEW_TRIP_FAILED',
  TRIPS_ADD_TO_TRIP_ERROR = 'TRIPS_ADD_TO_TRIP_FAILED',
  TRIPS_CANCEL_ERROR = 'TRIPS_CANCEL_FAILED',
  TRIPS_CLICK_CANCEL_ERROR = 'TRIPS_CLICK_CANCEL_FAILED',
  TRIPS_CLICK_CHANGE_ERROR = 'TRIPS_CLICK_CHANGE_FAILED',
  TRIPS_EXPAND_DETAILS_ERROR = 'TRIPS_EXPAND_DETAILS_FAILED',
  AMADEUS_SDK_ON_READY_WITHOUT_QBR_POINTS_ERROR = 'AMADEUS_SDK_ON_READY_WITHOUT_QBR_POINTS_ERROR',
  AMADEUS_ONLY_POINT_SELECTOR_RENDERED_ERROR = 'AMADEUS_ONLY_POINT_SELECTOR_RENDERED_ERROR',
  AMADEUS_ONLY_CREDIT_CARDS_RENDERED_ERROR = 'AMADEUS_ONLY_CREDIT_CARDS_RENDERED_ERROR',
  TRIPS_EXPAND_ITEM_ERROR = 'TRIPS_EXPAND_ITEM_FAILED',
  TRIPS_CLICK_PAYMENT_DETAILS_ERROR = 'TRIPS_CLICK_PAYMENT_DETAILS_FAILED',
  TRIPS_DETAILS_VIEW_ERROR = 'TRIPS_DETAILS_VIEW_FAILED',
  TRIPS_CLICK_SHOW_CANCELLED_ERROR = 'TRIPS_CLICK_SHOW_CANCELLED_FAILED',
  TRIPS_TITLE_CHANGE_ERROR = 'TRIPS_TITLE_CHANGE_FAILED',
  TRIPS_VIEW_ERROR = 'TRIPS_VIEW_FAILED',
  TRIPS_ACTIVITY_CLICK_ERROR = 'TRIPS_ACTIVITY_CLICK_ERROR',
  TRIPS_ACTIVITY_APPLY_FILTER_ERROR = 'TRIP_ACTIVITY_APPLY_FILTER_ERROR',
  TRIPS_ACTIVITY_ADD_COMMENT_ERROR = 'TRIPS_ACTIVITY_ADD_COMMENT_ERROR',
  TRIPS_ACTIVITY_DELETE_COMMENT_ERROR = 'TRIPS_ACTIVITY_DELETE_COMMENT_ERROR',
  TRIPS_CARD_FLIGHT_ACTION_ERROR = 'TRIPS_CARD_FLIGHT_ACTION_ERROR',
  TRIPS_CLICK_SHOW_INVOICES_ERROR = 'TRIPS_CLICK_SHOW_INVOICES_ERROR',
  PCC_CREATE_ERROR = 'PCC_CREATE_ERROR',
  PCC_DELETE_ERROR = 'PCC_DELETE_ERROR',
  PCC_UPDATE_ERROR = 'PCC_UPDATE_ERROR',
  PCC_SET_AS_DEFAULT_ERROR = 'PCC_SET_AS_DEFAULT_ERROR',
  PCC_TOGGLE_ERROR = 'PCC_TOGGLE_ERROR',
  PCC_SAVE_VALIDATION_ERROR = 'PCC_SAVE_VALIDATION_ERRORS_ERROR',
  DOC_CHANGE_ORGANIZATION_ERROR = ' DOC_CHANGE_ORGANIZATION_ERROR',
  DOC_MAP_VIEW_ERROR = 'DOC_MAP_VIEW_ERROR',
  DOC_MAP_SELECT_TRAVELER_ERROR = 'DOC_MAP_SELECT_TRAVELER_ERROR',
  DOC_MAP_CLICK_CONTACT_ERROR = 'DOC_MAP_CLICK_CONTACT_ERROR',
  DOC_MAP_CLICK_TRIP_ERROR = 'DOC_MAP_CLICK_TRIP_ERROR',
  DOC_MAP_SEARCH_LOCATION_ERROR = 'DOC_MAP_SEARCH_LOCATION_ERROR',
  DOC_TRAVELER_REPORTS_VIEW_ERROR = 'DOC_TRAVELER_REPORTS_VIEW_ERROR',
  DOC_TRAVELER_REPORTS_DOWNLOAD_CSV_ERROR = 'DOC_TRAVELER_REPORTS_DOWNLOAD_CSV_ERROR',
  DOC_TRAVELER_REPORTS_APPLY_FILTERS_ERROR = 'DOC_TRAVELER_REPORTS_APPLY_FILTERS_ERROR',
  REPORTS_VIEW_ERROR = 'REPORTS_VIEW_ERROR',
  REPORTS_DOWNLOAD_CSV_ERROR = 'REPORTS_DOWNLOAD_CSV_ERROR',
  REPORTS_APPLY_FILTERS_ERROR = 'REPORTS_APPLY_FILTERS_ERROR',
  DASHBOARD_VIEW_ERROR = 'DASHBOARD_VIEW_ERROR',
  DASHBOARD_CHANGE_HEADLINES_ERROR = 'DASHBOARD_CHANGE_HEADLINES_ERROR',
  DASHBOARD_APPLY_FILTERS_ERROR = 'DASHBOARD_APPLY_FILTERS_ERROR',
  ADMIN_PREFERRED_VENDORS_HOTELS_ADD_ERROR = 'ADMIN_PREFERRED_VENDORS_HOTELS_ADD_ERROR',
  ADMIN_PREFERRED_VENDORS_HOTELS_CHANGE_ORDER_ERROR = 'ADMIN_PREFERRED_VENDORS_HOTELS_CHANGE_ORDER_ERROR',
  ADMIN_PREFERRED_VENDORS_HOTELS_SELECT_ERROR = 'ADMIN_PREFERRED_VENDORS_HOTELS_SELECT_ERROR',
  ADMIN_PREFERRED_VENDORS_CARS_ADD_ERROR = 'ADMIN_PREFERRED_VENDORS_CARS_ADD_ERROR',
  ADMIN_PREFERRED_VENDORS_CARS_CHANGE_ORDER_ERROR = 'ADMIN_PREFERRED_VENDORS_CARS_CHANGE_ORDER_ERROR',
  ADMIN_PREFERRED_VENDORS_CARS_SELECT_ERROR = 'ADMIN_PREFERRED_VENDORS_CARS_SELECT_ERROR',
  ADMIN_PREFERRED_VENDORS_SAVE_ERROR = 'ADMIN_PREFERRED_VENDORS_SAVE_ERROR',
  ADMIN_PREFERRED_VENDORS_CANCEL_ERROR = 'ADMIN_PREFERRED_VENDORS_CANCEL_ERROR',
  AGENT_DESKTOP_UPDATE_STATUS_ERROR = 'AGENT_DESKTOP_UPDATE_STATUS_ERROR',
  AGENT_DESKTOP_ASSIGN_AGENT_ERROR = 'AGENT_DESKTOP_ASSIGN_AGENT_ERROR',
  AGENT_DESKTOP_TASK_EXPAND_ERROR = 'AGENT_DESKTOP_TASK_EXPAND_ERROR',
  AGENT_DESKTOP_COMMENT_ERROR = 'AGENT_DESKTOP_COMMENT_ERROR',
  AGENT_DESKTOP_STATS_CLICK_ERROR = 'AGENT_DESKTOP_STATS_CLICK_ERROR',
  AGENT_DESKTOP_APPLY_FILTER_ERROR = 'AGENT_DESKTOP_APPLY_FILTER_ERROR',
  COMPANION_VIEW_ACTIVE_PAGE_ERROR = 'COMPANION_VIEW_ACTIVE_PAGE_ERROR',
  COMPANION_VIEW_EXPAND_ERROR = 'COMPANION_VIEW_EXPAND_ERROR',
  COMPANION_VIEW_ACTIVE_TAB_ERROR = 'COMPANION_VIEW_ACTIVE_TAB_ERROR',
  COMPANION_VIEW_SEARCH_PROFILE_ERROR = 'COMPANION_VIEW_SEARCH_PROFILE_ERROR',
  COMPANION_VIEW_ADD_NOTE_ERROR = 'COMPANION_VIEW_ADD_NOTE_ERROR',
  COMPANION_VIEW_DELETE_NOTE_ERROR = 'COMPANION_VIEW_DELETE_NOTE_ERROR',
  BOOKING_IMPORTERS_HOTELS_ADD_BOOKING_ERROR = 'BOOKING_IMPORTERS_HOTELS_ADD_BOOKING_ERROR',
  BOOKING_IMPORTERS_HOTELS_FORM_ERRORS_ERROR = 'BOOKING_IMPORTERS_HOTELS_FORM_ERRORS_ERROR',
  BOOKING_IMPORTERS_LIMO_ADD_BOOKING_ERROR = 'BOOKING_IMPORTERS_LIMO_ADD_BOOKING_ERROR',
  BOOKING_IMPORTERS_LIMO_FORM_ERRORS_ERROR = 'BOOKING_IMPORTERS_LIMO_FORM_ERRORS_ERROR',
  BOOKING_IMPORTERS_MISCELLANEOUS_ADD_BOOKING_ERROR = 'BOOKING_IMPORTERS_MISCELLANEOUS_ADD_BOOKING_ERROR',
  BOOKING_IMPORTERS_MISCELLANEOUS_FORM_ERRORS_ERROR = 'BOOKING_IMPORTERS_MISCELLANEOUS_FORM_ERRORS_ERROR',
  BOOKING_IMPORTERS_FLIGHTS_ADD_BOOKING_ERROR = 'BOOKING_IMPORTERS_FLIGHTS_ADD_BOOKING_ERROR',
  BOOKING_IMPORTERS_FLIGHTS_FORM_ERRORS_ERROR = 'BOOKING_IMPORTERS_FLIGHTS_FORM_ERRORS_ERROR',
  BOOKING_IMPORTERS_CARS_ADD_BOOKING_ERROR = 'BOOKING_IMPORTERS_CARS_ADD_BOOKING_ERROR',
  BOOKING_IMPORTERS_CARS_FORM_ERRORS_ERROR = 'BOOKING_IMPORTERS_CARS_FORM_ERRORS_ERROR',
  BOOKING_IMPORTERS_RAILS_ADD_BOOKING_ERROR = 'BOOKING_IMPORTERS_RAILS_ADD_BOOKING_ERROR',
  BOOKING_IMPORTERS_RAILS_FORM_ERRORS_ERROR = 'BOOKING_IMPORTERS_RAILS_FORM_ERRORS_ERROR',
  BOOKING_IMPORTERS_CHARGE_TRAVELER_ERROR = 'BOOKING_IMPORTERS_CHARGE_TRAVELER_ERROR',
  SHELL_PNR_CREATE_ERROR = 'SHELL_PNR_CREATE_ERROR',
  SHELL_PNR_FORM_ERRORS_ERROR = 'SHELL_PNR_FORM_ERRORS_ERROR',
  PROFILE_PERSONAL_SAVE_CHANGES_ERROR = 'PROFILE_PERSONAL_SAVE_CHANGES_ERROR',
  PROFILE_PERSONAL_CANCEL_CHANGES_ERROR = 'PROFILE_PERSONAL_CANCEL_CHANGES_ERROR',
  PROFILE_PERSONAL_CHANGED_FIELDS_ERROR = 'PROFILE_PERSONAL_CHANGED_FIELDS_ERROR',
  PROFILE_AIR_PREFERENCES_SAVE_CHANGES_ERROR = 'PROFILE_AIR_PREFERENCES_SAVE_CHANGES_ERROR',
  PROFILE_AIR_PREFERENCES_CANCEL_CHANGES_ERROR = 'PROFILE_AIR_PREFERENCES_CANCEL_CHANGES_ERROR',
  PROFILE_AIR_PREFERENCES_CHANGED_FIELDS_ERROR = 'PROFILE_AIR_PREFERENCES_CHANGED_FIELDS_ERROR',
  PROFILE_LOYALTY_ADD_PROGRAM_ERROR = 'PROFILE_LOYALTY_ADD_PROGRAM_ERROR',
  PROFILE_LOYALTY_DELETE_PROGRAM_ERROR = 'PROFILE_LOYALTY_DELETE_PROGRAM_ERROR',
  PROFILE_LOYALTY_CHANGE_PROGRAM_ERROR = 'PROFILE_LOYALTY_CHANGE_PROGRAM_ERROR',
  PROFILE_LOYALTY_PROGRAM_FORM_ERRORS_ERROR = 'PROFILE_LOYALTY_PROGRAM_FORM_ERRORS_ERROR',
  PROFILE_PAYMENT_ADD_CARD_ERROR = 'PROFILE_PAYMENT_ADD_CARD_ERROR',
  PROFILE_PAYMENT_DELETE_CARD_ERROR = 'PROFILE_PAYMENT_DELETE_CARD_ERROR',
  PROFILE_PAYMENT_UNMASK_CARD_ERROR = 'PROFILE_PAYMENT_UNMASK_CARD_ERROR',
  PROFILE_PERSONAL_UPLOAD_PHOTO_ERROR = 'PROFILE_PERSONAL_UPLOAD_PHOTO_ERROR',
  PROFILE_PERSONAL_CHANGE_TITLE_ERROR = 'PROFILE_PERSONAL_CHANGE_TITLE_ERROR',
  PROFILE_PERSONAL_MARK_AS_SEAT_ONE_ERROR = 'PROFILE_PERSONAL_MARK_AS_SEAT_ONE_ERROR',
  PROFILE_PERSONAL_CHANGE_GENDER_ERROR = 'PROFILE_PERSONAL_CHANGE_GENDER_ERROR',
  PROFILE_PERSONAL_CHANGE_PRONOUN_ERROR = 'PROFILE_PERSONAL_CHANGE_PRONOUN_ERROR',
  PROFILE_PERSONAL_CHANGE_DOB_ERROR = 'PROFILE_PERSONAL_CHANGE_DOB_ERROR',
  PROFILE_PERSONAL_CHANGE_MOBILE_ERROR = 'PROFILE_PERSONAL_CHANGE_MOBILE_ERROR',
  PROFILE_PERSONAL_MOBILE_FORM_ERRORS_ERROR = 'PROFILE_PERSONAL_MOBILE_FORM_ERRORS_ERROR',
  PROFILE_PERSONAL_ADD_MOBILE_ERROR = 'PROFILE_PERSONAL_ADD_MOBILE_ERROR',
  PROFILE_PERSONAL_DELETE_MOBILE_ERROR = 'PROFILE_PERSONAL_DELETE_MOBILE_ERROR',
  PROFILE_PERSONAL_CHANGE_ADDRESS_ERROR = 'PROFILE_PERSONAL_CHANGE_ADDRESS_ERROR',
  PROFILE_PERSONAL_ADDRESS_FORM_ERRORS_ERROR = 'PROFILE_PERSONAL_ADDRESS_FORM_ERRORS_ERROR',
  PROFILE_PERSONAL_ADD_ADDRESS_ERROR = 'PROFILE_PERSONAL_ADD_ADDRESS_ERROR',
  PROFILE_PERSONAL_DELETE_ADDRESS_ERROR = 'PROFILE_PERSONAL_DELETE_ADDRESS_ERROR',
  PROFILE_PERSONAL_CHANGE_PASSPORT_ERROR = 'PROFILE_PERSONAL_CHANGE_PASSPORT_ERROR',
  PROFILE_PERSONAL_PASSPORT_FORM_ERRORS_ERROR = 'PROFILE_PERSONAL_PASSPORT_FORM_ERRORS_ERROR',
  PROFILE_PERSONAL_ADD_PASSPORT_ERROR = 'PROFILE_PERSONAL_ADD_PASSPORT_ERROR',
  PROFILE_PERSONAL_DELETE_PASSPORT_ERROR = 'PROFILE_PERSONAL_DELETE_PASSPORT_ERROR',
  PROFILE_PERSONAL_CHANGE_KNT_ERROR = 'PROFILE_PERSONAL_CHANGE_KNT_ERROR',
  PROFILE_PERSONAL_KNT_FORM_ERRORS_ERROR = 'PROFILE_PERSONAL_KNT_FORM_ERRORS_ERROR',
  PROFILE_PERSONAL_ADD_KNT_ERROR = 'PROFILE_PERSONAL_ADD_KNT_ERROR',
  PROFILE_PERSONAL_DELETE_KNT_ERROR = 'PROFILE_PERSONAL_DELETE_KNT_ERROR',
  PROFILE_PERSONAL_CHANGE_REDRESS_NUMBER_ERROR = 'PROFILE_PERSONAL_CHANGE_REDRESS_NUMBER_ERROR',
  PROFILE_PERSONAL_REDRESS_NUMBER_FORM_ERRORS_ERROR = 'PROFILE_PERSONAL_REDRESS_NUMBER_FORM_ERRORS_ERROR',
  PROFILE_PERSONAL_ADD_REDRESS_NUMBER_ERROR = 'PROFILE_PERSONAL_ADD_REDRESS_NUMBER_ERROR',
  PROFILE_PERSONAL_DELETE_REDRESS_NUMBER_ERROR = 'PROFILE_PERSONAL_DELETE_REDRESS_NUMBER_ERROR',
  PROFILE_PERSONAL_CHANGE_EMERGENCY_CONTACT_ERROR = 'PROFILE_PERSONAL_CHANGE_EMERGENCY_CONTACT_ERROR',
  PROFILE_PERSONAL_EMERGENCY_CONTACT_FORM_ERRORS_ERROR = 'PROFILE_PERSONAL_EMERGENCY_CONTACT_FORM_ERRORS_ERROR',
  PROFILE_PERSONAL_ADD_EMERGENCY_CONTACT_ERROR = 'PROFILE_PERSONAL_ADD_EMERGENCY_CONTACT_ERROR',
  PROFILE_PERSONAL_DELETE_EMERGENCY_CONTACT_ERROR = 'PROFILE_PERSONAL_DELETE_EMERGENCY_CONTACT_ERROR',
  PROFILE_PERSONAL_ADD_NOTE_ERROR = 'PROFILE_PERSONAL_ADD_NOTE_ERROR',
  PROFILE_PERSONAL_DELETE_NOTE_ERROR = 'PROFILE_PERSONAL_DELETE_NOTE_ERROR',
  THOUGHTSPOT_REPORTS_VIEW_ERROR = 'THOUGHTSPOT_REPORTS_VIEW_ERROR',
  THOUGHTSPOT_REPORTS_APPLY_FILTERS_ERROR = 'THOUGHTSPOT_REPORTS_APPLY_FILTERS_ERROR',
  TRANSLATION_ERROR_CODE_MISSING_ERROR = 'TRANSLATION_ERROR_CODE_MISSING_ERROR',
  OPEN_SUPPORT_CHAT_ERROR = 'OPEN_SUPPORT_CHAT_ERROR',
  MISSING_ERROR_CODE_ERROR = 'MISSING_ERROR_CODE_ERROR',
  VIEW_TRAVEL_POLICY_ERROR = 'VIEW_TRAVEL_POLICY_ERROR',
  TNC_CONSENT_ERROR = 'TNC_CONSENT_ERROR',
  ACCEPT_FLIGHT_RECOMMENDATION_ERROR = 'ACCEPT_FLIGHT_RECOMMENDATION_ERROR',
  PNR_UNFINALIZE_ERROR = 'PNR_UNFINALIZE_ERROR',
}
