import type { DateTime } from '../../common/date_time';
import type { Money } from '../../common/money';
import type { TMCInfo } from '../../common/tmc';
import type { UserId } from '../../common/user_id';
import type { Cabin, FlightNumber, POS } from '../air/air_common';
import type { Vendor, Location } from '../car/car_common';
import type { PaginationParams } from '../common/paginationParams';
import type { Traveler } from '../common/traveler';
import type { UserBasicInfo } from '../common/user_basic_info';
import type { CompanyId, UserOrgId } from '../common/user_org_id';
import type { AirPnrAlertType, AirPnrLegAlertType, PnrStatusV1, PNRType, UserFacingStatus } from '../pnr/pnr';
import type { ThirdPartySource } from '../supplier/third_party_info';
import type { TravelerEventSummary } from '../../event/event';
import { ManualFormDataPolicyInfo } from '../pnr/manual_form';
import { TripUsageMetadata } from '@spotnana/types/openapi/models/trip-usage-metadata';

export enum TripCategoryRequest {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST_OR_COMPLETED',
  CANCELLED = 'CANCELLED',
  DISRUPTED = 'DISRUPTED',
}
export interface ListTripsRequest {
  userOrgId?: UserOrgId;
}

export interface ListTripsResponse {
  trips: ListTripsResponseTripInfo[];
}

export interface ListTripsResponseTripInfo {
  tripId: string;
  name: string;
  description: string;
  /** Start date of the trip */
  startDate?: DateTime;
  /** End date of the trip */
  endDate?: DateTime;
  /** User basic info of the traveler. */
  userBasicInfo?: UserBasicInfo;
  /** Trip related metadata stored in trips table. */
  tripMetadata?: ListTripsResponseTripInfoTripMetadata;
}

export interface ListTripsResponseTripInfoTripMetadata {
  locationInfos: ListTripsResponseTripInfoTripMetadataLocationInfo[];
  /** Denotes the creation date of a trip. */
  creationDate?: DateTime;
  /** The user facing status for this Trip. */
  tripStatus: UserFacingStatus;
  eventSummary?: TravelerEventSummary;
  /** Set to true if any of the PNRs in the trip has more than one pax */
  isMultiPax: boolean;
}

export interface ListTripsResponseTripInfoTripMetadataLocationInfo {
  /** The location name for the booking associated in the trip. */
  locationName: string;
}

export interface TripFilter {
  userFilter?: TripFilterUserFilter | undefined;
  companyFilter?: TripFilterCompanyFilter | undefined;
}

export interface TripFilterUserFilter {
  userId?: UserId;
}

export interface TripFilterCompanyFilter {
  companyId?: CompanyId;
}

export interface ListTripSummariesRequest {
  userOrgId?: UserOrgId;
  /** Set of filters to be applied for the request */
  filters: TripFilter[];
}

export interface ListTripSummariesResponse {
  tripSummaries: ListTripSummariesResponseTripSummary[];
  paginationParams: PaginationParams;
  qcSummary: ListTripSummariesResponseTripQcSummary[];
  failedTripSummaries: ListTripSummariesResponseTripSummary[];
}

export interface ListTripSummariesResponseTripSummary {
  tripId: string;
  name: string;
  description: string;
  pnrSummaries: ListTripSummariesResponsePnrSummary[];
  /** The user facing status for this Trip. */
  tripStatus: UserFacingStatus;
  /** Start date of the trip */
  startDate?: DateTime;
  /** End date of the trip */
  endDate?: DateTime;
  /** User basic info of the traveler. */
  userBasicInfo?: UserBasicInfo;
  /**
   * This will only contain empty shell PNRs. After the segments have
   * been added in the PNRs, they would come up in the pnr_summaries field
   */
  emptyShellPnrSummaries: ListTripSummariesResponsePnrSummary[];
  eventSummary?: TravelerEventSummary;
  tripUsageMetadata?: TripUsageMetadata;
  createdByUserBasicInfo?: UserBasicInfo;
}

export interface ListTripSummariesResponsePnrSummary {
  pnrId: string;
  sourcePnrId: string;
  air?: ListTripSummariesResponseAirPnrSummary | undefined;
  hotel?: ListTripSummariesResponseHotelPnrSummary | undefined;
  car?: ListTripSummariesResponseCarPnrSummary | undefined;
  rail?: ListTripSummariesResponseRailPnrSummary | undefined;
  limo?: ListTripSummariesResponseLimoPnrSummary | undefined;
  misc?: ListTripSummariesResponseMiscPnrSummary | undefined;
  pnrStatus: PnrStatusV1;
  /** The user facing status for this PNR/Booking. */
  bookingStatus: UserFacingStatus;
  /** TMC info related to pnr. */
  tmcInfo?: TMCInfo;
  /** The point of sale, where this itinerary was shopped from. */
  pos?: POS;
  /** The third party source for data */
  source: ThirdPartySource;
  /** Type of pnr like air,hotel,etc. */
  pnrType: PNRType;
  /** This list of travelers who are part of the booking */
  traveler: Traveler[];
  /** All the policy related information about this PNR */
  policyInfo?: ManualFormDataPolicyInfo;
}

export interface ListTripSummariesResponseAirPnrSummary {
  /** @deprecated */
  flights: ListTripSummariesResponseAirPnrSummaryFlight[];
  totalAmount?: Money;
  /** The list of legs present in this PNR/Booking. */
  legInfos: ListTripSummariesResponseAirPnrSummaryLegInfo[];
  flightAlerts?: AirPnrAlertType[];
  legAlerts?: AirPnrLegAlertType[];
}

/** Encapsulates the information and status for a 'leg'. */
export interface ListTripSummariesResponseAirPnrSummaryLegInfo {
  /** The user facing status for the leg. */
  legStatus: UserFacingStatus;
  /** The list of flights in the leg. */
  flights: ListTripSummariesResponseAirPnrSummaryFlight[];
  /** This int sets the sorting priority of the leg to determine its order on the trips page */
  sortingPriority: number;
}

export interface ListTripSummariesResponseAirPnrSummaryFlight {
  origin: string;
  destination: string;
  departureDateTime?: DateTime;
  arrivalDateTime?: DateTime;
  status: PnrStatusV1;
  marketing?: FlightNumber;
  cabin?: Cabin;
  bookingCode?: string;
}

export interface ListTripSummariesResponseHotelPnrSummary {
  name: string;
  city: string;
  fromDate?: DateTime;
  toDate?: DateTime;
  totalAmount?: Money;
  status: PnrStatusV1;
  /** This int sets the sorting priority of the leg to determine its order on the trips page */
  sortingPriority: number;
}

export interface ListTripSummariesResponseCarPnrSummary {
  vehicleName: string;
  vendor?: Vendor;
  fromDate?: DateTime;
  toDate?: DateTime;
  totalAmount?: Money;
  status: PnrStatusV1;
  pickupLocation?: Location;
  dropOffLocation?: Location;
  /** This int sets the sorting priority of the leg to determine its order on the trips page */
  sortingPriority: number;
}

export interface ListTripSummariesResponseLimoPnrLeg {
  dropOffLocation?: Location;
  fromDate: DateTime;
  pickupLocation: Location;
  /** This int sets the sorting priority of the leg to determine its order on the trips page */
  sortingPriority: number;
}

export interface ListTripSummariesResponseLimoPnrSummary {
  vehicleName: string;
  vendor?: Vendor;
  totalAmount?: Money;
  status: PnrStatusV1;
  legs: ListTripSummariesResponseLimoPnrLeg[];
}

export interface ListTripSummariesResponseMiscPnrSummary {
  description: string;
  startDateTime: DateTime;
  endDateTime: DateTime;
  totalAmount?: Money;
  status: PnrStatusV1;
}

export interface ListTripSummariesResponseRailPnrSummary {
  journeys: ListTripSummariesResponseRailPnrSummaryJourney[];
  totalAmount?: Money;
}

export interface ListTripSummariesResponseRailPnrSummaryJourney {
  origin: string;
  destination: string;
  departureDateTime?: DateTime;
  arrivalDateTime?: DateTime;
  status: PnrStatusV1;
  /** This int sets the sorting priority of the leg to determine its order on the trips page */
  sortingPriority: number;
}

export interface ListTripSummariesResponseTripQcSummary {
  tripId: string;
  qcIssueCount: number;
}
