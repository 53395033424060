import type { ListExpensePartnerConfigResponse } from '@spotnana/types/openapi/models/list-expense-partner-config-response';
import { useQuery } from '@tanstack/react-query';
import api from '../../api';
import SpotnanaError from '../../api/SpotnanaError';
import type { SpotnanaQueryResult } from '../../types';
import { defaultQueryClient } from '../defaultQueryClient';

const getExpensePartnerConfigsKey = (organizationId: string): string[] => [
  'v2',
  'expense-partner-config',
  organizationId,
];

const fetchExpensePartnerConfigs = async (organizationId: string): Promise<ListExpensePartnerConfigResponse> => {
  try {
    const result = await api('GET', 'companyBaseUrl', {
      urlParam: `/${organizationId}/expense-partner-config`,
    });
    return result as ListExpensePartnerConfigResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useFetchExpensePartnerConfigs = (
  organizationId: string | undefined,
  enabled = true,
): SpotnanaQueryResult<ListExpensePartnerConfigResponse> =>
  useQuery<ListExpensePartnerConfigResponse, SpotnanaError>({
    queryKey: getExpensePartnerConfigsKey(organizationId ?? ''),
    queryFn: () => fetchExpensePartnerConfigs(organizationId ?? ''),
    enabled: enabled && !!organizationId,
  });

export const invalidateExpensePartnerConfigs = (organizationId: string | undefined): void => {
  defaultQueryClient.invalidateQueries({
    queryKey: getExpensePartnerConfigsKey(organizationId ?? ''),
  });
};
