import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import groupBy from 'lodash/groupBy';
import intersectionWith from 'lodash/intersectionWith';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { dateFormats, emptyTravelerAddress } from '../constants';
import { breakfastRoomAmenities, defaultHotelDetailsFilters } from '../constants/hotels';
import {
  dateUtil,
  dateUtilFormat,
  getDateDiff,
  getFutureDateFromDays,
  getPastDateTimeFromDuration,
  isBefore,
} from '../date-utils';
import type { ITripHotelDetails, PaymentMethod } from '../types';
import { FareTypeEnum, PaymentMethodEnum } from '../types';
import type { DateTime } from '../types/api/v1/common/date_time';
import type { Latlng } from '../types/api/v1/common/latlng';
import type { PostalAddress } from '../types/api/v1/common/postal_address';
import type { Preference, PreferredType } from '../types/api/v1/obt/common/common';
import { PolicyInfoApprovalType } from '../types/api/v1/obt/common/policy_info';
import type { TermsAndConditions } from '../types/api/v1/obt/common/terms_and_conditions';
import type {
  Description,
  HotelAmenity,
  HotelSpecContactInfo,
  ImageSet,
  OccupancyDateParamsOccupancy,
  Rate,
  RateStatistics,
  HotelPaymentMethod,
} from '../types/api/v1/obt/hotel/hotel_common';
import {
  DescriptionType,
  HotelAmenityType,
  HotelDeatilsBedType,
  HotelDetailsRateType,
  HotelRoomAmenitiesMap,
  HotelSpecStarRatingType,
  RateTypeV1,
  FeeType,
  HotelPaymentMethodType,
  GuaranteeType,
} from '../types/api/v1/obt/hotel/hotel_common';
import type {
  AdditionalDetail,
  HotelDetailsResponse,
  RateOption,
  Room,
} from '../types/api/v1/obt/hotel/hotel_details_response';
import {
  AdditionalDetailAdditionalDetailType,
  CancellationPolicyRefundableEnum,
  PrepaidQualifier,
  RateInfoRateSource,
  RoomAmenityRoomAmenityTypeEnum,
} from '../types/api/v1/obt/hotel/hotel_details_response';
import type {
  ICancellationTerm,
  IDateWiseBreakup,
  IHotelDetailFilter,
  IHotelDetailFilterV2,
  IHotelOverView,
  IHotelSummary,
  IOccupancy,
  IRoom,
  ISliderImage,
  MandatoryFees,
  MandatoryFeesBreakdown,
  RateOptionFilters,
  ReservationAmountBreakDown,
  RoomRateOptionSummary,
  RoomSummary,
} from '../types/hotel';
import { HotelRateCodeTypeEnum } from '../types/hotel';
import { MoneyUtil } from '../utils/Money';
import { getPreventBookingInfoFromPolicyInfo, getVendorPreferences } from '../utils/policies';
import { convertRateTypeV1ToFareTypeEnum, feeTypeTitleMapper, getImageUrlAboveThresholdWidth } from '../utils/hotels';
import { getIsFareCorporateNegotiated } from '../utils/negotiatedFares';

const qualifiedRateTypes = [
  RateTypeV1.MILITARY,
  RateTypeV1.SENIOR_CITIZEN,
  RateTypeV1.AAA,
  RateTypeV1.AARP,
  RateTypeV1.GOVERNMENT,
];

export default class HotelDetailsManager {
  constructor(readonly response: HotelDetailsResponse) {
    if (!response) {
      throw new Error('invalid Hotel Details Response passed to HotelDetailsManager');
    }
  }

  public GetTotalBaseAmount = (rateOption: RateOption): MoneyUtil =>
    MoneyUtil.parse(rateOption.rateInfo?.totalRate?.base);

  public GetRateOptionPaymentDescription = (rateOption: RateOption): string[] | undefined =>
    rateOption.paymentDescription;

  public GetTermsAndConditions = (): TermsAndConditions[] | undefined => this.response.termsAndConditions;

  public GetTotalTaxAmount = (rateOption: RateOption, currency: string, originalCurrency: string): MoneyUtil => {
    const taxAmount = rateOption.rateInfo?.totalRate?.tax;
    if (taxAmount) {
      return MoneyUtil.parse(taxAmount);
    }

    const brexAmount = MoneyUtil.parse(taxAmount)?.getBrexPoints();

    const zeroOtherCoinage = brexAmount?.amount ? [{ ...brexAmount, amount: 0 }] : [];

    return MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency, zeroOtherCoinage);
  };

  public GetTotalFees = (rateOption: RateOption): MoneyUtil => {
    const fees = rateOption.rateInfo?.totalRate?.fees ?? [];

    if (fees.length === 0) {
      const brexAmount = MoneyUtil.parse(fees[0]?.amount)?.getBrexPoints();
      const zeroOtherCoinage = brexAmount?.amount ? [{ ...brexAmount, amount: 0 }] : [];
      const currency = this.GetTotalBaseAmount(rateOption).getCurrency();
      const originalCurrency = this.GetTotalBaseAmount(rateOption).getOriginalCurrency();
      return MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency, zeroOtherCoinage);
    }

    const parsedFees = fees.map((fee) => MoneyUtil.parse(fee.amount));

    return parsedFees.reduce((totalFee, currentFee) => totalFee.add(currentFee));
  };

  public GetTotalPrice = (rateOption?: RateOption): MoneyUtil => {
    if (rateOption) {
      const baseAmount = this.GetTotalBaseAmount(rateOption);
      const baseCurrency = baseAmount.getCurrency();
      const baseOriginalCurrency = baseAmount.getOriginalCurrency();

      const taxAmount = this.GetTotalTaxAmount(rateOption, baseCurrency, baseOriginalCurrency);
      const feesAmount = this.GetTotalFees(rateOption);

      const totalAmount = baseAmount.add(taxAmount).add(feesAmount);
      return totalAmount;
    }
    return MoneyUtil.zeroMoney();
  };

  public GetPriceFromRate = (rate: Rate | undefined, currency: string, originalCurrency: string): MoneyUtil => {
    if (!rate) {
      return MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency);
    }

    const { base, tax, fees } = rate;
    const baseAmount = MoneyUtil.parse(base);
    const taxAmount = MoneyUtil.parse(tax);
    const feesAmount =
      fees?.reduce(
        (result, fee) => result.add(MoneyUtil.parse(fee.amount)),
        MoneyUtil.zeroMoneyWithOriginal(baseAmount.getCurrency(), baseAmount.getOriginalCurrency()),
      ) ?? MoneyUtil.zeroMoneyWithOriginal(baseAmount.getCurrency(), baseAmount.getOriginalCurrency());

    return baseAmount.add(taxAmount).add(feesAmount);
  };

  public GetPublishedTotalPriceOrTotalDifference = (
    rateOption?: RateOption,
    getTotalPriceDifference = false,
  ): MoneyUtil => {
    if (!rateOption) {
      return MoneyUtil.zeroMoney();
    }

    const base = this.GetTotalBaseAmount(rateOption);
    const baseCurrency = base.getCurrency();
    const baseOriginalCurrency = base.getOriginalCurrency();

    return this.GetPriceFromRate(
      getTotalPriceDifference ? rateOption.rateInfo.rateDifference : rateOption.rateInfo.publishedRate,
      baseCurrency,
      baseOriginalCurrency,
    );
  };

  public GetPublishedNightlyPrice = (rateOption?: RateOption): MoneyUtil => {
    if (!rateOption) {
      return MoneyUtil.zeroMoney();
    }

    const base = this.GetTotalBaseAmount(rateOption);
    const baseCurrency = base.getCurrency();
    const baseOriginalCurrency = base.getOriginalCurrency();

    const showOnlyBaseFare = this.getShowOnlyBaseFare();

    return showOnlyBaseFare
      ? MoneyUtil.parse(rateOption.rateInfo.publishedNightlyRate?.base)
      : this.GetPriceFromRate(rateOption.rateInfo.publishedNightlyRate, baseCurrency, baseOriginalCurrency);
  };

  public GetNegotiatedSavings = (rateOption?: RateOption): MoneyUtil => {
    if (!rateOption) {
      return MoneyUtil.zeroMoney();
    }

    const publishedTotalPrice = this.GetPublishedTotalPriceOrTotalDifference(rateOption);
    const totalPrice = this.GetTotalPrice(rateOption);

    return publishedTotalPrice.subtract(totalPrice);
  };

  public GetCancellationChargesOld(rateOption: RateOption): {
    cancellationCharges: MoneyUtil;
    refundAmount: MoneyUtil;
  } {
    const totalFare = this.GetTotalPrice(rateOption);
    /** refundable will always we there in cancellation policy so removed optinal */
    const isRateOptionRefundable = rateOption.cancellationPolicy.refundable === CancellationPolicyRefundableEnum.TRUE;
    const cancellationTerms = this.GetRoomOptionCancellationTerms(rateOption);
    const currency = totalFare.getCurrency();
    const originalCurrency = totalFare.getOriginalCurrency();
    const zeroMoney = MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency);
    const destinationFee = this.GetTotalFees(rateOption);
    const cancellationChargesWithoutDestinationFee = totalFare.subtract(destinationFee);
    if (!isRateOptionRefundable) {
      return {
        cancellationCharges: cancellationChargesWithoutDestinationFee,
        refundAmount: zeroMoney,
      };
    }
    const isRateOptionCurrentlyNotRefundable =
      rateOption.cancellationPolicy.currentlyRefundable === CancellationPolicyRefundableEnum.FALSE;
    if (isRateOptionCurrentlyNotRefundable) {
      const amount = rateOption.cancellationPolicy.currentlyApplicableTerm?.amount;
      const cancellationCharges = amount ? MoneyUtil.parse(amount) : cancellationChargesWithoutDestinationFee;
      const refundAmount = totalFare.subtract(cancellationCharges);
      return {
        cancellationCharges,
        refundAmount,
      };
    }

    const todaysDate = dateUtilFormat(dateUtil(), dateFormats.ISO);

    let charges: MoneyUtil | undefined;
    cancellationTerms.forEach(({ deadline: { iso8601 }, amount }) => {
      // For comparision using ISO_WO_SECONDS as [ss] are not mandatory to be part of the deadline date(BCD),
      // In case seconds are not present is before date check fails resulting in wrong information.
      if (!isBefore(todaysDate, iso8601, dateFormats.ISO_WO_SECONDS)) {
        if (!charges || charges.getAmount() < amount.getAmount()) {
          charges = amount;
        }
      }
    });

    /**
     * if charges === undefined, implies full refund.
     */
    const cancellationCharges = charges ?? zeroMoney;
    const refundAmount = totalFare.subtract(cancellationCharges);

    return {
      cancellationCharges,
      refundAmount,
    };
  }

  public GetCancellationCharges(rateOption: RateOption): {
    cancellationCharges: MoneyUtil;
    refundAmount: MoneyUtil;
  } {
    const totalFare = this.GetTotalPrice(rateOption);
    /** refundable will always we there in cancellation policy so removed optinal */
    const currency = totalFare.getCurrency();
    const originalCurrency = totalFare.getOriginalCurrency();
    const zeroMoney = MoneyUtil.zeroMoneyWithOriginal(currency, originalCurrency);
    const destinationFee = this.GetTotalFees(rateOption);
    const totalChargesWithoutDestinationFee = totalFare.subtract(destinationFee);

    const isRateOptionCurrentlyNotRefundable =
      rateOption.cancellationPolicy.currentlyRefundable === CancellationPolicyRefundableEnum.FALSE;
    if (isRateOptionCurrentlyNotRefundable) {
      const amount = rateOption.cancellationPolicy.currentlyApplicableTerm?.amount;
      const cancellationCharges = amount ? MoneyUtil.parse(amount) : totalChargesWithoutDestinationFee;
      const refundAmount = totalFare.subtract(cancellationCharges);
      return {
        cancellationCharges,
        refundAmount,
      };
    }

    return {
      cancellationCharges: zeroMoney,
      refundAmount: totalChargesWithoutDestinationFee,
    };
  }

  public GetFareBreakup(rateOption: RateOption): {
    base: MoneyUtil;
    tax: MoneyUtil;
    fees: MoneyUtil;
    mandatoryFees: MandatoryFees;
    nightlyRate: MoneyUtil;
    nightlyBaseRate: MoneyUtil;
    totalDays: number;
  } {
    const baseAmount = this.GetTotalBaseAmount(rateOption);
    const baseCurrency = baseAmount.getCurrency();
    const baseOriginalCurrency = baseAmount.getOriginalCurrency();

    const taxAmount = this.GetTotalTaxAmount(rateOption, baseCurrency, baseOriginalCurrency);
    const feesAmount = this.GetTotalFees(rateOption);
    const mandatoryFees = HotelDetailsManager.GetMandatoryFees(rateOption.rateInfo.totalRate);

    return {
      base: baseAmount,
      tax: taxAmount,
      fees: feesAmount,
      mandatoryFees,
      nightlyRate: HotelDetailsManager.getAverageNightlyRate(
        this.response.occupancyDates?.checkInDate?.iso8601 ?? '',
        this.response.occupancyDates?.checkOutDate?.iso8601 ?? '',
        this.GetTotalPrice(rateOption),
      ),
      nightlyBaseRate: HotelDetailsManager.getAverageNightlyRate(
        this.response.occupancyDates?.checkInDate?.iso8601 ?? '',
        this.response.occupancyDates?.checkOutDate?.iso8601 ?? '',
        baseAmount,
      ),
      totalDays: HotelDetailsManager.GetTotalDays(rateOption),
    };
  }

  private GetAvgNightlyRateAmount(
    rateOption: RateOption,
    baseCurrency: string,
    baseOriginalCurrency: string,
  ): MoneyUtil {
    if (!rateOption) {
      return MoneyUtil.zeroMoneyWithOriginal(baseCurrency, baseOriginalCurrency);
    }

    return this.GetPriceFromRate(rateOption.rateInfo.averageNightlyRate, baseCurrency, baseOriginalCurrency);
  }

  private GetTotalFromRate(rate: Rate | undefined, currency: string, originalCurrency: string): MoneyUtil {
    if (rate?.total) {
      return MoneyUtil.parse(rate.total);
    }
    return this.GetPriceFromRate(rate, currency, originalCurrency);
  }

  public GetReservationAmountBreakDown(rateOption: RateOption): ReservationAmountBreakDown {
    const base = this.GetTotalBaseAmount(rateOption);
    const baseCurrency = base.getCurrency();
    const baseOriginalCurrency = base.getOriginalCurrency();
    const taxesAndFees = this.GetTotalTaxAmount(rateOption, baseCurrency, baseOriginalCurrency);
    const fees = this.GetTotalFees(rateOption);
    const mandatoryFees = HotelDetailsManager.GetMandatoryFees(rateOption.rateInfo.totalRate);
    const avgNightlyRateAmount = this.GetAvgNightlyRateAmount(rateOption, baseCurrency, baseOriginalCurrency);
    const noOfNights = HotelDetailsManager.GetTotalDays(rateOption);

    const total = base.add(taxesAndFees).add(fees);

    const dueNow = this.GetTotalFromRate(rateOption.rateInfo.prepaidRate, baseCurrency, baseOriginalCurrency);
    const dueLater = this.GetTotalFromRate(rateOption.rateInfo.postpaidRate, baseCurrency, baseOriginalCurrency);

    return {
      base,
      taxesAndFees,
      fees,
      avgNightlyRateInfo: {
        amount: avgNightlyRateAmount,
        noOfNights,
      },
      mandatoryFees,
      total,
      dueNow,
      dueLater,
    };
  }

  static GetTotalNights(checkInDate: string, checkOutDate: string): number {
    // we always should have at least 1 night
    return Math.max(getDateDiff(checkInDate, checkOutDate), 1);
  }

  public getFirstCorporateNegotiatedRateIfAvailable = (rooms: Room[]): RateOption | undefined => {
    let negotiatedRateOption;
    rooms?.find(({ rateOptions = [] }) => {
      return rateOptions.find((rateOption) => {
        const { rateType, rateTag } = rateOption.rateInfo;
        const fareType = rateTag ? FareTypeEnum.UNRECOGNIZED : convertRateTypeV1ToFareTypeEnum(rateType);
        const totalPrice = this.GetTotalPrice(rateOption);
        const { checkInDate, checkOutDate } = this.GetOccupancy();
        const averageNightlyPrice = HotelDetailsManager.getAverageNightlyRate(checkInDate, checkOutDate, totalPrice);
        if (
          getIsFareCorporateNegotiated(totalPrice, fareType) &&
          getIsFareCorporateNegotiated(averageNightlyPrice, fareType)
        ) {
          negotiatedRateOption = rateOption;
        }
        return false;
      });
    });
    return negotiatedRateOption;
  };

  private GetHotelShortDescription(): string {
    const hotelDescriptions = this.GetHotelDescriptions();
    const description = first(hotelDescriptions.filter((desc) => desc.type === DescriptionType.GENERAL));
    return description?.value ?? '';
  }

  public GetRateOption(priceValidateKey: string): RateOption | undefined {
    let rateOption: RateOption | undefined;
    this.response.rooms.some((room) => {
      rateOption = room.rateOptions.find((roomRateOption) => roomRateOption.priceValidateKey === priceValidateKey);
      return !!rateOption;
    });
    return rateOption;
  }

  static getAllowedFop(rateOption?: RateOption): PaymentMethod[] {
    return rateOption?.rateInfo.totalRate?.allowedFop ?? [];
  }

  /** This is being used to enable changing the FOP at hotels modify checkout */
  static isFOPModifiable = (rateOption?: RateOption): boolean => rateOption?.isFopModifiable ?? false;

  public GetOccupancy(): IOccupancy {
    const checkInDate = this.response.occupancyDates?.checkInDate?.iso8601 ?? '';
    const checkOutDate = this.response.occupancyDates?.checkOutDate?.iso8601 ?? '';
    const checkInTime = this.GetCheckInTime()?.iso8601 ?? '';
    const checkOutTime = this.GetCheckOutTime()?.iso8601 ?? '';
    return {
      checkInDate,
      checkOutDate,
      checkInTime,
      checkOutTime,
      checkInDateTime: `${checkInDate} ${checkInTime}`,
      checkOutDateTime: `${checkOutDate} ${checkOutTime}`,
    };
  }

  public GetHotelOverView(): IHotelOverView {
    return {
      address: this.GetHotelAddress(),
      contact: this.GetHotelContacts(),
      shortDescription: this.GetHotelShortDescription(),
      sliderImages: this.GetHotelImages(),
    };
  }

  public GetHotelImages(): ISliderImage[] {
    const hotelImageSets: ImageSet[] = this.response.hotelSpec?.imageSets ?? [];
    return HotelDetailsManager.GetSliderImages(hotelImageSets);
  }

  public GetHotelMasterChainCode(): string | undefined {
    return this.response.hotelSpec?.masterChainCode;
  }

  public static GetSliderImages(imageSets: ImageSet[]): ISliderImage[] {
    return imageSets.map((imageSet) => {
      const imageMap: ISliderImage = { original: '', thumbnail: '', caption: '' };
      imageMap.caption = imageSet.imageGroup?.caption ?? '';
      imageMap.original = getImageUrlAboveThresholdWidth({
        imageSet,
        threshold: 1024,
      });
      imageMap.thumbnail = getImageUrlAboveThresholdWidth({
        imageSet,
        threshold: 512,
      });
      return imageMap;
    });
  }

  public GetHotelAmenities(): HotelAmenity[] {
    const additionalAmenities =
      this.response?.hotelSpec?.additionalAmenities?.map((amenity) => ({
        type: HotelAmenityType.UNRECOGNIZED,
        additionalInfo: amenity,
        complimentary: false,
      })) ?? [];

    const amenities = this.response?.hotelSpec?.amenities ?? [];

    /**
     * Before using JSON.stringify as comparator in other places do consider the
     * following issues:
     * 1. Circular References: JSON.stringify cannot handle objects with circular
     *    references, as it will throw an error. Ensure that your object doesn't
     *    contain circular references.
     * 2. Large Objects: Stringify-ing very large objects can lead to performance
     *    issues or memory problems.
     *
     * Both of these issues are not applicable here
     */
    return uniqBy([...amenities, ...additionalAmenities], JSON.stringify);
  }

  /** @deprecated */
  public GetRoomDetails(): Room[] {
    return this.response.rooms;
  }

  public GetHotelAddress(): PostalAddress {
    return this.response.hotelSpec?.address ?? cloneDeep(emptyTravelerAddress);
  }

  private GetHotelContacts(): HotelSpecContactInfo {
    return this.response.hotelSpec?.contactInfo ?? { phone: [], fax: [] };
  }

  public GetHotelName(): string {
    return this.response.hotelSpec?.name ?? '';
  }

  // Uses regex to remove unexpected new lines and weird spaces from badFormat string.
  private removeFormatting = (badFormat: string): string => badFormat.replace(/(?:\s+)/g, ' ').trimEnd();

  public GetHotelDescriptions(): Description[] {
    const descriptions = this.response.hotelSpec?.descriptions ?? [];

    /* BEGIN WARNING: Removing formatting is a temporary solution until we get updated text from backend that doesn't have weird spacing issues */
    const hasGeneralDescription =
      descriptions.filter((description) => description.type === DescriptionType.GENERAL).length > 0;

    if (hasGeneralDescription) {
      // remove formatting from 'general' description only
      descriptions.filter((description) => description.type === DescriptionType.GENERAL)[0].value =
        this.removeFormatting(
          descriptions.filter((description) => description.type === DescriptionType.GENERAL)[0].value,
        );
    }
    /* :END WARNING */

    return descriptions;
  }

  public GetCheckInTime(): DateTime | undefined {
    return this.response.hotelSpec?.checkInTime;
  }

  public GetCheckOutTime(): DateTime | undefined {
    return this.response.hotelSpec?.checkOutTime;
  }

  static isRoomOptionCurrentlyNotRefundable({ cancellationPolicy }: RateOption): boolean {
    return (
      cancellationPolicy.refundable === CancellationPolicyRefundableEnum.TRUE &&
      cancellationPolicy.currentlyRefundable === CancellationPolicyRefundableEnum.FALSE
    );
  }

  static isRoomOptionCurrentlyRefundable({ cancellationPolicy }: RateOption): boolean {
    return cancellationPolicy.currentlyRefundable === CancellationPolicyRefundableEnum.TRUE;
  }

  static isRoomOptionRefundable({ cancellationPolicy }: RateOption): boolean {
    return cancellationPolicy.refundable === CancellationPolicyRefundableEnum.TRUE;
  }

  public static isBookingDotComSource({ rateInfo }: RateOption): boolean {
    return rateInfo?.rateSource === RateInfoRateSource.BOOKING_COM;
  }

  public static isExpediaSource({ rateInfo }: RateOption): boolean {
    return rateInfo?.rateSource === RateInfoRateSource.EXPEDIA;
  }

  public static isRateVPayEnabled({ supportedPaymentMethods }: RateOption): boolean {
    return supportedPaymentMethods?.some(
      (paymentMethod: HotelPaymentMethod) => paymentMethod.type === HotelPaymentMethodType.VPAY_PAYMENT,
    );
  }

  static TotalBedCount({ bedInfo }: RateOption): number {
    return bedInfo.reduce((totalBeds, info) => (totalBeds < info.count ? info.count : totalBeds), 0);
  }

  static AllBedTypes({ bedInfo }: RateOption): number[] {
    return bedInfo.map((info) => info.bedType);
  }

  public GetRoomOptionCancellationTerms(rateOption: RateOption): ICancellationTerm[] {
    const { cancellationPolicy } = rateOption;
    const cancellationTerms: ICancellationTerm[] = [];
    if (cancellationPolicy.refundable === CancellationPolicyRefundableEnum.TRUE) {
      const checkInTime = `${this.GetOccupancy().checkInDate}T${this.GetCheckInTime()?.iso8601 ?? '00:00:00'}`;

      const totalPrice = this.GetTotalPrice(rateOption);
      const { terms } = cancellationPolicy;

      terms?.forEach(({ absoluteDeadline, durationBeforeArrival, description, amount }) => {
        if (absoluteDeadline) {
          cancellationTerms.push({
            deadline: absoluteDeadline,
            description,
            amount: amount ? MoneyUtil.parse(amount) : totalPrice,
          });
        }
        if (durationBeforeArrival) {
          cancellationTerms.push({
            deadline: {
              iso8601: getPastDateTimeFromDuration(durationBeforeArrival.iso8601, checkInTime, dateFormats.ISO),
            },
            description,
            amount: amount ? MoneyUtil.parse(amount) : totalPrice,
          });
        }
      });
    }
    return cancellationTerms;
  }

  public GetMinimumTotalRate(): MoneyUtil {
    const { rooms } = this.response;
    const defaultTotalrate = first(first(rooms)?.rateOptions)?.rateInfo?.totalRate;
    const fees = defaultTotalrate?.fees ?? [];
    const minFees =
      fees.reduce(
        (totalFee, currentFee) => MoneyUtil.parse(currentFee.amount).add(totalFee),
        MoneyUtil.parse(defaultTotalrate?.base),
      ) ?? MoneyUtil.zeroMoney();
    let minimumRate = MoneyUtil.parse(defaultTotalrate?.base).add(MoneyUtil.parse(defaultTotalrate?.tax)).add(minFees);
    rooms.forEach((room) => {
      room.rateOptions.forEach((rateOption) => {
        const totalRate = this.GetTotalPrice(rateOption);
        minimumRate = totalRate.lessThan(minimumRate) ? totalRate : minimumRate;
      });
    });
    return minimumRate;
  }

  public static GetTotalDays = (rateOption: RateOption): number => rateOption.rateInfo?.nightlyRate.length ?? 0;

  public static GetCo2EmissionsValue = (rateOption: RateOption | undefined): number =>
    rateOption?.co2EmissionDetail?.co2EmissionValue ?? 0;

  private GetHotelCoordinates = (): Latlng | undefined => this.response.hotelSpec?.coordinates;

  public GetStarRating = (): number | undefined => this.response.hotelSpec?.starRating;

  public GetLocality = (): string | undefined => this.response.hotelSpec?.address?.locality;

  public GetHotelPreferredType = (): PreferredType[] =>
    getVendorPreferences(this.response?.preferredType, this.response?.preferences);

  public GetHotelPreferences = (): Preference[] => this.response?.preferences || [];

  static getAverageNightlyRate(checkInDate: string, checkOutDate: string, totalAmount: MoneyUtil): MoneyUtil {
    const numberOfDays = HotelDetailsManager.GetTotalNights(checkInDate, checkOutDate);
    return totalAmount.divide(numberOfDays);
  }

  public getHotelSummaryForDetail(): IHotelSummary {
    const { checkInDate, checkOutDate } = this.GetOccupancy();

    return {
      id: this.response.hotelDetailsId || this.response.hotelSpec?.sourceHotelId || '',
      title: this.GetHotelName() ?? '',
      rating: this.GetStarRating() ?? 1,
      ratingType: this.response.hotelSpec?.starRatingType ?? HotelSpecStarRatingType.OFFICIAL,
      chainCode: this.response.hotelSpec?.chainCode,
      chainName: this.response.hotelSpec?.chainName,
      coordinates: this.response.hotelSpec?.coordinates,
      address: this.GetHotelAddress(),
      amenities: this.GetHotelAmenities().map(({ type }) => HotelAmenityType[type]) ?? [''],
      totalAmount: this.GetTotalPrice(first(first(this.response?.rooms)?.rateOptions)),
      totalNights: HotelDetailsManager.GetTotalNights(checkInDate, checkOutDate),
      avgNightlyRate: HotelDetailsManager.getAverageNightlyRate(
        checkInDate,
        checkOutDate,
        this.GetTotalPrice(first(first(this.response?.rooms)?.rateOptions)),
      ),
      position: {
        lat: this.GetHotelCoordinates()?.latitude ?? 0,
        lng: this.GetHotelCoordinates()?.longitude ?? 0,
      },
      imageUrl: this.response.hotelSpec?.imageSets[0]?.imageGroup?.images[0].url ?? '',
      distance: { length: 0, unit: 2 },
      policyViolationMessage: '',
      preferredType: this.GetHotelPreferredType(),
      preferences: this.response.preferences ?? [],
      promotionalOffers: undefined,
      rewardPointsEarned: undefined,
      contactInfo: this.response.hotelSpec?.contactInfo ?? undefined,
    };
  }

  public GetDayWiseRateBreakup(rateOption: RateOption): IDateWiseBreakup[] {
    const nightlyRate = rateOption.rateInfo?.nightlyRate ?? [];
    return nightlyRate.map((nightlyCost, index) => {
      const base = MoneyUtil.parse(nightlyCost.base);
      const tax = MoneyUtil.parse(nightlyCost.tax);
      const Totalfees = nightlyCost.fees ?? [];

      const fees = Totalfees.reduce(
        (totalFee, currentFee) => MoneyUtil.parse(currentFee.amount).add(totalFee),
        MoneyUtil.zeroMoney(),
      );

      const mandatoryFees = HotelDetailsManager.GetMandatoryFees(nightlyCost);

      const salesTaxFee = Totalfees.find(({ type }) => {
        return type === FeeType.SALES_TAX;
      });
      let saleTaxAmount = MoneyUtil.zeroMoney();
      let updatedTotalFees = fees;
      if (salesTaxFee && salesTaxFee?.amount) {
        saleTaxAmount = MoneyUtil.parse(salesTaxFee.amount);
        updatedTotalFees = updatedTotalFees.subtract(saleTaxAmount);
      }

      if (mandatoryFees.total.getAmount() > 0) {
        updatedTotalFees = updatedTotalFees.subtract(mandatoryFees.total);
      }

      return {
        dateString: getFutureDateFromDays(
          index,
          this.response.occupancyDates?.checkInDate?.iso8601 ?? '',
          dateFormats.LONG_DATE_REVERSE_FORMAT,
        ),
        base,
        tax,
        fees: updatedTotalFees,
        salesTax: saleTaxAmount,
        mandatoryFeesBreakdown: mandatoryFees.breakdown,
        total: base.add(tax).add(fees),
      };
    });
  }

  public static IsCvvRequired(rateOption: RateOption): boolean {
    return rateOption.rateInfo?.isCvvRequired ?? false;
  }

  // Hotel Details Redesign Methods
  public getRooms = (): Room[] =>
    this.response?.rooms?.flatMap((room) => {
      // Split rate options of a room with different bed infos into
      // array of rooms with rate option consisting of only single type of bedInfo
      const rateOptionsGroupedByBedInfo = groupBy(room.rateOptions, (rateOption) => JSON.stringify(rateOption.bedInfo));
      const values = Object.values(rateOptionsGroupedByBedInfo);
      return values.map((entry: RateOption[]): Room => ({ ...room, rateOptions: entry }));
    });

  public static getRoomSummary = (room: Room): RoomSummary => {
    const roomLevelAmenities = intersectionWith(
      ...room.rateOptions.map((rateOption) => [
        ...rateOption.amenities.filter(({ type }) => !breakfastRoomAmenities.includes(type)),
        ...rateOption.additionalAmenities.map((amenity) => ({
          type: RoomAmenityRoomAmenityTypeEnum.UNRECOGNIZED,
          additionalInfo: amenity,
          complimentary: false,
        })),
      ]),
      isEqual,
    );

    return {
      title: room.description,
      images: HotelDetailsManager.GetSliderImages(room.imageSets ?? []),
      bedInfo: room.rateOptions[0].bedInfo,
      viewInfo: room.rateOptions[0].roomInfo?.roomView,
      amenities: uniq(roomLevelAmenities.map((amenity) => amenity.type)),
      /**
       * Before using JSON.stringify as comparator in other places do consider the
       * following issues:
       * 1. Circular References: JSON.stringify cannot handle objects with circular
       *    references, as it will throw an error. Ensure that your object doesn't
       *    contain circular references.
       * 2. Large Objects: Stringify-ing very large objects can lead to performance
       *    issues or memory problems.
       *
       * Both of these issues are not applicable here
       */
      roomLevelAmenities: uniqBy(roomLevelAmenities, JSON.stringify),
    };
  };

  public getCheckInDateTime = (): DateTime => {
    const { checkInDate, checkInTime } = this.GetOccupancy();
    return { iso8601: `${checkInDate}T${checkInTime || '00:00:00'}` };
  };

  public static readonly getRoomRateOptions = (room: Room): RateOption[] => {
    return room.rateOptions ?? [];
  };

  // TODO: Look into what should be shown for intermediate states
  public static isRateOptionCancellationDeadlineValid(cancellationTerms: ICancellationTerm[]): boolean {
    const cancellationInfo = first(cancellationTerms);
    if (cancellationInfo) {
      return moment(cancellationInfo.deadline.iso8601).isAfter(moment());
    }
    return true;
  }

  private static isRateOptionEligibleForComplimentaryBreakfast(amenities: RateOption['amenities']): boolean {
    return amenities.some((amenity) => breakfastRoomAmenities.includes(amenity.type) && amenity.complimentary);
  }

  private static getPromotionalOffers(rateOption: RateOption) {
    return rateOption.promotionalOffers ?? [];
  }

  static GetRewardPointsEarned(rateOption: RateOption) {
    return rateOption.rewardPointsEarned ?? [];
  }

  private static GetMandatoryFees(rate: Rate | undefined): MandatoryFees {
    const breakdown: MandatoryFeesBreakdown[] = [];
    let total = MoneyUtil.zeroMoney();
    rate?.fees.forEach((fee) => {
      if (fee.displayFee) {
        const feeType = feeTypeTitleMapper[fee.type] ?? feeTypeTitleMapper[FeeType.UNKNOWN];
        total = total.add(MoneyUtil.parse(fee.amount));
        breakdown.push({
          title: feeType,
          feeInclusions: fee.feeInclusions,
          amount: MoneyUtil.parse(fee.amount),
        });
      }
    });

    return {
      total,
      breakdown,
    };
  }

  public getRoomRateOptionsSummary = (rateOptions: RateOption[]): RoomRateOptionSummary[] => {
    const { preferences = [] } = this.response;
    return rateOptions.map((rateOption) => {
      const { rateInfo, amenities, description, policyInfo, priceValidateKey, isPrepaidRoom } = rateOption;
      const isEligibleForLoyalty = HotelDetailsManager.isEligibleForLoyalty(rateOption);
      const cancellationTerms = this.GetRoomOptionCancellationTerms(rateOption);
      const isRefundable = HotelDetailsManager.isRoomOptionRefundable(rateOption);
      const hasComplimentaryBreakfastIncluded =
        HotelDetailsManager.isRateOptionEligibleForComplimentaryBreakfast(amenities);
      const { ratePlanName, rateType, rateTag } = rateInfo;
      const isBookingDotComRate = HotelDetailsManager.isBookingDotComSource(rateOption);
      const isExpediaRate = HotelDetailsManager.isExpediaSource(rateOption);
      const isRateVPayEnabled = HotelDetailsManager.isRateVPayEnabled(rateOption);
      const policyViolationMessage = policyInfo?.violationMessage;

      const totalPrice = this.GetTotalPrice(rateOption);
      const totalDays = HotelDetailsManager.GetTotalDays(rateOption);
      const { checkInDate, checkOutDate } = this.GetOccupancy();
      const averageNightlyPrice = HotelDetailsManager.getAverageNightlyRate(checkInDate, checkOutDate, totalPrice);
      const publishedRate = this.GetPublishedTotalPriceOrTotalDifference(rateOption);
      const publishedNightlyRate = this.GetPublishedNightlyPrice(rateOption);

      const dateWiseFare = this.GetDayWiseRateBreakup(rateOption);
      const rateDifference = this.GetPublishedTotalPriceOrTotalDifference(rateOption, true);
      const isRoomMemberOnlyRate = HotelDetailsManager.isRoomMemberOnlyRate(rateOption);

      const averageNightlyRate = rateOption?.rateInfo?.averageNightlyRate;
      const showOnlyBaseFare = this.getShowOnlyBaseFare();

      const promotionalOffers = HotelDetailsManager.getPromotionalOffers(rateOption);
      const rewardPointsEarned = HotelDetailsManager.GetRewardPointsEarned(rateOption);
      const mandatoryFees = HotelDetailsManager.GetMandatoryFees(rateOption.rateInfo.averageNightlyRate);
      const isDepositRequired = rateOption.guaranteeType === GuaranteeType.DEPOSIT;

      return {
        averageNightlyPrice,
        cancellationTerms,
        dateWiseFare,
        description,
        hasComplimentaryBreakfastIncluded,
        isBookingDotComRate,
        isEligibleForLoyalty,
        isRefundable,
        policyInfo,
        policyViolationMessage,
        priceValidateKey,
        ratePlanName,
        rateType,
        rateTag,
        totalDays,
        totalPrice,
        publishedRate,
        publishedNightlyRate,
        isExpediaRate,
        isPrepaidRoom,
        rateDifference,
        isRoomMemberOnlyRate,
        averageNightlyRate,
        showOnlyBaseFare,
        preferences,
        promotionalOffers,
        rewardPointsEarned,
        mandatoryFees,
        isRateVPayEnabled,
        isDepositRequired,
      };
    });
  };

  /**
   * TODO: duplicating this function here as this is used in mobile also.
   * Clean up needs to happen when mobile filters are replaced.
   */
  public GetFilteredRoomsNew(rooms: IRoom[], filters: IHotelDetailFilter | IHotelDetailFilterV2): IRoom[] {
    if (!('rates' in filters)) {
      return this.GetFilteredRoomsV2(rooms, filters);
    }

    return rooms
      .map((room) => {
        const draftRoom = cloneDeep(room);

        draftRoom.rateOptions = draftRoom.rateOptions.filter((rateOption) => {
          const { rateInfo, isPrepaidRoom, policyInfo } = rateOption;
          // Earn loyalty points
          const isEligibleForLoyalty = HotelDetailsManager.isEligibleForLoyalty(rateOption);
          // Cancellation
          const bedCount = HotelDetailsManager.TotalBedCount(rateOption);
          const bedType = HotelDetailsManager.AllBedTypes(rateOption);
          const rateOptionAmenities = rateOption?.amenities.map((amenity) => amenity.type) || [];

          // Check if filters.inclusionsTypes array is empty
          const hasRequiredAmenities =
            filters.inclusionsTypes.length === 0 ||
            filters.inclusionsTypes.every((type) => {
              // Iterate over each element in filters.inclusionsTypes array
              // Get the corresponding subTypes array based on the type value
              // Check if any of the subTypes are included in the rateOptionAmenities array
              const subTypes = HotelRoomAmenitiesMap[type as keyof typeof HotelRoomAmenitiesMap];
              return subTypes.some((subType) => rateOptionAmenities.includes(subType));
            });

          // todo which rate and what if rate is not present
          const averageNightlyFare = rateInfo?.averageNightlyRate?.base?.convertedAmount ?? -1;
          const isRefundable = HotelDetailsManager.isRoomOptionRefundable(rateOption);
          const rateType = rateInfo?.rateType ?? -1;
          const allowedFop = HotelDetailsManager.getAllowedFop(rateOption);
          const isPayByPointsQualifiedRoom = isPrepaidRoom && allowedFop.includes(PaymentMethodEnum.BREX_POINTS);

          // Check if filters.rates array is empty
          // Check if averageNightlyFare is greater than or equal to the lower bound
          // Check if the upper bound is 1000 or averageNightlyFare is less than or equal to the upper bound
          const withinPriceRange =
            !filters.rates.length ||
            (filters.rates[0] <= averageNightlyFare &&
              (filters.rates[1] === defaultHotelDetailsFilters.rates[1] || filters.rates[1] >= averageNightlyFare));

          // Check if filters.rateTypes array is empty
          const hasRequiredRateType =
            !filters.rateTypes.length ||
            filters.rateTypes.some((type) => {
              // Iterate over each element in filters.rateTypes array
              // Get the corresponding enum type based on the type value
              if (type === HotelRateCodeTypeEnum.GOV_OR_MIL) {
                return [HotelDetailsRateType.GOVERNMENT, HotelDetailsRateType.MILITARY].includes(Number(rateType));
              }
              const rateTypeEnum = HotelDetailsRateType[type as keyof typeof HotelDetailsRateType];
              // Check if the rate type matches REGULAR then only return options that non qualified rates
              const hasRegularRateType =
                rateTypeEnum === HotelDetailsRateType.REGULAR && !qualifiedRateTypes.includes(Number(rateType));
              // If not regular rate then return rate option that matches the specified rateType
              return hasRegularRateType || Number(rateTypeEnum) === Number(rateType);
            });

          // Check if filters.bedTypes array is empty
          const hasRequiredBedType =
            !filters.bedTypes.length ||
            filters.bedTypes.some((element) => {
              // Iterate over each element in filters.bedTypes array
              // Get the corresponding enum type based on the element value
              // Check if the bed type matches or if it's MORE_BEDS and bedCount is greater than 1
              const enumType = HotelDeatilsBedType[element as keyof typeof HotelDeatilsBedType];
              return bedType.includes(enumType) || (enumType === HotelDeatilsBedType.MORE_BEDS && bedCount > 1);
            });

          // checking the payment criteria
          // if prepaid only options selected then room must be prepaid
          // If pay later selected then room should not be prepaid
          const paymentQualified =
            (!(filters.prepaidQualifier === PrepaidQualifier.PREPAID_ONLY) || isPrepaidRoom) &&
            (!(filters.prepaidQualifier === PrepaidQualifier.EXCLUDE_PREPAID) || !isPrepaidRoom);

          // Check if either the 'showOutOfPolicy' filter is enabled or the 'violationMessage' of 'policyInfo' is an empty string
          const outOfPolicyCriteriaMet = filters.showOutOfPolicy || policyInfo?.violationMessage === '';
          const finalAnswer =
            outOfPolicyCriteriaMet &&
            hasRequiredAmenities &&
            hasRequiredRateType &&
            withinPriceRange &&
            hasRequiredBedType &&
            paymentQualified &&
            (!filters.payByPoints || isPayByPointsQualifiedRoom) &&
            (!filters.eligibleForLoyalty || isEligibleForLoyalty) &&
            (!filters.refundableOnly || isRefundable);
          return finalAnswer;
        });

        return draftRoom;
      })
      .filter((room) => room.rateOptions.length > 0);
  }

  private GetFilteredRoomsV2(rooms: IRoom[], filters: IHotelDetailFilterV2): IRoom[] {
    return rooms
      .map((room) => {
        const draftRoom = cloneDeep(room);

        draftRoom.rateOptions = draftRoom.rateOptions.filter((rateOption) => {
          const { rateInfo, isPrepaidRoom, policyInfo, amenities } = rateOption;

          /** payByPoints */
          const allowedFop = HotelDetailsManager.getAllowedFop(rateOption);
          const isPayByPointsQualifiedRoom = isPrepaidRoom && allowedFop.includes(PaymentMethodEnum.BREX_POINTS);
          const payByPointsCriterionMet = !filters.payByPoints || isPayByPointsQualifiedRoom;
          if (!payByPointsCriterionMet) {
            return false;
          }

          /** showOutOfPolicy */
          const isWithinPolicy = policyInfo?.violationMessage === '';
          const outOfPolicyCriterionMet = filters.showOutOfPolicy || isWithinPolicy;
          if (!outOfPolicyCriterionMet) {
            return false;
          }

          /** refundable */
          const isRefundable = HotelDetailsManager.isRoomOptionRefundable(rateOption);
          const refundableCriterionMet = !filters.refundable || isRefundable;
          if (!refundableCriterionMet) {
            return false;
          }

          /** eligibleForLoyalty */
          const isEligibleForLoyalty = HotelDetailsManager.isEligibleForLoyalty(rateOption);
          const eligibleForLoyaltyCriterionMet = !filters.eligibleForLoyalty || isEligibleForLoyalty;
          if (!eligibleForLoyaltyCriterionMet) {
            return false;
          }

          /** showConditional */
          const isNonQualifiedRate = !qualifiedRateTypes.includes(rateInfo?.rateType ?? RateTypeV1.UNRECOGNIZED);
          const showConditionalCriterionMet = filters.showConditional || isNonQualifiedRate;
          if (!showConditionalCriterionMet) {
            return false;
          }

          /** prepaid */
          const prepaidCriterionMet = (filters.prepaid && filters.payAtProperty) || !filters.prepaid || isPrepaidRoom;
          if (!prepaidCriterionMet) {
            return false;
          }

          /** payAtProperty */
          const payAtPropertyCriterionMet =
            (filters.prepaid && filters.payAtProperty) || !filters.payAtProperty || !isPrepaidRoom;
          if (!payAtPropertyCriterionMet) {
            return false;
          }

          /** complimentaryBreakfast */
          const hasComplimentaryBreakfastIncluded =
            HotelDetailsManager.isRateOptionEligibleForComplimentaryBreakfast(amenities);
          const complimentaryBreakfastCriterionMet =
            !filters.complimentaryBreakfast || hasComplimentaryBreakfastIncluded;
          if (!complimentaryBreakfastCriterionMet) {
            return false;
          }

          /** accessible */
          /** @deprecated We will be moving to `rateOption.accessibilityInfo` completely once implemented for every supplier */
          const isRoomAccessible = amenities.some(({ type }) => HotelRoomAmenitiesMap.ACCESSIBLE_ROOMS?.includes(type));
          const isRateOptionAccessible = !!rateOption.accessibilityInfo && rateOption.accessibilityInfo.length > 0;
          const accessibleCriterionMet = !filters.accessible || isRoomAccessible || isRateOptionAccessible;
          if (!accessibleCriterionMet) {
            return false;
          }

          return true;
        });

        return draftRoom;
      })
      .filter((room) => room.rateOptions.length > 0);
  }

  // TODO: (Himesh) Refactor to enable passing an filter data object instead
  public GetFilteredRooms(rooms: IRoom[], filters: RateOptionFilters): IRoom[] {
    return rooms
      .map((room) => {
        const draftRoom = cloneDeep(room);

        draftRoom.rateOptions = draftRoom.rateOptions.filter((rateOption) => {
          const { rateInfo, isPrepaidRoom, isModifiable } = rateOption;
          const isEligibleForLoyalty = HotelDetailsManager.isEligibleForLoyalty(rateOption);
          const isRefundable = HotelDetailsManager.isRoomOptionRefundable(rateOption);
          const isNonQualifiedRate = !qualifiedRateTypes.includes(rateInfo?.rateType ?? RateTypeV1.UNRECOGNIZED);
          const allowedFop = HotelDetailsManager.getAllowedFop(rateOption);
          const isPayByPointsQualifiedRoom = isPrepaidRoom && allowedFop.includes(PaymentMethodEnum.BREX_POINTS);

          return (
            (!filters.eligibleForLoyalty || isEligibleForLoyalty) &&
            (!filters.freeCancellation || isRefundable) &&
            (filters.qualifiedRate || isNonQualifiedRate) &&
            (!filters.prepaidRoomsOnly || isPrepaidRoom) &&
            (!filters.payByPointsOnly || isPayByPointsQualifiedRoom) &&
            (!filters.isModifiable || isModifiable)
          );
        });

        return draftRoom;
      })
      .filter((room) => room.rateOptions.length > 0);
  }

  static GetAdditionalCancellationInfo(rateOption: RateOption): AdditionalDetail | undefined {
    return rateOption.additionalDetails?.find(
      (detail) => detail.additionalDetailType === AdditionalDetailAdditionalDetailType.CANCELLATION_INFORMATION,
    );
  }

  public GetTripHotelDetails = (): ITripHotelDetails => {
    const rateOption = this.GetRoomDetails()[0].rateOptions[0];

    return {
      address: this.GetHotelAddress(),
      amenities: this.GetHotelAmenities(),
      roomDetails: this.GetRoomDetails()[0],
      cancellationTerms: this.GetRoomOptionCancellationTerms(rateOption),
      additionalCancellationInfo: HotelDetailsManager.GetAdditionalCancellationInfo(rateOption),
      summary: this.getHotelSummaryForDetail(),
    };
  };

  static isSoftApprovalRequired = (rateOption: RateOption): boolean =>
    rateOption.policyInfo?.approvalType === PolicyInfoApprovalType.SOFT_APPROVAL;

  static isHardApprovalRequired = (rateOption: RateOption): boolean =>
    rateOption.policyInfo?.approvalType === PolicyInfoApprovalType.HARD_APPROVAL;

  public GetRoomRateType = (rateOption: RateOption): RateTypeV1 => rateOption.rateInfo?.rateType ?? -1;

  public GetRoomRateTag = (rateOption: RateOption): string => rateOption.rateInfo?.rateTag ?? '';

  public static GetFeeType(rateOption: RateOption): FeeType {
    const fees = rateOption.rateInfo?.totalRate?.fees ?? [];
    return (fees[0] && fees[0].type) ?? 0;
  }

  public static CheckIfCurrentRoomIsAvailableForNewDates = (
    hotelDetailsResponse: HotelDetailsResponse,
  ): { isCurrentRoomAvailable: boolean; room: IRoom | undefined; rateOption: RateOption | undefined } => {
    // If we got a totalRate & rateDifference, then we know that the room is available for the new dates
    const { bookedRooms = [] } = hotelDetailsResponse;
    const isCurrentRoomAvailable =
      !!bookedRooms.length &&
      bookedRooms.every(
        (bookedRoom) =>
          !!bookedRoom.rateOptions.length &&
          bookedRoom.rateOptions.every(
            (rateOption) => !!rateOption.rateInfo.totalRate && !!rateOption.rateInfo.rateDifference,
          ),
      );

    const room = isCurrentRoomAvailable ? bookedRooms[0] : undefined;
    const rateOption = room && room.rateOptions[0];

    return { isCurrentRoomAvailable, room, rateOption };
  };

  /**
   * We will be using BE API for determining which loyalty if any is appicable
   */
  public static isEligibleForLoyalty(rateOption: RateOption | undefined): boolean {
    // TODO: remove ratesource check
    return (
      (rateOption?.rateInfo?.rateSource !== undefined &&
        [RateInfoRateSource.SABRE, RateInfoRateSource.QANTAS_HOTELS].includes(rateOption.rateInfo.rateSource)) ||
      !!rateOption?.earnLoyaltyPoints
    );
  }

  public static GetIsEveryRoomRateOptionNotAllowedToBook({
    rateOptions,
  }: {
    rateOptions: RoomRateOptionSummary[];
  }): boolean {
    return rateOptions.every((rateOption) => {
      const bookingNotAllowedInfo = getPreventBookingInfoFromPolicyInfo(rateOption.policyInfo);

      return Boolean(bookingNotAllowedInfo?.prevent);
    });
  }

  public static GetPenaltyAmount(response: HotelDetailsResponse): MoneyUtil {
    return MoneyUtil.parse(response.bookedRooms[0]?.rateOptions[0]?.rateInfo.penaltyAmount);
  }

  public static isRoomMemberOnlyRate(rateOption: RateOption): boolean {
    return rateOption.rateInfo?.rateType === RateTypeV1.MEMBERSHIP;
  }

  public getShowOnlyBaseFare(): boolean {
    return this.response.showOnlyBaseFare ?? false;
  }

  public getHotelRateStatistics(): RateStatistics | undefined {
    return this.response.rateStatistics;
  }

  static GetTotalTravelersFromOccupancyData(occupancyData: Partial<OccupancyDateParamsOccupancy>[]): number {
    let totalTravelers = 0;
    occupancyData.forEach(({ numAdults = 0, numChildren = 0, numInfants = 0 }) => {
      totalTravelers += numAdults + numChildren + numInfants;
    });
    return totalTravelers;
  }
}
