import { useTranslation } from 'react-i18next';
import first from 'lodash/first';
import last from 'lodash/last';
import { getFullName } from '../../../transformers/common';
import type { ITraveler } from '../../../types/traveler';
import { localizationKeys } from '../../../constants/common';
import { localisedCurrencyValue, localisedOriginalCurrencyValue } from '../../../utils/Flights/fareBreakupUtils';
import type { IGetFlightFareBreakup } from '../../../utils/Flights/getFlightFareBreakup';
import { useGetAirFareBreakupTripFees } from '../useGetAirFareBreakupTripFees';
import { PaymentMethodEnum, RateOptionTicketTypeEnum } from '../../../types';
import { defineMessage } from '../../../translations/defineMessage';
import { AirCheckoutFareNodeTypeEnum, CheckoutColorVariantEnum } from './types';
import type { IAirCheckoutFareBreakupNode } from './types';
import { MoneyUtil } from '../../../utils/Money';
import { createUserNameFromFullName } from '../../../utils/common';
import { useFeatureFlag } from '../../../feature-gating';

export interface IUseItineraryFareBreakupProps {
  /**
   * The properties below are missing from the fareBreakup returned from useGetTripFareBreakup, hence
   * they will be treated as optional
   */
  flightFareBreakup: Omit<
    IGetFlightFareBreakup,
    'totalItineraryFare' | 'isSameCurrency' | 'negotiatedRatesInfo'
  > | null;
  travelers: (ITraveler | undefined)[];
  holdBooking: boolean;
}

interface ITravelerFullNameProps {
  travelers: (ITraveler | undefined)[];
  userId: string;
  shouldUsePreferredName: boolean;
}

export const getTravelerFullName = ({ travelers, userId, shouldUsePreferredName }: ITravelerFullNameProps) => {
  const traveler = travelers.find((currentTraveler) => currentTraveler?.userOrgId?.userId?.id === userId);
  return shouldUsePreferredName ? createUserNameFromFullName(traveler?.user?.name) : getFullName(traveler?.user?.name);
};

const { LOCALIZED_CURRENCY } = localizationKeys;

export const useItineraryFareBreakup = ({
  flightFareBreakup,
  travelers,
  holdBooking,
}: IUseItineraryFareBreakupProps): IAirCheckoutFareBreakupNode[] => {
  const shouldUsePreferredName = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');
  const { getFareBreakupTripFees } = useGetAirFareBreakupTripFees();
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation('COMMON');

  if (!flightFareBreakup) {
    return [];
  }

  const fareBreakup: IAirCheckoutFareBreakupNode[] = [];

  const {
    paxesFareBreakup,
    totalAmount,
    showBrexPointsFare,
    ticketType,
    merchantFee,
    totalSeatPrice,
    totalBaggagePrice,
    totalBookingAmount,
    changeFees,
    unusedCredits,
    eligibleRefund,
    serviceFees,
    paymentFees,
    allowedFop,
    appliedCreditAirlineCode,
    zeroMoney,
    airlineFees,
    promotionApplied,
  } = flightFareBreakup;

  const serviceFeesHavingDifferentOriginalCurrency =
    totalBookingAmount &&
    !serviceFees.total.isAmountZero() &&
    (serviceFees.total.getOriginalCurrency() !== totalBookingAmount.getOriginalCurrency() ||
      serviceFees.isTotalHavingDifferentCurrency);

  const isBookingAmountNotInPreferredCurrency =
    totalBookingAmount && totalBookingAmount.getCurrency() !== totalBookingAmount.getOriginalCurrency();

  const totalBrexAmount = totalAmount.getBrexPoints()?.amount;

  const passengersAncillaryComponents: IAirCheckoutFareBreakupNode[][] = [];

  paxesFareBreakup.forEach((paxFareBreakup) => {
    const { fareComponents } = paxFareBreakup;
    const { seat, baggage, uaPassPlusBaseFare, uaPassPlusFeesAndTax, otherAncillary } = fareComponents;

    const passengerAncillaryComponents: IAirCheckoutFareBreakupNode[] = [];
    if (!seat.isAmountZero()) {
      passengerAncillaryComponents.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('Seats')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(seat)),
      });
    }

    if (baggage && !baggage.isAmountZero()) {
      passengerAncillaryComponents.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('Baggage')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(baggage)),
      });
    }

    if (!otherAncillary.totalPrice.isAmountZero()) {
      const otherAncillaryNames = otherAncillary.ancillaries;
      const label =
        otherAncillaryNames.length === 1 ? otherAncillaryNames[0] : tCommon(defineMessage('Other Ancillaries'));

      passengerAncillaryComponents.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label,
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(otherAncillary.totalPrice)),
      });
    }

    if (!uaPassPlusBaseFare.isAmountZero()) {
      passengerAncillaryComponents.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('United PassPlus - Flight')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(uaPassPlusBaseFare)),
      });
    }

    if (!uaPassPlusFeesAndTax.isAmountZero()) {
      passengerAncillaryComponents.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('United PassPlus - Fees and taxes')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(uaPassPlusFeesAndTax)),
      });
    }
    passengersAncillaryComponents.push(passengerAncillaryComponents);
  });

  /**  Passenger level fare components with base and tax start here */
  paxesFareBreakup.forEach((paxFareBreakup, paxIndex) => {
    const travelerNumber = paxIndex + 1;
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.SUB_HEADING,
      label: paxFareBreakup.paxUserId
        ? getTravelerFullName({
            travelers,
            userId: paxFareBreakup.paxUserId,
            shouldUsePreferredName: !!shouldUsePreferredName,
          })
        : tCommon(defineMessage('Traveler {{travelerNumber}}'), { travelerNumber }),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(paxFareBreakup.paxTotal)),
    });

    const { fareComponents } = paxFareBreakup;
    const { legBaseFare, baseFare, feesAndTaxes } = fareComponents;

    if (ticketType === RateOptionTicketTypeEnum.MULTI) {
      legBaseFare?.forEach((legInfo) => {
        const { flight } = legInfo;
        const flightBaseFare = legInfo.baseFare || zeroMoney || MoneyUtil.zeroMoney();
        fareBreakup.push({
          type: AirCheckoutFareNodeTypeEnum.NORMAL,
          label: `${first(flight)?.departureAirportCode} > ${last(flight)?.arrivalAirportCode}`,
          value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(flightBaseFare)),
        });
      });
    } else {
      fareBreakup.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('Flights')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(baseFare)),
      });
    }

    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Fees and taxes')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(feesAndTaxes)),
    });

    if (unusedCredits && unusedCredits.isAmountZero()) {
      if (passengersAncillaryComponents[paxIndex].length > 0) {
        fareBreakup.push(...passengersAncillaryComponents[paxIndex]);
      }
    }
  });

  if (changeFees && !changeFees.isAmountZero()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Change fee')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(changeFees)),
    });
  }
  /**  Passenger level fare components with base and tax start here */

  /** Unused credits info starts here */
  if (unusedCredits && !unusedCredits.isAmountZero()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Flight credits')),
      value: `-${t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(unusedCredits))}`,
      colorVariant: CheckoutColorVariantEnum.SUCCESS,
    });
  }

  if (eligibleRefund && eligibleRefund.isNegative()) {
    fareBreakup.push({ type: AirCheckoutFareNodeTypeEnum.DIVIDER });

    if (appliedCreditAirlineCode === 'UA' && zeroMoney) {
      fareBreakup.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('Residual amount')),
        colorVariant: CheckoutColorVariantEnum.DANGER,
        subTextLeft: tCommon(
          defineMessage(
            'The residual credit value will result in a loss and a refund will not be processed. Refunds are subject to fare rules and at the discretion of the carrier.',
          ),
        ),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(zeroMoney)),
      });
    } else {
      fareBreakup.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('Residual amount')),
        colorVariant: CheckoutColorVariantEnum.DANGER,
        subTextLeft: tCommon(
          defineMessage(
            'Refund for the residual amount is not guaranteed. Refunds are subject to fare rules and at the discretion of the carrier.',
          ),
        ),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(eligibleRefund.multiply(-1))),
      });
    }

    if (!totalBaggagePrice.isAmountZero() || !totalSeatPrice.isAmountZero() || !serviceFees.total.isAmountZero()) {
      fareBreakup.push({
        type: AirCheckoutFareNodeTypeEnum.DIVIDER,
      });
    }
  }

  /** Unused credits info ends here */

  /**  Passenger level fare components excluding base and tax start here */
  if (unusedCredits && !unusedCredits.isAmountZero()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.DIVIDER,
    });

    // The index is passed 0 because,unused credits is just allowed for single pax
    if (passengersAncillaryComponents[0]) {
      fareBreakup.push(...passengersAncillaryComponents[0]);
    }
  }

  /**  Passenger level fare components excluding base and tax start here */

  if (
    eligibleRefund &&
    eligibleRefund.isAmountZero() &&
    (!merchantFee.isAmountZero() || !serviceFees.total.isAmountZero() || !paymentFees.isAmountZero())
  ) {
    fareBreakup.push({ type: AirCheckoutFareNodeTypeEnum.DIVIDER });
  }

  if (promotionApplied && promotionApplied.discountAmount.isPositive()) {
    fareBreakup.push(
      { type: AirCheckoutFareNodeTypeEnum.DIVIDER },
      {
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon('Promo Code ({{promoCode}})', { promoCode: promotionApplied.promotionCode }),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(promotionApplied.discountAmount.multiply(-1))),
        colorVariant: CheckoutColorVariantEnum.SUCCESS,
      },
    );
  }

  if (serviceFeesHavingDifferentOriginalCurrency) {
    fareBreakup.push(
      {
        type: AirCheckoutFareNodeTypeEnum.SUB_HEADING,
        label: tCommon(defineMessage('Booking Amount')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalBookingAmount)),
        subTextLeft: serviceFees.showWithFeeBreakup
          ? tCommon(defineMessage('Excl. $t(Trip fees)'))
          : tCommon(defineMessage('Excl. $t(Service Fees)')),
        subTextRight: serviceFeesHavingDifferentOriginalCurrency
          ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(totalBookingAmount))}`
          : '',
      },
      { type: AirCheckoutFareNodeTypeEnum.DIVIDER },
    );
  }

  fareBreakup.push(...getFareBreakupTripFees({ serviceFees }));

  if (!serviceFees.showWithFeeBreakup && !serviceFees.base.isAmountZero()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('$t(Service fees)*')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.base)),
    });

    if (serviceFees.showTax) {
      fareBreakup.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('$t(Service fees) taxes*')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.tax)),
      });
    }

    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.INFO,
      text: tCommon(defineMessage('*Non-refundable')),
    });
  }

  if (airlineFees && !airlineFees.isAmountZero()) {
    fareBreakup.push(
      { type: AirCheckoutFareNodeTypeEnum.DIVIDER },
      {
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('Credit card fee')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(airlineFees)),
      },
    );
  }

  if (!paymentFees.isAmountZero()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Type of payment')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(paymentFees)),
    });
  }

  // render Grand total
  fareBreakup.push(
    { type: AirCheckoutFareNodeTypeEnum.DIVIDER },
    {
      type: AirCheckoutFareNodeTypeEnum.HEADING,
      label: tCommon(holdBooking ? defineMessage('Estimated total') : defineMessage('Total Amount')),
      value:
        serviceFeesHavingDifferentOriginalCurrency || holdBooking
          ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(totalAmount))}`
          : t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalAmount)),
    },
  );

  if (!serviceFeesHavingDifferentOriginalCurrency && isBookingAmountNotInPreferredCurrency) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.ONLY_VALUE,
      value: `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(totalAmount))}`,
    });
  }

  if (showBrexPointsFare && allowedFop.includes(PaymentMethodEnum.BREX_POINTS) && totalBrexAmount) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.ONLY_VALUE,
      value: `or ${totalBrexAmount.toLocaleString()} Pts`,
    });
  }

  // Merchant fees rendered after total since, it is not included in totalAmount
  if (!merchantFee.isAmountZero()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Credit card fee')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(merchantFee)),
      toolTipText: tCommon(
        defineMessage(
          'This is a non-refundable credit card processing fee charged when booking Spotnana special fares.',
        ),
      ),
    });
  }

  return fareBreakup;
};
