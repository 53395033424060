import type { BackendModule } from 'i18next';
import noop from 'lodash/noop';
import type { TranslationNamespacesKey } from './namespaces';
import type { TranslationNamespaceLoader } from './types';
import loadNamespaceForDE from './de/index.lazy';
import loadNamespaceForEN from './en/index.lazy';
import loadNamespaceForENAU from './en-AU/index.lazy';
import loadNamespaceForENCA from './en-CA/index.lazy';
import loadNamespaceForENGB from './en-GB/index.lazy';
import loadNamespaceForES from './es/index.lazy';
import loadNamespaceForESLATAM from './es-419/index.lazy';
import loadNamespaceForFR from './fr/index.lazy';
import loadNamespaceForFRCA from './fr-CA/index.lazy';
import loadNamespaceForJA from './ja/index.lazy';
import loadNamespaceForKO from './ko/index.lazy';
import loadNamespaceForPT from './pt/index.lazy';
import loadNamespaceForPTBR from './pt-BR/index.lazy';
import { getLanguageCodeDetails } from './utils/language';

const REGION_LANGUAGE_SUBTAG_TO_NAMESPACE_LOADER_MAP: Record<string, TranslationNamespaceLoader> = {
  'en-AU': loadNamespaceForENAU,
  'en-CA': loadNamespaceForENCA,
  'en-GB': loadNamespaceForENGB,
  'es-419': loadNamespaceForESLATAM,
  'fr-CA': loadNamespaceForFRCA,
  'pt-BR': loadNamespaceForPTBR,
};

const LANGUAGE_SUBTAG_TO_NAMESPACE_LOADER_MAP: Record<string, TranslationNamespaceLoader> = {
  de: loadNamespaceForDE,
  en: loadNamespaceForEN,
  es: loadNamespaceForES,
  fr: loadNamespaceForFR,
  ja: loadNamespaceForJA,
  ko: loadNamespaceForKO,
  pt: loadNamespaceForPT,
};

const getNamespaceLoader = (languageCode: string): TranslationNamespaceLoader => {
  const languageDetails = getLanguageCodeDetails(languageCode);
  // check if a region specific loader exists for this language code
  let loader = REGION_LANGUAGE_SUBTAG_TO_NAMESPACE_LOADER_MAP[languageDetails.languageCode];

  if (!loader) {
    // find a loader corresponding to base language code if not available
    loader = LANGUAGE_SUBTAG_TO_NAMESPACE_LOADER_MAP[languageDetails.baseLanguageCode];
  }

  // if nothing exists, fallback to default english loader
  if (!loader) {
    loader = loadNamespaceForEN;
  }

  return loader;
};

export const LazyLoadPlugin: BackendModule = {
  type: 'backend',
  init: noop,
  save: noop,
  create: noop,
  async read(language, namespace, callback) {
    try {
      const namespaceLoader = getNamespaceLoader(language);
      const translations = await namespaceLoader(namespace as TranslationNamespacesKey);
      callback(null, translations);
    } catch (error) {
      callback(error instanceof Error ? error : null, null);
    }
  },
};
