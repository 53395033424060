/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * type of the transaction
 * @export
 * @enum {string}
 */

export const TransactionType = {
    TripServiceFee: 'TRIP_SERVICE_FEE',
    AirTicketIssued: 'AIR_TICKET_ISSUED',
    AirTicketVoided: 'AIR_TICKET_VOIDED',
    AirTicketRefunded: 'AIR_TICKET_REFUNDED',
    AirTicketExchanged: 'AIR_TICKET_EXCHANGED',
    AirTicketUndetermined: 'AIR_TICKET_UNDETERMINED',
    HotelBooked: 'HOTEL_BOOKED',
    HotelModified: 'HOTEL_MODIFIED',
    HotelCancelled: 'HOTEL_CANCELLED',
    CarBooked: 'CAR_BOOKED',
    CarCancelled: 'CAR_CANCELLED',
    RailBooked: 'RAIL_BOOKED',
    RailRefunded: 'RAIL_REFUNDED',
    RailExchanged: 'RAIL_EXCHANGED',
    RailCancelled: 'RAIL_CANCELLED'
} as const;

export type TransactionType = typeof TransactionType[keyof typeof TransactionType];



