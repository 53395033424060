/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { EventFilter } from './event-filter';

/**
 * List of events request
 * @export
 * @interface ListEventsRequest
 */
export interface ListEventsRequest {
    /**
     * Type of events
     * @type {string}
     * @memberof ListEventsRequest
     */
    'listEventType': ListEventsRequestListEventTypeEnum;
    /**
     * List events filters
     * @type {Array<EventFilter>}
     * @memberof ListEventsRequest
     */
    'filters'?: Array<EventFilter>;
    /**
     * Indicates from where in the list of events the server should start.
     * @type {number}
     * @memberof ListEventsRequest
     */
    'offset'?: number;
    /**
     * Maximum number of results to be fetched for the query.
     * @type {number}
     * @memberof ListEventsRequest
     */
    'limit'?: number;
}

export const ListEventsRequestListEventTypeEnum = {
    Upcoming: 'UPCOMING',
    PastOrCompleted: 'PAST_OR_COMPLETED',
    CancelledEvent: 'CANCELLED_EVENT'
} as const;

export type ListEventsRequestListEventTypeEnum = typeof ListEventsRequestListEventTypeEnum[keyof typeof ListEventsRequestListEventTypeEnum];


