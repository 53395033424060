/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo, HTMLAttributes, useCallback, useId, useEffect, useRef, forwardRef, useState } from 'react';

import {
  SelectStore,
  ComboboxStore,
  SelectLabel,
  SelectGroup,
  ComboboxGroup,
  SelectGroupLabel,
  ComboboxGroupLabel,
  Combobox,
  SelectItemProps,
  ComboboxListProps,
} from '@ariakit/react';
import clsx from 'clsx';
import { CircularProgress, FormHelperText } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { TOptionBase, isNestedList } from '../utils/select';
import { select_wrapper, select_list_header, select_list_footer } from './SelectRoot.styles';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import { DeprecatedOptionWithStringifiedValue, getPartialVisibleItemList } from './helpers';
import { breakpoints, flex, margins, unit, useMediaQuery } from '../utils';

import { AdornmentWrapper, InputBase } from '../Input';
import type { TInputStates } from '../Input';
import { SELECT_INTERNAL_DUMMY_TITLE } from './constants';
import { TDropdownItemSlots } from './types';

import { noop } from '../utils/fp';
import { IconName } from '../Icon/types';
import { IconButton } from '../IconButton';
import { Button } from '../Button';
import { form_helper_text, input_wrapper } from '../Input/Input.styles';
import { SelectConfigContext, SelectPopoverProps, useSelectConfig } from './SelectConfigContext';
import { paddings, spacings } from '../utils/styling/utilityTokens';
import { Skeleton } from '../Skeleton';
import { SelectListWrapper } from './defaultListWrappers/SelectListWrapper';
import { SelectSearchListWrapper } from './defaultListWrappers/SelectSearchListWrapper';
import { ComboboxListWrapper } from './defaultListWrappers/ComboboxListWrapper';
import { ComboboxSingleItem, SelectSingleItem } from './defaultItemRenderers/singleItemRenderers';
import { ComboboxMultiItem, SelectMultiItem } from './defaultItemRenderers/multiItemRenderers';
import { SelectSingleValue } from './defaultValueRenderers/singleValueRenderers';
import { SelectMultiValue } from './defaultValueRenderers/multiValueRenderers';
import { ComboboxTrigger } from './defaultValueRenderers/ComboboxTrigger';
import { ItemStylingProps } from './defaultItemRenderers/types';

export type TSelectSizes = 'small' | 'medium' | 'standard';

export type TSelectStore<Variant extends 'single' | 'multiple', TItem extends TOptionBase> = SelectStore<
  Variant extends 'single' ? string : Variant extends 'multiple' ? string[] : never
> & {
  getSelection: (
    value: Variant extends 'single' ? string : string[],
  ) => Variant extends 'single' ? TItem | null : [TItem[], Set<string>];
  clearSelection?: () => void;
};

/**
 * Flat list of options
 */

export type TFlatSelectOptions<Item extends TOptionBase = TOptionBase> = Item[];
export type TNestedSelectOptions<
  Item extends TOptionBase = TOptionBase,
  /**
   * This provision is for supporting MultiSelect grouped UI where each group's parent title is also clickable
   * to toggle all children.
   */
  Title extends string | Item = string | Item,
> = {
  title: Title;
  list: Item[];
  isHidden?: true | undefined;
}[];
// export type TMultiSelectOptions<Item extends TOptionBase = TOptionBase> = { title: string; list: Item[] }[];
export type TDeprecatedSelectOptions<Item extends TOptionBase = TOptionBase> =
  | TFlatSelectOptions<Item>
  | TNestedSelectOptions<Item>;

export type TBaseSelectSlots<TItem extends TOptionBase = TOptionBase> = {
  ItemGroup?: React.FC<{
    group: {
      title: string | DeprecatedOptionWithStringifiedValue<TItem>;
      list: DeprecatedOptionWithStringifiedValue<TItem>[];
      isHidden?: true | undefined;
    };
  }>;
  ListWrapper?: React.FC<
    React.PropsWithChildren<
      {
        renderInPlace?: boolean;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        selectStore?: SelectStore<any>;
        comboboxStore?: ComboboxStore & { placeholder?: string };
        Wrapper?: React.FC<{ children: React.ReactNode }>;
      } & Omit<ComboboxListProps, 'store'>
    >
  >;

  SingleItem?: React.FC<
    {
      option: DeprecatedOptionWithStringifiedValue<TItem>;
    } & ItemStylingProps
  >;

  MultiItem?: React.FC<
    {
      option: DeprecatedOptionWithStringifiedValue<TItem>;
    } & ItemStylingProps
  >;

  SingleValue?: React.FC<{
    placeholder?: string | undefined;
  }>;

  MultiValue?: React.FC<{
    placeholder?: string | undefined;
  }>;
};

export type TSelectListSlots = {
  ListHeader?: (
    props: {
      heading: string;
      close: (evt: React.SyntheticEvent) => void;
      clear: (evt: React.SyntheticEvent) => void;
      closeLabel?: string;
    } & React.HTMLAttributes<HTMLDivElement>,
  ) => JSX.Element;
  listPrefixContent?: React.ReactNode;
  listLoader?: {
    loadingComponent?: React.ReactNode;
    loaderText?: string;
    numberOfRows?: number;
  };
  ListFooter?: (
    props: React.PropsWithChildren<{
      variant?: 'single' | 'multiple';
      clear?: () => void;
      selectAll?: () => void;
      close?: (evt: React.SyntheticEvent) => void;
      expanded?: boolean;
      onSetExpand?: (evt: React.SyntheticEvent) => void;
      optionsLength?: number;
    }>,
  ) => JSX.Element;
};

export type TSelectSlots<TItem extends TOptionBase = TOptionBase> = TBaseSelectSlots<TItem> &
  TSelectListSlots &
  TDropdownItemSlots<TItem>;

export type TSelectRootProps<
  ComponentType extends 'select' | 'combobox' = 'select',
  Variant extends 'single' | 'multiple' = 'single',
  TItem extends TOptionBase = TOptionBase,
  TInternalOption extends DeprecatedOptionWithStringifiedValue<TItem> = DeprecatedOptionWithStringifiedValue<TItem>,
> = {
  wrapperRef?: React.Ref<HTMLElement> | React.ForwardedRef<HTMLElement>;
  comboboxTriggerRef?: React.Ref<HTMLInputElement> | React.ForwardedRef<HTMLInputElement>;
  componentType: ComponentType;
  kind?: Variant extends 'single' ? 'persist' : never;
  placeholder?: string;
  size?: TSelectSizes;
  variant: Variant;
  label: string;
  required?: boolean;
  selectStore: TSelectStore<Variant, TItem>;
  options: TDeprecatedSelectOptions<TInternalOption>;
  emptyContent?: NonNullable<React.ReactNode>;
  showLoader?: boolean;
  portal?: boolean;
  popoverProps?: SelectPopoverProps;
  /**
   * When true, the popover will have the sameWidth as the anchor element
   */
  sameWidth?: boolean;
  /**
   * When true, the list of options is rendered in place instead of inside a popover.
   * This makes the options always visible.
   */
  renderInPlace?: boolean;
  showPartialList?: boolean;
  onItemClick?: (item: TItem) => void;
  setValueOnClick?: SelectItemProps['setValueOnClick'];
  disabled?: boolean;
  slots?: TSelectSlots<TItem>;
  // props to help with rendering messages, errors, etc.
  /** Error state or success state */
  state?: TInputStates;
  /** the text for showing a hint, error, etc. use in tandem with `state` prop */
  helperText?: string;
  // search?: {
  //   value: string;
  //   placeholder?: string;
  //   onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  // };
  ValueRenderer?: (props: { option: TItem }) => JSX.Element;
  hideLabel?: boolean;
} & HTMLAttributes<HTMLDivElement> &
  (ComponentType extends 'select'
    ? {
        comboboxStore?: ComboboxStore & { placeholder?: string };
      }
    : {
        comboboxStore: ComboboxStore;
      });

type FCWithChildren = React.FC<{ children?: React.ReactNode }>;

const defaultSlots: Required<TBaseSelectSlots> = {
  ItemGroup: SelectItemGroup,
  SingleItem: SelectSingleItem,
  MultiItem: SelectMultiItem,
  SingleValue: SelectSingleValue,
  MultiValue: SelectMultiValue,
  ListWrapper: SelectListWrapper,
};

const selectSearchSlots: Required<TBaseSelectSlots> = {
  ItemGroup: SelectItemGroup,
  SingleItem: ComboboxSingleItem,
  MultiItem: ComboboxMultiItem,
  SingleValue: SelectSingleValue,
  MultiValue: SelectMultiValue,
  ListWrapper: SelectSearchListWrapper as typeof defaultSlots.ListWrapper,
};

const autocompleteSlots: Required<TBaseSelectSlots> = {
  ItemGroup: SelectItemGroup,
  SingleItem: ComboboxSingleItem,
  MultiItem: ComboboxMultiItem,
  SingleValue: SelectSingleValue,
  MultiValue: SelectMultiValue,
  ListWrapper: ComboboxListWrapper as typeof defaultSlots.ListWrapper,
};

function getSlots<TItem extends TOptionBase = TOptionBase>(
  slots: TSelectSlots<TItem> = {},
  componentType: 'select' | 'combobox',
  comboboxStore?: ReturnType<typeof useSelectConfig>['comboboxStore'],
): TSelectSlots<TItem> & Required<TBaseSelectSlots<TItem>> {
  return {
    ...defaultSlots,
    // eslint-disable-next-line no-nested-ternary
    ...(comboboxStore ? (componentType === 'select' ? selectSearchSlots : autocompleteSlots) : {}),
    ...slots,
  } as TSelectSlots<TItem> & Required<TBaseSelectSlots<TItem>>;
}

export const SelectListLoader = ({ loaderText, numberOfRows = 5 }: { loaderText: string; numberOfRows?: number }) => {
  return (
    <div className="BlocksSelect-ListLoader">
      <div css={[flex.init, flex.row, flex.align.center, paddings.y.compact, spacings.medium]}>
        <CircularProgress size={20} />
        <Typography variant="body2" kind="medium" color="secondary">
          {loaderText}
        </Typography>
      </div>
      {[...Array(numberOfRows)].map((_, index) => (
        <div key={index} css={paddings.y.compact}>
          <Skeleton height={24} css={[paddings.y.superTight]} />
        </div>
      ))}
    </div>
  );
};

export const SelectRoot = <
  ComponentType extends 'select' | 'combobox' = 'select',
  Variant extends 'single' | 'multiple' = 'single',
  TItem extends TOptionBase = TOptionBase,
  TInternalOption extends DeprecatedOptionWithStringifiedValue<TItem> = DeprecatedOptionWithStringifiedValue<TItem>,
>({
  placeholder,
  label,
  required,
  showPartialList,
  componentType,
  selectStore,
  options,
  variant,
  kind,
  size = 'standard',
  renderInPlace,
  onItemClick,
  setValueOnClick,
  slots: userSlots,
  comboboxStore,
  emptyContent,
  ValueRenderer,
  showLoader,
  disabled,
  state,
  helperText,
  wrapperRef,
  comboboxTriggerRef,
  hideLabel,
  portal,
  sameWidth = true,
  popoverProps,
  ...props
}: TSelectRootProps<ComponentType, Variant, TItem, TInternalOption>): JSX.Element => {
  const { t: tt } = useTranslation('WEB');
  const [expanded, setExpanded] = useState(false);
  const {
    SingleItem,
    SingleValue,
    MultiItem,
    MultiValue,
    ItemGroup,
    ListWrapper,
    ListFooter,
    ListHeader,
    listPrefixContent,
    listLoader,
    ItemDescription,
    ItemPrefix,
    ItemSuffix,
  } = useMemo(() => getSlots(userSlots, componentType, comboboxStore), [userSlots, componentType, comboboxStore]);
  const labelId = useId();

  const consolidatedOptions = useMemo(() => {
    if (isNestedList(options)) {
      return options;
    }
    const selectedValues = selectStore.getState()?.value ?? [];
    const visibleItemList = getPartialVisibleItemList({
      showPartialList: !!showPartialList,
      expanded,
      options,
      selectedValues,
    });

    return [{ title: SELECT_INTERNAL_DUMMY_TITLE, list: visibleItemList }] as TNestedSelectOptions<TInternalOption>;
  }, [expanded, options, selectStore, showPartialList]);

  const ctxVal = useMemo(
    () => ({
      placeholder,
      kind,
      variant,
      size,
      options,
      selectStore,
      onItemClick,
      setValueOnClick,
      slots: { SingleItem, MultiItem, ItemDescription, ItemPrefix, ItemSuffix },
      comboboxStore,
      componentType,
      labelId,
      emptyContent,
      ValueRenderer,
      showLoader,
      disabled,
      state,
      helperText,
      required,
      portal,
      sameWidth,
      popoverProps,
    }),
    [
      placeholder,
      kind,
      variant,
      size,
      options,
      selectStore,
      onItemClick,
      setValueOnClick,
      SingleItem,
      MultiItem,
      ItemDescription,
      ItemPrefix,
      ItemSuffix,
      comboboxStore,
      componentType,
      labelId,
      emptyContent,
      ValueRenderer,
      showLoader,
      disabled,
      state,
      helperText,
      required,
      portal,
      sameWidth,
      popoverProps,
    ],
  );

  const isEmpty = useMemo(() => {
    return consolidatedOptions.every((group) => !group.list.length);
  }, [consolidatedOptions]);

  const selectState = selectStore.getState();
  if (selectState.activeId === null && !(componentType === 'select' && comboboxStore)) {
    selectStore.setActiveId(selectStore.first());
  }

  /* istanbul ignore next */
  const onClear = () => {
    if (variant === 'single') {
      (selectStore as SelectStore).setValue('');
      selectStore?.hide();
    } else {
      (selectStore as SelectStore).setValue([]);
      selectStore?.hide();
    }
    selectStore.clearSelection?.();
  };

  const onSetExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <SelectConfigContext.Provider value={ctxVal}>
      <div css={select_wrapper} {...props} ref={wrapperRef as React.Ref<HTMLDivElement>}>
        {!hideLabel && label && componentType === 'select' && (
          <SelectLabel
            render={
              <Typography id={labelId} variant="body2" kind="medium" color="secondary" as="label">
                {label}
                {required && (
                  <Typography variant="body2" color="error" kind="medium" as="label">
                    &nbsp;*
                  </Typography>
                )}
              </Typography>
            }
            id={labelId}
            store={selectStore as TSelectStore<Variant, TItem>}
          />
        )}

        {componentType === 'combobox' && (
          <ValueWrapper state={state} helperText={helperText}>
            <Typography
              id={labelId}
              variant="body2"
              kind="medium"
              color="secondary"
              as="label"
              css={[flex.init, flex.column, { gap: unit(0.25) }]}
            >
              {label}
              {required && (
                <Typography variant="body2" color="error" kind="medium" as="label">
                  &nbsp;*
                </Typography>
              )}
              <ComboboxTrigger ref={comboboxTriggerRef} state={state} />
            </Typography>
          </ValueWrapper>
        )}

        {componentType === 'select' && (
          <ValueWrapper state={state} helperText={helperText}>
            {!renderInPlace && variant === 'single' && <SingleValue placeholder={placeholder} />}
            {!renderInPlace && variant === 'multiple' && <MultiValue placeholder={placeholder} />}
          </ValueWrapper>
        )}

        <ListWrapper
          selectStore={selectStore as TSelectStore<Variant, TItem>}
          renderInPlace={renderInPlace}
          comboboxStore={comboboxStore}
        >
          {Boolean(ListHeader || (componentType === 'select' && comboboxStore)) && (
            <div className="BlocksSelect-ListHeader" css={input_wrapper}>
              {ListHeader && <ListHeader close={selectStore?.hide ?? noop} clear={onClear} heading={label} />}
              {componentType === 'select' && comboboxStore && (
                <SearchField icon="Search" hasHeader={Boolean(ListHeader)} renderInPlace={renderInPlace} />
              )}
            </div>
          )}
          <div className={clsx('BlocksSelect-OptionListWrapper', `option-list-size-${size}`, 'ItemListWrapper')}>
            {listPrefixContent}
            {showLoader &&
              (listLoader?.loadingComponent ?? (
                <SelectListLoader
                  loaderText={listLoader?.loaderText ?? tt('Loading...')}
                  numberOfRows={listLoader?.numberOfRows}
                />
              ))}
            {consolidatedOptions.map((group) => (
              <ItemGroup group={group} key={typeof group.title === 'string' ? group.title : group.title.value} />
            ))}
            {!showLoader &&
              isEmpty &&
              (emptyContent ?? (
                <Typography
                  color="secondary"
                  css={[paddings.x.compact, paddings.y.compact]}
                  variant={size === 'standard' ? 'body1' : 'body2'}
                >
                  {tt('No results found')}
                </Typography>
              ))}
            {showLoader && (listLoader?.loadingComponent ?? <div className="BottomLoader" />)}
          </div>

          {ListFooter && (
            <ListFooter
              variant={variant}
              clear={onClear}
              close={selectStore?.hide ?? noop}
              expanded={expanded}
              optionsLength={options?.length}
              onSetExpand={onSetExpand}
              selectAll={() => {
                if (variant === 'multiple') {
                  selectStore.setState('value', () => {
                    const value = selectStore
                      .getState()
                      .renderedItems.map((i) => i.value)
                      .filter(Boolean);

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return value as any;
                  });
                }
              }}
            />
          )}
        </ListWrapper>
      </div>
    </SelectConfigContext.Provider>
  );
};

/**
 *
 * @deprecated
 */
function SelectItemGroup<TItem extends TOptionBase>({
  group: { title: titleOrParentItem, list },
}: {
  group: TNestedSelectOptions<DeprecatedOptionWithStringifiedValue<TItem>>[0];
}) {
  const {
    variant,
    componentType,
    slots: { SingleItem, MultiItem },
    size,
  } = useSelectConfig();
  const title = titleOrParentItem;
  const Group = (componentType === 'select' ? SelectGroup : ComboboxGroup) as typeof SelectGroup;

  const GroupLabel = (componentType === 'select' ? SelectGroupLabel : ComboboxGroupLabel) as typeof SelectGroupLabel;

  const pseudoSelectItem = (() => {
    if (variant === 'multiple' && typeof titleOrParentItem === 'object') {
      const option = titleOrParentItem;
      return <MultiItem key={option.value} option={option} />;
    }
    return null;
  })();

  const labelContent = useMemo(() => {
    return typeof titleOrParentItem === 'string' ? (
      <GroupLabel
        className="BlocksSelect-GroupLabel"
        render={
          <div css={[flex.init, flex.justify.start, flex.align.center, margins.yb.half]}>
            <Typography
              variant={size === 'standard' ? 'body2' : 'body3'}
              color="secondary"
              css={[margins.xl.point75, margins.xr.half]}
            >
              {typeof title === 'string' ? title : title.label}
            </Typography>
          </div>
        }
      />
    ) : (
      pseudoSelectItem
    );
  }, [titleOrParentItem, size, GroupLabel, title, pseudoSelectItem]);

  const isDummy =
    typeof title === 'string' ? title === SELECT_INTERNAL_DUMMY_TITLE : title.label === SELECT_INTERNAL_DUMMY_TITLE;

  /**
   * Wrapper component is used to decide between flat & nested view without branching the code
   */
  const Wrapper = useCallback<FCWithChildren>(
    ({ children }) =>
      isDummy ? (
        <>{children}</>
      ) : (
        <Group className="BlocksSelect-Group">
          {labelContent}
          <div className="BlocksSelect-Group ChildWithPadding">{children}</div>
        </Group>
      ),
    [isDummy, Group, labelContent],
  );

  const ItemSlot = useCallback<React.FC<{ option: DeprecatedOptionWithStringifiedValue<TItem> }>>(
    ({ option }) => {
      if (variant === 'single') {
        return <SingleItem key={option.value} option={option} />;
      }
      if (variant === 'multiple') {
        return <MultiItem key={option.value} option={option} />;
      }
      return null;
    },
    [MultiItem, SingleItem, variant],
  );
  return (
    <Wrapper>
      {list.map((opt) => (
        <ItemSlot key={opt.value} option={opt} />
      ))}
    </Wrapper>
  );
}

function ValueWrapper({
  children,
  state,
  helperText,
  id,
}: {
  children: React.ReactNode;
  state?: TInputStates;
  helperText?: string;
  id?: string;
}) {
  return (
    <>
      {children}
      {helperText && (
        <FormHelperText id={id} css={form_helper_text} className={`state-${state}`}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}

function SearchField({
  hasHeader,
  icon,
  renderInPlace,
}: {
  hasHeader?: boolean;
  icon?: IconName;
  renderInPlace?: boolean;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t: tt } = useTranslation('WEB');
  const { selectStore, comboboxStore, disabled = false } = useSelectConfig();
  const open = selectStore?.useState('open');
  const placeholder = comboboxStore?.placeholder ?? `${tt('Search')}...`;

  /**
   * The issue here is on first render, the aria-kit popover renders on top of screen, then uses it's dimension to properly align it to the trigger element.
   * When we focused it in useEffect, the popover is out of screen, and default focus behavior on inputs basically focuses it into view, thus the scrolling issue.
   * Adding a delay, gives aria-kit time to position the popover correctly before we focus on the input.
   */
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;
    if (open && !renderInPlace) {
      timeoutId = setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [open, renderInPlace]);

  const renderInput = useCallback(
    (props: Omit<React.HTMLAttributes<HTMLElement>, 'color'>) => (
      <InputBase
        inputProps={{
          ...props,
          'aria-label': tt('Search'),
        }}
        ref={inputRef}
        placeholder={placeholder}
        {...{
          startAdornment: icon ? (
            <AdornmentWrapper variant="left">
              <Icon name="Search" />
            </AdornmentWrapper>
          ) : undefined,
          className: clsx('BlocksSelect-SearchField', { 'is-under-header': hasHeader }),
          fullWidth: true,
        }}
        size="standard"
      />
    ),
    [hasHeader, icon, placeholder, tt],
  );

  if (!comboboxStore) return null;

  return <Combobox disabled={disabled} store={comboboxStore} autoFocus={!renderInPlace} render={renderInput} />;
}

const Header: TSelectListSlots['ListHeader'] = ({ heading, close, closeLabel = 'Close', ...rest }) => {
  const isMobile = !useMediaQuery(breakpoints.from('tablet'));
  return (
    <div css={select_list_header} className="BlocksSelect-header" {...rest}>
      {/* Because selects render as bottom sheet in mobile, header should be H4 for accessibility */}
      <Typography variant={isMobile ? 'header4' : 'body1'} as="span" kind="semibold">
        {heading}
      </Typography>
      <IconButton
        tabIndex={-1}
        icon="CloseCross"
        tooltip={closeLabel}
        size="small"
        onClick={close}
        data-testid="BlocksSelect-MenuListTitle-Close"
      />
    </div>
  );
};

const Footer: TSelectListSlots['ListFooter'] = ({ children }) => {
  return (
    <div css={select_list_footer} className="BlocksSelect-footer">
      {children}
    </div>
  );
};

export const selectSlots = {
  ListHeader: Header,
  ListFooter: Footer,
};

/**
 * COMBOBOX COMPONENTS
 */

type SelectListActionProps = {
  iconName?: IconName;
  onClick: () => void;
  label: string;
  onBlur?: VoidFunction;
};

export const SelectListAction = forwardRef<HTMLButtonElement, SelectListActionProps>(
  ({ iconName, onClick, onBlur, label }: SelectListActionProps, ref) => {
    const { onClose } = useSelectConfig();
    const handleClick = () => {
      onClick();
      onClose?.();
    };
    return (
      <Button
        variant="ghost"
        startIcon={iconName}
        onClick={handleClick}
        onBlur={onBlur}
        size="large"
        fullWidth
        css={[flex.justify.start]}
        ref={ref}
      >
        {label}
      </Button>
    );
  },
);

export const ValueRenderer = <TItem extends TOptionBase>({
  option,
}: {
  option: TItem & {
    avatarInfo: { src: string; alt: string };
    description?: string;
    showFlags?: boolean;
  };
}) => (
  <div css={[flex.init, flex.align.center]}>
    {option.showFlags && <img src={option?.avatarInfo?.src} alt={option?.avatarInfo?.alt} />}
    <div css={[margins.xl.half]}>
      <Typography variant="body1" kind="medium" css={[flex.init, flex.align.start]}>
        {option.label}
      </Typography>

      {option?.description && (
        <Typography variant="body3" color="secondary">
          {option.description}
        </Typography>
      )}
    </div>
  </div>
);

/**
 * @deprecated temporary alias until we migrate to simpler Select API
 */
export const Select = SelectRoot;

// temporary aliases until all are incrementally migrated to new select
export const selectRootLocalItems = {
  SelectItemGroup,
  ValueWrapper,
  SearchField,
  SelectListAction,
  ValueRenderer,
  Header,
  Footer,
  getSlots,
};
