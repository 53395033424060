import { useTranslation } from 'react-i18next';
import type { AirRevalidateItineraryResponse } from '../../../types/api/v1/obt/air/air_revalidate_itinerary_response';
import { MoneyUtil } from '../../../utils/Money';
import { localisedOriginalCurrencyValue } from '../../../utils/Flights/fareBreakupUtils';
import type { IAirCheckoutFareBreakupNode } from './types';
import { AirCheckoutFareNodeTypeEnum, CheckoutColorVariantEnum } from './types';
import { defineMessage } from '../../../translations/defineMessage';
import { localizationKeys } from '../../../constants/common';

export interface IUsePointsFareBreakupDataProps {
  cashToBePaid: MoneyUtil;
  pointsUsed: number;
  creditCardFee: MoneyUtil;
  travelFundBalanceUsed: MoneyUtil;
  airRevalidateResponse: AirRevalidateItineraryResponse | null;
}

const { LOCALIZED_CURRENCY } = localizationKeys;

export const usePointsFareBreakupData = ({
  cashToBePaid,
  pointsUsed,
  travelFundBalanceUsed,
  creditCardFee,
  airRevalidateResponse,
}: IUsePointsFareBreakupDataProps) => {
  const { t } = useTranslation();

  const { t: tCommon } = useTranslation('COMMON');

  const fareBreakup: IAirCheckoutFareBreakupNode[] = [];

  const promotionApplied = airRevalidateResponse?.promotionApplied
    ? {
        promotionCode: airRevalidateResponse.promotionApplied.promotionCode,
        discountAmount: MoneyUtil.parse(airRevalidateResponse.promotionApplied.discountAmount),
      }
    : undefined;

  const promoCodeDiscount = promotionApplied?.discountAmount || MoneyUtil.zeroMoney();
  const totalCashAmount = creditCardFee.add(cashToBePaid).subtract(promoCodeDiscount);

  if (pointsUsed > 0) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Qantas points used')),
      value: `${pointsUsed.toLocaleString()} Pts`,
    });
  }

  if (!travelFundBalanceUsed.isZero()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Travel fund balance used')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(travelFundBalanceUsed)),
    });
  }

  if (promotionApplied && promotionApplied.discountAmount.isPositive()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Cash to be paid')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(cashToBePaid)),
    });
    fareBreakup.push(
      {
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon('Promo Code ({{promoCode}})', { promoCode: promotionApplied.promotionCode }),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(promotionApplied.discountAmount.multiply(-1))),
        colorVariant: CheckoutColorVariantEnum.SUCCESS,
      },
      {
        type: AirCheckoutFareNodeTypeEnum.DIVIDER,
      },
    );
  } else {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Cash to be paid')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(cashToBePaid)),
    });
  }

  if (!creditCardFee.isZero()) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Credit card fee')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(creditCardFee)),
    });
  }

  if (!travelFundBalanceUsed.isZero()) {
    const totalCashAmountWithTravelFund = totalCashAmount.add(travelFundBalanceUsed);
    const totalCashAmountString = t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalCashAmountWithTravelFund));

    fareBreakup.push(
      { type: AirCheckoutFareNodeTypeEnum.DIVIDER },
      {
        type: AirCheckoutFareNodeTypeEnum.HEADING,
        label: tCommon(defineMessage('Total Amount')),
        value: totalCashAmountString,
      },
    );
  } else if (pointsUsed > 0) {
    const totalCashAmountString = t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalCashAmount));

    fareBreakup.push(
      { type: AirCheckoutFareNodeTypeEnum.DIVIDER },
      {
        type: AirCheckoutFareNodeTypeEnum.HEADING,
        label: tCommon(defineMessage('Total Amount')),
        value: `${totalCashAmountString} + ${pointsUsed.toLocaleString()} Pts`,
      },
    );
  } else {
    const totalCashAmountString = t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalCashAmount));

    fareBreakup.push(
      { type: AirCheckoutFareNodeTypeEnum.DIVIDER },
      {
        type: AirCheckoutFareNodeTypeEnum.HEADING,
        label: tCommon(defineMessage('Total Amount')),
        value: totalCashAmountString,
      },
    );
  }

  return fareBreakup;
};
