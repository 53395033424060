import { useCallback, useEffect, useId, useMemo, useState } from 'react';

import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalClickableLinkPlugin from '@lexical/react/LexicalClickableLinkPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorState, LexicalEditor } from 'lexical';
import MaxLengthPlugin from './plugins/MaxLengthPlugin';

import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import LinkPlugin from './plugins/LinkPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import Toolbar from './Toolbar';
import { EditorToolbarCustomizationProps } from './types';
import { DEFAULT_EDITOR_CUSTOMIZATION_PROPS } from './constants';
import { CAN_USE_DOM } from './utils/shared';
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin';
import { clsx } from '../utils';

const DEFAULT_PLACEHOLDER_TEXT = 'Enter some rich text...';

type EditorProps = EditorToolbarCustomizationProps & {
  onHtmlChange?: (newHtml: string) => void;
  placeholderText?: string;
  state?: 'error' | 'success';
  inputId: string;
  ariaLabel?: string;
  maxLength?: number;
};

export const RichTextEditorInternal = ({
  onHtmlChange,
  placeholderText = DEFAULT_PLACEHOLDER_TEXT,
  state,
  inputId,
  ariaLabel,
  maxLength,
  ...editorCustomizationProps
}: EditorProps): JSX.Element => {
  const isEditable = useLexicalEditable();
  const countId = useId();

  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(null);
  const [isSmallWidthViewport, setIsSmallWidthViewport] = useState<boolean>(false);
  const [isLinkEditMode, setIsLinkEditMode] = useState<boolean>(false);

  const onEditorInnerContainerRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  useEffect(() => {
    const updateViewPortWidth = () => {
      const isNextSmallWidthViewport = CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

      if (isNextSmallWidthViewport !== isSmallWidthViewport) {
        setIsSmallWidthViewport(isNextSmallWidthViewport);
      }
    };

    window.addEventListener('resize', updateViewPortWidth);

    return () => {
      window.removeEventListener('resize', updateViewPortWidth);
    };
  }, [isSmallWidthViewport]);

  const onChange = useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      editorState.read(() => {
        const htmlString = $generateHtmlFromNodes(editor, null);
        onHtmlChange?.(htmlString);
      });
    },
    [onHtmlChange],
  );

  const customizationProps = useMemo(
    () => ({
      ...DEFAULT_EDITOR_CUSTOMIZATION_PROPS,
      ...editorCustomizationProps,
    }),
    [editorCustomizationProps],
  );

  return (
    <div className={clsx('spotnana-text-editor--editor__shell', state ? `state-${state}` : undefined)}>
      <Toolbar customizationProps={customizationProps} setIsLinkEditMode={setIsLinkEditMode} />
      <div className="spotnana-text-editor--editor__container">
        <AutoFocusPlugin />
        <AutoLinkPlugin />
        <RichTextPlugin
          contentEditable={
            <div className="spotnana-text-editor--editor__scroller-container">
              <div className="spotnana-text-editor--editor__inner-container" ref={onEditorInnerContainerRef}>
                <ContentEditable
                  className="spotnana-text-editor--content-editable__root"
                  id={inputId}
                  data-testid={`editor-${inputId}`}
                  ariaLabel={ariaLabel}
                  aria-describedby={countId}
                />
              </div>
            </div>
          }
          placeholder={<div className="spotnana-text-editor--placeholder__root">{placeholderText}</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <ListPlugin />
        <LinkPlugin />
        {!isEditable && <LexicalClickableLinkPlugin />}
        <TabFocusPlugin />
        <TabIndentationPlugin />
        {floatingAnchorElem && !isSmallWidthViewport && (
          <>
            <FloatingLinkEditorPlugin
              anchorElem={floatingAnchorElem}
              isLinkEditMode={isLinkEditMode}
              setIsLinkEditMode={setIsLinkEditMode}
            />
            {/**
             * Todo: Fix it's interactivity with user-click, currently after 3-4 clicks it works
             */
            /* <FloatingTextFormatToolbarPlugin
              anchorElem={floatingAnchorElem}
              showFontStyle={customizationProps.showFontStyle}
              setIsLinkEditMode={setIsLinkEditMode}
            /> */}
          </>
        )}
        {maxLength && <MaxLengthPlugin maxLength={maxLength} id={countId} />}
        <OnChangePlugin onChange={onChange} ignoreSelectionChange />
      </div>
    </div>
  );
};
