/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Value added service fee type
 * @export
 * @enum {string}
 */

export const ValueAddedServiceFeeType = {
    VirtualCardPayment: 'VIRTUAL_CARD_PAYMENT',
    PriceOptimization: 'PRICE_OPTIMIZATION',
    UnusedCreditApplication: 'UNUSED_CREDIT_APPLICATION'
} as const;

export type ValueAddedServiceFeeType = typeof ValueAddedServiceFeeType[keyof typeof ValueAddedServiceFeeType];



