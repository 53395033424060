/* eslint-disable */

import { Money } from "../../common/money";

export interface AirCancelPnrRequest {
  /**
   *  The spotnana assigned unique PNR id of the booking.
   */
  pnrId: string;
  /**
   *  The cancellation type being requested.
   */
  cancelType: AirCancelPnrRequestCancelType;

  /**
   * cancellation Option selected by user
   */
  optionId?: string;
  cancellationOverride?: AirCancelPnrRequestCancellationOverride;
}

export interface AirCancelPnrResponse {
  confirmationId: string;
}
export enum AirCancelPnrRequestCancelType {
  UNKNOWN = 0,
  /** VOID -  This state indicates that user has requested to void PNR which is still in void period so cancelling is free of
   cost.
   */
  VOID = 1,
  /** CANCEL -  This state indicates that user has requested to cancel/refund PNR which is not in void period so cancelling
   could be chargeable.
   */
  CANCEL = 2,
  UNRECOGNIZED = -1,
}

export interface AirCancelPnrRequestCancellationOverride {
  /**
   * A waiver code to be applied to the refund. Typically a waiver code will
   * be supplied by the airline for the agent to use to override a cancel fee.
   * Example: WAV123.
   */
  waiverCode: string;
  /**
   * A tour code to be applied to the refund. Input of a tourcode will
   * override any tourcode on the original ticket.
   * Example: TRAC345.
   */
  tourCode: string;
  /**
   * Amount of cancel penalty to apply to the refund. This will override any
   * penalty calculated by the system.
   * Example: 100.00.
   */
  newCancellationPenalty?: Money;
}
