import type { InfiniteData, UseMutationResult } from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import produce from 'immer';
import { useRef } from 'react';

import api from '../api';
import type SpotnanaError from '../api/SpotnanaError';
import type { AirModifyBookingRequest, AirModifyBookingResponse } from '../types';
import type { AirSearchResponse } from '../types/api/v1/obt/air/air_search_response';
import type { AirModifySearchRequest } from '../types/api/v2/obt/model/air-modify-search-request';
import type { SpotnanaQueryOptions, SpotnanaQueryResult } from '../types/common';
import { defaultQueryClient } from './defaultQueryClient';

export const airModifySearchKey = (request: AirModifySearchRequest): unknown[] => ['air-modify-search', request];
export type AirModifySearchKey = ReturnType<typeof airModifySearchKey>;
export const fetchAirModifySearchResult = async (request: AirModifySearchRequest): Promise<AirSearchResponse> => {
  const data = await api('POST', 'airModifySearch', { data: request }, { allowParallelRequests: true });
  const result = data as AirSearchResponse;

  return result;
};

export const useAirModifySearchQuery = (
  request: AirModifySearchRequest,
  options?: SpotnanaQueryOptions<AirSearchResponse, AirModifySearchKey>,
): SpotnanaQueryResult<AirSearchResponse> =>
  useQuery({ queryKey: airModifySearchKey(request), queryFn: () => fetchAirModifySearchResult(request), ...options });

/*
This mutation doesnt actually mutate data.
This is done to get the mutateAsync function to call the query imperetively
*/
export const useAirModifySearchMutation = (): UseMutationResult<
  AirSearchResponse | null,
  SpotnanaError,
  AirModifySearchRequest
> => useMutation({ mutationFn: (requestBody: AirModifySearchRequest) => fetchAirModifySearchResult(requestBody) });

export const useAirModifySearchInfiniteQuery = (request: AirModifySearchRequest) => {
  /**
   * Only initial request can be with empty `searchId: ""`!
   * Next requests:
   *  - user apply filters/sortings
   *  - getting next chunk of flights(pagination)
   * have to include `searchId` from initial response!!!
   * More info: ST-18057
   */
  const searchIdRef = useRef('');
  const queryKey = airModifySearchKey(request);

  return useInfiniteQuery<
    AirSearchResponse,
    SpotnanaError,
    InfiniteData<AirSearchResponse>,
    typeof queryKey,
    { pageNumber: number }
  >({
    queryKey,
    queryFn: ({ pageParam }) => {
      const noramizedRequest: AirModifySearchRequest = produce(request, (draftRequest) => {
        if (!draftRequest.legSearchParams) {
          draftRequest.legSearchParams = {};
        }

        const { current: searchId } = searchIdRef;

        if (searchId) {
          draftRequest.legSearchParams.searchId = searchId;
        }

        draftRequest.legSearchParams.pageNumber = pageParam?.pageNumber ?? 1;
      });

      return fetchAirModifySearchResult(noramizedRequest);
    },
    initialPageParam: { pageNumber: 1 },
    getNextPageParam: (lastPage) => {
      const currentPageNumber = lastPage?.paginationParams?.currentPageNumber ?? 1;
      const totalPages = lastPage?.paginationParams?.totalNumPages ?? 1;

      if (currentPageNumber < totalPages) {
        return { pageNumber: currentPageNumber + 1 };
      }

      return undefined;
    },
  });
};

export const airModifyPnr = async (requestBody: AirModifyBookingRequest): Promise<AirModifyBookingResponse> => {
  const data = await api('POST', 'airModifyPNR', {
    data: requestBody,
  });

  return data as AirModifyBookingResponse;
};

export const useAirExchangePnr = (): UseMutationResult<
  AirModifyBookingResponse,
  SpotnanaError,
  AirModifyBookingRequest
> => useMutation({ mutationFn: (requestBody: AirModifyBookingRequest) => airModifyPnr(requestBody) });

export const invalidateAirModifySearchResponses = (): Promise<void> =>
  defaultQueryClient.invalidateQueries({ queryKey: ['air-modify-search'] });
