import type { VirtualPaymentMetadata } from '@spotnana/types/openapi/models/virtual-payment-metadata';
import type {
  BookingPaymentSource,
  PointsBalance,
  VendorProgramPaymentDescriptor,
  VendorProgramPaymentMetadata,
} from '../../types';
import { PaymentSourceType } from '../../types';
import type { BrexBudgetMetadata } from '../../types/api/v2/obt/model/brex-budget-metadata';
import type { CardMetadata } from '../../types/api/v2/obt/model/card-metadata';
import type { VirtualCardMetadata } from '../../types/api/v2/obt/model/virtual-card-metadata';

/**
 * Type guard function to determine if a given payment source is of type "Card",
 * and if it contains a "cardMetadata" within its metadata.
 */
export function isCardPaymentSourceWithMetadata(
  paymentSource: BookingPaymentSource,
): paymentSource is BookingPaymentSource & { metadata: { cardMetadata: CardMetadata } } {
  const isCardPaymentSourceType = paymentSource.type === PaymentSourceType.Card;
  const hasMetadataAndCardMetadata = !!paymentSource.metadata && 'cardMetadata' in paymentSource.metadata;

  return isCardPaymentSourceType && hasMetadataAndCardMetadata;
}

/**
 * Type guard function to determine if a given payment source is of type "VirtualCard",
 * and if it contains a "virtualCardMetadata" within its metadata.
 */
export function isVirtualCardPaymentSourceWithMetadata(
  paymentSource: BookingPaymentSource,
): paymentSource is BookingPaymentSource & { metadata: { virtualCardMetadata: VirtualCardMetadata } } {
  const isVirtualCardPaymentSourceType = paymentSource.type === PaymentSourceType.VirtualCard;
  const hasMetadataAndVirtualCardMetadata = !!paymentSource.metadata && 'virtualCardMetadata' in paymentSource.metadata;

  return isVirtualCardPaymentSourceType && hasMetadataAndVirtualCardMetadata;
}

/**
 * Type guard function to determine if a given payment source is of type "CustomVirtualPayment",
 * and if it contains a "virtualPaymentMetadata" within customPaymentMethodMetadata in its metadata.
 */
export function isCustomVirtualPaymentSourceWithMetadata(
  paymentSource: BookingPaymentSource,
): paymentSource is BookingPaymentSource & {
  metadata: { customPaymentMethodMetadata: { virtualPaymentMetadata: VirtualPaymentMetadata } };
} {
  const isCustomVirtualPaymentSourceType = paymentSource.type === PaymentSourceType.CustomPaymentMethod;
  const hasMetadataAndCustomVirtualPaymentMetadata =
    isCustomVirtualPaymentSourceType &&
    !!paymentSource.metadata &&
    'customPaymentMethodMetadata' in paymentSource.metadata &&
    !!paymentSource.metadata?.customPaymentMethodMetadata &&
    'virtualPaymentMetadata' in paymentSource.metadata.customPaymentMethodMetadata &&
    !!paymentSource.metadata.customPaymentMethodMetadata.virtualPaymentMetadata;

  return hasMetadataAndCustomVirtualPaymentMetadata;
}

/**
 * Type guard function to determine if a given payment source is of type "VendorProgramPayment",
 * and if it contains a "vendorProgramPaymentDescriptor" within its metadata. Additionally, it verifies the presence
 * of "vendorProgramPaymentMetadata" within the "vendorProgramPaymentDescriptor".
 */
export function isVendorProgramPaymentWithVendorDescription(
  paymentSource: BookingPaymentSource,
): paymentSource is BookingPaymentSource & {
  metadata: { vendorProgramPaymentDescriptor: { vendorProgramPaymentMetadata: VendorProgramPaymentMetadata } };
} {
  const isVendorProgramPaymentSourceType = paymentSource.type === PaymentSourceType.VendorProgramPayment;
  const hasMetadataAndVendorProgramDescriptor = !!(
    !!paymentSource.metadata &&
    'vendorProgramPaymentDescriptor' in paymentSource.metadata &&
    paymentSource.metadata.vendorProgramPaymentDescriptor?.vendorProgramPaymentMetadata
  );
  return isVendorProgramPaymentSourceType && hasMetadataAndVendorProgramDescriptor;
}

/**
 * Type guard function to determine if a given payment source is of type "CustomPaymentMethod",
 * and if it contains a "customPaymentMethodMetadata" within its metadata. Additionally, it verifies the presence
 * of "brexBudgetMetadata".
 */
export function isCustomPaymentWithBrexBudgetMetadata(
  paymentSource: BookingPaymentSource,
): paymentSource is BookingPaymentSource & {
  metadata: { customPaymentMethodMetadata: { brexBudgetMetadata: BrexBudgetMetadata } };
} {
  const isCustomPaymentSourceType = paymentSource.type === PaymentSourceType.CustomPaymentMethod;
  const hasMetadataAndCustomMetadata =
    !!paymentSource.metadata &&
    'customPaymentMethodMetadata' in paymentSource.metadata &&
    !!paymentSource.metadata?.customPaymentMethodMetadata &&
    'brexBudgetMetadata' in paymentSource.metadata?.customPaymentMethodMetadata;

  return isCustomPaymentSourceType && hasMetadataAndCustomMetadata;
}

export function isRewardPaymentWithPoints(
  paymentSource: BookingPaymentSource,
): paymentSource is BookingPaymentSource & {
  metadata: { rewardsProgramMetadata: { pointsBalance: PointsBalance } };
} {
  const isRewardsPaymentSourceType = paymentSource.type === PaymentSourceType.RewardsProgram;
  const hasMetadataAndPointsBalance =
    !!paymentSource.metadata &&
    'rewardsProgramMetadata' in paymentSource.metadata &&
    !!paymentSource.metadata?.rewardsProgramMetadata &&
    'pointsBalance' in paymentSource.metadata?.rewardsProgramMetadata;

  return isRewardsPaymentSourceType && hasMetadataAndPointsBalance;
}

export function hasDirectBillingInVendorProgram(metadata: VendorProgramPaymentDescriptor): boolean {
  return !!(metadata.vendorProgramPaymentMetadata && 'directBilling' in metadata.vendorProgramPaymentMetadata);
}
