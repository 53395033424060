import flatten from 'lodash/flatten';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import { FormOfPaymentTypeEnum } from '@spotnana/types/openapi/models/form-of-payment';
import { TransactionType } from '@spotnana/types/openapi/models/transaction-type';
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import { getPaymentTripFeeTypeLabel } from '../../../utils/payment/getPaymentTripFeeLabel';
import { isFutureDate } from '../../../date-utils';
import { localizeDate } from '../../../translations/localizers';
import type { Transaction } from '../../../types/api/v2/obt/model/transaction';
import type { PaymentData } from '../../../types/api/v2/obt/model/payment-data';
import type { FormOfPayment } from '../../../types/api/v2/obt/model/form-of-payment';
import type { EntityAnswer } from '../../../types/api/v2/obt/model/entity-answer';
import { TicketTicketTypeEnum } from '../../../types/api/v2/obt/model/ticket';
import { getDisplayPreBookAnswer } from '../../../utils/getDisplayPreBookAnswer';
import { createServiceFeesWithTripFeeV3 } from '../../../utils/fareBreakupPaymentTripFees';
import { CardCompany } from '../../../types/api/v2/obt/model/card-company';
import { PaymentMethod } from '../../../types/api/v2/obt/model/payment-method';
import { PreCheckoutQuestionType } from '../../../types/api/v2/obt/model/pre-checkout-question-type';
import type { PnrData } from '../../../types/api/v2/obt/model/pnr-data';
import { PnrType } from '../../../types/api/v2/obt/model/pnr-type';
import { ThirdPartySource } from '../../../types/api/v2/obt/model/third-party-source';
import { MoneyUtil, createUserNameFromFullName, getNameStringFromName, maskCardNumber } from '../../../utils';
import { Persona } from '../../../types/api/v2/obt/model/persona';
import { UserFacingStatus } from '../../../types/api/v2/obt/model/user-facing-status';
import { defineCommonMessage } from '../../../translations/defineMessage';
import type {
  BasePriceBreakdown,
  CustomerCharge,
  PnrPaymentMethod,
  PnrTransaction,
  TravelData,
  TripServiceFeePnrTransaction,
} from '../types';
import { PnrStatus } from '../../../types/api/v2/obt/model/pnr-status';
import type { ItemGroup } from '../../../types/api/v2/obt/model/item-group';

export interface PnrV3ManagerProps {
  readonly pnrData: PnrData;
  readonly pnrId: string;
}
export class PnrV3Manager {
  pnrId: string;

  pnrData: PnrData;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    if (!pnrData || !pnrId) {
      throw new Error('Invalid param passed to PnrV3Manager');
    }
    this.pnrData = pnrData;
    this.pnrId = pnrId;
  }

  public pnrType(): PnrType {
    const pnr = this.pnrData;

    switch (true) {
      case Boolean(pnr.airPnr):
        return PnrType.Air;
      case Boolean(pnr.hotelPnr):
        return PnrType.Hotel;
      case Boolean(pnr.carPnr):
        return PnrType.Car;
      case Boolean(pnr.railPnr):
        return PnrType.Rail;
      case Boolean(pnr.limoPnr):
        return PnrType.Limo;
      default:
        throw new Error('Invalid PNR type');
    }
  }

  public serviceFee({ isTripFeeEnhancementEnabled = false }: { isTripFeeEnhancementEnabled?: boolean } = {}) {
    const serviceFees = this.pnrData.serviceFees?.[0];
    const visibleToTraveler = serviceFees?.visibleToTraveler ?? true;

    const base = MoneyUtil.convertV2MoneyToMoneyUtil(serviceFees?.fare.base);
    const tax = MoneyUtil.convertV2MoneyToMoneyUtil(serviceFees?.fare.tax);
    const total = base.add(tax);
    if (isTripFeeEnhancementEnabled) {
      const parsedServiceFees = createServiceFeesWithTripFeeV3(this.pnrData.serviceFees || []);
      return {
        ...parsedServiceFees,
        fop: serviceFees?.fop,
        visibleToTraveler,
      };
    }
    return {
      fop: serviceFees?.fop,
      base,
      tax,
      total,
      visibleToTraveler,
      tripFees: [],
      showWithFeeBreakup: isTripFeeEnhancementEnabled,
      isTotalHavingDifferentCurrency: false,
    };
  }

  public paymentInfo() {
    const { totalFareAmount, totalFare } = this.pnrData;
    const baseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareAmount?.base);
    const taxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareAmount?.tax);
    const totalAmount = totalFare ? MoneyUtil.convertV2MoneyToMoneyUtil(totalFare) : baseAmount.add(taxAmount); // This is needed because unknown bug where totalFare is undefined in PnrData but shows up correctly in tripData and obt calls to same pnr api.  Will need more investigation to determine root cause.

    const paymentMethods =
      this.pnrData.paymentInfo?.map((paymentInfo) => {
        if (paymentInfo.fop.paymentMethod === PaymentMethod.CreditCard) {
          return {
            type: paymentInfo.fop.paymentMethod,
            name: maskCardNumber(paymentInfo.fop.card?.number ?? ''),
          };
        }
        return {
          type: paymentInfo.fop.paymentMethod,
          name: '',
        };
      }) ?? [];

    return {
      baseAmount,
      taxAmount,
      totalAmount,
      paymentMethods,
    };
  }

  public travelerDetails() {
    const travelers = this.pnrData?.travelers?.map((pnrTraveler) => ({
      name: getNameStringFromName(pnrTraveler.user?.name),
      preferredName: getNameStringFromName(pnrTraveler.user?.name, { usePreferredName: true }),
      id: pnrTraveler.user?.id,
      loyaltyId: pnrTraveler.travelerPersonalInfo?.loyaltyInfos.map((l) => l.id).join(', ') ?? '',
    }));
    return travelers;
  }

  public travelData(): TravelData[] {
    const { preBookAnswers } = this.pnrData;

    const toTravelData = (entityAnswer: EntityAnswer) => {
      return {
        question: entityAnswer.questionDisplayText ?? '',
        isOOPReasonCode:
          entityAnswer.question?.questionType?.preCheckoutQuestionType === PreCheckoutQuestionType.OopReasonCode,
        answer: getDisplayPreBookAnswer(entityAnswer),
        customFieldLocations: entityAnswer.question?.customFieldLocations || [],
      };
    };

    // Prebook answers are stored at both the PNR level and the ticket level,
    // and both share the same entity ID for consistency.
    // When an exchange or a booking is ticketed for air, the prebook answers are saved at the ticket level,
    // but the entity ID remains the same as at the PNR level.
    // In such cases, the prebook answer stored at the ticket level (entityAnswer) should be considered,
    // as it contains the most relevant and up-to-date information.
    const findTicketEntityAnswer = (entityId?: string) => {
      if (this.pnrData.airPnr && entityId) {
        // a valid ticket will have a flightCoupons array with at least one element
        const flightTickets =
          this.pnrData.airPnr?.travelerInfos?.[0]?.tickets?.filter(
            (ticket) => ticket.ticketType === TicketTicketTypeEnum.Flight && (ticket.flightCoupons || []).length > 0,
          ) ?? [];

        for (const ticket of flightTickets) {
          const answer = ticket.preBookAnswers?.answers?.find((ans) => ans.entityId === entityId);
          if (answer) return answer;
        }
      }

      return undefined;
    };

    const pnrTravelData = preBookAnswers?.answers?.map((entityAnswer) => {
      const { entityId } = entityAnswer;
      const airPnrTicketEntityAnswer = findTicketEntityAnswer(entityId);
      if (airPnrTicketEntityAnswer) {
        return toTravelData(airPnrTicketEntityAnswer);
      }
      return toTravelData(entityAnswer);
    });

    return pnrTravelData ?? [];
  }

  public sourceInfo() {
    const { sourceInfo } = this.pnrData;
    const sourcePnrId = sourceInfo?.sourcePnrId ?? '';

    return {
      sourcePnrId,
      thirdPartySource: sourceInfo?.thirdParty || '',
      posDescriptor: sourceInfo?.posDescriptor,
    };
  }

  public isSpotnanaMerchant() {
    const isNotFareLogix = this.pnrData.sourceInfo?.thirdParty !== ThirdPartySource.FarelogixNdc;
    const hasCTC = !!this.pnrData.costToCustomer && this.pnrData.costToCustomer.payments.length > 0;
    return isNotFareLogix && hasCTC;
  }

  public isPnrOutOfPolicy() {
    return this.pnrData.policyInfo?.outOfPolicy ?? false;
  }

  public isPersonalBooking() {
    return first(this.pnrData?.pnrTravelers)?.persona === Persona.Personal;
  }

  public getPolicyInfo() {
    const { policyInfo } = this.pnrData;
    const isOutOfPolicy = policyInfo?.outOfPolicy === true;
    const ruleInfos = policyInfo?.appliedPolicyInfo?.ruleResultInfos ?? [];
    const violationInfos = flatten(ruleInfos.map((ruleResultInfo) => ruleResultInfo.violationInfos ?? []));
    return { isOutOfPolicy, violationInfos };
  }

  public isPnrOnHold() {
    const { bookingStatus } = this.pnrData;
    return bookingStatus === UserFacingStatus.HoldStatus;
  }

  public isPnrPending() {
    const { bookingStatus } = this.pnrData;
    return bookingStatus === UserFacingStatus.PendingStatus;
  }

  public isPnrUpcoming() {
    const { bookingStatus } = this.pnrData;
    return (
      bookingStatus === UserFacingStatus.ConfirmedStatus || bookingStatus === UserFacingStatus.ApprovalRequestedStatus
    );
  }

  public isPnrCancelled() {
    const USER_FACING_CANCELLED_STATUSES: UserFacingStatus[] = [
      UserFacingStatus.CancelledStatus,
      UserFacingStatus.VoidedStatus,
    ];

    const PNR_CANCELLED_STATUSES: PnrStatus[] = [PnrStatus.Cancelled, PnrStatus.CancelledByVendor, PnrStatus.Voided];

    const { pnrData } = this;
    if (pnrData.airPnr) {
      // we split the airPnr based on legs and show the status of the each leg
      const legStatus = first(pnrData.airPnr.legs)?.legStatus;
      return USER_FACING_CANCELLED_STATUSES.includes(legStatus ?? UserFacingStatus.UnknownStatus);
    }

    const pnrStatus =
      pnrData.hotelPnr?.pnrStatus ??
      pnrData.carPnr?.pnrStatus ??
      pnrData.railPnr?.inwardJourney?.journeyStatus ??
      pnrData.railPnr?.outwardJourney?.journeyStatus ??
      pnrData.limoPnr?.pnrStatus ??
      pnrData.miscPnr?.pnrStatus ??
      PnrStatus.Unknown;

    return PNR_CANCELLED_STATUSES.includes(pnrStatus);
  }

  public IsPnrCompleted(): boolean {
    const USER_FACING_COMPLETED_STATUSES: UserFacingStatus[] = [
      UserFacingStatus.CompletedStatus,
      UserFacingStatus.RefundedStatus,
    ];

    const { pnrData } = this;
    if (pnrData.airPnr) {
      const legStatuses = uniq(pnrData.airPnr.legs.map((leg) => leg.legStatus));
      return legStatuses.every((legStatus) =>
        USER_FACING_COMPLETED_STATUSES.includes(legStatus ?? UserFacingStatus.UnknownStatus),
      );
    }
    if (pnrData.hotelPnr) {
      const endDate = pnrData.hotelPnr.checkOutDateTime.iso8601;
      return !isFutureDate(endDate);
    }
    if (pnrData.carPnr) {
      const endDate = pnrData.carPnr.dropOffDateTime.iso8601;
      return !isFutureDate(endDate);
    }
    if (pnrData.railPnr) {
      const endDate = last(pnrData.railPnr.legInfos)?.arriveAtLocal?.iso8601;
      return endDate ? !isFutureDate(endDate) : false;
    }
    if (pnrData.limoPnr) {
      const endDate = last(pnrData.limoPnr.legs)?.dropOffDateTime?.iso8601;
      return endDate ? !isFutureDate(endDate) : false;
    }
    if (pnrData.miscPnr) {
      const endDate = pnrData.miscPnr.endDateTime.iso8601;
      return endDate ? !isFutureDate(endDate) : false;
    }

    // No PNR level status for completed status for hotel, car, rail, limo, misc
    return false;
  }

  public isVirtualCardBooking() {
    const isCustomVirtualPaymentMethod =
      first(this.pnrData?.paymentInfo)?.fop?.paymentMethod === PaymentMethod.CustomVirtualPayment;
    if (isCustomVirtualPaymentMethod) {
      return true;
    }
    return !!first(this.pnrData.costOfGoodsSold?.payments)?.fop?.paymentMetadata?.virtualCardMetadata;
  }

  static getPaymentFopInfo(fop: FormOfPayment | undefined) {
    if (!fop) {
      return {
        description: defineCommonMessage('Unknown'),
        cardCompany: CardCompany.None,
        cardNumber: '',
        cardLabel: '',
      };
    }
    let description = defineCommonMessage('Unknown');
    let cardCompany: CardCompany = CardCompany.None;
    const cardLabel = fop.card?.label ?? fop.card?.name ?? '';
    if (fop.paymentMethod === PaymentMethod.CreditCard || fop.type === FormOfPaymentTypeEnum.Card) {
      cardCompany = fop.card?.company ?? CardCompany.None;
      description = defineCommonMessage('Credit card');
    }
    if (fop.paymentMethod === PaymentMethod.QantasPoints || fop.type === FormOfPaymentTypeEnum.QantasPoints) {
      description = defineCommonMessage('Qantas points');
    }
    if (fop.paymentMethod === PaymentMethod.BrexPoints || fop.type === FormOfPaymentTypeEnum.BrexPoints) {
      description = defineCommonMessage('Brex points');
    }
    if (fop.paymentMethod === PaymentMethod.FlightCredits) {
      description = defineCommonMessage('Flight credits');
    }
    if (fop.paymentMethod === PaymentMethod.DelayedInvoicing) {
      description = defineCommonMessage('Billed to company');
    }
    if (fop.paymentMethod === PaymentMethod.Cash || fop.type === FormOfPaymentTypeEnum.Cash) {
      description = defineCommonMessage('Cash');
    }
    if (fop.paymentMethod === PaymentMethod.QantasTravelFund) {
      description = defineCommonMessage('Travel fund');
    }

    if (fop.paymentMethod === PaymentMethod.VendorProgramPayment && 'card' in fop) {
      description = defineCommonMessage('UATP');
      cardCompany = fop.card?.company ?? CardCompany.None;
    }

    if (
      fop.paymentMethod === PaymentMethod.VendorProgramPayment &&
      fop.paymentMetadata &&
      fop.paymentMetadata.vendorProgramPaymentMetadata &&
      'directBilling' in fop.paymentMetadata.vendorProgramPaymentMetadata
    ) {
      description = defineCommonMessage('Direct billing');
    }

    const cardNumber = maskCardNumber(fop.card?.number ?? '').slice(-9);

    if (fop.paymentMethod === PaymentMethod.CustomVirtualPayment) {
      description = defineCommonMessage('Virtual credit card');

      const maskedCreditCardNumber = cardNumber.slice(-4);
      const hasMaskedCreditCardNumber = maskedCreditCardNumber.length > 0;
      if (hasMaskedCreditCardNumber) {
        description = defineCommonMessage('Credit card *({{maskedCreditCardNumber}})');
        description.values = {
          maskedCreditCardNumber,
        };
      }
    }

    return {
      description,
      cardCompany,
      cardNumber,
      cardLabel,
      sourceType: fop.paymentSourceType,
    };
  }

  public paymentMethods(): PnrPaymentMethod[] | undefined {
    const paymentInfos = this.pnrData.paymentInfo;
    const visiblePaymentInfos = paymentInfos?.filter((paymentInfo) => {
      const { fop: serviceFeeFop, visibleToTraveler } = this.serviceFee();
      if (isEqual(paymentInfo.fop, serviceFeeFop) && !visibleToTraveler) {
        return false;
      }
      return true;
    });

    return visiblePaymentInfos?.map((paymentInfo) => {
      const { paymentMethod } = paymentInfo.fop;

      const totalChargeAmount = MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.totalCharge);
      const refundChargeAmount = MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.totalRefund);
      const netChargeAmount = MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.netCharge);

      let netCharge = netChargeAmount;
      let showChargeAmount = !(
        totalChargeAmount.isAmountZero() &&
        refundChargeAmount.isAmountZero() &&
        netChargeAmount.isAmountZero()
      );

      const showTotalAndRefundCharge = !totalChargeAmount.equals(netChargeAmount);

      const fopInfo = PnrV3Manager.getPaymentFopInfo(paymentInfo.fop);

      if (paymentInfo.fop.paymentMethod === PaymentMethod.QantasPoints) {
        netCharge = netChargeAmount.getQantasPoints()?.amount ? netChargeAmount : totalChargeAmount;
        showChargeAmount = !!netCharge.getQantasPoints()?.amount;
      }
      if (paymentInfo.fop.paymentMethod === PaymentMethod.BrexPoints) {
        netCharge = netChargeAmount.getBrexPoints()?.amount ? netChargeAmount : totalChargeAmount;
        showChargeAmount = !!netCharge.getBrexPoints()?.amount;
      }

      return {
        paymentMethod,
        ...fopInfo,

        totalCharge: totalChargeAmount,
        netCharge,
        refundCharge: refundChargeAmount,

        showChargeAmount,
        showTotalAndRefundCharge,
      };
    });
  }

  public qantasPoints() {
    const paymentInfos = this.pnrData.paymentInfo ?? [];
    let qantasPoints: MoneyUtil | null = null;
    let hasQantasPoints = false;

    paymentInfos.forEach(({ fop, netCharge, totalCharge }) => {
      if (fop.paymentMethod === PaymentMethod.QantasPoints) {
        hasQantasPoints = true;
        const charge = (netCharge?.otherCoinage ?? []).length > 0 ? netCharge : totalCharge;

        const chargeMoney = MoneyUtil.convertV2MoneyToMoneyUtil(charge);
        qantasPoints = qantasPoints ? qantasPoints.add(chargeMoney) : chargeMoney;
      }
    });

    return {
      hasQantasPoints,
      qantasPoints: qantasPoints ?? MoneyUtil.zeroMoney('AUD'),
    };
  }

  public isOutsideBooking() {
    return this.pnrData.sourceInfo?.thirdParty === 'OFFLINE';
  }

  /**
   * TRANSACTION RELATED METHODS
   */

  static isTripServiceFeePnrTransaction(
    pnrTransaction: PnrTransaction,
  ): pnrTransaction is TripServiceFeePnrTransaction {
    return pnrTransaction.type === 'TRIP_SERVICE_FEE';
  }

  getTransactionPriceBreakdown(itemGroup: ItemGroup): BasePriceBreakdown {
    const {
      transactionAmount,
      transactionFees,
      penalty,
      totalAmount: totalFare,
      totalAmountDiff: totalFareDiff,
      transactionType,
    } = itemGroup;
    const firstTransactionOfKind: TransactionType[] = [
      TransactionType.AirTicketIssued,
      TransactionType.CarBooked,
      TransactionType.HotelBooked,
      TransactionType.RailBooked,
    ];
    const baseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(transactionAmount?.base);
    const taxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(transactionAmount?.tax);
    const transactionFee = MoneyUtil.convertV2MoneyToMoneyUtil(transactionFees);
    const changePenalty = MoneyUtil.convertV2MoneyToMoneyUtil(penalty);

    const baseTotalAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFare?.base);
    const taxTotalAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFare?.tax);
    const totalAmount = baseTotalAmount.add(taxTotalAmount);

    // This is used to calculate the previous total amount
    const baseTotalAmountDiff = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareDiff?.base);
    const taxTotalAmountDiff = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareDiff?.tax);
    const totalAmountDiff = baseTotalAmountDiff.add(taxTotalAmountDiff);

    let previousTotalAmount = MoneyUtil.zeroMoney();

    if (!firstTransactionOfKind.includes(transactionType)) {
      previousTotalAmount = totalAmount.subtract(totalAmountDiff);
    }

    const transactionDate = localizeDate(itemGroup.transactionDateTime?.iso8601) ?? '';

    return {
      baseAmount,
      taxAmount,
      changePenalty,
      transactionFee,
      totalAmount,
      previousTotalAmount,
      transactionDate,
    };
  }

  getCustomerCharge(ctc: PaymentData): CustomerCharge {
    const fopInfo = PnrV3Manager.getPaymentFopInfo(ctc.fop);
    const isRefunded = ctc.isRefunded || false;
    const amount = MoneyUtil.convertV2MoneyToMoneyUtil(ctc.amount);
    const { isGuarantee } = ctc;
    return {
      fopInfo,
      isRefunded,
      amount,
      isGuarantee,
    };
  }

  getServiceFeeTransactionsWithLabel(): TripServiceFeePnrTransaction[] {
    const { tripFees } = this.serviceFee({ isTripFeeEnhancementEnabled: true });

    const travelerNamesMap: Record<string, string> = {};
    this.pnrData.pnrTravelers?.forEach((pnrTraveler) => {
      const travelerName = createUserNameFromFullName(pnrTraveler.personalInfo?.name);
      travelerNamesMap[pnrTraveler.userId.id] = travelerName;
    });

    if (
      // Check if service fees exist
      !tripFees ||
      tripFees.length === 0 ||
      // Do not show if transactions do not exist
      !this.pnrData.transactions ||
      this.pnrData.transactions.length === 0
    ) {
      return [];
    }

    const firstTransaction = this.pnrData.transactions[0];
    const firstItemGroup = firstTransaction.itemGroups?.[0];

    return tripFees
      .filter((serviceFee) => serviceFee.visibleToTraveler)
      .map((serviceFee) => {
        const { base, tax, fop, ticketNumber } = serviceFee;
        const totalAmount = tax ? base.add(tax) : base;
        const serviceFeeCtc: PaymentData = {
          amount: totalAmount.toMoneyV2Type(),
          fop,
          isRefunded: false,
        };

        const itemGroup = {
          ...firstItemGroup,
          transactionType: TransactionType.TripServiceFee,
          transactionAmount: {
            base: base.toMoneyV2Type(),
            tax: tax?.toMoneyV2Type() ?? MoneyUtil.zeroMoney().toMoneyV2Type(),
          },
          totalAmount: {
            base: base.toMoneyV2Type(),
            tax: tax?.toMoneyV2Type() ?? MoneyUtil.zeroMoney().toMoneyV2Type(),
          },
          invoiceData: {
            buyerInfo: firstItemGroup?.invoiceData?.buyerInfo,
            sellerInfo: firstItemGroup?.invoiceData?.sellerInfo,
            invoiceNumber: '',
          },
          confirmationNumber: ticketNumber,
          transactionFees: undefined,
          penalty: undefined,
        };

        const travelerNames = itemGroup.userId?.id
          ? [travelerNamesMap[itemGroup.userId.id]]
          : Object.values(travelerNamesMap);

        const description = getPaymentTripFeeTypeLabel(serviceFee, true);
        const transactionDate = localizeDate(itemGroup.transactionDateTime?.iso8601) ?? '';

        return {
          ...itemGroup,
          type: 'TRIP_SERVICE_FEE',
          ctc: [serviceFeeCtc],
          customerCharges: [this.getCustomerCharge(serviceFeeCtc)],
          priceBreakdown: {
            baseAmount: base,
            taxAmount: tax ?? MoneyUtil.zeroMoney(),
            transactionFee: MoneyUtil.zeroMoney(),
            changePenalty: MoneyUtil.zeroMoney(),
            totalAmount,
            previousTotalAmount: MoneyUtil.zeroMoney(),
            transactionDate,
          },
          transactionDetails: {
            description,
          },
          pnrId: this.pnrId,
          travelerNames,
        };
      });
  }

  getServiceFeeTransactions(isTripFeeEnhancementEnabled: boolean): TripServiceFeePnrTransaction[] {
    const travelerNamesMap: Record<string, string> = {};
    this.pnrData.pnrTravelers?.forEach((pnrTraveler) => {
      const travelerName = createUserNameFromFullName(pnrTraveler.personalInfo?.name);
      travelerNamesMap[pnrTraveler.userId.id] = travelerName;
    });

    let relevantTransactions = (this.pnrData?.transactions || []).filter((transaction) =>
      transaction.itemGroups?.some((itemGroup) => itemGroup.transactionType === TransactionType.TripServiceFee),
    );

    // added this as have to show different label for each type of trip fee
    if (isTripFeeEnhancementEnabled) {
      return this.getServiceFeeTransactionsWithLabel();
    }

    if (relevantTransactions.length === 0) {
      relevantTransactions = this.getServiceFeePlaceholderTransactions();
    }

    return relevantTransactions.flatMap((transaction) => {
      return (transaction.itemGroups || [])
        .filter((itemGroup) => itemGroup.transactionType === TransactionType.TripServiceFee)
        .map((itemGroup) => {
          const travelerNames = itemGroup.userId?.id
            ? [travelerNamesMap[itemGroup.userId.id]]
            : Object.values(travelerNamesMap);

          return {
            ...itemGroup,
            type: 'TRIP_SERVICE_FEE',
            ctc: transaction.ctc || [],
            customerCharges: (transaction.ctc || []).map(this.getCustomerCharge),
            priceBreakdown: this.getTransactionPriceBreakdown(itemGroup),
            transactionDetails: {
              description: defineCommonMessage('Fee'),
            },
            pnrId: this.pnrId,
            travelerNames,
          };
        });
    });
  }

  // This is temporary. Please remove it if change for adding service fee transaction is done in the future.
  // This adds the service fee transaction manually till BE sends it
  public getServiceFeePlaceholderTransactions(): Transaction[] {
    const { serviceFees } = this.pnrData;

    if (
      // Check if service fees exist
      !serviceFees ||
      serviceFees.length === 0 ||
      // Do not show if transactions do not exist
      !this.pnrData.transactions ||
      this.pnrData.transactions.length === 0 ||
      // Do not show if service fee transaction already exists
      this.pnrData.transactions.some((transaction) =>
        transaction.itemGroups?.some((itemGroup) => itemGroup.transactionType === TransactionType.TripServiceFee),
      )
    ) {
      return [];
    }
    const firstTransaction = this.pnrData.transactions[0];
    return serviceFees
      .filter((serviceFee) => serviceFee.visibleToTraveler)
      .map((serviceFee) => {
        const { fare, fop, ticketNumber } = serviceFee;
        const baseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(fare.base);
        const taxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(fare.tax);
        const totalAmount = baseAmount.add(taxAmount);
        const serviceFeeCtc: PaymentData = {
          amount: totalAmount.toMoneyV2Type(),
          fop,
          isRefunded: false,
        };
        const firstItemGroup = firstTransaction.itemGroups?.[0];

        return {
          ctc: [serviceFeeCtc],
          itemGroups: [
            {
              ...firstItemGroup,
              transactionType: TransactionType.TripServiceFee,
              transactionAmount: fare,
              totalAmount: fare,
              invoiceData: {
                buyerInfo: firstItemGroup?.invoiceData?.buyerInfo,
                sellerInfo: firstItemGroup?.invoiceData?.sellerInfo,
                invoiceNumber: '',
              },
              confirmationNumber: ticketNumber,
              transactionFees: undefined,
              penalty: undefined,
            },
          ],
        };
      });
  }
}
