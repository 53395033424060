import type { LimoSortOrder } from '@spotnana/types/openapi/models/limo-sort-order';
import type { LimoType } from '@spotnana/types/openapi/models/limo-type';
import type { LimoSearchRequest } from '@spotnana/types/openapi/models/limo-search-request';
import type { LimoBookingNote } from '@spotnana/types/openapi/models/limo-booking-note';
import type { LimoVendorInfo } from '@spotnana/types/openapi/models/limo-vendor-info';
import type { LimoVendorMinimal } from '@spotnana/types/openapi/models/limo-vendor-minimal';
import type { ISuggestion } from './autocomplete';
import type { BookingFlow, SharedSearchParams } from './common';
import type { Latlng } from './api/v1/common/latlng';
import type { MoneyUtil } from '../utils/Money';
import type { PaymentSourceType } from './api/v2/obt/model';

export interface LimoFareBreakupDetails {
  basePrice: MoneyUtil;
  feesPrice: MoneyUtil;
  totalPrice: MoneyUtil;
  totalExtraCharges: MoneyUtil;
}

export interface ILimoSearchFormUrlParams extends SharedSearchParams {
  fromDate?: string;
  toDate?: string;
  duration?: string;
  isPersonalTrip?: boolean;
  pickup?: ISuggestion | null;
  dropOff?: ISuggestion | null;
  selectedTab?: ILimoSearchType;
}

export interface ILimoSearchErrorState {
  pickup: string;
  dropOff: string;
  from: string;
  to: string;
  flightNumber: string;
}

export enum LimoSearchType {
  DURATION = 'DURATION',
  TRANSFER = 'TRANSFER',
}

export type ILimoSearchType = keyof typeof LimoSearchType;

type ISearchParams = {
  pickup: ISuggestion | null;
  dropOff: ISuggestion | null;
  dateTime: { from: string; to: string };
};

export interface ILimoSearchParams extends ISearchParams {
  filters?: ILimoFilterState;
  sortType?: LimoSortOrder;
  searchType: ILimoSearchType;
  duration?: number;
  flightNumber?: string;
}

export interface ILimoFilterState {
  limoTypes: LimoType[];
  vendors: LimoVendorMinimal[];
}

interface LimoSearchResultsFEOnlyUrlParams {
  tripId?: string;
  isPreselectedTrip?: boolean;
  pickupLocation: ISuggestion;
  dropOffLocation: ISuggestion;
  isPersonalTravel?: boolean;
  utm_source?: string;
  bookingFlow: BookingFlow;
  registrarId?: string;
  userId?: string;
  flightNumber: string;
}

export type ILimoSearchResultsUrlParams = Omit<LimoSearchRequest, 'travelerInfos'> & LimoSearchResultsFEOnlyUrlParams;

export interface ILimoSummary {
  displayName: string;
  vendor?: LimoVendorInfo;
  imageUrl: string;
  amenities: {
    numberOfBags: number;
    numPassengers: number;
  };
  totalCost: MoneyUtil;
  bookingDetails: LimoBookingNote[];
  pickupTime: string;
  dropOffTime: string;
}

export interface ILimoLocationDetails {
  address: string;
  coordinates?: Latlng;
}

export interface ILimoCheckoutUrlParams extends ILimoSearchResultsUrlParams {
  limoId: string;
  paymentSourceId?: string;
  paymentSourceType?: PaymentSourceType;
}

export interface ILimoRate {
  label: string;
  price: MoneyUtil;
}
