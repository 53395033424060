import React, { createContext, useContext } from 'react';

import { PopoverOptions, SelectItemProps } from '@ariakit/react';
import { Interpolation, Theme } from '@emotion/react';

import { DeprecatedOptionWithStringifiedValue } from '../SelectRoot/helpers';

import type { TInputStates } from '../Input';

import { TSelectSizes, TSelectSlots, TSelectStore } from '../SelectRoot';
import {
  TSelectComboboxRendererProps,
  TSelectOption,
  TSelectOptionList,
  TComboboxStore,
} from '../Select/SelectRenderer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SelectConfigContext = createContext<any>(undefined);

export type SelectVirtualProps = {
  enabled: boolean;
  /**
   * Approximate pixel size of each item. You can "Inspect Element" on non-virtualised list item and hover over the item to copy the size.
   * Even with auto-sizing on, it is required to provide this. Autosizing only calculates the correct size when each virtual item enters the DOM.
   */
  estimateSize?: (index: number) => number;

  /**
   * If the virtual list has prefixContent or search bar, you can provide the height of that content to avoid overlapping.
   */
  startOffset?: number;

  /**
   * Height of the scrollable area
   */
  listHeight?: number;
};

export function useSelectConfig<
  ComponentType extends 'select' | 'combobox' = 'select',
  Variant extends 'single' | 'multiple' = 'single' | 'multiple',
  TItem extends TSelectOption = TSelectOption,
  // Searchable extends boolean = false,
  TInternalOption extends DeprecatedOptionWithStringifiedValue<TItem> = DeprecatedOptionWithStringifiedValue<TItem>,
>() {
  return useContext(SelectConfigContext) as unknown as {
    options: TSelectOptionList<TInternalOption>;
    comboboxStore: ComponentType extends 'select' ? TComboboxStore | undefined : TComboboxStore;
    selectStore: TSelectStore<Variant, TItem>;
    variant: Variant;
    size?: TSelectSizes;
    slots: Required<TSelectSlots<TItem>> & TSelectSlots<TItem>;
    componentType: ComponentType;
    labelId: string;
    label?: string;
    ariaLabelledBy?: string;

    onItemClick?: (item: TItem) => void;
    setValueOnClick?: SelectItemProps['setValueOnClick'];
    kind?: TSelectComboboxRendererProps<ComponentType, Variant, TItem>['kind'];
    placeholder?: string;
    showLoader?: boolean;
    showClearButton?: boolean;
    disabled?: boolean;
    state?: TInputStates;
    portal?: boolean;
    fixed?: boolean;
    sameWidth?: boolean;
    helperText?: string;
    ValueRenderer?: (props: { option: TItem }) => JSX.Element;
    popoverProps?: SelectPopoverProps;
    drawerCss?: Interpolation<Theme>;
    virtual?: SelectVirtualProps;
    hasSearch?: boolean;
    onClose?: () => void;
    triggerRef?: React.Ref<HTMLElement>;
    containerRef?: React.RefObject<HTMLDivElement>;
    required?: boolean;
  };
}

export type SelectPopoverProps = Omit<PopoverOptions<'div'>, 'store'> & { style?: React.CSSProperties };
