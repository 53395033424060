import { useTranslation } from 'react-i18next';
import { defineMessage } from '../../translations/defineMessage';
import type { CancellationBreakupComponent } from '../../types';
import { CancellationBreakupFontEnum, CancellationColorEnum, PaymentMethodEnum } from '../../types';
import type { MoneyUtil } from '../../utils';
import { localisedCurrencyValue, localisedOriginalCurrencyValue } from '../../utils';
import type { IFareBreakup } from './useGetItineraryFareBreakup';
import { useGetAirExchangeFareBreakupTripFee } from './useGetAirExchangeFareBreakupTripFee';
import { localizationKeys } from '../../constants';
import type { AirRevalidateItineraryResponse } from '../../types/api/v1/obt/air/air_revalidate_itinerary_response';
import { getFlightFaresForExchange } from '../../utils/Flights/getFlightFaresForExchange';

const { LOCALIZED_CURRENCY } = localizationKeys;

export function useGetAirExchangeFareBreakup(
  revalidateItineraryResponse: AirRevalidateItineraryResponse | undefined,
  fareBreakup: IFareBreakup,
  waiverPenaltyAmount: MoneyUtil | undefined,
): { fareBeakupConfig: CancellationBreakupComponent[]; isAirExchangeRefundable: boolean } {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation('COMMON');
  const { serviceFees } = fareBreakup;
  const { getExchangeFareBreakupTripFees } = useGetAirExchangeFareBreakupTripFee();
  const { fareDifference, totalFare, changeFee, isAirExchangeRefundable, isSameCurrency, allowedFop } =
    getFlightFaresForExchange({
      revalidateItineraryResponse,
      fareBreakupFromAirSelectedItinerary: fareBreakup,
      waiverPenaltyAmount,
    });

  const serviceFeesHavingDifferentOriginalCurrency =
    totalFare &&
    !isAirExchangeRefundable && // when non refundabable at that time adding service fee to total
    serviceFees.showWithFeeBreakup &&
    !serviceFees.total.isAmountZero() &&
    (serviceFees.total.getOriginalCurrency() !== totalFare.getOriginalCurrency() ||
      serviceFees.isTotalHavingDifferentCurrency);

  const totalBrexAmount = totalFare.getBrexPoints()?.amount;

  const getFormattedTotalDisplayAmount = () => {
    if (isAirExchangeRefundable) {
      return t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalFare.multiply(-1)));
    }
    if (serviceFeesHavingDifferentOriginalCurrency) {
      return `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(totalFare))}`;
    }
    return t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalFare));
  };

  let fareBeakupConfig: CancellationBreakupComponent[] = [];

  fareBeakupConfig = [
    {
      label: tCommon(defineMessage('Fare difference')),
      value: `${t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(fareDifference))}`,
      variation: CancellationBreakupFontEnum.SUB_HEADING,
      children: [
        {
          label: tCommon(defineMessage('Including taxes and fees')),
          variation: CancellationBreakupFontEnum.LABEL,
        },
      ],
    },
    {
      label: tCommon(defineMessage('Change penalty')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(changeFee)),
      variation: CancellationBreakupFontEnum.SUB_HEADING,
    },
    ...getExchangeFareBreakupTripFees({ serviceFees }),
    {
      variation: CancellationBreakupFontEnum.DIVIDER,
    },
    {
      label: isAirExchangeRefundable
        ? tCommon(defineMessage('Potential Refund/Credit/Loss'))
        : tCommon(defineMessage('Total due')),
      value: getFormattedTotalDisplayAmount(),
      variation: CancellationBreakupFontEnum.HEADING,
      colorVariation: CancellationColorEnum.NORMAL,
    },
  ];

  if (!isSameCurrency && !serviceFeesHavingDifferentOriginalCurrency) {
    fareBeakupConfig.push({
      value: isAirExchangeRefundable
        ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(totalFare.multiply(-1)))}`
        : `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(totalFare))}`,
      variation: CancellationBreakupFontEnum.ONLY_VALUE,
    });
  }

  if (allowedFop?.includes(PaymentMethodEnum.BREX_POINTS) && totalBrexAmount) {
    fareBeakupConfig.push({
      value: `or ${totalBrexAmount.toLocaleString()} Pts`,
      variation: CancellationBreakupFontEnum.ONLY_VALUE,
    });
  }

  return { fareBeakupConfig, isAirExchangeRefundable };
}
