import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import { localizationKeys } from '../../../constants';

import { defineMessage } from '../../../translations/defineMessage';
import type { IServiceFeeData } from '../../../types';
import type { MoneyUtil } from '../../../utils';
import { localisedCurrencyValue, localisedOriginalCurrencyValue } from '../../../utils';

import type { HotelFareBreakupSlotConfig } from './types';
import { HotelFareBreakupSlotFareVariant, HotelFareBreakupSlotType } from './types';
import { getPaymentTripFeeTypeLabel } from '../../../utils/payment/getPaymentTripFeeLabel';
import { TripFeeLabels } from '../../../constants/paymentTripFees';

interface ICheckIsServiceFeesWithTripFeeBreakupVisible {
  serviceFees: IServiceFeeData;
}
export const checkIsServiceFeesWithTripFeeBreakupVisible = ({
  serviceFees,
}: ICheckIsServiceFeesWithTripFeeBreakupVisible) => {
  return !!(serviceFees.showWithFeeBreakup && !serviceFees.total.isZero());
};

interface ICheckServiceFeesOriginalCurrencyMismatched {
  serviceFees: IServiceFeeData;
  amountToCompare: MoneyUtil;
}

export const checkServiceFeesOriginalCurrencyMismatched = ({
  serviceFees,
  amountToCompare,
}: ICheckServiceFeesOriginalCurrencyMismatched) => {
  return (
    !serviceFees.total.isZero() &&
    (serviceFees.total.getOriginalCurrency() !== amountToCompare.getOriginalCurrency() ||
      serviceFees.isTotalHavingDifferentCurrency)
  );
};

export const checkServiceFeesOriginalCurrencyMismatchedWithBreakupCheck = ({
  serviceFees,
  amountToCompare,
}: ICheckServiceFeesOriginalCurrencyMismatched) => {
  return !!(
    serviceFees.showWithFeeBreakup && checkServiceFeesOriginalCurrencyMismatched({ serviceFees, amountToCompare })
  );
};

interface IGetFareBreakupTripFeesProps {
  serviceFees: IServiceFeeData;
  showAlreadyPaidPrefix?: boolean;
}
interface IParams extends IGetFareBreakupTripFeesProps {
  t: TFunction;
  tCommon: TFunction;
}
const { LOCALIZED_CURRENCY } = localizationKeys;

function getFareBreakupTripFeesUtil({ serviceFees, t, tCommon, showAlreadyPaidPrefix = false }: IParams) {
  const formatPrice = (value: MoneyUtil): string => t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(value));
  const formatLocalizedPrice = (value: MoneyUtil): string => t(LOCALIZED_CURRENCY, localisedCurrencyValue(value));
  const alreadyPaidSuffix = showAlreadyPaidPrefix ? tCommon(defineMessage('(Already Paid)')) : '';
  const tripFeeNodes: HotelFareBreakupSlotConfig[] = [];
  if (!checkIsServiceFeesWithTripFeeBreakupVisible({ serviceFees })) {
    return tripFeeNodes;
  }

  tripFeeNodes.push({
    type: HotelFareBreakupSlotType.Fare,
    variant: HotelFareBreakupSlotFareVariant.Emphasized,
    label: tCommon(TripFeeLabels.TRIP_FEES_STAR),
    value: serviceFees.isTotalHavingDifferentCurrency
      ? `~${formatLocalizedPrice(serviceFees.total)}`
      : formatPrice(serviceFees.total),
    testID: 'hotel-details-fare-breakup.service-fee-trip-fees-total',
    infoHeader: tCommon(TripFeeLabels.TRIP_FEES_STAR),
    infoBody: tCommon(defineMessage('Total fees charged by the travel management company')),
  });
  serviceFees.tripFees?.forEach((tripFee, index) => {
    if (!tripFee.base.isZero()) {
      tripFeeNodes.push({
        type: HotelFareBreakupSlotType.Fare,
        label: tCommon(getPaymentTripFeeTypeLabel(tripFee)),
        value: [formatPrice(tripFee.base), alreadyPaidSuffix].join(' '),
        testID: `hotel-details-fare-breakup.service-fee-trip-fees-breakup${index}`,
      });
    }
  });

  if (serviceFees.showTax) {
    tripFeeNodes.push({
      type: HotelFareBreakupSlotType.Fare,
      label: tCommon(TripFeeLabels.TRIP_FEES_TAXES),
      value: [formatPrice(serviceFees.tax), alreadyPaidSuffix].join(' '),
      testID: 'hotel-details-fare-breakup.service-fee-trip-fees-tax',
    });
  }

  tripFeeNodes.push({
    type: HotelFareBreakupSlotType.Fare,
    label: '',
    value: '',
    testID: 'hotel-details-fare-breakup.service-fee-trip-fees-text',
    subLabel: tCommon(TripFeeLabels.NON_REFUNDABLE),
  });
  return tripFeeNodes;
}

export const useGetServiceFeeWithTripFeeBreakup = () => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation('COMMON');
  const getFareBreakupTripFees = useCallback(
    ({ serviceFees, showAlreadyPaidPrefix }: IGetFareBreakupTripFeesProps): HotelFareBreakupSlotConfig[] => {
      return getFareBreakupTripFeesUtil({ serviceFees, t, tCommon, showAlreadyPaidPrefix });
    },
    [t, tCommon],
  );
  return {
    getFareBreakupTripFees,
  };
};
