/* eslint-disable no-nested-ternary */
import { useDebounceCallback } from '@react-hook/debounce';
import isEqual from 'lodash/isEqual';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  RBAC,
  TravelerBasicInfo,
  UserOrgId,
  globalTranslationKeys,
  useFeatureFlag,
  useHasUserAccess,
  useTravelerEntitySearchAutocompleteQuery,
  userRolesByFeature,
} from 'obt-common';
import type { AutocompleteFilter } from 'obt-common/types/api/v2/obt/model/autocomplete-filter';

import ClickAwayListener from '@spotnana/pixel-react/dist/ClickAwayListener';
import Typography from '@spotnana/pixel-react/dist/Typography';

import { Button } from '@spotnana/blocks/src/Button/Button';
import { Icon } from '@spotnana/blocks/src/Icon/Icon';
import { RoleInfoType } from 'obt-common/types/api/v1/obt/profile/role/roles_info';
import { SelectListAction } from '@spotnana/blocks/src/SelectRoot';
import TravelerLoader from 'src/v1-components/shared/TravelerSelector/TravelerLoader';
import Divider from '@spotnana/pixel-react/dist/Divider';
import ClickableBox from 'src/v1-components/shared/ClickableBox';
import { flex, margins } from '@spotnana/blocks/src/utils';
import { TravelerPickerOptionPrefix } from 'src/app/shared/components/GroupBookingTravelerSelector/TravelerPickerOption/TravelerPickerOptionPrefix';
import { EntityAutocompleteProfileOptionDescription } from 'src/v1-components/shared/EntityAutocomplete/EntityAutocompleteProfileOptionDescription';
import { TravelerAutocomplete } from 'src/app/shared/components/TravelerAutocomplete/TravelerAutocomplete';
import { AutocompleteExpandDirections } from '../../../constants/autocomplete';
import Autocomplete, { AutocompleteProps } from '../Autocomplete';

import AutocompleteInput from './AutocompleteInput';
import AutocompleteOption from './AutocompleteOption';
import NoResults from './NoResults';
import {
  add_traveler_container,
  add_traveler_row,
  create_new_traveler_button,
  getAutocompleteContainerBoxProps,
  getAutocompleteInputStylingProps,
  getAutocompletePopoverProps,
  large_icon,
  no_result_found_text,
} from './styles';

import { TravelerAutocompleteModal } from './TravelerAutocompleteModal';
import { icon_style } from './index.styles';
import { AddMeButton } from './AddMeButton';

const { NO_TRAVELER_PROFILES_FOUND, NO_RESULTS_FOUND } = globalTranslationKeys;

type AutocompletePropsType = AutocompleteProps<TravelerBasicInfo>;

export interface ISelectTravelerProps {
  dataTestId?: string;
  onSelect(selection: TravelerBasicInfo | null): void;
  onClose: VoidFunction;
  onAddMe?: VoidFunction;
  error?: string;
  expandDirection?: AutocompleteExpandDirections;
  alreadySelectedTravelerIds?: UserOrgId[];

  openRegisterModal?: () => void;
  showRegisterTravelerOnFooter?: boolean;
  registerNewTravelerText?: string;
  outerContainerStyles?: AutocompleteProps<TravelerBasicInfo>['outerContainerStyles'];
  clickAwayListernerStyles?: CSSProperties;
  inputContainerWidth?: string;
  onCreateNewTraveler?: () => void;
  onSearchValueChange?: (value: string) => void;
  createNewTravelerButtonText?: string;
  searchFilters?: AutocompleteFilter[];
  allowedRoles?: RoleInfoType[];
  showAutocompleteModal?: boolean;
  autocompleteModalHeader?: string;
  'aria-label'?: string;
}

export default function SelectTraveler({
  dataTestId,
  onSelect,
  onClose,
  onAddMe,
  error,
  expandDirection = AutocompleteExpandDirections.Right,
  alreadySelectedTravelerIds = [],
  openRegisterModal,
  showRegisterTravelerOnFooter = false,
  registerNewTravelerText,
  outerContainerStyles,
  clickAwayListernerStyles,
  inputContainerWidth,
  onCreateNewTraveler,
  onSearchValueChange,
  createNewTravelerButtonText,
  searchFilters,
  allowedRoles,
  showAutocompleteModal,
  autocompleteModalHeader,
  'aria-label': ariaLabel,
}: ISelectTravelerProps): JSX.Element {
  const { t } = useTranslation();
  const { t: tt } = useTranslation('WEB');
  const isBlocksTravelerAutocompleteEnabled = useFeatureFlag('FE_INFRA_TRAVELER_AUTOCOMPLETE_BLOCKS');
  const [searchValue, setSearchValue] = useState<string>('');
  const [autocompleteActive, setAutocompleteActive] = useState(true);

  const isSufficientRole = useHasUserAccess(allowedRoles ?? [...userRolesByFeature.adminPages]);
  const showRegisterTravelerButton = showRegisterTravelerOnFooter && isSufficientRole;

  // Debounce changes of search value that is passed to autocomplete query
  const [requestSearchValue, setRequestSearchValue] = useState<string>(searchValue);
  const setRequestSearchValueDebounced = useDebounceCallback(setRequestSearchValue, 400);
  useEffect(() => {
    setRequestSearchValueDebounced(searchValue);
  }, [searchValue, setRequestSearchValueDebounced]);

  const { data: travelers, isLoading: travelersLoading } = useTravelerEntitySearchAutocompleteQuery(
    requestSearchValue,
    searchFilters,
  );

  const displayedTravelers = useMemo(
    () =>
      (travelers ?? []).filter(
        (traveler) =>
          !alreadySelectedTravelerIds.find((selectedTravelerId) => isEqual(selectedTravelerId, traveler.userOrgId)),
      ),
    [travelers, alreadySelectedTravelerIds],
  );

  const handleSearchChange = useCallback<NonNullable<AutocompletePropsType['onInputChange']>>(
    (_event, updatedValue) => {
      setSearchValue(updatedValue);
      if (onSearchValueChange) {
        onSearchValueChange(updatedValue);
      }
    },
    [onSearchValueChange],
  );

  const handleSelect = useCallback<AutocompletePropsType['onSelection']>(
    (selection) => {
      onSelect(selection);
    },
    [onSelect],
  );

  const renderNoResultsWithAction = (): JSX.Element => (
    <div>
      <Typography css={no_result_found_text} variation="body">
        {t(NO_RESULTS_FOUND)}
      </Typography>
      <div css={add_traveler_container}>
        <Button fullWidth variant="ghost" css={create_new_traveler_button} onClick={onCreateNewTraveler}>
          <div css={add_traveler_row}>
            <Icon name="AddPlusCircleFill" css={large_icon} />
            <Typography variation="body" color="brand">
              {createNewTravelerButtonText || tt('Create new traveler')}
            </Typography>
          </div>
        </Button>
      </div>
    </div>
  );

  const OldFooter = useCallback((): JSX.Element | null => {
    if (showRegisterTravelerOnFooter) {
      return (
        <RBAC allowedRoles={allowedRoles ?? [...userRolesByFeature.adminPages]}>
          <Divider />
          <ClickableBox as="button" bg="bg.blueGray" p="20px" onClick={openRegisterModal} className="full-width">
            <Typography color="primary" fontWeight="semiBold" css={[flex.init, flex.align.center]}>
              <Icon name="AddPlusCircleFill" css={[large_icon, margins.xr.half]} />
              {registerNewTravelerText || tt('Register new traveler')}
            </Typography>
          </ClickableBox>
        </RBAC>
      );
    }
    return null;
  }, [openRegisterModal, showRegisterTravelerOnFooter, tt, registerNewTravelerText, allowedRoles]);

  const Footer = useCallback(() => {
    return (
      <SelectListAction
        onClick={openRegisterModal as VoidFunction}
        label={registerNewTravelerText || tt('Register new traveler')}
        iconName="AddPlusCircleFill"
      />
    );
  }, [openRegisterModal, registerNewTravelerText, tt]);

  return (
    <ClickAwayListener onClickAway={onClose} style={clickAwayListernerStyles}>
      {!isBlocksTravelerAutocompleteEnabled ? (
        <Autocomplete
          outerContainerStyles={outerContainerStyles}
          id="select-traveler-v2"
          inputContainerWidth={inputContainerWidth}
          data-testid={dataTestId ?? 'select-traveler-v2'}
          inputValue={searchValue}
          getContainerBoxProps={(popupOpen): ReturnType<typeof getAutocompleteContainerBoxProps> =>
            getAutocompleteContainerBoxProps(popupOpen, expandDirection)
          }
          getInputStylingProps={getAutocompleteInputStylingProps}
          getPopoverProps={(...args) => ({
            ...getAutocompletePopoverProps(...args),
            'aria-label': ariaLabel,
          })}
          getOptionLabel={(option): string => option.fullName}
          loading={travelersLoading}
          onInputChange={handleSearchChange}
          options={displayedTravelers}
          onSelection={handleSelect}
          renderLoadingState={(): JSX.Element => <TravelerLoader />}
          renderNoResultsState={
            onCreateNewTraveler
              ? renderNoResultsWithAction
              : (): JSX.Element => <NoResults message={t(NO_TRAVELER_PROFILES_FOUND)} />
          }
          renderOption={({ isHighlighted, option }): JSX.Element => (
            <AutocompleteOption travelerInfo={option} active={isHighlighted} />
          )}
          errorMessage={error}
          renderInput={(props): JSX.Element => <AutocompleteInput onAddMe={onAddMe} {...props} />}
          active={autocompleteActive}
          onDeactivate={(event, reason): void => {
            if (!['blur', 'toggleInput'].includes(reason)) {
              setAutocompleteActive(false);
              onClose();
            }
          }}
          footer={<OldFooter />}
        />
      ) : showAutocompleteModal ? (
        <TravelerAutocompleteModal
          onClose={onClose}
          onSelect={(value: TravelerBasicInfo | null) => {
            handleSelect(value);
            onClose();
          }}
          onAddMe={onAddMe}
          onRegisterNewTraveler={showRegisterTravelerButton ? openRegisterModal : undefined}
          open={showAutocompleteModal}
          registerNewTravelerText={registerNewTravelerText}
          header={autocompleteModalHeader}
        />
      ) : (
        <TravelerAutocomplete
          onTravelerSelect={(value: TravelerBasicInfo | null) => {
            onSelect(value);
            onClose();
          }}
          startAdornment={<Icon name="UserTravelerAdd" css={icon_style} />}
          endAdornment={onAddMe ? <AddMeButton onClick={onAddMe} /> : undefined}
          slots={{
            ItemPrefix: TravelerPickerOptionPrefix,
            ItemDescription: EntityAutocompleteProfileOptionDescription,
            ListFooter: showRegisterTravelerButton ? Footer : undefined,
          }}
          popoverProps={{ portal: true, style: { minWidth: '25rem' }, 'aria-label': ariaLabel }}
        />
      )}
    </ClickAwayListener>
  );
}
