import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import type { Application as ApplicationResponse } from '../types/api/v2/obt/model/application';
import api from '../api';
import type { GetApplicationListResponse } from '../types/api/v2/obt/model/get-application-list-response';

const fetchApplication = async (applicationId: string): Promise<ApplicationResponse> => {
  const result = await api('GET', 'getApplication', { urlParam: `/${applicationId}` });
  return result as ApplicationResponse;
};

export const getApplicationQueryKey = (applicationId: string | undefined | null) => ['GET_APPLICATION', applicationId];

export const useApplicationQuery = (
  applicationId: string | undefined | null,
  options?: Omit<UseQueryOptions<ApplicationResponse, AxiosError>, 'queryKey' | 'queryFn' | 'enabled'>,
): UseQueryResult<ApplicationResponse, AxiosError> =>
  useQuery<ApplicationResponse, AxiosError>({
    ...options,
    queryFn: () => fetchApplication(applicationId as string),
    queryKey: getApplicationQueryKey(applicationId),
    enabled: !!applicationId,
  });

const fetchApplications = async (organizationId: string): Promise<GetApplicationListResponse> => {
  const result = await api('GET', 'companyBaseUrl', {
    urlParam: `/${organizationId}/applications`,
  });
  return result as GetApplicationListResponse;
};

export const getOrgApplicationsQueryKey = (organizationId: string | undefined | null) => [
  'GET_ORG_APPLICATIONS',
  organizationId,
];

export const useOrgApplicationsQuery = (
  organizationId: string | undefined | null,
  options?: Omit<UseQueryOptions<GetApplicationListResponse, AxiosError>, 'queryKey' | 'queryFn'>,
): UseQueryResult<GetApplicationListResponse, AxiosError> =>
  useQuery<GetApplicationListResponse, AxiosError>({
    ...options,
    queryFn: () => fetchApplications(organizationId as string),
    queryKey: getOrgApplicationsQueryKey(organizationId),
    enabled: !!organizationId,
  });
