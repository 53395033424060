import type { UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { CreatePccRequest } from '@spotnana/types/openapi/models/create-pcc-request';
import type { CreatePccResponse } from '@spotnana/types/openapi/models/create-pcc-response';
import type { UpdatePccRequest } from '@spotnana/types/openapi/models/update-pcc-request';

import type { SupplierPccRulesConfig } from '@spotnana/types/openapi/models/supplier-pcc-rules-config';
import type { SupplierType } from '@spotnana/types/openapi/models/supplier-type';
import type { SupplierVendor } from '@spotnana/types/openapi/models/supplier-vendor';
import type { LoginSupportInfo } from '@spotnana/types/openapi/models/login-support-info';
import api, { getUrl } from '../api';
import SpotnanaError from '../api/SpotnanaError';
import { useLoggedInUserId } from '../providers';
import type { SpotnanaQueryMutationResult, SpotnanaQueryResult } from '../types';
import type { ReadPccResponse, ReadSupportConfigResponse } from '../types/api/v2/obt/model';
import type { GetZendeskChatTokenResponse } from '../types/api/v2/obt/model/get-zendesk-chat-token-response';
import type { ListPccRequest } from '../types/api/v2/obt/model/list-pcc-request';
import type { ListPccResponse } from '../types/api/v2/obt/model/list-pcc-response';
import type { ReadSupplierInfoResponse } from '../types/api/v2/obt/model/read-supplier-info-response';
import type { SupportConfig } from '../types/api/v2/obt/model/support-config';
import type { UpdateSupplierInfoRequest } from '../types/api/v2/obt/model/update-supplier-info-request';
import Config from '../utils/Config';
import { defaultQueryClient } from './defaultQueryClient';

const getTmcSupplierInfoUrl = (organizationId: string): string => `/${organizationId}/supplier-info`;

export const getPccConfig = (
  organizationId: string,
  supplierId?: string,
  vendor?: string,
): [string, string, string, string] => ['pccList', organizationId, supplierId ?? '', vendor ?? ''];

const fetchTmcSupplierInfo = async (organizationId: string): Promise<ReadSupplierInfoResponse | undefined> => {
  if (organizationId) {
    try {
      const result = await api('GET', 'sharedCompany', {
        urlParam: getTmcSupplierInfoUrl(organizationId),
      });
      return result as ReadSupplierInfoResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return Promise.resolve(undefined);
};

export const useFetchTmcSupplierInfo = (
  organizationId: string,
  enabled = true,
): SpotnanaQueryResult<ReadSupplierInfoResponse | undefined> =>
  useQuery<ReadSupplierInfoResponse | undefined, SpotnanaError>({
    queryKey: [getTmcSupplierInfoUrl(organizationId)],
    queryFn: () => fetchTmcSupplierInfo(organizationId),
    enabled: enabled && !!organizationId,
  });

export const invalidateSupplierInfo = (organizationId: string): void => {
  defaultQueryClient.invalidateQueries({
    queryKey: [getTmcSupplierInfoUrl(organizationId)],
  });
};

const getCompanySupportConfig = (organizationId: string): string => `/${organizationId}/support-config`;

const fetchCompanySupportConfig = async (organizationId: string): Promise<ReadSupportConfigResponse | undefined> => {
  if (organizationId) {
    try {
      const result = await api('GET', 'sharedCompany', {
        urlParam: getCompanySupportConfig(organizationId),
      });

      return result as ReadSupportConfigResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return Promise.resolve(undefined);
};

export const useFetchCompanySupportConfig = <TData = ReadSupportConfigResponse | undefined>(
  organizationId: string,
  options?: Partial<UseQueryOptions<ReadSupportConfigResponse | undefined, SpotnanaError, TData>>,
) =>
  useQuery<ReadSupportConfigResponse | undefined, SpotnanaError, TData>({
    queryKey: [getCompanySupportConfig(organizationId)],
    queryFn: () => fetchCompanySupportConfig(organizationId),
    ...options,
    enabled: !!organizationId,
  });

const getSubDomainSupportConfig = (subDomain: string): string =>
  `/v2/login/support-info?spotnanaSubdomain=${subDomain}`;

const fetchSubDomainSupportConfig = async (subDomain: string): Promise<LoginSupportInfo | undefined> => {
  try {
    const result = await api('GET', 'getLoginSupportInfo', {
      urlParam: `?spotnanaSubdomain=${subDomain}`,
    });
    return result as LoginSupportInfo;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useFetchSubDomainSupportConfig = <TData = LoginSupportInfo | undefined>(
  subDomain: string,
  options?: Partial<UseQueryOptions<LoginSupportInfo | undefined, SpotnanaError, TData>>,
) =>
  useQuery<LoginSupportInfo | undefined, SpotnanaError, TData>({
    queryKey: [getSubDomainSupportConfig(subDomain)],
    queryFn: () => fetchSubDomainSupportConfig(subDomain),
    ...options,
    enabled: !!subDomain,
  });

export function useSupportConfigForCurrentOrganization(
  options?: Omit<
    UseQueryOptions<ReadSupportConfigResponse | undefined, SpotnanaError, SupportConfig | undefined>,
    'queryKey'
  >,
) {
  const loggedInUserId = useLoggedInUserId();

  return useFetchCompanySupportConfig<SupportConfig | undefined>(
    loggedInUserId?.tmcBasicInfo?.bookingTmc?.id?.id ?? '',
    {
      ...options,
      select: (config) => config?.supportConfig,
    },
  );
}

export const getZendeskChatToken = (companyId: string): string => `/chat-token/${companyId}/zendesk`;

export function compileZendeskTokenFetchUrl(companyId: string): string {
  const urlParam = getZendeskChatToken(companyId);
  const urlPath = getUrl('zendeskChatToken', urlParam);
  const fullUrl = Config.VITE_API_BASE_URL + urlPath;
  return fullUrl;
}

export const fetchZendeskChatToken = async (companyId: string): Promise<GetZendeskChatTokenResponse | undefined> => {
  if (companyId) {
    try {
      const result = await api('POST', 'zendeskChatToken', {
        urlParam: getZendeskChatToken(companyId),
        data: { timestamp: new Date().getTime() },
      });

      return result as GetZendeskChatTokenResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return Promise.resolve(undefined);
};

export const useFetchZendeskChatToken = (
  options?: Omit<
    UseQueryOptions<GetZendeskChatTokenResponse | undefined, SpotnanaError, GetZendeskChatTokenResponse>,
    'queryKey'
  >,
) => {
  const loggedInUserId = useLoggedInUserId();
  const companyId = loggedInUserId?.tmcBasicInfo?.bookingTmc?.id?.id ?? '';
  return useQuery<GetZendeskChatTokenResponse | undefined, SpotnanaError, GetZendeskChatTokenResponse>({
    queryKey: [getZendeskChatToken(companyId)],
    queryFn: () => fetchZendeskChatToken(companyId),
    ...options,
    enabled: options?.enabled !== undefined ? options.enabled && !!companyId : !!companyId,
  });
};

const updateSupplierInfo = async (
  organizationId: string,
  requestBody: UpdateSupplierInfoRequest,
): Promise<ReadSupplierInfoResponse> => {
  try {
    const result = await api('POST', 'sharedCompany', {
      urlParam: getTmcSupplierInfoUrl(organizationId),
      data: requestBody,
    });
    return result as ReadSupplierInfoResponse;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useUpdateSupplierInfo = ({
  onSuccess,
}: {
  onSuccess?: (requestBody: UpdateSupplierInfoRequest) => void;
} = {}): SpotnanaQueryMutationResult<ReadSupplierInfoResponse, UpdateSupplierInfoRequest> =>
  useMutation({
    mutationFn: (requestBody: UpdateSupplierInfoRequest) => updateSupplierInfo(requestBody.tmcId?.id, requestBody),
    onSuccess: (_data, requestBody) => onSuccess?.(requestBody),
  });

const getPccUrl = (pccId: string): string[] => [`/v2/pcc/${pccId}`];

export const fetchPccInfo = async (pccId: string): Promise<ReadPccResponse | undefined> => {
  if (pccId) {
    try {
      const result = await api('GET', 'pcc', {
        urlParam: `/${pccId}`,
      });
      return result as ReadPccResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return undefined;
};

const createPcc = async (request: CreatePccRequest): Promise<CreatePccResponse> => {
  const data = await api('POST', 'pcc', { data: request });
  return data as CreatePccResponse;
};

const updatePcc = async (request: UpdatePccRequest): Promise<void> => {
  await api('PUT', 'pcc', {
    urlParam: `/${request.pcc.pccId}`,
    data: request,
  });
};

export const useFetchPccQuery = (pccId: string): SpotnanaQueryResult<ReadPccResponse | undefined> =>
  useQuery<ReadPccResponse | undefined, SpotnanaError>({
    queryKey: getPccUrl(pccId),
    queryFn: () => fetchPccInfo(pccId),
  });

export const useCreatePccMutation = (): SpotnanaQueryMutationResult<CreatePccResponse, CreatePccRequest> =>
  useMutation({
    mutationFn: (request: CreatePccRequest) => createPcc(request),
    onSuccess: (_res, requestBody) => {
      defaultQueryClient.invalidateQueries({
        queryKey: getPccConfig(requestBody.tmcId.id, requestBody.supplier, requestBody.pccAirline),
      });
      // To make sure that the query key is invalidated for supplier only forms
      defaultQueryClient.invalidateQueries({ queryKey: getPccConfig(requestBody.tmcId.id, requestBody.supplier) });
    },
  });

export const useUpdatePccMutation = (): SpotnanaQueryMutationResult<void, UpdatePccRequest> =>
  useMutation({
    mutationFn: (request: UpdatePccRequest) => updatePcc(request),
    onSuccess: (_res, requestBody) => {
      const queryKey = getPccConfig(requestBody.pcc.tmcId.id, requestBody.pcc.supplier, requestBody.pcc.pccAirline);

      // To make sure that the query key is invalidated for supplier only forms
      const queryKeyWithSupplier = getPccConfig(requestBody.pcc.tmcId.id, requestBody.pcc.supplier);

      defaultQueryClient.invalidateQueries({ queryKey });
      defaultQueryClient.invalidateQueries({ queryKey: queryKeyWithSupplier });

      defaultQueryClient.invalidateQueries({ queryKey: getPccUrl(requestBody.pcc.pccId) });
    },
  });

const fetchPccList = async (request: ListPccRequest): Promise<ListPccResponse | undefined> => {
  if (request.tmcId.id) {
    try {
      const result = await api('POST', 'pccList', {
        data: request,
      });
      return result as ListPccResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return undefined;
};

export const useFetchPccQueryList = ({
  isEnabled = true,
  ...request
}: ListPccRequest & {
  isEnabled?: boolean;
}): SpotnanaQueryResult<ListPccResponse | undefined> =>
  useQuery({
    queryKey: getPccConfig(request.tmcId.id, (request.supplier as string) ?? '', request.vendor ?? ''),
    queryFn: () => fetchPccList(request),
    enabled: isEnabled,
  });

export const invalidatePccList = (): void => {
  defaultQueryClient.invalidateQueries({
    queryKey: ['pccList'],
  });
};

const deletePcc = async (pccId: string): Promise<void> => {
  try {
    await api('DELETE', 'pcc', {
      urlParam: `/${pccId}`,
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useDeletePcc = (): SpotnanaQueryMutationResult<void, string> =>
  useMutation({
    mutationKey: ['deletePcc'],
    mutationFn: (pccId) => deletePcc(pccId),
    onSuccess: () => {
      defaultQueryClient.invalidateQueries({
        queryKey: ['pccList'],
      });
    },
  });

interface IFetchTmcConfigurationRequest {
  tmcId: string;
  supplierType: SupplierType;
  vendor?: SupplierVendor;
}

function getFetchTmcConfigurationKey({ tmcId, supplierType, vendor }: IFetchTmcConfigurationRequest) {
  return ['tmcConfig', tmcId, supplierType, vendor];
}
async function fetchTmcConfiguration({
  tmcId,
  supplierType,
  vendor,
}: IFetchTmcConfigurationRequest): Promise<SupplierPccRulesConfig | undefined> {
  let urlParam = `/${tmcId}/supplier-types/${supplierType}/pcc-config`;
  if (vendor) {
    urlParam += `?vendor=${vendor}`;
  }
  const result = await api('GET', 'tmcBaseURLV3', {
    urlParam,
  });
  return result as SupplierPccRulesConfig;
}
export function useFetchTmcConfiguration({
  tmcId,
  supplierType,
  vendor,
}: IFetchTmcConfigurationRequest): SpotnanaQueryResult<SupplierPccRulesConfig | undefined> {
  return useQuery({
    queryKey: getFetchTmcConfigurationKey({ tmcId, supplierType, vendor }),

    queryFn: () => fetchTmcConfiguration({ tmcId, supplierType, vendor }),
  });
}

interface IUpdateTmcConfigurationRequest {
  tmcId: string;
  supplierType: SupplierType;
  rulesConfig: SupplierPccRulesConfig;
  vendor?: SupplierVendor;
}

async function updateTmcConfiguration({
  tmcId,
  supplierType,
  rulesConfig,
  vendor,
}: IUpdateTmcConfigurationRequest): Promise<void> {
  let urlParam = `/${tmcId}/supplier-types/${supplierType}/pcc-config`;
  if (vendor) {
    urlParam += `?vendor=${vendor}`;
  }
  await api('POST', 'tmcBaseURLV3', {
    urlParam,
    data: rulesConfig,
  });
}
export function useUpdateTmcConfiguration({
  tmcId,
  supplierType,
  vendor,
}: Omit<IUpdateTmcConfigurationRequest, 'rulesConfig'>): SpotnanaQueryMutationResult<void, SupplierPccRulesConfig> {
  return useMutation({
    mutationKey: ['updateTmcConfiguration'],
    mutationFn: (rulesConfig: SupplierPccRulesConfig) =>
      updateTmcConfiguration({ tmcId, supplierType, rulesConfig, vendor }),
    onSuccess: () => {
      defaultQueryClient.invalidateQueries({
        queryKey: getFetchTmcConfigurationKey({ tmcId, supplierType, vendor }),
      });
    },
  });
}

interface IFetchOrgConfigurationRequest {
  tmcId: string;
  companyId: string | undefined;
  supplierType: SupplierType;
  vendor?: SupplierVendor;
}

function getFetchOrgConfigurationKey({ tmcId, companyId, supplierType, vendor }: IFetchOrgConfigurationRequest) {
  return ['orgConfig', tmcId, companyId, supplierType, vendor];
}

async function fetchOrgConfiguration({
  tmcId,
  companyId,
  supplierType,
  vendor,
}: IFetchOrgConfigurationRequest): Promise<SupplierPccRulesConfig | undefined> {
  if (!companyId) {
    return undefined;
  }
  let urlParam = `/${tmcId}/companies/${companyId}/supplier-types/${supplierType}/pcc-config`;
  if (vendor) {
    urlParam += `?vendor=${vendor}`;
  }
  try {
    const result = await api('GET', 'tmcBaseURLV3', {
      urlParam,
    });
    return result as SupplierPccRulesConfig;
  } catch (e) {
    return undefined;
  }
}

export function useFetchOrgConfiguration({
  tmcId,
  companyId,
  supplierType,
  vendor,
}: IFetchOrgConfigurationRequest): SpotnanaQueryResult<SupplierPccRulesConfig | undefined> {
  return useQuery({
    queryKey: getFetchOrgConfigurationKey({ tmcId, companyId, supplierType, vendor }),
    queryFn: () => fetchOrgConfiguration({ tmcId, companyId, supplierType, vendor }),
    enabled: !!companyId,
  });
}

interface IUpdateOrgConfigurationRequest {
  tmcId: string;
  companyId: string | undefined;
  supplierType: SupplierType;
  rulesConfig: SupplierPccRulesConfig;
  vendor?: SupplierVendor;
}

async function updateOrgConfiguration({
  tmcId,
  supplierType,
  companyId,
  rulesConfig,
  vendor,
}: IUpdateOrgConfigurationRequest): Promise<void> {
  if (!companyId) {
    return;
  }
  let urlParam = `/${tmcId}/companies/${companyId}/supplier-types/${supplierType}/pcc-config`;
  if (vendor) {
    urlParam += `?vendor=${vendor}`;
  }
  await api('POST', 'tmcBaseURLV3', {
    urlParam,
    data: rulesConfig,
  });
}
export function useUpdateOrgConfiguration({
  tmcId,
  companyId,
  supplierType,
  vendor,
}: Omit<IUpdateOrgConfigurationRequest, 'rulesConfig'>): SpotnanaQueryMutationResult<void, SupplierPccRulesConfig> {
  return useMutation({
    mutationKey: ['updateOrgConfiguration'],
    mutationFn: (rulesConfig: SupplierPccRulesConfig) =>
      updateOrgConfiguration({ tmcId, supplierType, companyId, rulesConfig, vendor }),
    onSuccess: () => {
      defaultQueryClient.invalidateQueries({
        queryKey: getFetchOrgConfigurationKey({ tmcId, companyId, supplierType, vendor }),
      });
    },
  });
}
