import Box from '@spotnana/pixel-react/dist/Box';
import CircularLoader from '@spotnana/pixel-react/dist/CircularLoader';
import * as Icons from '@spotnana/pixel-react/dist/Icons';
import {
  AgentAvailabilityStatus,
  logger,
  useAgentStatusAvailabilityQuery,
  useUpdateAgentStatusAvailability,
} from 'obt-common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from '@spotnana/blocks/src/Typography';
import { flex, themed } from '@spotnana/blocks/src/utils';
import { paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';
import { css } from '@emotion/react';
import { availabilityStatusColorsMap } from './constants';
import { overlayLoaderStyles } from './TasksTable/styles';

const menu_item = themed(
  ({ palette }) => css`
    cursor: pointer;
    ${paddings.x.compact}
    ${paddings.y.compact}
    :hover {
      background-color: ${palette.bg.hover};
    }
    :focus-visible {
      outline: 2px solid ${palette.border.keyboardFocus};
      outline-offset: -2px;
    }
  `,
);

const OverlayLoader = styled(Box)`
  ${overlayLoaderStyles}
  height: 100%;
  width: 100%;
`;

interface IProps {
  id: string;
}

const AgentStatusMenu = ({ id }: IProps): JSX.Element => {
  const { t: tt } = useTranslation('WEB');

  const { isFetching, data } = useAgentStatusAvailabilityQuery(id, true);

  const { isPending: updatingStatus, mutateAsync: updateStatus } = useUpdateAgentStatusAvailability();
  const onClick = useCallback(
    async (newStatus: AgentAvailabilityStatus) => {
      try {
        await updateStatus({ id, request: { status: newStatus } });
      } catch {
        logger.error(new Error(`AGENT_AVAILABILITY_STATUS_FAILED: Failed to update agent availability status`));
      }
    },
    [updateStatus, id],
  );

  const menuItems = [
    {
      label: tt('Online'),
      value: AgentAvailabilityStatus.Available,
    },
    {
      label: tt('Idle'),
      value: AgentAvailabilityStatus.OnBreak,
    },
    {
      label: tt('Offline'),
      value: AgentAvailabilityStatus.Offline,
    },
  ];

  return (
    <Box position="relative">
      {(isFetching || updatingStatus) && (
        <OverlayLoader>
          <CircularLoader size={32} />
        </OverlayLoader>
      )}
      <div css={[flex.init, flex.column]}>
        {menuItems.map((item) => {
          return (
            <button type="button" css={menu_item} onClick={() => onClick(item.value)} key={item.label}>
              <div css={[flex.init, flex.align.center, spacings.medium]}>
                {data?.status === item.value ? (
                  <Box data-testid={`check-container-${item.value}`}>
                    <Icons.Check width={12} height={8} primaryColor={availabilityStatusColorsMap[item.value]} />
                  </Box>
                ) : (
                  <Box height="6px" width="6px" borderRadius="circle" bg={availabilityStatusColorsMap[item.value]} />
                )}
                <Typography variant="body1">{item.label}</Typography>
              </div>
            </button>
          );
        })}
      </div>
    </Box>
  );
};

export default AgentStatusMenu;
