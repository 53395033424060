import { Airplane } from '@spotnana/pixel-react/dist/Icons';
import { getAirlineLogo } from 'obt-common';
import React, { CSSProperties } from 'react';
import { Tooltip } from '@spotnana/blocks/src/Tooltip';
import { useAirlinesContext } from 'src/providers/AirlinesProviderContext/context';
import { Interpolation, Theme } from '@emotion/react';
import { airline_image_container, logo_image } from './styles';

interface AirlineImageProps {
  marketingAirline: string;
  style?: CSSProperties;
  containerStyles?: Interpolation<Theme>;
}

export const AirlineImage: React.FC<AirlineImageProps> = ({ marketingAirline, style, containerStyles, ...props }) => {
  const { airlinesNameMap } = useAirlinesContext();

  const airlineName = airlinesNameMap[marketingAirline];

  if (!airlineName || !marketingAirline) {
    return <Airplane css={logo_image} style={style} data-testid="fallback-img" />;
  }
  const logo = getAirlineLogo(marketingAirline);

  return (
    <div css={[airline_image_container, containerStyles]} data-testid={`${marketingAirline}-container`}>
      <Tooltip variant="label" placement="top" title={undefined}>
        <img
          src={logo}
          css={logo_image}
          alt={`${airlineName} logo`}
          style={style}
          data-testid="airline-logo"
          {...props}
        />
      </Tooltip>
    </div>
  );
};
