import { css } from '@emotion/react';
import { flex } from '../../../utils';
import { paddings } from '../../../utils/styling/utilityTokens';

export const remaining_characters = css`
  ${flex.init}
  ${flex.justify.end}
  ${paddings.x.tight}
  ${paddings.y.superTight}
  width: 100%;
`;
