import { useMemo } from 'react';
import useJSONFeatureFlag from '../../feature-gating/useJSONFeatureFlag';
import type { CognigyChatConfig } from './types';

export function useCognigyChatConfig(): { enabled: boolean; config: CognigyChatConfig } {
  const cognigyChatConfig = useJSONFeatureFlag('FE_COGNIGY_CHAT_CONFIG');

  return useMemo(() => {
    const { jsBundleUrl = '', endpointBaseUrl = '', endpointApiKey = '', sessionTimeout = 0 } = cognigyChatConfig || {};

    return {
      enabled: !!jsBundleUrl && !!endpointBaseUrl && !!endpointApiKey && sessionTimeout >= 0,
      config: { jsBundleUrl, endpointBaseUrl, endpointApiKey, sessionTimeout },
    };
  }, [cognigyChatConfig]);
}
