import get from 'lodash/get';
import { TripFeeTypeLabelMap, TripFeeTypeLabelMapEmails } from '../../constants/paymentTripFees';
import type { ITripFeeInfo } from '../../types';

// Trip fee info has different values for type of fee which are represented by properties feeType, subType, subCategory
// This function returns the label for the same from the mapper which is maintained in const TripFeeTypeLabelMap.
export const getPaymentTripFeeTypeLabel = (
  tripFeeInfo: Pick<ITripFeeInfo, 'feeType' | 'subType' | 'subCategory'>,
  redirectedFromEmail?: boolean,
) => {
  const { feeType, subType, subCategory } = tripFeeInfo;
  const mapArray = [feeType, subType, subCategory].filter((item) => !!item);
  if (redirectedFromEmail) {
    return get(TripFeeTypeLabelMapEmails, mapArray);
  }
  const label = get(TripFeeTypeLabelMap, mapArray);
  return typeof label === 'string' ? label : TripFeeTypeLabelMap.BOOKING_FEE.TRIP_FEE;
};
