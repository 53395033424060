import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import moment from 'moment';
import produce from 'immer';
import type {
  IFlightSearchSegmentState,
  ILeg1,
  IAirModifySearchUrlParams,
  IFlightsFilterState,
  INewLeg,
  ExistingLeg,
  ILegState,
} from '../types/flight';
import { LegStatus } from '../types/flight';

import type { IModifyAirSearchUrlParams } from '../types/airExchange';

import type { IAirSuggestion } from '../types/autocomplete';
import type { AirModifySearchRequest } from '../types/api/v2/obt/model/air-modify-search-request';
import { getAirSearchRequestFilters } from './flights';
import { dateFormats } from '../constants/common';
import { splitObjectIntoArray } from './common';

import {
  AllianceEnum,
  CabinEnum,
  FareCategoryCabinViewFareCategory,
  FareCategoryNGSFareCategory,
} from '../types/api/v1/obt/air/air_common';
import type {
  Filter as FilterV1,
  FilterAllianceFilter,
  FilterCabinFilter,
  FilterCovidFilter,
  FilterFareCategoryFilter,
  FilterFareType,
  FilterFlightNumberFilter,
  FilterSeatPrefFilter,
  FilterTimeRangeFilter,
  SortOption as SortOptionV1,
} from '../types/api/v1/obt/air/air_search_request';
import {
  FilterCabinFilterTypeEnum,
  FilterChangeableFilter,
  FilterCovidFilterPreference,
  FilterFareTypeEnum,
  FilterSeatPrefFilterSeatType,
  SortOptionSortByEnum,
  SortOptionSortOrderEnum,
} from '../types/api/v1/obt/air/air_search_request';

import { Alliance } from '../types/api/v2/obt/model/alliance';
import type { AllianceFilter } from '../types/api/v2/obt/model/alliance-filter';
import { CabinFilterType } from '../types/api/v2/obt/model/cabin-filter-type';
import { ChangeableFilter } from '../types/api/v2/obt/model/changeable-filter';
import { FareTypeFilter } from '../types/api/v2/obt/model/fare-type-filter';
import type { CabinFilter } from '../types/api/v2/obt/model/cabin-filter';
import { Cabin } from '../types/api/v2/obt/model/cabin';
import type { SeatPrefFilter } from '../types/api/v2/obt/model/seat-pref-filter';
import { SeatType } from '../types/api/v2/obt/model/seat-type';
import type { TimeRangeFilter } from '../types/api/v2/obt/model/time-range-filter';
import { CovidFilterPreference } from '../types/api/v2/obt/model/covid-filter-preference';
import type { CovidFilter } from '../types/api/v2/obt/model/covid-filter';
import { NGSFareCategory } from '../types/api/v2/obt/model/ngsfare-category';
import { CabinViewFareCategory } from '../types/api/v2/obt/model/cabin-view-fare-category';
import type { FareCategoryFilter } from '../types/api/v2/obt/model/fare-category-filter';
import type { Filter } from '../types/api/v2/obt/model/filter';
import type { FlightNumberFilter } from '../types/api/v2/obt/model/flight-number-filter';
import type { Leg } from '../types/api/v2/obt/model/leg';
import type { SortOption } from '../types/api/v2/obt/model/sort-option';
import { SortBy } from '../types/api/v2/obt/model/sort-by';
import { SortOrder } from '../types/api/v2/obt/model/sort-order';

/**
 *
 * @param exchangeableLegs : Array of all the legs that are exchangeable in chronological order (it should also include existing flights that may or may not have been edited)
 * @param exchangeableLegState : Array of all legs meta information
 * @returns : Array of Leg1 as needed by the AirModifySearchRequest
 */
export const getUpdatedLegsForExchange = <T extends ILegState>(
  exchangeableLegs: IFlightSearchSegmentState<IAirSuggestion>[],
  exchangeableLegState: T[],
): ILeg1[] =>
  exchangeableLegs
    .flatMap((leg, updatedIndex) => {
      // No changes in this leg
      if (exchangeableLegState[updatedIndex].status === LegStatus.None) {
        return [
          {
            index: exchangeableLegState[updatedIndex].originalLegIndex,
            remove: false,
          },
        ];
      }

      if (exchangeableLegState[updatedIndex].status === LegStatus.Removed) {
        // this shouldn't happen we rely on legState object for removing the leg
        return [];
      }

      // It's an old leg check if its updated
      if (exchangeableLegState[updatedIndex].status === LegStatus.Updated) {
        // It's an existing flight which was updated, remove it and add a new leg
        return [
          {
            index: exchangeableLegState[updatedIndex].originalLegIndex,
            remove: true,
          },
          {
            origin: leg.origin,
            destination: leg.destination,
            // eslint-disable-next-line no-restricted-syntax
            date: moment(leg.date).format(dateFormats.ISO),
          },
        ] as ILeg1[];
      }

      // It's a new flight
      return [
        {
          origin: leg.origin,
          destination: leg.destination,
          // eslint-disable-next-line no-restricted-syntax
          date: moment(leg.date).format(dateFormats.ISO),
        },
      ];
    })
    .concat(
      exchangeableLegState
        .filter((legState) => legState.status === LegStatus.Removed)
        .map((legState) => ({
          index: legState.originalLegIndex,
          remove: true,
        })),
    );

/**
 * Adaptors for filters and sort options for v1 and v2 proto models
 */

const mapV1ToV2CabinFilterType = new Map([
  [FilterCabinFilterTypeEnum.ALL, CabinFilterType.All],
  [FilterCabinFilterTypeEnum.AT_LEAST_ONE, CabinFilterType.AtLeastOne],
  [FilterCabinFilterTypeEnum.DEFAULT, CabinFilterType.Default],
  [FilterCabinFilterTypeEnum.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);

const mapV1toV2CabinFilterCabin = new Map([
  [CabinEnum.BUSINESS, Cabin.Business],
  [CabinEnum.ECONOMY, Cabin.Economy],
  [CabinEnum.PREMIUM_ECONOMY, Cabin.PremiumEconomy],
  [CabinEnum.FIRST, Cabin.First],
  [CabinEnum.UNKNOWN_CABIN, Cabin.UnknownCabin],
  [CabinEnum.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);

export const convertV1CabinFilterToV2 = (v1Filter: FilterCabinFilter): CabinFilter => ({
  type: mapV1ToV2CabinFilterType.get(v1Filter.type),
  cabin: mapV1toV2CabinFilterCabin.get(v1Filter.cabin),
});

/**
 * Time range filter has the same structure so a type case would be enough
 */
export const convertV1TimeRangeFilterToV2 = (v1Filter: FilterTimeRangeFilter): TimeRangeFilter =>
  v1Filter as TimeRangeFilter;

const mapV1ToV2Alliance = new Map([
  [AllianceEnum.ONEWORLD, Alliance.Oneworld],
  [AllianceEnum.STAR_ALLIANCE, Alliance.StarAlliance],
  [AllianceEnum.UNKNOWN_ALLIANCE, Alliance.UnknownAlliance],
  [AllianceEnum.SKYTEAM, Alliance.Skyteam],
  [AllianceEnum.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);

export const convertV1AllianceFilterToV2 = (v1Filter: FilterAllianceFilter): AllianceFilter => ({
  airlines: v1Filter.airlines?.filter(Boolean),
  alliances: v1Filter.alliances
    ?.map((v1Alliance) => mapV1ToV2Alliance.get(v1Alliance) ?? false)
    ?.filter(Boolean) as Alliance[],
});

const mapV1ToV2FareTypeFilter = new Map([
  [FilterFareTypeEnum.PRIVATE, FareTypeFilter.Private],
  [FilterFareTypeEnum.PUBLIC, FareTypeFilter.Public],
  [FilterFareTypeEnum.UNKNOWN, FareTypeFilter.Unknown],
  [FilterFareTypeEnum.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);

export const convertV1FareTypeFilterToV2 = (v1Filter: FilterFareType): FareTypeFilter | undefined =>
  mapV1ToV2FareTypeFilter.get(v1Filter);

const mapV1ToV2ChangeableFilter = new Map([
  [FilterChangeableFilter.CHANGEABLE_FLEXIBLE_REFUNDABLE, ChangeableFilter.ChangeableFlexibleRefundable],
  [FilterChangeableFilter.NO_CHANGEABLE_FILTER, ChangeableFilter.NoChangeableFilter],
  [FilterChangeableFilter.REFUNDABLE_WITHOUT_PENALTY, ChangeableFilter.RefundableWithoutPenalty],
  [FilterChangeableFilter.REFUNDABLE_WITH_PENALTY, ChangeableFilter.RefundableWithPenalty],
  [FilterChangeableFilter.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);
export const convertV1ChangeableFilterToV2 = (v1Filter: FilterChangeableFilter): ChangeableFilter | undefined =>
  mapV1ToV2ChangeableFilter.get(v1Filter);

const mapV1ToV2SeatTypeFilter = new Map([
  [FilterSeatPrefFilterSeatType.FLAT, SeatType.Flat],
  [FilterSeatPrefFilterSeatType.PRIVATE_SUITE, SeatType.PrivateSuite],
  [FilterSeatPrefFilterSeatType.RECLINER, SeatType.Recliner],
  [FilterSeatPrefFilterSeatType.SKYCOUCH, SeatType.Skycouch],
  [FilterSeatPrefFilterSeatType.UNKNOWN, SeatType.Unknown],
  [FilterSeatPrefFilterSeatType.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);

export const convertV1SeatTypeToV2 = (v1Filter: FilterSeatPrefFilter): SeatPrefFilter => ({
  seatTypes: v1Filter.seatTypes
    .map((v1SeatType) => mapV1ToV2SeatTypeFilter.get(v1SeatType) ?? false)
    .filter(Boolean) as SeatType[],
  minPitchInch: v1Filter.minPitchInch,
});

const mapV1ToV2CovidPreferenceFilter = new Map([
  [FilterCovidFilterPreference.NOT_REQUIRED, CovidFilterPreference.NotRequired],
  [FilterCovidFilterPreference.NO_PREFERENCE, CovidFilterPreference.NoPreference],
  [FilterCovidFilterPreference.REQUIRED, CovidFilterPreference.Required],
  [FilterCovidFilterPreference.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);

export const convertV1CovidFilterToV2 = (v1Filter: FilterCovidFilter): CovidFilter => ({
  vaccine: mapV1ToV2CovidPreferenceFilter.get(v1Filter.vaccine),
  covidTest: mapV1ToV2CovidPreferenceFilter.get(v1Filter.covidTest),
  faceMask: mapV1ToV2CovidPreferenceFilter.get(v1Filter.faceMask),
  temperatureCheck: mapV1ToV2CovidPreferenceFilter.get(v1Filter.temperatureCheck),
  blockedAdjacentSeats: mapV1ToV2CovidPreferenceFilter.get(v1Filter.blockedAdjacentSeats),
});

const mapV1ToV2NGSCategory = new Map([
  [FareCategoryNGSFareCategory.STANDARD, NGSFareCategory.Standard],
  [FareCategoryNGSFareCategory.PREMIUM, NGSFareCategory.Premium],
  [FareCategoryNGSFareCategory.BASE, NGSFareCategory.Base],
  [FareCategoryNGSFareCategory.ULTRA_LUXURY, NGSFareCategory.UltraLuxury],
  [FareCategoryNGSFareCategory.LUXURY, NGSFareCategory.Luxury],
  [FareCategoryNGSFareCategory.ENHANCED, NGSFareCategory.Enhanced],
  [FareCategoryNGSFareCategory.UNKNOWN_NGS_CATEGORY, NGSFareCategory.Base],
  [FareCategoryNGSFareCategory.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);

const mapV1ToV2CabinViewCategory = new Map([
  [FareCategoryCabinViewFareCategory.BASIC, CabinViewFareCategory.Basic],
  [FareCategoryCabinViewFareCategory.BUSINESS, CabinViewFareCategory.Business],
  [FareCategoryCabinViewFareCategory.ECONOMY, CabinViewFareCategory.Economy],
  [FareCategoryCabinViewFareCategory.FIRST, CabinViewFareCategory.First],
  [FareCategoryCabinViewFareCategory.PREMIUM_ECONOMY, CabinViewFareCategory.PremiumEconomy],
  [FareCategoryCabinViewFareCategory.UNKNOWN_CABIN_CATEGORY, CabinViewFareCategory.UnknownCabinCategory],
  [FareCategoryCabinViewFareCategory.UNRECOGNIZED, undefined],
  [undefined, undefined],
]);

export const convertV1FareCategoryToV2 = (v1Filter: FilterFareCategoryFilter): FareCategoryFilter => ({
  fareCategories: v1Filter.fareCategories.map((v1FareCategory) => ({
    ngsCategory: mapV1ToV2NGSCategory.get(v1FareCategory.ngsCategory),
    cabinViewCategory: mapV1ToV2CabinViewCategory.get(v1FareCategory.cabinViewCategory),
  })),
});

export const convertV1FlightNumberToV2 = (v1Filter: FilterFlightNumberFilter): FlightNumberFilter | undefined =>
  v1Filter.flightNumber
    ? {
        flightNumber: { airlineCode: v1Filter.flightNumber.airline, num: v1Filter.flightNumber.num },
      }
    : undefined;

export const convertV1FilterToV2 = (v1Filter: FilterV1): Filter =>
  omitBy(
    {
      ...v1Filter,
      cabin: v1Filter.cabin ? convertV1CabinFilterToV2(v1Filter.cabin) : undefined,
      alliance: v1Filter.alliance ? convertV1AllianceFilterToV2(v1Filter.alliance) : undefined,
      timeRange: v1Filter.timeRange ? convertV1TimeRangeFilterToV2(v1Filter.timeRange) : undefined,
      fareType: v1Filter.fareType ? convertV1FareTypeFilterToV2(v1Filter.fareType) : undefined,
      changeable: v1Filter.changeable !== undefined ? convertV1ChangeableFilterToV2(v1Filter.changeable) : undefined,
      seatPref: v1Filter.seatPref ? convertV1SeatTypeToV2(v1Filter.seatPref) : undefined,
      covid: v1Filter.covid ? convertV1CovidFilterToV2(v1Filter.covid) : undefined,
      fareCategoryFilter: v1Filter.fareCategoryFilter
        ? convertV1FareCategoryToV2(v1Filter.fareCategoryFilter)
        : undefined,
      flightNumber: v1Filter.flightNumber ? convertV1FlightNumberToV2(v1Filter.flightNumber) : undefined,
    },
    isUndefined,
  );

const mapV1toV2SortBy = new Map([
  [SortOptionSortByEnum.PRICE, SortBy.Price],
  [SortOptionSortByEnum.DEPARTURE_TIME, SortBy.DepartureTime],
  [SortOptionSortByEnum.ARRIVAL_TIME, SortBy.ArrivalTime],
  [SortOptionSortByEnum.DURATION, SortBy.Duration],
  [SortOptionSortByEnum.UNRECOGNIZED, undefined],
]);

const mapV1toV2SortOrder = new Map([
  [SortOptionSortOrderEnum.UNRECOGNIZED, undefined],
  [SortOptionSortOrderEnum.ASCENDING, SortOrder.Ascending],
  [SortOptionSortOrderEnum.DESCENDING, SortOrder.Descending],
]);

export const convertV1SortToV2 = (v1Sort: SortOptionV1): SortOption => ({
  sortBy: mapV1toV2SortBy.get(v1Sort.sortBy),
  sortOrder: mapV1toV2SortOrder.get(v1Sort.sortOrder),
  shelfNumber: v1Sort.shelfNumber,
});

const getWebAirModifySearchLegs = (legs: ILeg1[]): Leg[] =>
  legs.map((leg) => {
    if ((leg as INewLeg).origin !== undefined) {
      const newLeg = leg as INewLeg;
      return {
        origin: newLeg.origin?.code ?? '',
        destination: newLeg.destination?.code ?? '',
        date: { iso8601: newLeg.date },
      };
    }
    return leg as ExistingLeg;
  });

export const getWebAirModifySearchRequest = (params: IAirModifySearchUrlParams): AirModifySearchRequest => {
  const {
    pnrId,
    legs,
    sortOptions,
    filter,
    currLegNumber,
    lastLegRateOptionId,
    asyncRouteHappy,
    searchId,
    pageNumber,
    pageSize,
    exchangeableIndices,
  } = params;
  const modifySearchLegs = getWebAirModifySearchLegs(legs);

  const legNumber = currLegNumber ?? 0;
  const legIndex = exchangeableIndices[legNumber];
  const filters =
    filter?.length && filter[legNumber]
      ? (Object.assign({}, ...filter[legNumber]) as IFlightsFilterState)
      : ([] as IFlightsFilterState);

  let filtersArray: Filter[] = [];

  if (filter?.length) {
    const updatedFilters = produce(filters, (draftState) => {
      if (draftState.timeRange) {
        const timeRanges = draftState.timeRange;
        draftState.timeRange = timeRanges.map((timeRange) => {
          const { departure, arrival } = timeRange;
          return {
            ...timeRange,
            departure: {
              min: String(Object.values(departure)[0]),
              max: String(Object.values(departure)[1]),
            },
            arrival: {
              min: String(Object.values(arrival)[0]),
              max: String(Object.values(arrival)[1]),
            },
          };
        });
      }
    });

    const newFilters = getAirSearchRequestFilters(updatedFilters);

    delete newFilters.cabin;

    const newFiltersV2 = convertV1FilterToV2(newFilters);
    filtersArray = splitObjectIntoArray(newFiltersV2);
  }

  const sortOptionsV2 =
    sortOptions?.length && // check if sortOptions is undefined or empty
    sortOptions[legNumber] && // check if leg sortOptions is undefined
    sortOptions[legNumber].length && // check if leg sortOptions is empty
    sortOptions[legNumber][0].sortBy !== -1 // check if leg sortOptions sort type is unrecognized
      ? [convertV1SortToV2(sortOptions[legNumber][0])]
      : [];

  const legSearchParams = {
    selectedRateOptionId: lastLegRateOptionId,
    asyncRouteHappy,
    legIndex,
    pageNumber,
    pageSize,
    searchId,
  };

  return {
    legs: modifySearchLegs,
    filters: filtersArray,
    sortOptions: sortOptionsV2,
    legSearchParams,
    pnrId: String(pnrId),
  };
};

export const getModifyAirSearchRequest = ({
  pnrId,
  legs,
  sortOptions,
  lastLegRateOptionId,
  currLegNumber,
  searchId,
  pageSize,
  pageNumber,
  filter,
}: IModifyAirSearchUrlParams): AirModifySearchRequest => {
  const legSearchParams = {
    pageNumber,
    pageSize,
    searchId,
    legIndex: currLegNumber,
    selectedRateOptionId: lastLegRateOptionId,
    asyncRouteHappy: true,
  };

  const newFilters = getAirSearchRequestFilters(Object.assign({}, ...filter));

  delete newFilters.cabin;

  return {
    pnrId,
    legs,
    legSearchParams,
    sortOptions: sortOptions.map(convertV1SortToV2),
    filters: splitObjectIntoArray(convertV1FilterToV2(newFilters)) as Filter[],
  };
};
