// commenting out from base PR as this is going to be a part of follow up, don't want people to use this until ready

import React, { useRef } from 'react';
import {
  TSelectOption,
  TSelectOptionList,
  SelectComboboxRenderer,
  TSelectComboboxRendererProps,
} from './SelectRenderer';
import { TSearchConfig, useSelectStore_internal } from './useSelectStore_internal';
import { useDefaultDisclosure } from '../SelectRoot/useDefaultDisclosure';
import { EMPTY_ARRAY } from '../utils';
import { getStringifiedValueFromOption } from './componentUtils';

type TSelectHookProps<Variant extends 'single' | 'multiple', TItem extends TSelectOption> = {
  variant: Variant;

  /**
   * All available options for the select.
   *
   * Please ensure that options are not unnecessarily being sent as new references on each render.
   * Upstream components should ensure they meet basic quality requirements of only sending new
   * arrays when the options have actually been updated.
   */
  options: TSelectOptionList<TItem>;
  /**
   * Default checked items.
   *
   * Please note that default values are only considered on first mount.
   * Sending new arrays here will *not* change anything in the component.
   * If an empty array is sent on first render, the default state will be 0 checked items.
   * You must ensure that the component is only rendered once default items are ready.
   */
  defaultValue?: Variant extends 'single' ? TItem : TItem[];
  /**
   * Checked value.
   *
   * Will sync the component state when value is changed
   */
  controlledValue?: Variant extends 'single' ? TItem : TItem[];
  defaultOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onChange?: (item: Variant extends 'single' ? TItem | undefined : TItem[]) => void;
  search?: TSearchConfig<TItem>;
  searchPlaceholder?: string;
  /** Callback when on clear is clicked  */
  onClear?: () => void;
  /** Display a clear button on the field itself, need to implement `onClear` for it to work */
  showClearButton?: boolean;
};
/**
 * This is extremely internal blocks code and usage outside of 2-3 blocks components is never ever required.
 *
 * In a JS only world, we don't need the if-else block in this function. But with TS, that makes the code work simpler.
 * Also, having clear separation of the two variants makes it easier to understand and extend the code in long term.
 *
 * Has a weird name to help reviewers reject PRs.
 */
export const useSelectStore_DO_NOT_USE_EVER = <
  Variant extends 'single' | 'multiple',
  TItem extends TSelectOption = TSelectOption,
>({
  options,
  defaultValue,
  controlledValue,
  defaultOpen,
  onOpen,
  onClose,
  onChange,
  onClear,
  search,
  searchPlaceholder,
  variant,
}: TSelectHookProps<Variant, TItem>) => {
  const firstRenderVariant = useRef(variant);
  const disclosure = useDefaultDisclosure({ defaultOpen, onOpen, onClose });

  if (!['single', 'multiple'].includes(variant) || firstRenderVariant.current !== variant) {
    // ensure that during development of a new variant by someone else, we don't accidentally cause production crashes
    // and are enforced to keep all runtime types consistent with the features
    throw new Error('Invalid variant value or variant changed during runtime. Please use key={variantName}.');
  }

  /**
   *
   *
   * We are calling hooks conditionally here because we have ensured that even if the props changes the variant,
   * the component will not change the hook that is called, and will throw an error.
   *
   * This workaround enables us to bundle two separate type-safe variants with different types without resorting to type casting
   * and losing the safety that TS grants us with a very complex set of components around Select.
   *
   */

  const commonProps = {
    ...disclosure,
    onChange: (value: unknown) => {
      onChange?.(value as Variant extends 'single' ? TItem | undefined : TItem[]);
    },
    onClear: () => {
      onClear?.();
    },
    searchPlaceholder,
    options,
    search,
    componentType: 'select' as const,
  };

  /**
   * SINGLE VARIANT
   */
  if (firstRenderVariant.current === 'single') {
    const defValue = defaultValue as TItem | undefined;
    const conValue = controlledValue as TItem | undefined;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSelectStore_internal<'select', 'single', TItem>({
      ...commonProps,
      defaultValue: defValue ? String(defValue.value) : '',
      value: conValue ? String(conValue.value) : undefined,
      controlledValue: conValue,
      variant: 'single' as const,
    });
  }

  /**
   * MULTIPLE VARIANT
   */

  const defValue = defaultValue as TItem[] | undefined;
  const conValue = controlledValue as TItem[] | undefined;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSelectStore_internal<'select', 'multiple', TItem>({
    ...commonProps,
    defaultValue: defValue ? defValue.map(getStringifiedValueFromOption) : (EMPTY_ARRAY as []),
    value: conValue ? conValue.map(getStringifiedValueFromOption) : undefined,
    controlledValue: conValue,
    variant: 'multiple' as const,
  });
};

type TSelectRendererProps<Variant extends 'single' | 'multiple', TItem extends TSelectOption> = Omit<
  TSelectComboboxRendererProps<'select', Variant, TItem>,
  'options' | 'componentType' | 'selectStore' | 'onChange' | 'defaultValue' | 'value'
>;

export type TSelectProps<
  Variant extends 'single' | 'multiple' = 'single',
  TItem extends TSelectOption = TSelectOption,
> = TSelectRendererProps<Variant, TItem> & TSelectHookProps<Variant, TItem>;

function SelectC<Variant extends 'single' | 'multiple' = 'single', TItem extends TSelectOption = TSelectOption>({
  options,
  defaultValue,
  controlledValue,
  defaultOpen,
  onOpen,
  onClose,
  onChange,
  onClear,
  showClearButton,
  search,
  searchPlaceholder,
  variant,
  showLoader,
  ...componentProps
}: TSelectProps<Variant, TItem>) {
  const store = useSelectStore_DO_NOT_USE_EVER<Variant, TItem>({
    options,
    defaultValue,
    controlledValue,
    defaultOpen,
    onOpen,
    onClose,
    onChange,
    onClear,
    search,
    searchPlaceholder,
    variant,
  });

  useActiveIdLogic({ store });

  return (
    <SelectComboboxRenderer<'select', Variant, TItem>
      {...store}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(componentProps as any)}
      variant={variant}
      showLoader={showLoader}
      showClearButton={showClearButton}
      hasSearch={!!search}
    />
  );
}

export function useActiveIdLogic({ store }: { store: ReturnType<typeof useSelectStore_internal> }) {
  const { selectStore, comboboxStore, componentType } = store;
  const state = selectStore.getState();
  if (state.activeId === null && !(componentType === 'select' && comboboxStore)) {
    selectStore.setActiveId(selectStore.first());
  }
}

export const Select = React.memo(SelectC) as typeof SelectC;
