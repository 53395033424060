/**
 * Temporarily disabling ts on this file due to unresolved imports, will fix this post
 * code-mod work of building common from source
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * This needs to be direct import to avoid different versions of i18next being used,
 * will not be an issue once we move common to be built from source in web
 */
import i18nInstance, { type i18n } from 'i18next';

import { Locale } from 'date-fns';
import * as Locales from 'date-fns/locale';

// we allow import of obt-common here as it's a file with no library dependencies, doesn't need duplication
import {
  getExtendedDateTimeFormatOptions,
  ExtendedDateStyle,
  // TODO: Add alias of common like obt-web in blocks as well
} from 'obt-common/translations/localizers/extendedDateTime';

/**
 * In CI sometimes we get POSIX variant of locales, which causes downstream issues.
 * This ensures, correct code is always sent which should be BCP-47 compliant.
 * @param i18nModule
 * @returns
 */
export const getNormalisedLanguage = (i18nModule: i18n): string => {
  const currentLanguage = i18nModule.language;
  if (!currentLanguage) {
    return currentLanguage;
  }

  return currentLanguage.split('@')[0];
};

/**
 * This is copied from obt-common temporarily, will be remove after some changes to i18n fns in common,
 * outside the scope of this PR.
 *
 * obt-common utils aim to be backwards compatible, which makes them accept null, undefined, etc and lose type-safety.
 */
export const localiseDate = (date: Date, dateStyle: ExtendedDateStyle = 'medium'): string => {
  const locale = getNormalisedLanguage(i18nInstance) ?? 'en';
  // eslint-disable-next-line no-restricted-syntax
  return new Intl.DateTimeFormat(locale, getExtendedDateTimeFormatOptions({ dateStyle })).format(date);
};

export function getDateFnsLocale({ locale, region }: { locale: string; region: string }): Locale {
  return Locales[locale.substring(0, 2) + region] ?? Locales[locale.substring(0, 2)] ?? Locales.enUS;
}

export function useDateFnsLocale(language?: string) {
  const lang = language ?? getNormalisedLanguage(i18nInstance) ?? 'en-US';
  const region = lang.split('-')[1];
  return getDateFnsLocale({ locale: lang, region });
}

export type DateFormats = ExtendedDateStyle;
