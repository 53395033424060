import { useEffect, useState, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';

import type { Persona } from '../../types/api/v2/obt/model/persona';
import type { useAuth } from '../../providers/AuthProvider';
import { LoginState } from '../../providers/AuthProvider';
import type { AmplifyError, AuthProviderInfo } from '../../types/auth';
import { AuthProviderType } from '../../types/auth';

type UseAuthOutput = ReturnType<typeof useAuth>;

type UseSignInInput = {
  email: string;
  persona?: Persona;
  authProvidersInfo: AuthProviderInfo[];
  loginState: UseAuthOutput['loginState'];
  ssoLogin: UseAuthOutput['ssoLogin'];
  userpassLogin: UseAuthOutput['userpassLogin'];
  resetPassword: UseAuthOutput['resetPassword'];
  resendSignupCode: UseAuthOutput['resendSignupCode'];
};

type UseSignInOutput = {
  password: string;
  setPassword: (value: string) => void;
  showSSOOption: boolean;
  loginWithSSO: (providerInfo: AuthProviderInfo) => ReturnType<UseAuthOutput['ssoLogin']>;
  loginWithUserCredentials: () => ReturnType<UseAuthOutput['userpassLogin']>;
  isLoggingIn: boolean;
  loginError: AmplifyError | null;
  sendVerificationCode: () => ReturnType<UseAuthOutput['resendSignupCode']>;
  isSendingVerificationCode: boolean;
  sendingVerificationCodeError: unknown;
  sendingVerificationCodeSuccess: boolean;
  initiatePasswordReset: () => ReturnType<UseAuthOutput['resetPassword']>;
  isInitiatingPasswordReset: boolean;
  initiatingPasswordResetError: unknown;
  initiatingPasswordResetSuccess: boolean;
  availableSSOProviders: AuthProviderInfo[];
};

const supportedSSOProviders = [AuthProviderType.GOOGLE, AuthProviderType.CUSTOM];

function useSignIn({
  email,
  persona,
  authProvidersInfo,
  loginState,
  ssoLogin,
  userpassLogin,
  resetPassword,
  resendSignupCode,
}: UseSignInInput): UseSignInOutput {
  const availableSSOProviders = useMemo(
    () => authProvidersInfo.filter(({ type }) => supportedSSOProviders.includes(type)),
    [authProvidersInfo],
  );
  const showSSOOption = useMemo(() => availableSSOProviders.length > 0, [availableSSOProviders.length]);

  const [password, setPassword] = useState('');

  const loginWithSSOMutation = useMutation<void, AmplifyError, AuthProviderInfo>({
    mutationFn: (providerInfo: AuthProviderInfo) => ssoLogin(providerInfo.key),
  });

  const loginWithUserCredentialsMutation = useMutation<void, AmplifyError>({
    mutationFn: () => userpassLogin(email, password, persona),
  });

  const sendVerificationCodeMutation = useMutation({
    mutationFn: () => resendSignupCode(email),
  });

  const initiatePasswordResetMutation = useMutation({
    mutationFn: () => resetPassword(email),
  });

  useEffect(() => {
    loginWithSSOMutation.reset();
    loginWithUserCredentialsMutation.reset();
    sendVerificationCodeMutation.reset();
    initiatePasswordResetMutation.reset();
    // TODO: FIX_ESLINT_VIOLATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, email]);

  // TODO: Remove aliases (needed due to typescript version incompatibility) when upgrading to 4.x
  const loginWithUserCredentials = (): ReturnType<UseAuthOutput['userpassLogin']> =>
    loginWithUserCredentialsMutation.mutateAsync();
  const sendVerificationCode = (): ReturnType<UseAuthOutput['resendSignupCode']> =>
    sendVerificationCodeMutation.mutateAsync();
  const initiatePasswordReset = (): ReturnType<UseAuthOutput['resetPassword']> =>
    initiatePasswordResetMutation.mutateAsync();

  const isAuthenticating = loginState === LoginState.AUTHENTICATING || loginState === LoginState.LOGGED_IN;

  return {
    password,
    setPassword,
    showSSOOption,
    loginWithSSO: loginWithSSOMutation.mutateAsync,
    loginWithUserCredentials,
    isLoggingIn: loginWithUserCredentialsMutation.isPending || loginWithSSOMutation.isPending || isAuthenticating,
    loginError: loginWithSSOMutation.error || loginWithUserCredentialsMutation.error,
    sendVerificationCode,
    isSendingVerificationCode: sendVerificationCodeMutation.isPending,
    sendingVerificationCodeError: sendVerificationCodeMutation.error,
    sendingVerificationCodeSuccess: sendVerificationCodeMutation.isSuccess,
    initiatePasswordReset,
    isInitiatingPasswordReset: initiatePasswordResetMutation.isPending,
    initiatingPasswordResetError: initiatePasswordResetMutation.error,
    initiatingPasswordResetSuccess: initiatePasswordResetMutation.isSuccess,
    availableSSOProviders,
  };
}

export default useSignIn;
