import { useMutation, useQuery } from '@tanstack/react-query';

import type { LegalEntityId } from '../../types/api/v2/obt/model/legal-entity-id';
import type { LegalEntity } from '../../types/api/v2/obt/model/legal-entity';
import type { UpdateLegalEntityRequestType } from '../../types/api/v1/obt/profile/organization_service';
import { UpdateLegalEntityRequestTypeEnum } from '../../types/api/v1/obt/profile/organization_service';
import type {
  UpdateLegalEntityThirdPartyInfoRequest,
  ReadLegalEntityThirdPartyInfoRequest,
} from '../../types/api/v1/obt/supplier/update_third_party_info';
import type { OrganizationThirdPartyInfo } from '../../types/api/v1/obt/supplier/third_party_info';
import type { OrganizationId } from '../../types/api/v1/obt/common/user_org_id';
import type {
  ILegalEntity,
  LegalEntityCreateRequest,
  SpotnanaQueryMutationResult,
  SpotnanaQueryResult,
  Tier,
} from '../../types';
import SpotnanaError from '../../api/SpotnanaError';
import api from '../../api';

import { updateLegalEntityTier } from './legalEntityTier';
import { invalidateExpensePartnerConfigs } from './expensePartnerConfig';

export const saveLegalEntity = async (
  organizationId: string,
  legalEntityId: string,
  legalEntity: LegalEntity,
): Promise<void> => {
  const data = { ...legalEntity, id: legalEntityId };
  await api('PUT', 'companyBaseUrl', {
    urlParam: `/${organizationId}/legal-entities/${legalEntityId}`,
    data,
  });
};

export const removeLegalEntity = async (organizationId: string, legalEntityId: string): Promise<void> => {
  await api('DELETE', 'companyBaseUrl', {
    urlParam: `/${organizationId}/legal-entities/${legalEntityId}`,
    data: { legalEntityId },
  });
};

interface CreateLegalEntityResponse {
  id?: string;
}

export const addLegalEntity = async (
  organizationId: string,
  legalEntity: LegalEntityCreateRequest,
): Promise<CreateLegalEntityResponse> => {
  const data = await api('POST', 'companyBaseUrl', {
    urlParam: `/${organizationId}/legal-entities`,
    data: legalEntity,
  });

  const responseData = data as CreateLegalEntityResponse;
  if (!responseData?.id) {
    throw new Error('createLegalEntity was a success but it did not return a legalEntityId');
  }

  return responseData;
};

const { NAME, DBA, EIN, ADDRESS, PHONE_NUMBERS, BILLING_CURRENCY, IS_DELAYED_INVOICING_ENABLED } =
  UpdateLegalEntityRequestTypeEnum;

const defaultLegalEntityUdpdateFields: UpdateLegalEntityRequestTypeEnum[] = [
  NAME,
  DBA,
  EIN,
  ADDRESS,
  PHONE_NUMBERS,
  BILLING_CURRENCY,
  IS_DELAYED_INVOICING_ENABLED,
];

export interface UpdateLegalEntityRequest {
  legalEntityId?: LegalEntityId;
  legalEntity?: ILegalEntity;
  types: UpdateLegalEntityRequestType[];
}

const updateLegalEntity = async (
  legalEntityId: LegalEntityId,
  legalEntity: ILegalEntity,
  updateFieldTypes: UpdateLegalEntityRequestTypeEnum[] = defaultLegalEntityUdpdateFields,
): Promise<void> => {
  const updateLegalEntityRequest: UpdateLegalEntityRequest = {
    legalEntityId,
    legalEntity,
    types: updateFieldTypes as UpdateLegalEntityRequestType[],
  };

  await api('POST', 'updateLegalEntity', { data: updateLegalEntityRequest });
};

interface IUseUpdateLegalEntity {
  legalEntityId: LegalEntityId;
  legalEntity: ILegalEntity;
}

export const useUpdateLegalEntity = (
  updateFieldTypes: UpdateLegalEntityRequestTypeEnum[] = defaultLegalEntityUdpdateFields,
  organizationId: string | undefined,
): SpotnanaQueryMutationResult<void, IUseUpdateLegalEntity> =>
  useMutation({
    mutationFn: ({ legalEntityId, legalEntity }: IUseUpdateLegalEntity) =>
      updateLegalEntity(legalEntityId, legalEntity, updateFieldTypes),

    onSuccess: (_res) => {
      invalidateExpensePartnerConfigs(organizationId);
    },
  });

const readLegalEntityThirdPartyInfo = async (
  requestBody: ReadLegalEntityThirdPartyInfoRequest,
): Promise<OrganizationThirdPartyInfo> => {
  try {
    const result = (await api('POST', 'readLegalEntityThirdPartyInfo', {
      data: requestBody,
    })) as OrganizationThirdPartyInfo;
    return result;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

const legalEntityKey = (legalEntityId?: LegalEntityId): unknown[] => ['legalEntityThirdPartyInfo', legalEntityId?.id];

export const useReadLegalEntityThirdPartyInfo = (
  legalEntityId?: LegalEntityId,
  options?: {
    enabled: boolean;
    refetchOnMount?: boolean | 'always';
  },
): SpotnanaQueryResult<OrganizationThirdPartyInfo> =>
  useQuery<OrganizationThirdPartyInfo, SpotnanaError>({
    queryKey: legalEntityKey(legalEntityId),
    queryFn: () => readLegalEntityThirdPartyInfo({ legalEntityId: { id: legalEntityId?.id ?? '' } }),
    ...options,
  });

const updateLegalEntityThirdPartyInfo = async (requestBody: UpdateLegalEntityThirdPartyInfoRequest): Promise<void> => {
  try {
    await api('POST', 'updateLegalEntityThirdPartyInfo', {
      data: requestBody,
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

type TAddLegalEntityWithThirdPartyInfoRequest = UpdateLegalEntityThirdPartyInfoRequest & {
  organizationId: OrganizationId;
  legalEntity: LegalEntityCreateRequest;
  enableThirdPartyInfoUpdate: boolean;
  tier?: Tier;
};

export const useAddLegalEntityWithThirdPartyInfo = (): SpotnanaQueryMutationResult<
  LegalEntityId | undefined,
  TAddLegalEntityWithThirdPartyInfoRequest
> =>
  useMutation({
    mutationFn: async ({
      organizationId,
      legalEntity,
      orgThirdPartyInfo,
      updateTypes,
      enableThirdPartyInfoUpdate,
      tier,
    }: TAddLegalEntityWithThirdPartyInfoRequest) => {
      const { id: legalEntityId } = await addLegalEntity(organizationId?.id, legalEntity);
      await Promise.all([
        enableThirdPartyInfoUpdate
          ? updateLegalEntityThirdPartyInfo({
              legalEntityId: { id: legalEntityId ?? '' },
              orgThirdPartyInfo,
              updateTypes,
            })
          : Promise.resolve(),
        tier ? updateLegalEntityTier(organizationId?.id, legalEntityId ?? '', tier) : Promise.resolve(),
      ]);
      return legalEntityId as LegalEntityId;
    },
  });

type TUpdateLegalEntityWithThirdPartyInfoRequest = UpdateLegalEntityThirdPartyInfoRequest & {
  organizationId: OrganizationId;
  legalEntity: LegalEntity;
  legalEntityId: LegalEntityId;
  enableThirdPartyInfoUpdate: boolean;
  tier?: Tier;
};

export const useUpdateLegalEntityWithThirdPartyInfo = (): SpotnanaQueryMutationResult<
  LegalEntityId,
  TUpdateLegalEntityWithThirdPartyInfoRequest
> =>
  useMutation({
    mutationFn: async ({
      organizationId,
      legalEntityId,
      legalEntity,
      orgThirdPartyInfo,
      updateTypes,
      enableThirdPartyInfoUpdate,
      tier,
    }: TUpdateLegalEntityWithThirdPartyInfoRequest) => {
      await saveLegalEntity(organizationId?.id, legalEntityId.id ?? '', legalEntity);
      await Promise.all([
        enableThirdPartyInfoUpdate
          ? updateLegalEntityThirdPartyInfo({
              legalEntityId,
              orgThirdPartyInfo,
              updateTypes,
            })
          : Promise.resolve(),
        tier ? updateLegalEntityTier(organizationId?.id, legalEntityId.id ?? '', tier) : Promise.resolve(),
      ]);
      return legalEntityId;
    },
  });
