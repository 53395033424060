/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Suffix for name
 * @export
 * @enum {string}
 */

export const NameSuffix = {
  NameSuffixUnknown: 'NAME_SUFFIX_UNKNOWN',
  Sr: 'SR',
  Jr: 'JR',
  Md: 'MD',
  Phd: 'PHD',
  Ii: 'II',
  Iii: 'III',
  Iv: 'IV',
  Do: 'DO',
  Atty: 'ATTY',
  V: 'V',
  Vi: 'VI',
  Esq: 'ESQ',
  Dc: 'DC',
  Dds: 'DDS',
  Vm: 'VM',
  Jd: 'JD',
  Second: 'SECOND',
  Third: 'THIRD',
} as const;

export type NameSuffix = (typeof NameSuffix)[keyof typeof NameSuffix];
