import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { GetTripDetailsResponse } from '../../types/api/v1/obt/trip/trip_details';
import dateUtil from '../../date-utils/dateUtil';
import minutesToDurationString from '../../date-utils/minutesToDurationString';
import AirPnrCancellationResponseManager from '../../services/AirPnrCancellationResponseManager';
import { defineMessage } from '../../translations/defineMessage';
import type { CancellationBreakupComponent, IUsePnrCancellationInfo } from '../../types/Flights/pnrCancellation';
import { RefundModeEnum } from '../../types/Flights/pnrCancellation';
import { getAirPnr, getCancellationBannerText, getTripDetailsResponseManager } from '../../utils/Flights/Cancel';
import { MoneyUtil } from '../../utils/Money';
import type { IServiceFeeData, PnrCancellationDetails } from '../../types';
import { AirCancellationState } from '../../types';
import { IUserFacingStatusEnum } from '../../constants/trips';
import { useHasUserAccess } from '../../components';
import { userRolesByFeature } from '../../constants';
import { getFareBreakupInfoFromCancellationDetails } from '../../utils/cancelFareBreakup/getFareBreakupInfoFromCancellationDetails';
import { getFareBreakupNodesFromCancellationInfo } from '../../utils/cancelFareBreakup/getFareBreakupNodesFromCancellationInfo';

interface IProps {
  pnrId: string;
  newPenaltyAmount: MoneyUtil;
  pnrCancellationDetails?: PnrCancellationDetails;
  tripDetailsResponse?: GetTripDetailsResponse;
  isSeat1ATraveler: boolean;
  serviceFees: IServiceFeeData;
}

const { utc } = dateUtil;

export const usePnrCancellationInfo = ({
  pnrId,
  newPenaltyAmount,
  pnrCancellationDetails,
  tripDetailsResponse,
  isSeat1ATraveler,
  serviceFees,
}: IProps): IUsePnrCancellationInfo | null => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation('COMMON');

  const hasAgentAccess = useHasUserAccess(userRolesByFeature.organizationSelector);

  const tripDetailsResponseManager = useMemo(
    () => getTripDetailsResponseManager(tripDetailsResponse),
    [tripDetailsResponse],
  );

  const [selectedRefundMode, setSelectedRefundMode] = useState<RefundModeEnum | undefined>(undefined);
  const [refundModeOptionId, setRefundModeOptionId] = useState<string>('');

  const airPnr = useMemo(() => getAirPnr(tripDetailsResponseManager, pnrId), [tripDetailsResponseManager, pnrId]);
  const hasScheduleChangeStatus = !!airPnr?.pnr?.air?.legInfo?.some(
    (details) => details.legStatus === IUserFacingStatusEnum.SCHEDULE_CHANGE_STATUS,
  );

  const pnrCancellationResponseManager = pnrCancellationDetails
    ? new AirPnrCancellationResponseManager(pnrCancellationDetails)
    : null;

  const applicableRefundModes = pnrCancellationResponseManager?.getApplicableRefundModes();

  useEffect(() => {
    if (applicableRefundModes?.[0]?.refundMode && !selectedRefundMode) {
      setSelectedRefundMode(applicableRefundModes[0].refundMode);
      setRefundModeOptionId(applicableRefundModes[0].optionId ?? '');
    }
  }, [applicableRefundModes, selectedRefundMode]);

  const updateRefundMode = (optionId: string): void => {
    setRefundModeOptionId(optionId);

    const newRefundMode = applicableRefundModes?.find((refundMode) => refundMode.optionId === optionId)?.refundMode;
    setSelectedRefundMode(newRefundMode);
  };

  let voidDuration: string | undefined;

  if (!pnrCancellationResponseManager || !applicableRefundModes) {
    return null;
  }

  const voidPeriod = pnrCancellationResponseManager.getVoidPeriod();

  const cancellationState = pnrCancellationResponseManager.getCancellationState();

  const cancellationNotSupportedPriorityReasons =
    pnrCancellationResponseManager.getCancellationNotSupportedPriorityReasons();

  const cancellationNotSupportedReasons = pnrCancellationResponseManager.getCancellationNotSupportedReasons();

  const fareBreakups = pnrCancellationResponseManager.getFareBreakup();

  const isNonRefundableNonExchangeable = pnrCancellationResponseManager.getIsNonRefundableNonExchangeable();
  const fareInfoIndex = pnrCancellationResponseManager.getSelectedFareInfoIndex(
    isNonRefundableNonExchangeable,
    selectedRefundMode,
  );

  const selectedRefundModeInfo = applicableRefundModes.find((refundMode) => refundMode.optionId === refundModeOptionId);

  const optionId = selectedRefundModeInfo?.optionId;

  let fareBreakupConfig: CancellationBreakupComponent[] = [];

  const fareBreakup = fareInfoIndex >= 0 ? fareBreakups[fareInfoIndex] : undefined;

  const shouldShowFarBreakup =
    fareBreakup &&
    (cancellationState === AirCancellationState.CancellableByObt ||
      cancellationState === AirCancellationState.CancellableBySupport);

  let flightScheduleChangeWarning = '';

  let finalTotalRefund = selectedRefundModeInfo?.refundAmount;
  let finalAirlineCancellationFee = MoneyUtil.zeroMoney();

  if (shouldShowFarBreakup) {
    const fareBreakupInfo = getFareBreakupInfoFromCancellationDetails({
      fareInfo: fareBreakup,
      fareInfoIndex,
      newPenaltyAmount,
      selectedRefundMode,
      selectedRefundModeInfo,
      pnrCancellationResponseManager,
      serviceFees,
    });
    finalTotalRefund = fareBreakupInfo.totalRefund;
    finalAirlineCancellationFee = fareBreakupInfo.airlineCancellationFee;

    const isCancellable =
      cancellationState === AirCancellationState.CancellableByObt ||
      cancellationState === AirCancellationState.CancellableBySupport;
    // This is required because if booking is already refundable, no need to show warning to customer.
    const isTotalRefund = fareBreakupInfo.totalRefund?.equals(fareBreakupInfo.totalPaid) || false;

    if (isCancellable && !isTotalRefund && hasScheduleChangeStatus) {
      flightScheduleChangeWarning = tCommon(
        defineMessage(
          'If your flight schedule was changed by over 2 hours you may be eligible for a full refund, please contact support',
        ),
      );
    }

    fareBreakupConfig = getFareBreakupNodesFromCancellationInfo(fareBreakupInfo, t, tCommon);
  }

  const hasMultipleRefundModes = applicableRefundModes.length > 1;

  const cancellationBannerText = getCancellationBannerText({
    isNonRefundableNonExchangeable,
    hasMultipleRefundModes,
    isSeat1ATraveler,
    cancellationState,
    cancellationNotSupportedPriorityReasons,
    isAgent: hasAgentAccess,
  });

  let cancellationButtonText = tCommon(defineMessage('Confirm cancellation'));

  if (cancellationState === AirCancellationState.CancellableBySupport) {
    cancellationButtonText = tCommon(defineMessage('Submit cancellation request'));
  }

  if (
    cancellationState === AirCancellationState.CancellationInfoNotAvailable ||
    (isSeat1ATraveler && cancellationState === AirCancellationState.NonCancellable)
  ) {
    cancellationButtonText = tCommon(defineMessage('Contact support'));
  }

  const shouldContactSupport =
    cancellationState === AirCancellationState.CancellationInfoNotAvailable ||
    (cancellationState === AirCancellationState.NonCancellable && isSeat1ATraveler);

  let modeOfPaymentInfo: { heading: string; subText: string } | undefined;
  let additionalInformation: { heading: string; subText: string } | undefined;

  if (
    (cancellationState === AirCancellationState.CancellableByObt ||
      cancellationState === AirCancellationState.CancellableBySupport) &&
    !hasMultipleRefundModes &&
    fareBreakupConfig.length > 0 &&
    !isNonRefundableNonExchangeable
  ) {
    if (selectedRefundMode === RefundModeEnum.FLIGHT_CREDITS) {
      modeOfPaymentInfo = {
        heading: tCommon(defineMessage('Refund mode')),
        subText: tCommon(
          defineMessage(
            'Flight credits will be added to your profile. These can be used for future bookings with the same carrier.',
          ),
        ),
      };
    }

    if (selectedRefundMode === RefundModeEnum.MIXED_PAYMENT_MODE) {
      modeOfPaymentInfo = {
        heading: tCommon(defineMessage('Refund mode')),
        subText: tCommon(
          defineMessage(
            'The refund amount will be credited to the original payment method and the flight credits will be added to your profile. These can be used for future bookings with the same carrier.',
          ),
        ),
      };
    }

    if (selectedRefundMode === RefundModeEnum.ORIGINAL_PAYMENT_MODE) {
      modeOfPaymentInfo = {
        heading: tCommon(defineMessage('Refund mode')),
        subText: tCommon(defineMessage('The refund amount will be credited to the original payment method.')),
      };
    }
  }

  if (fareBreakupConfig.length > 0) {
    additionalInformation = {
      heading: tCommon(defineMessage('Additional information')),
      subText: tCommon(
        defineMessage(
          'You may be eligible for additional refund from the airlines if you have purchased any add-ons like seat or extra baggage. Please contact the airlines if the refund is not processed.',
        ),
      ),
    };
  }

  if (voidPeriod) {
    const voidEndTime = utc(voidPeriod);
    const now = utc();
    const minutes = voidEndTime.diff(now, 'minute');
    voidDuration = minutes > 0 ? minutesToDurationString(minutes) : undefined;
  }

  return {
    voidDuration,
    cancellationBannerText,
    cancellationButtonText,
    modeOfPaymentInfo,
    additionalInformation,
    fareBreakupConfig,
    cancellationState,
    cancellationNotSupportedReasons,
    updateRefundMode,
    applicableRefundModes,
    selectedRefundMode,
    optionId,
    shouldContactSupport,
    flightScheduleChangeWarning,
    airlineCancellationFee: finalAirlineCancellationFee,
    totalRefund: finalTotalRefund,
    selectedRefundOptionId: refundModeOptionId,
  };
};
