import { useCallback, useState } from 'react';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, $insertNodes, LexicalEditor, TextNode } from 'lexical';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from '@lexical/rich-text';

import { useTranslation } from 'react-i18next';
import { RichTextEditorInternal } from './Editor';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';

import { main_styles } from './styles';
import { EditorToolbarCustomizationProps } from './types';
import { ExtendedTextNode } from './ExtendedTextNode';
import { Switch } from '../Switch';
import { preview_html, preview_toggle_parent } from './index.styles';

export type RichTextEditorProps = EditorToolbarCustomizationProps & {
  initialHtml: string;
  onHtmlChange: (newHtml: string) => void;
  showPreviewButton: boolean;
  placeholderText?: string;
  mode?: 'edit' | 'view';
  state?: 'error' | 'success';
  className?: string;
  inputId: string;
  maxLength?: number;
  ariaLabel?: string;
};

const getInitialEditorStateFn = (editor: LexicalEditor, html?: string) => {
  if (!html) {
    return;
  }

  editor.update(
    () => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(html, 'text/html');

      // Below is a workaround for these issues
      // https://github.com/facebook/lexical/issues/2807
      // https://github.com/facebook/lexical/issues/3677
      const elementsWithBr = dom.querySelectorAll('*:not(br) > br');
      for (let i = 0; i < elementsWithBr.length; i += 1) {
        const brElement = elementsWithBr[i];
        const parentElement = brElement.parentNode;
        const lastBr = parentElement?.querySelectorAll('br:last-child')[0];
        if (lastBr === brElement) {
          parentElement?.removeChild(brElement);
        }
      }

      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().select();
      $insertNodes(nodes);
    },
    { discrete: true },
  );
};

export const RichTextEditor = ({
  initialHtml,
  onHtmlChange,
  showPreviewButton,
  mode = 'edit',
  state,
  className,
  ...editorCustomizationProps
}: RichTextEditorProps): JSX.Element => {
  const { t: tt } = useTranslation('WEB');

  const initialConfig: InitialConfigType = {
    namespace: 'SpotnanaRichTextEditor',
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      AutoLinkNode,
      LinkNode,
      ExtendedTextNode,
      // eslint-disable-next-line no-underscore-dangle
      { replace: TextNode, with: (node: TextNode) => new ExtendedTextNode(node.__text) },
    ],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
    editorState: (editor: LexicalEditor) => getInitialEditorStateFn(editor, initialHtml),
  };
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const onEditorHtmlChange = useCallback(
    (newHtml: string) => {
      onHtmlChange(newHtml);
    },
    [onHtmlChange],
  );
  const onToggle = () => setIsPreviewVisible((previousValue) => !previousValue);
  if (mode === 'view') {
    return (
      <div
        css={preview_html}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: initialHtml }}
      />
    );
  }
  return (
    <div css={main_styles} className={className}>
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextEditorInternal {...editorCustomizationProps} onHtmlChange={onEditorHtmlChange} state={state} />
        <>
          {showPreviewButton && (
            <div css={preview_toggle_parent}>
              <Switch
                label={tt('Preview')}
                labelPlacement="start"
                checked={isPreviewVisible}
                onChange={onToggle}
                size="regular"
              />
            </div>
          )}
          {isPreviewVisible && (
            <div
              css={preview_html}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: initialHtml }}
            />
          )}
        </>
      </LexicalComposer>
    </div>
  );
};
