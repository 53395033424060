export enum HotelFareBreakupSlotType {
  Header,
  Divider,
  Fare,
  SecondaryInfo,
}

export enum HotelFareBreakupSlotFareVariant {
  Normal = 'normal',
  Emphasized = 'emphasized',
  Total = 'total',
}

export enum HotelFareBreakupVariant {
  Mobile = 'mobile',
  Web = 'web',
}

export type HotelFareBreakupSlotConfig =
  | {
      type: HotelFareBreakupSlotType.Header;
      text: string;
      testID?: string;
    }
  | {
      type: HotelFareBreakupSlotType.Divider;
      variant?: 'transparent';
      testID?: string;
    }
  | {
      type: HotelFareBreakupSlotType.Fare;
      label: string;
      value: string;
      secondaryValue?: string;
      brexValue?: string;
      variant?: HotelFareBreakupSlotFareVariant;
      subLabel?: string;
      infoHeader?: string;
      infoBody?: string;
      /** @param infoHidden - if true, info icon will be explicitly hidden, false by default */
      infoHidden?: boolean;
      testID?: string;
    }
  | {
      type: HotelFareBreakupSlotType.SecondaryInfo;
      text: string;
      testID?: string;
    };
