import { useQuery } from '@tanstack/react-query';

import api from '../../api';
import SpotnanaError from '../../api/SpotnanaError';
import type { SpotnanaQueryResult, Tier } from '../../types';

const getLegalEntityTierKey = (organizationId: string | undefined, legalEntityId: string | undefined): unknown[] => [
  'legal-entity-tier-key',
  organizationId,
  legalEntityId,
];

export const fetchLegalEntityTier = async (
  organizationId: string | undefined,
  legalEntityId: string | undefined,
): Promise<{ tier: Tier }> =>
  (await api(
    'GET',
    'companyBaseUrl',
    {
      urlParam: `/${organizationId}/legal-entities/${legalEntityId}/tier`,
    },
    { subType: 'legalEntityTier' },
  )) as Promise<{ tier: Tier }>;

export const useFetchLegalEntityTierQuery = (
  organizationId: string | undefined,
  legalEntityId: string | undefined,
  options: {
    enabled?: boolean;
    refetchOnMount?: boolean | 'always';
  } = {
    enabled: true,
  },
): SpotnanaQueryResult<{ tier: Tier }> =>
  useQuery<{ tier: Tier }, SpotnanaError>({
    queryKey: getLegalEntityTierKey(organizationId, legalEntityId),
    queryFn: () => fetchLegalEntityTier(organizationId, legalEntityId),
    enabled: !!organizationId && !!legalEntityId && !!options.enabled,
    refetchOnMount: options.refetchOnMount,
  });

export const updateLegalEntityTier = async (
  organizationId: string | undefined,
  legalEntityId: string | undefined,
  tier: Tier,
): Promise<void> => {
  if (!organizationId || !legalEntityId) {
    throw new SpotnanaError('Missing some of the required parameters when trying to update tier');
  }
  try {
    await api('PUT', 'companyBaseUrl', {
      urlParam: `/${organizationId}/legal-entities/${legalEntityId}/tier`,
      data: { tier },
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};
