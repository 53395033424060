import type * as React from 'react';

import type { CreditCardAccessType } from '@spotnana/types/openapi/models/credit-card-access-type';
import type { CSSObject } from 'styled-components';
import type { CompanySpecifiedAttributeLegalEntity } from '@spotnana/types/openapi/models/company-specified-attribute-legal-entity';
import type { HrFeedPolicyDetails } from '@spotnana/types/openapi/models/hr-feed-policy-details';
import type {
  OrganizationThirdPartyInfoSabreInfo,
  OrganizationThirdPartyInfoSabreInfoRemark,
  OrganizationThirdPartyInfoSabreInfoVendorProgramInfo,
} from './api/v1/obt/supplier/third_party_info';
import type {
  CostCenter,
  Department,
  Grade,
  LegalEntity,
  LegalEntityId,
  LegalEntityNode,
  OfficeId,
  Organization,
  OrganizationBasicInfo,
  OrganizationNode,
} from './api/v1/obt/common/organization';
import type {
  HotelCancellationPropsType,
  HotelRateConditionsNotAllowedPropsType,
  HotelRating,
  HotelRestrictedKeywordsPropsKeywords,
  LowestLogisticalFarePropsAirportConnectionChanges,
  LowestLogisticalFarePropsCarrierType,
  LowestLogisticalFarePropsMaxNumberOfStops,
  MaxFlightBookingPriceProps,
  AllowedAirAddonsPropsAddon,
  MaxHotelBookingPriceProps,
  LowestFarePerHotelPropertyProps,
  TimeLocal,
  FlightCabinUpgradeV2Props,
} from './api/v1/obt/policy/policy_rules';
import { FlightTicketsChangeablePropsType, FlightTicketsRefundablePropsType } from './api/v1/obt/policy/policy_rules';
import type {
  AirClid3Code,
  AirClid4Code,
  AirTourCode,
  ConfermaMetadataCompanyEnum,
  HotelRateCode,
  Latlng,
  LengthUnit,
  ListPaymentInfosRequest,
  ListPaymentInfosResponse,
  RoleType,
  Tier,
  UaPassPlus,
  UserId,
} from './api/v2/obt/model';
import type {
  IDesignation,
  IOffice,
  IPersonaBasicInfo,
  IPhoneNumber,
  IPostalAddress,
  ISearchTravelerResponseTravelerSearchResult,
  ITravelerName,
  IUserBasicInfo,
  IUserOrgId,
} from './traveler';
import type {
  GetMergedPolicyResponse,
  ListPoliciesResponse,
  ReadPolicyResponse,
} from './api/v1/obt/policy/policy_service';
import type { CompanyId, OrganizationId, TierEnumNumerals, UserOrgId } from './api/v1/obt/common/user_org_id';
import type { Policy, PolicyUserGroup } from './api/v1/obt/policy/policy';
import { PolicyApprovalInfoApprovalProcessType } from './api/v1/obt/policy/policy';
import type { RoleInfoType } from './api/v1/obt/profile/role/roles_info';
import { RoleInfoTypeEnum } from './api/v1/obt/profile/role/roles_info';

import type { AuthConfigAuthProviderAuthProviderType } from './api/v1/obt/common/auth_config';
import type { Employee } from './api/v1/obt/common/employee';
import type { ImageV1 } from './api/v1/common/image_group';
import type { Name } from './api/v1/common/name';
import type { PhoneNumber } from './api/v1/common/phone_number';
import type { PolicyCategory } from './api/v1/obt/policy/policy_common';
import { PolicyType, ActionDisplayText } from './api/v1/obt/policy/policy_common';
import type { ReadEmployeeResponse } from './api/v1/obt/profile/profile_services';
import { UpdateOrganizationRequestTypeEnum } from './api/v1/obt/profile/organization_service';
import type { UserBasicInfo } from './api/v1/obt/common/user_basic_info';
import { UpdateTypeWithAllocatedPCC } from './api/v1/obt/tmc/update_third_party_info';
import type { VendorProgram } from './api/v2/obt/model/vendor-program';
import type { GenericVendorProgramInfo } from './api/v2/obt/model/generic-vendor-program-info';
import type { TitleEnum } from './api/v1/obt/common/title';
import type { TravelType } from './api/v2/obt/model/travel-type';
import type { RemarkInfoTypeEnum } from './api/v2/obt/model/remark-info';
import type { ExpensePartnerConfig } from './api/v1/obt/common/expense_partner_config';
import type { CustomFieldQuestionType } from './customFields';
import type { Cabin } from './api/v1/obt/air/air_common';
import type { CarType, EngineType } from './api/v1/obt/car/car_common';
import type { TravelClass } from './api/v1/obt/rail/rail_common';
import type { Money } from './api/v1/common/money';
import type { ILabelValuePair } from './common';
import type { ClientAuthenticationMethod } from './api/v2/obt/model/client-authentication-method';
import type { UserBusinessInfoWorkerType } from './api/v1/obt/common/user_business_info';
import type { PreferenceTierLevel } from './api/v2/obt/model/preference-tier-level';
import type { Persona } from './api/v2/obt/model/persona';

export type {
  OrganizationThirdPartyInfoSabreInfoAllocatedPcc,
  OrganizationThirdPartyInfo,
  OrganizationThirdPartyInfoSabreInfoRemark,
} from './api/v1/obt/supplier/third_party_info';
export { TravelType as ThirdPartyInfoTravelTypeEnum } from './api/v1/obt/tmc/update_third_party_info';
export { UpdateType as ThirdPartyInfoUpdateTypeEnum } from './api/v1/obt/supplier/update_third_party_info';

export { TravelType as ExpensePartnerTravelType } from './api/v1/common/tmc';
export { ExpensePartnerConfigPartner } from './api/v1/obt/common/expense_partner_config';
export type { ExpensePartnerConfig } from './api/v1/obt/common/expense_partner_config';
export { UpdateLegalEntityRequestTypeEnum } from './api/v1/obt/profile/organization_service';
export type { MaxFlightBookingPriceProps } from './api/v1/obt/policy/policy_rules';
export {
  LowestLogisticalFarePropsMaxNumberOfStops,
  LowestLogisticalFarePropsAirportConnectionChanges,
  LowestLogisticalFarePropsCarrierType,
} from './api/v1/obt/policy/policy_rules';

export type { HotelRestrictedKeywordsPropsKeywords } from './api/v1/obt/policy/policy_rules';
export { MaxFlightBookingPricePropsType, MaxFlightBookingPricePropsSign } from './api/v1/obt/policy/policy_rules';

export interface IOrgOverview {
  companyName: string;
  billingCurrency: string;
  emailDomains: string[];
  emergencyContacts: ICompanyEmergencyContact[];
  ccEmails: string[];
  organizationLogo?: ImageV1;
  isFake: boolean;
}

export interface ILegalEntityDetail {
  name: string;
  dba: string;
  ein: string;
  billingCurrency: string;
  isDelayedInvoicingEnabled?: boolean;
  bookingTmcRef?: CompanyId;
  ccEmailAddresses?: string[];
  externalId?: string;
  companySpecifiedAttributes?: CompanySpecifiedAttributeLegalEntity[];
}

export interface ILegalEntityData {
  details: ILegalEntityDetail;
  address: IPostalAddress;
  phoneNumber: IPhoneNumber;
}

export interface ILegalEntityName {
  id: LegalEntityId;
  name: string;
}

export interface IOfficeTableData {
  officeId: OfficeId;
  officeName: string;
  legalEntityId: LegalEntityId;
  legalEntityName: string;
  address: string;
  city: string;
  country: string;
  countryFlag: React.ReactNode;
  employeeCount?: number;
}

export interface IOfficeLEOption extends ILabelValuePair {
  legalEntityId: string;
}

export interface IOfficeDetails {
  name: string;
  legalEntityId: string;
  taxId?: string;
  latlng?: Latlng;
}

export interface IOfficeData {
  details: IOfficeDetails;
  address: IPostalAddress;
  isLegalEntityEditable: boolean;
}

export interface ILegalEntityTable {
  id: LegalEntityId;
  name: string;
  addressLine1: string;
  city: string;
  country: string;
  countryFlag: React.ReactNode;
  taxNumber: string;
  billingCurrency: string;
  employeeCount?: number;
  officeCount?: number;
  expensePartnerConfig?: ExpensePartnerConfig;
}

export interface ICompanyEmergencyContact {
  email: string;
  name: Name;
  phoneNumber: PhoneNumber;
  designation: string;
}

export interface IGeneralRemarks {
  dkNumber: string;
  templateId: string;
  filterId: string;
  pcc: string;
}

export interface IThirdPartyDetails {
  general: IGeneralRemarks;
  l1Remarks: string[];
  pnrRemarks: ISabreInfoRemark[];
  airClid3: string[][];
  airClid4: string[];
  hotelRateCode: string[];
  airTourCode: string[][];
  airCtid: string[][];
  carCodes: CarCode[];
  vPayCarIdNumbers: VPayCarIdNumber[];
}
export interface CarCode {
  vendorName: string;
  vendorCode: string;
  isVirtualPayOnly: boolean;
}

export interface VPayCarIdNumber {
  vendorName: string;
  vPayIdNumber: string;
  isVirtualPayOnly: boolean;
}
export interface ICompanyAuthentication {
  type: AuthConfigAuthProviderAuthProviderType | null;
  userProvisionAllowed: boolean;
  applicationId: string;
  tokenEndpoint: string;
  clientAuthentication: ClientAuthenticationMethod | undefined;
  requireProxy: boolean;
  idleSessionTimeoutMin: number;
}

export interface IEmployeeDetails {
  userOrgId?: UserOrgId;
  title: TitleEnum;
  name: ITravelerName;
  email: string;
  phoneNumber: IPhoneNumber;
  designation: string;
  employeeId: string;
  manager: IUserBasicInfo | null;
  tier?: TierEnumNumerals;
  workerType?: UserBusinessInfoWorkerType;
  accountingCode?: string;
}

export interface IEmployeeCompanyDetails {
  legalEntityId: string;
  officeId: string;
  departmentId: string;
  costCenterId: string;
  countryCode?: string;
  ecc?: string;
}

export enum NewUserTypeEnum {
  EMPLOYEE = 'EMPLOYEE',
  GUEST = 'GUEST',
  LEISURE = 'LEISURE',
}

export interface INewUserData {
  newUserType: NewUserTypeEnum;
  employeeDetails: IEmployeeDetails;
  employeeCompanyDetails: IEmployeeCompanyDetails;
  preferredCurrency?: string;
}

export enum CustomRoleInfoType {
  TRAVELER,
  ARRANGER,
  COMPANY_ADMIN,
  SPOTNANA_AGENT,
  SPOTNANA_ADMIN,
  TMC_ADMIN,
  TMC_AGENT,
}

export type ManageForType = 'individuals' | 'everyone';

export interface ManageForOptionsType {
  label: string;
  value: ManageForType;
}

export interface UserManagementRoleInfo {
  value: CustomRoleInfoType;
  label: string;
  manageFor?: ManageForOptionsType;
  travelArrangerFor?: UserBasicInfo[];
  description?: string;
}

export interface RoleOptionType {
  label: string;
  value: CustomRoleInfoType;
  description: string;
  manageFor?: ManageForOptionsType;
  travelArrangerFor?: UserBasicInfo[];
  backEndValue: RoleInfoType[] | null;
  filterValue: string;
}

export interface RoleFilterType {
  label: string;
  backEndValue: RoleInfoType;
  value: string;
}

export interface IFetchPolicyDetailsResponse {
  parentPolicyData?: GetMergedPolicyResponse;
  policyData?: ReadPolicyResponse;
}

export type IListPaymentInfosRequest = ListPaymentInfosRequest;
export type IListPaymentInfosResponse = ListPaymentInfosResponse;

export interface CustomManageForOptionsType {
  label: string;
  value: string;
}

export interface CustomizedEmployeeRoleType {
  value: string;
  label: string;
  manageFor?: CustomManageForOptionsType;
  travelArrangerFor?: IUserBasicInfo[];
  description?: string;
}

export interface IReasonCode {
  code: string;
  id: string;
}

export type IOrganizationThirdPartyInfoSabreInfoVendorProgramInfo =
  OrganizationThirdPartyInfoSabreInfoVendorProgramInfo;
export type ISabreInfoRemark = OrganizationThirdPartyInfoSabreInfoRemark;
export type ISabreInfo = OrganizationThirdPartyInfoSabreInfo;
export type IOrganization = Organization;
export type IOrganizationBasic = OrganizationBasicInfo;
export type ILegalEntity = LegalEntity;
export type IOrganizationId = OrganizationId;
export type IUaPassPlus = UaPassPlus;
export type IVendorProgram = VendorProgram;
export type IOrganizationNode = OrganizationNode;
export type ILegalEntityId = LegalEntityId;
export type ILegalEntityNode = LegalEntityNode;
export type IDepartment = Department;
export type IGrade = Grade;
export type IOfficeId = OfficeId;
export type ICostCenter = CostCenter;
export type IPolicy = Policy;
export type IReadPolicyResponse = ReadPolicyResponse;
export type IReadEmployeeResponse = ReadEmployeeResponse;
export const IPolicyApprovalProcessType = PolicyApprovalInfoApprovalProcessType;
// export const ThirdPartyInfoUpdateTypeEnum = UpdateType;
export const ThirdPartyInfoWithAllocatedPCCUpdateTypeEnum = UpdateTypeWithAllocatedPCC;

export type IHotelRating = HotelRating;
export type IHotelCancellationPropsType = HotelCancellationPropsType;
export type IMaxHotelPriceByLocationPropsMaxPriceInLocation = {
  amount: string;
  currency: string;
  address: IPostalAddress;
};

export type IPolicyHotelMaxPriceCustomizationsKeys = 'address' | 'amount' | 'currency' | '';

export interface IHighestFlightCabinByDuration {
  props: { min: number; max: number; value: string }[];
  approvalProcessType?: PolicyApprovalInfoApprovalProcessType;
}

export interface IPolicyState {
  policyId: string;
  type: PolicyType;
  name: string;
  currency: string;
  category: PolicyCategory | undefined;
  /** @deprecated */
  inPolicyApprovalTypeForAir: PolicyApprovalInfoApprovalProcessType;
  /** @deprecated */
  outOfPolicyApprovalTypeForAir: PolicyApprovalInfoApprovalProcessType;
  inPolicyApprovalTypeForDomesticAir: PolicyApprovalInfoApprovalProcessType;
  inPolicyApprovalTypeForInternationalAir: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyApprovalTypeForDomesticAir: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyApprovalTypeForInternationalAir: PolicyApprovalInfoApprovalProcessType;
  inPolicyApprovalTypeForCar: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyApprovalTypeForCar: PolicyApprovalInfoApprovalProcessType;
  inPolicyApprovalTypeForHotel: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyApprovalTypeForHotel: PolicyApprovalInfoApprovalProcessType;
  inPolicyApprovalTypeForRail: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyApprovalTypeForRail: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyAgentApprovalTypeForAir: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyAgentApprovalTypeForHotel: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyAgentApprovalTypeForCar: PolicyApprovalInfoApprovalProcessType;
  outOfPolicyAgentApprovalTypeForRail: PolicyApprovalInfoApprovalProcessType;
  enableAgentActionOverride: boolean;
  policyApprover: UserOrgId[];
  policyApproverIsManager: boolean;
  policyApproverIsEmployeeLevel: boolean;
  policyDefaultApprover: UserOrgId[];
  shouldNotifyManager: boolean;
  // edit members section
  usersGroups: PolicyUserGroup[];
  // flight related state
  maxFlightBookingPriceByDuration: { min: number; max: number; value: string }[];
  highestFlightCabinByDurationDomestic: IHighestFlightCabinByDuration;
  highestFlightCabinByDurationInternational: IHighestFlightCabinByDuration;
  // Overnight flight related state
  highestFlightCabinOvernight: string;
  overNightFlightTimeStart: TimeLocal | undefined;
  overNightFlightTimeEnd: TimeLocal | undefined;
  overNightFlightTimeOverlapMins: number | undefined;
  overNightFlightIncludeLayover: boolean | undefined;
  /** @deprecated */
  flightCabinUpgrade: boolean | undefined;
  flightCabinUpgradeDomestic: FlightCabinUpgradeV2Props | undefined;
  flightCabinUpgradeInternational: FlightCabinUpgradeV2Props | undefined;
  flightBookingWindow: number | undefined;
  flightBookingWindowDomestic: number | undefined;
  flightBookingWindowInternational: number | undefined;
  co2EmissionPerPassengerPerKm: string;
  flightRefundableTickets: string;
  flightChangeableTickets: string;
  maxFlightBookingPriceDomestic?: MaxFlightBookingPriceProps;
  maxFlightBookingPriceInternational?: MaxFlightBookingPriceProps;
  flightCaptureReasonCode: boolean;
  flightReasonCodes: IReasonCode[];
  economyFaresDomesticDisallowed: boolean;
  economyFaresDomesticReason: string;
  economyFaresInternationalDisallowed: boolean;
  economyFaresInternationalReason: string;
  cabinClassNotAllowedDomestic: Cabin[];
  cabinClassNotAllowedDomesticReason: string;
  cabinClassNotAllowedInternational: Cabin[];
  cabinClassNotAllowedInternationalReason: string;
  // llf related state
  llfLayoverDurationInHoursDomestic?: number | undefined;
  llfLayoverDurationInHoursInternational?: number | undefined;
  llfFlightTimeWindowDomestic?: number | undefined;
  llfFlightTimeWindowInternational?: number | undefined;
  llfMaxLayoverDurationMinutesDomestic: number | undefined;
  llfMaxLayoverDurationMinutesInternational: number | undefined;
  llfFlightTimeWindowMinutesDomestic: number | undefined;
  llfFlightTimeWindowMinutesInternational: number | undefined;
  llfMaxNumberOfStops: LowestLogisticalFarePropsMaxNumberOfStops | undefined;
  llfAirportConnectionChange: LowestLogisticalFarePropsAirportConnectionChanges | undefined;
  llfCarrierType: LowestLogisticalFarePropsCarrierType | undefined;
  llfCarrierAirlines: string[];
  llfRejectMixCabinFlights: boolean;
  // hotel related state
  maxHotelPriceByLocationDefaultPriceAmount: string;
  maxHotelPriceTaxInclusion?: boolean;
  maxHotelPriceByLocationCustomizations: IMaxHotelPriceByLocationPropsMaxPriceInLocation[];
  hotelBookingWindow: string;
  hotelCancellationPolicy: string;
  hotelExperience: { min: number; max: number };
  hotelCaptureReasonCode: boolean;
  hotelReasonCodes: IReasonCode[];
  notAllowedHotelTypes: HotelRateConditionsNotAllowedPropsType[];
  notAllowedHotelReason: string;

  hotelMedianDistance?: number;
  hotelMedianDistanceUnit?: LengthUnit;
  hotelMedianMinRate?: number;
  hotelMedianMaxRate?: number;
  hotelMedianEnabled?: boolean;
  hotelRestrictByKeywords?: HotelRestrictedKeywordsPropsKeywords[];
  maxHotelBookingPriceProps?: MaxHotelBookingPriceProps;
  lowestFarePerHotelPropertyProps?: LowestFarePerHotelPropertyProps;
  // car related state
  maxCarPricePerNumberOfDays: string;
  allowedCarTypes: string[];
  carCaptureReasonCode: boolean;
  carReasonCodes: IReasonCode[];
  notAllowedCarTypes: CarType[];
  notAllowedCarTypeReason: string;
  notAllowedCarEngineTypes: EngineType[];
  // rail
  railReasonCodes: IReasonCode[];
  railCaptureReasonCode: boolean;
  railBookingWindow?: number;
  railMaxPrice: { min: number; max: number; value: Money | undefined }[];
  railHighestClass: { min: number; max: number; value: TravelClass | undefined }[];
  // addons
  allowedAirAddons: AllowedAirAddonsPropsAddon[];
  parentPolicyId?: string;
  isMembershipViaHrFeed: boolean;
  hrFeedPolicyDetails?: HrFeedPolicyDetails;
}

export interface IPolicyRangeItem<T = string> {
  min: number;
  max: number;
  value: T;
}

export interface IPolicyLinkingState {
  currency?: boolean;
  // flight related state
  maxFlightBookingPriceByDuration?: boolean;
  highestFlightCabinByDurationDomestic?: boolean;
  highestFlightCabinByDurationInternational?: boolean;
  highestFlightCabinOvernight?: boolean;
  flightCabinUpgrade?: boolean;
  flightCabinUpgradeDomestic?: boolean;
  flightCabinUpgradeInternational?: boolean;
  flightBookingWindow?: boolean;
  co2EmissionPerPassengerPerKm?: boolean;
  flightRefundableTickets?: boolean;
  flightChangeableTickets?: boolean;
  maxFlightBookingPriceDomestic?: boolean;
  maxFlightBookingPriceInternational?: boolean;
  flightReasonCodes?: boolean;
  economyFaresDomestic?: boolean;
  economyFaresInternational?: boolean;
  cabinClassNotAllowedDomestic?: boolean;
  cabinClassNotAllowedInternational?: boolean;
  llfConfigurations?: boolean;
  flightBookingWindowDomestic?: boolean;
  flightBookingWindowInternational?: boolean;
  allowedAirAddons?: boolean;
  // hotel related state
  maxHotelPriceByLocation?: boolean;
  hotelBookingWindow?: boolean;
  hotelCancellationPolicy?: boolean;
  hotelExperience?: boolean;
  hotelReasonCodes?: boolean;
  notAllowedHotelTypes?: boolean;
  hotelMedianRates?: boolean;
  hotelRestrictByKeywords?: boolean;
  lowestFarePerHotelProperty?: boolean;
  // car related state
  maxCarPricePerNumberOfDays?: boolean;
  allowedCarTypes?: boolean;
  carReasonCodes?: boolean;
  notAllowedCarTypes?: boolean;
  notAllowedCarEngineTypes?: boolean;
  // rail
  railMaxBookingPrice?: boolean;
  railTravelClass?: boolean;
  railReasonCodes?: boolean;
  railBookingWindow?: boolean;
}

export { PolicyType as PolicyTypeEnum };
export { ActionDisplayText };

export enum policyUserGroupConditionTypeKeysEnum {
  USER_ORG_IDS = 'userOrgIds',
  DEPARTMENTS = 'departments',
  LEGAL_ENTITY_IDS = 'legalEntityIds',
  COST_CENTERS = 'costCenters',
  GRADES = 'grades',
  OFFICE_IDS = 'officeIds',
  POSITION_TITLES = 'positionTitles',
  COUNTRY_CODES = 'countryCodes',
  WORKER_TYPES = 'workerTypes',
  ACCOUNTING_CODES = 'accountingCodes',
}

export type IAddPolicyUserGroupConditionReqTypes =
  | {
      key: string;
      type: 'userOrgIds';
      data: IUserOrgId[];
    }
  | {
      key: string;
      type: 'departments';
      data: string[];
    }
  | {
      key: string;
      type: 'legalEntityIds';
      data: LegalEntityId[];
    }
  | {
      key: string;
      type: 'costCenters';
      data: string[];
    }
  | {
      key: string;
      type: 'grades';
      data: string[];
    }
  | {
      key: string;
      type: 'officeIds';
      data: IOfficeId[];
    }
  | {
      key: string;
      type: 'positionTitles';
      data: string[];
    }
  | {
      key: string;
      type: 'countryCodes';
      data: string[];
    }
  | {
      key: string;
      type: 'workerTypes';
      data: number[];
    }
  | {
      key: string;
      type: 'accountingCodes';
      data: string[];
    }
  | {
      key: string;
      type: 'personas';
      data: number[];
    };

export type IPolicyConditionLocalData =
  | ISearchTravelerResponseTravelerSearchResult
  | IPersonaBasicInfo
  | IOffice
  | IDepartment
  | ILegalEntityNode
  | ICostCenter
  | IGrade
  | IDesignation
  | string;

export type IPolicyUserGroup = PolicyUserGroup;

export interface IPolicyUserGroupConditionItem {
  key: string;
  type: policyUserGroupConditionTypeKeysEnum | 'default';
  data: IAddPolicyUserGroupConditionReqTypes['data'];
}

export interface IPolicyMultipleUpdateUsers {
  addTravelers: ISearchTravelerResponseTravelerSearchResult[];
  dropTravelers: ISearchTravelerResponseTravelerSearchResult[];
}

export interface IPolicyMultipleUpdateUsersWithBypass {
  emails: string[];
  actions: string[];
}

export interface IApproverInfo {
  name?: ITravelerName;
  userOrgId?: IUserOrgId;
  profilePicture?: ImageV1;
  email?: string;
}

export { RoleInfoTypeEnum };
export type IAuthConfigType = AuthConfigAuthProviderAuthProviderType;
export type IEmployee = Employee;
export { FlightTicketsRefundablePropsType };
export { FlightTicketsChangeablePropsType };

export type IPolicyGeneralSection = Pick<
  IPolicyState,
  | 'name'
  | 'inPolicyApprovalTypeForAir'
  | 'inPolicyApprovalTypeForHotel'
  | 'inPolicyApprovalTypeForCar'
  | 'inPolicyApprovalTypeForRail'
  | 'inPolicyApprovalTypeForInternationalAir'
  | 'inPolicyApprovalTypeForDomesticAir'
  | 'outOfPolicyApprovalTypeForAir'
  | 'outOfPolicyApprovalTypeForHotel'
  | 'outOfPolicyApprovalTypeForCar'
  | 'outOfPolicyApprovalTypeForRail'
  | 'outOfPolicyApprovalTypeForDomesticAir'
  | 'outOfPolicyApprovalTypeForInternationalAir'
  | 'outOfPolicyAgentApprovalTypeForAir'
  | 'outOfPolicyAgentApprovalTypeForHotel'
  | 'outOfPolicyAgentApprovalTypeForCar'
  | 'outOfPolicyAgentApprovalTypeForRail'
  | 'policyApprover'
  | 'policyApproverIsManager'
  | 'policyApproverIsEmployeeLevel'
  | 'policyDefaultApprover'
  | 'shouldNotifyManager'
  | 'currency'
  | 'type'
  | 'policyId'
  | 'parentPolicyId'
  | 'enableAgentActionOverride'
>;

export type IPolicyCarCustomizations = Pick<
  IPolicyState,
  | 'policyId'
  | 'currency'
  | 'maxCarPricePerNumberOfDays'
  | 'allowedCarTypes'
  | 'carCaptureReasonCode'
  | 'carReasonCodes'
  | 'notAllowedCarTypes'
  | 'notAllowedCarTypeReason'
  | 'notAllowedCarEngineTypes'
>;

export type IPolicyHotelCustomizations = Pick<
  IPolicyState,
  | 'policyId'
  | 'type'
  | 'currency'
  | 'hotelBookingWindow'
  | 'hotelCancellationPolicy'
  | 'hotelExperience'
  | 'maxHotelPriceByLocationCustomizations'
  | 'maxHotelPriceByLocationDefaultPriceAmount'
  | 'maxHotelPriceTaxInclusion'
  | 'hotelCaptureReasonCode'
  | 'hotelReasonCodes'
  | 'notAllowedHotelTypes'
  | 'notAllowedHotelReason'
  | 'hotelMedianDistance'
  | 'hotelMedianDistanceUnit'
  | 'hotelMedianMaxRate'
  | 'hotelMedianMinRate'
  | 'hotelMedianEnabled'
  | 'hotelRestrictByKeywords'
  | 'maxHotelBookingPriceProps'
  | 'lowestFarePerHotelPropertyProps'
>;

export type IListPoliciesResponse = ListPoliciesResponse;

export interface IPolicySectionFieldConfig {
  key: string;
  title: string;
  disable: boolean;
  hasDivider: boolean;
  component: React.ReactNode;
  info?: React.ReactNode;
  customization?: React.ReactNode;
  height?: number;
  action?: React.ReactNode;
  isInGroup?: boolean;
  alignTitleStart?: boolean;
  containerStyle?: CSSObject;
  subtitle?: string;
  hidden?: boolean;
  readOnly?: boolean;
  required?: boolean;
  hideTitle?: boolean;
}

export interface IPolicySectionGroupConfig {
  name: string;
  subTitle?: string;
  info?: React.ReactNode;
  fields: IPolicySectionFieldConfig[];
  expandIcon?: React.ReactNode;
  collapseIcon?: React.ReactNode;
  readOnly?: boolean;
  shouldForceExpanded?: boolean;
  linker?: React.ReactNode;
  containerStyle?: CSSObject;
}

export interface IPaymentCard {
  index?: number;
  id: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  company: string;
  cardName: string;
  cardLabel?: string;
  currency?: string;
  cardAccessType?: CreditCardAccessType;
}

export interface IUaPassPlusDetails {
  businessName: string;
  uscId: string;
  tourCode: string;
  snapCode: string;
  number:
    | {
        errorMessages: [string];
        isValid: boolean;
      }
    | string;
  cardExpiryDate: string;
  startTicketingDate: string;
  endTicketingDate: string;
  startTravelingDate: string;
  endTravelingDate: string;
}

export interface IGenericVendorProgram {
  discountCode: string;
  programInfo: GenericVendorProgramInfo;
}

export interface IRemarkInfo {
  remarkCode: string;
  remarkType: RemarkInfoTypeEnum;
  airlineCodes: string[];
}

export { UpdateOrganizationRequestTypeEnum };

export interface IDeleteVendorProgramRequest {
  organizationId: OrganizationId;
  programId: string;
}

export interface IUserListItem {
  department: string;
  fullname: string;
  persona?: NewUserTypeEnum;
  office: string;
  userOrgId?: UserOrgId;
  userId?: string;
  email: string;
  title: string;
  role: string;
  tier: Tier;
}

export interface IUserList {
  users: IUserListItem[];
  numOfPages: number;
  numOfResults: number;
}

export type UserListFilters = Partial<Record<keyof IUserListItem, string[] | Tier[]>>;

export type ISelectWithLabelsDataItem = {
  selected?: boolean;
  label: string;
  value: string;
};

export interface IPccDataState {
  isDefault: boolean;
  name: string;
  dkNumber: string;
  iata: string;
  zoneId: string;
  awsKey: string | undefined;
  applicableTo: TravelType[];
  primaryQueue: string;
  secondaryQueue: string;
  currencyCode: string;
  tmcId: string;
  supportedCurrencies: ISelectWithLabelsDataItem[];
  supportedCountries: ISelectWithLabelsDataItem[];
  clid3: AirClid3Code[];
  clid4: AirClid4Code[];
  hotelRateCodes: HotelRateCode[];
  airTourCodes: AirTourCode[];
  airlines: string[];
  label: string;
  isIpcc: boolean | undefined;
  linkedIpcc: string | undefined;
}

export interface IPccDataErrors {
  name: string;
  dkNumber: string;
  iata: string;
  zoneId: string;
  awsKey: string;
  primaryQueue: string;
  secondaryQueue: string;
  currencyCode: string;
  applicableTo: string;
  clid3: string[];
  clid4: string[];
  hotelRateCodes: string[];
  airTourCodes: string[];
  airlines: string[];
  tmcId: string;
  label: string;
}

export interface IThirdPartyDetailErrors {
  l1Remarks?: string[];
  pnrRemarks?: string[];
  airTourCodes: string[];
  airCtid: string[];
  airClid3: string[];
  airClid4: string[];
  carCodes: string[];
  vpayCarIdNumbers: string[];
  hotelRateCodes: string[];
}

export interface ITmcThirdPartyDetailErrors {
  filterId: string;
  templateId: string;
  pnrRemarks: string[];
  l1Remarks: string[];
}

export type PCCTravelType = TravelType;
export type ICompanyId = CompanyId;

export interface ILegalEntityThirdPartyInfoErrors {
  airPnrRemarks: string[];
  profileRemarks: string[];
  allocatedPccs: string[];
}

export interface IValidateLegalEntityThirdPartyInfo {
  errors: ILegalEntityThirdPartyInfoErrors;
  isValid: boolean;
}

export enum CustomFieldRequirement {
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL',
}

export interface CustomFieldOption {
  name: string;
  description: string;
}

export interface CustomFieldSetupFormData {
  name: string;
  requirement: { label: string; value: CustomFieldRequirement };
  type: { label: string; value: CustomFieldQuestionType };
  options: Array<CustomFieldOption>;
}

export interface IICreateVirtualCardStateProduct {
  required: boolean;
  instruction: string;
}

export interface IPaymentSourceConflictItem {
  type: 'AIR' | 'HOTEL' | 'CAR' | 'RAIL';
  id: string;
  label: string;
  consent: boolean;
}

export interface ICreateVirtualCardErrors {
  product: string;
  clientId: string;
  cardType: string;
  label: string;
  productConfigInstruction: string;
  consentOverride: string;
  legalEntities: string;
  carVendors: string;
}
export interface ICreateVirtualCardState {
  product: 'HOTEL' | 'CAR' | undefined;
  clientId: string;
  cardType: ConfermaMetadataCompanyEnum | undefined;
  label: string;
  legalEntities: string[];
  productConfig: IICreateVirtualCardStateProduct;
  conflicts: IPaymentSourceConflictItem[];
  paymentSourceId?: string;
  roleTypes: RoleType[];
  includedPersonas: Persona[];
  carVendors: string[];
}

export {
  AirlinePrefFlightTypeEnum as AirlinePrefFlightTypeEnumV1,
  SeatLocationPrefPositionEnum as SeatLocationPrefPositionEnumV1,
  SeatAmenityPrefAirSeatAmenityTypeEnum as SeatAmenityPrefAirSeatAmenityTypeEnumV1,
  FarePrefFareTypeEnum as FarePrefFareTypeEnumV1,
} from './api/v1/obt/common/air_preference';

export interface FeatureFlagConfiguration {
  isPolicyApprovalV2Enabled?: boolean;
  hotelMedianRatesFeatureEnabled?: boolean;
  isFlightBookingWindowEnabled?: boolean;
  isSplitNonAirApprovalEnabled?: boolean;
  isReasonCodesEnabled?: boolean;
  isLlfIncrementFeatureEnabled?: boolean;
  isMixedCabinItinerariesEnabled?: boolean;
  isAncillaryUIEnabled?: boolean;
  isPolicyAirDomIntlSplitEnabled?: boolean;
  isDefaultApproverEnabled?: boolean;
  hotelMedianRatesFeatureEnabledV3?: boolean;
  isHideHotelMedianRateCustomizationForNonDefaultPolicyEnabled?: boolean;
  isPolicyCustomizeCabinByMileage?: boolean;
  splitPaymentInfoConfig?: SplitPaymentInfoConfig;
  isLowestFarePerHotelPropertyEnabled?: boolean;
  isPolicyDefineRuleLevelActionsAndExceptionsEnabled?: boolean;
  isAgentOverrideEnabled?: boolean;
}

export interface SplitPaymentInfoConfig {
  cabinShouldSplit: boolean;
  cabinPersonalFop: boolean;
  ancillaryShouldSplit: boolean;
  ancillaryPersonalFop: boolean;
}

export interface IUseGetApproverInfoResult {
  [key: string]: {
    isFetched: boolean;
    data: {
      name: string;
      email: string | undefined;
      id: UserId | undefined;
      profilePicture: ImageV1 | undefined;
      isActiveUser: boolean | undefined;
    };
  };
}

export type PreferenceTierOrRestricted = PreferenceTierLevel | 'RESTRICTED';
