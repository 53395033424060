import first from 'lodash/first';
import groupBy from 'lodash/groupBy';

import type { AxiosError } from 'axios';
import QueryString from 'qs';
import SpotnanaError from '../api/SpotnanaError';
import api from '../api/index';
import { autocompleteConfig, autocompleteTypes, AXIOS_CANCELLED_CALL } from '../constants/common';
import type { AutocompleteResponse, Airport, Location, AirportAirportGroupInfo } from '../types/api/v2/obt/model';
import type { GooglePlaceDetailsResponse } from '../types/api/v1/obt/autocomplete/response';
import type {
  IAirSuggestion,
  IAutoCompleteConfig,
  IAutocompleteSuggestion,
  IAutocompleteType,
  ICarSuggestion,
  IHotelSuggestion,
  ISuggestion,
  IRailSuggestion,
  IPlaceSuggestion,
  IAutocompleteSuggestionCustomizer,
} from '../types/autocomplete';
import { AirLocationTypeEnum } from '../types/autocomplete';

import type { IApiRequestTypes } from '../api/apiTypes';
import { joinTruthyValues } from '../utils';
import type { InternalAPIError } from '../types';
import type { RailThirdParty } from '../types/api/v2/obt/model/rail-third-party';
import type { AutocompleteRequest } from '../types/api/v1/obt/autocomplete/request';

export type AutocompleteServiceResultItemsLimitType = number | 'noLimit';

export default class AutocompleteService {
  readonly charLimit: IAutoCompleteConfig = autocompleteConfig;

  sessionToken?: string;

  constructor(
    readonly type: IAutocompleteType,
    readonly isOutsideBooking = false,
    readonly allowMultiAirportSelection = false,
    readonly organizationId?: string,
    readonly thirdParty?: RailThirdParty,
    readonly suggestionCustomizer?: IAutocompleteSuggestionCustomizer,
  ) {
    this.type = type;
    this.isOutsideBooking = isOutsideBooking;
    this.allowMultiAirportSelection = allowMultiAirportSelection;
    this.organizationId = organizationId;
    this.thirdParty = thirdParty;
    this.suggestionCustomizer = suggestionCustomizer;
  }

  /**
   * this service is specific to Search (home page) functionality and simply wan't to call the autcomplete endpoint,
   * this might not be a good fit. You should call the autocomplete endpoint as a normal endpoint like we do with other API endpoints.
   */
  public async fetchItems(
    query: string,
    numItems: AutocompleteServiceResultItemsLimitType,
    organizationLogo?: string,
    temporaryRequestArgs?: Partial<AutocompleteRequest>,
  ): Promise<IAutocompleteSuggestion[] | undefined> {
    const { suggestionCustomizer } = this;

    // fetch results
    const results = await this.fetchItemsInternal(query, numItems, organizationLogo, temporaryRequestArgs);

    // if a customizer is configured, invoke it to customize the suggestions
    if (results && suggestionCustomizer) {
      return suggestionCustomizer.applyCustomization(results);
    }

    return results;
  }

  private async fetchItemsInternal(
    query: string,
    numItems: AutocompleteServiceResultItemsLimitType,
    organizationLogo?: string,
    temporaryRequestArgs?: Partial<AutocompleteRequest>,
  ): Promise<IAutocompleteSuggestion[] | undefined> {
    // TODO: We should decide whether to enforce it in component or here; Seems redundant to have it in both places.

    if (query.length >= this.charLimit[this.type]) {
      switch (this.type) {
        case autocompleteTypes.AIR:
          return this.fetchAirItems(query);
        case autocompleteTypes.HOTEL:
          return this.fetchHotelItems(query, numItems, this.isOutsideBooking, organizationLogo, temporaryRequestArgs);
        case autocompleteTypes.CAR:
          return this.fetchCarItems(query, numItems);
        case autocompleteTypes.RAIL:
          return AutocompleteService.fetchRailItems(query, this.isOutsideBooking, this.thirdParty);
        case autocompleteTypes.POLICY_LOCATION:
          return this.fetchPolicyLocations(query, numItems);
        case autocompleteTypes.CAR_VENDOR:
          return this.fetchCarVendors(query, numItems);
        case autocompleteTypes.HOTEL_VENDOR:
          return this.fetchHotelVendors(query, numItems);
        case autocompleteTypes.PLACES:
          return AutocompleteService.fetchPlaceLocations(query);

        default:
          return Promise.resolve([]);
      }
    }
    return Promise.resolve([]);
  }

  public static async fetchGooglePlaces(
    location: ISuggestion | null,
  ): Promise<GooglePlaceDetailsResponse | undefined | null> {
    if (location?.googlePlaceId) {
      const { googlePlaceId, sessionToken } = location;
      try {
        const result = await api('GET', 'placeDetails', {
          params: { placeId: googlePlaceId, sessionToken },
        });
        return result as GooglePlaceDetailsResponse;
      } catch (err) {
        if ((err as AxiosError<InternalAPIError>)?.message !== AXIOS_CANCELLED_CALL) {
          return null;
        }
        return undefined;
      }
    }
    return undefined;
  }

  /**
   * @deprecated this service is specific to Search (home page) functionality and simply wan't to call the autcomplete endpoint,
   * this might not be a good fit. You should call the autocomplete endpoint as a normal endpoint like we do with other API endpoints.
   */
  private static async requestData(
    requestType: IApiRequestTypes,
    query: string,
    sessionToken?: string,
    travelerOrgId?: string,
    thirdParty?: RailThirdParty,
    temporaryRequestArgs?: Partial<AutocompleteRequest>,
  ): Promise<AutocompleteResponse | undefined> {
    try {
      const data = await api(
        'GET',
        requestType,
        {
          ...(temporaryRequestArgs
            ? {
                urlParam: `?${QueryString.stringify({
                  query,
                  sessionToken,
                  travelerOrgId,
                  thirdParty,
                  ...temporaryRequestArgs,
                })}`,
              }
            : {}),
          ...(!temporaryRequestArgs ? { params: { query, sessionToken, travelerOrgId, thirdParty } } : {}),
        },
        {
          allowParallelRequests: true,
        },
      );
      return data as AutocompleteResponse;
    } catch (err) {
      return undefined;
    }
  }

  private async fetchAirItems(query: string): Promise<IAirSuggestion[] | undefined> {
    const data = await AutocompleteService.requestData('airAutocomplete', query);
    return data ? this.getAirSuggestions(data.airports) : undefined;
  }

  private async fetchPolicyLocations(
    query: string,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): Promise<IHotelSuggestion[] | undefined> {
    const response = await AutocompleteService.requestData('policyAutocomplete', query, this.sessionToken);
    if (!response) {
      return undefined;
    }
    this.sessionToken = response?.googleSessionToken;
    const locationList = AutocompleteService.GetLocationsList(response, numItems);
    return [...locationList];
  }

  private async fetchHotelItems(
    query: string,
    numItems: AutocompleteServiceResultItemsLimitType,
    outsideBooking = false,
    organizationLogo?: string,
    temporaryRequestArgs?: Partial<AutocompleteRequest>,
  ): Promise<IHotelSuggestion[] | undefined> {
    const response = await AutocompleteService.requestData(
      outsideBooking ? 'manualFormHotelAutoComplete' : 'hotelAutocomplete',
      query,
      this.sessionToken,
      this.organizationId,
      undefined,
      temporaryRequestArgs,
    );

    if (!response) {
      return undefined;
    }

    this.sessionToken = response?.googleSessionToken;
    const officeList = AutocompleteService.GetOfficesList(response, numItems, organizationLogo);
    const locationList = AutocompleteService.GetLocationsList(response, numItems);
    const airportList = AutocompleteService.GetAirportsList(response, numItems);
    const poiList = AutocompleteService.GetPoiList(response, numItems);
    const hotelList = AutocompleteService.GetHotelsList(response, numItems);
    const railStationList = AutocompleteService.GetRailStationList(response, numItems);
    const restList = AutocompleteService.GetRestList(response, numItems);
    return [...officeList, ...locationList, ...airportList, ...poiList, ...hotelList, ...railStationList, ...restList];
  }

  private async fetchCarItems(
    query: string,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): Promise<ICarSuggestion[] | undefined> {
    const response = await AutocompleteService.requestData('carAutocomplete', query, this.sessionToken);

    if (!response) {
      return undefined;
    }
    this.sessionToken = response?.googleSessionToken;
    const airportList = AutocompleteService.GetAirportsList(response, numItems);
    const locationList = AutocompleteService.GetLocationsList(response, numItems);
    const poiList = AutocompleteService.GetPoiList(response, numItems);
    const restList = AutocompleteService.GetRestList(response, numItems);
    return [...airportList, ...locationList, ...poiList, ...restList];
  }

  private async fetchCarVendors(
    query: string,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): Promise<ISuggestion[] | undefined> {
    const response = await AutocompleteService.requestData('carVendorAutocomplete', query, this.sessionToken);

    if (!response) {
      return undefined;
    }
    const vendorsList = AutocompleteService.GetCarVendorsList(response, numItems);
    return [...vendorsList];
  }

  private async fetchHotelVendors(
    query: string,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): Promise<ISuggestion[] | undefined> {
    const response = await AutocompleteService.requestData('hotelSupplierAutocomplete', query, this.sessionToken);

    if (!response) {
      return undefined;
    }
    return AutocompleteService.GetHotelVendorsList(response, numItems);
  }

  static async fetchRailItems(
    query: string,
    outsideBooking = false,
    thirdParty?: RailThirdParty,
  ): Promise<IRailSuggestion[] | undefined> {
    const response = await AutocompleteService.requestData(
      outsideBooking ? 'manualFormRailAutoComplete' : 'railAutocomplete',
      query,
      undefined,
      undefined,
      thirdParty,
    );
    if (!response) return undefined;

    const railStations = this.GetRailStationList(response, response.railStations.length);
    const others = this.GetRestList(response, response.others.length);

    return [...railStations, ...others];
  }

  static async fetchPlaceLocations(query: string): Promise<IPlaceSuggestion[] | undefined> {
    const response = await AutocompleteService.requestData('placeAutocomplete', query);
    if (!response) return undefined;
    const locationList = AutocompleteService.GetLocationsList(response, response.locations.length);
    const poiList = AutocompleteService.GetPoiList(response, response.poi.length);
    const restList = AutocompleteService.GetRestList(response, response.others.length);
    return [...locationList, ...poiList, ...restList];
  }

  public static getAirportNode(
    airport: Airport,
    nodeType: AirLocationTypeEnum.AIRPORT | AirLocationTypeEnum.CITY_AIRPORT,
    groupAirports: Airport[],
  ): IAirSuggestion {
    return {
      type: nodeType,
      code: airport.airportCode ?? '',
      name: airport.airportName ?? airport.location?.name ?? '',
      place: joinTruthyValues(
        {
          city: airport.location?.name,
          state: airport.location?.stateName,
          country: airport.location?.countryName.replace(/united states of america/i, 'USA'),
        },
        ', ',
      ),
      countryCode: airport.location?.countryCode ?? '',
      data: [airport],
      groupAirports,
    };
  }

  static getAirCityNode(
    groupInfo: AirportAirportGroupInfo,
    airports: Airport[],
    nodeType: AirLocationTypeEnum.CITY | AirLocationTypeEnum.CITY_GROUP,
  ): IAirSuggestion {
    let code = '';
    if (groupInfo.groupCityCode && groupInfo.groupCityCode !== '') {
      code = groupInfo.groupCityCode;
    } else if (groupInfo.groupId && groupInfo.groupId !== '') {
      code = groupInfo.groupId;
    }
    code += '_GROUP';

    return {
      type: nodeType,
      code,
      name: joinTruthyValues(
        {
          city: groupInfo.mainAirportLocation?.name,
          country: groupInfo.mainAirportLocation?.countryName.replace(/united states of america/i, 'USA'),
        },
        ', ',
      ),
      place: joinTruthyValues(
        {
          city: groupInfo.mainAirportLocation?.name,
          state: groupInfo.mainAirportLocation?.stateName,
          country: groupInfo.mainAirportLocation?.countryName.replace(/united states of america/i, 'USA'),
        },
        ', ',
      ),
      countryCode: groupInfo.mainAirportLocation?.countryCode ?? '',
      data: airports,
    };
  }

  static getGroupedAirSuggestions(airports: Airport[]): IAirSuggestion[] {
    const groupedAirSuggestions = groupBy(airports, 'groupInfo.groupId');

    const allGroupValues = Object.values(groupedAirSuggestions);

    const airSuggestions = allGroupValues.flatMap((groupedValues) => {
      const { groupInfo } = groupedValues[0];

      const airportNodeType = groupInfo ? AirLocationTypeEnum.CITY_AIRPORT : AirLocationTypeEnum.AIRPORT;
      const airportNodes = groupedValues.map((item) => {
        const airportNode = AutocompleteService.getAirportNode(item, airportNodeType, groupedValues);
        return airportNode;
      });

      let cityNode: IAirSuggestion | null = null;
      if (groupInfo) {
        const cityNodeType = groupInfo.groupCityCode ? AirLocationTypeEnum.CITY : AirLocationTypeEnum.CITY_GROUP;
        cityNode = AutocompleteService.getAirCityNode(groupInfo, groupedValues, cityNodeType);
      }

      return [cityNode, ...airportNodes].filter((value): value is IAirSuggestion => !!value);
    });

    return airSuggestions;
  }

  private getAirSuggestions(airports: Airport[]): IAirSuggestion[] {
    if (this.allowMultiAirportSelection) {
      return AutocompleteService.getGroupedAirSuggestions(airports);
    }

    // Group airports by cityCode
    const groupedAirSuggestions = groupBy(airports, 'cityCode');
    return (
      Object.values(groupedAirSuggestions)
        .reduce((acc: (IAirSuggestion | IAirSuggestion[])[], group) => {
          const firstGroup = first(group);
          return [
            ...acc,
            group.length > 1
              ? // create an array of CITY and its corresponding CITY_AIRPORT since there are multiple airports in a city
                [
                  {
                    type: AirLocationTypeEnum.CITY,
                    code: firstGroup?.cityCode ?? '',
                    name: joinTruthyValues(
                      {
                        city: firstGroup?.location?.name,
                        country: firstGroup?.location?.countryName.replace(/united states of america/i, 'USA'),
                      },
                      ', ',
                    ),
                    place: joinTruthyValues(
                      {
                        city: firstGroup?.location?.name,
                        state: firstGroup?.location?.stateName,
                        country: firstGroup?.location?.countryName.replace(/united states of america/i, 'USA'),
                      },
                      ', ',
                    ),
                    countryCode: firstGroup?.location?.countryCode ?? '',
                  },
                  ...group.map((item) => ({
                    type: AirLocationTypeEnum.CITY_AIRPORT,
                    code: item?.airportCode ?? '',
                    name: item?.airportName ?? '',
                    place: item?.location?.stateName,
                    countryCode: item?.location?.countryCode ?? '',
                  })),
                ]
              : // create an array with a single AIRPORT because the city contains only 1 airport
                [
                  {
                    type: AirLocationTypeEnum.AIRPORT,
                    code: firstGroup?.airportCode ?? '',
                    name: firstGroup?.airportName ?? firstGroup?.location?.name ?? '',
                    place: joinTruthyValues(
                      {
                        city: firstGroup?.location?.name,
                        state: firstGroup?.location?.stateName,
                        country: firstGroup?.location?.countryName.replace(/united states of america/i, 'USA'),
                      },
                      ', ',
                    ),
                    countryCode: firstGroup?.location?.countryCode ?? '',
                  },
                ],
          ];
        }, [])
        // flatten the entire list as renderOptions would need a flat array
        .flat()
    );
  }

  private static GetNonOptionalSuggestionFields = (
    location: Location | undefined,
    type: string,
    sessionToken: string,
  ): ISuggestion => ({
    type,
    sessionToken,
    name: location?.name ?? '',
    location: '',
    state: location?.stateName ?? '',
    country: location?.countryName ?? '',
    googlePlaceId: location?.googlePlaceId ?? '',
    continentCode: location?.continentCode ?? '',
  });

  private static TruncateAutocompleteResultsList = <T>(
    list: T[],
    limit: AutocompleteServiceResultItemsLimitType,
  ): T[] => {
    if (limit === 'noLimit') {
      return list;
    }

    if (limit < 0) {
      throw new SpotnanaError(`"limit" should be either "noLimit", 0 or a positive number. Got: ${limit}.`);
    }

    return list.slice(0, limit);
  };

  private static GetAirportsList = (
    { airports, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): ISuggestion[] => {
    const airportsToUse = this.TruncateAutocompleteResultsList(airports, numItems);

    return airportsToUse.map((airport) => ({
      ...AutocompleteService.GetNonOptionalSuggestionFields(airport.location, 'AIRPORT', googleSessionToken),
      name: airport.airportName ?? '',
      data: airport.airportCode,
      coordinates: airport.location?.latlong,
      location: airport.location?.name ?? '',
      countryCode: airport.location?.countryCode ?? '',
      cityCode: airport.cityCode ?? '',
      city: airport.location?.name ?? '',
    }));
  };

  private static GetOfficesList = (
    { offices, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
    organizationLogo?: string,
  ): ISuggestion[] => {
    const officesToUse = this.TruncateAutocompleteResultsList(
      (offices ?? []).filter((office) => !!office.latlng),
      numItems,
    );

    return officesToUse.map((office) => ({
      name: office.name,
      type: 'OFFICE',
      sessionToken: googleSessionToken,
      coordinates: office?.latlng ?? { latitude: 0, longitude: 0 },
      location: '',
      state: '',
      country: '',
      googlePlaceId: '',
      imageUrl: organizationLogo,
      countryCode: office?.address?.regionCode,
      address: office?.address,
    }));
  };

  private static GetLocationsList = (
    { locations, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): ISuggestion[] => {
    const locationsToUse = this.TruncateAutocompleteResultsList(locations, numItems);

    return locationsToUse.map((location) => ({
      ...AutocompleteService.GetNonOptionalSuggestionFields(location, 'CITY', googleSessionToken),
      coordinates: location?.latlong ?? { latitude: 0, longitude: 0 },
    }));
  };

  private static GetCarVendorsList = (
    { carVendors, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): ISuggestion[] => {
    const carVendorsToUse = this.TruncateAutocompleteResultsList(carVendors ?? [], numItems);

    return carVendorsToUse.map((carVendor) => ({
      ...AutocompleteService.GetNonOptionalSuggestionFields(undefined, 'CAR_VENDOR', googleSessionToken),
      name: carVendor.name ?? '',
      data: carVendor.code ?? '',
    }));
  };

  private static GetHotelsList = (
    { hotels, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): ISuggestion[] => {
    const hotelsToUse = this.TruncateAutocompleteResultsList(hotels, numItems);

    return hotelsToUse.map((hotel) => ({
      ...AutocompleteService.GetNonOptionalSuggestionFields(hotel.location, 'HOTEL', googleSessionToken),
      name: hotel.hotelName ?? '',
      data: hotel.hotelCode ?? '',
      location: hotel?.location?.name ?? '',
      coordinates: hotel.location?.latlong,
      address: hotel.address,
      countryCode: hotel.location?.countryCode,
      brandCode: hotel.brandCode ?? '',
      chainCode: hotel.chainCode ?? '',
      starRating: hotel.starRating,
      contactInfo: hotel.contactInfo,
    }));
  };

  private static GetHotelVendorsList = (
    { hotels, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): ISuggestion[] => {
    const hotelsToUse = this.TruncateAutocompleteResultsList(hotels, numItems);

    return hotelsToUse.map((hotel) => ({
      ...AutocompleteService.GetNonOptionalSuggestionFields(hotel.location, 'HOTEL', googleSessionToken),
      name: hotel.hotelName,
      data: hotel.hotelCode,
      location: hotel?.location?.name ?? '',
      coordinates: hotel.location?.latlong,
      countryCode: hotel.location?.countryCode ?? '',
    }));
  };

  private static GetPoiList = (
    { poi, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): ISuggestion[] => {
    const poiToUse = this.TruncateAutocompleteResultsList(poi, numItems);

    return poiToUse.map((item) => ({
      ...AutocompleteService.GetNonOptionalSuggestionFields(item, 'POI', googleSessionToken),
    }));
  };

  private static GetRestList = (
    { others, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): ISuggestion[] => {
    const otherItemsToUse = this.TruncateAutocompleteResultsList(others, numItems);

    return otherItemsToUse.map((item) => ({
      ...AutocompleteService.GetNonOptionalSuggestionFields(item, 'OTHER', googleSessionToken),
    }));
  };

  private static GetRailStationList = (
    { railStations, googleSessionToken }: AutocompleteResponse,
    numItems: AutocompleteServiceResultItemsLimitType,
  ): IRailSuggestion[] => {
    const railStationsToUse = this.TruncateAutocompleteResultsList(railStations, numItems);

    return railStationsToUse.map((railStation) => ({
      ...AutocompleteService.GetNonOptionalSuggestionFields(railStation.location, 'RAIL STATION', googleSessionToken),
      name: railStation.railStationName ?? '',
      data: railStation.railStationCode,
      coordinates: railStation.location?.latlong,
      stationType: railStation.stationType,
      location: railStation.location?.name ?? '',
      stationReferenceId: first(railStation.sourceReferenceInfos)?.stationReferenceId,
      timeZone: railStation.timeZone,
      cityName: railStation.cityName ?? '',
      stateCode: railStation.stateCode,
      countryCode: railStation.countryCode,
      continentCode: railStation.continentCode,
      thirdParty: railStation.thirdParty,
      inventoryNames: railStation.sourceReferenceInfos?.map((sourceReferenceInfo) => sourceReferenceInfo.inventoryName),
    }));
  };
}
