import { endPoints, bookingFailedErrorCode, fareExpiredErrorCode } from 'obt-common';
import { ROUTE_PATHS } from 'src/routes';

const endPointsWithGlobalErrorSnackbarDisabled = [
  { endpoint: endPoints.zendeskChatToken, disabledForErrors: ['USER_NOT_SYNCED_IN_CWT'], canMatchPartOfEndpoint: true },
  { endpoint: endPoints.getAuthConfig, disabledForErrors: ['*'], canMatchPartOfEndpoint: false },
  { endpoint: endPoints.createShellPnr, disabledForErrors: ['*'], canMatchPartOfEndpoint: false },
  {
    endpoint: endPoints.airRevalidateItenary,
    disabledForErrors: [fareExpiredErrorCode, bookingFailedErrorCode],
    canMatchPartOfEndpoint: false,
  },
  {
    endpoint: endPoints.airAncillaries,
    disabledForErrors: [fareExpiredErrorCode],
    canMatchPartOfEndpoint: false,
  },
  {
    endpoint: endPoints.getAirSeatMaps,
    disabledForErrors: [fareExpiredErrorCode],
    canMatchPartOfEndpoint: false,
  },
  {
    endpoint: endPoints.sharedCompany,
    disabledForErrors: ['AGENCY_SUPPLIER_CONFIG_NOT_FOUND'],
    canMatchPartOfEndpoint: true,
  },
  {
    endpoint: endPoints.hardApproval,
    disabledForErrors: ['APPROVAL_INFO_ALREADY_PROCESSED', 'VOID_WINDOW_EXPIRED'],
    canMatchPartOfEndpoint: false,
  },
  {
    endpoint: endPoints.airCancelPnr,
    disabledForErrors: ['GATEWAY_TIMEOUT'],
    canMatchPartOfEndpoint: false,
  },
  {
    endpoint: endPoints.enrollAirlineLoyalty,
    disabledForErrors: [
      'AA_LOYALTY_CUSTOMER_ALREADY_EXISTS',
      'AA_LOYALTY_UNKNOWN_ERROR',
      'AMERICAN_AIRLINES_REST_CALL_FAILURE',
      'UNKNOWN_AMERICAN_AIRLINE_ERROR',
    ],
    canMatchPartOfEndpoint: false,
  },
  {
    endpoint: 'v2/mid-office/companies',
    disabledForErrors: ['CONFIG_NOT_FOUND'],
    canMatchPartOfEndpoint: true,
    disabledForRoles: ['*'],
  },
  {
    endpoint: 'v3/supplier/tmcs',
    disabledForErrors: ['CONFIG_NOT_FOUND'],
    canMatchPartOfEndpoint: true,
    disabledForRoles: ['*'],
  },
  {
    endpoint: 'v3/content-builder/companies',
    disabledForErrors: ['CONFIG_NOT_FOUND'],
    canMatchPartOfEndpoint: true,
    disabledForRoles: ['*'],
  },
  {
    endpoint: endPoints.thoughtspotAuthToken,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  {
    endpoint: endPoints.thoughtspotAuthTokenV2,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  {
    // Disabling since site messages are fetched on every route change and
    // the errors are not relevant to user's actions on the site. Will be
    // monitored and addressed separately.
    endpoint: endPoints.siteMessages,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  // Disabling global error snackbar for Hotel checkout APIs
  // since they are being handled by a dedicated error modal
  {
    endpoint: endPoints.hotelPriceCheck,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  {
    endpoint: endPoints.hotelCreatePnr,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  /**
   * This disables outward rail search errors on the air search page. Inward is not necessary
   * because if outward fails inward won't fire
   */
  {
    endpoint: endPoints.railOutwardSearch,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledRoutes: ['/flights/results', ROUTE_PATHS.RAILS_RESULTS],
    disabledForRoles: ['*'],
  },
  {
    endpoint: endPoints.railInwardSearch,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  {
    endpoint: endPoints.railBookItinerary,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  {
    endpoint: endPoints.railReserveItinerary,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  {
    endpoint: endPoints.railCreatePnr,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledForRoles: ['*'],
  },
  /**
   * This disables error snackbar for failed look ups on the profile redirect page
   * since we display a separate message to the user.
   */
  {
    endpoint: endPoints.readTraveler,
    disabledForErrors: ['*'],
    canMatchPartOfEndpoint: false,
    disabledRoutes: [ROUTE_PATHS.PROFILE_REDIRECT],
    disabledForRoles: ['*'],
  },
  {
    endpoint: endPoints.agentDesktopAgents,
    disabledForErrors: ['AGENT_NOT_FOUND'],
    canMatchPartOfEndpoint: true,
    disabledRoutes: [ROUTE_PATHS.SPOTTERNET_ALL],
    disabledForRoles: ['*'],
  },
  {
    endpoint: '/v2/analytics/reports',
    disabledForErrors: ['REPORT_NOT_FOUND'],
    canMatchPartOfEndpoint: true,
    disabledRoutes: ['/reports'],
    disabledForRoles: ['*'],
  },
  {
    endpoint: '/v3/email/companies',
    disabledForErrors: ['CONFIG_NOT_FOUND'],
    canMatchPartOfEndpoint: true,
    disabledForRoles: ['*'],
  },
  {
    endpoint: '/v3/email/users',
    disabledForErrors: ['CONFIG_NOT_FOUND'],
    canMatchPartOfEndpoint: true,
    disabledForRoles: ['*'],
  },
];

export default endPointsWithGlobalErrorSnackbarDisabled;
