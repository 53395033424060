/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Identifies the different ways the card can be used across an organization
 * @export
 * @enum {string}
 */

export const CreditCardAccessType = {
  UnknownType: 'UNKNOWN_TYPE',
  Centralised: 'CENTRALISED',
  Individual: 'INDIVIDUAL',
  Personal: 'PERSONAL',
  Tmc: 'TMC',
  Application: 'APPLICATION',
  Itinerary: 'ITINERARY',
  Events: 'EVENTS',
  TravelArrangerManaged: 'TRAVEL_ARRANGER_MANAGED',
  CompanyTravelArrangerManaged: 'COMPANY_TRAVEL_ARRANGER_MANAGED',
} as const;

export type CreditCardAccessType = (typeof CreditCardAccessType)[keyof typeof CreditCardAccessType];
