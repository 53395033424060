/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { TravelCard } from './travel-card';

/**
 * Ancilliaries for rail.
 * @export
 * @interface RailAncillary
 */
export interface RailAncillary {
  /**
   *
   * @type {TravelCard}
   * @memberof RailAncillary
   */
  travelCard?: TravelCard;
  /**
   * Type of the ancillary
   * @type {string}
   * @memberof RailAncillary
   */
  type: RailAncillaryTypeEnum;
}

export const RailAncillaryTypeEnum = {
  UnknownType: 'UNKNOWN_TYPE',
  TravelCard: 'TRAVEL_CARD',
} as const;

export type RailAncillaryTypeEnum = (typeof RailAncillaryTypeEnum)[keyof typeof RailAncillaryTypeEnum];
