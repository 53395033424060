/* eslint-disable */
import { AirCancellationDetails } from '../types';
import { IRefundModeDetails, RefundData, RefundModeEnum } from '../types/Flights/pnrCancellation';
import { AirCancellationNotSupportedReason } from '../types/api/v2/obt/model/air-cancellation-not-supported-reason';
import { AirCancellationState } from '../types/api/v2/obt/model/air-cancellation-state';
import { AirFareInfo } from '../types/api/v2/obt/model/air-fare-info';
import { PnrCancellationDetails } from '../types/api/v2/obt/model/pnr-cancellation-details';
import { MoneyUtil } from '../utils/Money';

export default class AirPnrCancellationResponseManager {
  airCancellationDetails: AirCancellationDetails | undefined;

  constructor(readonly response: PnrCancellationDetails) {
    if (!response) {
      throw new Error('Empty data passed to AirPnrCancellationResponseManager');
    }

    if (!response.cancellationDetails || !('air' in response.cancellationDetails)) {
      throw new Error('Invalid data passed to AirPnrCancellationResponseManager');
    }

    this.airCancellationDetails = response.cancellationDetails.air;
  }

  public getCancellationState(): AirCancellationState | undefined {
    return this.airCancellationDetails?.cancellationState;
  }

  public getCancellationNotSupportedPriorityReasons(): AirCancellationNotSupportedReason[] {
    return this.airCancellationDetails?.cancellationNotSupportedReasons ?? [];
  }

  public getVoidPeriod(): string | undefined {
    const cancellationOptions = this.airCancellationDetails?.cancellationOptions;

    if (!cancellationOptions || !cancellationOptions.length) {
      return '';
    }
    const isCancellableFlight =
      this.airCancellationDetails?.cancellationState === AirCancellationState.CancellableByObt;

    return isCancellableFlight ? cancellationOptions[0].offerExpiryInfo?.dateTime?.iso8601 : undefined;
  }

  /**
   * @returns true if all tickets in this PNR are nonRefundableAndNonExchangeable, false if any ticket exists that is otherwise.
   */
  public getIsNonRefundableNonExchangeable(): boolean {
    return Boolean(
      this.airCancellationDetails?.travelers &&
        this.airCancellationDetails?.travelers.every(
          (traveler) =>
            traveler.tickets && traveler.tickets.every((ticket) => ticket.isNonRefundableAndNonExchangeable),
        ),
    );
  }

  public getSelectedFareInfoIndex(isCancelNonRefundableNonExchangeable: boolean, refundMode?: RefundModeEnum): number {
    const cancellationOptions = this.airCancellationDetails?.cancellationOptions;
    if (!refundMode || !cancellationOptions) {
      return 0;
    }
    const fareInfoRefundIndex = cancellationOptions.findIndex((cancellationOption) => {
      const { refund, flightCredits } = cancellationOption.fareInfo;
      const hasRefund = !!refund;
      const hasNoCredits = !flightCredits;

      const isRefundValid = isCancelNonRefundableNonExchangeable || hasRefund;

      return hasNoCredits && isRefundValid;
    });

    const flightCreditsOrMixedIndex = cancellationOptions.findIndex(
      (cancellationOption) => !!cancellationOption.fareInfo.flightCredits,
    );

    if (refundMode === RefundModeEnum.FLIGHT_CREDITS || refundMode === RefundModeEnum.MIXED_PAYMENT_MODE) {
      return flightCreditsOrMixedIndex;
    }

    return fareInfoRefundIndex;
  }

  public getFareBreakup(): AirFareInfo[] {
    const cancellationOptions = this.airCancellationDetails?.cancellationOptions;

    if (!cancellationOptions || !cancellationOptions.length) {
      return [];
    }

    return cancellationOptions.map((cancellationOption) => cancellationOption?.fareInfo);
  }

  public getTotalRefund(): RefundData {
    let totalRefund = { refundAmount: MoneyUtil.zeroMoney(), creditAmount: MoneyUtil.zeroMoney() };

    const cancellationOptions = this.airCancellationDetails?.cancellationOptions;

    if (!cancellationOptions || !cancellationOptions.length) {
      return totalRefund;
    }

    const fareInfoRefund = cancellationOptions.find(
      (cancellationOption) =>
        !!cancellationOption.fareInfo.refund &&
        !MoneyUtil.convertV2MoneyToMoneyUtil(cancellationOption.fareInfo.refund.base)
          .add(MoneyUtil.convertV2MoneyToMoneyUtil(cancellationOption.fareInfo.refund.tax))
          .isZero(),
    )?.fareInfo?.refund;

    const flightCredits = cancellationOptions.find((cancellationOption) => !!cancellationOption.fareInfo.flightCredits)
      ?.fareInfo?.flightCredits;
    totalRefund = {
      refundAmount: fareInfoRefund
        ? MoneyUtil.convertV2MoneyToMoneyUtil(fareInfoRefund.base).add(
            MoneyUtil.convertV2MoneyToMoneyUtil(fareInfoRefund.tax),
          )
        : MoneyUtil.zeroMoney(),
      creditAmount: flightCredits
        ? MoneyUtil.convertV2MoneyToMoneyUtil(flightCredits.totalCreditAmount.base).add(
            MoneyUtil.convertV2MoneyToMoneyUtil(flightCredits.totalCreditAmount.tax),
          )
        : MoneyUtil.zeroMoney(),
    };

    return totalRefund;
  }

  public getCancellationNotSupportedReasons(): AirCancellationNotSupportedReason[] {
    const travelers = this.airCancellationDetails?.travelers;

    const cancellationNotSupportedReasons = new Set<AirCancellationNotSupportedReason>();

    travelers?.forEach((traveler) => {
      traveler.tickets?.forEach((ticket) => {
        ticket?.cancellationNotSupportedReasons?.forEach((reason) => {
          cancellationNotSupportedReasons.add(reason);
        });
      });
    });

    return Array.from(cancellationNotSupportedReasons);
  }

  public getTotalDeductions(fareInfoIndex: number): MoneyUtil {
    const cancellationOptions = this.airCancellationDetails?.cancellationOptions;

    if (!cancellationOptions || !cancellationOptions.length) {
      return MoneyUtil.zeroMoney();
    }

    const fareInfo = cancellationOptions[fareInfoIndex].fareInfo;

    const totalUsedFare = MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.usedFare.base).add(
      MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.usedFare.tax),
    );

    const totalNonRefundableComponents = MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.nonRefundableFare.base).add(
      MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.nonRefundableFare.tax),
    );

    const merchantFees = MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.merchantFee);

    const cancellationFees = MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.cancellationFee.base).add(
      MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.cancellationFee.tax),
    );

    const totalDeduction = totalUsedFare.add(totalNonRefundableComponents).add(merchantFees).add(cancellationFees);

    return totalDeduction;
  }

  public getApplicableRefundModes(): IRefundModeDetails[] {
    const cancellationOptions = this.airCancellationDetails?.cancellationOptions;

    const applicableRefundModes: IRefundModeDetails[] = [];

    cancellationOptions?.forEach((cancellationOption) => {
      const refundBase = MoneyUtil.convertV2MoneyToMoneyUtil(cancellationOption?.fareInfo?.refund?.base);
      const refundTax = MoneyUtil.convertV2MoneyToMoneyUtil(cancellationOption?.fareInfo?.refund?.tax);
      const flightCredit = cancellationOption?.fareInfo?.flightCredits;

      if (flightCredit) {
        const creditBase = MoneyUtil.convertV2MoneyToMoneyUtil(flightCredit.totalCreditAmount.base);
        const creditTax = MoneyUtil.convertV2MoneyToMoneyUtil(flightCredit.totalCreditAmount.tax);

        applicableRefundModes.push({
          refundMode:
            refundBase.isZero() && refundTax.isZero()
              ? RefundModeEnum.FLIGHT_CREDITS
              : RefundModeEnum.MIXED_PAYMENT_MODE,
          refundAmount: refundBase.add(refundTax),
          creditAmount: creditBase.add(creditTax),
          changeFee: MoneyUtil.convertV2MoneyToMoneyUtil(flightCredit.changePenalty?.base).add(
            MoneyUtil.convertV2MoneyToMoneyUtil(flightCredit.changePenalty?.tax),
          ),
          optionId: cancellationOption?.optionId,
        });
      } else {
        applicableRefundModes.push({
          refundMode: RefundModeEnum.ORIGINAL_PAYMENT_MODE,
          refundAmount: MoneyUtil.convertV2MoneyToMoneyUtil(cancellationOption?.fareInfo?.refund?.base).add(
            MoneyUtil.convertV2MoneyToMoneyUtil(cancellationOption?.fareInfo?.refund?.tax),
          ),
          creditAmount: MoneyUtil.zeroMoney(),
          changeFee: MoneyUtil.zeroMoney(),
          optionId: cancellationOption?.optionId,
        });
      }
    });
    return applicableRefundModes;
  }
}
