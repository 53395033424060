import type { MoneyUtil } from '../Money';
import { airTranslationKeys } from '../../constants/flights';
import type { IFareComponent, IPaxFareBreakup, FareTypeEnum, IPaxSegmentSeatNumber } from '../../types/flight';
import type AirSeatMapResponseManager from '../../services/AirSeatMapResponseManager';
import type { PaymentMethod } from '../../types/api/v1/common/money';
import type { RateOptionTicketType } from '../../types/api/v1/obt/air/air_search_response';
import { defineMessage } from '../../translations/defineMessage';
import type { IServiceFeeData } from '../../types';

interface ILocalisedCurrencyValue {
  currencyCode: string;
  amount: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
}

export interface ILocalisedCurrency {
  value: ILocalisedCurrencyValue;
}
export interface IFareBreakupData {
  label: string;
  id: string;
  dataTestId: string;
  value: ILocalisedCurrency;
}

export const localisedOriginalCurrencyValue = (amount: MoneyUtil, positiveOnly?: boolean): ILocalisedCurrency => {
  const currencyInfo = amount.getOriginalCurrencyInfo();
  const originalAmount = Number(amount.getDisplayOriginalAmount());
  return {
    value: {
      currencyCode: currencyInfo.code,
      amount: positiveOnly ? Math.abs(originalAmount) : originalAmount,
      minFractionDigits: currencyInfo.decimalDigits,
    },
  };
};

export const localisedCurrencyValue = (amount: MoneyUtil, positiveOnly?: boolean): ILocalisedCurrency => {
  const currencyInfo = amount.getCurrencyInfo();
  const originalAmount = Number(amount.getDisplayAmount());
  return {
    value: {
      currencyCode: currencyInfo.code,
      amount: positiveOnly ? Math.abs(originalAmount) : originalAmount,
      minFractionDigits: currencyInfo.decimalDigits,
    },
  };
};
const formatToCurrency = ({ value: { currencyCode, amount, minFractionDigits } }: ILocalisedCurrency) => ({
  currency: currencyCode,
  value: amount,
  minFractionDigits,
});

export const localisedOriginalCurrency = (amount: MoneyUtil) =>
  formatToCurrency(localisedOriginalCurrencyValue(amount));

export const localisedCurrency = (amount: MoneyUtil) => formatToCurrency(localisedCurrencyValue(amount));

type FareComponentKey = keyof IFareComponent;

export enum FareComponentKeyEnum {
  BASE_FARE = 'baseFare',
  FLIGHT_CREDITS = 'flightCreditPricePerPax',
  ELIGIBLE_REFUND = 'eligibleRefund',
  FEES_AND_TAXES = 'feesAndTaxes',
  SEAT = 'seat',
  BAGGAGE = 'baggage',
  PAYMENT_FEES = 'paymentFees',
  UA_PASS_PLUS_BASE_FARE = 'uaPassPlusBaseFare',
  UNITED_PASSPLUS_FEES_AND_TAXES = 'uaPassPlusFeesAndTax',
  PENALTY_FEES = 'penaltyFees',
  AIRLINE_CODE = 'appliedCreditAirlineCode',
  ZERO_MONEY = 'zeroMoney',
  OTHER_ANCILLARY = 'otherAncillary',
}

const {
  FLIGHTS,
  FLIGHT_CREDITS,
  FEES_AND_TAXES,
  ELIGIBLE_REFUND,
  SEATS,
  BAGGAGE,
  UNITED_PASSPLUS_FLIGHT,
  UNITED_PASSPLUS_FEES_AND_TAXES,
} = airTranslationKeys;

export function getFareData(
  fareComponentKey: FareComponentKey,
  fareComponentValue: MoneyUtil,
  paymentFees?: MoneyUtil,
  shouldUseOriginalAmount = true,
): IFareBreakupData | null {
  const fareComponentValueWithpaymentFees = paymentFees ? fareComponentValue.add(paymentFees) : fareComponentValue;

  const moneyDisplayFunction = shouldUseOriginalAmount ? localisedOriginalCurrencyValue : localisedCurrencyValue;

  switch (fareComponentKey) {
    case FareComponentKeyEnum.BASE_FARE:
      return {
        label: FLIGHTS,
        id: 'fare_flights',
        dataTestId: 'flight-fare',
        value: moneyDisplayFunction(fareComponentValue),
      };
    case FareComponentKeyEnum.FLIGHT_CREDITS:
      return {
        label: FLIGHT_CREDITS,
        id: 'fare_flight_credits',
        dataTestId: 'flight-credits-fare',
        value: moneyDisplayFunction(fareComponentValue),
      };
    case FareComponentKeyEnum.ELIGIBLE_REFUND:
      return {
        label: ELIGIBLE_REFUND,
        id: 'fare_eligible_refund',
        dataTestId: 'eligible-refund-fare',
        value: moneyDisplayFunction(fareComponentValue),
      };
    case FareComponentKeyEnum.FEES_AND_TAXES:
      return {
        label: FEES_AND_TAXES,
        id: 'fare_fees-taxes',
        dataTestId: 'fees-and-taxes',
        value: moneyDisplayFunction(fareComponentValueWithpaymentFees),
      };
    case FareComponentKeyEnum.PENALTY_FEES:
      return {
        label: 'Change fee',
        id: 'fare_penalty-fees',
        dataTestId: 'penalty-fees',
        value: moneyDisplayFunction(fareComponentValue),
      };
    case FareComponentKeyEnum.SEAT:
      return {
        label: SEATS,
        id: 'fare_seat-selection',
        dataTestId: 'seat-fare',
        value: moneyDisplayFunction(fareComponentValue),
      };
    case FareComponentKeyEnum.BAGGAGE:
      return {
        label: BAGGAGE,
        id: 'fare_baggage',
        dataTestId: 'baggage-fare',
        value: moneyDisplayFunction(fareComponentValue),
      };
    case FareComponentKeyEnum.UA_PASS_PLUS_BASE_FARE:
      return {
        label: UNITED_PASSPLUS_FLIGHT,
        id: 'United PassPlus base fare',
        dataTestId: 'base-fare-united-passplus',
        value: moneyDisplayFunction(fareComponentValue),
      };
    case FareComponentKeyEnum.UNITED_PASSPLUS_FEES_AND_TAXES:
      return {
        label: UNITED_PASSPLUS_FEES_AND_TAXES,
        id: 'United PassPlus Fees and taxes',
        dataTestId: 'fees-and-tax-united-passplus',
        value: moneyDisplayFunction(fareComponentValue),
      };
    case FareComponentKeyEnum.OTHER_ANCILLARY: {
      const otherAncillaryFareComponentValue = fareComponentValue as unknown as IFareComponent['otherAncillary'];
      const label =
        otherAncillaryFareComponentValue.ancillaries.length === 1
          ? otherAncillaryFareComponentValue.ancillaries[0]
          : defineMessage('Other Ancillaries');

      return {
        label,
        id: 'Other Ancillaries',
        dataTestId: 'other-ancillaries',
        value: moneyDisplayFunction(otherAncillaryFareComponentValue.totalPrice),
      };
    }
    default:
      return null;
  }
}
export interface INegotiatedRatesInfo {
  savingsInPercents: number;
  negotiatedRateSavings: MoneyUtil;
  fareType: FareTypeEnum;
}

interface IFareBreakupV2 {
  paxesFareBreakup: IPaxFareBreakup[];
  totalAmount: MoneyUtil;
  totalTicketPrice: MoneyUtil;
  isSameCurrency: boolean;
  ticketType: RateOptionTicketType;
  // TODO: make this non-optional when implemented on web.
  negotiatedRatesInfo?: INegotiatedRatesInfo;
  allowedFop: PaymentMethod[];
  primaryTravelerTicketPrice: MoneyUtil;
  merchantFee: MoneyUtil;
  totalAmountExcludingServiceFees: MoneyUtil;
  totalFlightPrice: MoneyUtil;
  totalTaxesAndFees: MoneyUtil;
  totalSeatPrice: MoneyUtil;
  totalBaggagePrice: MoneyUtil;
}

export interface IUseGetItineraryFareBreakup extends IFareBreakupV2 {
  paymentFees: MoneyUtil;
  serviceFees: IServiceFeeData;
  isSameCurrency: boolean;
  showBrexPointsFare: boolean;
  unusedCredits: MoneyUtil;
}

interface IGetPaxTotalSeatPrice {
  paxUserId: string;
  paxSelectedSeats: IPaxSegmentSeatNumber[];
  airSeatMapResponseManager: AirSeatMapResponseManager;
  zeroMoney: MoneyUtil;
}

export const getPaxTotalSeatPrice = ({
  paxUserId,
  paxSelectedSeats,
  airSeatMapResponseManager,
  zeroMoney,
}: IGetPaxTotalSeatPrice): MoneyUtil => {
  if (!paxUserId || paxSelectedSeats.length === 0) {
    return zeroMoney;
  }
  const paxSelectedSeatsPrices: MoneyUtil[] = paxSelectedSeats.map((seatInfo) => {
    const { flightIndex, seatNumber: paxSeatNumber } = seatInfo;
    const paxSeatPrice = airSeatMapResponseManager.getSeatPriceForPaxAndSeatNumber(
      paxUserId,
      flightIndex,
      paxSeatNumber,
    );
    if (paxSeatPrice.isZero()) {
      return zeroMoney;
    }

    return paxSeatPrice;
  });

  let totalPaxSeatPrice = zeroMoney;
  paxSelectedSeatsPrices.forEach((seatPrice) => {
    totalPaxSeatPrice = totalPaxSeatPrice.add(seatPrice);
  });

  return totalPaxSeatPrice;
};
