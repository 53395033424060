import { useTranslation } from 'react-i18next';
import { defineMessage } from '../../translations/defineMessage';
import type { IAirRevalidateItineraryResponse } from '../../types';
import type { AirRevalidateItineraryResponse } from '../../types/api/v2/obt/model/air-revalidate-itinerary-response';
import type { IFareBreakupFields, IFareChangeFareBreakupNode } from '../../types/Flights/fareChangeBreakupNode';
import { ColorVariantEnum, FareChangeFareBreakupNodeTypeEnum } from '../../types/Flights/fareChangeBreakupNode';
import { localizationKeys } from '../../constants/common';
import { TripFeeLabels } from '../../constants/paymentTripFees';
import { MoneyUtil } from '../Money';
import { localisedCurrencyValue, localisedOriginalCurrencyValue } from './fareBreakupUtils';
import { getPaymentTripFeeTypeLabel } from '../payment/getPaymentTripFeeLabel';

const { LOCALIZED_CURRENCY } = localizationKeys;

interface RevalidateResponseFares extends Partial<IAirRevalidateItineraryResponse> {
  legPrices?: AirRevalidateItineraryResponse['legPrices'];
}

interface IGetFareChangeBreakupNodes {
  revalidateResponse: RevalidateResponseFares | null;
  fareBreakup: IFareBreakupFields | null;
}

interface IFareChangeBreakupNodes {
  breakup: IFareChangeFareBreakupNode[];
  isFareChangePositive: boolean;
}
/**
 *
 * This method returns breakup nodes in fare change scenarios. This is compatible with
 * v1 and v2 revalidate response
 */
export const useGetFareChangeBreakupNodes = ({
  revalidateResponse,
  fareBreakup,
}: IGetFareChangeBreakupNodes): IFareChangeBreakupNodes => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation('COMMON');

  if (!fareBreakup || !revalidateResponse) {
    return { breakup: [], isFareChangePositive: false };
  }

  const { serviceFees, totalSeatPrice, totalBaggagePrice, totalAmount: previousTotalAmount } = fareBreakup;
  const { totalFare, fareDifference, penaltyValue, appliedCreditValue, totalItineraryFare, fareRules } =
    revalidateResponse;

  const updatedFareWithoutServiceFees = MoneyUtil.parse(totalFare);

  const updatedFareWithServiceFees = updatedFareWithoutServiceFees.add(serviceFees.total);
  const eligibleRefund = MoneyUtil.parse(fareDifference);
  const changeFees = MoneyUtil.parse(penaltyValue);
  const unusedCreditsApplied = MoneyUtil.parse(appliedCreditValue);
  const updatedItineraryFare = MoneyUtil.parse(totalItineraryFare);
  const fareChange = updatedFareWithServiceFees.subtract(previousTotalAmount);

  const isFareChangePositive = fareChange.isPositive();

  const serviceFeesHavingDifferentOriginalCurrency =
    !serviceFees.total.isZero() &&
    (serviceFees.total.getOriginalCurrency() !== updatedFareWithoutServiceFees.getOriginalCurrency() ||
      serviceFees.isTotalHavingDifferentCurrency);

  const breakup: IFareChangeFareBreakupNode[] = [];

  const hasNewFareRules = fareRules && fareRules.length !== 0;

  if (totalFare && !hasNewFareRules && isFareChangePositive) {
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.BANNER,
      value: tCommon(defineMessage('The airline has increased the flight fare for this route.')),
    });
  }

  if (totalItineraryFare) {
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Updated flight fare')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(updatedItineraryFare)),
      subText: '',
      colorVariant: ColorVariantEnum.NORMAL,
    });
  }

  if (!changeFees.isZero()) {
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Change fee')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(changeFees)),
      subText: '',
      colorVariant: ColorVariantEnum.NORMAL,
    });
  }

  if (!unusedCreditsApplied.isZero()) {
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Flight credits')),
      value: `-${t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(unusedCreditsApplied))}`,
      colorVariant: ColorVariantEnum.SUCCESS,
      subText: '',
    });
  }

  if (serviceFeesHavingDifferentOriginalCurrency) {
    breakup.push(
      { type: FareChangeFareBreakupNodeTypeEnum.DIVIDER },
      {
        type: FareChangeFareBreakupNodeTypeEnum.HEADING,
        label: tCommon(defineMessage('Booking Amount')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(updatedFareWithoutServiceFees)),
      },
    );
  }

  if (eligibleRefund.isNegative()) {
    breakup.push(
      { type: FareChangeFareBreakupNodeTypeEnum.DIVIDER },
      {
        type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('Residual amount')),
        colorVariant: ColorVariantEnum.DANGER,
        subText: tCommon(
          defineMessage(
            'Refund for the residual amount not guaranteed. It is subject to fare rules and at the discretion of the carrier.',
          ),
        ),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(eligibleRefund.multiply(-1))),
      },
    );
  }

  if (
    !eligibleRefund.isZero() &&
    (!totalBaggagePrice.isZero() || !totalSeatPrice.isZero() || !serviceFees.total.isZero())
  ) {
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.DIVIDER,
    });
  }

  if (!totalBaggagePrice.isZero()) {
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Baggage fee')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalBaggagePrice)),
      subText: '',
      colorVariant: ColorVariantEnum.NORMAL,
    });
  }

  if (!totalSeatPrice.isZero()) {
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Seat fee')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalSeatPrice)),
      subText: '',
      colorVariant: ColorVariantEnum.NORMAL,
    });
  }

  if (serviceFees.showWithFeeBreakup && !serviceFees.total.isZero()) {
    if (!totalBaggagePrice.isZero() || !totalSeatPrice.isZero()) {
      breakup.push({
        type: FareChangeFareBreakupNodeTypeEnum.DIVIDER,
      });
    }
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: tCommon(TripFeeLabels.TRIP_FEES_STAR),
      value: serviceFees.isTotalHavingDifferentCurrency
        ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(serviceFees.total))}`
        : t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.total)),
      subText: '',
      colorVariant: ColorVariantEnum.NORMAL,
      showAsSubHeader: true,
    });

    serviceFees.tripFees?.forEach((tripFee) => {
      if (!tripFee.base.isZero()) {
        breakup.push({
          type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
          label: tCommon(getPaymentTripFeeTypeLabel(tripFee)),
          value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(tripFee.base)),
          subText: '',
          colorVariant: ColorVariantEnum.NORMAL,
        });
      }
    });
    if (serviceFees.showTax) {
      breakup.push({
        type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
        label: tCommon(TripFeeLabels.TRIP_FEES_TAXES),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.tax)),
        subText: '',
        colorVariant: ColorVariantEnum.NORMAL,
      });
    }

    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: '',
      value: '',
      subText: tCommon(TripFeeLabels.NON_REFUNDABLE),
      colorVariant: ColorVariantEnum.NORMAL,
    });
  }
  if (!serviceFees.showWithFeeBreakup && !serviceFees.base.isZero()) {
    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('$t(Service fee)*')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.base)),
      subText: '',
      colorVariant: ColorVariantEnum.NORMAL,
    });

    if (serviceFees.showTax) {
      breakup.push({
        type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('$t(Service fee) taxes*')),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.tax)),
        subText: '',
        colorVariant: ColorVariantEnum.NORMAL,
      });
    }

    breakup.push({
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
      label: '',
      value: '',
      subText: tCommon(defineMessage('*Non-refundable')),
      colorVariant: ColorVariantEnum.NORMAL,
    });
  }

  if (!serviceFees.total.isZero() || !totalSeatPrice.isZero() || !totalBaggagePrice.isZero()) {
    breakup.push({ type: FareChangeFareBreakupNodeTypeEnum.DIVIDER });
  }

  if (totalFare) {
    breakup.push(
      {
        type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
        label: tCommon(defineMessage('Previous total:')),
        colorVariant: ColorVariantEnum.NORMAL,
        subText: '',
        value: serviceFeesHavingDifferentOriginalCurrency
          ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(previousTotalAmount))}`
          : t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(previousTotalAmount)),
        strikethroughValue: true,
      },
      {
        type: FareChangeFareBreakupNodeTypeEnum.NORMAL,
        label: isFareChangePositive ? tCommon(defineMessage('Fare increase')) : tCommon(defineMessage('Fare decrease')),
        colorVariant: isFareChangePositive ? ColorVariantEnum.DANGER : ColorVariantEnum.SUCCESS,
        subText: '',
        value: serviceFeesHavingDifferentOriginalCurrency
          ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(fareChange))}`
          : t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(fareChange)),
      },
      {
        type: FareChangeFareBreakupNodeTypeEnum.HEADING,
        label: tCommon(defineMessage('Updated amount')),
        value: serviceFeesHavingDifferentOriginalCurrency
          ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(updatedFareWithServiceFees))}`
          : t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(updatedFareWithServiceFees)),
      },
    );
  }

  return { breakup, isFareChangePositive };
};
