// Generated using yarn generate command
import MuiInputBase, { InputBaseProps } from '@mui/material/InputBase';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { forwardRef, useId } from 'react';
import type { RefCallBack } from 'rhf-v7';
import omit from 'lodash/omit';
import FormHelperText from '@mui/material/FormHelperText';
import type { PropsOf } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import type { RemoveMuiProps } from '../types';
import { InputLabel } from '../InputLabel';
import { form_helper_text, input_control, input_label, input_wrapper } from './Input.styles';
import { Icon } from '../Icon';
import { useBlocksTheme } from '../utils/styling/useBlocksTheme';
import { clsx } from '../utils';

export type TInputStates = 'error' | 'success' | 'warning' | 'default';
export type TInputSizes = 'medium' | 'standard' | 'small';

/**
 * TODO: replace with actual runtime theme variable after theming utils work is done
 * https://mui.com/material-ui/react-text-field/#customization
 */

export type TBaseInputProps =
  | RemoveMuiProps<InputBaseProps, 'ref' | 'defaultValue'> & {
      labelProps?: PropsOf<typeof InputLabel>;
      /**
       * Rare use-case like Select where another component needs the input label's ID
       */
      ref?: RefCallBack | React.RefObject<HTMLInputElement>;
      formControlProps?: FormControlProps;
      fullWidth?: boolean;
      hideLabel?: boolean;
      helperText?: string;
      wrapperRef?: React.RefObject<HTMLDivElement>;
      state?: TInputStates;
      /**
       * Small = 32px, Medium = 40px, Standard (default) = 48px
       */
      size?: TInputSizes;
      /**
       * Please ensure that label is in sentence case. For eg - First name and not First Name
       */
      label?: string;
    };

export type TInputProps = TBaseInputProps & {
  defaultValue?: string;
};

export const Input = forwardRef<HTMLInputElement, TInputProps>((props: TInputProps, ref) => {
  const {
    helperText,
    required,
    className,
    formControlProps,
    fullWidth,
    labelProps,
    hideLabel,
    wrapperRef,
    state,
    size = 'standard',
    multiline = false,
    endAdornment,
    placeholder,
    ...baseInputProps
  } = props;

  const inputId = useId();
  const descriptionId = `${inputId}-description`;
  const { palette } = useBlocksTheme();
  const { t: tt } = useTranslation('WEB');

  let endIcon: React.ReactNode | undefined;
  if (state === 'error') {
    endIcon = <Icon height={20} width={20} name="ErrorCircleFill" color={palette.icon.error} />;
  } else if (state === 'success') {
    endIcon = <Icon height={20} width={20} name="CheckCircleFill" color={palette.icon.success} />;
  }

  let endAdornmentWithStateIcon = props.endAdornment;
  if (endIcon) {
    endAdornmentWithStateIcon = (
      <div className="BlocksInput-EndAdornmentWithStateIcon">
        {endIcon}
        {endAdornment}
      </div>
    );
  }

  /**
   * Inputs in read only state should not have any placeholders
   */
  const getPlaceholder = () => {
    if (props.readOnly) {
      return undefined;
    }

    return placeholder ?? (baseInputProps.label ? tt('Enter {{label}}', { label: baseInputProps.label }) : undefined);
  };

  const descriptionProps: React.AriaAttributes = {};
  if (helperText) {
    descriptionProps['aria-describedby'] = descriptionId;
  }

  return (
    <InputFormControl
      ref={wrapperRef}
      state={state}
      className={clsx(className, { 'BlocksInput-fullWidth': fullWidth })}
      inputId={inputId}
      required={required}
      label={'label' in baseInputProps ? baseInputProps.label : undefined}
      labelProps={{ ...labelProps, className: hideLabel ? 'BlocksLabel-hidden' : undefined }}
      {...formControlProps}
      aria-live="polite"
    >
      <MuiInputBase
        css={input_wrapper}
        id={inputId}
        required={required}
        className={clsx(
          { 'BlocksInput-fullWidth': fullWidth, 'MuiInputBase-multiline': multiline },
          `BlocksInput-${size}`,
        )}
        {...omit(baseInputProps, ['label'])}
        inputRef={ref}
        multiline={multiline}
        endAdornment={endAdornmentWithStateIcon}
        placeholder={getPlaceholder()}
        {...descriptionProps}
      />
      <InputHelperText id={descriptionId} state={state} message={helperText} />
    </InputFormControl>
  );
});

export function InputHelperText({
  id,
  message,
  state,
  className,
}: {
  id?: string;
  message?: React.ReactNode;
  state?: TInputStates;
  className?: string;
}) {
  return message ? (
    <FormHelperText id={id} css={form_helper_text} className={clsx(className, state ? `state-${state}` : undefined)}>
      {message}
    </FormHelperText>
  ) : null;
}

/**
 * This is an internal component used by components like Select which need to render an input without any of FormControl features
 */
export const InputBase = forwardRef<HTMLInputElement, TInputProps>(function InputBaseComponent(
  { state, fullWidth, className, size = 'standard', ...props },
  ref,
) {
  return (
    <MuiInputBase
      css={input_wrapper}
      inputRef={ref}
      className={clsx(
        className,
        {
          'BlocksInput-fullWidth': fullWidth,
          [`state-${state}`]: Boolean(state),
        },
        `BlocksInput-${size}`,
      )}
      {...props}
    />
  );
});

export function InputFormControl({
  className,
  inputId,
  required,
  label,
  children,
  labelProps,
  state,
  ...formControlProps
}: FormControlProps & {
  labelProps?: PropsOf<typeof InputLabel>;
  className?: string;
  inputId: string;
  required?: boolean;
  label?: string;
  state?: TInputStates;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <FormControl
      css={input_control}
      variant="standard"
      className={clsx(className, state && `state-${state}`)}
      {...formControlProps}
      error={state === 'error'}
    >
      {label && (
        <InputLabel
          {...labelProps}
          id={labelProps?.id ?? `${inputId}-label`}
          htmlFor={labelProps?.htmlFor ?? inputId}
          css={input_label}
          shrink
          required={required}
          className={labelProps?.className}
        >
          {label}
        </InputLabel>
      )}
      {children}
    </FormControl>
  );
}
