import { SerializedStyles, css } from '@emotion/react';
import { flex, margins, themed } from '../utils/styling';
import { InternalBlocksTheme } from '../BlocksThemeProvider/blocksTheme';
import { cornerRadii, paddings, spacings } from '../utils/styling/utilityTokens';

export const input_control = css`
  &.BlocksInput-fullWidth {
    width: 100%;
    ${flex.init}
    ${spacings.extraSmall}
    position: relative;
  }
`;

const input_size = ({
  height,
  spacing,
  rootInputPaddingX,
  rootInputPaddingY,
  inputPaddingX,
  inputPaddingY,
  adornmentIconSize,
  adornmentMargin,
  inputTypography,
  cornerRadius,
}: {
  height: string;
  rootInputPaddingX: SerializedStyles;
  rootInputPaddingY: SerializedStyles;
  inputPaddingX: SerializedStyles;
  inputPaddingY: SerializedStyles;
  adornmentIconSize: string;
  adornmentMargin: string;
  inputTypography: {
    fontWeight: number;
    fontSize: string;
    lineHeight: string;
  };
  spacing?: SerializedStyles;
  cornerRadius?: SerializedStyles;
}) =>
  themed(
    () => css`
      height: ${height};

      &.MuiInputBase-root {
        ${spacing}
        ${rootInputPaddingX}
        ${rootInputPaddingY}
        ${cornerRadius}
    
        .BlocksInput-AdornmentWrapper-left, .BlocksInput-AdornmentWrapper-right {
          > svg {
            height: ${adornmentIconSize};
            width: ${adornmentIconSize};
          }
        }

        .BlocksInput-AdornmentWrapper-left:not(:has(button)) {
          margin-left: ${adornmentMargin};
        }

        .BlocksInput-AdornmentWrapper-right:not(:has(button)) {
          margin-right: ${adornmentMargin};
        }
      }

      .MuiInputBase-input {
        ${inputPaddingX}
        ${inputPaddingY}
        ${inputTypography}
      }
    `,
  );
export const input_placeholder_text = themed(
  ({ palette, fontWeight }) => css`
    color: ${palette.inputs.textPlaceholder};
    text-wrap: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    opacity: 1;
    font-weight: ${fontWeight.w400};
  `,
);
// styes applied to the .MuiInputBase-root puts adormnent component inside input
export const input_wrapper = themed(
  ({ palette, typography, palette: { inputs } }, { compose }) => css`
    &.BlocksInput-fullWidth {
      width: 100%;
    }

    &.MuiInputBase-root {
      border-radius: ${palette.inputs.borderRadius};
      border: 1px solid ${inputs.borderDefault};
      box-shadow: none;
      &.MuiInputBase-multiline {
        height: inherit;
        overflow: hidden;
      }

      .state-error > &,
      &.state-error {
        border: 1px solid ${inputs.borderError} !important;
        outline: none !important;
      }

      .state-warning > &,
      &.state-warning {
        border: 1px solid ${inputs.borderWarning} !important;
        outline: none !important;
      }

      .state-success > &,
      &.state-success {
        border: 1px solid ${inputs.borderSuccess} !important;
      }

      &:hover {
        border: 1px solid ${palette.border.hover};
      }

      &:focus,
      &.Blocks-focused,
      &.Mui-focused,
      &.Simulate-focused {
        /* focus state should override all border styles */
        border: 1px solid ${palette.border.keyboardFocus} !important;
        outline: 1px solid ${palette.border.keyboardFocus} !important;
      }

      background-color: ${palette.surface.base};
      &.Mui-disabled {
        border: 1px solid ${palette.border.light};
        background-color: ${palette.bg.disabled};
        color: ${palette.inputs.textDisabled};
        cursor: not-allowed;
        pointer-events: all !important;
      }
    }

    .MuiInputBase-input {
      transition: all 0.25s ease-in-out;

      position: relative;

      color: ${palette.inputs.text};
      width: 100%;
      box-shadow: none;
      text-overflow: ellipsis;

      &::placeholder {
        ${compose(input_placeholder_text)}
      }

      &:disabled {
        color: ${palette.inputs.textDisabled};
        cursor: not-allowed;
        pointer-events: all !important;
      }
    }

    .BlocksInput-EndAdornmentWithStateIcon {
      ${flex.init}
      ${flex.row}
      ${flex.align.center}
      ${spacings.medium}
    }

    &.BlocksNumberInput {
      padding: 0 3.5rem;
      *.MuiInputBase-input {
        min-width: 5rem;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          display: none;
        }
        &:disabled {
          border: none;
        }
      }
    }

    .BlocksInput-AdornmentWrapper-left,
    .BlocksInput-AdornmentWrapper-right {
      padding: 0;
    }

    /* 
      For icon buttons, we just need 4px padding because of the hover effect on icon buttons.
      So to make it consistent for other start adornments(like icons), we have to specify additional margin
      of 4px 
    */
    &.BlocksInput-standard {
      ${compose(
        input_size({
          height: '48px',
          spacing: spacings.small,
          rootInputPaddingX: paddings.x.tight,
          rootInputPaddingY: paddings.y.tight,
          adornmentIconSize: '24px',
          adornmentMargin: '4px',
          inputPaddingX: paddings.x.superTight,
          inputPaddingY: paddings.y.superTight,
          inputTypography: typography.body1.default,
        }),
      )}
    }

    &.BlocksInput-medium {
      ${compose(
        input_size({
          height: '40px',
          spacing: spacings.extraSmall,
          rootInputPaddingX: paddings.x.superTight,
          rootInputPaddingY: paddings.y.superTight,
          adornmentIconSize: '20px',
          adornmentMargin: '4px',
          inputPaddingX: paddings.x.superTight,
          inputPaddingY: paddings.y.buttonSmall,
          inputTypography: typography.body2.default,
        }),
      )}
    }

    &.BlocksInput-small {
      ${compose(
        input_size({
          height: '32px',
          cornerRadius: cornerRadii.buttonSmall,
          rootInputPaddingX: paddings.x.superTight,
          rootInputPaddingY: paddings.y.superTight,
          adornmentIconSize: '20px',
          adornmentMargin: '0px', // Adjust as needed
          inputPaddingX: paddings.x.superTight,
          inputPaddingY: paddings.y.extremeTight,
          inputTypography: typography.body2.default,
        }),
      )}
    }
  `,
);

export const input_label = themed(
  ({ palette }) => css`
    /**
    * need to add important because mui is applying some styling to the label causing it to appear blue otherwise
    */
    color: ${palette.text.secondary} !important;
    position: relative;

    .MuiInputLabel-asterisk {
      color: ${palette.text.error};
    }
  `,
);

export const numeric_input_action = themed(
  ({ palette: { bg, inputs, surface } }) => css`
    position: absolute;
    height: calc(100% - 0px);
    font-size: 1.6rem;
    line-height: 0;
    width: 3rem;
    background-color: ${surface.background};

    transition: all 0.25s ease-in-out;
    border: 1px solid ${inputs.borderDefault};

    &:hover,
    .MuiInputBase-root:hover & {
      border: 1px solid ${inputs.borderHover};
    }

    .MuiInputBase-root:disabled & {
      border: 1px solid ${inputs.borderDisabled};
    }

    :focus-visible {
      background-color: ${bg.buttonDisabled};
    }
  `,
);

const leftStyles = (palette: InternalBlocksTheme['palette']['light']) => css`
  left: 0;
  border: none !important;
  border-top-left-radius: ${palette.inputs.borderRadius};
  border-bottom-left-radius: ${palette.inputs.borderRadius};
`;

const rightStyles = (palette: InternalBlocksTheme['palette']['light']) => css`
  right: 0;
  border: none !important;
  border-top-right-radius: ${palette.inputs.borderRadius};
  border-bottom-right-radius: ${palette.inputs.borderRadius};
`;

export const action_minus = themed(
  ({ palette }) => css`
    ${leftStyles(palette)}

    &:dir(rtl) {
      ${rightStyles(palette)}
    }
  `,
);

export const action_plus = themed(
  ({ palette }) => css`
    ${rightStyles(palette)}

    &:dir(rtl) {
      ${leftStyles(palette)}
    }
  `,
);

export const form_helper_text = themed(
  ({ typography, palette }) => css`
    ${typography.body2.default}
    /** unset MUI margins */
    margin: 0;
    ${margins.xl.point25}
    ${margins.yt.point25}
    position: relative;
    bottom: 0;
    &.state-error {
      color: ${palette.text.error};
    }

    &.state-success {
      color: ${palette.text.success};
    }
  `,
);

export const numeric_postfix = css`
  position: absolute;
  right: 3.5rem;
`;
