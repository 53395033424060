import { storage, StorageKeys, useLoggedInUser } from 'obt-common';
import { CognigyChatEventData, CognigyChatConfig } from 'obt-common/support/cognigy/types';
import { useEffect } from 'react';

declare global {
  interface Window {
    initWebchat?: (url: string, options?: any) => Promise<WebchatInstance>;
    cognigyWebchat?: WebchatInstance;
  }
}

interface WebchatInstance {
  open: () => void;
  close: () => void;
  sendMessage: (message: string) => void;
  registerAnalyticsService: (callback: (event: CognigyChatEventData) => void) => void;
}

interface CognigyChat {
  openCognigyChat: () => void;
  closeCognigyChat: () => void;
}

export function useCognigyChat(isEnabled: boolean, config: CognigyChatConfig): CognigyChat {
  const { data: userData } = useLoggedInUser();
  const userId = userData?.userOrgId?.userId?.id || '';

  useEffect(() => {
    if (!isEnabled) return;

    const { jsBundleUrl, endpointBaseUrl, endpointApiKey, sessionTimeout } = config;

    const script = document.createElement('script');
    script.src = jsBundleUrl;
    script.async = true;
    script.onload = () => initializeWebchat(endpointBaseUrl, endpointApiKey, sessionTimeout);
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, config, userId]);

  const initializeWebchat = async (endpointBaseUrl: string, endpointApiKey: string, sessionTimeout: number) => {
    if (!window.initWebchat) return;

    let sessionId = await storage.getItem(StorageKeys.COGNIGY_SESSION_ID);
    const lastOutgoingMessageTimestamp = (await storage.getItem(StorageKeys.COGNIGY_LAST_OUTGOING_MESSAGE)) ?? '';

    if (!sessionId || isSessionExpired(lastOutgoingMessageTimestamp, sessionTimeout)) {
      sessionId = `session-${Date.now()}`;
      await storage.setItem(StorageKeys.COGNIGY_SESSION_ID, sessionId);
    }

    injectCustomStyles();

    const webchat = await window.initWebchat(`${endpointBaseUrl}${endpointApiKey}`, { userId, sessionId });
    window.cognigyWebchat = webchat;

    registerAnalyticsService(webchat);
  };

  const isSessionExpired = (lastOutgoingMessageTimestamp: string, sessionTimeout: number): boolean => {
    if (!lastOutgoingMessageTimestamp) return false;
    const diff = Date.now() - parseInt(lastOutgoingMessageTimestamp, 10);
    return Math.floor(diff / 1000 / 60) > sessionTimeout;
  };

  const injectCustomStyles = () => {
    const style = document.createElement('style');
    style.innerHTML =
      '.webchat-toggle-button { display: none !important; } #webchatWindow { max-height: 50%; width: 360px; margin-bottom: 20px; margin-right: 10px; }';
    document.head.appendChild(style);
  };

  const registerAnalyticsService = (webchat: WebchatInstance) => {
    webchat.registerAnalyticsService((event) => {
      const { type } = event;
      if (type === 'webchat/outgoing-message') {
        storage.setItem(StorageKeys.COGNIGY_LAST_OUTGOING_MESSAGE, Date.now().toString());
      }
    });
  };

  return {
    openCognigyChat: () => window.cognigyWebchat?.open(),
    closeCognigyChat: () => window.cognigyWebchat?.close(),
  };
}
