import first from 'lodash/first';

import { defineCommonMessage } from '../../../translations/defineMessage';
import { getDurationInDaysOrHours } from '../../../date-utils';
import type { PnrV3ManagerProps } from '../PnrV3Manager';
import { PnrV3Manager } from '../PnrV3Manager';
import type { LimoInfo } from '../../../types/api/v2/obt/model/limo-info';
import { localizeDateTime } from '../../../translations/localizers/datetime';
import { MoneyUtil } from '../../../utils/Money';
import { CancellationPolicy1PolicyEnum } from '../../../types/api/v2/obt/model/cancellation-policy1';
import { getLocationFullAddressV2 } from '../../../utils/common';
import type { CostExtrasInner, LimoLegInfo } from '../../../types/api/v2/obt/model';
import { ElectricVehicle, LimoInfoPaymentTypeEnum } from '../../../types/api/v2/obt/model';
import { EnumToCarMapper } from '../../../constants/limo';
import { CreatedVia } from '../../../types/api/v2/obt/model/created-via';

export class LimoPnrV3Manager extends PnrV3Manager {
  limoPnr?: LimoInfo;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    super({ pnrData, pnrId });
    this.limoPnr = this.pnrData.limoPnr;
  }

  public vendorName(): string {
    return this.limoPnr?.limoDetails.limoVendorInfo?.name ?? this.limoPnr?.limoDetails.vendorName ?? '';
  }

  public isOutsideBooking(): boolean {
    return this?.sourceInfo()?.thirdPartySource === 'OFFLINE';
  }

  public isShellPnr(): boolean {
    return this.pnrData?.createdVia === CreatedVia.Shell;
  }

  public cancellationPolicyText(): string {
    const cancellationPolicy = this.limoPnr?.cancellationPolicy;
    const deadline = this.limoPnr?.cancellationPolicy?.deadline?.iso8601;
    const cancellationPolicyDeadline = localizeDateTime(deadline, {
      dateStyle: 'medium',
      timeStyle: 'short',
    });
    const money = MoneyUtil.convertV2MoneyToMoneyUtil(cancellationPolicy?.amount);
    let cancellationPolicyText = '';
    switch (cancellationPolicy?.policy) {
      case CancellationPolicy1PolicyEnum.FreeCancellationUntil:
        cancellationPolicyText = `Cancel by  ${cancellationPolicyDeadline}  to avoid penalty`;
        break;
      case CancellationPolicy1PolicyEnum.PartiallyRefundable:
        cancellationPolicyText = `Refundable for ${money.getDisplayAmount()} ${money.getCurrency()}`;
        break;
      case CancellationPolicy1PolicyEnum.NonRefundable:
        cancellationPolicyText = `Non refundable`;
        break;
      default:
        cancellationPolicyText = '';
    }
    return cancellationPolicyText;
  }

  private legInfo(leg: LimoLegInfo) {
    const pickupDatetime = leg?.pickupDateTime.iso8601;
    const dropoffDatetime = leg?.dropOffDateTime?.iso8601;
    const pickupLocation = leg?.pickupLocation;
    const dropoffLocation = leg?.dropOffLocation;
    const pickUpAddressLines = leg?.pickupLocation?.address.addressLines;
    const dropOffAddressLines = leg?.dropOffLocation?.address?.addressLines;
    const duration = dropoffDatetime ? getDurationInDaysOrHours(pickupDatetime ?? '', dropoffDatetime) : '';

    return {
      pickup: {
        date: leg?.pickupDateTime,
        location: {
          address: getLocationFullAddressV2(pickupLocation?.address),
          coordinates: leg?.pickupLocation.coordinates,
        },
        addressLines: pickUpAddressLines,
      },
      dropoff: {
        date: leg?.dropOffDateTime,
        location: {
          address: getLocationFullAddressV2(dropoffLocation?.address),
          coordinates: leg?.dropOffLocation?.coordinates,
        },
        addressLines: dropOffAddressLines,
      },
      duration,
      pickupNotes: leg?.pickupNotes,
      dropoffNotes: leg?.dropOffNotes,
    };
  }

  public limoDetails() {
    const { limoPnr } = this;
    const limoDetails = limoPnr?.limoDetails;
    const firstLeg = first(limoPnr?.legs);
    const { bookingStatus } = this.pnrData;

    const legsInfo = limoPnr?.legs.map((leg) => this.legInfo(leg));

    // TODO: remove legInfo and use legsInfo
    const legInfo = legsInfo && legsInfo[0];

    const isLimoElectric = limoDetails?.electricVehicle === ElectricVehicle.Yes;
    const carTypeName = EnumToCarMapper(limoDetails?.carType);
    const carName = carTypeName ? `${carTypeName}${isLimoElectric ? ' (Electric)' : ''}` : undefined;

    const phoneNumber = {
      rawInput: limoPnr?.driver?.phone?.rawInput,
      countryCode: limoPnr?.driver?.phone?.countryCode,
    };

    const validCancellationPolicy =
      !!this.limoPnr?.cancellationPolicy &&
      this.limoPnr?.cancellationPolicy.policy !== CancellationPolicy1PolicyEnum.Unknown;

    return {
      legsInfo,
      cancellationPolicyText: this.cancellationPolicyText(),
      carName,
      confirmationNumber: limoPnr?.vendorConfirmationNumber,
      bookingId: this.pnrId,
      driver: {
        name: limoPnr?.driver?.name,
        phone: phoneNumber,
      },
      startDate: firstLeg?.pickupDateTime,
      endDate: firstLeg?.dropOffDateTime,
      legInfo,

      validCancellationPolicy,
      vendorName: this.vendorName(),
      bookingDateTime: this.pnrData.sourceInfo?.bookingDateTime,
      travelerDetails: super.travelerDetails(),
      bookingStatus,
      isPnrOutOfPolicy: super.isPnrOutOfPolicy(),
      amenities: limoDetails?.amenities,
      bookingNotes: limoPnr?.bookingNotes,
      duration: limoPnr?.duration,
      notesToVendor: limoPnr?.notesToVendor,
    };
  }

  public paymentInfo() {
    const isPayLater = this.limoPnr?.paymentType && this.limoPnr.paymentType === LimoInfoPaymentTypeEnum.PayAtVendor;

    const transactionDate = this.limoPnr?.rate.transactionDate;
    const extras = this.limoPnr?.rate.extras;
    const base = this.pnrData.totalFareAmount?.base;

    const totalExtraCharges = (extras || []).reduce((acc: MoneyUtil, { amount }: CostExtrasInner): MoneyUtil => {
      const result = acc.add(MoneyUtil.convertV2MoneyToMoneyUtil(amount));
      return result;
    }, MoneyUtil.zeroMoneyWithOriginal(base?.convertedCurrency ?? 'USD', base?.currencyCode ?? 'USD'));

    const { baseAmount, paymentMethods, taxAmount, totalAmount } = super.paymentInfo();
    return {
      isPayLater,
      baseAmount,
      paymentMethods,
      taxAmount,
      totalExtraCharges,
      totalAmount: totalAmount.add(totalExtraCharges),
      transactionDate,
    };
  }

  public pnrTitle() {
    const pnrTitle = defineCommonMessage('Limo service');
    return pnrTitle;
  }
}
