/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * - CANCELLABLE_BY_OBT: Cancellation possible via API. - CANCELLABLE_BY_SUPPORT: Cancellation possible via agent support. - NON_CANCELLABLE: Booking is non-cancellable. - CANCELLATION_IN_PROGRESS: Cancellation is in progress. - CANCELLATION_BY_AGENT_REQUESTED: Cancellation request has been sent to agent. - CANCELLED: Already cancelled. - CANCELLATION_INFO_NOT_AVAILABLE: Cancellation information not available.
 * @export
 * @enum {string}
 */

export const AirCancellationState = {
  CancellableByObt: 'CANCELLABLE_BY_OBT',
  CancellableBySupport: 'CANCELLABLE_BY_SUPPORT',
  NonCancellable: 'NON_CANCELLABLE',
  CancellationInProgress: 'CANCELLATION_IN_PROGRESS',
  CancellationByAgentRequested: 'CANCELLATION_BY_AGENT_REQUESTED',
  Cancelled: 'CANCELLED',
  CancellationInfoNotAvailable: 'CANCELLATION_INFO_NOT_AVAILABLE',
} as const;

export type AirCancellationState = (typeof AirCancellationState)[keyof typeof AirCancellationState];
