import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import { getPaymentTripFeeTypeLabel } from '../../utils/payment/getPaymentTripFeeLabel';
import { TripFeeLabels } from '../../constants/paymentTripFees';
import { localisedCurrencyValue, localisedOriginalCurrencyValue } from '../../utils/Flights/fareBreakupUtils';
import { AirCheckoutFareNodeTypeEnum } from './useItineraryFareBreakup/types';
import type { IAirCheckoutFareBreakupNode } from './useItineraryFareBreakup/types';
import type { IServiceFeeData } from '../../types/payment';
import { localizationKeys } from '../../constants/common';

const { LOCALIZED_CURRENCY } = localizationKeys;

interface IGetFareBreakupTripFeesProps {
  serviceFees: IServiceFeeData;
}

interface IGetFareBreakupTripFeesUtil {
  serviceFees: IServiceFeeData;
  t: TFunction;
  tCommon: TFunction;
}

const getFareBreakupTripFeesUtil = ({
  serviceFees,
  t,
  tCommon,
}: IGetFareBreakupTripFeesUtil): IAirCheckoutFareBreakupNode[] => {
  const tripFeeNodes: IAirCheckoutFareBreakupNode[] = [];

  if (serviceFees.showWithFeeBreakup && !serviceFees.total.isAmountZero()) {
    tripFeeNodes.push({
      type: AirCheckoutFareNodeTypeEnum.SUB_HEADING,
      label: tCommon(TripFeeLabels.TRIP_FEES_STAR),
      value: serviceFees.isTotalHavingDifferentCurrency
        ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(serviceFees.total))}`
        : t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.total)),
    });

    serviceFees.tripFees?.forEach((tripFee) => {
      if (!tripFee.base.isZero()) {
        tripFeeNodes.push({
          type: AirCheckoutFareNodeTypeEnum.NORMAL,
          label: tCommon(getPaymentTripFeeTypeLabel(tripFee)),
          value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(tripFee.base)),
        });
      }
    });

    if (serviceFees.showTax) {
      tripFeeNodes.push({
        type: AirCheckoutFareNodeTypeEnum.NORMAL,
        label: tCommon(TripFeeLabels.TRIP_FEES_TAXES),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.tax)),
      });
    }

    tripFeeNodes.push({
      type: AirCheckoutFareNodeTypeEnum.INFO,
      text: tCommon(TripFeeLabels.NON_REFUNDABLE),
    });
  }

  return tripFeeNodes;
};

export const useGetAirFareBreakupTripFees = () => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation('COMMON');
  const getFareBreakupTripFees = useCallback(
    ({ serviceFees }: IGetFareBreakupTripFeesProps): IAirCheckoutFareBreakupNode[] => {
      return getFareBreakupTripFeesUtil({ serviceFees, t, tCommon });
    },
    [t, tCommon],
  );
  return {
    getFareBreakupTripFees,
  };
};
