import type { CustomResource, CustomResourceLoader } from '../../namespaces';

const LANG_CODE_TO_ASYNC_RESOURCE_LOADER_MAP: Record<string, () => Promise<{ default: CustomResource }>> = {
  de: () => import('./de.json'),
  en: () => import('./en.json'),
  'en-AU': () => import('./en-AU.json'),
  'en-CA': () => import('./en-CA.json'),
  'en-GB': () => import('./en-GB.json'),
  es: () => import('./es.json'),
  'es-419': () => import('./es-419.json'),
  fr: () => import('./fr.json'),
  'fr-CA': () => import('./fr-CA.json'),
  ja: () => import('./ja.json'),
  ko: () => import('./ko.json'),
  pt: () => import('./pt.json'),
  'pt-BR': () => import('./pt-BR.json'),
  // default loader for empty resource
  DEFAULT: async () => {
    return {
      default: {},
    };
  },
};

/**
 * Loads Custom Resource for a specific Language
 * @param langCode
 * @returns
 */
export const getResourceForLanguage: CustomResourceLoader = async (langCode) => {
  const loader = LANG_CODE_TO_ASYNC_RESOURCE_LOADER_MAP[langCode] ?? LANG_CODE_TO_ASYNC_RESOURCE_LOADER_MAP.DEFAULT;
  return (await loader()).default;
};
