import { useQuery, useQueries, useMutation } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import type { CreateSiteMessageRequest } from '@spotnana/types/openapi/models/create-site-message-request';
import type { UpdateSiteMessageRequest } from '@spotnana/types/openapi/models/update-site-message-request';
import api from '../../api';
import { defaultQueryClient } from '../defaultQueryClient';
import { SpotnanaError } from '../../api/SpotnanaError';

import type { ListSiteMessagesRequest } from '../../types/api/v2/obt/model/list-site-messages-request';
import type { ListSiteMessagesResponse } from '../../types/api/v2/obt/model/list-site-messages-response';
import type { SiteMessage } from '../../types/api/v2/obt/model/site-message';
import type { EntityId } from '../../types/api/v2/obt/model/entity-id';
import type { SpotnanaQueryResult } from '../../types/common';
import type { GetApplicableSiteMessagesRequest, GetApplicableSiteMessagesResponse } from '../../types';

const siteMessageDetailsKey = (companyId: string, messageId: string): unknown[] => {
  return ['site-message', companyId, messageId];
};

const invalidateSiteMessageDetailsKey = (companyId: string, messageId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries({
    queryKey: siteMessageDetailsKey(companyId, messageId),
  });

const companySiteMessagesKey = (companyId: string, filters?: ListSiteMessagesRequest): unknown[] => {
  const optional = omitBy({ filters }, isNil);
  return (['company-site-messages', companyId] as unknown[]).concat(isEmpty(optional) ? [] : [optional]);
};

const invalidateCompanySiteMessagesKey = (companyId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries({
    queryKey: companySiteMessagesKey(companyId),
  });

const fetchSiteMessageDetails = async (companyId: string, messageId: string): Promise<SiteMessage> => {
  const data = await api(
    'GET',
    'companyBaseUrl',
    {
      urlParam: `/${companyId}/site-messages/${messageId}`,
    },
    { allowParallelRequests: true },
  );
  return data as unknown as SiteMessage;
};

export const useGetSiteMessageDetails = (
  companyId: string,
  messageId: string,
  options?: {
    refetchOnMount?: boolean | 'always';
  },
) =>
  useQuery<SiteMessage>({
    queryKey: siteMessageDetailsKey(companyId, messageId),
    queryFn: () => fetchSiteMessageDetails(companyId, messageId),
    refetchOnMount: options?.refetchOnMount ?? true,
  });

const deleteSiteMessage = async (
  companyId: string,
  messageId: string,
): Promise<{ companyId: string; messageId: string }> => {
  await api('DELETE', 'companyBaseUrl', {
    urlParam: `/${companyId}/site-messages/${messageId}`,
  });
  return { companyId, messageId };
};

export const useDeleteSiteMessage = () =>
  useMutation({
    mutationFn: ({ companyId, messageId }: { companyId: string; messageId: string }) =>
      deleteSiteMessage(companyId, messageId),

    onSuccess: async ({ companyId }: { companyId: string }) => {
      await invalidateCompanySiteMessagesKey(companyId);
    },
  });

const createSiteMessage = async (companyId: string, requestBody: CreateSiteMessageRequest): Promise<EntityId> => {
  try {
    const data = await api('POST', 'companyBaseUrl', {
      urlParam: `/${companyId}/site-messages`,
      data: requestBody,
    });
    return data as unknown as EntityId;
  } catch {
    throw new SpotnanaError('Could not create Site Message');
  }
};

export const useCreateSiteMessage = (companyId: string) =>
  useMutation({
    mutationFn: (requestBody: CreateSiteMessageRequest) => createSiteMessage(companyId, requestBody),
    onSuccess: async () => {
      await invalidateCompanySiteMessagesKey(companyId);
    },
  });

const updateSiteMessage = async (
  companyId: string,
  messageId: string,
  requestBody: UpdateSiteMessageRequest,
): Promise<string> => {
  try {
    await api('PUT', 'companyBaseUrl', {
      urlParam: `/${companyId}/site-messages/${messageId}`,
      data: requestBody,
    });
    return messageId ?? '';
  } catch {
    throw new SpotnanaError('Updating Site Message failed');
  }
};

export const useUpdateSiteMessage = (companyId: string) =>
  useMutation({
    mutationFn: ({ messageId, requestBody }: { messageId: string; requestBody: UpdateSiteMessageRequest }) =>
      updateSiteMessage(companyId, messageId, requestBody),

    onSuccess: async (messageId: string) => {
      await invalidateCompanySiteMessagesKey(companyId);
      await invalidateSiteMessageDetailsKey(companyId, messageId);
    },
  });

const fetchCompanySiteMessages = async (
  companyId: string,
  filters?: ListSiteMessagesRequest,
): Promise<ListSiteMessagesResponse> => {
  if (!companyId) {
    return { messages: [] };
  }
  const data = await api('POST', 'companyBaseUrl', {
    urlParam: `/${companyId}/site-messages/list`,
    data: filters ?? {},
  });
  return data as unknown as ListSiteMessagesResponse;
};

export const useGetCompanySiteMessages = (
  companyId: string | undefined,
  options?: {
    filters?: ListSiteMessagesRequest;
    refetchOnMount?: boolean | 'always';
  },
) =>
  useQuery<ListSiteMessagesResponse>({
    queryKey: companySiteMessagesKey(companyId ?? '', options?.filters),
    queryFn: () => fetchCompanySiteMessages(companyId ?? '', options?.filters),
    refetchOnMount: options?.refetchOnMount ?? true,
  });

export const useGetCompanySiteMessagesDetails = (
  companyId: string,
  messageIds: string[],
): SpotnanaQueryResult<SiteMessage>[] =>
  useQueries({
    queries: messageIds
      ? messageIds.map((messageId) => ({
          queryKey: siteMessageDetailsKey(companyId, messageId),
          queryFn: () => fetchSiteMessageDetails(companyId, messageId),
        }))
      : [],
  }) as SpotnanaQueryResult<SiteMessage>[];

const fetchApplicableSiteMessages = async (
  requestBody: GetApplicableSiteMessagesRequest,
): Promise<GetApplicableSiteMessagesResponse> => {
  try {
    const messages = await api('POST', 'siteMessages', {
      data: requestBody,
    });
    return messages as GetApplicableSiteMessagesResponse;
  } catch (e) {
    return { messages: [] };
  }
};

export const useGetApplicableSiteMessages = () =>
  useMutation({
    mutationFn: ({ requestBody }: { requestBody: GetApplicableSiteMessagesRequest }) =>
      fetchApplicableSiteMessages(requestBody),
  });
