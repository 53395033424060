import type { Draft } from 'immer';
import produce from 'immer';
import first from 'lodash/first';
import type MultiPaxAirPnrManager from '../../../services/AirPnrManager';
import AirSearchResponseManager from '../../../services/AirSearchResponseManager';
import TripDetailsResponseManager from '../../../services/TripDetailsResponseManager';
import V2TripDetailsResponseManager from '../../../services/V2TripDetailsResponseManager';
import { defineMessage } from '../../../translations/defineMessage';
import { AirCancelPnrRequestCancelType } from '../../../types/api/v1/obt/pnr/air_cancel_pnr';
import type { AirPnrVoidPolicy, Pnr } from '../../../types/api/v1/obt/pnr/pnr';
import type { ManualFormReadResponse } from '../../../types/api/v2/obt/model';
import { AirCancellationState, PaymentPaymentTypeEnum, TicketStatusEnum } from '../../../types/api/v2/obt/model';
import type {
  IAirSearchResponse,
  IFlightLayoverInfo,
  IFlightLegDetails,
  IFlightLegSummary,
  IItineraryFareInfoEnhanced,
} from '../../../types/flight';
import type { IGetCancellationProps } from '../../../types/Flights/pnrCancellation';
import type { IsRefundableEnum, ITripDetails, ITripPassengersFareBreakup } from '../../../types/trip';
import { ITripPnrStatusEnum } from '../../../types/trip';
import { MoneyUtil } from '../../Money';
import { getCancelViaSupportBannerText } from '../../trips';

const getAmount = (amount?: number): number => amount ?? 0;

export const getCancelManualBookingReqBody = (
  data: ManualFormReadResponse,
  refundAmount: MoneyUtil,
): ManualFormReadResponse =>
  produce(data, (draft: Draft<ManualFormReadResponse>): void => {
    const { manualFormData } = draft;
    if (manualFormData?.airPnr?.legs) {
      manualFormData.airPnr.legs = [];
    }

    const numOfTravelers = manualFormData?.airPnr?.travelerInfos?.length || 1;
    const refundAmountPerTraveler = refundAmount.divide(numOfTravelers);
    const currency = refundAmount.getCurrency();

    if (manualFormData?.airPnr?.travelerInfos) {
      const { travelerInfos } = manualFormData?.airPnr;
      travelerInfos.forEach(({ booking, tickets }) => {
        const bookingClone = booking;
        const ticketsClone = tickets;
        delete bookingClone.seats;
        delete bookingClone.otherCharges;
        const totalFare = bookingClone.itinerary?.totalFare;
        if (totalFare) {
          totalFare.base.amount = 0;
          totalFare.tax.amount = 0;
        }
        if (ticketsClone) {
          ticketsClone.forEach((ticket) => {
            const ticketClone = ticket;
            delete ticketClone.flightCoupons;
            ticketClone.status = TicketStatusEnum.Refunded;
            ticketClone.refundInfo = {
              refundAmount: {
                base: {
                  amount: getAmount(refundAmountPerTraveler.getAmount()),
                  currencyCode: currency,
                },
                tax: { amount: 0, currencyCode: currency },
              },
            };
          });
        }
      });
    }
    if (!refundAmount.getAmount()) {
      return;
    }

    manualFormData.costOfGoodsSold?.payments.forEach(({ amount }) => {
      if (!amount) {
        return;
      }

      const { base, tax } = amount;

      const taxMoney = MoneyUtil.create(getAmount(tax.amount), currency);
      const baseMoney = MoneyUtil.create(getAmount(base.amount), currency);
      base.amount = baseMoney.add(taxMoney).subtract(refundAmountPerTraveler).getAmount();
      base.convertedAmount = base.amount;
      tax.amount = 0;
      tax.convertedAmount = tax.amount;
    });

    if (!manualFormData.costToCustomer) {
      return;
    }

    manualFormData.costToCustomer.payments.forEach(({ amount, paymentType }) => {
      if (paymentType === PaymentPaymentTypeEnum.ServiceFee || !amount) {
        return;
      }
      const { base, tax } = amount;
      const taxMoney = MoneyUtil.create(getAmount(tax.amount), currency);
      const baseMoney = MoneyUtil.create(getAmount(base.amount), currency);
      base.amount = baseMoney.add(taxMoney).subtract(refundAmountPerTraveler).getAmount();
      base.convertedAmount = base.amount;
      tax.amount = 0;
      tax.convertedAmount = tax.amount;
    });
  });

export const getTripDetailsResponseManager = (
  tripDetailsResponse: ITripDetails | undefined,
): TripDetailsResponseManager | null => {
  if (!tripDetailsResponse) {
    return null;
  }
  const tripDetailsResponseManager = new TripDetailsResponseManager(tripDetailsResponse);
  return tripDetailsResponseManager;
};

export const getAirPnr = (
  tripDetailsResponseManager: TripDetailsResponseManager | null,
  pnrId: string,
): {
  airDetails: IAirSearchResponse;
  isCancelled: boolean;
  totalAmount: MoneyUtil;
  pnr: Pnr;
} | null => {
  if (!tripDetailsResponseManager) {
    return null;
  }
  const pnrs = tripDetailsResponseManager.GetPnrDetailsObject();
  const airPnr = pnrs && pnrs[String(pnrId)];
  if (!airPnr) {
    return null;
  }

  // eslint-disable-next-line no-restricted-syntax
  const splitPnrs = V2TripDetailsResponseManager.SplitAirPnrIfNeeded(airPnr);
  // GetAirPnrCardDetails returns totalFare with currency as traveler currency and original currency as the booking currency
  // eslint-disable-next-line no-restricted-syntax
  const { totalFare } = V2TripDetailsResponseManager.GetAirPnrCardDetails(splitPnrs[0]);

  // In refund form amount input is created with original currency, this is done to make totalAmount currency same as the orignal currency
  const totalAmount = MoneyUtil.create(totalFare.getOriginalAmount(), totalFare.getOriginalCurrency());

  const airDetails = airPnr?.air?.air;
  const isCancelled =
    airPnr.status === ITripPnrStatusEnum.CANCELLED ||
    airPnr.status === ITripPnrStatusEnum.CANCELLED_BY_VENDOR ||
    airPnr.status === ITripPnrStatusEnum.VOIDED;
  if (!airDetails) {
    return null;
  }
  return { airDetails, isCancelled, totalAmount, pnr: airPnr };
};

interface IGetNormalizedFlightDetailV2Props {
  airDetails?: IAirSearchResponse | null;
  sourcePnrId: string;
  multiPaxAirPnrManager: MultiPaxAirPnrManager | null;
  ndcEnabled: boolean;
}

export interface INormalizedFlightDetailsV2 {
  departureDate: string;
  isRefundable: IsRefundableEnum;
  isExchangeable: boolean;
  cancelType: AirCancelPnrRequestCancelType;
  pnrVoidPolicy: AirPnrVoidPolicy | undefined;
  itineraries: {
    summary: IFlightLegSummary;
    legDetails: IFlightLegDetails;
    legFlightDetails: IFlightLayoverInfo[];
    fareInfoEnhanced: IItineraryFareInfoEnhanced[];
  }[];
  passengersFareBreakup: ITripPassengersFareBreakup[];
  totalFlightPrice: MoneyUtil;
  totalRefund: MoneyUtil;
  totalAirlineCredit: MoneyUtil;
  merchantFee: MoneyUtil;
}

export const getNormalizedFlightDetailsV2 = ({
  airDetails,
  multiPaxAirPnrManager,
  sourcePnrId,
}: Omit<IGetNormalizedFlightDetailV2Props, 'ndcEnabled'>): INormalizedFlightDetailsV2 | null => {
  if (!airDetails || !multiPaxAirPnrManager) {
    return null;
  }

  const airSearchResponseManager = new AirSearchResponseManager(airDetails);
  const itineraryAllLegs = multiPaxAirPnrManager.GetItineraryAllLegs(String(sourcePnrId));
  const itineraries = itineraryAllLegs.map(({ legNumber, legIndex }) => {
    const summary = airSearchResponseManager.GetLegSummaryForTrips(0, legNumber, legIndex);
    const legDetails = airSearchResponseManager.GetLegDetailsForTrips(0, legNumber, legIndex);
    const legFlightDetails = airSearchResponseManager.GetLegFlightDetails(0, legIndex);
    const fareInfoEnhanced = airSearchResponseManager.getAllFaresForItineraryIndexEnhanced(0, legNumber);

    return { summary, legDetails, legFlightDetails, fareInfoEnhanced };
  });

  const departureDate = first(itineraries)?.summary.departureDateTime ?? '';
  const pnrVoidPolicy = multiPaxAirPnrManager.GetAirPnrVoidPolicy(sourcePnrId);
  const isRefundable = multiPaxAirPnrManager.IsPnrRefundable(sourcePnrId, 0);
  const isExchangeable = multiPaxAirPnrManager.IsPnrExchangeable(sourcePnrId, 0);
  const cancelType = pnrVoidPolicy?.isVoidable
    ? AirCancelPnrRequestCancelType.VOID
    : AirCancelPnrRequestCancelType.CANCEL;

  const passengersFareBreakup = multiPaxAirPnrManager.getPassengersFareBreakup(sourcePnrId);
  const totalRefund = multiPaxAirPnrManager.GetTotalRefund(sourcePnrId);
  const totalAirlineCredit = multiPaxAirPnrManager.GetTotalAirlineCredit(sourcePnrId);
  const totalFlightPrice = multiPaxAirPnrManager.GetTotalFlightPrice(sourcePnrId);

  const merchantFee = airSearchResponseManager.GetMerchantFee(0);

  return {
    departureDate,
    isRefundable,
    isExchangeable,
    cancelType,
    pnrVoidPolicy,
    itineraries,
    passengersFareBreakup,
    totalFlightPrice,
    totalRefund,
    totalAirlineCredit,
    merchantFee,
  };
};

export const getCancellationBannerText = ({
  isNonRefundableNonExchangeable,
  hasMultipleRefundModes,
  isSeat1ATraveler,
  cancellationState,
  cancellationNotSupportedPriorityReasons,
  isAgent,
}: IGetCancellationProps) => {
  let message = '';

  if (!cancellationState) {
    return '';
  }

  const priorityReasonText = getCancelViaSupportBannerText(cancellationNotSupportedPriorityReasons, isAgent);

  if (priorityReasonText) {
    return priorityReasonText;
  }

  if (isNonRefundableNonExchangeable) {
    message = defineMessage('This booking is non-refundable and non-exchangeable.');
  }

  if (cancellationState === AirCancellationState.NonCancellable) {
    if (isSeat1ATraveler) {
      message = defineMessage(
        'Online cancellation is not available for this flight. Please contact support for assistance.',
      );
    } else {
      message = defineMessage(
        'This booking is non-refundable. Please contact the airline directly for more information.',
      );
    }
  }

  if (cancellationState === AirCancellationState.CancellableBySupport) {
    if (hasMultipleRefundModes) {
      message = defineMessage(
        'Please select your refund mode and submit your cancellation request. Our agents will process this for you in the next few hours.',
      );
    } else {
      message = defineMessage(
        'Please submit your cancellation request. Our agents will process this for you in the next few hours.',
      );
    }
  }

  if (cancellationState === AirCancellationState.CancellationByAgentRequested) {
    message = defineMessage(
      'Your cancellation request has been submitted, please check back in a few hours. You will receive an email when the booking is successfully canceled.',
    );
  }

  if (cancellationState === AirCancellationState.CancellationInProgress) {
    message = defineMessage(
      'Your cancellation is in progress.You will receive an email when the booking is successfully canceled.',
    );
  }

  if (cancellationState === AirCancellationState.CancellationInfoNotAvailable) {
    message = defineMessage(
      'Online cancellation is not available for this flight. Please contact support for assistance.',
    );
  }
  return message;
};
