import React, { useRef } from 'react';

import {
  SelectStore,
  ComboboxStore,
  SelectList,
  ComboboxList,
  ComboboxPopover,
  ComboboxListProps,
} from '@ariakit/react';
import { useClickOutside } from '@react-hookz/web/cjs/useClickOutside';
import { items_list_wrapper, popover_wrapper } from '../SelectRoot.styles';
import { useSelectConfig } from '../SelectConfigContext';
import { fixPopoverEscapeKeyDown } from '../../utils/ariaKitFixes';

export function ComboboxListWrapper<T extends SelectStore>({
  children,
  renderInPlace,
  selectStore,
  comboboxStore,
  ...rest
}: React.PropsWithChildren<
  {
    renderInPlace?: boolean;
    selectStore?: T;
    comboboxStore: NonNullable<ComboboxStore & { placeholder?: string }>;
  } & Omit<ComboboxListProps, 'store'>
>) {
  const popoverRef = useRef<HTMLDivElement>(null);
  const { portal, fixed, sameWidth, popoverProps, containerRef } = useSelectConfig();

  // Although this feature is natively supported via `hideOnInteractOutside` prop, it is not working as expected.
  // So we manually attach a listener for the same

  useClickOutside(popoverRef, (event) => {
    if (!containerRef?.current?.contains(event.target as Node)) {
      comboboxStore.setOpen(false);
    }
  });

  if (!selectStore) {
    return null;
  }

  const comboboxList = (
    <ComboboxList
      css={items_list_wrapper}
      store={comboboxStore}
      className="BlocksSelect-List"
      // autocomplete keyboard UX to be like Select, otherwise screenreader keeps announcing "Selected" on each key
      render={(props) => <SelectList store={selectStore} {...props} />}
      {...rest}
    >
      {children}
    </ComboboxList>
  );

  if (renderInPlace) {
    return React.cloneElement(comboboxList, {
      className: `${(comboboxList.props as { className: string }).className} is-in-place`,
      alwaysVisible: true,
    });
  }

  return (
    <ComboboxPopover
      ref={popoverRef}
      store={comboboxStore}
      sameWidth={sameWidth}
      gutter={8}
      className="BlocksSelect-Popover"
      portal={portal}
      fixed={fixed}
      css={popover_wrapper}
      hideOnEscape={fixPopoverEscapeKeyDown}
      {...popoverProps}
    >
      {comboboxList}
    </ComboboxPopover>
  );
}
