import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../api';
import type SpotnanaError from '../api/SpotnanaError';
import type {
  ListRingsResponse,
  MoveUserToRingRequest,
  SpotnanaQueryResult,
  SpotnanaQueryMutationResult,
} from '../types';
import { defaultQueryClient } from './defaultQueryClient';

const listRingsKey = (): unknown[] => ['list-rings'];
const listUserRingsKey = (userId: string): unknown[] => ['list-user-rings', userId];

const fetchRingList = async (): Promise<ListRingsResponse> => {
  const data = await api('GET', 'listRings');
  return data as ListRingsResponse;
};

const fetchUserRingList = async (userEmailAddress: string): Promise<ListRingsResponse> => {
  const data = await api('GET', 'listUserRings', { params: { userEmailAddress } });
  return data as ListRingsResponse;
};

const moveUserToRing = async (request: MoveUserToRingRequest): Promise<ListRingsResponse> => {
  const data = await api('POST', 'moveUserToRing', { data: request });
  return data as ListRingsResponse;
};

export const useRingListQuery = (): SpotnanaQueryResult<ListRingsResponse> =>
  useQuery<ListRingsResponse, SpotnanaError>({
    queryKey: listRingsKey(),
    queryFn: () => fetchRingList(),
  });

export const useUserRingListQuery = (userEmailAddress: string): SpotnanaQueryResult<ListRingsResponse> =>
  useQuery<ListRingsResponse, SpotnanaError>({
    queryKey: listUserRingsKey(userEmailAddress),

    queryFn: () => fetchUserRingList(userEmailAddress),
  });

export const useMoveUserToRing = (): SpotnanaQueryMutationResult<ListRingsResponse, MoveUserToRingRequest> =>
  useMutation({
    mutationFn: (request: MoveUserToRingRequest) => moveUserToRing(request),
    onSuccess: (_data, requestBody) => {
      if (requestBody.userEmailAddress) {
        defaultQueryClient.invalidateQueries({
          queryKey: listUserRingsKey(requestBody.userEmailAddress),
        });
      }
    },
  });
