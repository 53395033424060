import { useMutation, useQuery } from '@tanstack/react-query';

import pickBy from 'lodash/pickBy';
import isUndefined from 'lodash/isUndefined';
import type {
  CreatePaymentSourceRequest,
  CreatePaymentSourceResponse,
  GetPaymentSourceResponse,
  IUserOrgId,
  ListAllPaymentSourcesResponse,
  ListPaymentInfosRequest,
  ListPaymentInfosResponse,
  SpotnanaQueryMutationResult,
  SpotnanaQueryResult,
  UpdatePaymentSourceRequest,
  UpdatePaymentSourceResponse,
} from '../../types';
import SpotnanaError from '../../api/SpotnanaError';
import api from '../../api';
import { defaultQueryClient } from '../defaultQueryClient';

export interface IBaseUseListPaymentInfos {
  /**
   * @deprecated Use userId
   */
  includePersonal?: boolean;
  /**
   * @deprecated Use centalCardOrgId
   */
  includeCentral?: boolean;
  centalCardOrgId?: string;
  tmcId?: string;
  enabled?: boolean;
}

export interface IOldUserParams {
  userOrgId?: IUserOrgId;
  includePersonal?: boolean;
  includeCentral?: boolean;
}

export interface INewUserParams {
  userId?: string;
}

export type IUseListPaymentInfos = IBaseUseListPaymentInfos & (IOldUserParams | INewUserParams);

export type IUseListPaymentSources = { enabled?: boolean } & (
  | { companyId: string; tmcId?: string }
  | { userId: string }
);

export type IUseUserPaymentSources = {
  userId: string;
  enabled?: boolean;
};

const listPaymentInfoBaseKey = 'list-payment-infos';
const listPaymentInfosKey = (req: IUseListPaymentInfos): unknown[] => {
  const { centalCardOrgId, tmcId } = req;

  let userId: string | undefined;
  if ('userOrgId' in req) {
    userId = req.userOrgId?.userId?.id;
  }
  if ('userId' in req) {
    userId = req.userId;
  }

  return [listPaymentInfoBaseKey, userId, centalCardOrgId, tmcId];
};

export const fetchPaymentInfo = async (
  request: ListPaymentInfosRequest,
): Promise<ListPaymentInfosResponse | undefined> =>
  api('POST', 'listPaymentInfos', {
    data: request,
  }) as Promise<ListPaymentInfosResponse | undefined>;

export const useListPaymentInfos = ({
  includePersonal,
  includeCentral,
  tmcId,
  centalCardOrgId,
  enabled = true,
  ...rest
}: IUseListPaymentInfos): SpotnanaQueryResult<ListPaymentInfosResponse | undefined> =>
  useQuery<ListPaymentInfosResponse | undefined, SpotnanaError>({
    queryKey: listPaymentInfosKey({ includePersonal, includeCentral, tmcId, centalCardOrgId, ...rest }),
    queryFn: async () => {
      const request: ListPaymentInfosRequest = {
        includeCentral,
        includePersonal,
        ...(tmcId
          ? {
              includeTmcCards: {
                tmcId,
              },
            }
          : {}),
        ...(centalCardOrgId
          ? {
              includeCentralCards: {
                organizationId: centalCardOrgId,
              },
            }
          : {}),
      };

      if ('userId' in rest) {
        request.includePersonalCards = {
          userId: rest.userId,
        };
      }

      if ('userOrgId' in rest) {
        request.userOrgId = rest.userOrgId;
      }

      const paymentInfos = await fetchPaymentInfo(request);
      return paymentInfos;
    },
    enabled,
  });
export const invalidateUseListPaymentInfos = (userId?: string): void => {
  defaultQueryClient.invalidateQueries({
    queryKey: [listPaymentInfoBaseKey, userId],
  });
};

interface ListPaymentSourcesKeyProps {
  companyId?: string;
  tmcId?: string;
  userId?: string;
}
const listPaymentSourcesKey = ({ companyId, tmcId, userId }: ListPaymentSourcesKeyProps): unknown[] => [
  'list-payment-sources',
  companyId,
  tmcId,
  userId,
];

export const fetchPaymentSources = async (
  queryKeys: ListPaymentSourcesKeyProps,
): Promise<ListAllPaymentSourcesResponse | undefined> => {
  const queryParams = new URLSearchParams(pickBy(queryKeys, (value) => !isUndefined(value)));
  return api('GET', 'paymentSources', {
    urlParam: `?${queryParams.toString()}`,
  }) as ListAllPaymentSourcesResponse | undefined;
};

export const useListPaymentSources = ({
  enabled = true,
  ...queryFields
}: IUseListPaymentSources): SpotnanaQueryResult<ListAllPaymentSourcesResponse | undefined> => {
  // build query keys
  const queryKeys = {
    companyId: 'companyId' in queryFields ? queryFields.companyId : undefined,
    tmcId: 'tmcId' in queryFields ? queryFields.tmcId : undefined,
    userId: 'userId' in queryFields ? queryFields.userId : undefined,
  };

  return useQuery({
    queryKey: listPaymentSourcesKey(queryKeys),
    queryFn: () => fetchPaymentSources(queryKeys) as Promise<ListAllPaymentSourcesResponse | undefined>,
    enabled,
  });
};

const deletePaymentSource = async (paymentSourceId: string): Promise<boolean> => {
  try {
    await api('DELETE', 'paymentSources', {
      urlParam: `/${paymentSourceId}`,
    });
    return true;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useDeletePaymentSources = ({
  companyId,
  travelerUserId,
}: {
  companyId: string;
  travelerUserId: string | null;
}): SpotnanaQueryMutationResult<boolean, { paymentSourceId: string }> =>
  useMutation({
    mutationFn: ({ paymentSourceId }: { paymentSourceId: string }) => deletePaymentSource(paymentSourceId),
    onSuccess() {
      defaultQueryClient.invalidateQueries({ queryKey: listPaymentSourcesKey({ companyId }) });
      if (travelerUserId) {
        defaultQueryClient.invalidateQueries({
          queryKey: ['travelerUnusedCredits', travelerUserId],
        });
      }
    },
  });

const createPaymentResource = async (
  request: CreatePaymentSourceRequest,
): Promise<CreatePaymentSourceResponse | undefined> => {
  try {
    const response = (await api('POST', 'paymentSources', { data: request })) as CreatePaymentSourceResponse;
    return response;
  } catch (error) {
    throw new SpotnanaError('Payment method creation failed');
  }
};

type InvalidatePaymentSourcesListParams =
  | {
      companyId: string;
      tmcId?: string;
    }
  | { userId: string };

export const invalidatePaymentSourcesList = (params: InvalidatePaymentSourcesListParams) => {
  defaultQueryClient.invalidateQueries({ queryKey: listPaymentSourcesKey(params) });
};

interface UseCreatePaymentSourceMutationParams {
  companyId: string;
  travelerUserId: string | null;
}

export const useCreatePaymentSourceMutation = ({
  companyId,
  travelerUserId,
}: UseCreatePaymentSourceMutationParams): SpotnanaQueryMutationResult<
  CreatePaymentSourceResponse | undefined,
  CreatePaymentSourceRequest
> =>
  useMutation({
    mutationFn: (request: CreatePaymentSourceRequest) => createPaymentResource(request),
    async onSuccess() {
      await invalidatePaymentSourcesList({ companyId });
      if (travelerUserId) {
        await defaultQueryClient.invalidateQueries({
          queryKey: ['travelerUnusedCredits', travelerUserId],
        });
      }
    },
  });

const updatePaymentResource = async (
  request: UpdatePaymentSourceRequest,
  paymentSourceId: string,
): Promise<UpdatePaymentSourceResponse | undefined> => {
  try {
    const response = (await api('PUT', 'paymentSources', {
      urlParam: `/${paymentSourceId}`,
      data: request,
    })) as UpdatePaymentSourceResponse;
    return response;
  } catch (error) {
    throw new SpotnanaError('Payment method creation failed');
  }
};

interface UseUpdatePaymentSourceMutationParams {
  paymentSourceId: string;
  companyId: string;
  travelerUserId: string | null;
}

export const useUpdatePaymentSourceMutation = ({
  paymentSourceId,
}: UseUpdatePaymentSourceMutationParams): SpotnanaQueryMutationResult<
  UpdatePaymentSourceResponse | undefined,
  UpdatePaymentSourceRequest
> =>
  useMutation({
    mutationFn: (request: UpdatePaymentSourceRequest) => updatePaymentResource(request, paymentSourceId),
  });

/**
 * Payment Sources CRUD API
 */

export interface IUseGetPaymentSourceParams {
  paymentSourceId: string | undefined;
  enabled: boolean;
}
const getPaymentSourceKey = (paymentSourceId: string | undefined): unknown[] => ['get-payment-source', paymentSourceId];

const getPaymentSource = async (paymentSourceId: string | undefined): Promise<GetPaymentSourceResponse | undefined> =>
  api('GET', 'paymentSources', { urlParam: `/${paymentSourceId}` }) as Promise<GetPaymentSourceResponse | undefined>;

export const useGetPaymentSourceQuery = ({ paymentSourceId, enabled }: IUseGetPaymentSourceParams) =>
  useQuery<GetPaymentSourceResponse | undefined, SpotnanaError>({
    queryKey: getPaymentSourceKey(paymentSourceId),

    queryFn: async () => {
      const response = (await getPaymentSource(paymentSourceId)) as GetPaymentSourceResponse | undefined;
      return response;
    },

    enabled,
  });
