import { defineMessage } from '../translations/defineMessage';

type IErrorMessages = {
  common: {
    [k in
      | 'LEADING_TRAILING_WHITESPACE'
      | 'TIME_LIMIT_SHOULD_BE_BETWEEEN_1_24_HRS'
      | 'MULTIPLE_CONSEUCTIVE_WHITESPACE'
      | 'TIME_LIMIT_SHOULD_BE_BETWEEEN_0_POINT_5_24_HRS'
      | 'TIME_LIMIT_MULTIPLE_OF_0_POINT_5']: string;
  };
  hotel: {
    [k in 'EMPTY' | 'INVALID_FORMAT']: {
      [k: string]: string;
    };
  };
  cars: {
    [k in 'EMPTY' | 'INVALID_COMBINATION']: {
      [k: string]: string;
    };
  };
  limo: {
    [k in 'EMPTY' | 'INVALID_COMBINATION']: {
      [k: string]: string;
    };
  };
  rails: {
    [k in 'EMPTY' | 'INVALID_COMBINATION']: {
      [k: string]: string;
    };
  };
  traveler: {
    [k in 'EMPTY' | 'LENGTH' | 'INVALID_FORMAT' | 'DUPLICATE']: {
      [k: string]: string;
    };
  };
  trip: {
    [k in 'EMPTY']: {
      [k: string]: string;
    };
  };
  admin: {
    [k in 'EMPTY' | 'INVALID_FORMAT' | 'DUPLICATE' | 'LENGTH' | 'DUPLICATES' | 'OTHER']: {
      [k: string]: string;
    };
  };
};

const EMPTY_FIELD_RESTRICTION = 'Empty value is not allowed';
export const getEmptyError = (fieldName: string): string => `${fieldName} is required`;
export const getInvalidError = (fieldName: string): string => `Invalid ${fieldName}`;

// TODO: The errors should be localised
const errorMessages: IErrorMessages = {
  common: {
    LEADING_TRAILING_WHITESPACE: 'Leading or trailing spaces not allowed',
    MULTIPLE_CONSEUCTIVE_WHITESPACE: 'Multiple consecutive spaces not allowed',
    TIME_LIMIT_SHOULD_BE_BETWEEEN_1_24_HRS: 'Time limit should be between 1hr and 24hrs',
    TIME_LIMIT_SHOULD_BE_BETWEEEN_0_POINT_5_24_HRS: 'Time limit should be between 0.5hr and 24hrs',
    TIME_LIMIT_MULTIPLE_OF_0_POINT_5: 'Please enter increase/decrease by .5',
  },
  hotel: {
    EMPTY: {
      LOCATION: getEmptyError('Location'),
    },
    INVALID_FORMAT: {
      TO_AFTER_FROM: 'Check-out date should be atleast 1 day after Check-in date',
      PAST: defineMessage('Check-in date is in the past'),
    },
    // other types of validations and messages
  },
  cars: {
    EMPTY: {
      LOCATION: getEmptyError('Location'),
    },
    INVALID_COMBINATION: {
      TIME: 'Drop-off time must be after the pick-up time for same-day rentals',
      PAST: defineMessage('Your journey is in the past'),
    },
  },
  rails: {
    EMPTY: {
      LOCATION: getEmptyError('Location'),
    },
    INVALID_COMBINATION: {
      TIME: defineMessage('For round trips, your return journey must commence after your outbound journey is complete'),
      TIME_EXCHANGE: defineMessage('This journey must commence after your previous journey is complete'),
      LOCATION: defineMessage('Destination may not be the same as the origin'),
      PAST: defineMessage('Your journey is in the past, local time'),
      OUTSIDE_RANGE: defineMessage('Selected date or time is outside of the allowed range'),
      THIRD_PARTY: defineMessage(
        'No route available between selected stations. Please select a different origin or destination',
      ),
      OPEN_RETURN_NOT_AVAILABLE: defineMessage(
        'Open Return not available for selected stations. Please select Round trip or One way',
      ),
    },
  },
  limo: {
    EMPTY: {
      LOCATION: getEmptyError('Location'),
    },
    INVALID_COMBINATION: {
      TIME: 'Drop-off time must be after the pick-up time for same-day rentals',
      PAST: defineMessage('Your journey is in the past'),
    },
  },
  traveler: {
    EMPTY: {
      TITLE: getEmptyError('Title'),
      FIRST_NAME: getEmptyError('First Name'),
      LAST_NAME: getEmptyError('Last Name'),
      GENDER: getEmptyError('Gender'),
      DOB: getInvalidError('date of birth'),
      PHONE_NUMBER: getEmptyError('Phone number'),
      COUNTRY_CODE_PHONE: getEmptyError('Country/Region code'),
      EMAIL: getEmptyError('Email'),
      ADDRESS_NAME: getEmptyError('Address name'),
      ADDRESS_LINE1: getEmptyError('Address Line 1'),
      CITY: getEmptyError('City'),
      COUNTRY: getEmptyError('Country/Region'),
      POSTALCODE: getEmptyError('Postal/zipcode'),
      PASSPORT_ID: getEmptyError('Passport No.'),
      NATIONAL_ID: getEmptyError('National ID'),
      ISSUE_COUNTRY: getEmptyError('Issuing country/region'),
      NATIONAL_ID_TYPE: getEmptyError('National id type'),
      NATIONALITY_COUNTRY: getEmptyError('Nationality country/region'),
      PASSPORT_EXPIRY: getInvalidError('expiry date'),
      PAYMENT_CARD_NAME: getEmptyError('Name'),
      PAYMENT_CARD_COMPANY: getEmptyError('Card company'),
      PAYMENT_CARD_NUMBER: getEmptyError('Card number'),
      PAYMENT_CARD_EXPIRY_MONTH: getEmptyError('Month'),
      PAYMENT_CARD_EXPIRY_YEAR: getEmptyError('Year'),
      PAYMENT_CARD_CVV: getEmptyError('cvv'),
      PAYMENT_CARD_APPLICABLE_TO: getEmptyError('At least one'),
      PAYMENT_CARD_LABEL: getEmptyError('Card label'),
      LOYALTY_PROGRAM: getEmptyError('Program name'),
      LOYALTY_CARD_ID: getEmptyError('Program number'),
      ATLEAST_ONE_DEFAULT_ADDRESS: getEmptyError('One default address'),
      NO_COUNTRY_CODE: getEmptyError('Country/Region code'),
      KNOWN_TRAVELER_NUM: getEmptyError('Known traveler number'),
      REDRESS_NUM: getEmptyError('Redress number'),
      CURRENCY_CODE: getEmptyError('Currency'),
      STATE: getEmptyError('State/Province'),
      OWNERSHIP_LABEL: getEmptyError('Card type'),
    },
    LENGTH: {
      LAST_NAME: 'Last name should be at least 2 characters',
      PASSPORT_NUMBER: 'Passport number should have maximum 25 characters',
      NATIONAL_ID_NUMBER: 'National ID should have maximum 29 characters',
      LOYALTY_CARD_ID: 'Program number should have maximum 30 characters',
    },
    INVALID_FORMAT: {
      PAYMENT_CARD_EXPIRY_MONTH: 'Month should be between 1-12',
      PAYMENT_CARD_EXPIRY_YEAR: 'Year should be 4 digits',
      PAYMENT_CARD_CVV: 'cvv should be 3 or 4 digits',
      EMAIL: 'Invalid Email',
      POSTAL_CODE: 'Invalid postal/zipcode',
      NAME: 'No special characters allowed',
      REDRESS_NUMBER: 'Redress number should have numeric values only',
      KNOWN_TRAVELER_NUMBER: 'Known traveler number should have alphanumeric values only',
      PASSPORT_NUMBER: 'Passport number should have alphanumeric values only',
      NATIONAL_ID_NUMBER: 'National ID should have alphanumeric values only',
      LOYALTY_CARD_ID: 'Program number should have alphanumeric values only',
      PAX_TYPE_INFANT_DATE: 'Age should be less than 2 years',
      PAX_TYPE_CHILD_DATE: 'Age should be between 2 and 18 years',
      PAX_TYPE_ADULT_DATE: 'Age should be greater than 18 years',
      PASSPORT_EXPIRY_DATE: 'Passport cannot have same issue and expiry date',
      NATIONAL_ID_EXPIRY_DATE: 'National ID cannot have same issue and expiry date',
    },
    DUPLICATE: {
      PAYMENT_CARD_LABEL: 'A card with this label already exists. Please update the card label and try again',
      LOYALTY_PROGRAM: 'Loyalty program already exists',
      PASSPORT_NUMBER: 'Passport with this number already exist',
      NATIONAL_ID_NUMBER: 'National ID with this number already exist',
    },
  },
  trip: {
    EMPTY: {
      TRIP_NAME: getEmptyError('Trip name'),
    },
  },
  admin: {
    EMPTY: {
      COMPANY_NAME: getEmptyError('Company name'),
      EMAIL_DOMAINS: getEmptyError('Email domain'),
      WORK_EMAIL: getEmptyError('Work email'),
      BILLING_CURRENCY: getEmptyError('Billing currency'),
      EMERGENCY_CONTACT_DESIGNATION: getEmptyError('Designation'),
      OFFICE_NAME: getEmptyError('Office name'),
      LEGAL_ENTITY_FOR_OFFICE: getEmptyError('Legal entity'),
      LEGAL_ENTITY_NAME: getEmptyError('Legal entity name'),
      TAX_NUMBER: getEmptyError('Tax number'),
      DEPARTMENT_NAME: getEmptyError('Department name'),
      COST_CENTER_NAME: getEmptyError('Cost center name'),
      AUTH_APPLICATION_ID: getEmptyError('Application ID'),
      TOKEN_ENDPOINT: getEmptyError('Token endpoint'),
      POLICY_NAME: getEmptyError('Policy group name'),
      POLICY_APPROVER: getEmptyError('Policy approver'),
      POLICY_DEFAULT_APPROVER: getEmptyError('Default approver'),
      POLICY_HOTEL_ADVANCE_BOOKING_WINDOW: getEmptyError('Booking window'),
      POLICY_HOTEL_MAX_PRICE_BY_LOCATION_ADDRESS: getEmptyError('Address'),
      POLICY_CABIN_CLASS: getEmptyError('Cabin class'),
      EMPLOYEE_ID: getEmptyError('Employee id'),
      USC_ID: getEmptyError('USC Id code'),
      TOUR_CODE: getEmptyError('Tour code'),
      SNAP_CODE: getEmptyError('Snap code'),
      UATP_EXPIRY_DATE: getEmptyError('Expiration date'),
      PCC_LABEL: getEmptyError('PCC Label'),
      PCC_NUMBER: getEmptyError('PCC number'),
      DK_NUMBER: getEmptyError('Dk number'),
      IATA_NUMBER: getEmptyError('Iata number'),
      TIME_ZONE: getEmptyError('Timezone'),
      AWS_KEY: getEmptyError('Credentials'),
      PRIMARY_QUEUE: getEmptyError('Primary queue number'),
      SECONDARY_QUEUE: getEmptyError('Secondary queue number'),
      CURRENCY_CODE: getEmptyError('Currency'),
      TMC_ID: getEmptyError('TMC'),
      TRAVEL_TYPE: getEmptyError('Travel type'),
      PCC: getEmptyError('PCC'),
      AIRLINE: getEmptyError('Airline'),
      FILTER_ID: getEmptyError('Filter ID'),
      TEMPLATE_ID: getEmptyError('Template ID'),
      L1REMARKS: EMPTY_FIELD_RESTRICTION,
      PNR_REMARKS: EMPTY_FIELD_RESTRICTION,
      AIR_TOUR_CODES: EMPTY_FIELD_RESTRICTION,
      AIR_CTID: EMPTY_FIELD_RESTRICTION,
      AIR_CLID3: EMPTY_FIELD_RESTRICTION,
      AIR_CLID4: EMPTY_FIELD_RESTRICTION,
      CAR_CODE: EMPTY_FIELD_RESTRICTION,
      HOTEL_RATE_CODE: EMPTY_FIELD_RESTRICTION,
      REMARK_CODE: getEmptyError('Remark code'),
      REMARK_TYPE: getEmptyError('Remark type'),
      REASON_CODE_AIR: getEmptyError('Air reason code'),
      REASON_CODE_CAR: getEmptyError('Car reason code'),
      REASON_CODE_HOTEL: getEmptyError('Hotel reason code'),
      REASON_CODE_RAIL: getEmptyError('Hotel reason rail'),
      CABIN_CLASS_NOT_ALLOWED_DOMESTIC_AIR: getEmptyError('Cabin class not allowed domestic'),
      CABIN_CLASS_NOT_ALLOWED_INTERNATIONAL_AIR: getEmptyError('Cabin class not allowed international'),
      FLIGHT_CABIN_UPGRADE: EMPTY_FIELD_RESTRICTION,
      ECONOMY_FARE_NOT_ALLOWED_DOMESTIC_AIR: getEmptyError('Economy fare not allowed domestic'),
      ECONOMY_FARE_NOT_ALLOWED_INTERNATIONAL_AIR: getEmptyError('Economy fare not allowed international'),
      NOT_ALLOWED_HOTEL_TYPES: getEmptyError('Not allowed hotel types'),
      NOT_ALLOWED_CAR_TYPES: getEmptyError('Not allowed car types'),
      RAIL_HIGHEST_CLASS: getEmptyError('Highest class'),
      RAIL_MAXIMUM_PRICE: getEmptyError('Maximum price'),
      FLIGHT_LLF_AIRLINES_EMPTY: 'Should select at least 1 airline',
      HOTEL_MEDIAN_DISTANCE_EMPTY: getEmptyError('Median distance'),
      HOTEL_MEDIAN_DISTANCE_UNIT_EMPTY: getEmptyError('Median distance unit'),
      HOTEL_MEDIAN_MIN_RATING_EMPTY: getEmptyError('Min rating'),
      HOTEL_MEDIAN_MAX_RATING_EMPTY: getEmptyError('Max rating'),
      HOTEL_MEDIAN_TAX_FEE_INCLUDED_EMPTY: getEmptyError('Median tax fee included or excluded'),
      HOTEL_MEDIAN_BUFFER_THRESHOLD_EMPTY: getEmptyError('Buffer threshold'),
      HOTEL_MEDIAN_BUFFER_THRESHOLD_RATE_EMPTY: getEmptyError('Buffer threshold rate'),
      HOTEL_RESTRICT_KEYWORD: getEmptyError('Keyword'),
      HOTEL_RESTRICT_KEYWORD_REASON: getEmptyError('Reason'),
      COUNTRY: getEmptyError('Country/Region'),
      NUMBER_OF_EMPLOYEES: getEmptyError('Number of employees'),
      ESTIMATED_NUMBER_OF_TRAVELERS: getEmptyError('Estimated number of travelers'),
      ZIP: getEmptyError('ZIP/Postcode'),
      COMPANY_ADDRESS_LINE1: getEmptyError('Company address 1'),
      CITY: getEmptyError('City'),
    },
    INVALID_FORMAT: {
      EMAIL_DOMAINS: 'Domains can only include letters, numbers, hyphens, and must include at least 1 period.',
      EMPLOYEE_EMAIL: 'Email does not belong to the company domain',
      POLICY_RANGE: 'You have selected overlapping flight durations. Please update the duration ranges and try again',
      USC_ID: 'USC Id should have alphanumeric values only',
      TOUR_CODE: 'Tour code should have alphanumeric values only',
      SNAP_CODE: 'Snap code should have alphanumeric values only',
      UATP_EXPIRY_DATE: 'Invalid Expiration date',
      UATP_EXPIRY_DATE_RANGE: 'Expiry date should be greater than current date',
      START_TICKETING_DATE: 'Invalid Start Ticketing date',
      END_TICKETING_DATE: 'Invalid End Ticketing date',
      START_TRAVELING_DATE: 'Invalid Start Traveling date',
      END_TRAVELING_DATE: 'Invalid End Traveling date',
      CODE: 'Code is invalid',
      REMARK_CODE: 'Remark code should be alphanumeric',
      HOTEL_RESTRICT_KEYWORD: 'Keyword cannot include spaces or full stops',
      CLIENT_AUTHENTICATION_METHOD: 'Select client authentication method',
      CARBON_COST_CONFIG: 'Please provide all the details for carbon cost.',
      WORK_EMAIL: 'The email provided is invalid.',
      WORK_PHONE_NUMBER: 'The phone number provided is invalid.',
      COUNTRY_NOT_ALLOWED:
        'We regret to inform you that our services are not currently supported in your country/region.',
      COMPANY_WEBSITE: 'The company website provided is invalid.',
    },
    DUPLICATE: {
      EMAIL_DOMAINS: 'Two or more email domains are duplicate',
      EMAILS: 'Two or more emails are duplicate',
      DEPARTMENTS: 'A department with this name already exists',
      COST_CENTER: 'A cost center with this name already exists',
      HOTEL_RESTRICT_KEYWORD: 'A keyword with this already exists',
      HOTEL_RESTRICT_KEYWORD_INPUT: 'Two or more keywords are duplicate',
    },
    LENGTH: {
      EMAIL_DOMAINS: 'Email domains should be in between 3 to 67 characters',
      POLICY_USER_GROUPS: 'User group should have at least one condition',
      USC_ID: 'USC Id should have maximum 8 characters',
      TOUR_CODE: 'Tour code should have maximum 15 characters',
      SNAP_CODE: 'Snap code should have maximum 10 characters',
      REMARK_AIRLINE_CODES: 'At least 1 airline should be selected',
      TRAVEL_TYPE: 'At least 1 travel type should be selected',
    },
    DUPLICATES: {
      DUPLICATE_POLICY_NAME: 'Policy name exists',
    },
    OTHER: {
      DEFAULT_PCC: 'Default PCC must be mutually exclusive',
      MAX_FLIGHT_BOOKING_PRICE_POLICY: 'Both entries need to be added for the policy rule to be active',
      TERMS_OF_CONDITION: 'Terms & Conditions acceptance is required.',
    },
  },
};
export default errorMessages;
