import type AirPnrCancellationResponseManager from '../../services/AirPnrCancellationResponseManager';
import type { AirFareInfo, IRefundModeDetails, IServiceFeeData } from '../../types';
import type { RefundModeEnum, ICancellationFareBreakupInfo } from '../../types/Flights/pnrCancellation';
import { MoneyUtil } from '../Money';

interface IGetFareBreakupFromCancellationDetails {
  fareInfo: AirFareInfo;
  fareInfoIndex: number;
  newPenaltyAmount: MoneyUtil;
  selectedRefundModeInfo?: IRefundModeDetails;
  pnrCancellationResponseManager: AirPnrCancellationResponseManager;
  selectedRefundMode?: RefundModeEnum;
  serviceFees: IServiceFeeData;
}

export const getFareBreakupInfoFromCancellationDetails = ({
  fareInfo,
  fareInfoIndex,
  newPenaltyAmount,
  selectedRefundMode,
  selectedRefundModeInfo,
  pnrCancellationResponseManager,
  serviceFees,
}: IGetFareBreakupFromCancellationDetails): ICancellationFareBreakupInfo => {
  let totalRefund = selectedRefundModeInfo?.refundAmount ?? MoneyUtil.zeroMoney();
  let airlineCancellationFee = MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.cancellationFee.base).add(
    MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.cancellationFee.tax),
  );
  let airlineCreditValue = selectedRefundModeInfo?.creditAmount ?? MoneyUtil.zeroMoney();

  let totalDeductions = pnrCancellationResponseManager.getTotalDeductions(fareInfoIndex);

  if (!newPenaltyAmount.isZero()) {
    const penaltyDifference = airlineCancellationFee.subtract(newPenaltyAmount);
    totalRefund = totalRefund?.add(penaltyDifference);
    airlineCreditValue = airlineCreditValue.add(penaltyDifference);
    totalDeductions = totalDeductions.subtract(penaltyDifference);
    airlineCancellationFee = newPenaltyAmount;
  }

  const totalPaid = MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.totalFare.base).add(
    MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.totalFare.tax),
  );

  const showBrexPoints = Boolean(totalRefund?.getBrexPoints()?.amount);

  const airlineChangeFee = selectedRefundModeInfo?.changeFee ?? MoneyUtil.zeroMoney();
  return {
    totalPaid,
    totalDeductions,
    airlineCancellationFee,
    totalRefund,
    airlineCreditValue,
    airlineChangeFee,
    selectedRefundMode,
    fareInfo,
    showBrexPoints,
    serviceFees,
    cancellationState: pnrCancellationResponseManager.getCancellationState(),
  };
};
