/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * User facing booking status
 * @export
 * @enum {string}
 */

export const UserFacingStatus = {
    UnknownStatus: 'UNKNOWN_STATUS',
    PendingStatus: 'PENDING_STATUS',
    ConfirmedStatus: 'CONFIRMED_STATUS',
    ActiveStatus: 'ACTIVE_STATUS',
    CompletedStatus: 'COMPLETED_STATUS',
    CancelledStatus: 'CANCELLED_STATUS',
    RefundedStatus: 'REFUNDED_STATUS',
    VoidedStatus: 'VOIDED_STATUS',
    ProcessingStatus: 'PROCESSING_STATUS',
    UnconfirmedStatus: 'UNCONFIRMED_STATUS',
    AirlineControlStatus: 'AIRLINE_CONTROL_STATUS',
    PaymentDeclinedStatus: 'PAYMENT_DECLINED_STATUS',
    ScheduleChangeStatus: 'SCHEDULE_CHANGE_STATUS',
    HoldStatus: 'HOLD_STATUS',
    ApprovalRequestedStatus: 'APPROVAL_REQUESTED_STATUS',
    ApprovalDeniedStatus: 'APPROVAL_DENIED_STATUS',
    CancellationInProgressStatus: 'CANCELLATION_IN_PROGRESS_STATUS',
    InoperativeStatus: 'INOPERATIVE_STATUS',
    FlightUnconfirmedStatus: 'FLIGHT_UNCONFIRMED_STATUS'
} as const;

export type UserFacingStatus = typeof UserFacingStatus[keyof typeof UserFacingStatus];



