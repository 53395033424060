import React, { useRef } from 'react';

import { SelectStore, SelectList, SelectPopover, SelectListProps } from '@ariakit/react';
import { useClickOutside } from '@react-hookz/web/cjs/useClickOutside';
import { popover_wrapper, items_list_wrapper, select_wrapper, swipeable_drawer } from '../SelectRoot.styles';
import { useSelectConfig } from '../SelectConfigContext';
import { breakpoints, useMediaQuery } from '../../utils';
import { SwipeableDrawer } from '../../SwipeableDrawer';
import { fixPopoverEscapeKeyDown } from '../../utils/ariaKitFixes';

/**
 * Will be used to override whether to render options is a Select list component or Combobox list component, and whether to render in Popover or not.
 * When we use Select with search, the items must be rendered in ComboboxList.
 * Refer to https://org/examples/select-combobox
 */
export function SelectListWrapper<T extends SelectStore>({
  children,
  renderInPlace,
  selectStore,
  ...rest
}: React.PropsWithChildren<{ renderInPlace?: boolean; selectStore?: T } & Omit<SelectListProps, 'store'>>) {
  const isMobile = !useMediaQuery(breakpoints.from('tablet'));
  const open = selectStore?.useState().open;
  const { portal, fixed, sameWidth, popoverProps, containerRef } = useSelectConfig();
  const popoverRef = useRef<HTMLDivElement>(null);

  // Although this feature is natively supported via `hideOnInteractOutside` prop, it is not working as expected.
  // So we manually attach a listener for the same
  useClickOutside(popoverRef, (event) => {
    if (!containerRef?.current?.contains(event.target as Node)) {
      selectStore?.setOpen(false);
    }
  });

  if (!selectStore) return null;

  const selectList = (
    <SelectList
      store={selectStore}
      className="BlocksSelect-List"
      css={items_list_wrapper}
      alwaysVisible
      autoFocus={false}
      {...rest}
    >
      {children}
    </SelectList>
  );

  if (renderInPlace) {
    return React.cloneElement(selectList, {
      className: `${(selectList.props as { className: string }).className} is-in-place`,
    });
  }

  if (isMobile) {
    return (
      <SwipeableDrawer
        onClose={() => selectStore?.setOpen(false)}
        onOpen={() => selectStore?.setOpen(true)}
        anchor="bottom"
        kind="rounded"
        open={open}
        css={[select_wrapper, swipeable_drawer]}
      >
        {selectList}
      </SwipeableDrawer>
    );
  }

  return (
    <SelectPopover
      ref={popoverRef}
      store={selectStore}
      gutter={8}
      sameWidth={sameWidth}
      className="BlocksSelect-List"
      portal={portal}
      fixed={fixed}
      css={popover_wrapper}
      hideOnEscape={fixPopoverEscapeKeyDown}
      {...popoverProps}
    >
      {selectList}
    </SelectPopover>
  );
}
