import { useQuery } from '@tanstack/react-query';

import type { OrganizationBasicInfo } from '../../types/api/v1/obt/common/organization';
import type { ListOrganizationsResponse } from '../../types/api/v1/obt/profile/organization_service';
import type { SpotnanaQueryResult } from '../../types';
import type SpotnanaError from '../../api/SpotnanaError';
import api from '../../api';

export const fetchOrganizationList = async (
  companyRole?: 'TMC' | 'PARTNER_TMC',
  companyIds?: string[],
): Promise<OrganizationBasicInfo[]> => {
  let urlParams = {};
  if (companyRole) {
    urlParams = { ...urlParams, companyRole };
  }
  if (companyIds) {
    urlParams = { ...urlParams, companyIds: companyIds.join(',') };
  }
  const params = new URLSearchParams(urlParams).toString();
  const data = await api('GET', 'listOrganization', { urlParam: params ? `?${params}` : '' });
  const result = data as ListOrganizationsResponse;
  if (!result.organizationBasicInfos) {
    throw new Error('Organization infos not available');
  }
  return result.organizationBasicInfos;
};

const organizationListKey = (companyRole?: string, companyIds?: string[]): unknown[] => [
  'org-list-spotnana',
  companyRole ?? 'all',
  ...(companyIds ?? []),
];

interface QueryOptions {
  enabled?: boolean;
}

interface TMCListQueryParams {
  isSpotnanaAgentOrAdmin: boolean;
  companyRole?: 'TMC' | 'PARTNER_TMC';
  companyIds?: string[];
  options?: QueryOptions;
}

export const useTMCListQuery = ({
  isSpotnanaAgentOrAdmin,
  companyRole,
  companyIds,
  options: { enabled = true } = {},
}: TMCListQueryParams): SpotnanaQueryResult<OrganizationBasicInfo[]> =>
  useQuery({
    queryKey: organizationListKey(companyRole, companyIds),
    queryFn: () => (isSpotnanaAgentOrAdmin ? fetchOrganizationList(companyRole, companyIds) : []),
    enabled,
  });

export const useTMCPartnerListQuery = (
  isSpotnanaAgentOrAdmin: boolean,
  {
    enabled = true,
  }: {
    enabled?: boolean;
  } = {},
): SpotnanaQueryResult<OrganizationBasicInfo[]> =>
  useQuery<OrganizationBasicInfo[], SpotnanaError>({
    queryKey: organizationListKey('PARTNER_TMC'),
    queryFn: () => (isSpotnanaAgentOrAdmin ? fetchOrganizationList('PARTNER_TMC') : []),
    enabled,
  });
