import { QueryClient } from '@tanstack/react-query';

/**
 * Mobile App will use options from `defaultQueryClient.options.native.ts` file
 * Web will use options from  `defaultQueryClient.options.ts` file
 */
import queriesOptions from './defaultQueryClient.options';

export const defaultQueryClientOptions = {
  queries: {
    ...queriesOptions,
  },
};

export const defaultQueryClient = new QueryClient({
  defaultOptions: defaultQueryClientOptions,
});
