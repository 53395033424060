import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import type { GetApplicableObtShopFeaturesRequest } from '../types/api/v2/obt/model/obt_shop_features';
import type { OBTShopFeaturesConfig } from '../types/api/v2/obt/model/obtshop-features-config';

const readShopFeaturesApplicability = async (userId?: string): Promise<OBTShopFeaturesConfig> => {
  try {
    const result = (await api('GET', 'userBaseUrl', {
      urlParam: `/${userId}/applicable-obt-shop-features`,
    })) as OBTShopFeaturesConfig;
    return result;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

const shopFeaturesApplicableKey = (userId?: string): unknown[] => ['shopFeaturesApplicability', userId];

export const useShopFeaturesApplicability = (
  request: GetApplicableObtShopFeaturesRequest,
  options?: Omit<UseQueryOptions<OBTShopFeaturesConfig, SpotnanaError>, 'queryKey' | 'queryFn'>,
): UseQueryResult<OBTShopFeaturesConfig, SpotnanaError> =>
  useQuery<OBTShopFeaturesConfig, SpotnanaError>({
    queryKey: shopFeaturesApplicableKey(request?.userId),
    queryFn: () => readShopFeaturesApplicability(request?.userId),
    ...options,
  });
