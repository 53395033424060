/* eslint-disable */
import {
  CostCenter,
  CostCenterId,
  Department,
  DepartmentId,
  Grade,
  GradeId,
  LegalEntity,
  LegalEntityId,
  LegalEntityNode,
  Office,
  OfficeId,
  Organization,
  OrganizationBasicInfo,
  OrganizationNode,
} from '../../../v1/obt/common/organization';
import { OrganizationAgencyId, OrganizationId, UserOrgId } from '../../../v1/obt/common/user_org_id';

import { Agency } from '../../../v1/obt/common/agency';
import { AuthConfig } from '../../../v1/obt/common/auth_config';
import type { CompanyId } from '../../common/company_id';
import { Empty } from '../../../google/protobuf/empty';
import {
  UpdatePreferencesRequest,
  ReadPreferencesRequest,
  ReadPreferencesResponse,
  GetApplicablePreferencesRequest,
  GetApplicablePreferencesResponse,
} from '../../../v1/obt/profile/preferences';

/**
 *  Proto messages for Organization CRUD.
 */
export interface CreateOrganizationRequest {
  organization?: Organization;
}

export interface CreateOrganizationResponse {
  organizationId?: OrganizationId;
}

export interface ReadOrganizationRequest {
  organizationId?: OrganizationId;
}

export interface ReadOrganizationResponse {
  organizationNode?: OrganizationNode;
}

export interface ListOrganizationsRequest {
  userOrgId?: UserOrgId;
}

export interface ListOrganizationsResponse {
  organizationBasicInfos: OrganizationBasicInfo[];
}

export interface UpdateOrganizationRequest {
  organizationId?: OrganizationId;
  organization?: Organization;
  types: UpdateOrganizationRequestType[];
}

export interface DeleteOrganizationRequest {
  organizationId?: OrganizationId;
}

/**
 *  Proto messages for Legal Entity CRUD.
 */
export interface CreateLegalEntityRequest {
  organizationId?: OrganizationId;
  legalEntity?: LegalEntity;
}

export interface CreateLegalEntityResponse {
  legalEntityId?: LegalEntityId;
}

export interface ReadLegalEntityRequest {
  legalEntityId?: LegalEntityId;
}

export interface ReadLegalEntityResponse {
  legalEntityNode?: LegalEntityNode;
}

export interface UpdateLegalEntityRequest {
  legalEntityId?: LegalEntityId;
  legalEntity?: LegalEntity;
  types: UpdateLegalEntityRequestType[];
}

export interface DeleteLegalEntityRequest {
  legalEntityId?: LegalEntityId;
}

/**
 *  Proto messages for Office CRUD.
 */
export interface CreateOfficeRequest {
  legalEntityId: LegalEntityId;
  office: Office;
}

export interface CreateOfficeResponse {
  officeId?: OfficeId;
}

export interface ReadOfficeRequest {
  officeId?: OfficeId;
}

export interface ReadOfficeResponse {
  office?: Office;
}

export interface UpdateOfficeRequest {
  officeId: OfficeId;
  /**
   *  Legal Entity updates are allowed for offices.
   */
  legalEntityId: LegalEntityId;
  office: Office;
  types: UpdateOfficeRequestType[];
}

export interface DeleteOfficeRequest {
  officeId?: OfficeId;
}

/**
 *  Proto messages for Department CRUD.
 */
export interface CreateDepartmentRequest {
  organizationId?: OrganizationId;
  department?: Department;
}

export interface CreateDepartmentResponse {
  departmentId?: DepartmentId;
}

export interface UpdateDepartmentRequest {
  departmentId?: DepartmentId;
  department?: Department;
  types: UpdateDepartmentRequestType[];
}

export interface DeleteDepartmentRequest {
  departmentId?: DepartmentId;
}

/**
 *  Proto messages for Cost Center CRUD.
 */
export interface CreateCostCenterRequest {
  organizationId?: OrganizationId;
  costCenter?: CostCenter;
}

export interface CreateCostCenterResponse {
  costCenterId?: CostCenterId;
}

export interface UpdateCostCenterRequest {
  costCenterId?: CostCenterId;
  costCenter?: CostCenter;
  types: UpdateCostCenterRequestType[];
}

export interface DeleteCostCenterRequest {
  costCenterId?: CostCenterId;
}

/**
 *  Proto messages for Grade CRUD.
 */
export interface CreateGradeRequest {
  organizationId?: OrganizationId;
  grade?: Grade;
}

export interface CreateGradeResponse {
  gradeId?: GradeId;
}

export interface UpdateGradeRequest {
  gradeId?: GradeId;
  grade?: Grade;
  types: UpdateGradeRequestType[];
}

export interface DeleteGradeRequest {
  gradeId?: GradeId;
}

/**
 *  TODO(ST-7118): Add support for update and delete agency.
 */
export interface CreateAgencyRequest {
  agency?: Agency;
}

export interface CreateAgencyResponse {
  agencyId?: OrganizationAgencyId;
}

export interface ReadAgencyRequest {
  agencyId?: OrganizationAgencyId;
}

export interface ReadAgencyResponse {
  agency?: Agency;
}

export interface GetAuthConfigRequest {
  email: string;
  companyId?: CompanyId;
}

export interface GetAuthConfigResponse {
  authConfig?: AuthConfig;
}

export interface GetOrganizationIdRequest {
  includeInactive: boolean;
  legalEntityId?: LegalEntityId | undefined;
  officeId?: OfficeId | undefined;
  gradeId?: GradeId | undefined;
  /**
   *  Identifier for specific HR feed file upload instance/record.
   */
  hrFeedUploadedFileId: string | undefined;
}

export interface GetOrganizationIdResponse {
  organizationId?: OrganizationId;
}

/**
 *  RPC service contract for Organization/LegalEntity/Office CRUD.
 */
export interface OrganizationService {
  CreateOrganization(request: CreateOrganizationRequest): Promise<CreateOrganizationResponse>;

  ReadOrganization(request: ReadOrganizationRequest): Promise<ReadOrganizationResponse>;

  ListOrganizations(request: ListOrganizationsRequest): Promise<ListOrganizationsResponse>;

  ReadOrganizationBasicInfo(request: ReadOrganizationRequest): Promise<OrganizationBasicInfo>;

  UpdateOrganization(request: UpdateOrganizationRequest): Promise<Empty>;

  DeleteOrganization(request: DeleteOrganizationRequest): Promise<Empty>;

  CreateLegalEntity(request: CreateLegalEntityRequest): Promise<CreateLegalEntityResponse>;

  ReadLegalEntity(request: ReadLegalEntityRequest): Promise<ReadLegalEntityResponse>;

  UpdateLegalEntity(request: UpdateLegalEntityRequest): Promise<Empty>;

  DeleteLegalEntity(request: DeleteLegalEntityRequest): Promise<Empty>;

  CreateOffice(request: CreateOfficeRequest): Promise<CreateOfficeResponse>;

  ReadOffice(request: ReadOfficeRequest): Promise<ReadOfficeResponse>;

  UpdateOffice(request: UpdateOfficeRequest): Promise<Empty>;

  DeleteOffice(request: DeleteOfficeRequest): Promise<Empty>;

  CreateDepartment(request: CreateDepartmentRequest): Promise<CreateDepartmentResponse>;

  UpdateDepartment(request: UpdateDepartmentRequest): Promise<Empty>;

  DeleteDepartment(request: DeleteDepartmentRequest): Promise<Empty>;

  CreateCostCenter(request: CreateCostCenterRequest): Promise<CreateCostCenterResponse>;

  UpdateCostCenter(request: UpdateCostCenterRequest): Promise<Empty>;

  DeleteCostCenter(request: DeleteCostCenterRequest): Promise<Empty>;

  CreateGrade(request: CreateGradeRequest): Promise<CreateGradeResponse>;

  UpdateGrade(request: UpdateGradeRequest): Promise<Empty>;

  DeleteGrade(request: DeleteGradeRequest): Promise<Empty>;

  /**
   *  Creates a new agency with the given details.
   */
  CreateAgency(request: CreateAgencyRequest): Promise<CreateAgencyResponse>;

  /**
   *  Reads the agency.
   */
  ReadAgency(request: ReadAgencyRequest): Promise<ReadAgencyResponse>;

  GetAuthConfig(request: GetAuthConfigRequest): Promise<GetAuthConfigResponse>;

  GetOrganizationId(request: GetOrganizationIdRequest): Promise<GetOrganizationIdResponse>;

  UpdatePreferences(request: UpdatePreferencesRequest): Promise<Empty>;

  ReadPreferences(request: ReadPreferencesRequest): Promise<ReadPreferencesResponse>;

  GetApplicablePreferences(request: GetApplicablePreferencesRequest): Promise<GetApplicablePreferencesResponse>;
}

export enum UpdateOrganizationRequestTypeEnum {
  UNKNOWN_TYPE = 0,
  NAME = 1,
  PHONE_NUMBERS = 2,
  DESIGNATION_TREE = 3,
  EMAIL_DOMAINS = 4,
  BILLING_CURRENCY = 5,
  EMERGENCY_CONTACT_INFOS = 6,
  AUTH_CONFIG = 7,
  IS_SELF_SIGN_UP_ENABLED = 9,
  ANNOUNCEMENTS = 10,
  ORGANIZATION_LOGO = 11,
  CC_EMAILS = 12,
  BCC_EMAILS = 13,
  IS_FAKE = 15,
  UNRECOGNIZED = -1,
}

type UpdateOrganizationRequestTypeEnumKeys = keyof typeof UpdateOrganizationRequestTypeEnum;

export type UpdateOrganizationRequestType =
  (typeof UpdateOrganizationRequestTypeEnum)[UpdateOrganizationRequestTypeEnumKeys];

export enum UpdateLegalEntityRequestTypeEnum {
  UNKNOWN_TYPE = 0,
  NAME = 1,
  DBA = 2,
  EIN = 3,
  ADDRESS = 4,
  PHONE_NUMBERS = 5,
  BILLING_CURRENCY = 6,
  CONTRACTING_TMC_ID = 7,
  IS_DELAYED_INVOICING_ENABLED = 8,
  EXPENSE_PARTNER_CONFIG = 9,
  ADDITIONAL_INFO = 10,
  EXTERNAL_ID = 11,
  COMPANY_SPECIFIED_ATTRIBUTES = 12,
  UNRECOGNIZED = -1,
}

export type UpdateLegalEntityRequestType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 8 | -1;

export enum UpdateOfficeRequestTypeEnum {
  UNKNOWN_TYPE = 0,
  NAME = 1,
  ADDRESS = 2,
  LEGAL_ENTITY_ID = 3,
  LATLNG = 4,
  EXTERNAL_ID = 5,
  TAX_ID = 6,
  UNRECOGNIZED = -1,
}

export type UpdateOfficeRequestType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | -1;

export enum UpdateDepartmentRequestTypeEnum {
  UNKNOWN_TYPE = 0,
  NAME = 1,
  UNRECOGNIZED = -1,
}

export type UpdateDepartmentRequestType = 0 | 1 | -1;

export enum UpdateCostCenterRequestTypeEnum {
  UNKNOWN_TYPE = 0,
  NAME = 1,
  UNRECOGNIZED = -1,
}

export type UpdateCostCenterRequestType = 0 | 1 | -1;

export enum UpdateGradeRequestTypeEnum {
  UNKNOWN_TYPE = 0,
  NAME = 1,
  UNRECOGNIZED = -1,
}

export type UpdateGradeRequestType = 0 | 1 | -1;
