import type { TFunction } from 'react-i18next';
import { AirCancellationState, type CancellationBreakupComponent } from '../../types';
import type { ICancellationFareBreakupInfo } from '../../types/Flights/pnrCancellation';
import {
  CancellationBreakupFontEnum,
  CancellationColorEnum,
  RefundModeEnum,
} from '../../types/Flights/pnrCancellation';
import { localisedOriginalCurrencyValue } from '../Flights/fareBreakupUtils';
import { MoneyUtil } from '../Money';
import { defineMessage } from '../../translations/defineMessage';
import { localizationKeys } from '../../constants/common';
import { getServiceFeeTripFareBreakup } from './getFareBreakupServiceFeeTripFeesNodesFromCancellationInfo';

const { LOCALIZED_CURRENCY } = localizationKeys;

export const getFareBreakupNodesFromCancellationInfo = (
  cancellationFareBreakupInfo: ICancellationFareBreakupInfo,
  t: TFunction,
  tCommon: TFunction,
): CancellationBreakupComponent[] => {
  const {
    totalPaid,
    totalDeductions,
    fareInfo,
    airlineCreditValue,
    showBrexPoints,
    selectedRefundMode,
    airlineCancellationFee,
    totalRefund,
    airlineChangeFee,
    cancellationState,
    serviceFees,
  } = cancellationFareBreakupInfo;

  /**
   * The quotes are estimates when cancellation type is CANCELLABLE_BY_SUPPORT.
   */
  const refundAmountLabel =
    cancellationState === AirCancellationState.CancellableBySupport
      ? tCommon(defineMessage('Estimated refund amount'))
      : tCommon(defineMessage('Refund amount'));

  const fareBreakupNodes: CancellationBreakupComponent[] = [
    {
      label: tCommon(defineMessage('Total paid')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalPaid)),
      variation: CancellationBreakupFontEnum.HEADING,
      colorVariation: CancellationColorEnum.NORMAL,
      children: [
        {
          label: tCommon(defineMessage('Flight fare')),
          value: t(
            LOCALIZED_CURRENCY,
            localisedOriginalCurrencyValue(MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.totalFare.base)),
          ),
          variation: CancellationBreakupFontEnum.NORMAL,
        },
        {
          label: tCommon(defineMessage('Fee and taxes')),
          value: t(
            LOCALIZED_CURRENCY,
            localisedOriginalCurrencyValue(MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.totalFare.tax)),
          ),
          variation: CancellationBreakupFontEnum.NORMAL,
        },
      ],
    },
    {
      variation: CancellationBreakupFontEnum.DIVIDER,
    },
    {
      label: tCommon(defineMessage('Deductions')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalDeductions.multiply(-1))),
      variation: CancellationBreakupFontEnum.HEADING,
      colorVariation: CancellationColorEnum.NORMAL,
    },
    {
      label: tCommon(defineMessage('Non-refundable fare components')),
      variation: CancellationBreakupFontEnum.SUB_HEADING,
      children: [
        {
          label: tCommon(defineMessage('Flight fare')),
          value: t(
            LOCALIZED_CURRENCY,
            localisedOriginalCurrencyValue(
              MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.nonRefundableFare.base)
                .add(MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.usedFare.base))
                .multiply(-1),
            ),
          ),
          variation: CancellationBreakupFontEnum.NORMAL,
        },
        {
          label: tCommon(defineMessage('Fee and taxes')),
          value: t(
            LOCALIZED_CURRENCY,
            localisedOriginalCurrencyValue(
              MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.nonRefundableFare.tax)
                .add(MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.usedFare.tax))
                .multiply(-1),
            ),
          ),
          variation: CancellationBreakupFontEnum.NORMAL,
        },
      ],
    },
    {
      label: tCommon(defineMessage('Cancellation fee and other charges')),
      variation: CancellationBreakupFontEnum.SUB_HEADING,
      children: [
        {
          label: tCommon(defineMessage('Airline cancellation fee')),
          value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(airlineCancellationFee.multiply(-1))),
          variation: CancellationBreakupFontEnum.NORMAL,
        },
        {
          label: tCommon(defineMessage('Merchant fee')),
          value: t(
            LOCALIZED_CURRENCY,
            localisedOriginalCurrencyValue(MoneyUtil.convertV2MoneyToMoneyUtil(fareInfo.merchantFee).multiply(-1)),
          ),
          variation: CancellationBreakupFontEnum.NORMAL,
        },
      ],
    },
    ...getServiceFeeTripFareBreakup({ serviceFees, t, tCommon }),
    {
      variation: CancellationBreakupFontEnum.DIVIDER,
    },
    {
      label:
        selectedRefundMode === RefundModeEnum.ORIGINAL_PAYMENT_MODE ||
        selectedRefundMode === RefundModeEnum.MIXED_PAYMENT_MODE
          ? refundAmountLabel
          : tCommon(defineMessage('Estimated Refund to Flight Credit')),
      variation: CancellationBreakupFontEnum.HEADING,
      value:
        (selectedRefundMode === RefundModeEnum.ORIGINAL_PAYMENT_MODE ||
          selectedRefundMode === RefundModeEnum.MIXED_PAYMENT_MODE) &&
        totalRefund
          ? t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalRefund))
          : `~${t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(airlineCreditValue))}`,
      valueSubText: showBrexPoints ? `~ ${totalRefund?.getBrexPoints()?.amount.toLocaleString()} Points` : '',
      colorVariation: CancellationColorEnum.SUCCESS,
    },
  ];
  if (selectedRefundMode === RefundModeEnum.MIXED_PAYMENT_MODE) {
    fareBreakupNodes.push({
      label: tCommon(defineMessage('Estimated Refund to Flight Credit')),
      variation: CancellationBreakupFontEnum.HEADING,
      value: `~${t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(airlineCreditValue))}`,
      valueSubText: showBrexPoints ? `~ ${totalRefund?.getBrexPoints()?.amount.toLocaleString()} Points` : '',
      colorVariation: CancellationColorEnum.SUCCESS,
    });
  }

  if (cancellationState === AirCancellationState.CancellableBySupport) {
    fareBreakupNodes.push({
      label: tCommon(defineMessage('Final value subject to fare rules and restrictions')),
      variation: CancellationBreakupFontEnum.LABEL,
    });
  }

  if (showBrexPoints) {
    fareBreakupNodes.push({
      label: tCommon(defineMessage('Refund will be issued as points, the original form of payment for this booking.')),
      variation: CancellationBreakupFontEnum.LABEL,
    });
  }

  if (!airlineChangeFee.isZero()) {
    fareBreakupNodes.push({
      variation: CancellationBreakupFontEnum.BANNER,
      value: tCommon('You will be charged a fee of {{amount}} at the time of using these credits.', {
        amount: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(airlineChangeFee)),
      }),
    });
  }
  return fareBreakupNodes;
};
