/* eslint-disable */
import type { DateTime } from './date_time';
import type { PostalAddress } from '../../v1/common/postal_address';

/** Identifies the different ways the card can be used across an organization. */
export enum CreditCardAccessType {
  UNKNOWN_TYPE = 0,
  /** CENTRALISED - Everyone in organization can access the card. */
  CENTRALISED = 1,
  /** INDIVIDUAL - Only selected employees in an organization can access the card. */
  INDIVIDUAL = 2,
  /** PERSONAL - Personal card added by traveller and only he can use it. */
  PERSONAL = 3,
  /** TMC - TMC Level cards. */
  TMC = 4,
  /** APPLICATION - Everyone in context of the application can access. */
  APPLICATION = 5,
  /** ITINERARY - Can be accessed only in context of an itinerary. */
  ITINERARY = 6,
  // Applicable in context of Events.
  EVENTS = 7,
  /** TRAVEL_ARRANGER_MANAGED - Accessible to Travelers managed by a Travel Arranger */
  TRAVEL_ARRANGER_MANAGED = 8,
  /** COMPANY_TRAVEL_ARRANGER_MANAGED - Accessible to Travelers managed by a Company Travel Arranger */
  COMPANY_TRAVEL_ARRANGER_MANAGED = 9,
  UNRECOGNIZED = -1,
}

/** Used for representing credit/debit card. */
export interface Card {
  id: string;
  type: CardType;
  company: CardCompany;
  name: string;
  address?: PostalAddress;
  number: string;
  expiryMonth: number;
  expiryYear: number;
  cvv: string;
  label: string;
  /** Native currency of the card. */
  currency?: string;
  ownershipLabel?: OwnershipLabel;
}

export enum CardType {
  UNKNOWN = 0,
  CREDIT = 1,
  DEBIT = 2,
  UNRECOGNIZED = -1,
}

export enum CardCompany {
  NONE = 0,
  VISA = 1,
  MASTERCARD = 2,
  AMEX = 3,
  DISCOVER = 4,
  AIR_TRAVEL_UATP = 5,
  CARTE_BLANCHE = 6,
  DINERS_CLUB = 7,
  JCB = 9,
  EURO_CARD = 10,
  ACCESS_CARD = 11,
  BREX = 12,
  UNION_PAY = 13,
  UNRECOGNIZED = -1,
}

export enum CardApplicableTo {
  UNKNOWN_APPLICABLE_TO = 0,
  AIR = 1,
  HOTEL = 2,
  RAIL = 3,
  CAR = 4,
  UNRECOGNIZED = -1,
}

export enum OwnershipLabel {
  OWNERSHIP_LABEL_UNKNOWN = 0,
  CORPORATE = 1,
  PERSONAL = 2,
  CENTRAL = 3,
}

/** Rail Cards. */
export interface RailCard {
  /** Unique Spotnana code/identifier for Rail Card. */
  spotnanaCode: string;
  /** Vendor Name. */
  vendor: string;
  /** Card number of the Rail Card. */
  cardNumber: string;
  /** Name of the Rail Card. */
  name: string;
  /** Expiry date of the Rail Card. */
  expiryDate?: DateTime;
}

/** Access type for cards to check who in the organization has permission to use it. */
export interface CreditCardAccess {
  accessType: CreditCardAccessType;
  /**
   * Holds the ids for for all users who can access the card or organization id.
   *
   * @deprecated
   */
  entityIds: string[];
  entities: CreditCardAccessCardAccessEntity[];
}

export interface CreditCardAccessCardAccessEntity {
  entityId: string;
  centralCardAccessLevel: CreditCardAccessCardAccessEntityCentralCardAccessLevel;
}

/**
 * Centralised credit card can be applicable to the whole TMC or whole organization or one
 * or more legal entities. This is applicable only for access type centralised and TMC level.
 */
export enum CreditCardAccessCardAccessEntityCentralCardAccessLevel {
  UNKNOWN = 0,
  ORGANIZATION = 1,
  LEGAL_ENTITY = 2,
  TMC = 3,
  UNRECOGNIZED = -1,
}
