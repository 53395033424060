import { getFullAssetPath } from './common';

const REGION_FLAG_MAP = {
  ARGENTINA: getFullAssetPath('/assets/images/country-flags/Argentina (AR)', 'svg'),
  AUSTRALIA: getFullAssetPath('/assets/images/country-flags/Australia (AU)', 'svg'),
  BRAZIL: getFullAssetPath('/assets/images/country-flags/Brazil (BR)', 'svg'),
  CANADA: getFullAssetPath('/assets/images/country-flags/Canada (CA)', 'svg'),
  CHINA: getFullAssetPath('/assets/images/country-flags/China (CN)', 'svg'),
  FRANCE: getFullAssetPath('/assets/images/country-flags/France (FR)', 'svg'),
  GERMANY: getFullAssetPath('/assets/images/country-flags/Germany (DE)', 'svg'),
  INDIA: getFullAssetPath('/assets/images/country-flags/India (IN)', 'svg'),
  ISRAEL: getFullAssetPath('/assets/images/country-flags/Israel (IL)', 'svg'),
  ITALY: getFullAssetPath('/assets/images/country-flags/Italy (IT)', 'svg'),
  JAPAN: getFullAssetPath('/assets/images/country-flags/Japan (JP)', 'svg'),
  KOREA: getFullAssetPath('/assets/images/country-flags/Krorea (KR)', 'svg'),
  LATIN_AMERICA: getFullAssetPath('/assets/images/country-flags/Latin America (LATAM)', 'svg'),
  NETHERLANDS: getFullAssetPath('/assets/images/country-flags/Dutch (Du)', 'svg'),
  POLAND: getFullAssetPath('/assets/images/country-flags/Poland (PL)', 'svg'),
  PORTUGAL: getFullAssetPath('/assets/images/country-flags/Portugal (PT)', 'svg'),
  SPAIN: getFullAssetPath('/assets/images/country-flags/Spain (ES)', 'svg'),
  SWEDEN: getFullAssetPath('/assets/images/country-flags/Sweden (SW)', 'svg'),
  TURKEY: getFullAssetPath('/assets/images/country-flags/Turkey (TR)', 'svg'),
  UAE: getFullAssetPath('/assets/images/country-flags/United Arab Emirates (AE)', 'svg'),
  UK: getFullAssetPath('/assets/images/country-flags/United Kingdom (GB)', 'svg'),
  USA: getFullAssetPath('/assets/images/country-flags/United States of America (US)', 'svg'),
} as const;

type SupportedRegionFlags = keyof typeof REGION_FLAG_MAP;

type LanguageAndFlagDetails = {
  language: string;
  imageSrc: string;
  imageAlt: string;
  countryORLanguageInEnglish: string;
};

/**
 * Returns the flag for a given Region
 * @param region
 * @returns
 */
export function getRegionFlag(region: SupportedRegionFlags): string {
  return REGION_FLAG_MAP[region] ?? '';
}

const languageCodeToLanguageAndFlagDetailsMap: Record<string, LanguageAndFlagDetails> = {
  'ar-AE': {
    countryORLanguageInEnglish: 'United arab emirates',
    imageAlt: 'اَلْعَرَبِيَّةُ (United arab emirates)',
    imageSrc: getRegionFlag('UAE'),
    language: 'اَلْعَرَبِيَّةُ',
  },
  'de-DE': {
    countryORLanguageInEnglish: 'German',
    imageAlt: 'Deutsch (German)',
    imageSrc: getRegionFlag('GERMANY'),
    language: 'Deutsch',
  },
  'en-AU': {
    countryORLanguageInEnglish: 'Australian',
    imageAlt: 'English (Australian)',
    imageSrc: getRegionFlag('AUSTRALIA'),
    language: 'English',
  },
  'en-CA': {
    countryORLanguageInEnglish: 'Canadian',
    imageAlt: 'English (Canadian)',
    imageSrc: getRegionFlag('CANADA'),
    language: 'English',
  },
  'en-GB': {
    countryORLanguageInEnglish: 'United Kingdom',
    imageAlt: 'English (United Kingdom)',
    imageSrc: getRegionFlag('UK'),
    language: 'English',
  },
  'en-IN': {
    countryORLanguageInEnglish: 'India',
    imageAlt: 'English (India)',
    imageSrc: getRegionFlag('INDIA'),
    language: 'English',
  },
  'en-US': {
    countryORLanguageInEnglish: 'United States',
    imageAlt: 'English (United States)',
    imageSrc: getRegionFlag('USA'),
    language: 'English',
  },
  'es-419': {
    countryORLanguageInEnglish: 'Latin America',
    imageAlt: 'Español (Spanish - Latin America)',
    imageSrc: getRegionFlag('LATIN_AMERICA'),
    language: 'Español',
  },
  'es-AR': {
    countryORLanguageInEnglish: 'Spanish',
    imageAlt: 'Español (Spanish)',
    imageSrc: getRegionFlag('ARGENTINA'),
    language: 'Español',
  },
  'es-ES': {
    countryORLanguageInEnglish: 'Spanish',
    imageAlt: 'Español (Spanish)',
    imageSrc: getRegionFlag('SPAIN'),
    language: 'Español',
  },
  'fr-CA': {
    countryORLanguageInEnglish: 'Canada',
    imageAlt: 'Français (French - Canada)',
    imageSrc: getRegionFlag('CANADA'),
    language: 'Français',
  },
  'fr-FR': {
    countryORLanguageInEnglish: 'French',
    imageAlt: 'Français (French)',
    imageSrc: getRegionFlag('FRANCE'),
    language: 'Français',
  },
  'he-IL': {
    countryORLanguageInEnglish: 'Hebrew',
    imageAlt: 'עברית (Hebrew)',
    imageSrc: getRegionFlag('ISRAEL'),
    language: 'עברית',
  },
  'it-IT': {
    countryORLanguageInEnglish: 'Italian',
    imageAlt: 'Italiano (Italian)',
    imageSrc: getRegionFlag('ITALY'),
    language: 'Italiano',
  },
  'ja-JP': {
    countryORLanguageInEnglish: 'Japanese',
    imageAlt: '日本語 (Japanese)',
    imageSrc: getRegionFlag('JAPAN'),
    language: '日本語',
  },
  'ko-KR': {
    countryORLanguageInEnglish: 'Korean',
    imageAlt: '한국어 (Korean)',
    imageSrc: getRegionFlag('KOREA'),
    language: '한국어',
  },
  'nl-NL': {
    countryORLanguageInEnglish: 'Netherlands',
    imageAlt: 'Dutch (Netherlands)',
    imageSrc: getRegionFlag('NETHERLANDS'),
    language: 'Dutch',
  },
  'pl-PL': {
    countryORLanguageInEnglish: 'Polish',
    imageAlt: 'język (Polish)',
    imageSrc: getRegionFlag('POLAND'),
    language: 'język',
  },
  'pt-PT': {
    countryORLanguageInEnglish: 'Portuguese',
    imageAlt: 'Português (Portuguese)',
    imageSrc: getRegionFlag('PORTUGAL'),
    language: 'Português',
  },
  'pt-BR': {
    countryORLanguageInEnglish: 'Brazil',
    imageAlt: 'Português (Brazil)',
    imageSrc: getRegionFlag('BRAZIL'),
    language: 'Português',
  },
  'sv-SE': {
    countryORLanguageInEnglish: 'Swedish',
    imageAlt: 'Svenska (Swedish)',
    imageSrc: getRegionFlag('SWEDEN'),
    language: 'Svenska',
  },
  'tr-TR': {
    countryORLanguageInEnglish: 'Turkish',
    imageAlt: 'Türkçe (Turkish)',
    imageSrc: getRegionFlag('TURKEY'),
    language: 'Türkçe',
  },
  'zh-CN': {
    countryORLanguageInEnglish: 'Chinese',
    imageAlt: '官話 (Chinese)',
    imageSrc: getRegionFlag('CHINA'),
    language: '官話',
  },
} as const;

/**
 * Maps a given language code in ISO-639(1) to Country Flag and Language details
 * Defaults to en-US if no mapping is found
 * @param languageWithIsoCode
 * @returns
 */
export const getCountryLanguageAndFlagMapper = (languageWithIsoCode: string): LanguageAndFlagDetails => {
  return (
    languageCodeToLanguageAndFlagDetailsMap[languageWithIsoCode] ?? languageCodeToLanguageAndFlagDetailsMap['en-US']
  );
};
