import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import { Button } from '@spotnana/pixel-react/dist/Button';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { Ring, useMoveUserToRing } from 'obt-common';
import styled, { css, CSSObject } from 'styled-components';

const VerySmallLink = styled.a`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.label};
  cursor: pointer;
  text-decoration: underline;
`;

const VerySmallText = styled(Typography)(
  ({ theme }) =>
    css({
      fontSize: '10px',
      color: theme.colors.onSurface,
    }) as unknown as CSSObject,
);

interface IRingConfirmProps {
  userEmail: string;
  selectedRing: Ring;
  onConfirm: (proceed: boolean) => void;
}

const RingChangeConfirm = ({ userEmail, selectedRing, onConfirm }: IRingConfirmProps): JSX.Element => {
  const { isPending, mutateAsync } = useMoveUserToRing();
  const onChangeConfirm = async (): Promise<void> => {
    try {
      await mutateAsync({ userEmailAddress: userEmail, ringId: selectedRing.id });
      onConfirm(true);
    } catch {
      onConfirm(false);
    }
  };
  return (
    <Flex width="100%" height="100%" p={40} justifyContent="space-between">
      <svg width="88" height="86" viewBox="0 0 88 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M36.1844 72.3689C56.1686 72.3689 72.3689 56.1686 72.3689 36.1844C72.3689 16.2003 56.1686 0 36.1844 0C16.2003 0 0 16.2003 0 36.1844C0 56.1686 16.2003 72.3689 36.1844 72.3689Z"
          fill="#687591"
        />
        <path
          d="M72.3687 36.1844C72.3687 16.2004 56.1683 0 36.1843 0C35.3707 0 34.5639 0.029563 33.7637 0.0825372C51.7763 1.27258 66.1856 15.6416 67.4373 33.6369C67.5224 34.8596 66.5202 35.8707 65.2948 35.8446C65.107 35.8406 64.9186 35.8386 64.7298 35.8386C49.8061 35.8386 37.708 47.9367 37.708 62.8604C37.708 64.2618 37.8152 65.6381 38.0213 66.9818C38.4286 69.6376 36.4994 72.1016 33.8185 72.2829C33.8002 72.2841 33.782 72.2853 33.7637 72.2865C34.5639 72.3393 35.3707 72.3691 36.1843 72.3691C56.2864 72.3689 72.3687 56.0351 72.3687 36.1844V36.1844Z"
          fill="#525D74"
        />
        <path
          d="M36.1844 63.9607C51.5248 63.9607 63.9607 51.5248 63.9607 36.1844C63.9607 20.8441 51.5248 8.4082 36.1844 8.4082C20.8441 8.4082 8.4082 20.8441 8.4082 36.1844C8.4082 51.5248 20.8441 63.9607 36.1844 63.9607Z"
          fill="white"
        />
        <path
          d="M63.9606 36.1844C63.9606 20.8441 51.5249 8.4082 36.1844 8.4082C35.4318 8.4082 34.6866 8.43913 33.9492 8.49792C48.2975 9.64062 59.5739 21.6858 59.4898 36.3473C59.4893 36.348 59.4888 36.3485 59.4882 36.3492C47.7678 38.6534 39.0264 48.4251 37.8449 60.0854C37.6417 62.0899 36.0719 63.6929 34.0642 63.8614C34.0259 63.8647 33.9877 63.8677 33.9492 63.8708C50.1233 65.1596 63.9606 52.4169 63.9606 36.1844V36.1844Z"
          fill="#F2F3F4"
        />
        <path
          d="M36.1855 17.0015C35.4562 17.0015 34.8652 16.4104 34.8652 15.6813V13.8149C34.8652 13.0855 35.4563 12.4946 36.1855 12.4946C36.9148 12.4946 37.5057 13.0857 37.5057 13.8149V15.6813C37.5057 16.4104 36.9148 17.0015 36.1855 17.0015Z"
          fill="#687591"
        />
        <path
          d="M50.6836 23.0067C50.3456 23.0067 50.0078 22.8777 49.7499 22.6198C49.2344 22.1042 49.2344 21.2683 49.7501 20.7525L51.0698 19.433C51.5854 18.9174 52.4214 18.9176 52.9371 19.4331C53.4527 19.9487 53.4527 20.7847 52.9369 21.3004L51.6172 22.62C51.3595 22.8777 51.0215 23.0067 50.6836 23.0067V23.0067Z"
          fill="#687591"
        />
        <path
          d="M58.5538 37.5043H56.6874C55.9581 37.5043 55.3672 36.9132 55.3672 36.184C55.3672 35.4549 55.9583 34.8638 56.6874 34.8638H58.554C59.2833 34.8638 59.8743 35.4549 59.8743 36.184C59.8743 36.9132 59.2832 37.5043 58.5538 37.5043Z"
          fill="#687591"
        />
        <path
          d="M36.7304 60.4167C36.0011 60.4167 35.4102 59.8257 35.4102 59.0965V57.2299C35.4102 56.5006 36.0012 55.9097 36.7304 55.9097C37.4597 55.9097 38.0507 56.5008 38.0507 57.2299V59.0965C38.0507 59.8258 37.4597 60.4167 36.7304 60.4167Z"
          fill="#687591"
        />
        <path
          d="M20.3673 53.3222C20.0294 53.3222 19.6914 53.1932 19.4335 52.9355C18.918 52.4198 18.918 51.584 19.4335 51.0681L20.7533 49.7484C21.269 49.233 22.1048 49.233 22.6207 49.7484C23.1363 50.2641 23.1363 51.0999 22.6207 51.6158L21.301 52.9355C21.0429 53.1934 20.7049 53.3222 20.3673 53.3222V53.3222Z"
          fill="#687591"
        />
        <path
          d="M15.6827 37.5043H13.8163C13.087 37.5043 12.4961 36.9132 12.4961 36.184C12.4961 35.4549 13.0872 34.8638 13.8163 34.8638H15.6829C16.4123 34.8638 17.0032 35.4549 17.0032 36.184C17.0032 36.9132 16.4121 37.5043 15.6827 37.5043Z"
          fill="#687591"
        />
        <path
          d="M21.687 23.0067C21.3491 23.0067 21.0111 22.8777 20.7534 22.62L19.4337 21.3004C18.918 20.7848 18.918 19.9489 19.4335 19.4331C19.9493 18.9174 20.7852 18.9176 21.3008 19.433L22.6205 20.7525C23.1362 21.2681 23.1362 22.1041 22.6207 22.6198C22.3628 22.8777 22.0248 23.0067 21.687 23.0067V23.0067Z"
          fill="#687591"
        />
        <path
          d="M36.1829 38.0482C35.8453 38.0482 35.5129 37.9186 35.2615 37.6738L30.6179 33.152C30.0955 32.6433 30.0844 31.8075 30.5931 31.2849C31.1017 30.7625 31.9377 30.7512 32.4602 31.2601L34.8624 33.5992V24.2329C34.8624 23.5035 35.4534 22.9126 36.1826 22.9126C36.9119 22.9126 37.5029 23.5037 37.5029 24.2329V36.7278C37.5029 37.2587 37.185 37.7377 36.6959 37.9443C36.5302 38.0142 36.3559 38.0482 36.1829 38.0482V38.0482Z"
          fill="#687591"
        />
        <path
          d="M64.7296 85.6228C77.3012 85.6228 87.4925 75.4316 87.4925 62.86C87.4925 50.2884 77.3012 40.0972 64.7296 40.0972C52.1581 40.0972 41.9668 50.2884 41.9668 62.86C41.9668 75.4316 52.1581 85.6228 64.7296 85.6228Z"
          fill="#EAB14D"
        />
        <path
          d="M64.7292 40.0972C63.843 40.0972 62.9691 40.1496 62.1094 40.2482C73.4467 41.5476 82.2524 51.1748 82.2524 62.86C82.2524 74.5453 73.4467 84.1724 62.1094 85.4718C62.9693 85.5704 63.843 85.6228 64.7292 85.6228C77.3008 85.6228 87.492 75.4316 87.492 62.86C87.492 50.2885 77.3008 40.0972 64.7292 40.0972Z"
          fill="#E49542"
        />
        <path
          d="M64.3145 67.7605C62.8562 67.7605 61.6738 66.5782 61.6738 65.1198V49.3545C61.6738 47.8962 62.8562 46.7139 64.3145 46.7139C65.7728 46.7139 66.9552 47.8962 66.9552 49.3545V65.12C66.955 66.5783 65.7728 67.7605 64.3145 67.7605Z"
          fill="#F4FBFF"
        />
        <path
          d="M64.3145 77.9702C62.8562 77.9702 61.6738 76.7879 61.6738 75.3295V73.6475C61.6738 72.1892 62.8562 71.0068 64.3145 71.0068C65.7728 71.0068 66.9552 72.1892 66.9552 73.6475V75.3295C66.955 76.7879 65.7728 77.9702 64.3145 77.9702Z"
          fill="#F4FBFF"
        />
        <path
          d="M64.3124 46.7139C63.8309 46.7139 63.3808 46.8449 62.9922 47.07C63.7806 47.5268 64.3124 48.3776 64.3124 49.3545V65.12C64.3124 66.0968 63.7806 66.9478 62.9922 67.4046C63.3809 67.6296 63.8309 67.7607 64.3124 67.7607C65.7708 67.7607 66.9531 66.5783 66.9531 65.12V49.3545C66.9529 47.8962 65.7708 46.7139 64.3124 46.7139Z"
          fill="#F2F3F4"
        />
        <path
          d="M64.3124 71.0073C63.8309 71.0073 63.3808 71.1384 62.9922 71.3634C63.7806 71.8202 64.3124 72.6711 64.3124 73.648V75.33C64.3124 76.3068 63.7806 77.1578 62.9922 77.6146C63.3809 77.8396 63.8309 77.9707 64.3124 77.9707C65.7708 77.9707 66.9531 76.7883 66.9531 75.33V73.648C66.9529 72.1897 65.7708 71.0073 64.3124 71.0073Z"
          fill="#F2F3F4"
        />
      </svg>
      <Flex height={200} width={346} flexDirection="column" justifyContent="space-between">
        <Typography variation="small" lineHeight="20px" color="#687591">
          Product version change could take up to 2 - 3 minutes to be effective.
        </Typography>
        <VerySmallText lineHeight="16px">
          Please refresh all Spotnana tabs in your browser after 2 - 3 minutes to access correct product version. In
          case you don&apos;t refresh you will get a reminder in this browser tab.
        </VerySmallText>
        <Flex justifyContent="space-between" alignItems="center">
          <Box width="215px">
            <Button
              variation="primary"
              p="15px 48px"
              fontSize="16px"
              block
              loading={isPending}
              onClick={onChangeConfirm}
              data-testid="proceed-btn"
            >
              Proceed
            </Button>
          </Box>
          <VerySmallLink onClick={(): void => onConfirm(false)}>Don&apos;t want to change</VerySmallLink>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RingChangeConfirm;
