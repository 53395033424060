/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { SelectedPaymentSource } from '../../../v1/obt/pnr/payment';
import { FareAmount } from './fare-amount';
// May contain unused imports in some cases
// @ts-ignore
import { FormOfPayment } from './form-of-payment';
// May contain unused imports in some cases
// @ts-ignore
import { UserId } from './user-id';

/**
 * This will contain the total fare of goods along with other charges like service fee. The price break down of goods should be added in PNR details, like airPnr
 * @export
 * @interface Payment
 */
export interface Payment {
  /**
   * The list of traveler indices on which this payment applies.
   * @type {Array<number>}
   * @memberof Payment
   */
  travelerIndices: Array<number>;
  /**
   * The list of traveler user IDs on which this payment applies.
   * @type {Array<UserId>}
   * @memberof Payment
   */
  userIds?: Array<UserId>;
  /**
   *
   * @type {FareAmount}
   * @memberof Payment
   */
  amount?: FareAmount;
  /**
   *
   * @type {FormOfPayment}
   * @memberof Payment
   */
  fop?: FormOfPayment;
  /**
   * Reference number for original payment made for this service
   * @type {string}
   * @memberof Payment
   */
  paymentReference?: string;
  /**
   * Service for which this payment is made
   * @type {string}
   * @memberof Payment
   */
  paymentType: PaymentPaymentTypeEnum;
  /**
   * Third party who is processing the payment
   * @type {string}
   * @memberof Payment
   */
  paymentThirdParty?: PaymentPaymentThirdPartyEnum;
  /**
   * The payment id for this transaction
   * @type {string}
   * @memberof Payment
   */
  paymentId?: string;
  /**
   * Third party payment gateway used to process the payment
   * @type {string}
   * @memberof Payment
   */
  paymentGateway?: PaymentPaymentGatewayEnum;
  /**
   * The payment source selected by the user. When booking-engine receives this
   * message, it will use this field to identify the payment source and updates
   * FOP above with the corresponding payment-source.
   * @type {SelectedPaymentSource}
   * @memberof Payment
   */
  selectedPaymentSource?: SelectedPaymentSource;
  /**
   * Whether the payment is refunded to the customer
   * @type {boolean}
   * @memberof Payment
   */
  isRefunded?: boolean;
}

export const PaymentPaymentTypeEnum = {
  Unknown: 'UNKNOWN',
  Flights: 'FLIGHTS',
  ServiceFee: 'SERVICE_FEE',
  Miscellaneous: 'MISCELLANEOUS',
  Hotels: 'HOTELS',
  Cars: 'CARS',
  Rails: 'RAILS',
  Limo: 'LIMO',
  Misc: 'MISC',
} as const;

export type PaymentPaymentTypeEnum = (typeof PaymentPaymentTypeEnum)[keyof typeof PaymentPaymentTypeEnum];
export const PaymentPaymentThirdPartyEnum = {
  UnknownParty: 'UNKNOWN_PARTY',
  Stripe: 'STRIPE',
  Tfpay: 'TFPAY',
  Trainline: 'TRAINLINE',
  Brex: 'BREX',
} as const;

export type PaymentPaymentThirdPartyEnum =
  (typeof PaymentPaymentThirdPartyEnum)[keyof typeof PaymentPaymentThirdPartyEnum];
export const PaymentPaymentGatewayEnum = {
  PaymentGatewayUnknown: 'PAYMENT_GATEWAY_UNKNOWN',
  Stripe: 'STRIPE',
  Brex: 'BREX',
} as const;

export type PaymentPaymentGatewayEnum = (typeof PaymentPaymentGatewayEnum)[keyof typeof PaymentPaymentGatewayEnum];
