import { useTranslation } from 'react-i18next';
import type { ITraveler } from '../../types/traveler';
import type { IServiceFeeData } from '../../types/payment';
import { localizationKeys } from '../../constants/common';
import { localisedCurrencyValue, localisedOriginalCurrencyValue } from '../../utils/Flights/fareBreakupUtils';
import type { IPaxesFareBreakup } from '../../utils/Flights/getFlightFareBreakup';
import { defineMessage } from '../../translations/defineMessage';
import { AirCheckoutFareNodeTypeEnum } from './useItineraryFareBreakup/types';
import type { IAirCheckoutFareBreakupNode } from './useItineraryFareBreakup/types';
import type { MoneyUtil } from '../../utils';
import { getTravelerFullName } from './useItineraryFareBreakup/useItineraryFareBreakup';
import { useFeatureFlag } from '../../feature-gating';
import { useGetAirFareBreakupTripFees } from './useGetAirFareBreakupTripFees';

interface IProps {
  paxesFareBreakup: IPaxesFareBreakup[];
  travelers: ITraveler[];
  totalAmount: MoneyUtil;
  totalAmountExcludingServiceFees: MoneyUtil;
  serviceFees: IServiceFeeData;
}

const { LOCALIZED_CURRENCY } = localizationKeys;

export const useEditSeatMapFareBreakup = ({
  paxesFareBreakup,
  travelers,
  totalAmount,
  totalAmountExcludingServiceFees,
  serviceFees,
}: IProps): IAirCheckoutFareBreakupNode[] => {
  const { t } = useTranslation();
  const { t: tCommon } = useTranslation('COMMON');
  const shouldUsePreferredName = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');
  const { getFareBreakupTripFees } = useGetAirFareBreakupTripFees();

  const fareBreakup: IAirCheckoutFareBreakupNode[] = [];

  const isBookingAmountNotInPreferredCurrency =
    totalAmountExcludingServiceFees.getCurrency() !== totalAmountExcludingServiceFees.getOriginalCurrency();

  const isServiceFeesHavingDifferentCurrency =
    serviceFees.showWithFeeBreakup &&
    !serviceFees.total.isZero() &&
    (serviceFees.total.getOriginalCurrency() !== totalAmount.getOriginalCurrency() ||
      serviceFees.isTotalHavingDifferentCurrency);

  const isBookingAmountInDifferentCurrency =
    isBookingAmountNotInPreferredCurrency || isServiceFeesHavingDifferentCurrency;

  /**  Passenger level fare components with base and tax start here */
  paxesFareBreakup.forEach((paxFareBreakup, paxIndex) => {
    const travelerNumber = paxIndex + 1;
    const { fareComponents } = paxFareBreakup;
    const { seat } = fareComponents;

    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.SUB_HEADING,
      label: paxFareBreakup.paxUserId
        ? getTravelerFullName({
            travelers,
            userId: paxFareBreakup.paxUserId,
            shouldUsePreferredName: !!shouldUsePreferredName,
          })
        : tCommon(defineMessage('Traveler {{travelerNumber}}'), { travelerNumber }),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(paxFareBreakup.paxTotal)),
    });

    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.NORMAL,
      label: tCommon(defineMessage('Seats')),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(seat)),
    });
  });

  const tripfeeBreakUp = getFareBreakupTripFees({ serviceFees });
  if (tripfeeBreakUp.length) {
    fareBreakup.push({ type: AirCheckoutFareNodeTypeEnum.DIVIDER });
  }
  fareBreakup.push(...tripfeeBreakUp);

  // Render Grand total
  fareBreakup.push(
    { type: AirCheckoutFareNodeTypeEnum.DIVIDER },
    {
      type: AirCheckoutFareNodeTypeEnum.HEADING,
      label: defineMessage('Total Amount'),
      value: isBookingAmountInDifferentCurrency
        ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(totalAmount))}`
        : t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(totalAmount)),
    },
  );

  if (isBookingAmountInDifferentCurrency) {
    fareBreakup.push({
      type: AirCheckoutFareNodeTypeEnum.ONLY_VALUE,
      value: `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(totalAmount))}`,
    });
  }

  return fareBreakup;
};
