import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'react-i18next';
import type { CancellationBreakupComponent, IServiceFeeData } from '../../types';
import { CancellationBreakupFontEnum } from '../../types';
import { localisedCurrencyValue, localisedOriginalCurrencyValue } from '../../utils';
import { localizationKeys } from '../../constants';
import type { GetPaymentTripFeeLabelParams } from '../payment/useGetPaymentTripFeeLabels';
import { useGetPaymentTripFeeLabels, TripFeeLabelTypeEnum } from '../payment/useGetPaymentTripFeeLabels';

const { LOCALIZED_CURRENCY } = localizationKeys;

interface IGetTripFeeBreakdownsProps {
  serviceFees: IServiceFeeData;
}
interface IGetTripFeeBreakdownsUtilProps extends IGetTripFeeBreakdownsProps {
  t: TFunction;
  getPaymentTripFeeLabels: ({ tripFeeLabelType, tripFee }: GetPaymentTripFeeLabelParams) => string;
}

function getAirExchangeFareBreakupTripFeeUtil({
  serviceFees,
  t,
  getPaymentTripFeeLabels,
}: IGetTripFeeBreakdownsUtilProps) {
  const tripFeeNodes: CancellationBreakupComponent[] = [];
  const shouldShowServiceFeeBreakup = serviceFees.showWithFeeBreakup && !serviceFees.total.isZero();
  if (!shouldShowServiceFeeBreakup) {
    return tripFeeNodes;
  }
  tripFeeNodes.push({
    variation: CancellationBreakupFontEnum.DIVIDER,
  });
  tripFeeNodes.push({
    variation: CancellationBreakupFontEnum.SUB_HEADING,
    label: getPaymentTripFeeLabels({ tripFeeLabelType: TripFeeLabelTypeEnum.TRIP_FEES_STAR }),
    value: serviceFees.isTotalHavingDifferentCurrency
      ? `~${t(LOCALIZED_CURRENCY, localisedCurrencyValue(serviceFees.total))}`
      : t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.total)),
  });

  serviceFees.tripFees?.forEach((tripFee) => {
    if (!tripFee.base.isZero()) {
      tripFeeNodes.push({
        variation: CancellationBreakupFontEnum.NORMAL,
        label: getPaymentTripFeeLabels({ tripFeeLabelType: TripFeeLabelTypeEnum.TRIP_FEE_BREAKDOWN, tripFee }),
        value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(tripFee.base)),
      });
    }
  });

  if (serviceFees.showTax) {
    tripFeeNodes.push({
      variation: CancellationBreakupFontEnum.NORMAL,
      label: getPaymentTripFeeLabels({ tripFeeLabelType: TripFeeLabelTypeEnum.TRIP_FEES_TAXES }),
      value: t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(serviceFees.tax)),
    });
  }

  tripFeeNodes.push({
    variation: CancellationBreakupFontEnum.LABEL,
    label: getPaymentTripFeeLabels({ tripFeeLabelType: TripFeeLabelTypeEnum.NON_REFUNDABLE }),
  });
  return tripFeeNodes;
}

export const useGetAirExchangeFareBreakupTripFee = () => {
  const { t } = useTranslation();
  const { getPaymentTripFeeLabels } = useGetPaymentTripFeeLabels();
  const getExchangeFareBreakupTripFees = useCallback(
    ({ serviceFees }: IGetTripFeeBreakdownsProps): CancellationBreakupComponent[] => {
      return getAirExchangeFareBreakupTripFeeUtil({ serviceFees, t, getPaymentTripFeeLabels });
    },
    [t, getPaymentTripFeeLabels],
  );
  return {
    getExchangeFareBreakupTripFees,
  };
};
