import first from 'lodash/first';

import type { RoomAmenitiyRoomAmenityTypeEnum } from '@spotnana/types/openapi/models/room-amenitiy';
import type { TransactionItem } from '@spotnana/types/openapi/models/transaction-item';
import { TransactionType } from '@spotnana/types/openapi/models/transaction-type';
import { localizeDate } from '../../../translations/localizers';
import { FeeType } from '../../../types/api/v1/obt/hotel/hotel_common';
import { RebookReferenceRebookTypeEnum } from '../../../types/api/v2/obt/model/rebook-reference';
import { defineCommonMessage } from '../../../translations/defineMessage';
import { accessibleFeaturesMap } from '../../../constants/hotels';
import type { HotelSpecialRequestsAccessibleFeaturesEnum } from '../../../types/api/v2/obt/model/hotel-special-requests';
import {
  HotelSpecialRequestsCheckInEnum,
  HotelSpecialRequestsRoomFeaturesEnum,
  HotelSpecialRequestsRoomLocationsEnum,
} from '../../../types/api/v2/obt/model/hotel-special-requests';

import { dateUtil, getDateDiff } from '../../../date-utils';
import type { PnrV3ManagerProps } from '../PnrV3Manager';
import { PnrV3Manager } from '../PnrV3Manager';
import {
  MoneyUtil,
  createUserNameFromFullName,
  feeTypeTitleMapper,
  getHotelCancellationPolicyText,
  getLocationFullAddressV2,
  getNameStringFromName,
  pluralize,
  titleCase,
} from '../../../utils';
import type { DateTimeLocal, MandatoryFees, MandatoryFeesBreakdown } from '../../../types';
import { DeviceType, CancellationWorkflow, FormOfPaymentTypeEnum, HotelPaymentPaymentTypeEnum } from '../../../types';
import type { Hotel } from '../../../types/api/v2/obt/model/hotel';
import V2TripDetailsResponseManager from '../../V2TripDetailsResponseManager';
import type {
  HotelTransactionDetails,
  HotelPnrTransaction,
  TripServiceFeePnrTransaction,
  HotelPriceBreakdown,
} from '../types';
import type { Transaction } from '../../../types/api/v2/obt/model/transaction';
import type { ItemGroup } from '../../../types/api/v2/obt/model/item-group';
import type { HotelItem } from '../../../types/api/v2/obt/model/hotel-item';

export class HotelPnrV3Manager extends PnrV3Manager {
  hotelPnr?: Hotel;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    super({ pnrData, pnrId });
    this.hotelPnr = this.pnrData.hotelPnr;
  }

  public hotelName(): string {
    return this.hotelPnr?.hotelInfo.name ?? '';
  }

  public totalNights(): number {
    const checkInDate = this.hotelPnr?.checkInDateTime.iso8601 ?? '';
    const checkOutDate = this.hotelPnr?.checkOutDateTime.iso8601 ?? '';
    return Math.max(getDateDiff(checkInDate, checkOutDate), 1);
  }

  public hotelDetails() {
    const { hotelPnr } = this;
    const startDate = hotelPnr?.checkInDateTime;
    const endDate = hotelPnr?.checkOutDateTime;
    const cancellationPolicy = hotelPnr?.room.cancellationPolicy
      ? getHotelCancellationPolicyText(hotelPnr?.room.cancellationPolicy)
      : '';
    const phoneNumber = {
      rawInput: hotelPnr?.hotelInfo.phone?.rawInput,
      countryCode: hotelPnr?.hotelInfo.phone?.countryCode,
    };
    const fax = {
      rawInput: first(hotelPnr?.hotelInfo.fax)?.rawInput,
      countryCode: first(hotelPnr?.hotelInfo.fax)?.countryCode,
    };

    const hotelEmail = hotelPnr?.hotelInfo.email;
    const showAdditionalInformationMessage = this.pnrData.createdVia !== 'OFFLINE';

    const hotelDetails = {
      name: hotelPnr?.hotelInfo.name ?? '',
      chainCode: hotelPnr?.hotelInfo.chainCode ?? hotelPnr?.hotelInfo.masterChainCode ?? '',
      masterChainCode: hotelPnr?.hotelInfo.masterChainCode ?? '',
      chainName: hotelPnr?.hotelInfo.chainName ?? '',
      confirmationNumber: hotelPnr?.vendorConfirmationNumber ?? '',
      bookingId: this.pnrId,
      vendorCancellationId: hotelPnr?.vendorCancellationId ?? '',
      numberOfRooms: hotelPnr?.numberOfRooms.toString() ?? '',
      /**
       * @deprecated
       * to be renamed to roomName as a separate PR from trips v3, as it adds misdirection to naming (roomType is already another BE key)
       */
      roomType: hotelPnr?.room.roomName ?? '',
      checkIn: hotelPnr?.checkInDateTime,
      checkOut: hotelPnr?.checkOutDateTime,
      duration: `${this.totalNights()}d`,
      numNights: this.totalNights(),
      city: titleCase(hotelPnr?.hotelInfo.address?.locality || ''),
      contactDetails: {
        address: getLocationFullAddressV2(hotelPnr?.hotelInfo.address),
        phoneNumber,
        fax,
        latLng: hotelPnr?.hotelInfo.coordinates,
        locality: hotelPnr?.hotelInfo.address?.locality,
      },
      cancellationPolicy,
      hotelEmail,
      numPax: this.totalTravelers(),
      additionalDetails: hotelPnr?.room?.additionalDetails ?? [],
      co2EmissionsValue: hotelPnr?.room.co2EmissionDetail?.co2EmissionValue ?? 0,
      starRating: hotelPnr?.hotelInfo.starRating,
      specialRequests: this.specialRequestsStrings(),
    };

    const passengers = this.pnrData?.pnrTravelers?.map((pnrTraveler) => ({
      name: pnrTraveler.personalInfo?.name,
      id: pnrTraveler.userId.id,
      email: pnrTraveler.personalInfo?.email,
    }));

    const passengerDetails =
      hotelPnr?.travelerInfos?.map((travelerInfo) => {
        const requiredPassenger = passengers?.find((passenger) => passenger.id === travelerInfo.userId?.id);
        return {
          name: getNameStringFromName(requiredPassenger?.name),
          email: requiredPassenger?.email,
          preferredName: getNameStringFromName(requiredPassenger?.name, { usePreferredName: true }),
          loyaltyNumber: travelerInfo.loyaltyInfos?.map((l) => l.id).join(', ') ?? '',
        };
      }) ?? [];

    return {
      startDate,
      endDate,
      hotelDetails,
      passengerDetails,
      roomDetails: hotelPnr?.room,
      showAdditionalInformationMessage,
      isOutOfPolicy: this.isPnrOutOfPolicy(),
    };
  }

  public hotelSavings() {
    const { hotelPnr } = this;
    const savingsAmount = hotelPnr?.rebookReference?.hotelRateAssuranceMetadata?.actualSavings?.amount;
    const savingsCurrency = hotelPnr?.rebookReference?.hotelRateAssuranceMetadata?.actualSavings?.currencyCode;

    const savings = MoneyUtil.parse({
      currencyCode: savingsCurrency ?? '',
      amount: savingsAmount ?? 0,
    });

    return savings;
  }

  public isRateAssuranceRebooking() {
    return (
      !!this.hotelPnr?.rebookReference?.cancelledPnrIds?.length &&
      this.hotelPnr?.rebookReference.rebookType === RebookReferenceRebookTypeEnum.RateAssurance
    );
  }

  public paymentInfo() {
    const isPayLater = this.hotelPnr?.payment.paymentType === HotelPaymentPaymentTypeEnum.PayAtHotel;

    const rateInfo = this.hotelPnr?.room.rateInfo;
    const transactionDate = rateInfo?.totalRate.transactionDate;

    const prepaidBaseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.prepaidRate?.base);
    const prepaidTaxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.prepaidRate?.tax);

    const prepaidExtras = rateInfo?.prepaidRate?.extras ?? [];
    const prepaidTotalExtrasAmount = prepaidExtras.reduce((accumulator, currentValue) => {
      return accumulator.add(MoneyUtil.convertV2MoneyToMoneyUtil(currentValue.amount));
    }, MoneyUtil.zeroMoneyWithOriginal(prepaidBaseAmount.getCurrency(), prepaidBaseAmount?.getOriginalCurrency()));

    const prepaidAmount = prepaidBaseAmount.add(prepaidTaxAmount.add(prepaidTotalExtrasAmount));

    const postpaidBaseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.postpaidRate?.base);
    const postpaidTaxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.postpaidRate?.tax);

    const postpaidExtras = rateInfo?.postpaidRate?.extras ?? [];
    const totalExtras = rateInfo?.totalRate?.extras ?? [];

    let extraFeesWithTypesTotal = MoneyUtil.zeroMoneyWithOriginal(
      prepaidBaseAmount.getCurrency(),
      prepaidBaseAmount?.getOriginalCurrency(),
    );

    const extraFeesBreakdown: MandatoryFeesBreakdown[] = [];
    totalExtras.forEach((extra) => {
      if (extra.type) {
        extraFeesWithTypesTotal = extraFeesWithTypesTotal.add(MoneyUtil.convertV2MoneyToMoneyUtil(extra.amount));
        extraFeesBreakdown.push({
          title: FeeType[extra.type as keyof typeof FeeType]
            ? feeTypeTitleMapper[FeeType[extra.type as keyof typeof FeeType]]
            : { message: extra.type as never, values: {} },
          amount: MoneyUtil.convertV2MoneyToMoneyUtil(extra.amount),
          feeInclusions: [],
        });
      }
    });

    const totalExtrasAmount = totalExtras.reduce((accumulator, currentValue) => {
      return accumulator.add(MoneyUtil.convertV2MoneyToMoneyUtil(currentValue.amount));
    }, MoneyUtil.zeroMoneyWithOriginal(postpaidBaseAmount.getCurrency(), postpaidBaseAmount?.getOriginalCurrency()));

    const postpaidTotalExtrasAmount = postpaidExtras.reduce((accumulator, currentValue) => {
      return accumulator.add(MoneyUtil.convertV2MoneyToMoneyUtil(currentValue.amount));
    }, MoneyUtil.zeroMoneyWithOriginal(postpaidBaseAmount.getCurrency(), postpaidBaseAmount?.getOriginalCurrency()));

    const amountDueLater = postpaidBaseAmount.add(postpaidTaxAmount.add(postpaidTotalExtrasAmount));
    const changeFee = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.overallPenaltyCharged);

    const brexPointsFop = this.pnrData.paymentInfo?.find(
      (paymentInfo) => paymentInfo.fop.paymentMethod === FormOfPaymentTypeEnum.BrexPoints,
    );

    const paidByBrexPointsInstruction = brexPointsFop
      ? `${MoneyUtil.convertV2MoneyToMoneyUtil(brexPointsFop.totalCharge).getBrexPoints()?.amount} Brex Points`
      : undefined;

    const totalNightlyRate = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.totalRate?.base);

    const merchantCancellationPenaltyBaseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(
      rateInfo?.totalRate.refundInfo?.penalty?.base,
    );

    const merchantCancellationPenaltyTaxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(
      rateInfo?.totalRate.refundInfo?.penalty?.tax,
    );

    const merchantCancellationFee = merchantCancellationPenaltyBaseAmount.add(merchantCancellationPenaltyTaxAmount);

    const refundAmountBaseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(
      rateInfo?.totalRate.refundInfo?.refundAmount?.base,
    );

    const refundAmountTaxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(
      rateInfo?.totalRate.refundInfo?.refundAmount?.tax,
    );

    const refundAmount = refundAmountBaseAmount.add(refundAmountTaxAmount);

    const reservationAmount = prepaidAmount.add(amountDueLater);

    const mandatoryFees: MandatoryFees = {
      total: extraFeesWithTypesTotal,
      breakdown: extraFeesBreakdown,
    };

    const feesWithoutTypes = totalExtrasAmount.subtract(extraFeesWithTypesTotal);

    return {
      isPayLater,
      ...super.paymentInfo(),
      transactionDate,
      reservationAmount,
      prepaidAmount,
      amountDueLater,
      fees: feesWithoutTypes,
      mandatoryFees,
      changeFee,
      paidByBrexPointsInstruction,
      refundAmount,
      merchantCancellationFee,
      totalNightlyRate,
    };
  }

  public totalTravelers() {
    let totalTravelers = 0;
    const { hotelPnr } = this;

    if (!hotelPnr) {
      return totalTravelers;
    }

    hotelPnr.occupancy?.forEach(({ numAdults = 0, numChildren = 0, numInfants = 0 }) => {
      totalTravelers += numAdults + numChildren + numInfants;
    });

    return totalTravelers;
  }

  public getRoomLocationSpecialRequest(roomLocationRequest?: HotelSpecialRequestsRoomLocationsEnum): string {
    if (roomLocationRequest === HotelSpecialRequestsRoomLocationsEnum.HighFloor) {
      return 'High floor';
    }

    if (roomLocationRequest === HotelSpecialRequestsRoomLocationsEnum.LowFloor) {
      return 'Low floor';
    }

    return '';
  }

  public getSupportedSpecialFeatures(
    accessibleFeatures?: Array<HotelSpecialRequestsAccessibleFeaturesEnum>,
  ): Array<string> {
    return (
      accessibleFeatures?.map((feature) => {
        return accessibleFeaturesMap[feature as HotelSpecialRequestsAccessibleFeaturesEnum] ?? '';
      }) ?? []
    );
  }

  public getRoomCheckInSpecialRequest({
    checkIn,
    checkInTime,
  }: {
    checkIn?: HotelSpecialRequestsCheckInEnum;
    checkInTime?: DateTimeLocal;
  }) {
    if (checkIn === HotelSpecialRequestsCheckInEnum.EarlyCheckIn) {
      if (!checkInTime) {
        return 'Early check-in';
      }

      return `Early check-in (Anticipated ${checkInTime.iso8601} Hrs)`;
    }

    if (checkIn === HotelSpecialRequestsCheckInEnum.LateCheckIn) {
      if (!checkInTime) {
        return `Late check-in`;
      }

      return `Late check-in (Anticipated ${checkInTime.iso8601} Hrs)`;
    }

    return '';
  }

  public getRoomFeaturesSpecialRequest(roomFeatures?: HotelSpecialRequestsRoomFeaturesEnum): string {
    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.AccessibleRoom) {
      return 'Accessible room';
    }
    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.Crib) {
      return 'Crib';
    }
    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.FeatherFreeRoom) {
      return 'Feather free room';
    }
    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.NearElevator) {
      return 'Near elevator';
    }

    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.RollawayBed) {
      return 'Rollaway bed';
    }

    return '';
  }

  public getRoomAmenities() {
    /**
     * Temporary type casting as data coming from API is not consistent with the types
     */
    return (this.hotelPnr?.room.amenities ?? []) as unknown as Array<{
      /**
       * Extra information about the room amenity
       * @type {string}
       * @memberof RoomAmenitiy
       */
      additionalInfo?: string;
      /**
       * Is amenity complimentary
       * @type {boolean}
       * @memberof RoomAmenitiy
       */
      isComplimentary?: boolean;
      /**
       * Room amenity type
       * @type {string}
       * @memberof RoomAmenitiy
       */
      type: RoomAmenitiyRoomAmenityTypeEnum;
    }>;
  }

  public specialRequests() {
    const hotelSpecialRequests = this.hotelPnr?.hotelSpecialRequests;

    const locationRequest = this.getRoomLocationSpecialRequest(first(hotelSpecialRequests?.roomLocations));

    const otherRequest = hotelSpecialRequests?.additionalNote;
    const arrivalFlight = hotelSpecialRequests?.flightNumber;
    const checkInRequest = this.getRoomCheckInSpecialRequest({
      checkIn: hotelSpecialRequests?.checkIn,
      checkInTime: hotelSpecialRequests?.checkInTime,
    });

    const roomFeaturesRequest = this.getRoomFeaturesSpecialRequest(first(hotelSpecialRequests?.roomFeatures));
    const supportedSpecialFeatures = this.getSupportedSpecialFeatures(hotelSpecialRequests?.accessibleFeatures);
    const showSpecialRequests =
      !!locationRequest ||
      !!otherRequest ||
      !!arrivalFlight ||
      !!checkInRequest ||
      !!roomFeaturesRequest ||
      (supportedSpecialFeatures?.length ?? -1) > 0;

    return {
      locationRequest,
      roomFeaturesRequest,
      checkInRequest,
      otherRequest,
      arrivalFlight,
      supportedSpecialFeatures,
      showSpecialRequests,
    };
  }

  public specialRequestsStrings(): string {
    const specialRequests = this.specialRequests();
    const specialRequestsString = [];
    if (specialRequests.locationRequest) {
      specialRequestsString.push(specialRequests.locationRequest);
    }
    if (specialRequests.roomFeaturesRequest) {
      specialRequestsString.push(specialRequests.roomFeaturesRequest);
    }
    if (specialRequests.checkInRequest) {
      specialRequestsString.push(specialRequests.checkInRequest);
    }
    if (specialRequests.otherRequest) {
      specialRequestsString.push(specialRequests.otherRequest);
    }
    if (specialRequests.arrivalFlight) {
      specialRequestsString.push(specialRequests.arrivalFlight);
    }
    if (specialRequests.supportedSpecialFeatures) {
      specialRequestsString.push(...specialRequests.supportedSpecialFeatures);
    }
    return specialRequestsString.join(', ');
  }

  public pnrTitle() {
    const hotelName = this.hotelName();
    const totalNights = this.totalNights();

    const text = pluralize('night', totalNights);

    const pnrTitle = defineCommonMessage('{{noOfDays}} {{text}} in {{hotelName}}');
    pnrTitle.values = {
      noOfDays: totalNights,
      text,
      hotelName,
    };
    return pnrTitle;
  }

  getHotelCancellationWorkflow(
    config: {
      isAgent?: boolean;
      device: DeviceType;
    } = {
      isAgent: false,
      device: DeviceType.WEB,
    },
  ): CancellationWorkflow {
    const { isAgent, device } = config;
    if (!this.hotelPnr) {
      return CancellationWorkflow.SUPPORT;
    }

    const isOutsideBooking = this.isOutsideBooking();

    const isCancelled = this.isPnrCancelled();
    // BE returns confirmed status if booking checking date and time is in future.
    // This check can not be done in FE so FE will be consuming below to determine future booking.
    const isUpcomingPnr = this.isPnrUpcoming();

    /** If pickup date < today's date, or PNR is already
     * cancelled, then disable the cancellation button
     */
    const isCancellable = !isCancelled && Boolean(isOutsideBooking || isUpcomingPnr);

    // @followup migrate this function to V3TripDetailsResponseManager or HotelPnrV3Manager
    // eslint-disable-next-line no-restricted-syntax
    return V2TripDetailsResponseManager.getCancellationWorkflow({
      isCancellable,
      isAgent: Boolean(isAgent),
      isOutsideBooking,
      device,
    });
  }

  /**
   * TRANSACTION RELATED METHODS
   */

  private getTransactionDetails(
    transactionType: TransactionType | undefined,
    items: TransactionItem[],
  ): HotelTransactionDetails {
    const hotelItem = items.find((item) => item.itemType === 'HOTEL_ITEM') as HotelItem;

    let description = defineCommonMessage('Hotel booking');

    if (transactionType === TransactionType.HotelModified) {
      description = defineCommonMessage('Hotel modification');
    } else if (transactionType === TransactionType.HotelCancelled) {
      description = defineCommonMessage('Hotel cancellation');
    }
    let rateType = hotelItem.rateType?.replaceAll('_', ' ').toLowerCase() ?? '';
    rateType = rateType.charAt(0).toUpperCase() + rateType.slice(1);

    return {
      description,
      hotelName: hotelItem.hotelData?.hotelName,
      rateType,
    };
  }

  getHotelTransactionPriceBreakdown(itemGroup: ItemGroup): HotelPriceBreakdown {
    const basePriceBreakdown = super.getTransactionPriceBreakdown(itemGroup);
    const hotelItem = (itemGroup.items || []).find((item) => item.itemType === 'HOTEL_ITEM') as HotelItem | undefined;

    const checkInDate = dateUtil(hotelItem?.checkInDateTime?.iso8601);

    const perNightAmount = (hotelItem?.nightlyRates || [])
      .map((amount, index) => {
        const dateString = localizeDate(checkInDate.add(index, 'days'), 'long') ?? '';

        return {
          date: dateString,
          amount: MoneyUtil.convertV2MoneyToMoneyUtil(amount),
        };
      })
      .filter((nightAmount) => !nightAmount.amount.isZero());

    const avgNightlyRate = MoneyUtil.convertV2MoneyToMoneyUtil(hotelItem?.averageNightlyRate);

    return {
      ...basePriceBreakdown,
      perNightAmount,
      avgNightlyRate,
      dueLaterAmount: MoneyUtil.convertV2MoneyToMoneyUtil(hotelItem?.postpaidAmount),
      dueNowAmount: MoneyUtil.convertV2MoneyToMoneyUtil(hotelItem?.prepaidAmount),
    };
  }

  getTransactions(isTripFeeEnhancementEnabled: boolean): (HotelPnrTransaction | TripServiceFeePnrTransaction)[] {
    const serviceFeeTransactions: TripServiceFeePnrTransaction[] =
      this.getServiceFeeTransactions(isTripFeeEnhancementEnabled);

    const travelerNamesMap: Record<string, string> = {};
    this.pnrData.pnrTravelers?.forEach((pnrTraveler) => {
      const travelerName = createUserNameFromFullName(pnrTraveler.personalInfo?.name);
      travelerNamesMap[pnrTraveler.userId.id] = travelerName;
    });

    const hotelTransactions: Transaction[] = this.pnrData.transactions || [];

    const relevantTransactionTypes = [
      TransactionType.HotelBooked,
      TransactionType.HotelModified,
      TransactionType.HotelCancelled,
    ];

    const hotelPnrTransactions: HotelPnrTransaction[] = hotelTransactions.flatMap((transaction) => {
      return (transaction.itemGroups || [])
        .filter((itemGroup) => relevantTransactionTypes.includes(itemGroup.transactionType))
        .map((itemGroup) => {
          const travelerNames = itemGroup.userId?.id
            ? [travelerNamesMap[itemGroup.userId.id]]
            : Object.values(travelerNamesMap);

          return {
            ...itemGroup,
            type: 'HOTEL',
            ctc: transaction.ctc || [],
            customerCharges: (transaction.ctc || []).map(this.getCustomerCharge),
            priceBreakdown: this.getHotelTransactionPriceBreakdown(itemGroup),
            transactionDetails: this.getTransactionDetails(itemGroup.transactionType, itemGroup.items || []),
            pnrId: this.pnrId,
            travelerNames,
          };
        });
    });

    return [...hotelPnrTransactions, ...serviceFeeTransactions];
  }
}
