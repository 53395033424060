import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceFeeTransactionType } from '@spotnana/types/openapi/models/service-fee-transaction-type';
import { ValueAddedServiceFeeType } from '@spotnana/types/openapi/models/value-added-service-fee-type';
import type { ITripFeeInfo } from '../../types/payment';

export enum TripFeeLabelTypeEnum {
  TRIP_FEES_STAR = 'TRIP_FEES_STAR',
  TRIP_FEES_TAXES = 'TRIP_FEE_TAXES',
  NON_REFUNDABLE = 'NON_REFUNDABLE',
  TRIP_FEE_BREAKDOWN = 'TRIP_FEE_BREAKDOWN',
}

export type GetPaymentTripFeeLabelParams =
  | {
      tripFeeLabelType: TripFeeLabelTypeEnum.TRIP_FEE_BREAKDOWN;
      tripFee: ITripFeeInfo;
    }
  | {
      tripFeeLabelType: Exclude<TripFeeLabelTypeEnum, TripFeeLabelTypeEnum.TRIP_FEE_BREAKDOWN>;
      tripFee?: never;
    };

interface IGetTripFeeBreakDownLabel {
  tripFee: ITripFeeInfo;
}

export const useGetPaymentTripFeeLabels = () => {
  const { t: tt } = useTranslation('COMMON');

  const getVasFeeLabel = useCallback(
    ({ tripFee }: IGetTripFeeBreakDownLabel) => {
      if (tripFee.subCategory === ValueAddedServiceFeeType.VirtualCardPayment) {
        return tt('Virtual payment fee');
      }
      if (tripFee.subCategory === ValueAddedServiceFeeType.UnusedCreditApplication) {
        return tt('Unused credits application fee');
      }
      if (tripFee.subCategory === ValueAddedServiceFeeType.PriceOptimization) {
        return tt('Price optimization fee');
      }
      return '';
    },
    [tt],
  );

  const getTransactionFeeLabel = useCallback(
    ({ tripFee }: IGetTripFeeBreakDownLabel) => {
      if (tripFee.subCategory === ServiceFeeTransactionType.Booking) {
        return tt('Booking fee');
      }
      if (tripFee.subCategory === ServiceFeeTransactionType.Modification) {
        return tt('Modification fee');
      }
      if (tripFee.subCategory === ServiceFeeTransactionType.Cancellation) {
        return tt('Cancellation fee');
      }
      return '';
    },
    [tt],
  );

  const getTripFeeBreakDownLabel = useCallback(
    ({ tripFee }: IGetTripFeeBreakDownLabel) => {
      const isBookingFee = tripFee.feeType === 'BOOKING_FEE';
      const isTransactionFee = tripFee.subType === 'TRANSACTION_FEE';
      const isVasFee = tripFee.subType === 'VALUE_ADDED_SERVICE_FEE';
      if (isBookingFee && isTransactionFee) {
        return getTransactionFeeLabel({ tripFee });
      }
      if (isBookingFee && isVasFee) {
        return getVasFeeLabel({ tripFee });
      }
      if (isBookingFee && tripFee.subType === 'TRIP_FEE') {
        return tt('$t(Trip fee)');
      }
      return '';
    },
    [tt, getTransactionFeeLabel, getVasFeeLabel],
  );

  const getPaymentTripFeeLabels = useCallback(
    ({ tripFeeLabelType, tripFee }: GetPaymentTripFeeLabelParams): string => {
      switch (tripFeeLabelType) {
        case TripFeeLabelTypeEnum.TRIP_FEES_STAR:
          return tt('$t(Trip fees)*');
        case TripFeeLabelTypeEnum.TRIP_FEES_TAXES:
          return tt('$t(Trip fee) taxes');
        case TripFeeLabelTypeEnum.NON_REFUNDABLE:
          return tt('*Non-refundable');
        case TripFeeLabelTypeEnum.TRIP_FEE_BREAKDOWN:
          return getTripFeeBreakDownLabel({ tripFee });
        default:
          return '';
      }
    },
    [tt, getTripFeeBreakDownLabel],
  );
  return { getPaymentTripFeeLabels };
};
