/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Pnr Policy id
 * @export
 * @interface PnrPolicyId
 */
export interface PnrPolicyId {
  /**
   * Policy id
   * @type {string}
   * @memberof PnrPolicyId
   */
  id: string;
  /**
   * version of the policy
   * @type {string}
   * @memberof PnrPolicyId
   */
  version: string;
  /**
   * Name of the policy applied.
   * @type {string}
   * @memberof PnrPolicyId
   */
  policyName?: string;
  /**
   *
   * @type {string}
   * @memberof PnrPolicyId
   */
  approvalType?: PnrPolicyIdApprovalTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PnrPolicyId
   */
  policyType?: PnrPolicyIdPolicyTypeEnum;
}

export const PnrPolicyIdApprovalTypeEnum = {
  HardApproval: 'HARD_APPROVAL',
  SoftApproval: 'SOFT_APPROVAL',
  PassiveApproval: 'PASSIVE_APPROVAL',
} as const;

export type PnrPolicyIdApprovalTypeEnum =
  (typeof PnrPolicyIdApprovalTypeEnum)[keyof typeof PnrPolicyIdApprovalTypeEnum];
export const PnrPolicyIdPolicyTypeEnum = {
  Global: 'GLOBAL',
  Default: 'DEFAULT',
  Group: 'GROUP',
} as const;

export type PnrPolicyIdPolicyTypeEnum = (typeof PnrPolicyIdPolicyTypeEnum)[keyof typeof PnrPolicyIdPolicyTypeEnum];
