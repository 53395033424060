import type { GetTripSummariesRequest } from '@spotnana/types/openapi/models/get-trip-summaries-request';
import type { GetTripSummariesResponse } from '@spotnana/types/openapi/models/get-trip-summaries-response';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useRef } from 'react';
import api from '../api';
import type SpotnanaError from '../api/SpotnanaError';
import { TRIPS_PER_PAGE } from '../constants/trips';
import type { CompanyId, IUserOrgId, UserOrgIdOrNull } from '../types';

import type { ListTripSummariesResponse, TripCategoryRequest, TripFilter } from '../types/api/v1/obt/trip/list_trips';

import { removeEmptyValuesFromObject } from '../utils';
import { defaultQueryClient } from './defaultQueryClient';

type TTripSummariesKey = {
  userOrgId: UserOrgIdOrNull;
  type?: TripCategoryRequest;
  pageIndex?: number;
  pageSize?: number;
  showRelatedTrips?: boolean;
  companyIdFilter?: CompanyId;
  userIdFilter?: UserOrgIdOrNull;
};

export const tripSummariesKey = ({
  userOrgId,
  type,
  pageIndex,
  pageSize,
  showRelatedTrips,
  companyIdFilter,
  userIdFilter,
}: TTripSummariesKey): unknown[] => {
  const optional = omitBy({ type, pageIndex, pageSize, showRelatedTrips }, isNil);
  const userId = userOrgId?.userId?.id || '';
  const companyID = companyIdFilter ? companyIdFilter.id : '';
  const userIdFilterId = userIdFilter?.userId?.id || '';
  return (['trip-summary', userId, companyID, userIdFilterId] as unknown[]).concat(isEmpty(optional) ? [] : [optional]);
};

export const invalidateTripSummaries = (userOrgId: IUserOrgId): Promise<void> =>
  defaultQueryClient.invalidateQueries({ queryKey: tripSummariesKey({ userOrgId }) });

interface FetchTripSummariesInternalInput {
  userOrgId: UserOrgIdOrNull;
  pageIndex?: number;
  pageSize?: number;
  tripType?: TripCategoryRequest;
  showRelatedTrips: boolean;
  companyIdFilter?: CompanyId;
  userIdFilter?: UserOrgIdOrNull;
}

const fetchTripSummariesInternal = async ({
  userOrgId,
  pageIndex,
  pageSize = TRIPS_PER_PAGE,
  tripType,
  showRelatedTrips,
  companyIdFilter,
  userIdFilter,
}: FetchTripSummariesInternalInput): Promise<ListTripSummariesResponse> => {
  const startTripIndex = pageIndex !== undefined ? (pageIndex - 1) * pageSize : undefined;
  const filters: TripFilter[] = [];

  if (companyIdFilter) {
    filters.push({ companyFilter: { companyId: companyIdFilter } });
  }

  if (userIdFilter) {
    filters.push({ userFilter: { userId: userIdFilter.userId } });
  }
  const tripSummariesResponse = api('POST', 'listTripSummariesV1', {
    data: {
      userOrgId: userOrgId ? removeEmptyValuesFromObject(userOrgId) : undefined,
      startTripIndex,
      noOfTrips: pageSize,
      tripType,
      showRelatedTrips,
      filters,
    },
  });
  return tripSummariesResponse as unknown as ListTripSummariesResponse;
};

interface UseTripDetailsQueryInput {
  userOrgId: UserOrgIdOrNull;
  enabled?: boolean;
  params: { pageIndex?: number; pageSize?: number; type?: TripCategoryRequest };
  showRelatedTrips: boolean;
  companyIdFilter?: CompanyId;
  userIdFilter?: UserOrgIdOrNull;
}

export const useTripSummariesQuery = ({
  userOrgId,
  enabled = true,
  params = {},
  showRelatedTrips,
  companyIdFilter,
  userIdFilter,
}: UseTripDetailsQueryInput) =>
  useQuery<ListTripSummariesResponse, SpotnanaError>({
    queryKey: tripSummariesKey({
      userOrgId,
      type: params.type,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      showRelatedTrips,
      companyIdFilter,
      userIdFilter,
    }),
    queryFn: () =>
      fetchTripSummariesInternal({
        userOrgId,
        pageIndex: params.pageIndex,
        pageSize: params.pageSize,
        tripType: params.type,
        showRelatedTrips,
        companyIdFilter,
        userIdFilter,
      }),
    enabled,
  });

interface UseTripSummariesInfiniteQueryInput {
  userOrgId: UserOrgIdOrNull;
  type: TripCategoryRequest;
  showRelatedTrips: boolean;
}

export const useTripSummariesInfiniteQuery = ({
  userOrgId,
  type,
  showRelatedTrips,
}: UseTripSummariesInfiniteQueryInput) => {
  const pageNumberRef = useRef(1);

  return useInfiniteQuery<ListTripSummariesResponse, SpotnanaError>({
    queryKey: tripSummariesKey({
      userOrgId,
      type,
      showRelatedTrips,
    }),
    queryFn: ({ pageParam }) => {
      // If starting from scratch, reset the page number, else increment it
      const pageNumber = !pageParam ? 1 : pageNumberRef.current + 1;
      pageNumberRef.current = pageNumber;

      return fetchTripSummariesInternal({ userOrgId, pageIndex: pageNumber, tripType: type, showRelatedTrips });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      const totalPages = lastPage.paginationParams?.totalNumPages ?? 1;
      if (pageNumberRef.current < totalPages) {
        return { pageNumber: pageNumberRef.current };
      }
      return undefined;
    },
  });
};

export const getListTripSummariesKey = (companyId: string, tripIds: string[]) => [
  'listTripSummaries',
  { companyId, tripIds },
];

export const useListTripSummaries = <SelectedData = GetTripSummariesResponse>(
  { companyId = '', tripIds }: { companyId?: string; tripIds: string[] },
  options?: Omit<UseQueryOptions<GetTripSummariesResponse, SpotnanaError, SelectedData>, 'queryKey' | 'queryFn'>,
) => {
  const requestData: GetTripSummariesRequest = {
    companyId,
    tripIds,
  };

  return useQuery({
    queryKey: getListTripSummariesKey(companyId, tripIds),
    queryFn: (() =>
      api('POST', 'listTripSummariesV2', {
        // urlParam: '/list',
        data: requestData,
      })) as () => Promise<GetTripSummariesResponse>,
    enabled: Boolean(companyId) && tripIds.length > 0,
    ...options,
  });
};
