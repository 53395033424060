import type { TFunction } from 'react-i18next';
import { type CancellationBreakupComponent } from '../../types';
import type { ICancellationFareBreakupInfo } from '../../types/Flights/pnrCancellation';
import { CancellationBreakupFontEnum, CancellationColorEnum } from '../../types/Flights/pnrCancellation';
import { localisedCurrencyValue, localisedOriginalCurrencyValue } from '../Flights/fareBreakupUtils';
import type { MoneyUtil } from '../Money';
import { localizationKeys } from '../../constants/common';
import { TripFeeLabels } from '../../constants/paymentTripFees';
import { getPaymentTripFeeTypeLabel } from '../payment/getPaymentTripFeeLabel';

const { LOCALIZED_CURRENCY } = localizationKeys;

export const getServiceFeeTripFareBreakup = ({
  serviceFees,
  t,
  tCommon,
}: Pick<ICancellationFareBreakupInfo, 'serviceFees'> & { t: TFunction; tCommon: TFunction }) => {
  const fareBreakupNodes: CancellationBreakupComponent[] = [];
  const getValue = (amount: MoneyUtil) => {
    return t(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(amount));
  };
  const getCurrencyValue = (value: MoneyUtil) => {
    return t(LOCALIZED_CURRENCY, localisedCurrencyValue(value));
  };
  const shouldShowServiceFeeBreakup = serviceFees && serviceFees.showWithFeeBreakup && !serviceFees.total.isZero();
  if (!shouldShowServiceFeeBreakup) {
    return fareBreakupNodes;
  }
  const childrenArray: CancellationBreakupComponent[] = [];

  const serviceFeesTotalDisplayAmount = serviceFees.isTotalHavingDifferentCurrency
    ? `~${getCurrencyValue(serviceFees.total)}`
    : getValue(serviceFees.total);

  serviceFees.tripFees?.forEach((tripFee) => {
    if (!tripFee.base.isZero()) {
      childrenArray.push({
        label: tCommon(getPaymentTripFeeTypeLabel(tripFee)),
        value: getValue(tripFee.base),
        variation: CancellationBreakupFontEnum.NORMAL,
      });
    }
  });
  if (serviceFees.showTax) {
    childrenArray.push({
      label: tCommon(TripFeeLabels.TRIP_FEES_TAXES),
      value: getValue(serviceFees.tax),
      variation: CancellationBreakupFontEnum.NORMAL,
    });
  }
  childrenArray.push({
    label: tCommon(TripFeeLabels.NON_REFUNDABLE),
    variation: CancellationBreakupFontEnum.LABEL,
  });

  fareBreakupNodes.push({
    variation: CancellationBreakupFontEnum.DIVIDER,
  });
  fareBreakupNodes.push({
    label: tCommon(TripFeeLabels.TRIP_FEES_STAR),
    variation: CancellationBreakupFontEnum.HEADING,
    value: serviceFeesTotalDisplayAmount,
    colorVariation: CancellationColorEnum.NORMAL,
    children: childrenArray,
  });
  return fareBreakupNodes;
};
