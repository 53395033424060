import type { MoneyUtil } from '../../utils/Money';
import type { AirCancellationNotSupportedReason } from '../api/v2/obt/model/air-cancellation-not-supported-reason';
import type { AirCancellationState } from '../api/v2/obt/model/air-cancellation-state';
import type { AirFareInfo } from '../api/v2/obt/model/air-fare-info';
import type { IServiceFeeData } from '../payment';

interface ICancellationAdditionalInfo {
  heading: string;
  subText: string;
}

export enum CancellationBreakupFontEnum {
  HEADING = 'HEADING',
  SUB_HEADING = 'SUB_HEADING',
  DIVIDER = 'DIVIDER',
  NORMAL = 'NORMAL',
  LABEL = 'LABEL',
  BANNER = 'BANNER',
  ONLY_VALUE = 'ONLY_VALUE',
}

export enum CancellationColorEnum {
  SUCCESS = 'SUCCESS',
  NORMAL = 'NORMAL',
}

export type CancellationBreakupComponent =
  | {
      label: string;
      value: string;
      variation: CancellationBreakupFontEnum.HEADING;
      children?: CancellationBreakupComponent[];
      colorVariation: CancellationColorEnum;
      valueSubText?: string;
    }
  | {
      label: string;
      value: string;
      variation: CancellationBreakupFontEnum.NORMAL;
      children?: never;
    }
  | {
      variation: CancellationBreakupFontEnum.DIVIDER;
      children?: never;
    }
  | {
      label: string;
      value?: string;
      children?: CancellationBreakupComponent[];
      variation: CancellationBreakupFontEnum.SUB_HEADING;
    }
  | {
      value: string;
      variation: CancellationBreakupFontEnum.BANNER;
      children?: never;
    }
  | {
      label: string;
      variation: CancellationBreakupFontEnum.LABEL;
      children?: never;
    }
  | {
      variation: CancellationBreakupFontEnum.ONLY_VALUE;
      value: string;
      children?: never;
    };

export interface IUsePnrCancellationInfo {
  voidDuration?: string;
  cancellationBannerText: string;
  cancellationButtonText: string;
  modeOfPaymentInfo?: ICancellationAdditionalInfo;
  additionalInformation?: ICancellationAdditionalInfo;
  fareBreakupConfig: CancellationBreakupComponent[];
  cancellationState: AirCancellationState | undefined;
  cancellationNotSupportedReasons: AirCancellationNotSupportedReason[];
  updateRefundMode: (optionId: string) => void;
  selectedRefundOptionId: string;
  applicableRefundModes: IRefundModeDetails[];
  selectedRefundMode: RefundModeEnum | undefined;
  optionId?: string;
  shouldContactSupport: boolean;
  flightScheduleChangeWarning: string;
  airlineCancellationFee: MoneyUtil;
  totalRefund: MoneyUtil | undefined;
}

export interface IGetCancellationProps {
  isNonRefundableNonExchangeable: boolean;
  hasMultipleRefundModes: boolean;
  cancellationState: AirCancellationState | undefined;
  isSeat1ATraveler: boolean;
  cancellationNotSupportedPriorityReasons: AirCancellationNotSupportedReason[];
  isAgent: boolean;
}

export enum RefundModeEnum {
  ORIGINAL_PAYMENT_MODE = 'ORIGINAL_PAYMENT_MODE',
  FLIGHT_CREDITS = 'FLIGHT_CREDITS',
  MIXED_PAYMENT_MODE = 'MIXED_PAYMENT_MODE',
}

export interface IRefundModeDetails {
  refundMode: RefundModeEnum;
  creditAmount: MoneyUtil;
  refundAmount: MoneyUtil;
  changeFee: MoneyUtil;
  optionId?: string;
}

export interface RefundData {
  refundAmount: MoneyUtil;
  creditAmount: MoneyUtil;
}

export interface ICancellationFareBreakupInfo {
  totalPaid: MoneyUtil;
  totalDeductions: MoneyUtil;
  airlineCancellationFee: MoneyUtil;
  totalRefund: MoneyUtil;
  airlineCreditValue: MoneyUtil;
  airlineChangeFee: MoneyUtil;
  fareInfo: AirFareInfo;
  showBrexPoints: boolean;
  selectedRefundMode?: RefundModeEnum;
  cancellationState?: AirCancellationState;
  serviceFees: IServiceFeeData;
}
