import type { AgentLink } from '@spotnana/types/openapi/models/agent-link';
import type { CreateAgentLinkRequest } from '@spotnana/types/openapi/models/create-agent-link-request';
import type { CreateAgentLinkResponse } from '@spotnana/types/openapi/models/create-agent-link-response';
import type { ListAgentLinksResponse } from '@spotnana/types/openapi/models/list-agent-links-response';
import type { ReorderAgentLinksRequest } from '@spotnana/types/openapi/models/reorder-agent-links-request';
import type { UpdateAgentLinkRequest } from '@spotnana/types/openapi/models/update-agent-link-request';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import { defaultQueryClient } from './defaultQueryClient';

// Fetches links
const listLinks = async ({
  isPrivate,
  userId,
  tmcId,
}: {
  userId: string | undefined;
  tmcId: string | undefined;
  isPrivate: boolean;
}): Promise<ListAgentLinksResponse> => {
  try {
    // Make an API call to retrieve links
    const data = await api('GET', isPrivate ? 'agentDesktopAgents' : 'agentDesktopTMCs', {
      urlParam: `/${isPrivate ? userId : tmcId}/links`,
    });
    return data as ListAgentLinksResponse;
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Unique key for caching listLinks query data
const listLinksKey = (isPrivate: boolean) => ['listLinks', isPrivate ? 'private' : 'team'];

// Custom hook for using listLinks with React Query
export const useListLinks = (
  { userId, tmcId, isPrivate }: { userId: string | undefined; tmcId: string | undefined; isPrivate: boolean },
  options?: Omit<UseQueryOptions<ListAgentLinksResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    queryKey: listLinksKey(isPrivate),
    queryFn: () => listLinks({ userId, tmcId, isPrivate }),
    ...options,
  });
};

// Adds a new link
const addLink = async ({
  isPrivate,
  request,
  userId,
  tmcId,
}: {
  userId: string | undefined;
  tmcId: string | undefined;
  isPrivate: boolean;
  request: CreateAgentLinkRequest;
}): Promise<CreateAgentLinkResponse> => {
  try {
    // Make an API call to add a link
    const data = await api('POST', isPrivate ? 'agentDesktopAgents' : 'agentDesktopTMCs', {
      urlParam: `/${isPrivate ? userId : tmcId}/links`,
      data: request,
    });
    return data as CreateAgentLinkResponse;
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Custom hook for using addLink with React Query
export const useAddLink = (isPrivate: boolean) => {
  return useMutation({
    mutationFn: addLink,
    onSuccess: () => {
      // Invalidate the cache for listLinks query when a new link is successfully added
      defaultQueryClient.invalidateQueries({
        queryKey: listLinksKey(isPrivate),
      });
    },
  });
};

// Updates an existing link
const updateLink = async ({
  id,
  userId,
  tmcId,
  isPrivate,
  request,
}: {
  id: string;
  userId: string | undefined;
  tmcId: string | undefined;
  isPrivate: boolean;
  request: UpdateAgentLinkRequest;
}): Promise<void> => {
  try {
    // Make an API call to update a link
    await api('PUT', isPrivate ? 'agentDesktopAgents' : 'agentDesktopTMCs', {
      urlParam: `/${isPrivate ? userId : tmcId}/links/${id}`,
      data: request,
    });
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Custom hook for using updateLink with React Query
export const useUpdateLink = (isPrivate: boolean) => {
  return useMutation({
    mutationFn: updateLink,
    onSuccess: () => {
      // Invalidate the cache for listLinks query when a link is successfully updated
      defaultQueryClient.invalidateQueries({
        queryKey: listLinksKey(isPrivate),
      });
    },
  });
};

// Deletes an existing link
const deleteLink = async ({
  isPrivate,
  linkId,
  userId,
  tmcId,
}: {
  userId: string | undefined;
  tmcId: string | undefined;
  isPrivate: boolean;
  linkId: string;
}): Promise<void> => {
  try {
    // Make an API call to remove a link
    await api('DELETE', isPrivate ? 'agentDesktopAgents' : 'agentDesktopTMCs', {
      urlParam: `/${isPrivate ? userId : tmcId}/links/${linkId}`,
    });
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Custom hook for using deleteLink with React Query
export const useDeleteLink = (isPrivate: boolean) => {
  return useMutation({
    mutationFn: deleteLink,
    onSuccess: () => {
      // Invalidate the cache for listLinks query when a link is successfully removed
      defaultQueryClient.invalidateQueries({
        queryKey: listLinksKey(isPrivate),
      });
    },
  });
};

// Reorders links
const reorderLinks = async ({
  links,
  isPrivate,
  userId,
  tmcId,
}: {
  userId: string | undefined;
  tmcId: string | undefined;
  links: Array<AgentLink>;
  isPrivate: boolean;
}): Promise<void> => {
  try {
    // Make an API call to reorder links
    await api('POST', isPrivate ? 'agentDesktopAgents' : 'agentDesktopTMCs', {
      urlParam: `/${isPrivate ? userId : tmcId}/links/reorder`,
      data: { linkIds: links.map((link) => link.id) } as ReorderAgentLinksRequest,
    });
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Custom hook for using reorderLinks with React Query
export const useReorderLinks = (isPrivate: boolean) => {
  return useMutation({
    mutationFn: reorderLinks,
    onSuccess: () => {
      // Invalidate the cache for listLinks query when links are successfully reordered
      defaultQueryClient.invalidateQueries({
        queryKey: listLinksKey(isPrivate),
      });
    },
  });
};
