import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import isUndefined from 'lodash/isUndefined';
import uniq from 'lodash/uniq';
import isNil from 'lodash/isNil';

import type { TFunction } from 'react-i18next';
import type { PostalAddress as PostalAddressNewTypes } from '@spotnana/types/openapi/models/postal-address';
import { CustomFieldType as CustomFieldTypeV2 } from '@spotnana/types/openapi/models/custom-field-type';
import { availableLogoHotelBrands, availableLogoHotelParentChains } from '../constants/hotels/brands';
import { CustomFieldType as CustomFieldTypeV1 } from '../types/api/v1/obt/policy/user_defined_entity';
import type { BookingHistory } from '../types/api/v2/obt/model/booking-history';
import { BookingInfoStatusEnum } from '../types/api/v2/obt/model/booking-info';
import { WeightUnitEnum } from '../types/api/v2/obt/model/weight';
import { userRolesByFeature } from '../constants/rolesAccess';
import { airlinesMap } from '../constants/flights/airlines';
import { getCountryName } from '../constants/countryList';
import { devIDs, HTML_REGEXP, localizationKeys, mapNameSuffixFromV1ToV2 } from '../constants/common';
import { hotelLoyaltyMap } from '../constants/profile/loyalty/hotel';
import { carLoyaltyMap } from '../constants/profile/loyalty/car';
import { airlineLoyaltyMap } from '../constants/profile/loyalty/air';
import type { IConstValue, IFlightCodeDetails } from '../types/flight';
import type { IPoint, MarkerBounds } from '../types/dutyOfCare';
import type { IPostalAddress, ITravelerLoyaltyInfo, IUserOrgId } from '../types/traveler';
import { storage } from './Storage';
import { Currencies } from './Money/currencies';
import { MoneyUtil } from './Money';
import type { AuthenticatedUserBasicInfoExistingUser } from '../types/api/v1/auth/services';
import { CounterLocation } from '../types/api/v1/obt/car/car_common';
import type { RoleInfo } from '../types/api/v1/obt/profile/role/roles_info';
import { dateUtil, isFutureDate } from '../date-utils';
import type { PnrBookingHistory } from '../types/api/v1/obt/pnr/pnr';
import { PnrBookingHistoryBookingStatus } from '../types/api/v1/obt/pnr/pnr';
import type { ImageV1 } from '../types/api/v1/common/image_group';
import type { Name as NameV2 } from '../types/api/v2/obt/model/name';
import type { Name as NameV1 } from '../types/api/v1/common/name';
import type { Latlng, PostalAddress } from '../types';
import { PreferredType, RateTypeV1, StorageKeys } from '../types';
import logger from './logger';
import { defineMessage } from '../translations/defineMessage';
import { TravelClassHierarchy } from '../types/api/v1/obt/policy/policy_common';
import {
  AMTRAK_RAIL_CARRIER_NAME,
  ACELA_RAIL_CARRIER_NAME,
  RENFE_RAIL_CARRIER_NAME,
  AVLO_RAIL_TRANSPORT_NAME,
} from '../constants/rails';
import Config from './Config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const serializeJSON = (jsonObject: Record<string, any>): any => JSON.parse(JSON.stringify(jsonObject));

export const safeJsonStringify = (val: any, fallback: string): string => {
  try {
    return JSON.stringify(val);
  } catch (e) {
    return fallback;
  }
};

export const underScoreToSpace = (str: string): string => str?.replace(/_/g, ' ') ?? '';

export const spaceToUnderscore = (str: string): string => str?.replace(/ /g, '_') ?? '';

export const removeSpecialCharacterExceptUnderscore = (str: string): string => str?.replace(/\W+/g, '') ?? '';

/**
 * Remove diacritic symbols from the string via 2-step process:
 * 1. Decompose each symbol of the string using Normalization Form Canonical Decomposition, e.g. `ÖBB` -> `O¨BB`
 * 2. Replace all diacritic symbols that have `u30x ... u036x` codes in the Unicode system
 *
 * @param str - any string
 * @returns the same string but with all diacritics removed
 */
export const removeDiacritics = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const underScoreToDash = (str: string): string => str?.replace(/_/g, '-') ?? '';

export const capitalizeFirstLetter = (str: string): string => str[0].toUpperCase() + str.slice(1);
/**
 * .replace() symbol with currencyMap symbol as Intl.NumberFormat returns CUP
 * for CUP while, currencyMap returns $MN. Hence, there is a currency
 * symbol mismatch b/w results and checkout page.
 * .replace() fixes that issue
 */
export const localeCurrencyFormat = (
  num: number,
  currency: string,
  locale?: string,
  maximumFractionDigits?: number,
  minimumFractionDigits = 0,
): string => {
  const { symbol } = Currencies[currency];
  return (
    // eslint-disable-next-line no-restricted-syntax
    new Intl.NumberFormat(locale || 'en-US', {
      style: 'currency',
      currency,
      maximumFractionDigits: maximumFractionDigits ?? 0,
      minimumFractionDigits,
      currencyDisplay: 'symbol',
    })
      // eslint-disable-next-line no-restricted-syntax
      .format(num)
      .replace(currency, symbol)
  );
};

export const getFullAssetPath = (path: string, format: string) => `${(Config.VITE_PUBLIC_URL ?? '') + path}.${format}`;

export const getAirlineLogo = (airlineCode: string): string =>
  airlineCode ? getFullAssetPath(`/assets/images/airlines/${airlineCode}`, 'png') : '';

export const getHotelChainLogo = (hotelChain: string): string =>
  hotelChain ? getFullAssetPath(`/assets/images/hotel-groups/${hotelChain}`, 'svg') : '';

export const getHotelParentChainLogo = (hotelParentChain: string): string =>
  hotelParentChain ? getFullAssetPath(`/assets/images/hotel-parent-chains/${hotelParentChain}`, 'png') : '';

export const getHotelBrandLogo = (hotelBrand: string): string =>
  hotelBrand ? getFullAssetPath(`/assets/images/hotel-brands/${hotelBrand}`, 'png') : '';

export const getHotelLogo = (hotelChainOrBrandCode: string): string => {
  if (hotelChainOrBrandCode) {
    if (availableLogoHotelBrands.includes(hotelChainOrBrandCode)) {
      return getHotelBrandLogo(hotelChainOrBrandCode);
    }
    if (availableLogoHotelParentChains.includes(hotelChainOrBrandCode)) {
      return getHotelParentChainLogo(hotelChainOrBrandCode);
    }
  }

  return getFullAssetPath(`/email-assets/icons/hotel_building_fill`, `png`);
};

export const getBrandIcon = (brand: string): string =>
  getFullAssetPath(`/assets/images/brands/${brand.toLowerCase()}`, 'svg');

export const getAllianceLogo = (allianceName: string): string =>
  allianceName
    ? getFullAssetPath(`/assets/images/alliances/${allianceName.replace(' ', '-').toLowerCase()}`, 'svg')
    : '';

export const getAmenitiesLogo = (amenityName: string): string =>
  amenityName ? getFullAssetPath(`/assets/images/amenities/${amenityName.toLowerCase()}`, 'svg') : '';

export const getCarTypeLabel = (type: string): string => underScoreToSpace(type).toLowerCase();

/** @deprecated Move to getCarVendorLogoV2 */
export const getCarVendorLogo = (vendorCode: string, format = 'svg'): string =>
  getFullAssetPath(`/assets/images/car-vendors/${vendorCode}`, format);

export const getCarVendorLogoV2 = (vendorCode: string): string =>
  getFullAssetPath(`/v1-assets/images/car-vendors/${vendorCode}`, 'svg');

export const getRailCarriersLogo = (
  carrierName: string,
  transportName: string,
  isAmtrakCarrier = false,
  format = 'svg',
): string => {
  if (isAmtrakCarrier && carrierName !== ACELA_RAIL_CARRIER_NAME) {
    return getFullAssetPath(`/v1-assets/images/rail-carriers/${AMTRAK_RAIL_CARRIER_NAME}`, format);
  }

  const normalizedCarrierName = removeDiacritics(carrierName);
  const normalizedTransportName = removeDiacritics(transportName);

  const carrierImageMap: Record<string, Record<string, string>> = {
    [RENFE_RAIL_CARRIER_NAME]: {
      [AVLO_RAIL_TRANSPORT_NAME]: 'Renfe Avlo',
    },
  };

  const imagePath = carrierImageMap[normalizedCarrierName]?.[normalizedTransportName] ?? normalizedCarrierName;

  return getFullAssetPath(`/v1-assets/images/rail-carriers/${imagePath}`, format);
};

export const getRailOperatorsLogo = (railOperator: string, format = 'png'): string => {
  if (format === 'svg') {
    return getFullAssetPath(`/v1-assets/images/rail-carriers/Amtrak`, format);
  }
  return getFullAssetPath(`/v1-assets/images/rail-operators/${railOperator}`, format);
};

export const getCarTypeLogo = (carType: string): string =>
  carType ? getFullAssetPath(`/assets/images/car-types/${carType.trim().toLowerCase()}`, 'png') : '';

/**
 * This is temporary method to serve default image for missing limo images.
 * In future we would introduce something like car-images.
 */
export const getDefaultLimoImage = (): string => getFullAssetPath(`/assets/images/car-images/OTHER`, 'jpg');

export const paymentCardLogoAssetPath = `/assets/images/card-companies`;

export const getPaymentCardLogo = (cardIssuer: string): string =>
  cardIssuer ? getFullAssetPath(`${paymentCardLogoAssetPath}/${cardIssuer.toLowerCase()}`, 'svg') : '';

/**
 * Gets the country flag based on a countryCode
 * @param countryCode Expects a 2 char ISO 3166-1 country code
 * @returns Country Flag emoji | 🇶🇼 (ASCII characters if country code is invalid) |
 *   countryCode (if countryCode is not 2 chars)
 */
export const getCountryFlag = (countryCode: string): string => {
  if (countryCode.length === 2) {
    const chars = [...countryCode.toUpperCase()].map((c) => c.charCodeAt(0) + 127397);
    return String.fromCodePoint(...chars);
  }
  return countryCode;
};

export const getTwoDigitNumber = (number: string | number): string => `0${number}`.slice(-2);

export const splitInGroupsOfFour = (str: string): string[] | null => str.match(/.{1,4}/g);

interface maskCardNumberProps {
  split?: boolean;
}

export const maskCardNumber = (cardNumber: string, { split = true }: maskCardNumberProps = {}): string => {
  const displayNumber = cardNumber.length > 16 ? cardNumber.substring(cardNumber.length - 16) : cardNumber;

  // Replace everything except the last four characters with asterisk
  const data = displayNumber.replace(/.(?=.{4})/g, '*');
  let result = '';
  if (split) {
    result = splitInGroupsOfFour(data)?.join(' ') ?? '';
  } else {
    result = data;
  }
  return result;
};

export const maskTsaNumber = (str: string): string => {
  if (str.length <= 4) {
    return str; // If string length is 4 or less, return the string as it is
  }
  const lastFourChars = str.slice(-4); // Extract last four characters
  const allStars = '*'.repeat(str.length - 4);
  return allStars + lastFourChars;
};

export const getCardTitle = (name: string | undefined, label: string | undefined): string => {
  return label || name || '';
};

export const formatExpiryDate = (expiryMonth: number | undefined, expiryYear: number | undefined): string => {
  const formattedMonth = expiryMonth ? getTwoDigitNumber(expiryMonth) : '--';
  const formattedYear = expiryYear ?? '--';
  return `${formattedMonth}/${formattedYear}`;
};

export const maskUscId = (cardNumber: string): string => {
  const data = `${cardNumber}`.slice(0, -3).replace(/./g, '*') + `${cardNumber}`.slice(-3);
  return data;
};

export const joinTruthyValues = (map: Record<string, unknown>, delimiter: string): string =>
  Object.values(map)
    .filter((value) => !!value && !!String(value).trim())
    .join(delimiter);

export const titleCase = (string: string): string =>
  string
    .toLocaleLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toLocaleUpperCase() + word.slice(1))
    .join(' ');

/**
 * sentenceCase turns the passed string (ideally a single sentence) into, well... "sentenceCase" 😛
 *
 * @example
 * // returns 'This is a simple function. do you even need documentation?'
 * sentenceCase('this IS a simple function. Do YOU even need documentation?')
 */
export const sentenceCase = (string: string): string =>
  string.length > 0 ? string[0].toUpperCase() + string.slice(1).toLowerCase() : '';

/**
 * Turns the passed word into its correct form singular/plural. You can pass in a custom replacement function,
 * default is appending a `s` at the end of the word
 *
 * @example
 * // returns Frontends
 * pluralize('Frontend', 3)
 *
 * @example
 * // returns Radii
 * pluralize('Radius', 0, word => word.replace('ius', 'ii'))
 */
export const pluralize = (
  word: string,
  count: number,
  fn = (originalWord: string): string => `${originalWord}s`,
): string => (count === 1 ? word : fn(word));

export const isTestingAccount = (email: string | null): boolean =>
  devIDs.some((devID) => Boolean(email?.endsWith(devID)));

export const isSpotnanaAccount = (email: string | null): boolean => Boolean(email?.endsWith('@spotnana.com'));

export type PasswordStrengthParameters = {
  lower: boolean;
  upper: boolean;
  number: boolean;
  special: boolean;
  length: boolean;
  noWhitespace: boolean;
};

export const validatePasswordStrength = (password: string): PasswordStrengthParameters => ({
  lower: /[a-z]/.test(password),
  upper: /[A-Z]/.test(password),
  number: /\d/.test(password),
  special: /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/.test(password),
  length: /.{8,}/.test(password),
  noWhitespace: !/\s/.test(password),
});

export const isValidPassword = (password: string): boolean =>
  Object.values(validatePasswordStrength(password)).every(Boolean);

export const isObject = (value: unknown): value is Record<string, unknown> => {
  if (typeof value !== 'object') {
    return false;
  }
  // `typeof` for Arrays and null also return `"object"`
  if (Array.isArray(value) || value === null) {
    return false;
  }
  return true;
};

export const removeEmptyValuesFromObject = (obj: unknown): any => {
  // If the object is not an object, return it as is
  if (!isObject(obj)) {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_key, value]) => {
        // Filter out null values
        if (value === null) {
          return false;
        }
        // Filter out empty strings
        if (value === '') {
          return false;
        }
        // Filter out empty arrays
        if (Array.isArray(value) && value.length === 0) {
          return false;
        }
        return true;
      })
      .map(([key, value]) => [key, isObject(value) ? removeEmptyValuesFromObject(value) : value]),
  );
};

export const parseToUserOrgId = (userOrgIdString: string | undefined): IUserOrgId | undefined => {
  if (!userOrgIdString) {
    return undefined;
  }

  try {
    return JSON.parse(userOrgIdString);
  } catch (e) {
    return undefined;
  }
};

export const isSameUserOrgId = (userOrgId1?: IUserOrgId, userOrgId2?: IUserOrgId): boolean => {
  if (!userOrgId1 || !userOrgId2) {
    return false;
  }

  return userOrgId1.userId?.id === userOrgId2.userId?.id;
};

export const CurrencyCodesOptions = Object.keys(Currencies).map((c) => ({ label: c, value: c }));

export const getLocationFullAddress = (address?: IPostalAddress | PostalAddressNewTypes): string => {
  if (address) {
    const { addressLines, sublocality = '', locality = '', postalCode, administrativeArea, regionCode } = address;
    return [
      ...new Set([
        ...addressLines.map(titleCase),
        titleCase(sublocality),
        titleCase(locality),
        `${administrativeArea} ${postalCode}`.trim(),
        getCountryName(regionCode),
      ]),
    ]
      .filter(Boolean)
      .join(', ');
  }
  return '';
};

export const getLocationShortAddress = (address?: IPostalAddress): string => {
  if (address) {
    const { addressLines, sublocality = '', locality, administrativeArea } = address;

    const addressLine = addressLines.map(titleCase).filter(Boolean).join(', ');
    const additionalInfo = [...new Set([titleCase(sublocality), titleCase(locality), administrativeArea.toUpperCase()])]
      .filter(Boolean)
      .join(', ');
    const additionalInfoBracketized = additionalInfo ? `(${additionalInfo})` : '';

    if (!addressLine && !additionalInfoBracketized) {
      return '';
    }

    return `${addressLine} ${additionalInfoBracketized}`;
  }
  return '';
};

export const getLocationShortAddressV2 = (address?: PostalAddress): string => {
  if (address) {
    const { addressLines, sublocality = '', locality = '', administrativeArea } = address;

    const addressLine = addressLines.map(titleCase).filter(Boolean).join(', ');
    const additionalInfo = [
      ...new Set([titleCase(sublocality), titleCase(locality), administrativeArea?.toUpperCase() ?? '']),
    ]
      .filter(Boolean)
      .join(', ');
    const additionalInfoBracketized = additionalInfo ? `(${additionalInfo})` : '';

    if (!addressLine && !additionalInfoBracketized) {
      return '';
    }

    return `${addressLine} ${additionalInfoBracketized}`;
  }
  return '';
};

export const getLocationFullAddressV2 = (address?: PostalAddress): string => {
  if (address) {
    const { addressLines, sublocality, locality, postalCode, administrativeArea, regionCode } = address;
    return [
      ...new Set([
        ...addressLines.map(titleCase),
        titleCase(sublocality ?? ''),
        titleCase(locality ?? ''),
        `${administrativeArea ?? ''} ${postalCode ?? ''}`.trim(),
        getCountryName(regionCode),
      ]),
    ]
      .filter(Boolean)
      .join(', ');
  }
  return '';
};

export const isAirportLocation = (counterLocation: CounterLocation): boolean => {
  switch (counterLocation) {
    case CounterLocation.NON_AIRPORT_LOCATION:
    case CounterLocation.OFF_AIRPORT_RENTAL_SHUTTLE:
    case CounterLocation.CALL_FOR_SHUTTLE:
    case CounterLocation.TWO_SHUTTLES_AIRPORT_AND_RENTAL:
      return false;
    default:
      return true;
  }
};

export const getCompareFunctionForLoyaltyInfo =
  (map: Record<string, string>) =>
  (loyaltyInfo1: ITravelerLoyaltyInfo, loyaltyInfo2: ITravelerLoyaltyInfo): number => {
    const loyaltyIssuedBy1 = map[loyaltyInfo1.issuedBy] ?? loyaltyInfo1.issuedBy;
    const loyaltyIssuedBy2 = map[loyaltyInfo2.issuedBy] ?? loyaltyInfo2.issuedBy;
    const compare = loyaltyIssuedBy1.toLowerCase() < loyaltyIssuedBy2.toLowerCase();
    if (compare) return -1;
    if (!compare) return 1;
    return 0;
  };

export const getAirlineShortName = (airline: string): string =>
  airline
    .replace(/AirWays/gi, '')
    .replace(/Air Ways/gi, '')
    .replace(/AirLines/gi, '')
    .replace(/Air lines/gi, '');

export const getTripMessageFromSegments = (
  segments: Array<{
    origin: {
      code?: string;
      airport?: string;
      city?: string;
    };
    destination: {
      code?: string;
      airport?: string;
      city?: string;
    };
    date: string;
  }>,
): string => {
  const segmentsLength = segments?.length;

  if (segmentsLength === 1) {
    const trip = segments[0];
    return `one way trip from ${trip?.origin?.code ?? trip?.origin?.airport ?? trip?.origin?.city} to ${
      trip?.destination?.code ?? trip?.destination?.airport ?? trip?.destination?.city
    } on ${trip?.date}`;
  }

  if (segmentsLength === 2) {
    const onwardTrip = segments[0];
    const returnTrip = segments[1];
    const onwardOrigin = onwardTrip?.origin?.code ?? onwardTrip?.origin?.airport ?? onwardTrip?.origin?.city;
    const returnDestination =
      returnTrip?.destination?.code ?? returnTrip?.destination?.airport ?? returnTrip?.destination?.city;

    if (onwardOrigin === returnDestination) {
      return `round trip between ${onwardOrigin} and ${
        onwardTrip?.destination?.code ?? onwardTrip?.destination?.airport ?? onwardTrip?.destination?.city
      }, departure on ${onwardTrip?.date} and return on ${returnTrip?.date}`;
    }
  }

  if (segmentsLength >= 2) {
    let message = '';
    message += 'multi-city trip \n';
    message += segments
      .map(
        (trip) =>
          `from ${trip?.origin?.code ?? trip?.origin?.airport ?? trip?.origin?.city} to ${
            trip?.destination?.code ?? trip?.destination?.airport ?? trip?.destination?.city
          } on ${trip?.date} \n`,
      )
      .join('\n');
    return message;
  }

  return '';
};

/**
 * Gets the inverted object
 * @param obj Expects an object
 * @example invertedObject({a:'x',b:'y}) and returns {x:'a',y:'b'}
 * @returns inverted object
 */

export const invertObject = (obj: Record<string, string>): Record<string, string> =>
  Object.assign({}, ...Object.entries(obj).map(([a, b]) => ({ [b]: a })));

export const setImpersonationToken = (impersonationToken: string) => {
  storage.setItem(StorageKeys.IMPERSONATION_TOKEN, impersonationToken);
};

export const unSetImpersonationToken = () => {
  storage.removeItem(StorageKeys.IMPERSONATION_TOKEN);
};

export const getImpersonationToken = async (): Promise<string> => {
  const impersonationToken = (await storage.getItem(StorageKeys.IMPERSONATION_TOKEN)) ?? '';
  return impersonationToken;
};

export const getCurrentUser = async (): Promise<AuthenticatedUserBasicInfoExistingUser | null> => {
  try {
    const currentUser = (await storage.getItem(StorageKeys.CURRENT_USER)) ?? 'null';
    return JSON.parse(currentUser);
  } catch (e) {
    return null;
  }
};

interface UsePreferredName {
  usePreferredName?: boolean;
}

/**
@deprecated This function is deprecated and will be removed in future releases.
Please use createUserNameFromFullName instead.
*/
export const getProfileDisplayText = (
  profileName: NameV1 | NameV2 | null | undefined,
  profileEmail: string | undefined,
  { usePreferredName }: UsePreferredName = {},
): string => {
  if (profileName?.preferred && usePreferredName) {
    return profileName.preferred;
  }
  if (profileName?.given) {
    return `${profileName.given} ${profileName?.family1 ?? ''}`.trim();
  }
  return profileEmail ?? '';
};

/**
  1. Group all airlines by operating airline name or code
  2. For each operating airline (name/code)
    a. Get list of all unique marketing airlines
    b. Render list of all unique marketing airline names.
    c. If there is more than one marketing airline OR marketing airline is not same as operating
      i. Add `Operated by suffix`

  Note: For any airline with operatingAirlineName, we'll need to use the operatingAirlineName instead of the marketingName
 */
export const getAirlinesSubText = (airlines: IFlightCodeDetails[]): string => {
  const airlineByOptAirline = groupBy(
    airlines,
    (airlineInfo) => airlineInfo.operatingAirlineName || airlineInfo.operating.airline,
  );

  return Object.keys(airlineByOptAirline)
    .map((operatingAirline) => {
      const airlinesOperatedByOptAirline = airlineByOptAirline[operatingAirline];
      const uniqMarketingAirlines = uniq(
        airlinesOperatedByOptAirline.map((airlineInfo) => airlineInfo.marketing.airline),
      );
      const uniqMarketingAirlineNames = uniqMarketingAirlines.map((mktAirlineCode) => airlinesMap[mktAirlineCode]);

      let operatedBySuffix = '';
      // If there are more than one marketing airlines or different marketing and
      // operating airline, add suffix for operated by
      if (uniqMarketingAirlines.length > 1 || operatingAirline !== uniqMarketingAirlines[0]) {
        operatedBySuffix = ` Operated by ${airlinesMap[operatingAirline] || operatingAirline}`;
      }

      return `${uniqMarketingAirlineNames.join(', ')}${operatedBySuffix}`;
    })
    .join(', ');
};

export const getAllAirlines = (airlines: IFlightCodeDetails[]): string[] => {
  const allAirlines = airlines.flatMap((airlineInfo) => [
    airlineInfo.marketing.airline,
    airlineInfo.operatingAirlineName || airlineInfo.operating.airline,
  ]);

  return uniq(allAirlines);
};

export const assertUnreachable = (inputType: string, inputValue: never): never => {
  throw new Error(`Missed ${inputType} with value: ${inputValue}`);
};

export const loyaltyLogoMap = {
  AIR: {
    logo: getAirlineLogo,
    map: invertObject({ ...invertObject(airlineLoyaltyMap) }),
  },
  HOTEL: {
    logo: getHotelChainLogo,
    map: hotelLoyaltyMap,
  },
  CAR: {
    logo: getCarVendorLogo,
    map: carLoyaltyMap,
  },
  RAIL: {
    logo: getRailOperatorsLogo,
    map: null,
  },
  LIMO: { logo: null, map: null },
  MISC: { logo: null, map: null },
};

/**
 * It calculates and returns percent change between oldValue and newValue, handles negative numbers as well.
 *
 * Examples:
 *
 * Old  ->  New = Percent Change
 *
 * 5    ->   10 = 100
 * 10   ->    5 = -50
 * 5    ->  -10 = -300
 *
 * -10  ->    5 = 150
 * -10  ->   -5 = 50
 * -5   ->  -10 = -100
 *
 * 0    ->   10 = Infinity
 *
 * More examples in *calculatePercentageChange* test in the *index.test.ts* file
 */
export const calculatePercentageChange = (oldValue: number, newValue: number): number => {
  if (oldValue === 0) {
    return newValue >= 0 ? Infinity : -Infinity;
  }
  const isIncreasedOrEqual = newValue >= oldValue;
  const changeValue = ((newValue - oldValue) * 100) / oldValue;
  return isIncreasedOrEqual ? Math.abs(changeValue) : -Math.abs(changeValue);
};

export const getHotelRoomRateTypeLabel = (type: number): string => {
  switch (type) {
    case RateTypeV1.CORPORATE:
    case RateTypeV1.SPOTNANA:
    case RateTypeV1.MILITARY:
    case RateTypeV1.SENIOR_CITIZEN:
    case RateTypeV1.GOVERNMENT:
      return `${sentenceCase(RateTypeV1[type].replace('_', ' '))} rate`;
    case RateTypeV1.AAA:
    case RateTypeV1.AARP:
      return `${RateTypeV1[type]} membership rate`;
    case RateTypeV1.MEMBERSHIP:
      return 'Members-only rate';
    default:
      return '';
  }
};

export enum FileSize {
  'KB',
  'MB',
  'bytes',
}

export const convertFileSize = (bytes: number, convertTo: FileSize): number => {
  if (convertTo === FileSize.bytes) {
    return bytes;
  }
  if (convertTo === FileSize.KB) {
    return bytes / 1000;
  }
  // FileSize.MB
  return bytes / 1000000;
};

export function getLocalizedMoneyTranslationProps(
  amount: MoneyUtil,
  rounding: 'ceil' | 'floor' | 'round' = 'floor',
): { key: string; options: any } {
  const { LOCALIZED_CURRENCY } = localizationKeys;

  return {
    key: LOCALIZED_CURRENCY,
    options: {
      value: {
        currencyCode: amount.getCurrency(),
        amount: Math[rounding](amount.getAmount()),
      },
    },
  };
}

/**
 * Used for cleaning up params sent by emails generated via Thymeleaf.
 * It appends &amp; between params in URL, whereas FE only needs & for parseParams to work correctly
 */
export const cleanUrlSearch = (search: string): string => search.replace(/amp;/g, '');

export const isValidBookingHistoryItem = (bookingHistoryItem: PnrBookingHistory): boolean =>
  !!(
    bookingHistoryItem.bookerInfo &&
    bookingHistoryItem.bookingInfo &&
    bookingHistoryItem.bookingInfo.updatedTime &&
    bookingHistoryItem.bookingInfo.status !== PnrBookingHistoryBookingStatus.UNKNOWN_STATUS
  );

export const isValidV2BookingHistoryItem = (bookingHistoryItem: BookingHistory): boolean =>
  !!(
    bookingHistoryItem.bookerInfo &&
    bookingHistoryItem.bookingInfo &&
    bookingHistoryItem.bookingInfo.updatedDateTime &&
    bookingHistoryItem.bookingInfo.status !== BookingInfoStatusEnum.UnknownStatus
  );

export const shouldShowBookingHistory = (bookingHistory?: PnrBookingHistory[]): boolean =>
  !isUndefined(bookingHistory) &&
  bookingHistory.length > 0 &&
  bookingHistory.filter((item) => isValidBookingHistoryItem(item)).length > 0;

// Generates a unique workflow id that allows us to tie up all the parallel requests in the internal debug tool
export const generateUniqueId = (): string => {
  const timestamp = Date.now();
  const randomValuesArray = new Uint32Array(1);
  // Check https://caniuse.com/getrandomvalues to know which browsers support this(96%)
  crypto.getRandomValues(randomValuesArray);
  const uniqueId = timestamp.toString(16) + randomValuesArray[0].toString(16);
  return uniqueId;
};

export const checkSpotnanaAgentOrAdmin = (loggedInUserRoleInfos: RoleInfo[] | undefined): boolean =>
  !loggedInUserRoleInfos
    ? false
    : Boolean(
        intersection(
          (loggedInUserRoleInfos as RoleInfo[]).map((loggedInUser) => loggedInUser.type),
          userRolesByFeature.organizationSelector,
        ).length,
      );

export const arePointsInReverseOrder = (
  origin: IPoint | undefined,
  destination: IPoint | undefined,
  current: IPoint | undefined,
): boolean => {
  if (
    !origin ||
    !destination ||
    !current ||
    (origin.lng < current.lng && current.lng < destination.lng) ||
    (origin.lng < current.lng && destination.lng < origin.lng) ||
    (current.lng < destination.lng && destination.lng < origin.lng)
  ) {
    return false;
  }
  return true;
};

export const getPointFromPosition = (position: Latlng): IPoint => ({
  lat: position.latitude,
  lng: position.longitude,
});

export const GetLatLong = (position: IPoint): Latlng => ({
  latitude: position.lat,
  longitude: position.lng,
});

export const isExpiredPaymentCard = (expiryMonth: number, expiryYear: number): boolean => {
  // TODO remove any when fixed this issue: https://github.com/iamkun/dayjs/issues/1441
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const expiryDate = dateUtil.utc({ year: expiryYear, month: expiryMonth } as any);
  return !isFutureDate(expiryDate);
};

/**
 * Convert the following formats:
 * 1. HomeScreen -> Home screen
 * 2. home_screen -> Home screen
 * 3. Home-Screen -> Home screen
 */
export const convertToFriendlyText = (text: string): string => {
  if (!text) return text;
  const result = text
    .replace(/([A-Z])/g, ' $1')
    .replace(/(-|_|\/)/g, ' ')
    .toLowerCase()
    .trim();
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const railTravelClassLabels = {
  [TravelClassHierarchy.BUSINESS]: defineMessage('Business'),
  [TravelClassHierarchy.FIRST]: defineMessage('First'),
  [TravelClassHierarchy.SLEEPER]: defineMessage('Sleeper'),
  [TravelClassHierarchy.STANDARD]: defineMessage('Standard'),
  [TravelClassHierarchy.STANDARD_PREMIUM]: defineMessage('Standard premium'),
  [TravelClassHierarchy.COACH]: defineMessage('Coach'),
} as const;

const isInTravelClassLabels = (
  value: TravelClassHierarchy | undefined,
): value is keyof typeof railTravelClassLabels => {
  return !!value && value in railTravelClassLabels;
};

export const getViolationString = ({
  value,
  rounding = 'ceil',
  t,
}: {
  value?: IConstValue;
  rounding?: 'ceil' | 'floor' | 'round';
  t: TFunction<'COMMON'>;
}): string | number | JSX.Element => {
  if (!value) {
    return '';
  }
  if (value.s) {
    return value.s;
  }
  if (value.i) {
    return value.i.toString();
  }
  if (value.d) {
    return value.d.toString();
  }
  if (value.sList) {
    return value.sList.s.join(', ');
  }
  if (value.iList) {
    return value.iList.i.join(', ');
  }
  if (value.dList) {
    return value.dList.d.join(', ');
  }
  if (value.money) {
    const moneyInstance = MoneyUtil.parse(value.money);
    return localeCurrencyFormat(Math[rounding](moneyInstance.getAmount()), moneyInstance.getCurrency());
  }
  if (value.rating) {
    // we use start rating in app, star text is needed for email template
    return t('{{count}} star', { count: value.rating });
  }
  if (value.railTravelClass && isInTravelClassLabels(value.railTravelClass)) {
    return t(railTravelClassLabels[value.railTravelClass]);
  }

  if (!isNil(value.percentage)) {
    return `${value.percentage}%`;
  }
  return '';
};

/**
 * Convert an Enum (integer based) to be similar to output of Object.Entries
 * Supposed to ignore the autogenerated `UNRECOGNIZED = -1`
 *
 * ```
 * enum MyEnum {
 *  A = 0,
 *  B = 1,
 *  C = 2,
 *  UNRECOGNIZED = -1,
 * }
 * ```
 *
 * @example
 * // returns [[B, 1], [C, 2]] by default
 * // returns [[A, 0], [B, 1], [C, 2]] if `enableZero` is true
 * enumToEntries(MyEnum)
 */
export const enumToObjectEntries = <T extends { [s: string]: unknown }>(
  enumType: T,
  enableZero = false,
): [string, T][] =>
  Object.entries(enumType).filter(([x, y]) => Number.isNaN(Number(x)) && y !== -1 && (enableZero || y !== 0)) as [
    string,
    T,
  ][];

export const getPictureSrc = (picture?: ImageV1): string => {
  if (picture?.data) {
    return `data:image/png;base64,${picture?.data}`;
  }

  if (picture?.url) {
    return picture?.url;
  }

  return '';
};

export function mapNameFromV1ToV2(name?: NameV1): NameV2 | undefined {
  if (!name) {
    return undefined;
  }
  return {
    ...name,
    suffix: mapNameSuffixFromV1ToV2[name.suffix],
  };
}

/**
 * @deprecated This function is deprecated and will be removed in future releases.
 * Please use createUserNameFromFullName instead.
 */
export const getNameStringFromName = (
  name: Pick<NameV2, 'given' | 'middle' | 'family1' | 'preferred'> | undefined,
  { usePreferredName }: UsePreferredName = {},
): string => {
  if (usePreferredName && name?.preferred) {
    return [name?.preferred, name?.family1].filter(Boolean).join(' ');
  }
  return [name?.given, name?.middle, name?.family1].filter(Boolean).join(' ');
};

type NameFormats = NameV1 | NameV2 | null | undefined;

/**
 * Options to customize the format of the name output.
 */
type FormatNameOptions = {
  /**
   * Returns initials of the name parts if true.
   * @default false
   */
  useInitials?: boolean;

  /**
   * Uses 'preferred' name over 'given' name if true.
   * Falls back to 'given' name if 'preferred' is unavailable or false.
   * @default true
   */
  usePreferredName?: boolean;

  /**
   * Includes the 'middle' name in the output if true.
   * @default false
   */
  useMiddleName?: boolean;

  /**
   * Includes the 'family1' (last) name in the output if true.
   * @default true
   */
  useFamily1?: boolean;

  /**
   * Uses the suffix (e.g. Jr., Sr.) in the output if true.
   * @default false
   */
  useSuffix?: boolean;

  /**
   * Uses the Legal name (First + Last) with preferred name (when available) in brackets if true.
   * @default false
   */
  useLegalPreferredName?: boolean;
};

/**
 * Formats a name based on the provided options.
 *
 * @param {NameFormats} name - The name object which can be of type NameV1, NameV2, null, or undefined.
 * @param {string} [email] - The email address to output if name or given and preferred names are missing.
 * @param {FormatNameOptions} [options={}] - The options to customize the format of the output name.
 * @returns {string} The formatted name or initials, or the email string if configured to do so.
 *
 * @example
 * formatName({ given: 'John', family1: 'Smith' });
 * // Returns: "John Smith"
 *
 * @example
 * formatName({ preferred: 'Sam', given: 'John', family1: 'Smith' }, undefined, { useInitials: true });
 * // Returns: "SS"
 *
 * @example
 * formatName(undefined, 'sam@example.com', { useEmail: true });
 * // Returns: "sam@example.com"
 *
 * @example
 * formatName({ preferred: 'Jonny', given: 'John', family1: 'Smith' }, undefined, { useLegalPreferredName: true });
 * // Returns: "John Smith (Jonny)"
 *
 * @example
 * formatName({ preferred: 'Jonny', given: 'John', family1: 'Smith', family2: 'Sr.' }, undefined, { useSuffix: true });
 * // Returns: "Jonny Smith Sr."
 */
const formatName = (name: NameFormats, email?: string, options: FormatNameOptions = {}): string => {
  const {
    useInitials = false,
    usePreferredName = true,
    useMiddleName = false,
    useFamily1 = true,
    useSuffix = false,
    useLegalPreferredName = false,
  } = options;

  if (!name || (!name.preferred && !name.given)) {
    return email || '';
  }

  const firstName = usePreferredName && !useLegalPreferredName ? name.preferred || name.given : name.given;
  const middleName = useMiddleName && name.middle;
  const family1 = useFamily1 && name.family1;
  const suffix = useSuffix && name.family2;

  const filteredNameParts = [firstName, middleName, family1, suffix].filter(
    (part): part is string => !!part && !!part.trim(),
  );

  if (useInitials) {
    return filteredNameParts.map((part) => part.charAt(0).toUpperCase()).join('');
  }

  if (useLegalPreferredName && !!name.preferred && name.given !== name.preferred) {
    return `${filteredNameParts.join(' ')} (${name.preferred})`;
  }

  return filteredNameParts.join(' ');
};

/**
 * Creates a formatted user name from the full name object and optional parameters.
 *
 * @param {NameFormats} name - The name object which can be of type NameV1, NameV2, null, or undefined.
 * @param {string} [email] - The optional email address to use if 'useEmail' is true and name parts are missing.
 * @param {FormatNameOptions} [options={}] - The options to customize the format of the output name.
 * @returns {string} The formatted user name or initials, or the email string if configured to do so.
 */
export const createUserNameFromFullName = (
  name: NameFormats,
  email?: string,
  options: FormatNameOptions = {},
): string => {
  return formatName(name, email, options);
};

/**
 * Creates initials from the full name object.
 *
 * @param {NameFormats} name - The name object which can be of type NameV1, NameV2, null, or undefined.
 * @returns {string} The initials derived from the given name.
 */
export const createInitialsFromFullName = (name: NameFormats): string => {
  return formatName(name, undefined, { useInitials: true });
};

export const getDefaultZoomByType = (type: string): number => {
  switch (type) {
    case 'AIRPORT':
    case 'RAIL STATION':
      return 12;
    case 'OFFICE':
    case 'HOTEL':
      return 14;
    default:
      return 10;
  }
};

const changeLatlng = (lat: number, lng: number, diff: number): IPoint =>
  ({
    lat: lat + diff,
    lng: lng + diff,
  } as unknown as IPoint);

export const getMarkerBoundsByType = (coords: IPoint, type: string): MarkerBounds => {
  const { lat, lng } = coords;
  // Need increase bounds from center by type location for filter markers search
  switch (type) {
    case 'AIRPORT':
    case 'RAIL STATION':
      return {
        southWest: changeLatlng(lat, lng, -0.3),
        northEast: changeLatlng(lat, lng, 0.3),
      };
    case 'OFFICE':
    case 'HOTEL':
      return {
        southWest: changeLatlng(lat, lng, -0.1),
        northEast: changeLatlng(lat, lng, 0.1),
      };
    default:
      return {
        southWest: changeLatlng(lat, lng, -1.2),
        northEast: changeLatlng(lat, lng, 1.2),
      };
  }
};

export const isPreferredVendor = (preferredType: PreferredType[]) =>
  !!preferredType.length && preferredType.includes(PreferredType.COMPANY_PREFERRED);

export const assertIsError = (error: unknown): asserts error is Error => {
  if (!(error instanceof Error)) {
    throw new Error(`Expected error, got ${typeof error}`);
  }
};

export const arrayToStringWithNumberOfItems = (value: string[], numberOfItems: number): string =>
  value.slice(0, numberOfItems).join(', ') + (value.length > numberOfItems ? ` +${value.length - numberOfItems}` : '');

type ConsumeError = <TSuccessfulReturn, TErrorReturn>(params: {
  execute: () => TSuccessfulReturn;
  resultIfError: TErrorReturn;
}) => TSuccessfulReturn | TErrorReturn;

export const consumeError: ConsumeError = ({ execute, resultIfError }) => {
  try {
    return execute();
  } catch (err) {
    logger.error(err as Error);
    return resultIfError;
  }
};

export const roundUp = (value: number, decimalPlaces = 1): number =>
  Math.ceil(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;

export const formatFileSizeBytes = (bytes: number | undefined, decimals = 2): { value: number; unit: string } => {
  if (!bytes || (!!bytes && !+bytes)) return { value: 0, unit: '' };
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return { value: parseFloat((bytes / k ** i).toFixed(dm)), unit: sizes[i] };
};

export const convertFileSizeToBytes = (fileSize: { value: number; unit: string } | undefined): number => {
  if (!fileSize) return 0;
  const { value, unit } = fileSize;
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = sizes.indexOf(unit);
  return value * k ** i;
};

/** Use this function to create userOrgId only if useProfileReadQuery/useReadMultipleProfilesWithUserOrgIdQuery are using it  */
export function createUserOrgIdUsingUserId(userId: string | undefined): IUserOrgId | undefined {
  if (!userId) {
    return undefined;
  }

  return {
    userId: {
      id: userId,
    },
  };
}

export const convertBytesTo = (
  value: number,
  { kind }: { kind?: 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB' } = {},
) => {
  const byteSizes = {
    KB: 1024,
    MB: 1024 ** 2,
    GB: 1024 ** 3,
    TB: 1024 ** 4,
    PB: 1024 ** 5,
    EB: 1024 ** 6,
    ZB: 1024 ** 7,
    YB: 1024 ** 8,
  };

  return kind ? roundUp(value / byteSizes[kind]) : value;
};

export function getArrayFromArrayOrNode<T>(node: T | T[]): T[] {
  return Array.isArray(node) ? node : [node];
}

// Helper function to check if a string is valid JSON
export function isValidJSON(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

export function findClosestNumberFromSortedArray(sortedArray: number[], selectedNumber: number) {
  let left = 0;
  let right = sortedArray.length - 1;

  while (left < right) {
    const mid = Math.floor((left + right) / 2);

    if (sortedArray[mid] === selectedNumber) {
      return sortedArray[mid]; // Found an exact match
    }

    if (sortedArray[mid] < selectedNumber) {
      left = mid + 1;
    } else {
      right = mid;
    }
  }

  // Check which number is closer and return it
  if (Math.abs(sortedArray[left] - selectedNumber) <= Math.abs(sortedArray[right] - selectedNumber)) {
    return sortedArray[left];
  }
  return sortedArray[right];
}

export const promiseNoop = (): Promise<void> => {
  return Promise.resolve();
};

export function convertLbsToKg(lbs: number) {
  const kg = parseFloat((lbs * 0.453592).toFixed(2));
  return kg;
}

export const weightOptions = Object.keys(WeightUnitEnum).map((weight) => ({
  label: weight,
  value: WeightUnitEnum[weight as keyof typeof WeightUnitEnum],
}));

export const isEmptyHtmlString = (htmlString: string): boolean => {
  const isHtmlString = HTML_REGEXP.test(htmlString);

  if (!isHtmlString) {
    return false;
  }

  const textContent = htmlString.replace(/<[^<>]*>/g, '').trim();

  return textContent.length === 0;
};

export function injectPathParams(path: string, pathParams: Record<string, string | number>): string {
  return path.replace(/:([a-zA-Z0-9_]+)/g, (match, paramName) => String(pathParams[paramName] ?? match));
}
export function formatTerminalName(terminal: string | undefined): string {
  if (!terminal) return '';
  if (terminal.toLowerCase().includes('terminal')) {
    return terminal;
  }
  return `Terminal ${terminal}`;
}

export function customFieldTypeV1toV2(customFieldType: CustomFieldTypeV1): CustomFieldTypeV2 {
  switch (customFieldType) {
    case CustomFieldTypeV1.QUESTION:
      return CustomFieldTypeV2.Question;
    case CustomFieldTypeV1.BREX_TOKEN:
      return CustomFieldTypeV2.BrexToken;
    case CustomFieldTypeV1.BUDGET:
      return CustomFieldTypeV2.Budget;
    case CustomFieldTypeV1.MEETING:
      return CustomFieldTypeV2.Meeting;
    default:
      throw new Error('invalid CustomFieldType passed in customFieldTypeV1toV2');
  }
}
