import cloneDeep from 'lodash/cloneDeep';

import { cabinTypes } from '../flights/index';
import { emptyName, emptyPhoneNumber, emptyTravelerAddress } from '../traveler';
import { globalTranslationKeys } from '../common';

import type {
  ICompanyAuthentication,
  ICostCenter,
  IDepartment,
  ILegalEntityDetail,
  ILegalEntityThirdPartyInfoErrors,
  INewUserData,
  IOfficeDetails,
  IOrgOverview,
  IPolicy,
  IPolicyLinkingState,
  IPolicyState,
  IPolicyUserGroup,
  IThirdPartyDetailErrors,
  IThirdPartyDetails,
  ITmcThirdPartyDetailErrors,
  OrganizationThirdPartyInfo,
} from '../../types/admin';
import { NewUserTypeEnum, PolicyTypeEnum, policyUserGroupConditionTypeKeysEnum } from '../../types/admin';
import { PolicyApprovalInfoApprovalProcessType } from '../../types/api/v1/obt/policy/policy';
import { PolicyCategory } from '../../types/api/v1/obt/policy/policy_common';
import type { IKeyLabelPair, ILabelValuePair } from '../../types/common';

import { AuthConfigAuthProviderCustomProviderMetadataClientAuthenticationMethod as ClientAuthenticationMethodV1 } from '../../types/api/v1/obt/common/auth_config';
import type {
  ICompanyAuthValidationState,
  ILegalEntityValidationState,
  IOfficeValidationState,
  IOrgOverviewValidationState,
  IPolicyErrors,
  IUserInfoValidationState,
} from '../../validations/admin';
import { ValidationStatus } from '../../validations/common';
import {
  AllowedAirAddonsPropsAddon,
  FlightTicketsChangeablePropsType,
  FlightTicketsRefundablePropsType,
  HotelCancellationPropsType,
  LowestLogisticalFarePropsCarrierType,
  LowestLogisticalFarePropsMaxNumberOfStops,
  MaxFlightBookingPricePropsSign,
  MaxFlightBookingPricePropsType,
  MaxHotelBookingPricePropsType,
} from '../../types/api/v1/obt/policy/policy_rules';
import type { IPostalAddress } from '../../types/traveler';
import { TitleEnum } from '../../types/api/v1/obt/common/title';
import { Currencies } from '../../utils/Money/currencies';
import type { ThirdPartySabreInfo } from '../../services/SupplierInfoResponseManager';
import { ConfermaMetadataCompanyEnum, VendorType } from '../../types';
import { defineMessage } from '../../translations/defineMessage';
import { ClientAuthenticationMethod } from '../../types/api/v2/obt/model/client-authentication-method';
import { LengthUnitEnum } from '../../types/api/v1/common/length';
import { PreferenceTierLevel } from '../../types/api/v2/obt/model/preference-tier-level';

export {
  SupplierTypeToLabel,
  supplierTranslationKeys,
  defaultPccState,
  defaultPccErrorState,
  IPccDataStateDispatchTypes,
} from './supplier';

export const CABIN_CUSTOMIZATION_BY_MILEAGE_MAX = 10000;
export const CABIN_CUSTOMIZATION_BY_TIME_MAX = 1800;
export const CABIN_CUSTOMIZATION_BY_MILEAGE_STEP = 500;
export const CABIN_CUSTOMIZATION_BY_TIME_STEP = 60;

export const DEFAULT_OVERNIGHT_START = 22;
export const DEFAULT_OVERNIGHT_END = 6;
export const DEFAULT_OVERNIGHT_DURATION = 360;
export const DEFAULT_OVERNIGHT_INCLUDE_LAYOVER = false;
export const DEFAULT_OVERNIGHT_DURATION_MIN_HOURS = 1;
export const DEFAULT_OVERNIGHT_DURATION_MAX_HOURS = 24;

export const PNRRemarkType: { label: string; value: string }[] = [
  { label: defineMessage('Alpha Coded'), value: 'Alpha_Coded' },
  { label: defineMessage('Client Address'), value: 'Client_Address' },
  { label: defineMessage('Corporate'), value: 'Corporate' },
  { label: defineMessage('Delivery Address'), value: 'Delivery_Address' },
  { label: defineMessage('General'), value: 'General' },
  { label: defineMessage('Group Name'), value: 'Group_Name' },
  { label: defineMessage('Hidden'), value: 'Hidden' },
  { label: defineMessage('Historical'), value: 'Historical' },
  { label: defineMessage('Invoice'), value: 'Invoice' },
  { label: defineMessage('Itinerary'), value: 'Itinerary' },
];

export const TRAVEL_TYPE = {
  AIR: 'Air',
  HOTEL: 'Hotel',
  CAR: 'Car',
  HOTEL_CHAIN: 'Hotel_chain',
};

export const emptyOverview: IOrgOverview = {
  companyName: '',
  billingCurrency: '',
  emailDomains: [],
  emergencyContacts: [],
  ccEmails: [],
  isFake: false,
};

export const emptyOverviewError: IOrgOverviewValidationState = {
  error: {
    companyName: '',
    emailDomain: '',
    billingCurrency: '',
  },
  isValid: ValidationStatus.NOT_STARTED,
};

export const emptyLegalEntity: ILegalEntityDetail = {
  name: '',
  dba: '',
  ein: '',
  billingCurrency: '',
  isDelayedInvoicingEnabled: false,
};

export const emptyLegalEntityError: ILegalEntityValidationState = {
  error: { name: '', billingCurrency: '' },
  isValid: ValidationStatus.NOT_STARTED,
};

export const emptyLegalEntityThirdPartyInfo: OrganizationThirdPartyInfo = {
  sabreInfo: {
    dkNumber: '',
    templateId: '',
    filterId: '',
    codes: [],
    profileRemarks: [],
    airPnrRemarks: [],
    hotelPnrRemarks: [],
    carPnrRemarks: [],
    pccs: [],
    vendorPrograms: [],
    allocatedPccs: [],
  },
  ndcInfo: {
    swabizNumber: '',
  },
  isDefault: false,
};

export const emptyLegalEntityThirdPartyInfoErrorState: ILegalEntityThirdPartyInfoErrors = {
  airPnrRemarks: [],
  profileRemarks: [],
  allocatedPccs: [],
};

export const emptyDepartment: IDepartment = {
  name: '',
};

export const emptyOfficeDetails: IOfficeDetails = {
  name: '',
  legalEntityId: '',
};

export const emptyOfficeDetailsError: IOfficeValidationState = {
  error: {
    name: '',
    legalEntityId: '',
  },
  isValid: ValidationStatus.NOT_STARTED,
};

export const emptyCostCenter: ICostCenter = {
  name: '',
};

export const emptyNewUserData: INewUserData = {
  newUserType: NewUserTypeEnum.EMPLOYEE,
  employeeDetails: {
    title: TitleEnum.MR,
    name: { ...emptyName },
    email: '',
    phoneNumber: { ...emptyPhoneNumber },
    designation: '',
    employeeId: '',
    manager: null,
  },
  employeeCompanyDetails: {
    legalEntityId: '',
    officeId: '',
    departmentId: '',
    costCenterId: '',
    ecc: '',
  },
  preferredCurrency: Currencies.USD.code,
};

export enum companySetupSubPages {
  OVERVIEW = 'overview',
  SERVICE_FEE = 'service-fee',
  ENTITIES = 'entities',
  OFFICES = 'offices',
  DEPARTMENT = 'department',
  COST_CENTER = 'costcenter',
  L1_REMARKS = 'remarks',
  USER_UPLOAD = 'userupload',
  PAYMENT_METHODS = 'payment-methods',
  PREFERRED_VENDORS = 'preferred-vendors',
  SUPPLIER_MANAGEMENT = 'supplier-management',
  SUPPLIERS = 'suppliers',
  AIRLINE_PROGRAMS = 'airline-programs',
  SUPPLIER_PROGRAMS = 'supplier-programs',
  COMPANY_INTEGRATIONS = 'integrations',
  CUSTOM_FIELDS = 'custom-fields',
  SAFETY = 'safety',
  BOOKING_RESTRICTIONS = 'booking-restrictions',
  SERVICING_NOTES = 'servicing-notes',
  SITE_MESSAGING = 'site-messaging',
  TRIP_TEMPLATES = 'trip-templates',
  DATA_ACTION_REQUESTS = 'data-action-requests',
  EMAIL_NOTIFICATIONS = 'email-notifications',
  UNUSED_CREDITS = 'unused-credits',
  AGENT_FEE = 'agent-fee',
}

export enum tmcSettingsTranslationKeys {
  TMC_PAGE_HEADING = 'TMC_PAGE_HEADING',
  SETTINGS = 'SETTINGS',
  CUSTOMIZE = 'CUSTOMIZE',
  GENERAL = 'GENERAL',
  REMARKS = 'REMARKS',
  SUPPLIERS = 'SUPPLIERS',
  COMPANY_LIST = 'COMPANY_LIST',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_NAME_PLACEHOLDER = 'COMPANY_NAME_PLACEHOLDER',
  EMAIL_DOMAIN = 'EMAIL_DOMAIN',
  EMAIL_DOMAIN_PLACEHOLDER = 'EMAIL_DOMAIN_PLACEHOLDER',
  EMAIL_DOMAIN_TOOLTIP = 'EMAIL_DOMAIN_TOOLTIP',
  TMC_DEFAULT_PCC = 'TMC_DEFAULT_PCC',
  BILLING_CURRENCY = 'BILLING_CURRENCY',
  BILLING_CURRENCY_TOOLTIP = 'BILLING_CURRENCY_TOOLTIP',
  BILLING_CURRENCY_PLACEHOLDER = 'BILLING_CURRENCY_PLACEHOLDER',
  TMC_DEFAULT_PCC_ADD = 'TMC_DEFAULT_PCC_ADD',
  TMC_DEFAULT_PCC_TOOLTIP = 'TMC_DEFAULT_PCC_TOOLTIP',
  SELECT_PCC_PLACEHOLDER = 'SELECT_PCC_PLACEHOLDER',
  NO_PCC_CONFIGURED = 'NO_PCC_CONFIGURED',
  PCC_DELETE_BUTTON = 'PCC_DELETE_BUTTON',
  DEFAULT_PCC_ERROR_EMPTY = 'DEFAULT_PCC_ERROR_EMPTY',
  DEFAULT_PCC_ERROR_INTERSECTIONS = 'DEFAULT_PCC_ERROR_INTERSECTIONS',
  ADD_COMPANY = 'ADD_COMPANY',
  EMPTY_LIST_MESSAGE = 'EMPTY_LIST_MESSAGE',
  SEARCH = 'SEARCH',
  SEARCH_PLACEHOLDER = 'SEARCH_PLACEHOLDER',
  RATE_CODES = 'RATE_CODES',
  BACKOFFICE_SETUP = 'BACKOFFICE_SETUP',
  LOGO = 'LOGO',
  EMAIL = 'EMAIL',
  FRESHDESK_SETTING = 'FRESHDESK_SETTING',
  SUPPORT_SETTING = 'SUPPORT_SETTING',
  DK_NUMBER_FIELD = 'DK_NUMBER_FIELD',
  FILTER_ID_FIELD = 'FILTER_ID_FIELD',
  TEMPLATE_ID_FIELD = 'TEMPLATE_ID_FIELD',
  PNR_REMARK = 'PNR_REMARK',
  PNR_REMARK_TITLE = 'PNR_REMARK_TITLE',
}

export enum settingsTranslationKeys {
  CHOOSE_ORGANIZATION = 'CHOOSE_ORGANIZATION',
  ADMIN_PAGE_HEADING = 'ADMIN_PAGE_HEADING',
  COMPANY = 'COMPANY',
  GENERAL = 'GENERAL',
  SERVICE_FEE = 'SERVICE_FEE',
  LEGAL_ENTITIES = 'LEGAL_ENTITIES',
  OFFICES = 'OFFICES',
  PAYMENT_METHODS = 'PAYMENT_METHODS',
  USERS = 'USERS',
  POLICY = 'POLICY',
  CANCEL = 'CANCEL',
  NEW_COMPANY = 'NEW_COMPANY',
  TMC = 'TMC',
  USER_UPLOAD = 'USER_UPLOAD',
  REMARKS = 'REMARKS',
  ROLES = 'ROLES',
  LOGIN_AS_USER = 'LOGIN_AS_USER',
  PREFERRED_VENDORS = 'PREFERRED_VENDORS',
  AIRLINE_PROGRAMS = 'AIRLINE_PROGRAMS',
  ADD_GENERAL_AIRLINE_PROGRAM = 'ADD_GENERAL_AIRLINE_PROGRAM',
  DELETE_VENDOR_PROGRAM = 'DELETE_VENDOR_PROGRAM',
  UNITED_PASS_PLUS = 'UNITED_PASS_PLUS',
  ADD_UNITED_PASS_PLUS = 'ADD_UNITED_PASS_PLUS',
  UNITED_PASS_PLUS_APPLICABILITY_CRITERIA = 'UNITED_PASS_PLUS_APPLICABILITY_CRITERIA',
  SAVE = 'SAVE',
  SAVING = 'SAVING',
  CENTRAL_CARD_APPLICABLE_TEXT = 'CENTRAL_CARD_APPLICABLE_TEXT',
}

export enum generalSettingsTranslationKeys {
  GENERAL_SETTINGS_HEADING = 'GENERAL_SETTINGS_HEADING',
  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_NAME_PLACEHOLDER = 'COMPANY_NAME_PLACEHOLDER',
  EMAIL_DOMAIN = 'EMAIL_DOMAIN',
  EMAIL_DOMAIN_TOOLTIP = 'EMAIL_DOMAIN_TOOLTIP',
  EMAIL_DOMAIN_PLACEHOLDER = 'EMAIL_DOMAIN_PLACEHOLDER',
  BILLING_CURRENCY = 'BILLING_CURRENCY',
  BILLING_CURRENCY_TOOLTIP = 'BILLING_CURRENCY_TOOLTIP',
  BILLING_CURRENCY_PLACEHOLDER = 'BILLING_CURRENCY_PLACEHOLDER',
  EMERGENCY_CONTACT_TITLE = 'EMERGENCY_CONTACT_TITLE',
  ADD_CONTACT = 'ADD_CONTACT',
  COMPANY_LOGO_TITLE = 'COMPANY_LOGO_TITLE',
  ADD_COMPANY_LOGO = 'ADD_COMPANY_LOGO',
  CHANGE_COMPANY_LOGO = 'CHANGE_COMPANY_LOGO',
  CHANGE_COMPANY_LOGO_MESSAGE = 'CHANGE_COMPANY_LOGO_MESSAGE',
  CC_EMAILS_TITLE = 'CC_EMAILS_TITLE',
  CC_EMAILS_BUTTON = 'CC_EMAILS_BUTTON',
  CC_EMAILS_MESSAGE = 'CC_EMAILS_MESSAGE',
  CC_EMAILS_ERROR_MESSAGE = 'CC_EMAILS_ERROR_MESSAGE',
  CC_EMAILS_EMAIL = 'CC_EMAILS_EMAIL',
  DELETE = 'DELETE',
}

export enum ServiceFeeTranslationKeys {
  EMPTY_SERVICE_FEE_HEADER = 'EMPTY_SERVICE_FEE_HEADER',
  EMPTY_ORGANIZATION_LABEL = 'EMPTY_ORGANIZATION_LABEL',
}

export enum l1RemarksTranslationKeys {
  EMPTY_L1_REMARKS_HEADER = 'EMPTY_L1_REMARKS_HEADER',
  EMPTY_ORGANIZATION_LABEL = 'EMPTY_ORGANIZATION_LABEL',
}

export enum legalEntitiesTranslationKeys {
  LEGAL_ENTITIES_HEADER = 'LEGAL_ENTITIES_HEADER',
  CREATE_LEGAL_ENTITIES_BUTTON = 'CREATE_LEGAL_ENTITIES_BUTTON',
  EMPTY_LEGAL_ENTITIES_HEADER = 'EMPTY_LEGAL_ENTITIES_HEADER',
  EMPTY_ORGANIZATION_LABEL = 'EMPTY_ORGANIZATION_LABEL',
  EMPTY_L1_LABEL = 'EMPTY_L1_LABEL',
  EMPTY_LEGAL_ENTITIES_LABEL = 'EMPTY_LEGAL_ENTITIES_LABEL',
  LEGAL_ENTITY_NAME = 'LEGAL_ENTITY_NAME',
  DOING_BUSINESS_AS = 'DOING_BUSINESS_AS',
  TAX_NUMBER = 'TAX_NUMBER',
  BILLING_CURRENCY = 'BILLING_CURRENCY',
  ADD_LEGAL_ENTITY_HEADER = 'ADD_LEGAL_ENTITY_HEADER',
  ADD_LEGAL_ENTITY_BUTTON = 'ADD_LEGAL_ENTITY_BUTTON',
  EDIT_LEGAL_ENTITY_HEADER = 'EDIT_LEGAL_ENTITY_HEADER',
  EDIT_LEGAL_ENTITY_BUTTON = 'EDIT_LEGAL_ENTITY_BUTTON',
  OFFICES = 'OFFICES',
  EMPLOYEES = 'EMPLOYEES',
  LEGAL_ENTITY_HAS_OFFICES_AND_EMPLOYEES_HEADER = 'LEGAL_ENTITY_HAS_OFFICES_AND_EMPLOYEES_HEADER',
  LEGAL_ENTITY_HAS_OFFICES_AND_EMPLOYEES_SUBHEADER = 'LEGAL_ENTITY_HAS_OFFICES_AND_EMPLOYEES_SUBHEADER',
  DELETE_CONFIRMATION_HEADER = 'DELETE_CONFIRMATION_HEADER',
  DELETE_CONFIRMATION_LABEL = 'DELETE_CONFIRMATION_LABEL',
  DELAYED_INVOICING = 'DELAYED_INVOICING',
}

export enum officesTranslationKeys {
  OFFICES_HEADER = 'OFFICES_HEADER',
  CREATE_OFFICE_BUTTON = 'CREATE_OFFICE_BUTTON',
  EMPTY_LEGAL_ENTITY_OFFICE_HEADER = 'EMPTY_LEGAL_ENTITY_OFFICE_HEADER',
  EMPTY_LEGAL_ENTITY_OFFICE_LABEL = 'EMPTY_LEGAL_ENTITY_OFFICE_LABEL',
  EMPTY_OFFICE_HEADER = 'EMPTY_OFFICE_HEADER',
  EMPTY_OFFICE_LABEL = 'EMPTY_OFFICE_LABEL',
  EMPTY_L1_LABEL = 'EMPTY_L1_LABEL',
  OFFICE_NAME = 'OFFICE_NAME',
  LEGAL_ENTITY_FOR_OFFICE = 'LEGAL_ENTITY_FOR_OFFICE',
  ADD_OFFICE_HEADER = 'ADD_OFFICE_HEADER',
  ADD_OFFICE_BUTTON = 'ADD_OFFICE_BUTTON',
  EDIT_OFFICE_HEADER = 'EDIT_OFFICE_HEADER',
  EDIT_OFFICE_BUTTON = 'EDIT_OFFICE_BUTTON',
  EMPLOYEES = 'EMPLOYEES',
  OFFICE_HAS_EMPLOYEES = 'OFFICE_HAS_EMPLOYEES',
  DELETE_OFFICE_HAS_EMPLOYEES_HEADER = 'DELETE_OFFICE_HAS_EMPLOYEES_HEADER',
  DELETE_OFFICE_HAS_EMPLOYEES_LABEL = 'DELETE_OFFICE_HAS_EMPLOYEES_LABEL',
  DELETE_CONFIRMATION_HEADER = 'DELETE_CONFIRMATION_HEADER',
  DELETE_CONFIRMATION_LABEL = 'DELETE_CONFIRMATION_LABEL',
  EMPTY_SUPPLIER_LIST_HEADER = 'EMPTY_SUPPLIER_LIST_HEADER',
}

export enum useruploadTranslationKeys {
  USER_UPLOAD = 'USER_UPLOAD',
  CHOOSE_CSV_FILE = 'CHOOSE_CSV_FILE',
  USER_UPLOAD_CONTENT = 'USER_UPLOAD_CONTENT',
  EMPTY_L1_LABEL = 'EMPTY_L1_LABEL',
  EMPTY_USER_UPLOAD_HEADER = 'EMPTY_USER_UPLOAD_HEADER',
}

export enum authenticationMethodTranslationKeys {
  USER_AUTO_PROVISIONED_ALLOWED = 'USER_AUTO_PROVISIONED_ALLOWED',
}

export enum userManagementTranslationKeys {
  SELECT_USER = 'SELECT_USER',
  ADD_NEW_USER = 'ADD_NEW_USER',
  DELETE_USER = 'DELETE_USER',
  CONFIGURE_USER_HEADER = 'CONFIGURE_USER_HEADER',
  CONFIGURE_USER_LABEL = 'CONFIGURE_USER_LABEL',
  TRAVELER_TYPE_TITLE = 'TRAVELER_TYPE_TITLE',
  TRAVELER_TYPE = 'TRAVELER_TYPE',
  TRAVELER_TYPE_EMPLOYEE = 'TRAVELER_TYPE_EMPLOYEE',
  TRAVELER_TYPE_GUEST = 'TRAVELER_TYPE_GUEST',
  TRAVELER_TYPE_LEISURE = 'TRAVELER_TYPE_LEISURE',
  TRAVELER_INFORMATION_TITLE = 'TRAVELER_INFORMATION_TITLE',
  TITLE = 'TITLE',
  FIRST_NAME = 'FIRST_NAME',
  MIDDLE_NAME = 'MIDDLE_NAME',
  LAST_NAME = 'LAST_NAME',
  SUFFIX = 'SUFFIX',
  NAME_SECTION_TITLE = 'NAME_SECTION_TITLE',
  CONTACT_SECTION_TITLE = 'CONTACT_SECTION_TITLE',
  EMPLOYMENT_SECTION_TITLE = 'EMPLOYMENT_SECTION_TITLE',
  MANAGER_SECTION_TITLE = 'MANAGER_SECTION_TITLE',
  PREFERRED_CURRENCY_SECTION_TITLE = 'PREFERRED_CURRENCY_SECTION_TITLE',
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  EMPLOYEE_ID_INFO = 'EMPLOYEE_ID_INFO',
  JOB_TITLE = 'JOB_TITLE',
  MANAGER = 'MANAGER',
  COMPANY_SETTINGS_TITLE = 'COMPANY_SETTINGS_TITLE',
  LEGAL_ENTITY = 'LEGAL_ENTITY', // TODO(Ashit): Move this to common
  OFFICE = 'OFFICE',
  USER_ROLE_TITLE = 'USER_ROLE_TITLE',
  ROLE = 'ROLE',
  ROLE_TRAVELER_HEADER = 'ROLE_TRAVELER_HEADER',
  ROLE_TRAVELER_LABEL = 'ROLE_TRAVELER_LABEL',
  ROLE_ARRANGER_HEADER = 'ROLE_ARRANGER_HEADER',
  ROLE_ARRANGER_LABEL = 'ROLE_ARRANGER_LABEL',
  ROLE_COMPANY_ADMIN_HEADER = 'ROLE_COMPANY_ADMIN_HEADER',
  ROLE_COMPANY_ADMIN_LABEL = 'ROLE_COMPANY_ADMIN_LABEL',
  SAVE_BUTTON = 'SAVE_BUTTON',
  ADD_BUTTON = 'ADD_BUTTON',
  BACK = 'BACK',
  USER_CAN_MANAGE_ACCOUNTS_FOR = 'USER_CAN_MANAGE_ACCOUNTS_FOR',
  ARRANGER_FOR_USERS = 'ARRANGER_FOR_USERS',
  ADD_USER = 'ADD_USER',
  DELETE_USER_HEADER = 'DELETE_USER_HEADER',
  DELETE_USER_LABEL = 'DELETE_USER_LABEL',
  DELETE_EMPTY_USER_HEADER = 'DELETE_EMPTY_USER_HEADER',
  DELETE_EMPTY_USER_LABEL = 'DELETE_EMPTY_USER_LABEL',
  EMPTY_USER_LIST_HEADER = 'EMPTY_USER_LIST_HEADER',
  SEAT1A_CTA = 'SEAT1A_CTA',
  SEAT1A_CTA_SUBTEXT = 'SEAT1A_CTA_SUBTEXT',
  SEAT1A_FILTER = 'SEAT1A_FILTER',
  CAR_DISCOUNT_CODE = 'CAR_DISCOUNT_CODE',
  CAR_CORPORATE_CODE = 'CAR_CORPORATE_CODE',
  CANCEL_BUTTON = 'CANCEL_BUTTON',
}

export enum preferredVendorsTranslationKeys {
  SEARCH_HOTEL = 'SEARCH_HOTEL',
  SEARCH_CAR_VENDOR = 'SEARCH_CAR_VENDOR',
  LOADING = 'LOADING',
  NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
  ADD_HOTEL_BUTTON = 'ADD_HOTEL_BUTTON',
  ADD_CAR_VENDOR_BUTTON = 'ADD_CAR_VENDOR_BUTTON',
  HOTEL_VENDORS = 'HOTEL_VENDORS',
  CAR_VENDORS = 'CAR_VENDORS',
  NO_VENDORS_ADDED = 'NO_VENDORS_ADDED',
  SEARCH_VENDOR = 'SEARCH_VENDOR',
  HOTEL_NAME = 'HOTEL_NAME',
  LOCATION_NAME = 'LOCATION_NAME',
  PREFERRED_VENDOR_DUBLICATE = 'PREFERRED_VENDOR_DUBLICATE',
  VENDOR_NAME = 'VENDOR_NAME',
}

export enum UserManagementTab {
  GENERAL = 'GENERAL',
  ROLES = 'ROLES',
  CONFIGURATION = 'CONFIGURATION',
}

const csvSizeLimit = 20000000; // 20 MB

export const csvFileConfig = {
  acceptFileTypes: 'text/csv',
  sizeLimit: csvSizeLimit,
};

export const emptyL1Remarks: IThirdPartyDetails = {
  general: { dkNumber: '', templateId: '', pcc: '', filterId: '' },
  l1Remarks: [],
  pnrRemarks: [],
  airClid3: [],
  airClid4: [],
  hotelRateCode: [],
  airTourCode: [],
  airCtid: [],
  carCodes: [],
  vPayCarIdNumbers: [],
};

export const initialThirdPartyInfo: ThirdPartySabreInfo = {
  dkNumber: '',
  templateId: '',
  filterId: '',
  pccs: [],
  codes: [],
  profileRemarks: [],
  airPnrRemarks: [],
  hotelPnrRemarks: [],
  carPnrRemarks: [],
};

export const defaultThirdPartyDetailsErrorState: IThirdPartyDetailErrors = {
  l1Remarks: [],
  pnrRemarks: [],
  airTourCodes: [],
  airCtid: [],
  airClid3: [],
  airClid4: [],
  carCodes: [],
  vpayCarIdNumbers: [],
  hotelRateCodes: [],
};

export const defaultTmcThirdPartyDetailsErrorState: ITmcThirdPartyDetailErrors = {
  filterId: '',
  templateId: '',
  pnrRemarks: [],
  l1Remarks: [],
};

interface TranslationKeys {
  value: string;
  singular: string;
  conditionLabel: string;
}

export const policyUserGroupConditionKeys: Record<policyUserGroupConditionTypeKeysEnum | 'default', TranslationKeys> = {
  default: { value: '', singular: '', conditionLabel: '' },
  [policyUserGroupConditionTypeKeysEnum.USER_ORG_IDS]: {
    value: defineMessage('Users'),
    singular: defineMessage('User'),
    conditionLabel: defineMessage('All of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.DEPARTMENTS]: {
    value: defineMessage('Departments'),
    singular: defineMessage('Department'),
    conditionLabel: defineMessage('Users in any of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.LEGAL_ENTITY_IDS]: {
    value: defineMessage('Legal entities'),
    singular: defineMessage('Legal entity'),
    conditionLabel: defineMessage('Users in any of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.COST_CENTERS]: {
    value: defineMessage('Cost Centers'),
    singular: defineMessage('Cost Center'),
    conditionLabel: defineMessage('Users in any of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.GRADES]: {
    value: defineMessage('Grades'),
    singular: defineMessage('Grade'),
    conditionLabel: defineMessage('Users with any of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.OFFICE_IDS]: {
    value: defineMessage('Offices'),
    singular: defineMessage('Office'),
    conditionLabel: defineMessage('Users based in any of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.POSITION_TITLES]: {
    value: defineMessage('Job titles'),
    singular: defineMessage('Job title'),
    conditionLabel: defineMessage('Users with any of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.COUNTRY_CODES]: {
    value: defineMessage('Countries/Regions'),
    singular: defineMessage('Country/Region'),
    conditionLabel: defineMessage('Users in any of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.WORKER_TYPES]: {
    value: defineMessage('Worker types'),
    singular: defineMessage('Worker type'),
    conditionLabel: defineMessage('Users with any of the following'),
  },
  [policyUserGroupConditionTypeKeysEnum.ACCOUNTING_CODES]: {
    value: defineMessage('Accounting codes'),
    singular: defineMessage('Accounting codes'),
    conditionLabel: defineMessage('Users with any of the following'),
  },
};

/**
 * @deprecated Use `policyApprovalProcessTypeOptionsV2` instead
 */
export const policyApprovalProcessTypeOptions: IKeyLabelPair[] = [
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.HARD_APPROVAL,
    labelKey: defineMessage('Hard approval'),
  },
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.SOFT_APPROVAL,
    labelKey: defineMessage('Soft approval'),
  },
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.PASSIVE_APPROVAL,
    labelKey: defineMessage('Passive approval'),
  },
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.PREVENT_BOOKING,
    labelKey: defineMessage('Prevent booking'),
  },
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.NONE,
    labelKey: defineMessage('None'),
  },
];

/**
 * Policy approval process type options with updated order and labels.
 * Keep the following order or the options.
 */
export const policyApprovalProcessTypeOptionsV2: IKeyLabelPair[] = [
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.NONE,
    labelKey: defineMessage('No action'),
  },
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.PASSIVE_APPROVAL,
    labelKey: defineMessage('Passive approval'),
  },
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.SOFT_APPROVAL,
    labelKey: defineMessage('Soft approval'),
  },
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.HARD_APPROVAL,
    labelKey: defineMessage('Hard approval'),
  },
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.PREVENT_BOOKING,
    labelKey: defineMessage('Block booking'),
  },
];

export const policyAgentOverridesActionOptions: IKeyLabelPair[] = policyApprovalProcessTypeOptionsV2.filter(
  (o) => o.backendKey !== PolicyApprovalInfoApprovalProcessType.PREVENT_BOOKING,
);

export const outOfPolicyActionOptions: IKeyLabelPair[] = [
  {
    backendKey: PolicyApprovalInfoApprovalProcessType.UNRECOGNIZED,
    labelKey: defineMessage('Default to policy group action'),
  },
  ...policyApprovalProcessTypeOptionsV2,
];

export const policyHotelCancellationPolicyOptions: IKeyLabelPair[] = [
  {
    backendKey: HotelCancellationPropsType.NONE,
    labelKey: defineMessage('Any (allow all)'),
  },
  {
    backendKey: HotelCancellationPropsType.REFUNDABLE,
    labelKey: defineMessage('Fully refundable rooms only'),
  },
  {
    backendKey: HotelCancellationPropsType.REFUNDABLE_WITH_PENALTY,
    labelKey: defineMessage('Partially or fully refundable rooms only'),
  },
];

export const getPolicyFlightBookingPriceRateOptions = (): IKeyLabelPair<string>[] => [
  {
    backendKey: 'None',
    labelKey: defineMessage('None'),
  },
  {
    backendKey: `${MaxFlightBookingPricePropsSign.MORE} ${MaxFlightBookingPricePropsType.MINIMUM}`,
    labelKey: defineMessage('More than lowest fare'),
  },
  {
    backendKey: `${MaxFlightBookingPricePropsSign.MORE} ${MaxFlightBookingPricePropsType.MEDIAN}`,
    labelKey: defineMessage('More than median fare'),
  },
  {
    backendKey: `${MaxFlightBookingPricePropsSign.LESS} ${MaxFlightBookingPricePropsType.MEDIAN}`,
    labelKey: defineMessage('Less than median fare'),
  },
  {
    backendKey: `${MaxFlightBookingPricePropsSign.MORE} ${MaxFlightBookingPricePropsType.LOWEST_LOGICAL_FARE}`,
    labelKey: defineMessage('More than lowest logical fare'),
  },
];

/**
 * @deprecated Use `policyFlightCabinUpgradeConditionTypes` instead
 * TODO: remove when FE_CORP_POLICY_DEFINE_RULE_LEVEL_ACTIONS_AND_EXCEPTIONS feature flag is expired
 */
enum policyFlightCabinUpgradeTypes {
  DONT_ALLOW = 0,
  ALLOW_IF_CHEAPER = 1,
}

/**
 * @deprecated Use `policyFlightCabinUpgradeConditionOptions` instead
 * TODO: remove when FE_CORP_POLICY_DEFINE_RULE_LEVEL_ACTIONS_AND_EXCEPTIONS feature flag is expired
 */
export const policyFlightCabinUpgradeOptions: IKeyLabelPair[] = [
  {
    backendKey: policyFlightCabinUpgradeTypes.DONT_ALLOW,
    labelKey: defineMessage("Don't allow"),
  },
  {
    backendKey: policyFlightCabinUpgradeTypes.ALLOW_IF_CHEAPER,
    labelKey: defineMessage('Allow if Cheaper'),
  },
];

export enum policyFlightCabinUpgradeConditionTypes {
  ALLOW_IF_CHEAPER_THAN_LOWEST_FARE_IN_THE_IN_POLICY_CABIN = 'priceBasedUpgrade',
  ALLOW_IF_IN_POLICY_CABIN_IS_NOT_AVAILABLE = 'invBasedUpgrade',
}

export const policyFlightCabinUpgradeConditionOptions: IKeyLabelPair<policyFlightCabinUpgradeConditionTypes>[] = [
  {
    backendKey: policyFlightCabinUpgradeConditionTypes.ALLOW_IF_CHEAPER_THAN_LOWEST_FARE_IN_THE_IN_POLICY_CABIN,
    labelKey: defineMessage('Allow if cheaper than lowest fare in the in-policy cabin'),
  },
  {
    backendKey: policyFlightCabinUpgradeConditionTypes.ALLOW_IF_IN_POLICY_CABIN_IS_NOT_AVAILABLE,
    labelKey: defineMessage('Allow if in-policy cabin is not available'),
  },
];

export const policyAllowedAirAddonsOptions: IKeyLabelPair[] = [
  {
    backendKey: AllowedAirAddonsPropsAddon.BAGGAGE,
    labelKey: defineMessage('Additional Baggage'),
  },
  {
    backendKey: AllowedAirAddonsPropsAddon.NONE,
    labelKey: defineMessage('No Add-Ons Allowed'),
  },
  {
    backendKey: AllowedAirAddonsPropsAddon.EARLY_BIRD,
    labelKey: defineMessage('Early check-in'),
  },
  {
    backendKey: AllowedAirAddonsPropsAddon.SEAT,
    labelKey: defineMessage('Seat'),
  },
  // enable once these ancillaries are available in obt
  // {
  //   backendKey: AllowedAirAddonsPropsAddon.CARBON_OFFSET,
  //   labelKey: defineMessage('Carbon offset'),
  // },
  // {
  //   backendKey: AllowedAirAddonsPropsAddon.WIFI,
  //   labelKey: defineMessage('Wifi'),
  // },
];

export const policyFlightRefundableTicketsOptions: IKeyLabelPair[] = [
  {
    backendKey: FlightTicketsRefundablePropsType.NONE,
    labelKey: defineMessage('Any (allow all)'),
  },
  {
    backendKey: FlightTicketsRefundablePropsType.REFUNDABLE,
    labelKey: defineMessage('Fully refundable fares only'),
  },
  {
    backendKey: FlightTicketsRefundablePropsType.REFUNDABLE_WITH_PENALTY,
    labelKey: defineMessage('Partially refundable fares only'),
  },
  {
    backendKey: FlightTicketsRefundablePropsType.DONT_ALLOW,
    labelKey: defineMessage('Non-refundable fares allowed'),
  },
];

export const policyFlightChangeableTicketsOptions: IKeyLabelPair[] = [
  {
    backendKey: FlightTicketsChangeablePropsType.NONE,
    labelKey: defineMessage('Any (allow all)'),
  },
  {
    backendKey: FlightTicketsChangeablePropsType.CHANGEABLE,
    labelKey: defineMessage('Fully changeable fares only'),
  },
  {
    backendKey: FlightTicketsChangeablePropsType.CHANGEABLE_WITH_PENALTY,
    labelKey: defineMessage('Partially changeable fares only'),
  },
  {
    backendKey: FlightTicketsChangeablePropsType.DONT_ALLOW,
    labelKey: defineMessage('Non-changeable fares allowed'),
  },
];

export const policyFlightHighestCabinClassOptions: IKeyLabelPair[] = [
  {
    backendKey: -1,
    labelKey: defineMessage('None'),
  },
  ...cabinTypes,
];

export const emptyPolicyUserGroup: IPolicyUserGroup = {
  userOrgIds: [],
  legalEntityIds: [],
  officeIds: [],
  departments: [],
  costCenters: [],
  grades: [],
  positionTitles: [],
  countryCodes: [],
  workerTypes: [],
  accountingCodes: [],
  personas: [],
};

export const emptyPolicyErrors: IPolicyErrors = {
  name: '',
  policyApprovalInfo: '',
  policyDefaultApprover: '',
  userGroups: [],
  maxHotelPriceByLocation: [],
  highestFlightCabinByDurationDomestic: '',
  highestFlightCabinByDurationInternational: '',
  maxFlightBookingPriceByDuration: '',
  maxFlightBookingPriceDomestic: '',
  maxFlightBookingPriceInternational: '',
  flightCabinUpgradeDomestic: '',
  flightCabinUpgradeInternational: '',
  emptyAirReasonCode: '',
  emptyCarReasonCode: '',
  emptyHotelReasonCode: '',
  emptyRailReasonCode: '',
  emptyCabinClassNotAllowedDomesticReason: '',
  emptyCabinClassNotAllowedInternationalReason: '',
  emptyEconomyFareNotAllowedDomesticReason: '',
  emptyEconomyFareNotAllowedInternationalReason: '',
  emptyNotAllowedCarTypesReason: '',
  emptyNotAllowedHotelTypesReason: '',
  emptyRailHighestClassReason: '',
  llfLayoverDurationInHoursDomestic: '',
  llfLayoverDurationInHoursInternational: '',
  llfFlightTimeWindowDomestic: '',
  llfFlightTimeWindowInternational: '',
  llfCarrierAirlines: '',
  llfFlightTimeWindowMinutesDomestic: '',
  llfFlightTimeWindowMinutesInternational: '',
  llfMaxLayoverDurationMinutesDomestic: '',
  llfMaxLayoverDurationMinutesInternational: '',
  hotelMedianEmptyDistance: '',
  hotelMedianEmptyDistanceUnit: '',
  hotelMedianEmptyMaxRating: '',
  hotelMedianEmptyMinRating: '',
  hotelMedianEmptyTaxFeeIncluded: '',
  hotelMedianEmptyBufferThreshold: '',
  hotelMedianEmptyBufferThresholdRate: '',
};

const MINUTES_PER_DAY = 30 * 60;

export const emptyMaxPriceForDurationState: { min: number; max: number; value: string } = {
  value: '',
  min: 0,
  max: MINUTES_PER_DAY,
};

export const emptyMaxDomesticCabinState: { min: number; max: number; value: string } = {
  min: 0,
  max: MINUTES_PER_DAY,
  value: '-1',
};

export const emptyMaxCabinForMileageState: { min: number; max: number; value: string } = {
  min: 0,
  max: CABIN_CUSTOMIZATION_BY_MILEAGE_MAX,
  value: '-1',
};

export const emptyPolicyMaxPriceHotelCustomization: { amount: string; currency: string; address: IPostalAddress } = {
  // TODO (Bhavan): use zeroMoneyWithOriginal once policy makes a move to V2 types
  amount: '',
  currency: '',
  address: cloneDeep(emptyTravelerAddress),
};

export const emptyPolicy: IPolicy = {
  policyId: {
    id: '',
  },
  version: 0,
  type: PolicyTypeEnum.GROUP,
  category: PolicyCategory.CATEGORY_UNKNOWN,
  name: '',
  currency: {
    isoCode: '',
  },
  isMembershipViaHrFeed: false,
  enableAgentActionOverride: false,
  approvalInfo: {
    approvalProcessType: PolicyApprovalInfoApprovalProcessType.NONE,
    isManagerApprover: true,
    designatedApprovers: [],
    travelTypes: [],
    approvalConditions: [],
    defaultApprovers: [],
  },
  inPolicyApprovalInfo: {
    approvalProcessType: PolicyApprovalInfoApprovalProcessType.NONE,
    isManagerApprover: true,
    designatedApprovers: [],
    travelTypes: [],
    approvalConditions: [],
    defaultApprovers: [],
  },
  outOfPolicyApprovalInfo: {
    approvalProcessType: PolicyApprovalInfoApprovalProcessType.NONE,
    isManagerApprover: true,
    designatedApprovers: [],
    travelTypes: [],
    approvalConditions: [],
    defaultApprovers: [],
  },
  usersGroups: [],
  maxFlightBookingPriceByDuration: {
    props: {
      maxPriceForDurationList: [{ maxPrice: { amount: 0, currencyCode: '' }, durationInMinutes: 0 }],
    },
  },
  highestFlightCabinByDurationDomestic: {
    props: {
      highestCabinForDurationList: [{ cabin: -1, durationInMinutes: 0 }],
      highestCabinForMilageList: [{ cabin: -1 }],
    },
  },
  highestFlightCabinByDurationInternational: {
    props: {
      highestCabinForDurationList: [{ cabin: -1, durationInMinutes: 0 }],
      highestCabinForMilageList: [{ cabin: -1 }],
    },
  },
  highestFlightCabinOvernight: {
    props: {
      cabin: -1,
    },
  },
  flightCabinUpgrade: {
    props: {
      isAllowed: false,
    },
  },
  flightAdvanceBookingWindow: {
    props: {
      numDaysInAdvance: 0,
    },
  },
  co2EmissionPerPassengerPerKm: {
    props: {
      emission: 0,
    },
  },
  flightTicketsRefundable: {
    props: {
      type: 1,
    },
  },
  flightTicketsChangeable: {
    props: {
      type: 1,
    },
  },

  // restrictedHotels: {
  //   isLinked: false,
  //   props: {
  //     hotels: [],
  //   },
  // },
  maxHotelPriceByLocation: {
    props: {
      maxPriceInLocationList: [],
      // TODO (Bhavan): use zeroMoneyWithOriginal once policy makes a move to V2 types
      defaultMaxPrice: { currencyCode: '', amount: 0, convertedAmount: 0, convertedCurrency: '' },
    },
  },
  hotelAdvanceBookingWindow: {
    props: {
      numDaysInAdvance: 1,
    },
  },
  hotelCancellation: {
    props: {
      type: 1,
    },
  },
  hotelRating: {
    props: {
      ratingRange: { min: 0, max: 0 },
    },
  },
  // /** Car Specific Rules. */
  maxCarPricePerNumberOfDays: {
    props: {
      numDays: 1,
      maxPrice: { currencyCode: '', amount: 0 },
    },
  },
  allowedCarTypes: {
    props: {
      carTypes: [],
    },
  },
  rules: [],
  isRestrictive: false,
  inPolicyApprovalInfos: [],
  outOfPolicyApprovalInfos: [],
  outOfPolicyAgentApprovalInfos: [],
};

export const newPolicyLinkingState: IPolicyLinkingState = {
  currency: true,
  maxFlightBookingPriceByDuration: true,
  highestFlightCabinByDurationDomestic: true,
  highestFlightCabinByDurationInternational: true,
  highestFlightCabinOvernight: true,
  maxFlightBookingPriceDomestic: true,
  maxFlightBookingPriceInternational: true,
  flightCabinUpgrade: true,
  flightCabinUpgradeDomestic: true,
  flightCabinUpgradeInternational: true,
  flightBookingWindow: true,
  flightBookingWindowDomestic: true,
  flightBookingWindowInternational: true,
  co2EmissionPerPassengerPerKm: true,
  flightRefundableTickets: true,
  flightChangeableTickets: true,
  flightReasonCodes: true,
  economyFaresDomestic: true,
  economyFaresInternational: true,
  cabinClassNotAllowedDomestic: true,
  cabinClassNotAllowedInternational: true,
  // hotel related state
  maxHotelPriceByLocation: true,
  hotelBookingWindow: true,
  hotelCancellationPolicy: true,
  hotelExperience: true,
  hotelReasonCodes: true,
  notAllowedHotelTypes: true,
  hotelMedianRates: true,
  lowestFarePerHotelProperty: true,
  // car related state
  maxCarPricePerNumberOfDays: true,
  allowedCarTypes: true,
  carReasonCodes: true,
  notAllowedCarTypes: true,
  notAllowedCarEngineTypes: true,
  llfConfigurations: true,
  railBookingWindow: true,
  railMaxBookingPrice: true,
  railReasonCodes: true,
  railTravelClass: true,
  allowedAirAddons: true,
};

export const emptyEmployeePolicyState: IPolicyState = {
  policyId: '',
  type: PolicyTypeEnum.GROUP,
  category: PolicyCategory.EMPLOYEE,
  name: '',
  currency: '',
  isMembershipViaHrFeed: false,
  inPolicyApprovalTypeForAir: PolicyApprovalInfoApprovalProcessType.NONE,
  inPolicyApprovalTypeForCar: PolicyApprovalInfoApprovalProcessType.NONE,
  inPolicyApprovalTypeForHotel: PolicyApprovalInfoApprovalProcessType.NONE,
  inPolicyApprovalTypeForRail: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyApprovalTypeForAir: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyApprovalTypeForCar: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyApprovalTypeForHotel: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyApprovalTypeForRail: PolicyApprovalInfoApprovalProcessType.NONE,
  inPolicyApprovalTypeForDomesticAir: PolicyApprovalInfoApprovalProcessType.NONE,
  inPolicyApprovalTypeForInternationalAir: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyApprovalTypeForDomesticAir: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyApprovalTypeForInternationalAir: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyAgentApprovalTypeForAir: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyAgentApprovalTypeForHotel: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyAgentApprovalTypeForCar: PolicyApprovalInfoApprovalProcessType.NONE,
  outOfPolicyAgentApprovalTypeForRail: PolicyApprovalInfoApprovalProcessType.NONE,
  policyApprover: [],
  policyApproverIsManager: true,
  policyApproverIsEmployeeLevel: false,
  shouldNotifyManager: false,
  policyDefaultApprover: [],
  usersGroups: [
    {
      costCenters: [],
      grades: [],
      positionTitles: [],
      userOrgIds: [],
      officeIds: [],
      legalEntityIds: [],
      departments: [],
      countryCodes: [],
      workerTypes: [],
      accountingCodes: [],
      personas: [],
    },
  ],
  maxFlightBookingPriceByDuration: [],
  highestFlightCabinByDurationDomestic: {
    props: [],
  },
  highestFlightCabinByDurationInternational: {
    props: [],
  },
  highestFlightCabinOvernight: '',
  overNightFlightTimeStart: undefined,
  overNightFlightTimeEnd: undefined,
  overNightFlightTimeOverlapMins: undefined,
  overNightFlightIncludeLayover: undefined,
  flightBookingWindow: undefined,
  flightBookingWindowDomestic: undefined,
  flightBookingWindowInternational: undefined,
  co2EmissionPerPassengerPerKm: '',
  flightCabinUpgrade: undefined,
  flightCabinUpgradeDomestic: undefined,
  flightCabinUpgradeInternational: undefined,
  enableAgentActionOverride: false,
  flightChangeableTickets: '',
  flightRefundableTickets: '',
  llfLayoverDurationInHoursDomestic: 0,
  llfLayoverDurationInHoursInternational: 0,
  llfMaxNumberOfStops: LowestLogisticalFarePropsMaxNumberOfStops.ANY,
  llfFlightTimeWindowDomestic: 0,
  llfFlightTimeWindowInternational: 0,
  llfFlightTimeWindowMinutesDomestic: 0,
  llfFlightTimeWindowMinutesInternational: 0,
  llfMaxLayoverDurationMinutesDomestic: 0,
  llfMaxLayoverDurationMinutesInternational: 0,
  llfAirportConnectionChange: 0,
  llfCarrierType: LowestLogisticalFarePropsCarrierType.ANY,
  llfCarrierAirlines: [],
  llfRejectMixCabinFlights: false,
  hotelBookingWindow: '',
  hotelCancellationPolicy: '',
  hotelExperience: { min: 0, max: 0 },
  maxHotelBookingPriceProps: {
    type: MaxHotelBookingPricePropsType.LESS_THAN_MEDIAN,
    isTaxIncluded: false,
    percentage: 0,
  },
  maxHotelPriceByLocationDefaultPriceAmount: '',
  maxHotelPriceByLocationCustomizations: [],
  maxCarPricePerNumberOfDays: '',
  allowedCarTypes: [],
  notAllowedCarTypes: [],
  notAllowedCarEngineTypes: [],
  notAllowedCarTypeReason: '',
  maxFlightBookingPriceDomestic: undefined,
  maxFlightBookingPriceInternational: undefined,
  lowestFarePerHotelPropertyProps: { onlyAllowLowestFare: false },
  carReasonCodes: [],
  carCaptureReasonCode: false,
  hotelReasonCodes: [],
  hotelCaptureReasonCode: false,
  hotelRestrictByKeywords: [],
  flightReasonCodes: [],
  flightCaptureReasonCode: false,
  economyFaresDomesticDisallowed: false,
  economyFaresDomesticReason: '',
  economyFaresInternationalDisallowed: false,
  economyFaresInternationalReason: '',
  cabinClassNotAllowedDomestic: [],
  cabinClassNotAllowedDomesticReason: '',
  cabinClassNotAllowedInternational: [],
  cabinClassNotAllowedInternationalReason: '',
  notAllowedHotelTypes: [],
  notAllowedHotelReason: '',
  // rail
  railCaptureReasonCode: false,
  railReasonCodes: [],
  railHighestClass: [],
  railMaxPrice: [],
  railBookingWindow: undefined,
  // addons
  allowedAirAddons: [],
};

export const emptyNonEmployeePolicyState: IPolicyState = {
  ...emptyEmployeePolicyState,
  category: PolicyCategory.GUEST,
  policyApproverIsManager: false,
};

// Generates labels for slider by hours customization
export const policyMaxPriceCustomizationMarks: { [k: string]: string } = new Array(31)
  .fill(0)
  .reduce(
    (acc, _, ind, arr) => ({ ...acc, [ind * 60]: ind % 5 === 0 ? `${ind}${ind === arr.length - 1 ? 'h+' : 'h'}` : '' }),
    {},
  );

// Generates labels for slider by miles customization
export const policyMaxPriceByMileCustomizationMarks: { [k: string]: string } = new Array(21).fill(0).reduce(
  (acc, _, ind, arr) => ({
    ...acc,
    [ind * 500]: ind % 5 === 0 ? `${ind * 500} ${'miles'}${ind === arr.length - 1 ? '+' : ''}` : '',
  }),
  {},
);

export const emptyCompanyAuthDetails: ICompanyAuthentication = {
  type: null,
  userProvisionAllowed: false,
  applicationId: '',
  idleSessionTimeoutMin: 0,
  tokenEndpoint: '',
  clientAuthentication: undefined,
  requireProxy: false,
};

export const emptyCompanyAuthErrorState: ICompanyAuthValidationState = {
  isValid: ValidationStatus.NOT_STARTED,
  errorFields: { applicationId: '', tokenEndpoint: '', clientAuthentication: '' },
};

export const mapClientAuthenticationMethodV1toV2: Record<
  ClientAuthenticationMethodV1,
  ClientAuthenticationMethod | undefined
> = {
  [ClientAuthenticationMethodV1.CLIENT_SECRET_BASIC]: ClientAuthenticationMethod.Basic,
  [ClientAuthenticationMethodV1.CLIENT_SECRET_POST]: ClientAuthenticationMethod.Post,
  [ClientAuthenticationMethodV1.CLIENT_AUTHENTICATION_METHOD_UNKNOWN]: undefined,
  [ClientAuthenticationMethodV1.UNRECOGNIZED]: undefined,
};

export const companyUserProvisionAllowed: IKeyLabelPair<boolean>[] = [
  { labelKey: globalTranslationKeys.YES, backendKey: true },
  { labelKey: globalTranslationKeys.NO, backendKey: false },
];

export const emptyUserInfoError: IUserInfoValidationState = {
  errorFields: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffixName: '',
    email: '',
    employeeId: '',
    phoneNumber: {
      countryCode: '',
      phoneNumber: '',
    },
    legalEntity: '',
  },
  isValid: ValidationStatus.NOT_STARTED,
};

export const DEFAULT_USER_LIST_PAGE_INDEX = 1;
export const DEFAULT_USER_LIST_ITEM_PER_PAGE = 13;
export const DEFAULT_POLICY_USER_LIST_ITEM_PER_PAGE = 10;

export const EXPENCIFY_PARTNER_EMAIL = 'receipts@expensify.com';

export const paymentMethodsCreationNextScreen: { [k: string]: string } = {
  NONE: '-1',
  CC: '0',
  GC: '1',
  VC: '2',
};
export const paymentMethodTypeOptions: ILabelValuePair[] = [
  { label: 'Credit card', value: 'CC' },
  { label: 'Virtual card', value: 'VC' },
];

export const confermaCardCompanyOptions: { value: ConfermaMetadataCompanyEnum; label: string }[] = [
  { value: ConfermaMetadataCompanyEnum.Amex, label: 'American Express' },
  { value: ConfermaMetadataCompanyEnum.Discover, label: 'Discover' },
  { value: ConfermaMetadataCompanyEnum.Mastercard, label: 'Mastercard' },
  { value: ConfermaMetadataCompanyEnum.Visa, label: 'Visa' },
];

export const DEFAULT_POLICY_ID = 'DEFAULT';

export const SERVICE_FEE_TYPE = {
  TRIP_FEE: 'Trip fee',
  AGENT_FEE: 'Agent fee',
};

export const maxHotelSearchRadiusLengthUnitPropsTypes: ILabelValuePair[] = [
  { label: defineMessage('Miles'), value: LengthUnitEnum.MILE.toString() },
  { label: defineMessage('Kilometers'), value: LengthUnitEnum.KM.toString() },
];

export const hotelMedianTaxAndFeesPropsTypes: ILabelValuePair[] = [
  { label: defineMessage('Included'), value: 'true' },
  { label: defineMessage('Excluded'), value: 'false' },
];

export const hotelMedianBufferThresholdPropsTypes: ILabelValuePair[] = [
  { label: defineMessage('More than'), value: MaxHotelBookingPricePropsType.MORE_THAN_MEDIAN.toString() },
  { label: defineMessage('Less than'), value: MaxHotelBookingPricePropsType.LESS_THAN_MEDIAN.toString() },
];

export const vendorTypeToRestrictMessage: Record<string, string> = {
  airline: defineMessage('Enter reason for restricting airline'),
  hotel: defineMessage('Enter reason for restricting hotel'),
  hotelBrand: defineMessage('Enter reason for restricting hotel brand'),
  car: defineMessage('Enter reason for restricting car'),
};

export const vendorTypeToAutocompleteLabel: Record<string, string> = {
  airline: defineMessage('Airline name'),
  hotel: defineMessage('Hotel name'),
  hotelBrand: defineMessage('Hotel brand name'),
  car: defineMessage('Car name'),
};

export const vendorTypeToAutocompleteSearchLabel: Record<string, string> = {
  airline: defineMessage('Search airline'),
  hotel: defineMessage('Search hotel'),
  hotelBrand: defineMessage('Search hotel brand'),
  car: defineMessage('Search car'),
};

export const vendorTypeToAddNewVendorText: Record<string, string> = {
  airline: defineMessage('Add new airline'),
  hotel: defineMessage('Add new hotel'),
  hotelBrand: defineMessage('Add new hotel brand'),
  car: defineMessage('Add new car'),
};

export const SectionHeader: Record<VendorType, string> = {
  [VendorType.Airline]: defineMessage('Airlines'),
  [VendorType.Hotel]: defineMessage('Hotel property'),
  [VendorType.HotelBrand]: defineMessage('Hotel brands'),
  [VendorType.Car]: defineMessage('Car suppliers'),
  [VendorType.HotelChain]: defineMessage('Hotel chains'),
};

export const AddButtonText: Record<VendorType, string> = {
  [VendorType.Airline]: defineMessage('Add airline'),
  [VendorType.Hotel]: defineMessage('Add hotel'),
  [VendorType.HotelBrand]: defineMessage('Add hotel brand'),
  [VendorType.Car]: defineMessage('Add car supplier'),
  [VendorType.HotelChain]: defineMessage('Add hotel chain'),
};

export const TableHeader: Record<VendorType, string> = {
  [VendorType.Airline]: defineMessage('Airline name'),
  [VendorType.Hotel]: defineMessage('Hotel name'),
  [VendorType.HotelBrand]: defineMessage('Hotel brand name'),
  [VendorType.Car]: defineMessage('Car supplier name'),
  [VendorType.HotelChain]: defineMessage('Hotel group name'),
};

const TierStatus = {
  ...PreferenceTierLevel,
  UNASSIGNED: 'UNASSIGNED',
  RESTRICTED: 'RESTRICTED',
  CUSTOM: 'CUSTOM',
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
type TierStatus = (typeof TierStatus)[keyof typeof TierStatus];

interface TierStatusOption {
  value: string;
  label: string;
  shouldFilter?: boolean;
}

export const TierStatusOptionConfig: Record<TierStatus, TierStatusOption> = {
  [TierStatus.UNASSIGNED]: { value: TierStatus.UNASSIGNED, label: defineMessage('Unassigned'), shouldFilter: false },
  [TierStatus.TIER_1]: { value: TierStatus.TIER_1, label: defineMessage('Tier 1'), shouldFilter: true },
  [TierStatus.TIER_2]: { value: TierStatus.TIER_2, label: defineMessage('Tier 2'), shouldFilter: true },
  [TierStatus.TIER_3]: { value: TierStatus.TIER_3, label: defineMessage('Tier 3'), shouldFilter: true },
  [TierStatus.RESTRICTED]: { value: TierStatus.RESTRICTED, label: defineMessage('Restricted'), shouldFilter: false },
  [TierStatus.CUSTOM]: { value: TierStatus.CUSTOM, label: defineMessage('Custom'), shouldFilter: false },
};
