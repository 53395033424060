import type { TransactionItem } from '@spotnana/types/openapi/models/transaction-item';
import { TransactionType } from '@spotnana/types/openapi/models/transaction-type';

import type { ItemGroup } from '../../../types/api/v2/obt/model/item-group';
import type { CarItem } from '../../../types/api/v2/obt/model/car-item';
import type { Transaction } from '../../../types/api/v2/obt/model/transaction';
import { defineCommonMessage, defineMessage } from '../../../translations/defineMessage';
import { carExtrasLabelMap, carTypeToNameMapper } from '../../../constants/cars';
import getDurationInDaysOrHours from '../../../date-utils/getDurationInDaysOrHours';
import { localizeDateTime } from '../../../translations/localizers/datetime';
import type { ICarRate } from '../../../types';
import { CancellationPolicy1PolicyEnum, CarExtraTypeEnum } from '../../../types';
import type { Car } from '../../../types/api/v2/obt/model/car';
import { PaymentMethod } from '../../../types/api/v2/obt/model/payment-method';
import {
  MoneyUtil,
  createUserNameFromFullName,
  getLocationFullAddressV2,
  getNameStringFromName,
  maskCardNumber,
} from '../../../utils';
import type { PnrV3ManagerProps } from '../PnrV3Manager';
import { PnrV3Manager } from '../PnrV3Manager';
import { LengthUnitEnum } from '../../../types/api/v1/common/length';
import type {
  CarPnrTransaction,
  CarPriceBreakdown,
  CarTransactionDetails,
  TripServiceFeePnrTransaction,
} from '../types';

export class CarPnrV3Manager extends PnrV3Manager {
  carPnr?: Car;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    super({ pnrData, pnrId });
    this.carPnr = this.pnrData.carPnr;
  }

  private confirmationNumber() {
    return this.carPnr?.vendorConfirmationNumber ?? '';
  }

  private vendorCancellationId() {
    return this.carPnr?.vendorCancellationId ?? '';
  }

  private vendorName() {
    return this.carPnr?.carInfo?.vendor.name ?? '';
  }

  private displayCarName() {
    return this.carPnr?.carInfo?.carSpec.displayName ?? '';
  }

  public vendorInfo() {
    return {
      confirmationNumber: this.confirmationNumber(),
      vendorCancellationId: this.vendorCancellationId(),
      vendorName: this.vendorName(),
      vendorCode: this.carPnr?.carInfo?.vendor.code ?? '',
      carName: carTypeToNameMapper(this.carPnr?.carInfo?.carTypeCode) ?? '',
      displayName: this.displayCarName(),
    };
  }

  public cancellationPolicyDetails({ withUtcDate = false }): {
    message: string;
    variables: Record<string, unknown>;
    refundAmount: MoneyUtil;
    policyType?: CancellationPolicy1PolicyEnum;
  } {
    const { carPnr } = this;
    const cancellationPolicy = carPnr?.cancellationPolicy;
    const cancellationDeadline = cancellationPolicy?.deadline?.iso8601;
    const cancellationPolicyDeadline = localizeDateTime(cancellationPolicy?.deadline?.iso8601, {
      dateStyle: 'medium',
      timeStyle: 'short',
    });
    const cancellationPolicyDeadlineUtc = cancellationDeadline
      ? new Date(cancellationDeadline).toUTCString().replace('GMT', 'UTC')
      : '';

    const refundAmount = MoneyUtil.convertV2MoneyToMoneyUtil(cancellationPolicy?.amount);

    if (cancellationPolicy?.policy === CancellationPolicy1PolicyEnum.FreeCancellationUntil) {
      return {
        message: 'Cancel by {{cancellationPolicyDeadline}} to avoid penalty',
        variables: {
          cancellationPolicyDeadline: withUtcDate ? cancellationPolicyDeadlineUtc : cancellationPolicyDeadline,
        },
        refundAmount,
        policyType: CancellationPolicy1PolicyEnum.FreeCancellationUntil,
      };
    }
    if (cancellationPolicy?.policy === CancellationPolicy1PolicyEnum.PartiallyRefundable) {
      return {
        message: 'Refundable for {{amount} {{currency}}',
        variables: { amount: refundAmount.getAmount(), currency: refundAmount.getCurrency() },
        refundAmount,
        policyType: CancellationPolicy1PolicyEnum.PartiallyRefundable,
      };
    }
    if (cancellationPolicy?.policy === CancellationPolicy1PolicyEnum.NonRefundable) {
      return {
        message: 'Non refundable',
        variables: {},
        refundAmount,
        policyType: CancellationPolicy1PolicyEnum.NonRefundable,
      };
    }
    return {
      message: 'Free cancellation',
      variables: {},
      refundAmount,
    };
  }

  public amenities() {
    const amenities = this.carPnr?.carInfo?.carSpec.amenities;
    const { numLargeBags = 0, numSmallBags = 0, numSeatBelts = 0, numDoors = 0, numSeats = 0 } = amenities ?? {};
    return {
      numLargeBags,
      numSmallBags,
      numSeatBelts,
      numDoors,
      numSeats,
    };
  }

  public carDetails() {
    const { carPnr } = this;

    const mileageCharge = carPnr?.carInfo?.extraMileageCharge ?? { amount: 0, currencyCode: '' };
    const mileage = mileageCharge.amount === 0 ? defineMessage('Unlimited mileage') : defineMessage('Limited mileage');

    return {
      pickUpDateTime: carPnr?.pickupDateTime,
      pickUpAddress: getLocationFullAddressV2(carPnr?.carInfo.pickupLocation.address),
      dropOffDateTime: carPnr?.dropOffDateTime,
      dropOffAddress: getLocationFullAddressV2(carPnr?.carInfo.dropOffLocation.address),
      reservationNumber: this.confirmationNumber(),
      bookingId: this.pnrId,
      noOfBookedDays: getDurationInDaysOrHours(
        carPnr?.pickupDateTime.iso8601 ?? '',
        carPnr?.dropOffDateTime.iso8601 ?? '',
      ),
      co2EmissionsValue: carPnr?.carInfo?.co2EmissionDetail?.co2EmissionValue ?? 0,
      mileage,
    };
  }

  public carDetailsV2() {
    const { carPnr, pnrData, pnrId } = this;

    const carInfo = carPnr?.carInfo;
    const vendorName = carInfo?.vendor.name;
    const vendorCode = carInfo?.vendor.code;
    const carName = carTypeToNameMapper(carInfo?.carTypeCode) ?? carPnr?.carInfo?.carSpec?.displayName;
    const carSpec = carInfo?.carSpec;

    const confirmationNumber = carPnr?.vendorConfirmationNumber;
    const isPnrOutOfPolicy = this.isPnrOutOfPolicy();

    const pickupLocation = carInfo?.pickupLocation;
    const dropoffLocation = carInfo?.dropOffLocation;
    const amenities = carInfo?.carSpec.amenities;
    const mileage = carPnr?.carInfo.mileageAllowance;

    const pickupDateTime = carPnr?.pickupDateTime.iso8601;
    const dropoffDateTime = carPnr?.dropOffDateTime.iso8601;
    const noOfBookedDays = getDurationInDaysOrHours(pickupDateTime ?? '', dropoffDateTime ?? '');

    const cancellationPolicyText = this.cancellationPolicyDetails({ withUtcDate: true });

    const passengers = pnrData?.pnrTravelers?.map((pnrTraveler) => ({
      name: createUserNameFromFullName(pnrTraveler.personalInfo?.name),
      id: pnrTraveler.userId.id,
      hasLoyalty: !!pnrTraveler.loyalties?.filter((loyalty) => loyalty.type === 'CAR').length,
    }));
    const specialEquipment = (carPnr?.rate?.extras || []).map((carExtra) => carExtra.type);

    return {
      amenities: {
        numLargeBags: amenities?.numLargeBags ?? 0,
        numSeatBelts: amenities?.numSeatBelts ?? 0,
        numSmallBags: amenities?.numSmallBags ?? 0,
        numSeats: amenities?.numSeats ?? 0,
      },
      cancellationPolicyText,
      carName,
      carSpec,
      confirmationNumber,
      bookingId: pnrId ?? '',
      dropoff: {
        date: carPnr?.dropOffDateTime,
        location: {
          address: getLocationFullAddressV2(dropoffLocation?.address),
          coordinates: dropoffLocation?.coordinates,
          phoneNumber: dropoffLocation?.contactInfo?.phone,
        },
      },
      noOfBookedDays,
      pickup: {
        date: carPnr?.pickupDateTime,
        location: {
          address: getLocationFullAddressV2(pickupLocation?.address),
          coordinates: pickupLocation?.coordinates,
          phoneNumber: pickupLocation?.contactInfo?.phone,
        },
      },
      validAmenities: !!amenities && (!!amenities.numLargeBags || !!amenities.numSeatBelts || !!amenities.numSmallBags),
      vendorName,
      vendorCode,
      co2EmissionsValue: carInfo?.co2EmissionDetail?.co2EmissionValue ?? 0,
      bookingStatus: pnrData?.bookingStatus,
      isPnrOutOfPolicy,
      mileage,
      passengers,
      specialEquipment,
    };
  }

  public passengerDetails() {
    const passengerDetails =
      this.pnrData?.pnrTravelers?.map((travelerInfo) => {
        return {
          name: getNameStringFromName(travelerInfo?.personalInfo?.name),
          preferredName: getNameStringFromName(travelerInfo?.personalInfo?.name, { usePreferredName: true }),
          loyaltyNumber: travelerInfo.loyalties?.find((l) => l.type === 'CAR')?.id ?? '',
          loyaltyProgram: travelerInfo.loyalties?.find((l) => l.type === 'CAR')?.issuedBy ?? '',
        };
      }) ?? [];

    return passengerDetails;
  }

  public paymentInfo() {
    const { totalFareAmount, totalFare } = this.pnrData;
    const baseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareAmount?.base);
    const taxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareAmount?.tax);
    const totalAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFare);

    const paymentMethods =
      this.pnrData.paymentInfo?.map((paymentInfo) => {
        if (paymentInfo.fop.paymentMethod === PaymentMethod.CreditCard) {
          return {
            type: paymentInfo.fop.paymentMethod,
            name: maskCardNumber(paymentInfo.fop.card?.number ?? ''),
          };
        }
        if (
          paymentInfo.fop.paymentMethod === PaymentMethod.VendorProgramPayment &&
          paymentInfo.fop.paymentMetadata?.vendorProgramPaymentMetadata &&
          'directBilling' in paymentInfo.fop.paymentMetadata?.vendorProgramPaymentMetadata
        ) {
          return {
            type: paymentInfo.fop.paymentMethod,
            name: `Direct bill (${maskCardNumber(
              paymentInfo.fop.paymentMetadata?.vendorProgramPaymentMetadata.directBilling?.directBillingCode ?? '',
            )})`,
          };
        }
        return {
          type: paymentInfo.fop.paymentMethod,
          name: '',
        };
      }) ?? [];

    const reservationDate = this.carPnr?.rate.transactionDate;

    const carExtras = this.carPnr?.rate.extras;
    let carExtraRatesTotal = MoneyUtil.zeroMoney(carExtras?.[0]?.amount?.currencyCode);

    const carExtraRates: Record<string, ICarRate> = {};
    carExtras?.forEach((extra) => {
      const extraPrice = MoneyUtil.parse(MoneyUtil.convertV2MoneyToMoneyV1(extra.amount));
      carExtraRates[extra.type] = {
        label:
          carExtrasLabelMap[
            CarExtraTypeEnum[extra.type as keyof typeof CarExtraTypeEnum] || CarExtraTypeEnum.UNKNOWN_TYPE
          ] ?? carExtrasLabelMap[CarExtraTypeEnum.UNKNOWN_TYPE],
        price: extraPrice,
        extraMileageCharge: MoneyUtil.zeroMoney(),
        mileageAllowance: {
          length: -1,
          unit: LengthUnitEnum[-1],
        },
      };

      carExtraRatesTotal = carExtraRatesTotal.add(extraPrice);
    });

    return {
      baseAmount,
      taxAmount: taxAmount.subtract(carExtraRatesTotal),
      carExtras: carExtraRates,
      carExtraRatesTotal,
      totalAmount,
      paymentMethods,
      reservationDate,
    };
  }

  public pnrTitle() {
    const { noOfBookedDays } = this.carDetails();
    const pnrTitle = defineCommonMessage('{{noOfDays}} car rental');
    pnrTitle.values = {
      noOfDays: noOfBookedDays,
    };
    return pnrTitle;
  }

  /**
   * TRANSACTION RELATED METHODS
   */

  private getTransactionDetails(
    transactionType: TransactionType | undefined,
    items: TransactionItem[],
  ): CarTransactionDetails {
    const carItem = items.find((item) => item.itemType === 'CAR_ITEM') as CarItem;

    let description = defineCommonMessage('Car booking');

    if (transactionType === TransactionType.CarCancelled) {
      description = defineCommonMessage('Car cancellation');
    }

    return {
      description,
      carType: carTypeToNameMapper(carItem.carSpec?.type) ?? '',
      vendorName: this.carDetailsV2().vendorName ?? '',
    };
  }

  getCarTransactionPriceBreakdown(itemGroup: ItemGroup): CarPriceBreakdown {
    const basePriceBreakdown = super.getTransactionPriceBreakdown(itemGroup);
    const carItem = (itemGroup.items || []).find((item) => item.itemType === 'CAR_ITEM') as CarItem | undefined;

    const avgDailyRate = MoneyUtil.convertV2MoneyToMoneyUtil(carItem?.carDailyAverageRate);

    return {
      ...basePriceBreakdown,
      avgDailyRate,
    };
  }

  getTransactions(isTripFeeEnhancementEnabled: boolean): (CarPnrTransaction | TripServiceFeePnrTransaction)[] {
    const serviceFeeTransactions: TripServiceFeePnrTransaction[] =
      this.getServiceFeeTransactions(isTripFeeEnhancementEnabled);

    const travelerNamesMap: Record<string, string> = {};
    this.pnrData.pnrTravelers?.forEach((pnrTraveler) => {
      const travelerName = createUserNameFromFullName(pnrTraveler.personalInfo?.name);
      travelerNamesMap[pnrTraveler.userId.id] = travelerName;
    });

    const carTransactions: Transaction[] = this.pnrData.transactions || [];

    const relevantTransactionTypes = [TransactionType.CarBooked, TransactionType.CarCancelled];

    const carPnrTransactions: CarPnrTransaction[] = carTransactions.flatMap((transaction) => {
      return (transaction.itemGroups || [])
        .filter((itemGroup) => relevantTransactionTypes.includes(itemGroup.transactionType))
        .map((itemGroup) => {
          const travelerNames = itemGroup.userId?.id
            ? [travelerNamesMap[itemGroup.userId.id]]
            : Object.values(travelerNamesMap);

          return {
            ...itemGroup,
            type: 'CAR',
            ctc: transaction.ctc || [],
            customerCharges: (transaction.ctc || []).map(this.getCustomerCharge),
            priceBreakdown: this.getCarTransactionPriceBreakdown(itemGroup),
            transactionDetails: this.getTransactionDetails(itemGroup.transactionType, itemGroup.items || []),
            pnrId: this.pnrId,
            travelerNames,
          };
        });
    });
    return [...carPnrTransactions, ...serviceFeeTransactions];
  }
}
