import type { TFunction } from 'i18next';
import first from 'lodash/first';

import { BookingType } from '../types/api/v2/obt/model/booking-type';
import { ActionDisplayText } from '../types/api/v1/obt/policy/policy_common';
import { RateOptionTicketType } from '../types/api/v1/obt/air/air_search_response';
import type { PolicyInfo } from '../types/api/v1/obt/common/policy_info';
import { llfPredicates } from '../constants/policy';

export class FarePolicyManager {
  readonly policyInfo: PolicyInfo;

  readonly bookingType: BookingType;

  readonly ticketType?: RateOptionTicketType;

  constructor({
    policyInfo,
    bookingType,
    ticketType,
  }: {
    readonly policyInfo: PolicyInfo;
    readonly bookingType: BookingType;
    readonly ticketType?: RateOptionTicketType;
  }) {
    this.bookingType = bookingType;
    this.policyInfo = policyInfo;
    this.ticketType = ticketType;
  }

  private getAllRuleResultInfos(): PolicyInfo['ruleResultInfos'] {
    return this.policyInfo?.ruleResultInfos || [];
  }

  getOutOfPolicyRuleResultInfos(): PolicyInfo['ruleResultInfos'] {
    if (this.bookingType === BookingType.Rail) {
      return this.getAllRuleResultInfos().filter((rule) => !!rule.violationInfos?.[0]?.predicateString) ?? [];
    }
    return this.getAllRuleResultInfos().filter(
      (rule) =>
        rule.violationInfos.length > 0 &&
        !rule.actions.some((action) => action.preventBooking && action.preventBooking.prevent),
    );
  }

  getNotAllowedRuleResultInfos(): PolicyInfo['ruleResultInfos'] {
    return this.getAllRuleResultInfos().filter((rule) =>
      rule.actions.some((action) => action.preventBooking && action.preventBooking.prevent),
    );
  }

  checkFareOnlyOutOfPolicy(): boolean {
    return this.getOutOfPolicyRuleResultInfos().length > 0;
  }

  /**
   * @deprecated Use checkFareOnlyOutOfPolicy instead
   */
  checkFareOutOfPolicy(): boolean {
    if (this.bookingType === BookingType.Air) {
      return this.ticketType === RateOptionTicketType.MULTI || this.checkFareOnlyOutOfPolicy();
    }

    return this.checkFareOnlyOutOfPolicy();
  }

  checkFareNotAllowed(): boolean {
    return this.getNotAllowedRuleResultInfos().length > 0;
  }

  private getActionWithDisplayText(): ActionDisplayText | undefined {
    const notAllowedRules = this.getNotAllowedRuleResultInfos();
    const displayTexts = notAllowedRules
      .flatMap((rule) => rule.actions.map((action) => action.preventBooking && action.preventBooking.displayText))
      .filter((displayText) => displayText !== undefined);

    return first(displayTexts);
  }

  private getNotAllowedLabelText(tt: TFunction): string {
    const displayText = this.getActionWithDisplayText();
    if (displayText === undefined) return tt('Not allowed');
    switch (displayText) {
      case ActionDisplayText.OVER_BUDGET:
        return tt('Over budget');
      case ActionDisplayText.NOT_ALLOWED:
        return tt('Not allowed');
      case ActionDisplayText.INSUFFICIENT_POINTS:
        return tt('Insufficient points');
      default:
        return '';
    }
  }

  isOutOfPolicyOnlyBecauseOfLLF(): boolean {
    const ruleResultInfos = this.getOutOfPolicyRuleResultInfos();

    if (ruleResultInfos.length < 1) {
      return false;
    }

    return ruleResultInfos.every((ruleResult) =>
      ruleResult.violationInfos.every(
        (violationInfo) => violationInfo.predicate && llfPredicates.includes(violationInfo.predicate),
      ),
    );
  }

  // For the label text
  getPolicyViolationLabelText(
    tt: TFunction,
    showLowerFaresAvailable: boolean,
    isLastLeg: boolean,
    isLLFEnhancementEnabled: boolean,
  ): string {
    const isOutOfPolicy = this.getOutOfPolicyRuleResultInfos().length > 0;
    const isNotAllowed = this.checkFareNotAllowed();
    const isOutOfPolicyOnlyBecauseOfLLF = this.isOutOfPolicyOnlyBecauseOfLLF();

    // AIR NOt ALLOWED LABEL TEXT
    if (isNotAllowed) {
      return this.getNotAllowedLabelText(tt);
    }

    // AIR OUT OF POLICY LABEL TEXT
    const isMultiTicket = this.ticketType === RateOptionTicketType.MULTI;

    if (isMultiTicket && !isOutOfPolicy) {
      return tt('Separate Tickets');
    }

    const shouldShowLowerFares = showLowerFaresAvailable && isOutOfPolicyOnlyBecauseOfLLF;

    const showOOPForLastLeg = shouldShowLowerFares && !isLastLeg;

    const llfConditional = isLLFEnhancementEnabled ? showOOPForLastLeg : shouldShowLowerFares;

    if (llfConditional) {
      if (isOutOfPolicy && isMultiTicket) {
        return tt('Lower fares available, 1 more');
      }
      return tt('Lower fares available');
    }

    if (isOutOfPolicy && isMultiTicket) {
      return tt('Out of policy, 1 more');
    }
    return tt('Out of policy');
  }

  getOutOfPolicyOrLLFLabel(tt: TFunction, isLastLeg: boolean): string {
    const isOutOfPolicyOnlyBecauseOfLLF = this.isOutOfPolicyOnlyBecauseOfLLF();

    if (isOutOfPolicyOnlyBecauseOfLLF && !isLastLeg) {
      return tt('Lower fares available');
    }

    return tt('Out of policy');
  }
}
