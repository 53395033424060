import type { CreateAgentAnnouncementRequest } from '@spotnana/types/openapi/models/create-agent-announcement-request';
import type { ListAgentAnnouncementsRequest } from '@spotnana/types/openapi/models/list-agent-announcements-request';
import type { ListAgentAnnouncementsResponse } from '@spotnana/types/openapi/models/list-agent-announcements-response';
import type { UpdateAgentAnnouncementRequest } from '@spotnana/types/openapi/models/update-agent-announcement-request';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import { defaultQueryClient } from './defaultQueryClient';

// Response object for adding a new announcement
export interface AddAnnouncementResponse {
  announcementId: string;
}

// Fetches announcements
const listAnnouncements = async (request: ListAgentAnnouncementsRequest): Promise<ListAgentAnnouncementsResponse> => {
  try {
    // Make an API call to retrieve announcements
    const data = await api('POST', 'agentAnnouncements', {
      urlParam: '/list',
      data: request,
    });
    return data as ListAgentAnnouncementsResponse;
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Unique key for caching listAnnouncements query data
const listAnnouncementsKey = ['listAnnouncements'];

// Custom hook for using listAnnouncements with React Query
export const useListAnnouncements = (
  request: ListAgentAnnouncementsRequest,
  options?: Omit<UseQueryOptions<ListAgentAnnouncementsResponse, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const useListAnnouncementsKey = [...listAnnouncementsKey, request.paginationParams?.pageSize];
  return useQuery({
    queryKey: useListAnnouncementsKey,
    queryFn: () => listAnnouncements(request),
    ...options,
  });
};

// Adds a new announcement
const addAnnouncement = async ({
  request,
}: {
  request: CreateAgentAnnouncementRequest;
}): Promise<AddAnnouncementResponse> => {
  try {
    // Make an API call to add an announcement
    const data = await api('POST', 'agentAnnouncements', {
      data: {
        announcement: {
          ...request.announcement,
          id: uuidv4(),
        },
      },
    });
    return data as AddAnnouncementResponse;
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Custom hook for using addAnnouncement with React Query
export const useAddAnnouncement = () => {
  return useMutation({
    mutationFn: addAnnouncement,
    onSuccess: () => {
      // Invalidate the cache for listAnnouncements query when a new announcement is successfully added
      defaultQueryClient.invalidateQueries({
        queryKey: listAnnouncementsKey,
      });
    },
  });
};

// Updates an existing announcement
const updateAnnouncement = async ({ request }: { request: UpdateAgentAnnouncementRequest }): Promise<void> => {
  const { content, id, status } = request.announcement;
  try {
    // Make an API call to update an announcement
    await api('PUT', 'agentAnnouncements', {
      urlParam: `/${request.announcement.id}`,
      data: {
        announcement: {
          content,
          id,
          status,
        },
      },
    });
  } catch (e) {
    // Handle any errors that occur during the API call
    throw new SpotnanaError(e as Error);
  }
};

// Custom hook for using updateAnnouncement with React Query
export const useUpdateAnnouncement = () => {
  return useMutation({
    mutationFn: updateAnnouncement,
    onSuccess: () => {
      // Invalidate the cache for listAnnouncements query when an announcement is successfully updated
      defaultQueryClient.invalidateQueries({
        queryKey: listAnnouncementsKey,
      });
    },
  });
};
