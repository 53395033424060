import type { UseQueryOptions } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { AuthCodeTokenResponse } from '@spotnana/types/openapi/models/auth-code-token-response';

import type { IAuthenticatedUserBasicInfo, IImpersonateUserResponse, IRedirectInfo } from '../types/auth';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import type { ImpersonateUserRequest, ImpersonateUserResponse } from '../types/api/v1/auth/services';
import type { ProcessApprovalRequest, ApprovalResponse } from '../types/api/v1/obt/common/approval';
import { PersonaEnum } from '../types/api/v1/obt/common/user_org_id';
import type { UserBasicInfo } from '../types/api/v1/obt/common/user_basic_info';
import type { SpotnanaQueryResult, SpotnanaQueryMutationResult } from '../types/common';
import { defaultQueryClient } from './defaultQueryClient';
import type { IUserOrgId } from '../types/traveler';
import { removeEmptyValuesFromObject } from '../utils';
import type { InternalAPIError } from '../types';
import type {
  GetRelatedProfilesRequest,
  GetAllRelatedUserProfilesResponse,
} from '../types/api/v1/obt/profile/profile_services';

const userImpersonationKey = (userOrgId?: IUserOrgId): unknown[] => ['user-impersonation-key', userOrgId];

export const fetchImpersonationUserDetails = async (
  userOrgId?: IUserOrgId,
): Promise<ImpersonateUserResponse | null> => {
  const request: ImpersonateUserRequest = { userOrgId: removeEmptyValuesFromObject(userOrgId) };
  return (await api('POST', 'impersonateUser', { data: request })) as IImpersonateUserResponse;
};

export const useFetchImpersonationUserDetails = (
  userOrgId?: IUserOrgId,
): SpotnanaQueryResult<ImpersonateUserResponse | null> =>
  useQuery<ImpersonateUserResponse | null, SpotnanaError>({
    queryKey: userImpersonationKey(userOrgId),
    queryFn: () => fetchImpersonationUserDetails(userOrgId),
    enabled: !!userOrgId,
  });

const fetchPersonalProfileData = async (email: string): Promise<IAuthenticatedUserBasicInfo> =>
  (await api('POST', 'getPersonalProfile', { data: { email } })) as IAuthenticatedUserBasicInfo;

export const usePersonalProfileDataMutation = (): SpotnanaQueryMutationResult<
  IAuthenticatedUserBasicInfo,
  { email: string }
> =>
  useMutation({
    mutationFn: ({ email }: { email: string }) =>
      email ? fetchPersonalProfileData(email) : Promise.reject(new SpotnanaError('No email provided')),
  });

const fetchRelatedProfiles = async (req: GetRelatedProfilesRequest): Promise<GetAllRelatedUserProfilesResponse> =>
  (await api('POST', 'fetchRelatedProfiles', { data: req })) as GetAllRelatedUserProfilesResponse;

const relatedProfileDataQueryKey = ({ userId, organizationId }: GetRelatedProfilesRequest) => [
  'related-profile-data',
  userId,
  organizationId,
];

export function useRelatedProfileDataQuery<TData = GetAllRelatedUserProfilesResponse>(
  req: GetRelatedProfilesRequest,
  options?: Omit<UseQueryOptions<GetAllRelatedUserProfilesResponse, AxiosError, TData>, 'queryKey' | 'queryFn'>,
) {
  return useQuery<GetAllRelatedUserProfilesResponse, AxiosError, TData>({
    ...options,
    enabled: Boolean(req.userId?.id && req.organizationId?.id && options?.enabled !== false),
    queryFn: () => fetchRelatedProfiles(req),
    queryKey: relatedProfileDataQueryKey(req),
  });
}

export function useAdhocPersonasBasicInfos(
  req: GetRelatedProfilesRequest,
  options?: Omit<
    UseQueryOptions<GetAllRelatedUserProfilesResponse, AxiosError, UserBasicInfo[]>,
    'queryKey' | 'queryFn'
  >,
) {
  return useRelatedProfileDataQuery<UserBasicInfo[]>(req, {
    ...options,
    select: ({ userBasicInfos }) => userBasicInfos.filter((item) => item.persona === PersonaEnum.ADHOC),
  });
}

export const invalidateRelatedProfileDataQuery = (req: GetRelatedProfilesRequest): Promise<void> => {
  return defaultQueryClient.invalidateQueries({
    queryKey: relatedProfileDataQueryKey(req),
  });
};

export interface GroupedRelatedProfilesData {
  guests: GetAllRelatedUserProfilesResponse['userBasicInfos'];
  relatives: GetAllRelatedUserProfilesResponse['userBasicInfos'];
  personal: GetAllRelatedUserProfilesResponse['userBasicInfos'];
  employee: GetAllRelatedUserProfilesResponse['userBasicInfos'];
}

export const useGroupedRelatedProfilesQuery = (
  req: GetRelatedProfilesRequest,
  options?: Omit<
    UseQueryOptions<GetAllRelatedUserProfilesResponse, AxiosError, GroupedRelatedProfilesData>,
    'queryKey'
  >,
) =>
  useRelatedProfileDataQuery<GroupedRelatedProfilesData>(req, {
    ...options,
    select: ({ userBasicInfos }) => ({
      guests: userBasicInfos.filter((info) => info?.persona === PersonaEnum.ADHOC),
      relatives: userBasicInfos.filter((info) => info?.persona === PersonaEnum.RELATIVE),
      personal: userBasicInfos.filter((info) => info?.persona === PersonaEnum.PERSONAL),
      employee: userBasicInfos.filter((info) => info?.persona === PersonaEnum.EMPLOYEE),
    }),
  });

export const useRelatedProfileDataMutation = (): SpotnanaQueryMutationResult<
  GetAllRelatedUserProfilesResponse,
  GetRelatedProfilesRequest
> => useMutation({ mutationFn: (req: GetRelatedProfilesRequest) => fetchRelatedProfiles(req) });

const useCompanyRedirectionKey = (companyId: string): unknown[] => ['personal-profile-key', companyId];

export const useCompanyRedirectionQuery = (
  companyId: string,
  enabled = true,
): SpotnanaQueryResult<IRedirectInfo | null | undefined> =>
  useQuery<IRedirectInfo | null | undefined, SpotnanaError>({
    queryKey: useCompanyRedirectionKey(enabled ? companyId : ''),
    queryFn: async () =>
      (await api(
        'GET',
        'companyBaseUrl',
        {
          urlParam: `/${companyId}/redirect-info`,
        },
        { subType: 'companyRedirection' },
      )) as IRedirectInfo,
    enabled: enabled && !!companyId,
  });

const hardApproval = async (request: ProcessApprovalRequest | undefined): Promise<ApprovalResponse> => {
  try {
    const data = await api('POST', 'hardApproval', {
      data: request,
    });
    return data as ApprovalResponse;
  } catch (e) {
    const error = e as AxiosError<InternalAPIError>;
    return Promise.reject(error);
  }
};

export const useHardApprovalMutation = (): SpotnanaQueryMutationResult<ApprovalResponse, ProcessApprovalRequest> =>
  useMutation({ mutationFn: (request) => hardApproval(request) });

const getAuthTokenKey = (authCode?: string, tmcId?: string): string[] => [
  'auth-token-key',
  tmcId ?? '',
  authCode ?? '',
];

export const fetchAuthToken = async (
  authCode: string | undefined,
  tmcId: string | undefined,
): Promise<AuthCodeTokenResponse> => {
  if (!authCode || !tmcId) {
    throw new SpotnanaError('No authCode or tmcId parameter provided');
  }

  const data = await api('POST', 'getAuthToken', {
    urlParam: `/${tmcId}?auth_code=${authCode}`,
    data: {},
    headers: { Accept: 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' },
  });
  return data as AuthCodeTokenResponse;
};

export const useGetAuthToken = (
  authCode: string | undefined,
  tmcId: string | undefined,
): SpotnanaQueryResult<AuthCodeTokenResponse> =>
  useQuery<AuthCodeTokenResponse, SpotnanaError>({
    queryKey: getAuthTokenKey(authCode, tmcId),

    queryFn: () => fetchAuthToken(authCode, tmcId),
  });
