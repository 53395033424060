/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CustomFieldType as CustomFieldTypeV1 } from '../../../v1/obt/policy/user_defined_entity';

/**
 * The type of custom field.
 * @export
 * @enum {string}
 */

export const CustomFieldType = {
  Question: 'QUESTION',
  Meeting: 'MEETING',
  Budget: 'BUDGET',
  BrexToken: 'BREX_TOKEN',
} as const;

export type CustomFieldType = (typeof CustomFieldType)[keyof typeof CustomFieldType];

export const mapCustomFieldTypeV1toV2: Record<CustomFieldTypeV1, CustomFieldType> = {
  [CustomFieldTypeV1.QUESTION]: CustomFieldType.Question,
  [CustomFieldTypeV1.MEETING]: CustomFieldType.Meeting,
  [CustomFieldTypeV1.BUDGET]: CustomFieldType.Budget,
  [CustomFieldTypeV1.BREX_TOKEN]: CustomFieldType.BrexToken,
  [CustomFieldTypeV1.UNRECOGNIZED]: CustomFieldType.Question,
};
export const mapCustomFieldTypeV2toV1: Record< CustomFieldType, CustomFieldTypeV1 > = {
  [CustomFieldType.Question]: CustomFieldTypeV1.QUESTION,
  [CustomFieldType.Meeting] : CustomFieldTypeV1.MEETING,
  [CustomFieldType.Budget]: CustomFieldTypeV1.BUDGET,
  [CustomFieldType.BrexToken] :CustomFieldTypeV1.BREX_TOKEN,
};
