/* istanbul ignore file */
/* eslint-disable global-require,import/no-extraneous-dependencies */
import * as Sentry from '@sentry/react';
import type { Primitive, Extras, SeverityLevel } from '@sentry/core';
import { Config } from 'obt-common';

export const sentryErrorMessageIgnores = [
  // This happens due to marker sometimes, but otherwise on Windows 10 Chrome due to Lastpass etc
  // ( https://github.com/getsentry/sentry-javascript/issues/3440 )
  'Non-Error promise rejection captured',
  /** No need to get request failure redux errors as tickets */
  /request failed with status code/i,
  /** Chrome on Safari non-app issue */
  '<unknown>',
  /** Chrome on Safari non-app issue */
  '__gCrWeb',
  'ChunkLoadError',
  'Signout timeout fail',
  'moat_px is not defined',
  'IMUID is not defined',
  '__cmp is not defined',
  'apstagLOADED is not defined',
  "Cannot read properties of undefined (reading 'outputCurrentConfiguration')",
  "Cannot read properties of undefined (reading 'cmp')",
  "Cannot read properties of undefined (reading 'ns')",
  // We now store all old JS files on S3, so any of these errors are device/network related and not code related
  /failed to fetch dynamically imported module/i,
  'TypeError: Importing a module script failed.',
  'TypeError: Load failed',
  'TypeError: error loading dynamically imported module',
  /**
   * https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
   */
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: cancelled',
  // Ignore Network error raised by Cognito https://spotnana.atlassian.net/browse/ST-68063
  'Error: Network Error',
  // This is a known error raised for Brex Rewards Login
  // This flow is going to be removed soon so suppressing this error
  'Error: REDIRECTION_NEEDED_FOR_BE',
  'Error: timeout of 0ms exceeded',
  "TypeError: Cannot read properties of undefined (reading 'audioVolumeChange')",
  "TypeError: Cannot read properties of undefined (reading 'fireChangeEvent')",
  "TypeError: Cannot read properties of undefined (reading 'fireEvent')",
  "TypeError: Cannot read properties of undefined (reading 'fireReadyEvent')",
  "TypeError: Cannot read properties of undefined (reading 'setCurrentPosition')",
  "TypeError: Cannot read properties of undefined (reading 'setDefaultPosition')",
  "TypeError: Cannot read properties of undefined (reading 'setIsViewable')",
  "TypeError: Cannot read properties of undefined (reading 'setMaxSize')",
  "TypeError: Cannot read properties of undefined (reading 'setScreenSize')",
  'Error: WKWebView API client did not respond to this postMessage',
  /**
   * Ignore ResizeObserver loop limit exceeded error
   * @see https://spotnana.atlassian.net/browse/ST-38905
   */
  'ResizeObserver loop limit exceeded',
  /**
   * Ignore "Not Found" error raised by twilsock, as it does not impact the user.
   * @see https://spotnana.atlassian.net/browse/ST-71956
   */
  'Error: Not Found',
  /**
   * Ignore "t: disconnected (status: 0, code: 0)" error raised by twilsock, as it does not impact the user.
   * @see https://spotnana.atlassian.net/browse/ST-73280
   */
  't: disconnected (status: 0, code: 0)',
  /**
   * Ignore "Error: request 'GET' to 'aim.us1.twilio.com' timed out" error raised by twilsock, as it does not impact the user.
   * @see https://spotnana.atlassian.net/browse/ST-73515, https://spotnana.atlassian.net/browse/ST-73515
   */
  "Error: request 'GET' to 'aim.us1.twilio.com' timed out",
  "Error: request 'POST' to 'aim.us1.twilio.com' timed out",
  /**
   * Ignore "Error: disconnected" error raised by twilsock, as it does not impact the user.
   * @see https://spotnana.atlassian.net/browse/ST-73687
   */
  'Error: disconnected',
  /**
   * Ignore "'Error: Bad Request" error raised by twilsock, as it does not impact the user.
   * @see https://spotnana.atlassian.net/browse/ST-73816
   */
  'Error: Bad Request',
  /**
   * Ignore "* Twilsock: request timeout *' failed" error raised by twilsock, as it does not impact the user.
   * @see https://spotnana.atlassian.net/browse/ST-72157, https://spotnana.atlassian.net/browse/ST-73280, https://spotnana.atlassian.net/browse/ST-78911
   */
  /Twilsock: request timeout/i,
  /**
   * Ignore RangeError: Failed to construct 'IntersectionObserver', as it does not impact the user.
   * @see https://spotnana.atlassian.net/browse/ST-79000
   */
  "RangeError: Failed to construct 'IntersectionObserver'",
  /**
   * Ingore Error: Unable to preload CSS for https://app.spotnana.com/assets/index-a03ea337.css.
   * @see https://spotnana.atlassian.net/browse/ST-50975
   */
  'Error: Unable to preload CSS',

  /**
   * --------- Suppressing Error messages received only from Marriott ---------
   */
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78287
   */
  `TypeError: Cannot read properties of null (reading 'text')`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78569
   */
  `TypeError: Cannot read property 'audioVolumeChange' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78570
   */
  `TypeError: Cannot read property 'setScreenSize' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78571
   */
  `TypeError: Cannot read property 'fireReadyEvent' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78572
   */
  `TypeError: Cannot read property 'setMaxSize' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78573
   */
  `TypeError: Cannot read property 'fireChangeEvent' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78574
   */
  `TypeError: Cannot read property 'setDefaultPosition' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78575
   */
  `TypeError: Cannot read property 'fireEvent' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78576
   */
  `TypeError: Cannot read property 'setCurrentPosition' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-78577
   */
  `TypeError: Cannot read property 'setIsViewable' of undefined`,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-85740
   */
  /CustomEvent: Event `CustomEvent` (type=unhandledrejection)/i,
  /**
   * @see https://spotnana.atlassian.net/browse/ST-72790
   */
  'Error: Unable to connect: Access Token expired or expiration date invalid',
  /**
   * @see https://spotnana.atlassian.net/browse/ST-73280
   */
  't: disconnected (status: 0, code: 0)',
];

export const sentryUrlPatternIgnores = [
  // notoriously high bugs from edge.marker.io script
  /edge\.marker\.io/i,
  /marker\.io/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  /^pptr:\/\//i,
  /__puppeteer_evaluation_script__/i,
];

const sentryAllowUrls = [/https:\/\/.*\.spotnana\.com/gi];

const HAS_SENTRY = Boolean(Config.VITE_SENTRY_DSN?.length);

// 30s Timeout
const TIMEOUT = 30 * 1000;

const isSentryEnabled = () =>
  process.env.NODE_ENV !== 'development' && Config.VITE_ENVIRONMENT !== 'testing' && HAS_SENTRY;

/**
 * Extract error messages from Original Exception
 * @param exception
 * @returns
 */
const getErrorMessageFromException = (exception: unknown | undefined): string | undefined => {
  if (typeof exception === 'string') {
    return exception;
  }

  return (exception as Error)?.message;
};

export function initSentry(): void {
  if (isSentryEnabled()) {
    const eventLimiter: Record<string, boolean> = {};
    Sentry.init({
      dsn: Config.VITE_SENTRY_DSN,
      environment: Config.VITE_ENVIRONMENT,
      normalizeDepth: 6,
      denyUrls: sentryUrlPatternIgnores,
      allowUrls: sentryAllowUrls,
      ignoreErrors: sentryErrorMessageIgnores,
      attachStacktrace: true,
      integrations: [
        Sentry.thirdPartyErrorFilterIntegration({
          /**
           * @note: For third party integration to work correctly, this needs to be in Sync with `vite.config`
           */
          filterKeys: ['__SPOTNANA_FRONTEND__'],
          behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
        }),
      ],
      beforeSend: (event, hint) => {
        const message = getErrorMessageFromException(hint?.originalException);

        // do not send event if already sent in last 1 minute
        if (message) {
          if (message in eventLimiter) {
            // eslint-disable-next-line no-console
            console.log('Rate limiting activated for:', message);
            return null;
          }

          eventLimiter[message] = true;

          setTimeout(() => {
            delete eventLimiter[message];
          }, TIMEOUT);
        }

        /** Don't send error if from third party origins, adding this as denyUrls is seemingly flaky  */
        const isIgnored = isFileIgnored(event);

        if (isIgnored) {
          return null;
        }

        return event;
      },
    });
  }
}

function isFileIgnored(event: Sentry.Event): boolean {
  return sentryUrlPatternIgnores.some((blockedFileSubstring) =>
    // has excessive optional chaining to avoid any errors due to Sentry throwing unreliable data
    event?.exception?.values?.some((value) =>
      value?.stacktrace?.frames?.some(
        (frame) => frame?.abs_path?.match(blockedFileSubstring) || frame?.filename?.match(blockedFileSubstring),
      ),
    ),
  );
}

export const SENTRYTS_TESTABLE = {
  isFileIgnored,
};

export const addBreadcrumb = (breadcrumb: Sentry.Breadcrumb): void => {
  try {
    if (!isSentryEnabled()) {
      // skip
    }

    Sentry.addBreadcrumb(breadcrumb);
  } catch (error) {
    // ignore any errors
    if (Config.VITE_ENVIRONMENT !== 'production') {
      console.warn('[addBreadcrumb] Error:', error);
    }
  }
};

interface ICaptureExceptionOptions {
  /**
   * A uniq tag to group errors from one source
   *
   * Examples:
   *  source: 'myFnName'
   *  source: 'myClass.myFnName'
   */
  source: string;
  /**
   * Extra data to be attached to the error
   */
  extra?: Extras;
  /**
   * Easy way to filter out errors from Sentry
   * more: https://docs.sentry.io/platforms/node/guides/azure-functions/enriching-events/tags/
   */
  tags?: Record<string, Primitive>;
  /**
   * String literal type:
   *  'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
   * more: https://docs.sentry.io/platforms/node/usage/set-level/
   */
  level?: SeverityLevel;
  /**
   * Used to group errors together based on fingerprint array keys
   * Example networks errors:
   * ```
   *   fingerprint: [method, endpoint, String(response.statusCode)];
   *                ^^^ ['GET','v1/traveler/read', '400']
   * ```
   * more: https://docs.sentry.io/platforms/node/usage/sdk-fingerprinting/
   */
  fingerprint?: string[];
}

export function captureException(error: unknown, options?: ICaptureExceptionOptions): void {
  try {
    if (!isSentryEnabled()) {
      console.warn('[captureException]:', error);
    }

    const { level = 'error', fingerprint, extra, tags, source } = options || {};
    Sentry.withScope((scope) => {
      if (extra) {
        scope.setExtras(extra);
      }

      scope.setLevel(level);

      if (fingerprint) {
        scope.setFingerprint(fingerprint);
      }

      scope.setTags({ source, ...tags });

      Sentry.captureException(error);
    });
  } catch {
    // ignore any errors
    if (Config.VITE_ENVIRONMENT !== 'production') {
      console.warn('[captureException] Error:', error);
    }
  }
}
