import { css } from '@emotion/react';

import { breakpoints, flex, themed, unit } from '../utils';
import { paddings, spacings } from '../utils/styling/utilityTokens';
import { themeSelector } from '../utils/styling/utilityClasses';

export const loader = themed(
  ({ palette }) => css`
    z-index: 1;
    width: 100%;
    height: 100%;
    ${flex.init}
    ${flex.row}
    ${flex.one}
    ${flex.justify.center}
    ${flex.align.center}
    position: absolute;
    background-color: ${palette.surface.overlay};
  `,
);

export const modal = themed(
  (theme) => css`
    &.MuiDialog-root {
      background-color: ${theme.palette.surface.overlay};
    }
    &.BlocksModal-root .MuiDialog-paper {
      position: relative;
      border-radius: ${unit(1)};
      padding-top: 0;
      margin-top: 24px;

      ${breakpoints.from('tablet')} {
        margin-top: 32px;
        margin-bottom: 32px;
      }
      ${breakpoints.from('desktop')} {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

    &.BlocksModal-root .MuiDialog-paperFullScreen {
      border-radius-bottom: 0;
      width: 100%;
    }

    & .BlocksModal-paper {
      &.variant-medium {
        width: 568px;
      }

      &.variant-large {
        width: 780px;
      }

      &.variant-xlarge {
        width: 1024px;
      }
    }

    &.BlocksModal-root .layout-horizontal {
      overflow-y: auto;

      &.with-header {
        display: grid;
        grid-template-columns: minmax(200px, 8fr) 17fr;
        .BlocksModal-header {
          background-color: ${theme.palette.surface.background};
          padding: 40px;
          .BlocksModal-header-title {
            word-break: break-word;
          }
        }
        .BlocksModal-content {
          min-height: 160px;
        }
      }
      &.without-header {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  `,
);

export const modal_body = css`
  display: grid;
  overflow-y: auto;
`;

export const modal_close_button_container = css`
  position: absolute;
  top: 1.1rem;
  right: ${unit(1.5)};
`;

export const modal_close_button = css`
  position: relative;
`;

export const modal_header = themed(
  ({ palette }) => css`
    &.BlocksModal-header {
      ${flex.init}
      ${flex.column}
      ${spacings.extraSmall}
      ${paddings.x.cozy}
      ${paddings.y.cozy}
      background-color: ${palette.surface.base};
      border-bottom: 1px solid ${palette.border.light};

      &.with-hide-button {
        padding-right: 4.5rem;
      }
    }
  `,
);

export const modal_container = css`
  display: contents;
`;

export const modal_content = themed(
  (theme) => css`
    &.BlocksModal-content {
      background-color: ${theme.palette.surface.base};
      &.variant-standard {
        ${paddings.x.cozy}
        ${paddings.yt.cozy}
        ${paddings.yb.tight}

        &.no-footer {
          ${paddings.yb.cozy}
        }
      }

      &.variant-multicard {
        ${paddings.x.comfortable}
        ${paddings.yt.comfortable}

        &.no-footer {
          ${paddings.yb.comfortable}
        }
      }
    }
  `,
);

export const modal_footer = themed(
  ({ palette }) => css`
    &.BlocksModal-footer {
      ${paddings.x.cozy}
      ${paddings.y.cozy}
      background-color: ${palette.surface.base};

      &.type-actions {
        ${flex.init}
        ${flex.row}
        ${flex.align.center}
        ${flex.justify.end}
        gap: ${unit(0.5)};

        &.fullWidth {
          ${flex.justify.evenly}

          /* Column stacking only valid for mobile */
          &.stacking-column {
            ${flex.column}
            ${flex.align.end}
            ${flex.justify.center}
          }
        }
      }

      &.highlight {
        box-shadow: ${palette.shadow.scrollBottomShadow};
        border-top: 1px solid ${palette.border.light};

        ${themeSelector('dark')} {
          box-shadow: none;
        }
      }
    }
  `,
);
