/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PaymentMethodEnum } from '../../../v1/common/money';

/**
 * Payment method
 * @export
 * @enum {string}
 */

export const PaymentMethod = {
  PaymentMethodUnknown: 'PAYMENT_METHOD_UNKNOWN',
  CreditCard: 'CREDIT_CARD',
  BrexPoints: 'BREX_POINTS',
  Cash: 'CASH',
  QantasPoints: 'QANTAS_POINTS',
  VendorProgramPayment: 'VENDOR_PROGRAM_PAYMENT',
  DelayedInvoicing: 'DELAYED_INVOICING',
  FlightCredits: 'FLIGHT_CREDITS',
  QantasTravelFund: 'QANTAS_TRAVEL_FUND',
  CustomVirtualPayment: 'CUSTOM_VIRTUAL_PAYMENT',
} as const;

export const PaymentV1ToV2Mapper = {
  [PaymentMethodEnum.PAYMENT_METHOD_UNKNOWN]: PaymentMethod.PaymentMethodUnknown,
  [PaymentMethodEnum.CREDIT_CARD]: PaymentMethod.CreditCard,
  [PaymentMethodEnum.BREX_POINTS]: PaymentMethod.BrexPoints,
  [PaymentMethodEnum.CASH]: PaymentMethod.Cash,
  [PaymentMethodEnum.QANTAS_POINTS]: PaymentMethod.QantasPoints,
  [PaymentMethodEnum.VENDOR_PROGRAM_PAYMENT]: PaymentMethod.VendorProgramPayment,
  [PaymentMethodEnum.DELAYED_INVOICING]: PaymentMethod.DelayedInvoicing,
  [PaymentMethodEnum.FLIGHT_CREDITS]: PaymentMethod.FlightCredits,
  [PaymentMethodEnum.QANTAS_TRAVEL_FUND]: PaymentMethod.QantasTravelFund,
  [PaymentMethodEnum.CUSTOM_VIRTUAL_PAYMENT]: PaymentMethod.CustomVirtualPayment,
};

export const PaymentV2ToV1Mapper = {
  [PaymentMethod.PaymentMethodUnknown]: PaymentMethodEnum.PAYMENT_METHOD_UNKNOWN,
  [PaymentMethod.CreditCard]: PaymentMethodEnum.CREDIT_CARD,
  [PaymentMethod.BrexPoints]: PaymentMethodEnum.BREX_POINTS,
  [PaymentMethod.Cash]: PaymentMethodEnum.CASH,
  [PaymentMethod.QantasPoints]: PaymentMethodEnum.QANTAS_POINTS,
  [PaymentMethod.VendorProgramPayment]: PaymentMethodEnum.VENDOR_PROGRAM_PAYMENT,
  [PaymentMethod.DelayedInvoicing]: PaymentMethodEnum.DELAYED_INVOICING,
  [PaymentMethod.FlightCredits]: PaymentMethodEnum.FLIGHT_CREDITS,
  [PaymentMethod.QantasTravelFund]: PaymentMethodEnum.QANTAS_TRAVEL_FUND,
  [PaymentMethod.CustomVirtualPayment]: PaymentMethodEnum.CUSTOM_VIRTUAL_PAYMENT,
};

export type PaymentMethod = (typeof PaymentMethod)[keyof typeof PaymentMethod];
