import { ServiceFeeTransactionType } from '@spotnana/types/openapi/models/service-fee-transaction-type';
import { ValueAddedServiceFeeType } from '@spotnana/types/openapi/models/value-added-service-fee-type';
import { defineCommonMessage, defineMessage } from '../translations/defineMessage';

enum TripFeeType {
  BOOKING_FEE = 'BOOKING_FEE',
}
enum BookingFeeType {
  TRIP_FEE = 'TRIP_FEE',
  TRANSACTION_FEE = 'TRANSACTION_FEE',
  VALUE_ADDED_SERVICE_FEE = 'VALUE_ADDED_SERVICE_FEE',
}

export const TripFeeTypeLabelMap = {
  [TripFeeType.BOOKING_FEE]: {
    [BookingFeeType.TRIP_FEE]: defineMessage('$t(Trip fee)'),
    [BookingFeeType.TRANSACTION_FEE]: {
      [ServiceFeeTransactionType.Booking]: defineMessage('Booking fee'),
      [ServiceFeeTransactionType.Modification]: defineMessage('Modification fee'),
      [ServiceFeeTransactionType.Cancellation]: defineMessage('Cancellation fee'),
    },
    [BookingFeeType.VALUE_ADDED_SERVICE_FEE]: {
      [ValueAddedServiceFeeType.VirtualCardPayment]: defineMessage('Virtual payment fee'),
      [ValueAddedServiceFeeType.UnusedCreditApplication]: defineMessage('Unused credits application fee'),
      [ValueAddedServiceFeeType.PriceOptimization]: defineMessage('Price optimization fee'),
    },
  },
};

export const TripFeeTypeLabelMapEmails = {
  [TripFeeType.BOOKING_FEE]: {
    [BookingFeeType.TRIP_FEE]: defineCommonMessage('$t(Trip fee)'),
    [BookingFeeType.TRANSACTION_FEE]: {
      [ServiceFeeTransactionType.Booking]: defineCommonMessage('Booking fee'),
      [ServiceFeeTransactionType.Modification]: defineCommonMessage('Modification fee'),
      [ServiceFeeTransactionType.Cancellation]: defineCommonMessage('Cancellation fee'),
    },
    [BookingFeeType.VALUE_ADDED_SERVICE_FEE]: {
      [ValueAddedServiceFeeType.VirtualCardPayment]: defineCommonMessage('Virtual payment fee'),
      [ValueAddedServiceFeeType.UnusedCreditApplication]: defineCommonMessage('Unused credits application fee'),
      [ValueAddedServiceFeeType.PriceOptimization]: defineCommonMessage('Price optimization fee'),
    },
  },
};

export const TripFeeLabels = {
  TRIP_FEES_STAR: defineMessage('$t(Trip fees)*'),
  TRIP_FEES_TAXES: defineMessage('$t(Trip fee) taxes'),
  NON_REFUNDABLE: defineMessage('*Non-refundable'),
};
