import { useTranslation } from 'react-i18next';
import type { MoneyUtil } from '../utils/Money';
import { localizationKeys } from '../constants/common';

const { LOCALIZED_CURRENCY } = localizationKeys;

const usePriceWithCurrencySymbol = (useOriginalAmount = true): ((totalPrice: MoneyUtil | undefined) => string) => {
  const { t: globalTranslate } = useTranslation();
  return (totalPrice: MoneyUtil | undefined): string => {
    if (!totalPrice) {
      return '';
    }
    const currencyInfo = useOriginalAmount ? totalPrice.getOriginalCurrencyInfo() : totalPrice.getCurrencyInfo();
    const amount = useOriginalAmount ? totalPrice.getOriginalAmount() : totalPrice.getAmount();
    return globalTranslate(LOCALIZED_CURRENCY, {
      value: {
        currencyCode: currencyInfo.code,
        amount,
        maxFractionDigits: currencyInfo.decimalDigits,
        minFractionDigits: currencyInfo.decimalDigits,
      },
    });
  };
};

export default usePriceWithCurrencySymbol;
