import type { MoneyUtil } from '../../utils';
import type { IServiceFeeData } from '../payment';

export interface IFareBreakupFields {
  serviceFees: IServiceFeeData;
  totalSeatPrice: MoneyUtil;
  totalBaggagePrice: MoneyUtil;
  totalAmount: MoneyUtil;
}

export enum FareChangeFareBreakupNodeTypeEnum {
  HEADING = 'HEADING',
  NORMAL = 'NORMAL',
  DIVIDER = 'DIVIDER',
  BANNER = 'BANNER',
}

export type IFareChangeFareBreakupNode =
  | { type: FareChangeFareBreakupNodeTypeEnum.DIVIDER }
  | {
      type: FareChangeFareBreakupNodeTypeEnum.HEADING;
      label: string;
      value: string;
    }
  | {
      type: FareChangeFareBreakupNodeTypeEnum.NORMAL;
      label: string;
      value: string;
      subText: string;
      colorVariant: ColorVariantEnum;
      strikethroughValue?: boolean;
      dangerValue?: string;
      showAsSubHeader?: boolean;
    }
  | {
      type: FareChangeFareBreakupNodeTypeEnum.BANNER;
      value: string;
    };

export enum ColorVariantEnum {
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  NORMAL = 'NORMAL',
}
