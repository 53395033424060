import { RBAC, useFeatureFlag, userRoles } from 'obt-common';
import { useRef, useState } from 'react';
import { IconButton } from '@spotnana/blocks/src/IconButton';

import { HideOnRouteMatch } from 'src/v2-components/HideOnRouteMatch';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ROUTE_PATHS } from '../../routes';
import EntityAutocomplete from '../shared/EntityAutocomplete/EntityAutocomplete';

export const HeaderSearchButton = () => {
  const { pathname } = useLocation();
  const { t: tt } = useTranslation('WEB');
  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [showSearchButton, setShowSearchButton] = useState<boolean>(true);
  const shouldNotShowGlobalSearch = pathname.includes('/company-trips');
  const isGlobalSearchForTravelerEnabled = useFeatureFlag('FE_TMC_GLOBAL_SEARCH_FOR_TRAVELER');

  const handleButtonClick = () => {
    setShowSearchButton(false);
    setTimeout(() => {
      containerRef.current?.querySelector('input')?.focus();
    }, 100);
  };

  const handleInputClose = () => {
    setShowSearchButton(true);
    searchButtonRef.current?.focus();
  };

  const allowedRoles = isGlobalSearchForTravelerEnabled ? [] : userRoles.tmcAgentAndAbove;

  return (
    <RBAC allowedRoles={allowedRoles}>
      <div ref={containerRef} style={{ position: 'relative' }}>
        {!shouldNotShowGlobalSearch && (
          <HideOnRouteMatch paths={[ROUTE_PATHS.SPOTTERNET_ALL]}>
            <IconButton
              onClick={handleButtonClick}
              icon="Search"
              data-testid="header-search-button"
              style={{
                position: 'absolute',
                left: !showSearchButton ? '-32px' : '-72px',
                top: !showSearchButton ? '0px' : '-16px',
              }}
              tooltip={tt('Search')}
              ref={searchButtonRef}
            />
            {!showSearchButton && <EntityAutocomplete active={!showSearchButton} onClose={handleInputClose} />}
          </HideOnRouteMatch>
        )}
      </div>
    </RBAC>
  );
};
