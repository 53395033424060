import type { IFareBreakup } from '../../hooks';
import type { PaymentMethod } from '../../types';
import type { AirRevalidateItineraryResponse } from '../../types/api/v1/obt/air/air_revalidate_itinerary_response';
import { MoneyUtil } from '../Money';

export interface IGetFlightFaresForExchangeInput {
  revalidateItineraryResponse: AirRevalidateItineraryResponse | undefined;
  fareBreakupFromAirSelectedItinerary: IFareBreakup;
  waiverPenaltyAmount: MoneyUtil | undefined;
}

export interface IExchangeFaresInfo {
  totalFare: MoneyUtil;
  fareDifference: MoneyUtil;
  changeFee: MoneyUtil;
  isAirExchangeRefundable: boolean;
  isSameCurrency: boolean;
  allowedFop: PaymentMethod[];
}

export function getFlightFaresForExchange({
  revalidateItineraryResponse,
  fareBreakupFromAirSelectedItinerary,
  waiverPenaltyAmount,
}: IGetFlightFaresForExchangeInput): IExchangeFaresInfo {
  const { changeFees, isSameCurrency, finalPrice, allowedFop, serviceFees } = fareBreakupFromAirSelectedItinerary;

  let fareDiff = MoneyUtil.zeroMoney();

  let totalAmount = MoneyUtil.zeroMoney();

  let penaltyAmount = MoneyUtil.zeroMoney();

  if (revalidateItineraryResponse) {
    const { totalFare, fareDifference, penaltyValue } = revalidateItineraryResponse;
    totalAmount = MoneyUtil.parse(totalFare);
    fareDiff = MoneyUtil.parse(fareDifference);
    penaltyAmount = MoneyUtil.parse(penaltyValue);
  } else {
    fareDiff = finalPrice;
    penaltyAmount = changeFees;
    if (waiverPenaltyAmount) {
      penaltyAmount = waiverPenaltyAmount;
    }
    totalAmount = finalPrice.add(penaltyAmount);
  }

  const airExchangeRefundable = totalAmount.isNegative();

  const isExchangeNotRefundable = !airExchangeRefundable;
  const shouldIncludeServiceFeeInTotal =
    isExchangeNotRefundable && serviceFees?.showWithFeeBreakup && !serviceFees.total.isZero();
  if (shouldIncludeServiceFeeInTotal) {
    totalAmount = totalAmount.add(serviceFees.total);
  }

  return {
    totalFare: totalAmount,
    fareDifference: fareDiff,
    changeFee: penaltyAmount,
    isAirExchangeRefundable: airExchangeRefundable,
    isSameCurrency,
    allowedFop,
  };
}
