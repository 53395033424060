import { useTranslation } from 'react-i18next';
import type { MoneyUtil } from '../../utils';
import { localisedOriginalCurrencyValue } from '../../utils';
import { localizationKeys } from '../../constants';

const { LOCALIZED_CURRENCY } = localizationKeys;

export const useSeatPriceText = (seatPrice?: MoneyUtil) => {
  const { t: globalTranslate } = useTranslation();
  const { t: tt } = useTranslation('WEB');

  if (!seatPrice) {
    return undefined;
  }

  return seatPrice.isZero()
    ? tt('Free')
    : globalTranslate(LOCALIZED_CURRENCY, localisedOriginalCurrencyValue(seatPrice));
};
